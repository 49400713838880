import { Component, OnInit, ViewChild, ElementRef,OnDestroy } from "@angular/core";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import * as moment from "moment";
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomerService } from "src/app/services/customer.service";
import { LoaderService } from "src/app/shared/loader/loader.service";
import { KeycloakService } from 'keycloak-angular';
@Component({
  selector: 'app-storesdashboard',
  templateUrl: './storesdashboard.component.html',
  styleUrls: ['./storesdashboard.component.scss']
})
export class StoresdashboardComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();
  @ViewChild('select') select: MatSelect;
  @ViewChild('chartWrapper') chartWrapper!: ElementRef;
  @ViewChild('chartWrapper1') chartWrapper1!: ElementRef;
  @ViewChild('chartWrapper2') chartWrapper2!: ElementRef;
  @ViewChild('chartWrapper3') chartWrapper3!: ElementRef;
  @ViewChild('chartWrapper4') chartWrapper4!: ElementRef;
  fromdate: any;
  todate: any;
  companynamegi: any;
  allSelected=false;
  companyData1: any;
  selectedcompname: any;
  companyName:any=[]
  overViewData:any=[]
  ovwmetrix:any;
  consumptionData:any=[]
  consumptionGraphData:any=[]
  topValueData:any=[]
  stockValueData:any=[]
  topConsumptionData:any=[]
  stockData:any=[]
  receiptData:any=[]
  RGPData:any={}
  RGPGraphData:any=[]
  QualityCheckData:any=[]
  QualityCheckGraphData:any=[]
  COTData:any;
  view: any[] = [];
  view1: any[] = [];
  view2: any[] = [];
  view3: any[] = [300,300];
  view4: any[] = [300,300];

  // options
  legend: boolean = true;
  showLegend:boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = '';
  yAxisLabel: string = '';
  timeline: boolean = true;
  xAxisLabelStock = "Month"
  yAxisLabelStock = ""
  xAxisLabelReceipt = "Month"
  yAxisLabelReceipt =""
  xAxisLabelCon = "Month"
  yAxisLabelCon = ""
  colorScheme = {
    domain: ['#FFFEFA', '#00FF00', '#FF00FF', '#FF7F50', '#6495ED']
  };
  multi: { name: string; series: any; }[];
  stockGraphData: any=[]
  receiptGraphData: { name: string; series: any; }[];
  tvmmetrix: any;
  tcmmetrix: any;
  toggled:boolean = true;
  legendTitle= ""
  currentdate=new Date()
  model: {start: any, end: any}
  myFlagForSlideToggle: boolean = false;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    // 'This Month': [moment().startOf('month'), moment().endOf('month')],
  'This Month':[moment().startOf('month'),moment(new Date(this.currentdate.setMonth(this.currentdate.getMonth())))],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    // 'This Year': [moment().subtract('year').startOf('year'), moment().subtract('year').endOf('year')],
    'This Year': [moment().startOf('year'),moment(new Date(this.currentdate.setMonth(this.currentdate.getMonth()))) ],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
    'Last 2 Years': [moment().subtract(2, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]

  }
  userDetails: any;
  constructor(
    public custservice: CustomerService,
    public loader: LoaderService,
    public keycloak:KeycloakService
  ) {
    // Chart.register(...registerables);
   }
   onResize(ev:any) {
    this.view = [this.chartWrapper.nativeElement.clientWidth];
  }
  onResize1(ev:any) {
    this.view1 = [this.chartWrapper1.nativeElement.clientWidth];
  }
  onResize2(ev:any) {
    this.view2 = [this.chartWrapper2.nativeElement.clientWidth];
  }
  onResize3(ev:any) {
    this.view3 = [this.chartWrapper3.nativeElement.clientWidth,200];
  }
  onResize4(ev:any) {
    this.view4 = [this.chartWrapper4.nativeElement.clientWidth,200];
  }
  ngOnInit(): void {
    //keycloak token 
    // this.userDetails = this.keycloak.getKeycloakInstance().tokenParsed
    // console.log(this.userDetails);
    
    this.getcompanydata1(); 
    
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  datesUpdated(ev:any){
    if(this.model.end){
      this.fromdate =moment(this.model.start).format("YYYY-MM-DD")
      this.todate = moment(this.model.end).format("YYYY-MM-DD")
    }
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick() {
    let newStatusgi = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatusgi = false;
      }
    });
    this.allSelected = newStatusgi;
  }
  getcompanydata1() {
    let obj = {
      command: "lst",
      lmt:100000,
      pid:1,
      key:this.selectedcompname || ""
    };
    this.custservice.usercompanyData(obj).subscribe((res: any) => {
      this.companyData1= res.data;
      res.data.forEach((ele:any)=>{
        this.companyName.push(ele.company_name)
      })
      this.allSelected = true;
      this.model = {start: moment().subtract(29, 'days'),end:moment()}
      this.fromdate = moment(moment().subtract(29, 'days')).format("YYYY-MM-DD")
      this.todate = moment(moment()).format("YYYY-MM-DD")
      this.loader.hide();
      this.getDashboardData()
    });
  }
  getDashboardData(){
    this.getOverviewData()
      this.getGraphData()
      this.getTopValueData()
      this.getStockValueData()
      this.getStockData()
      this.getTopConsumptionData()
      this.getReceiptData()
      this.getRGPData()
      this.getQCSData()
      this.getCOTData()
  

  }
  autoRefreshingData(data:any){
    // console.log(data);
    if(data == true){
      this.getDashboardData();
      interval(20000).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.getDashboardData();
      });
    }
  }
  getOverviewData(){   
    let obj={
      from_date: this.fromdate,
      to_date: this.todate,
      company_name: this.companyName,
      command: "ovw",
      showLoader: 'hide'
    }
    this.custservice.getStoresDashboardData(obj).subscribe((res:any)=>{
      if(res.data){
        this.overViewData = res.data
        this.ovwmetrix = res.metrix
      }else{
        this.overViewData=[]
        this.ovwmetrix = 'lacs'
      }
    })
  }
  getGraphData(){
    let obj={
      from_date: this.fromdate,
      to_date: this.todate,
      company_name: this.companyName,
      command: "con",
      showLoader: 'hide'
    }
    this.custservice.getStoresDashboardData(obj).subscribe((res:any)=>{
      if(res){
        this.consumptionData = res.data
        let multi:any=[]
      this.consumptionData.forEach((element:any) => {
        multi.push({name:element.time_line,value:element.value})
      });
      this.consumptionGraphData = [
        {
          "name": "Consumption",
        "series":multi
        },
      ];
      if(res.metrix === "lacs"){
        this.yAxisLabelCon = "Value(Lacs)"
      }
      }
    })
    this.view = [this.chartWrapper.nativeElement.clientWidth];
  }
  getTopValueData(){
    let obj={
      from_date: this.fromdate,
      to_date: this.todate,
      company_name: this.companyName,
      command: "tvm",
      showLoader: 'hide'
    }
    this.custservice.getStoresDashboardData(obj).subscribe((res:any)=>{
      if(res){
        this.topValueData = res.data
        this.tvmmetrix = res.metrix
      }
    }) 
  }
  getStockValueData(){
    let obj={
      from_date: this.fromdate,
      to_date: this.todate,
      company_name: this.companyName,
      command: "stv",
      showLoader: 'hide'
    }
    this.custservice.getStoresDashboardData(obj).subscribe((res:any)=>{
      if(res){
        this.stockValueData = res.data[0]
      }
    }) 
  }
  getStockData(){
    this.stockGraphData=[]
    let obj={
      from_date: this.fromdate,
      to_date: this.todate,
      company_name: this.companyName,
      command: "stk",
      showLoader: 'hide'
    }
    this.custservice.getStoresDashboardData(obj).subscribe((res:any)=>{
      if(res){
        this.stockData = res.data
        let openingvalue:any=[]
        let receivedvalue:any=[]
        let issuedvalue:any=[]
        let returnedvalue:any=[]
        let closedvalue:any=[]
        this.stockData.forEach((element:any) => {
          openingvalue.push({name:element.time_line,value:element.opening_value})
          receivedvalue.push({name:element.time_line,value:element.received_value})
          issuedvalue.push({name:element.time_line,value:element.issued_value})
          returnedvalue.push({name:element.time_line,value:element.returned_value})
          closedvalue.push({name:element.time_line,value:element.closing_value})
        });
        this.stockGraphData=closedvalue
        var emptyString = ' ';
        for(var j=this.stockGraphData.length; j<=10;j++){
          emptyString += emptyString;
          this.stockGraphData.push({name:emptyString, value:''})
        }
        if(res.metrix==="lacs"){
        this.yAxisLabelStock = "Closed Value(Lacs)"
        }
          // {
          //   "name": "Opening Value",
          // "series": openingvalue
          // },
          // {
          //     "name": "Received Value",
          //   "series": receivedvalue
          //   },
          // {
          //     "name": "Issued Value",
          //   "series": issuedvalue
          //   },{
          //     "name": "Returned Value",
          //   "series": returnedvalue
          //   },
            // {
            //   "name": "Closed Value",
            // "series": closedvalue
            // },
        
        }
      })
      this.view2 = [this.chartWrapper2.nativeElement.clientWidth]; 
  }
  getTopConsumptionData(){
    let obj={
      from_date: this.fromdate,
      to_date: this.todate,
      company_name: this.companyName,
      command: "tcm",
      showLoader: 'hide'
    }
    this.custservice.getStoresDashboardData(obj).subscribe((res:any)=>{
      if(res){
        this.topConsumptionData = res.data
        this.tcmmetrix = res.metrix
      }
    }) 
  }
  getReceiptData(){
    let obj={
      from_date: this.fromdate,
      to_date: this.todate,
      company_name: this.companyName,
      command: "rec",
      showLoader: 'hide'
    }
    this.custservice.getStoresDashboardData(obj).subscribe((res:any)=>{
      if(res){
        this.receiptData = res.data
        let multi:any=[]
        this.receiptData.forEach((element:any) => {
          multi.push({name:element.time_line,value:element.value})
        });
        this.receiptGraphData = [
          {
            "name": "Stock",
          "series":multi
          },
        ];
        if(res.metrix==="lacs"){
          this.yAxisLabelReceipt = "Value(Lacs)"
        }
        }
      })
      this.view1 = [this.chartWrapper1.nativeElement.clientWidth];
  }
  getRGPData(){
    let obj={
      from_date: this.fromdate,
      to_date: this.todate,
      company_name: this.companyName,
      command: "rgp",
      showLoader: 'hide'
    }
    this.custservice.getStoresDashboardData(obj).subscribe((res:any)=>{
      if(res){
        this.RGPData = res.data[0]
        this.RGPGraphData =[{
          "name":String(Object.keys(this.RGPData)[0]),
          "value": Number(Object.values(this.RGPData)[0])
        },
        {
        "name":String(Object.keys(this.RGPData)[1]),
        "value": Number(Object.values(this.RGPData)[1])
      },
      ]
      }
    }) 
  }
  getQCSData(){
    let obj={
      from_date: this.fromdate,
      to_date: this.todate,
      company_name: this.companyName,
      command: "qcs",
      showLoader: 'hide'
    }
    this.custservice.getStoresDashboardData(obj).subscribe((res:any)=>{
      if(res){
        this.QualityCheckData = res.data
        this.QualityCheckGraphData =[{
          name:Object.keys(this.QualityCheckData[0])[0],
          value: Object.values(this.QualityCheckData[0])[0]
        },
        {
        name:Object.keys(this.QualityCheckData[0])[1],
        value: Object.values(this.QualityCheckData[0])[1]
      },
      ]
      }
    }) 
  }
  getCOTData(){
    this.COTData=0;
    let obj={
      from_date: this.fromdate,
      to_date: this.todate,
      company_name: this.companyName,
      command: "cot",
      showLoader: 'hide'
    }
    this.custservice.getStoresDashboardData(obj).subscribe((res:any)=>{
      if(res){
        this.COTData = res.count
      }
    }) 
  }
  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    if(dateRangeEnd.value){
    this.fromdate = moment(dateRangeStart.value).format("YYYY-MM-DD")
    this.todate = moment(dateRangeEnd.value).format("YYYY-MM-DD")
    }
  }
}
