import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import * as moment from "moment";
import { CustomerService } from "src/app/services/customer.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { AlertCallsService } from "src/app/auth/alert-calls.service";
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import * as XLSX from 'xlsx';
import { MatSort } from "@angular/material/sort"; 
@Component({
  selector: "app-nrgp",
  templateUrl: "./nrgp.component.html",
  styleUrls: ["./nrgp.component.scss"],
})
export class NrgpComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  @Output() page = new EventEmitter<PageEvent>();
  @ViewChild(MatSort) sort: MatSort;
  goTo:any;
  pageNumbers:any=[]
  reaDatalog: boolean;
  totalRecordslog: any;
  dataSourcemainlog = new MatTableDataSource();
  displayedColumns: any[] = [
    "action",
    "sno",
    "material_code",
    "material_description",
    "unit_of_measurement",
    "storage_location",
    "request_quantity",
    "quantity",
    "unit_price",
    "total_price",
    // "batch_no",
    "valuation_type",
    "item_remarks"
  ];
  displayedColumns1: any[] = [
    "sno",
    "Number",
    "Date",
    "company_Name",
    "tocompanyname",
    "request_number",
    "count",
    // "transfer_type",
    "action",
  ];
  demo1TabIndex = 0;
  model1: any = {};
  btn: any = "Save";
  companyData: any;
  masterData: any;
  selectedmaterial: any;
  model: any ={};
  materialCODE: any;
  materialNAME: any;
  selectedstorage: any;
  storageData: any;
  valutiondata: any;
  confirnum:boolean=false;
  saveddataarray: any[] = [];
  matCode: any[] = [];
  matName: any[] = [];
  UOM: any[] = [];
  storageLocation: any[] = [];
  InvoiceQuantity: any[] = [];
  ReqQTY: any[] = [];
  qty: any[] = [];
  itemremarks: any[] = [];
  unitPrice:any=[]
  basic_price:any=[]
  // batchno: any[] = [];
  valutionType: any[] = [];
  dataSource = new MatTableDataSource();
  dataSourcemain = new MatTableDataSource();
  SNO: any[] = [];
  selectedIndex: number;
  systemref: any;
  model2: any = {};
  selectedmaterial1: any;
  model2matcode: any;
  model2matdes: any;
  selectedstorage1: any;
  pageIndex: any = 1;
  pageIndex1: any = 1;
  pageSize: any = 10;
  logpageSize: any = 10;
  reaData: boolean;
  totalRecords: any = 0;
  editednumber: any;
  deleteNumber: any;
  editdataa: any;
  editModel: any = {};
  deletemodel: any = {};
  nrgpnum: any;
  filedata: any=[];
  createNewFile: any = {};
  fileUploadUrls: any[] = [];
  filenamearray1: any[] = [];
  fileUploadUrlspr: any[] = [];
  imageUrl = environment.base_url;
  deleteid: any;
  dialogRef: any = null;
  fileUploadUrlsgt: any[] = [];
  filenamearray: any[] = [];
  selectedfiles: any[] = [];
  resultNrgpnumber: any; 
  columnname: any="";
  searchData: any;
  smsdata: any;
  smsdata1: any;
  companyData1: any;
  logdata: any;
  total:number=0;
  purposeData:any=[]
  selectedPurpose: any;
  selectedvendor: any;
  companyname:any=[];
  vendorData: any;
  rgpnumber: any;
  rgpdata: any;
  selectedrgpnumber: any;
  poiddata: any;
  dcnumber: any;
  DCDATA: any;
  selecteddcnumber: any;
  matcodedata: any;
  DCID: any[] = []
  editDATAA: any = false;
  matDesc: string;
  documnet_nodata: any;
  viewnumber: any;
  viewData: boolean=false;
  logsearchData: any;
  SignData: any;
  approvalbtn: boolean=false;
  docSignature: any;
  today:any
  txnId: any;
  editeddata: boolean=false;

  //
  displayedColumnsap: any[] = [
    'sno',
    'document_number',
    // 'document_name',
    'description',
    'company_Name',
    'concern_name',
    'level',
    'pending_at',
    'action'
  ];
  pageNumbersap:any=[];
  pageIndexap: any = 1;
  pageSizeap: any = 10;
  totalRecordsap: any = 0;
  dataSourcemainap = new MatTableDataSource();
  goToap:any;
  reaDataap: boolean=false;
  @ViewChild('paginatorap') paginatorap: MatPaginator;
  columnnaplist: any;
  columnnameap: any="";
  searchDataap: any; 
  approvalstatus: boolean =false;
  gamCompanyfields: boolean=false;
  constructor(
    private custservice: CustomerService,
    private dialog: MatDialog,
    private alertcall: AlertCallsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.today=moment(new Date()).format("YYYY-MM-DD");
    this.model1.date = moment(new Date()).format("YYYY-MM-DD");
    // this.model1.reqdate = moment(new Date()).format("YYYY-MM-DD");
    // this.model1.lrdate = moment(new Date()).format("YYYY-MM-DD");
    // this.model1.order_nodate=moment(new Date()).format("YYYY-MM-DD");
    this.gamRestrictfields();
    this.getcompanydata();
    this.getTransactionId();
    this.getmasterdata();
    this.getstoragelocData();
    this.getvalutionData();
    this.getNRGPlist();
    this.getcompanydata1();
    this.getPurposeData();
    this.getvendordata();
    this.getpoData();
    this.getDataap();
    this.route.queryParams.subscribe((params: any) => {
      if (params.tab == "notificationsissue") {
        this.demo1TabIndex = 1;
      } else {
        this.demo1TabIndex = 0;
      }
    });
  }
  getTransactionId(){
    this.txnId=""
    this.custservice.getTransactionId().subscribe((res:any)=>{
      if(res){
        // console.log(res.txn_id);
        this.txnId=res.txn_id
      }
    })
  }
 //consignee 
 getvendordropdowndata(ev:any){
  console.log(ev.target.value);
  this.selectedvendor=ev.target.value
  if( this.selectedvendor.length>2){
 this.getvendordata()
  }
  if(! this.selectedvendor){
    this.getvendordata()
  }
}
getvendordata(){
  let obj={
    "command":"lst",
    "lmt":100000,
    "key":this.selectedvendor ||"",
  }
  this.custservice.getvendormasterdata(obj).subscribe((res:any)=>{
    this.vendorData=res.data
  })
}
selectedConsignee(){
  this.vendorData.forEach((el: any) => {
    if (el.name == this.model1.cosignee) {
      this.model1.consignee_address=el.address_1
    }
  });
}
  
  savefinaldata(fr: any) {
    console.log(this.saveddataarray);
    
    if (this.btn === "Save") {
      this.saveddataarray.forEach((ele: any, index) => {
        this.SNO.push(index + 1);
        this.matCode.push(ele.material_code);
        this.matName.push(ele.material_description || ele.description || ele.matcode);
        this.UOM.push(ele.uom || ele.unit_of_measurment || ele.unit_of_measurement);
        this.storageLocation.push(ele.storagelocation || ele.storage_location);
        this.ReqQTY.push(Number(ele.reqqty || ele.request_quantity));
        this.qty.push(Number(ele.qty || ele.quantity));
        this.itemremarks.push(ele.itemremarks || ele.item_remarks);
        this.unitPrice.push(ele.unit_price)
        this.basic_price.push(ele.basic_price )
        // this.batchno.push(ele.batchno);
        this.valutionType.push(ele.valutiontype || ele.valuation_type);
        console.log(this.valutionType);
        
      });
      let obj = {
        command: "add",
        "txn_id":this.txnId,
        work_order_number: this.model1.wonumber,
        date: moment(this.model1.date).format("YYYY-MM-DD"),
        company_name: this.model1.companyname,
        to_company_name: this.model1.tocompanyname,
        receiver_name: this.model1.receivername,
        request_date: moment(this.model1.reqdate).format("YYYY-MM-DD"),
        request_number: this.model1.reqnumber,
        comments: this.model1.cmts,
        vehicle_number: this.model1.vehnumber,
        // Transfer_type: this.model1.ttype,
        consignee_name: this.model1.cosignee,
        consignee_address:this.model1.consignee_address,
        lr_number: this.model1.lrnumber,
        transporter_name: this.model1.tname,
        lr_date: this.model1.lrdate,
        purpose: this.model1.purpose,
        locations: this.model1.location,
        line_item: this.SNO,
        material_code: this.matCode,
        material_description: this.matName,
        unit_of_measurment: this.UOM,
        request_quantity: this.ReqQTY,
        quantity: this.qty,
        item_remarks: this.itemremarks,
        valuation_type: this.valutionType,
        storage_location: this.storageLocation,
        unit_price: this.unitPrice,
        basic_price: this.basic_price,
        order_number:this.model1.order_no,
        order_date: moment(this.model1.order_nodate).format("YYYY-MM-DD"),
        eway_bill:this.model1.ewaybillno,
        to_locations:this.model1.to_location,
        rejection_number:this.model1.rejection_number,
        delivery_challan_number:this.model1.dcnumber,
        to_location:this.model1.tolocation
        // batch_number: this.batchno,
        // comments: this.model1.comments,
      };
      this.custservice.addOutGatePassNrgp(obj).subscribe((res: any) => {
        console.log(res);

        if (res && res["status_code"] == "200") {
          // this.alertcall.showSuccess('Accepted', res['message']);
          Swal.fire({
            text: res["message"],
            title: res["reference"],
            icon: "success",
            // title: res['reference'],
            width: 500,
          });
          // fr.reset();
          this.saveddataarray = [];
          this.dataSource.data = [];
          this.SNO = [];
          this.matCode = [];
          this.matName = [];
          this.UOM = [];
          this.storageLocation = [];
          this.ReqQTY = [];
          this.qty = [];
          this.itemremarks = [];
          this.unitPrice=[],
          this.basic_price=[]
          this.valutionType=[]
          this.dcnumber=""
          // this.batchno = [];
          this.smsdata = "";
          this.smsdata1="";
          this.getTransactionId();
          this.getNRGPlist();
          this.resultNrgpnumber = res["reference"];
          // if (this.fileUploadUrlspr.length > 0) {
            this.uploadedselctedfiles();
          // }
          this.model1={};
          this.total = 0
          // this.demo1TabIndex=1;
          this.model1.date = moment(new Date()).format("YYYY-MM-DD");
        } else {
          this.alertcall.showWarning("Accepted", res["message"]);
          this.SNO = [];
          this.matCode = [];
          this.matName = [];
          this.UOM = [];
          this.storageLocation = [];
          this.ReqQTY = [];
          this.qty = [];
          this.itemremarks = [];
          this.unitPrice=[],
          this.basic_price=[]
          this.valutionType=[]
          // this.batchno = [];
          this.model1.date = moment(new Date()).format("YYYY-MM-DD");
        }
      });
    } else {
      console.log(this.saveddataarray);
      this.saveddataarray.forEach((ele: any, index) => {
        this.SNO.push(index + 1);
        this.matCode.push(ele.material_code);
        this.matName.push(ele.material_description || ele.matcode);
        this.UOM.push(ele.uom || ele.unit_of_measurment || ele.unit_of_measurement);
        this.storageLocation.push(ele.storagelocation || ele.storage_location);
        this.ReqQTY.push(Number(ele.reqqty || ele.request_quantity));
        this.qty.push(Number(ele.qty || ele.quantity));
        this.itemremarks.push(ele.itemremarks || ele.item_remarks);
        // this.batchno.push(ele.batchno || ele.batch_number);
        this.valutionType.push(ele.valutiontype || ele.valuation_type);
        this.unitPrice.push(ele.unit_price)
        this.basic_price.push(ele.basic_price)
      });
      let obj = {
        reason: this.editModel.reason,
        work_order_number: this.model1.wonumber,
        date: moment(this.model1.date).format("YYYY-MM-DD"),
        company_name: this.model1.companyname,
        to_company_name: this.model1.tocompanyname,
        // contractor_name: this.model1.contractorname,
        // giver_name: this.model1.givername,
        receiver_name: this.model1.receivername,
        request_date: moment(this.model1.reqdate).format("YYYY-MM-DD"),
        request_number: this.model1.reqnumber,
        comments: this.model1.cmts,
        vehicle_number: this.model1.vehnumber,
        // Transfer_type: this.model1.ttype,
        transporter_name: this.model1.tname,
        consignee_name: this.model1.cosignee,
        consignee_address:this.model1.consignee_address,
        lr_number: this.model1.lrnumber,
        lr_date: moment(this.model1.lrdate).format("YYYY-MM-DD"),
        locations: this.model1.location,
        to_location:this.model1.tolocation,
        purpose: this.model1.purpose,
        line_item: this.SNO,
        material_code: this.matCode,
        material_description: this.matName,
        unit_of_measurment: this.UOM,
        request_quantity: this.ReqQTY,
        quantity: this.qty,
        item_remarks: this.itemremarks,
        valuation_type: this.valutionType,
        storage_location: this.storageLocation,
        unit_price: this.unitPrice,
        basic_price: this.basic_price,
        order_number:this.model1.order_no,
        order_date: moment(this.model1.order_nodate).format("YYYY-MM-DD"),
        eway_bill:this.model1.ewaybillno,
        to_locations:this.model1.to_location,
        rejection_number:this.model1.rejection_number,
        // batch_number: this.batchno,
        delivery_challan_number:this.model1.dcnumber,
        command: "edt",
        "txn_id":this.txnId,
        number: this.editednumber,
      };
      this.custservice.addOutGatePassNrgp(obj).subscribe((res: any) => {
        if (res && res["status_code"] == "200") {
          // this.alertcall.showSuccess('Accepted', res['message']);
          Swal.fire({
            text: res["message"],
            title: res["reference"],
            icon: "success",
            // title: res['reference'],
            width: 500,
          });
          fr.reset();
          this.saveddataarray = [];
          this.dataSource.data = [];
          this.SNO = [];
          this.matCode = [];
          this.matName = [];
          this.UOM = [];
          this.storageLocation = [];
          this.ReqQTY = [];
          this.qty = [];
          this.itemremarks = [];
          this.unitPrice=[],
          this.basic_price=[]
          this.valutionType=[]
          // this.batchno = [];
          this.smsdata = "";
          this.smsdata1="";
          this.getTransactionId();
          this.getNRGPlist();
          this.editModel.reason = "";
          this.resultNrgpnumber = this.editednumber;
          // if (this.fileUploadUrlspr.length > 0) {
            this.uploadedselctedfiles();
          // }
          this.btn = "Save";
          this.editeddata =false;
          this.model1={};
          this.total = 0
          this.demo1TabIndex=1;
        } else {
          this.alertcall.showWarning("Accepted", res["message"]);
          this.SNO = [];
          this.matCode = [];
          this.matName = [];
          this.UOM = [];
          this.storageLocation = [];
          this.ReqQTY = [];
          this.qty = [];
          this.itemremarks = [];
          this.unitPrice=[],
          this.basic_price=[]
          this.valutionType=[]
          // this.batchno = [];
          this.model1.date = moment(new Date()).format("YYYY-MM-DD");
        }
      });
    }
  }
  getcompanydata() {
    let obj = {
      command: "lst",
      lmt: 100000,
      pid: 1,
    };
    this.custservice.getcompanymasterdata(obj).subscribe((res: any) => {
      this.companyData = res.data;
    });
  }
  getcompanydata1() {
    let obj = {
      command: "lst",
      lmt: 100000,
      pid: 1,
    };
    this.custservice.usercompanyData(obj).subscribe((res: any) => {
      this.companyData1 = res.data;
    });
  }
  addNrgp(data: any) {
    this.materialNAME=""
    this.materialCODE=""
    this.model ={qty:0,unit_price:0,basic_price:0}
    this.dialog.open(data, {
      width: "1100px",
    });
  }
  closemodel() {
    this.dialog.closeAll();
  }

  getmasterdata() {
    let obj = {
      command: "lst",
      lmt: 100,
      pid: 1,
      key: this.selectedmaterial || this.selectedmaterial1,
      // "code":this.matcodedata,
    };
    this.custservice.getmaterialmasterdata(obj).subscribe((res: any) => {
      console.log(res);
      this.masterData = res.data;
    //   this.masterData.forEach((ele: any) => {
    //     if (ele.code == this.matcodedata) {
    //       // alert(1)
    //       this.model2.matcode = ele.system_reference_1
    //       // this.systemref = ele.system_reference_1
    //     }
    // });
    });
  }
  getmaterialDATA(ev: any) {
    console.log(ev.target.value);
    this.selectedmaterial = ev.target.value;
    if (this.selectedmaterial.length > 2) {
      this.getmasterdata();
    }
    if (!this.selectedmaterial) {
      this.getmasterdata();
    }
  }
  selectedmastergroup() {
    console.log(this.model.matcode);
    this.masterData.forEach((ele: any) => {
      if (ele.system_reference_1 == this.model.matcode) {
        this.model.uom = ele.uom_1;
        this.materialCODE = ele.code;
        this.materialNAME = ele.name;
      }
    });
    this.getSMSdata();
  }
  getstorageDATA(ev: any) {
    console.log(ev.target.value);
    this.selectedstorage = ev.target.value;
    if (this.selectedstorage.length > 2) {
      this.getstoragelocData();
    }
  }
  getstoragelocData() {
    let obj = {
      command: "mat",
      field: "storage_location",
      key: this.selectedstorage,
    };
    this.custservice.getmatstoragelocdata(obj).subscribe((res: any) => {
      this.storageData = res.data;
    });
  }
  keyPressNumbers(evt: any) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  getvalutionData() {
    let obj = {
      lmt: 100000,
      pid: 1,
      command: "lst",
      key: "",
    };
    this.custservice.getvalutiondata(obj).subscribe((res: any) => {
      this.valutiondata = res.data;
    });
  }
  saveaddeddata() {
    this.model["material_code"] = this.materialCODE;
    this.model["material_description"] = this.materialNAME;
    console.log(this.model);
    // this.editDATAA=true;
    this.saveddataarray.push(this.model);
    this.dataSource.data = this.saveddataarray;
    console.log(this.dataSource.data);
    this.model = {};
    this.smsdata = "";
    this.dialog.closeAll();
    this.total = 0;
    this.saveddataarray.forEach((ele:any)=>{
      this.total += ele.basic_price
      this.model1.materialvalue = ele.basic_price
      // this.total +=ele.total_value
      })
  }
  editdata(row1: any, index: any, data: any) {
    // this.editDATAA=false
    // this.matcodedata=row1.material_code
    // this.getmasterdata()
    this.materialCODE=""
    this.materialNAME=""
    this.selectedIndex = this.saveddataarray.indexOf(row1);
    console.log(this.selectedIndex);
    this.dialog.open(data, {
      width: "1100px",
    });
    // this.masterData.forEach((ele: any) => {
    //   if (ele.code == row1.material_code) {
    //     this.systemref = ele.system_reference_1;
    //   }
    // });
    this.matDesc=row1.material_code+"-"+(row1.description || row1.material_description || row1.matcode);
    this.materialCODE=row1.material_code;
    this.materialNAME=row1.description || row1.material_description || row1.matcode;
    this.model2.matcode = this.systemref || row1.matcode || this.matDesc;
    this.model2.uom = row1.uom || row1.unit_of_measurment || row1.unit_of_measurement ;
    this.model2.storagelocation = row1.storagelocation || row1.storage_location ;
    this.model2.reqqty = row1.reqqty || row1.request_quantity || row1.quantity;
    this.model2.qty = row1.qty || row1.quantity || row1.quantity;
    this.model2.itemremarks = row1.itemremarks || row1.item_remarks;
    // this.model2.batchno = row1.batchno || row1.batch_number;
    this.model2.valutiontype = row1.valutiontype || row1.valuation_type;
    this.model2.unit_price = row1.unit_price
    this.model2.basic_price = row1.basic_price;
    
  }

  getmaterialDATA1(ev: any) {
    this.selectedmaterial1 = ev.target.value;
    if (this.selectedmaterial1.length > 2) {
      this.getmasterdata();
    }
    if (!this.selectedmaterial1) {
      this.getmasterdata();
    }
  }
  selectedmastereditgroup() {
    this.masterData.forEach((el: any) => {
      if (el.system_reference_1 == this.model2.matcode) {
        this.model2.uom = el.uom_1;
        this.materialCODE = el.code;
        this.materialNAME = el.name;
      }
    });
    this.getSMSdata();
    console.log(this.model2matcode, this.model2matdes);
  }
  getstorageDATA1(ev: any) {
    console.log(ev.target.value);
    this.selectedstorage1 = ev.target.value;
    if (this.selectedstorage1.length > 2) {
      this.getstoragelocData();
    }
  }
  saveaddededitdata(fr: any) {
    this.materialCODE=""
    this.materialNAME=""
    this.masterData.forEach((el: any) => {
      if (el.system_reference_1 === this.model2.matcode) {
        this.model2matcode = el.code;
        this.materialCODE = el.code;
        this.materialNAME = el.name;
      }
    });
    this.model2["material_code"] = this.materialCODE;
    this.model2["material_description"] = this.materialNAME;
    this.saveddataarray.splice(this.selectedIndex, 1, this.model2);
    this.dataSource.data = this.saveddataarray;
    console.log(this.dataSource.data);

    this.saveddataarray[this.selectedIndex].matcode = this.model2.matcode;

    this.model2 = {};
    this.smsdata = "";
    this.smsdata1="";
    this.dialog.closeAll();
    // this.total=0;
    // this.saveddataarray.forEach((ele:any)=>{
    //   this.total += ele.basic_price 
    //   this.model1.materialvalue+=ele.basic_price
    //   })
    this.total=0
    this.saveddataarray.forEach((ele:any)=>{
    this.total += ele.basic_price || ele.total_value
    this.model1.materialvalue+=ele.basic_price
    })
  }
  deleterow(index: any) {
    this.saveddataarray.splice(index, 1);
    console.log(this.saveddataarray);
    this.dataSource.data = this.saveddataarray;
    console.log(this.dataSource.data);
  }
  getNRGPlist1(){
    this.columnname = "";
    this.searchData = "";
    this.getNRGPlist();
  }
  getNRGPlist() {
    this.pageNumbers=[]
    let obj = {
      command: "lst",
      lmt: this.pageSize,
      pid: this.pageIndex,
      // key: "",
    };
    this.custservice.getNrgpDataList(obj).subscribe((res: any) => {
      console.log(res);
      this.reaData = false;
      this.totalRecords = res?.count;
      this.dataSourcemain.data = res.data;
      this.dataSourcemain.sort = this.sort;
      for (let i = 1; i <= Math.ceil(this.totalRecords / this.pageSize); i++) {
        this.pageNumbers.push(i);
      }
      this.goTo=this.pageIndex
      if (res.data.length == 0) {
        this.reaData = true;
      }
    });
  }
  goToChange(){
    this.paginator.pageIndex = this.goTo - 1;
    const event: PageEvent = {
      // previousPageIndex: this.pageIndex-1,
      length: this.paginator.length,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
    };
    this.paginator.page.next(event);
  }
  emitPageEvent(pageEvent: PageEvent) {
    this.paginator.page.next(pageEvent);
  }

  calculateSerialNumber(index: number): number {
    const displayedIndex = index + 1 + (this.pageIndexap - 1) * this.pageSizeap;
    return isNaN(displayedIndex) ? 0 : Math.min(displayedIndex, this.totalRecordsap);
  }
  onChangeap() {
    console.log('Selected:',this.columnnameap);
    this.searchDataap=""
    if(!this.searchDataap){
      this.getDataap()
    }    
  }
  searchap1(){
    if(this.searchDataap.length>2 ){
      if(this.paginatorap){
        this.paginatorap.firstPage();
      }
      this.searchap();
    }else if(!this.searchDataap){
      this.getDataap()
      this.columnnameap=""
    }
  }
  searchap(){
    this.pageNumbersap = [];
    // this.pageIndexap=1
    console.log(this.searchDataap); 
   let obj= {"command":"pnd",  "document_name": "OutGatePassNRGP","field":this.columnnameap ,"key":this.searchDataap,"lmt":this.pageSizeap,"pid":this.pageIndexap, showLoader: 'hide'}
    if(this.searchDataap.length>2 && this.columnnameap){
      this.custservice.getNrgpDataList(obj).subscribe((res: any) => {
        if(res['status_code'] == 200){
          this.reaDataap = false;
          this.totalRecordsap = res?.count;
          this.columnnaplist=res?.field;
          this.dataSourcemainap.data = res.data
          this.dataSourcemainap.sort = this.sort
          for (let i = 1; i <= Math.ceil(this.totalRecordsap / this.pageSizeap); i++) {
            this.pageNumbersap.push(i);
          }
          this.goToap=this.pageIndexap
          if (res.data.length == 0) {
            this.reaDataap = true
          }
        }else{
          this.reaDataap = true
        }
      })
    }else if(!this.searchDataap){
      this.getDataap()
      this.columnnameap=""
    }
  }
  onpageeventap(event:any){ 
    this.pageIndexap=event.pageIndex+1;
    this.pageSizeap= event.pageSize;
    if(!this.searchDataap){
      this.getDataap()
    }
    else{
      this.searchap()
    }
  }
  getDataap1(){
    this.columnnameap=""
    this.searchDataap = "";
    this.getDataap();
  }
  getDataap() {
    
    this.pageNumbersap=[]
    let obj = {
      "command": "pnd",
      "lmt": this.pageSizeap,
      "pid": this.pageIndexap,
      "document_name": "OutGatePassNRGP"
    }
    this.custservice.getNrgpDataList(obj).subscribe((res: any) => {
      if(res['status_code'] == 200){
        this.reaDataap = false;
        this.totalRecordsap = res?.count;
        this.columnnaplist=res?.field;
        this.dataSourcemainap.data = res.data
        this.dataSourcemainap.sort = this.sort
        for (let i = 1; i <= Math.ceil(this.totalRecordsap / this.pageSizeap); i++) {
          this.pageNumbersap.push(i);
        }
        this.goToap=this.pageIndexap
        if (res.data.length == 0) {
          this.reaDataap = true
        }
      }else{
        this.reaDataap = true
      }

    })
  }
  goToChangeap(){
    this.paginatorap.pageIndex = this.goToap - 1;
    const event: PageEvent = {
      length: this.paginatorap.length,
      pageIndex: this.paginatorap.pageIndex,
      pageSize: this.paginatorap.pageSize,
    };
    this.paginatorap.page.next(event);
  }
  emitPageEventap(pageEvent: PageEvent) {
    this.paginatorap.page.next(pageEvent);
  }


  editNrgpData(data: any, dialog: any) {
    this.dialog.open(dialog, {
      width: "400px",
    });
    this.editednumber = data.number;
    this.editModel.reason = "";
    console.log(this.editednumber);
  }
  saveeditreason() {
    this.displayedColumns = ["action","sno","material_code","material_description","unit_of_measurement","storage_location","request_quantity","quantity","unit_price","total_price", "valuation_type","item_remarks"  
  ];
    let obj = {
      command: "mat",
      number: this.editednumber,
    };
    this.custservice.editNrgpData(obj).subscribe((res: any) => {
      this.viewData=false
      console.log(res);
      this.dialog.closeAll();
      this.editdataa = res.data[0];
      this.saveddataarray = res.data;
      this.dataSource.data = this.saveddataarray;
      (this.model1.wonumber = this.editdataa.work_order_number),
        (this.model1.date = moment(this.editdataa.date).format("YYYY-MM-DD")),
        (this.model1.companyname = this.editdataa.company_name),
        (this.model1.tocompanyname = this.editdataa.to_company_name),
        // this.model1.contractorname=this.editdataa.to_company_name,
        // this.model1.givername=this.editdataa.to_company_name,
        (this.model1.reqnumber = this.editdataa.request_number),
        (this.model1.reqdate = moment(this.editdataa.request_date).format(
          "YYYY-MM-DD"
        )),
        (this.model1.receivername = this.editdataa.receiver_name),
        // (this.model1.ttype = this.editdataa.Transfer_type),
        (this.model1.expectedredate = moment(
          this.editdataa.expected_return_date
        ).format("YYYY-MM-DD")),
        (this.model1.vehnumber = this.editdataa.vehicle_number),
        (this.model1.tname = this.editdataa.transporter_name),
        (this.model1.lrnumber = this.editdataa.lr_number),
        (this.model1.lrdate = moment(this.editdataa.lr_date).format(
          "YYYY-MM-DD"
        )),
        (this.model1.cmts = this.editdataa.comments),
        (this.model1.cosignee = this.editdataa.consignee_name),
        (this.model1.consignee_address = this.editdataa.consignee_address),
        (this.model1.purpose = this.editdataa.purpose),
        (this.model1.location = this.editdataa.locations),
        (this.model1.order_no=this.editdataa.order_number),
        (this.model1.order_nodate=moment(this.editdataa.order_date).format(
          "YYYY-MM-DD"
        )),
        (this.model1.rejection_number=this.editdataa.rejection_number),
        (this.model1.ewaybillno=this.editdataa.eway_bill),
        (this.model1.tolocation=this.editdataa.to_locations),
        (this.model1.dcnumber=this.editdataa.dc_number),
        (this.demo1TabIndex = 0);
      this.btn = "Update";
      this.editeddata =true;
      this.total=0
      this.saveddataarray.forEach((ele:any)=>{
      this.total += ele.basic_price || ele.total_value
     
      
    })
    });
  }
  deleteNrgpItem(rw: any, data: any) {
    this.dialog.open(data, {
      width: "400px",
    });
    this.deleteNumber = rw.number;
    this.deletemodel.reason = "";
  }
  deleteFile() {
    let obj = {
      command: "del",
      number: this.deleteNumber,
      reason: this.deletemodel.reason,
      "txn_id":this.txnId,
    };
    this.custservice.deleteNrgpList(obj).subscribe((res: any) => {
      console.log(res);
      if (res && res["status_code"] == "200") {
        this.alertcall.showSuccess("Accepted", res["message"]);
        this.getTransactionId();
        this.dialog.closeAll();
        this.deletemodel.reason = "";
        this.getNRGPlist();
      } else {
        this.alertcall.showSuccess("Accepted", res["message"]);
      }
    });
  }
  printNRGP(data: any) {
    this.getPrintStatus(data);   
    console.log(data.number);

  }
  getPrintStatus(data:any) {
    const postData = new FormData();
    postData.append("command","mat");
    postData.append("document_number", data.number);
    postData.append("document_name", "OutGatePassNRGP" );
    this.custservice.DocumentSign(postData).subscribe((res: any) => {
      if(res&& res.print_status === true){
        this.router.navigate(["/inventory/printoutgatepassrgpnrgp1"], {queryParams: { ogpnumber: data.number, doc_sign:data.doc_sign } });
      }else{
        this.alertcall.showWarning("Warning", "Not Approved,Unable to Print");
      } 
    });
  }
  openfileuploadmodel(data: any, row1: any) {
    this.dialog.open(data, {
      width: "800px",
    });
    if(row1.number){
      this.nrgpnum = row1.number;
    }
    if(row1.document_number){
      this.nrgpnum=row1.document_number;
    }
    this.getexistingfiles();
  }
 
  viewDoc(file: any) {
    // const url = this.imageUrl + "/" + file.file_path;
    const url = file.file_path;
    // console.log(url);  
     window.open(url, "_blank");
  }
  deleterowfile(row: any, data: any) {
    this.deleteid = data.id;
    this.dialogRef = this.dialog.open(row, {
      width: "400px",
    });
  }
  
  onpageevent(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if(!this.searchData){
    this.getNRGPlist();
    }
    else{
      this.search();
    }
  }
  
 
  onChange() {
    console.log("Selected:", this.columnname);
    this.searchData = "";
    if(!this.searchData){
      this.getNRGPlist();
    }
  }
  search1(){
    if(this.searchData.length > 2){
      if(this.paginator){
        this.paginator.firstPage();
      }
      this.search();
    }else if (!this.searchData) {
      this.getNRGPlist();
      this.columnname = "";
    }
  }
  search() {
    this.pageNumbers = [];
    console.log(this.searchData,"hhhhhhhhhhhhhh");
    if (this.searchData.length > 2 && this.columnname) {
      let obj = {
        command: "lst",
        field: this.columnname,
        key: this.searchData,
        lmt: this.pageSize,
        pid: this.pageIndex,
      };
      this.custservice.getNRGPsearchData(obj).subscribe((res: any) => {
        this.reaData = false;
        this.totalRecords = res?.count;
        this.dataSourcemain.data = res.data;
        for (let i = 1; i <= Math.ceil(this.totalRecords / this.pageSize); i++) {
          this.pageNumbers.push(i);
        }
        this.goTo=this.pageIndex
        if (res.data.length == 0) {
          this.reaData = true;
        }
      });
    } else if (!this.searchData) {
      this.getNRGPlist();
      this.columnname = "";
    }
  }
  


  getSMSdata() {
    let obj = {
      material_code: this.materialCODE,
      command: "sms",
    };
    this.custservice.getsmsdata(obj).subscribe((res: any) => {
      console.log(res);
      if (res && res["status_code"] == "200") {
        console.log(res);

        this.smsdata = res.message;
        this.smsdata1=res.storage_locations;
      } else {
        this.smsdata = "";
        this.smsdata1="";
        this.alertcall.showWarning("Warning", res["message"]);
      }
    });
  }
  getPurpose(ev:any){
  this.selectedPurpose= ev.target.value
  if(this.selectedPurpose.length>2){
    this.getPurposeData()
  }if(!this.selectedPurpose){
    this.getPurposeData()
  }
  }
  getNRGPQTY(){
    this.model.basic_price= Number(this.model.qty)*Number(this.model.unit_price)
    this.model1.materialvalue=Number(this.model.qty)*Number(this.model.unit_price)

  } 
  getNRGPQTYedit(){
    this.model2.basic_price= Number(this.model2.qty)*Number(this.model2.unit_price)
    this.model1.materialvalue==Number(this.model.qty)*Number(this.model.unit_price)
  }

  getPurposeData(){
    let obj={
"command":"pps",
"key":this.selectedPurpose?this.selectedPurpose:""
    }
    this.custservice.getPurposeNRGP(obj).subscribe((res:any)=>{
if(res){
  this.purposeData= res.data
}
    })
  }

    //rgp modal
    openpurchaseordermodel(data: any) {
      this.dialog.open(data, {
        width: '600px'
      })
      this.rgpnumber = "";
    }

    getpurchaseorderDATA(ev: any) {
      console.log(ev.target.value);
      this.selectedrgpnumber = ev.target.value
      if (this.selectedrgpnumber.length > 2) {
        this.getpoData()
      }
      if (!this.selectedrgpnumber) {
        this.getpoData()
      }
    }
    getpoData() {
      let obj = {
        "command": "rgp",
        // "lmt": 100000,
        // "pid": 1,
        "key": this.rgpnumber
      }
      this.custservice.getOutGatePassNrgp(obj).subscribe((res: any) => {
        this.rgpdata = res.data
      })
    }

    submitpono(fr:any){
      let obj={
      "command": "set",
       "document_number": this.rgpnumber,
      // "field":"number"
      }
      this.custservice.getOutGatePassNrgp(obj).subscribe((res:any)=>{
      // this.poiddata=res.data
      this.dialog.closeAll();
      this.editdataa = res.data[0];
      this.saveddataarray = res.data;
      this.dataSource.data = this.saveddataarray;
        (this.model1.companyname = this.editdataa.consignee_name),
        // (this.model1.tocompanyname = this.editdataa.company_name),
        (this.model1.reqnumber = this.editdataa.number),
        (this.model1.reqdate = moment(this.editdataa.date).format(
          "YYYY-MM-DD"
        )),
        (this.model1.cosignee = this.editdataa.company_name),
        (this.model1.location = this.editdataa.to_locations),
        (this.model1.tolocation=this.editdataa.locations),
        (this.model1.consignee_address=this.editdataa.consignee_address),
        (this.total = this.editdataa.total_value)
      });
    }

    getdcDATA(ev: any) {
      console.log(ev.target.value);
      this.selecteddcnumber = ev.target.value
      if (this.selecteddcnumber.length > 2) {
        this.getdcData()
      }
      if (!this.selecteddcnumber) {
        this.getdcData()
      }
    }
    getdcData() {
      let obj = {
         "command":"dcn",
        // "command": "set",
        // "lmt" : 100000,
        // "pid" : 1 ,
        "key": this.dcnumber
      }
      this.custservice.getOutGatePassNrgp(obj).subscribe((res: any) => {
        this.DCDATA = res.data
  
      })
    }
  
    deliveryChallanData(data: any) {
       this.DCID = []
      this.dialog.open(data, {
        width: '600px'
      })
      this.getdcData()
    }
  
    submitdcno(fr: any) {
      let obj = {
        "command": "mat",
        "key": this.dcnumber,
        field: "number",
      }
      this.custservice.addDeliveryChallan(obj).subscribe((res: any) => {
        this.viewData=false
        this.dialog.closeAll();
        this.editdataa = res.data[0]
        this.saveddataarray = res.data
        this.dataSource.data = this.saveddataarray
          this.model1.dateee = moment(this.editdataa.date).format("YYYY-MM-DD"),
          this.model1.companyname = this.editdataa.company_name,
          this.model1.cosignee = this.editdataa.consignee_name,
          this.model1.vehnumber = this.editdataa.vehicle_number,
          this.model1.tname = this.editdataa.transporter_name,
          this.model1.lrnumber = this.editdataa.lr_number,
          this.model1.lrdate = moment(this.editdataa.lr_date).format("YYYY-MM-DD"),
          this.model1.dcnumber=this.dcnumber,
          this.model1.consignee_address = this.editdataa.consignee_address
          this.total = this.editdataa.basic_price
          // this.model1.consigneename = this.editdataa.consignee_name,       
        this.demo1TabIndex = 0;
        // this.btn = "Update"
      });
    }
     //approval icon
  getApprovals(data:any, row1: any){
    this.approvalbtn=false
    // console.log(data.number);
    this.dialog.open(data, { // this.getConfirmData();
      width: '600px'
    })

    this.documnet_nodata=row1.number;
  }
  getApprovals1(data:any){
    this.approvalbtn=false
    this.documnet_nodata= data.number
    // console.log(data.number);
    this.dialog.open(data, { // this.getConfirmData();
      width: '600px'
    })
  }
getConfirmData(){
  let obj = {
    command: "add",
    document_name: "OutGatePassNRGP",
    document_number:this.documnet_nodata || this.viewnumber
  };
  this.custservice.AutoDocUpdate2(obj).subscribe((res: any) => {
    if(res && res['status_code']==200){
      this.dialog.closeAll()
      this.alertcall.showSuccess("Accepted", res['message'])
      this.getNRGPlist()
      this.approvalbtn=true
    } else {
      this.dialog.closeAll()
      this.alertcall.showWarning("Error", res['message'])
    }      
  });
}
 //view
//  viewsaveddata(data: any, dialog: any) {
//   this.dialog.open(dialog, {
//     width: "400px"
//   })
//   this.viewnumber = data.number

// }
viewreason(data:any) {
  this.displayedColumns = ["sno","material_code","material_description","unit_of_measurement","storage_location","request_quantity","quantity","unit_price","total_price", "valuation_type", "item_remarks" 
  ];
 if(data.number){
    this.viewnumber = data.number
  }
  if(data.document_number){
    this.viewnumber = data.document_number
  }
  let obj = {
    "command": "mat",
    "key":data.number || data.document_number,
    // "field": "number"
  }
  this.custservice.editNrgpData(obj).subscribe((res: any) => {
    // console.log(res);
    if(res.data.length>0){
      this.viewData=true
      if(data.document_number){
        this.approvalstatus=true
      }else{
        this.approvalstatus=false
      }
      // this.dialog.closeAll();
      this.editdataa = res.data[0];
      this.saveddataarray = res.data;
      this.dataSource.data = this.saveddataarray;
      this.total = 0; 
      this.saveddataarray.forEach((ele: any) => {
        this.total += ele.basic_price || ele.total_value;
      });
      (this.model1.wonumber = this.editdataa.work_order_number!==null?this.editdataa.work_order_number:"NA"),
        (this.model1.date = this.editdataa.date!==null?moment(this.editdataa.date).format("DD-MM-YYYY"):"NA"),
        (this.model1.companyname =this.editdataa.company_name!==""? this.editdataa.company_name:"NA"),
        (this.model1.tocompanyname = this.editdataa.to_company_name!==""?this.editdataa.to_company_name:"NA"),
        // this.model1.contractorname=this.editdataa.to_company_name,
        // this.model1.givername=this.editdataa.to_company_name,
        (this.model1.reqnumber = this.editdataa.request_number!==null?this.editdataa.request_number:"NA"),
        (this.model1.reqdate = this.editdataa.request_date!==null?moment(this.editdataa.request_date).format(
          "DD-MM-YYYY"
        ):"NA"),
        (this.model1.receivername = this.editdataa.receiver_name!==null?this.editdataa.receiver_name:"NA"),
        // (this.model1.ttype = this.editdataa.Transfer_type),
        (this.model1.expectedredate = this.editdataa.expected_return_date!==null? moment(
          this.editdataa.expected_return_date
        ).format("DD-MM-YYYY"):"NA"),
        (this.model1.vehnumber = this.editdataa.vehicle_number!==null?this.editdataa.vehicle_number:"NA"),
        (this.model1.tname = this.editdataa.transporter_name!==""?this.editdataa.transporter_name:"NA"),
        (this.model1.lrnumber = this.editdataa.lr_number!==null?this.editdataa.lr_number:"NA"),
        (this.model1.lrdate = this.editdataa.lr_date!==null?moment(this.editdataa.lr_date).format(
          "DD-MM-YYYY"
        ):"NA"),
        (this.model1.cmts = this.editdataa.comments!==null?this.editdataa.comments:"NA"),
        (this.model1.cosignee = this.editdataa.consignee_name!==""?this.editdataa.consignee_name:"NA"),
        (this.model1.consignee_address = this.editdataa.consignee_address!==""?this.editdataa.consignee_address:"NA"),
        (this.model1.purpose = this.editdataa.purpose!==""?this.editdataa.purpose:"NA"),
        (this.model1.location = this.editdataa.locations!==""?this.editdataa.locations:"NA"),
        (this.model1.order_no=this.editdataa.order_number!==""?this.editdataa.order_number:"NA"),
        (this.model1.order_nodate=this.editdataa.order_date!==null?moment(this.editdataa.order_date).format(
          "DD-MM-YYYY"
        ):"NA"),
        (this.model1.rejection_number=this.editdataa.rejection_number!==null?this.editdataa.rejection_number:"NA"),
        (this.model1.ewaybillno=this.editdataa.eway_bill!==null?this.editdataa.eway_bill:"NA"),
        (this.model1.tolocation=this.editdataa.to_locations!==""?this.editdataa.to_locations:"NA"),
        (this.model1.dcnumber=this.editdataa.dc_number!==null?this.editdataa.dc_number:"NA"),
       

        (this.demo1TabIndex = 0);
        this.docSignature=this.editdataa.doc_sign
        // this.getSignatureData();
    }else{
      this.alertcall.showWarning("Accepted", "No Data Found");
    }
    // this.btn = "Update";
  });
}
BacktoList(){
  if(this.approvalstatus){
    this.demo1TabIndex = 2;
  }else{
    this.demo1TabIndex = 1;
  }
  // this.demo1TabIndex = 1;
  this.viewData=false
  this.approvalbtn=false
  this.displayedColumns = ["action","sno","material_code","material_description","unit_of_measurement","storage_location","request_quantity","quantity","unit_price","total_price", "valuation_type","item_remarks"  
];
}
//signature data
getSignatureData() {
  const postData = new FormData();
  postData.append("command","mat");
  postData.append("document_number", this.viewnumber );
  postData.append("document_name", "OutGatePassNRGP" );
  this.custservice.DocumentSign(postData).subscribe((res: any) => {
    if(res){
      this.SignData = res.data;      
    }
  });
}
signatureStatus(data: any) {
  this.dialog.open(data, {
    width: '600px'
  })
  this.getSignatureData()
}

//on change tab
onTabChanged(ev:any){
  if(this.demo1TabIndex===1){
    // alert(1);
    this.viewData=false
    this.model1={};
    this.saveddataarray = [];
    this.dataSource.data = [];
    this.SNO = [];
    this.matCode = [];
    this.matName = [];
    this.UOM = [];
    this.storageLocation = [];
    this.ReqQTY = [];
    this.qty = [];
    this.itemremarks = [];
    this.unitPrice=[],
    this.basic_price=[]
    this.valutionType=[]
    this.dcnumber=""
    // this.batchno = [];
    this.smsdata = "";
    this.smsdata1="";
    this.total = 0;
    this.router.navigate(['/inventory/nrgp'])
    this.dataSourcemain.sort = this.sort;
  this.paginator.pageIndex = this.pageIndex-1
  this.displayedColumns = ["action","sno","material_code","material_description","unit_of_measurement","storage_location","request_quantity","quantity","unit_price","total_price","valuation_type","item_remarks"  
  ];
    this.pageSize = 10;
  this.pageIndex = 1;
  this.pageIndex1 = 1;
  this.model1.date = moment(new Date()).format("YYYY-MM-DD");
  this.editeddata =false;
  this.btn="Save";
  this.getNRGPlist();
  }else if(this.demo1TabIndex===2){
    // alert(12);
    this.viewData=false
    this.model1={};
          this.saveddataarray = [];
          this.dataSource.data = [];
          this.SNO = [];
          this.matCode = [];
          this.matName = [];
          this.UOM = [];
          this.storageLocation = [];
          this.ReqQTY = [];
          this.qty = [];
          this.itemremarks = [];
          this.unitPrice=[],
          this.basic_price=[]
          this.valutionType=[]
          this.dcnumber=""
          // this.batchno = [];
          this.smsdata = "";
          this.smsdata1="";
          this.total = 0;
          this.getDataap();
    this.router.navigate(['/inventory/nrgp'])
    // this.dataSourcemainlog.sort = this.sort;
    this.displayedColumns = ["action","sno","material_code","material_description","unit_of_measurement","storage_location","request_quantity","quantity","unit_price","total_price", "valuation_type", "item_remarks" 
  ];
  this.pageSizeap = 10;
  this.pageIndexap = 1;
  this.editeddata =false;
  this.btn="Save";
  this.model1.date = moment(new Date()).format("YYYY-MM-DD");
    }
    else if(this.demo1TabIndex===3){
      this.viewData=false
      this.model1={};
            this.saveddataarray = [];
            this.dataSource.data = [];
            this.SNO = [];
            this.matCode = [];
            this.matName = [];
            this.UOM = [];
            this.storageLocation = [];
            this.ReqQTY = [];
            this.qty = [];
            this.itemremarks = [];
            this.unitPrice=[],
            this.basic_price=[]
            this.valutionType=[]
            this.dcnumber=""
            this.smsdata = "";
            this.smsdata1="";
            this.total = 0;
      this.router.navigate(['/inventory/nrgp'])
      this.displayedColumns = ["action","sno","material_code","material_description","unit_of_measurement","storage_location","request_quantity","quantity","unit_price","total_price", "valuation_type", "item_remarks" 
    ];
    this.logpageSize = 10;
    this.pageIndex = 1;
    this.pageIndex1 = 1;
    this.editeddata =false;
    this.btn="Save";
    this.model1.date = moment(new Date()).format("YYYY-MM-DD");
      }

   }
   clearpage1(){
    this.viewData=false
    this.model1={};
          this.saveddataarray = [];
          this.dataSource.data = [];
          this.SNO = [];
          this.matCode = [];
          this.matName = [];
          this.UOM = [];
          this.storageLocation = [];
          this.ReqQTY = [];
          this.qty = [];
          this.itemremarks = [];
          this.unitPrice=[],
          this.basic_price=[]
          this.valutionType=[]
          this.dcnumber=""
          this.rgpnumber = "";
          this.editeddata =false;
          // this.batchno = [];
          // this.selectedfiles=[]
          this.smsdata = "";
          this.smsdata1="";
          this.total = 0;
          this.getTransactionId();
          this.btn = "Save"
          this.displayedColumns = ["action","sno","material_code","material_description","unit_of_measurement","storage_location","request_quantity","quantity","unit_price","total_price","valuation_type","item_remarks"  
  ];
  this.model1.date = moment(new Date()).format("YYYY-MM-DD");
   }

      openNewFileUploadModal(data: any) {
        this.dialog.open(data, {
          width: '800px'
        })
        if(this.editednumber){
          this.nrgpnum = this.editednumber;
        }
        this.getexistingfiles();
      }
    
      deleteFileItem(index:any){
        this.selectedfiles.splice(index,1)
       }
       trimInput(){
        this.dcnumber = this.dcnumber.trim();
        this.rgpnumber = this.rgpnumber.trim();
        this.model1.cosignee = this.model1.cosignee.trim();
        this.model1.consignee_address = this.model1.consignee_address.trim();
        this.model1.order_no = this.model1.order_no.trim();
        this.model1.tname = this.model1.tname.trim();
        this.model1.vehnumber = this.model1.vehnumber.trim();
        this.model1.rejection_number = this.model1.rejection_number.trim();
        this.model1.lrnumber = this.model1.lrnumber.trim();
        this.model1.ewaybillno = this.model1.ewaybillno.trim();
        this.model1.location = this.model1.location.trim();
        this.model1.tolocation = this.model1.tolocation.trim();
        this.model1.purpose = this.model1.purpose.trim();
        this.model1.reqnumber = this.model1.reqnumber.trim();
        this.model1.receivername = this.model1.receivername.trim();
        this.model1.dcnumber = this.model1.dcnumber.trim();
        this.model1.cmts = this.model1.cmts.trim();
       }

       uploadprfiles(fileInput: any) {
        if (
          fileInput &&
          fileInput.target &&
          fileInput.target.files &&
          fileInput.target.files.length > 0
        ) {
          this.fileUploadUrlspr = fileInput.target.files;
          // for (const file of this.fileUploadUrlspr) {
          //   this.filenamearray1.push(file.name);
          //   this.selectedfiles.push(file);
          // }
          for (const file of this.fileUploadUrlspr) {
            const existingFile = this.selectedfiles.find(selectedFile => selectedFile.name === file.name);
            if(this.isFileSelected(file.name) && existingFile){
              this.alertcall.showWarning('Alert', 'Some files are already selected');
            }else if(this.fileUploadUrlspr.length == 1){
              this.alertcall.showSuccess("Accepted","File is Uploaded Successfully")
              this.filenamearray.push(file.name)
              this.selectedfiles.push(file)
            }else{
              this.alertcall.showSuccess("Accepted","Files are Uploaded Successfully")
              this.filenamearray.push(file.name)
              this.selectedfiles.push(file)
            }
          }
          fileInput.target.value = '';
        }
      }
      isFileSelected(selectedFilePath: string): boolean {
        return this.selectedfiles.some((file:any) => file.name == selectedFilePath);
      }
      uploadedselctedfiles() {
        const postData = new FormData();
        postData.append("document_type", "Out Gate PassNRGP");
        postData.append("document_number", this.resultNrgpnumber);
        for (const file of this.selectedfiles) {
          postData.append("doc", file);
        }
    
        this.custservice.addfileupload(postData).subscribe((res: any) => {
          if (res && res["status_code"] == "200") {
            this.fileUploadUrlspr = [];
            this.selectedfiles = [];
            this.filenamearray1 = [];
          } else {
          }
        });
      }

      getexistingfiles() {
        let params = new HttpParams();
        params = new HttpParams()
          .set("document_number", this.nrgpnum)
          .set("document_type", "Out Gate PassNRGP");
       
        this.custservice.getexistingfies(params).subscribe((res: any) => {
          if (res && res["status_code"] == "200") {
            this.filedata = res.data;
            this.createNewFile.fileName = "";
          } else {
            this.filedata = [];
            console.log(this.filedata);
          }
        });
      }

      deleteexistingfile() {
        let params = new HttpParams();
        params = new HttpParams()
          .set("document_number", this.nrgpnum)
          .set("document_type", "Out Gate PassNRGP")
          .set("id", this.deleteid);
        this.custservice.deletefiles(params).subscribe((res: any) => {
          if (res && res["status_code"] == "200") {
            this.alertcall.showSuccess("Accepted", "File Deleted Successfully");
            this.getexistingfiles();
            this.dialogRef.close();
          } else {
            this.alertcall.showWarning("Error", res["message"]);
          }
        });
      }
      uploadWbsFile(fileInput: any) {
        if (
          fileInput &&
          fileInput.target &&
          fileInput.target.files &&
          fileInput.target.files.length > 0
        ) {
          this.fileUploadUrls = fileInput.target.files;
          this.createNewFile.fileName = fileInput.target.files[0].name;
          for (const file of this.fileUploadUrls) {
            this.filenamearray.push(file.name);
          }
        }
        console.log(this.fileUploadUrls,"");
        const postData = new FormData();
        postData.append("document_type", "Out Gate PassNRGP");
        postData.append("document_number", this.nrgpnum);
        for (const file of this.fileUploadUrls){
          // if(this.filedata && this.filedata.length >  0 && this.isFileSelected1(file.name)){
          //   this.alertcall.showWarning('Alert', 'Some files are already uploaded');
          // }else{
            postData.append("doc", file)
        }
        this.custservice.addfileupload(postData).subscribe((res: any) => {
          
          if (res['status_code'] == '200' && this.fileUploadUrls.length == 1) {
            this.alertcall.showSuccess("Accepted", "File is Uploaded Successfully")
            this.getexistingfiles()
            this.fileUploadUrls = []
          }else if (res['status_code'] == '200' && this.fileUploadUrls.length > 1) {
            this.alertcall.showSuccess("Accepted", "Files are Uploaded Successfully")
            this.getexistingfiles()
            this.fileUploadUrls = []
          }
           else {
            this.alertcall.showWarning("Error", res['message'])
          }
          fileInput.target.value = '';
        })
      // }
        // }
      }
      isFileSelected1(selectedFilePath: string): boolean {
        return this.filedata.some((file:any) => file.file_name == selectedFilePath);
      }
      gamRestrictfields(){
        let origin = window.location.hostname;
        var companyName:any = origin.split(".", 1)[0];
        console.log(companyName,origin);
        if(companyName == 'gam'){
          this.gamCompanyfields = true
        }else{
          this.gamCompanyfields = false
        }
        
       }
}
