<div class="dialog-container" [ngClass]="{'small-dialog': data.status === 'APPROVE'}">
    <h3 mat-dialog-title>{{ data.status }} AUDIT {{ data.name }}</h3>
    
    <mat-dialog-content *ngIf="data.status === 'REJECT'">
      <p>Please provide the reason....</p>
      <mat-form-field class="matField field" appearance="fill">
        <mat-label>Reason</mat-label>
        <input matInput [(ngModel)]="data.reason">
      </mat-form-field>
    </mat-dialog-content>   
    
    <mat-dialog-actions class="dialog-actions">
      <button mat-raised-button (click)="onNoClick()">No</button>
      <button mat-raised-button [color]="data.status === 'APPROVE' ? 'primary' : 'warn'"
              (click)="onYesClick(data.reason)" [mat-dialog-close]="data.animal" 
              cdkFocusInitial [disabled]="data.status === 'REJECT' && data.reason.length === 0">
        Yes
      </button>
    </mat-dialog-actions>
  </div>
  