import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CustomerService } from "src/app/services/customer.service";
import * as moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NoopScrollStrategy } from "@angular/cdk/overlay";
import { Overlay } from "@angular/cdk/overlay";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { AlertCallsService } from "src/app/auth/alert-calls.service";
import { I } from "@angular/cdk/keycodes";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatAccordion } from "@angular/material/expansion";
@Component({
  selector: 'app-storagelocation',
  templateUrl: './storagelocation.component.html',
  styleUrls: ['./storagelocation.component.scss']
})
export class StoragelocationComponent implements OnInit {
  demo1TabIndex: any = 0;
  companyData1: any;
  selectedcompname: any;
  selectedmaterial: any;
  selectematcode: any;
  masterData: any;
  selectedmatcode: any;
  smsdata: any[] = [];
  companyName: any;
  selectCompany: any;
  selectedmaterialName: any;
  avalstockqty: number;
  storagelocations: any;
  selectedmatName: any;
  old_location: any;
  newstoragelocation: any;
  noData:boolean = false;
  constructor(private custservice: CustomerService,
    private snackbar: MatSnackBar,
    public overlay: Overlay,
    private dialog: MatDialog,
    private alertcall: AlertCallsService,
    private router: Router,
    private route: ActivatedRoute,
    private injector: Injector) { }

  ngOnInit(): void {
    this.getcompanydata();
    this.getmasterdata();
  }


  //company list
  getcompanydata() {
    let obj = {
      command: "lst",
      lmt: 100,
      pid: 1,
      key: this.selectedcompname || ""
    };
    this.custservice.usercompanyData(obj).subscribe((res: any) => {
      this.companyData1 = res.data;
    });
  }
  SearchCompanyData(ev: any) {
    console.log(ev.target.value);
    this.selectedcompname = ev.target.value;
    if (this.selectedcompname.length > 2) {
      this.getcompanydata();
    }
    if (!this.selectedcompname) {
      this.getcompanydata();
    }
  }
  //material
  selectedmastergroup() {
    this.masterData.forEach((el: any) => {
      if (this.selectematcode == el.system_reference_1) {
        this.selectedmatcode = el.code;
        this.selectedmatName = el.description
        this.smsdata = [];
        this.noData = false;
      }
    });
  }
  getmaterialDATA(ev: any) {
    this.selectedmaterial = ev.target.value
    if (this.selectedmaterial.length > 2) {
      this.getmasterdata()
    }
    if (!this.selectedmaterial) {
      this.getmasterdata()
    }
  }
  getmasterdata() {
    let obj = {
      "command": 'lst',
      "lmt": 100,
      "pid": 1,
      "key": this.selectedmaterial
    }
    this.custservice.getmaterialmasterdata(obj).subscribe((res: any) => {
      this.masterData = res.data
    })
  }

  //search
  saveformdata(fr: any) {
    let obj = {
      material_code: this.selectedmatcode,
      company_name: this.companyName,
      command: "sms",
    };
    this.custservice.getsmsdata(obj).subscribe((res: any) => {
      if (res && res["status_code"] == "200") {
        this.smsdata = res.message;
        this.noData = true;
        this.storagelocations = res.storage_locations
        this.avalstockqty = 0
        this.smsdata.forEach((ele: any) => {
          this.avalstockqty += ele.quantity
        })
        console.log(this.smsdata);
        // fr.resetForm();
      } else {
        this.alertcall.showWarning("Warning", res["message"]);
      }
    });
  }
  getsmsdata() {
    let obj = {
      material_code: this.selectedmatcode,
      company_name: this.companyName,
      command: "sms",
    };
    this.custservice.getsmsdata(obj).subscribe((res: any) => {
      if (res && res["status_code"] == "200") {
        this.smsdata = res.message;
        this.storagelocations = res.storage_locations
        this.avalstockqty = 0
        this.smsdata.forEach((ele: any) => {
          this.avalstockqty += ele.quantity
        })
        console.log(this.smsdata);
        // fr.resetForm();
      } else {
        this.alertcall.showWarning("Warning", res["message"]);
      }
    });
  }
  //clearForm
  clearForm() {
    (<HTMLFormElement>document.getElementById("slform")).reset();
    this.smsdata = []
    this.selectedmatcode = ""
    this.companyName = ""
    this.noData = false;
  }

  //storage popup
  editgidata(item: any, dialog: any) {
    this.newstoragelocation=""
    this.old_location = item.storage_location
    this.dialog.open(dialog, {
      width: "400px",
    });
  }
  saveeditreason() {
    let obj = {
      material_code: this.selectedmatcode,
      material_description: this.selectedmatName,
      company_name: this.companyName,
      old_location: this.old_location,
      new_location: this.newstoragelocation,
      command: "edt",
    }
    this.custservice.storageLocation(obj).subscribe((res: any) => {
      if (res && res['status_code'] == 200) {
        this.alertcall.showSuccess('Accepted', res['message']);
        this.dialog.closeAll();
        this.getsmsdata();
      } else {
        this.alertcall.showWarning("Warning", res["message"]);
      }
      console.log(res);

    })
  }
}
