<div class="material-tab">
    <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms" [(selectedIndex)]="demo1TabIndex"
        (selectedTabChange)="onTabChanged($event)">
        <mat-tab>
            <ng-template mat-tab-label>Excess/Shortage/Damage Material Receipt Note</ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <div class="row mb-3">

                            <span *ngIf="viewData" class="col-md-0 ml-2 mb-2 ">
                                <button mat-raised-button type="button" (click)="BacktoList()"
                                 class="savebutton back">Back</button>
                            </span>

                            <div *ngIf="!viewData" class="col-md-0  ml-2 mb-2">
                                <button *ngIf="!viewData" mat-raised-button class="savebutton button1 save1" color="secondary"
                                    (click)="saveData()"
                                     [disabled]="savedisable()"
                                    >{{btn}}</button>
                            </div>
                            <div *ngIf="!viewData && EditData" class="col-md-0  ml-2 mb-2">
                                <button mat-raised-button class="savebutton button1 addnew" color="secondary"
                                    (click)=resetData() >
                                    Add New
                                </button>
                            </div>
                            
                            <div *ngIf="!viewData" class="col-md-0 ml-2 mb-2">
                                <button mat-raised-button color="secondary" type="button"
                                    class="attachfile savebutton button1"
                                    (click)="openNewFileUploadModal(newFileUploadModal)">Attach File&nbsp;
                                </button>

                            </div>
                            <div *ngIf="!viewData && EditData" class="col-md-0 ml-2 ">
                                <button type="button" mat-raised-button class="ml-1 savebutton button1 selectdmr"
                                     color="secondary"
                                    (click)="openpurchaseordermodel(pomodel)">
                                    Select DMR </button>
                            </div>
  
                            <div *ngIf="!viewData && txnId" class="col mt-1 text-right">
                                <p *ngIf="!viewData && txnId">Transaction Id: {{txnId}}</p>
                            </div>

                            <div *ngIf="viewData && showViewData.esdn_number" class="col mt-1 text-right">
                                <p *ngIf="viewData && showViewData.esdn_number">ESDN Number :
                                    {{showViewData.esdn_number}}</p>
                            </div>
                        </div>


                        <div class="row mb-3 mt-2">
                            <div class="col-md-3">
                                <label>DMR Number</label>
                                <!-- <p *ngIf="viewData && showViewData.dmr_number"> {{showViewData.dmr_number}}</p> -->
                                <input *ngIf="viewData && showViewData.dmr_number" type="text" class="inputstyles"
                                    name="dmr_number" [(ngModel)]="showViewData.dmr_number" required readonly>
                                <input *ngIf="!viewData" type="text" placeholder="Enter DMR Number" class="inputstyles"
                                    name="number" [(ngModel)]=" selectedDMRData.number" required (input)="trimInput()" readonly>
                                
                            </div>
                            
                            <div class="col-md-3">
                                <label>Date<span class="mandetory">*</span></label>
                                <!-- <p *ngIf="viewData && showViewData.date">{{showViewData.date | date: 'dd-MM-yyyy'}}</p> -->
                                <input *ngIf="viewData && showViewData.date" type="text" class="inputstyles"
                                    name="date" [(ngModel)]="showViewData.date" required readonly>
                                    <input *ngIf="!viewData" type="date" 
                                    class="inputstyles date1" name="date1" [(ngModel)]="selectedDMRData.date1"
                                    [max]="getCurrentDate()">
                            </div>
                            
                            <div class="col-md-6">
                                <label>Vendor Name</label>
                                <!-- <p *ngIf="viewData && showViewData.vendor_name"> {{showViewData.vendor_name}}</p> -->
                                <input *ngIf="viewData && showViewData.vendor_name" type="text" class="inputstyles"
                                    name="vendor_name" [(ngModel)]="showViewData.vendor_name" required readonly>
                                <!-- <input *ngIf="!viewData && EditData" type="text" placeholder="Enter Vendor Name" class="inputstyles"
                                    name="vendor_name" [(ngModel)]=" selectedDMRData.vendor_name" (input)="trimInput()"/> -->
                                <input *ngIf="!viewData" type="text" placeholder="Enter Vendor Name" class="inputstyles"
                                    name="vendor_name" [(ngModel)]=" selectedDMRData.vendor_name" (input)="trimInput()" readonly/>
                            </div>
                        </div>
                        <div class="row mb-3 mt-2">
                            <div class="col-md-3">
                                <label>DMR Date</label>
                                <!-- <p *ngIf="viewData && showViewData.dmr_date"> {{showViewData.dmr_date |
                                    date:'dd-MM-yyyy'}}</p> -->
                                    <input *ngIf="viewData && showViewData.dmr_date" type="text" class="inputstyles"
                                    name="dmr_date" [(ngModel)]="showViewData.dmr_date" required readonly>
                                <input *ngIf="!viewData" type="date" readonly
                                    class="inputstyles date1" name="date" [(ngModel)]="selectedDMRData.date" [max]="today"
                                    dateFormat="yyyy/mm/dd" [max]="getCurrentDate()">
                            </div>
                            <div class="col-md-3">
                                <label>PO Number</label>
                                <!-- <p *ngIf="viewData && showViewData.purchase_order_number">
                                    {{showViewData.purchase_order_number}}</p> -->
                                    <input *ngIf="viewData && showViewData.purchase_order_number" type="text" class="inputstyles"
                                    name="purchase_order_number" [(ngModel)]="showViewData.purchase_order_number" required readonly>
                                <input *ngIf="!viewData" type="text" placeholder="Enter PO Number" class="inputstyles" readonly
                                    name="purchase_order_number" [(ngModel)]=" selectedDMRData.purchase_order_number" (input)="trimInput()">
                            </div>
                            <div class="col-md-3">
                                <label>Transporter Name</label>
                                <!-- <p *ngIf="viewData && showViewData.transporter_name"> {{showViewData.transporter_name}}</p> -->
                                <input *ngIf="viewData && showViewData.transporter_name" type="text" class="inputstyles"
                                name="transporter_name" [(ngModel)]="showViewData.transporter_name " required readonly>
                                <input *ngIf="!viewData" type="text" placeholder="Enter Transporter Name"
                                    class="inputstyles" name="transporter_name" readonly
                                    [(ngModel)]=" selectedDMRData.transporter_name" (input)="trimInput()">
                            </div>
                            <div class="col-md-3">
                                <label>Vehicle Number</label>
                                <!-- <p *ngIf="viewData && showViewData.vehicle_number"> {{showViewData.vehicle_number}}</p> -->
                                <input *ngIf="viewData && showViewData.vehicle_number" type="text" class="inputstyles"
                                name="vehicle_number" [(ngModel)]="showViewData.vehicle_number" required readonly>
                                <input *ngIf="!viewData" type="text" placeholder="Enter Vehicle Number" readonly
                                    class="inputstyles" [(ngModel)]="selectedDMRData.vehicle_number" (input)="trimInput()"
                                    name="vehicle_number" maxlength="11" pattern="[A-Z0-9]*" (ngModelChange)="selectedDMRData.vehicle_number = $event.toUpperCase()"  (keypress)="keyPressAlphaNumeric($event)">
                            </div>
                            </div>
                        <div class="row mb-3 mt-2">
                            <div class="col-md-3">
                                <label>LR Number</label>
                                <!-- <p *ngIf="viewData && showViewData.lr_no"> {{showViewData.lr_no}}</p> -->
                                <input *ngIf="viewData && showViewData.lr_no" type="text" class="inputstyles"
                                name="lr_no" [(ngModel)]="showViewData.lr_no" required readonly>
                                <input *ngIf="!viewData" type="text" placeholder="Enter LR Number" class="inputstyles" (input)="trimInput()"
                                    [(ngModel)]=" selectedDMRData.lr_number" name="lr_number" readonly>
                            </div>
                        
                            <div class="col-md-3">
                                <label>LR Date</label>
                                <!-- <p *ngIf="viewData && showViewData.lr_date"> {{showViewData.lr_date |
                                    date:'dd-MM-yyyy'}}</p> -->
                                <input *ngIf="viewData && showViewData.lr_date" type="text" class="inputstyles"
                                    name="lr_date" [(ngModel)]="showViewData.lr_date" required readonly>
                                <input *ngIf="!viewData" type="date" 
                                    class="inputstyles date1" name="lr_date" [(ngModel)]="selectedDMRData.lr_date"
                                    [max]="today" dateFormat="yyyy/mm/dd" [max]="getCurrentDate()" readonly>
                            </div>
                            <div class="col-md-3">
                                <label>Buyer Name<span class="mandetory">*</span></label>
                                <!-- <p *ngIf="viewData && showViewData.buyer_name"> {{showViewData.buyer_name}}</p> -->
                                <input *ngIf="viewData && showViewData.buyer_name" type="text" class="inputstyles"
                                name="buyer_name" [(ngModel)]="showViewData.buyer_name" required readonly>
                                <input *ngIf="!viewData" type="text" placeholder="Enter Buyer Name" class="inputstyles" (input)="trimInput()"
                                    [(ngModel)]="selectedDMRData.buyer_name">
                            </div>
                            <div class="col-md-3">
                                <label>Company Name</label>
                                <!-- <p *ngIf="viewData && showViewData.company_name"> {{showViewData.company_name}}</p> -->
                                <input *ngIf="viewData && showViewData.company_name" type="text" class="inputstyles"
                                name="company_name" [(ngModel)]="showViewData.company_name" required readonly>
                                <input *ngIf="!viewData" type="text" placeholder="Enter Company Name" (input)="trimInput()"
                                    class="inputstyles" name="company_name" [(ngModel)]="selectedDMRData.company_name" readonly>
                            </div>
                        </div>
                        <div class="row mb-3 mt-2">
                            <div class="col-md-3">
                                <label>User Department<span class="mandetory">*</span></label>
                                <!-- <p *ngIf="viewData && showViewData.user_department"> {{showViewData.user_department}} </p> -->
                                <input *ngIf="viewData && showViewData.user_department" type="text" class="inputstyles"
                                name="user_department" [(ngModel)]="showViewData.user_department" required readonly>
                                <input *ngIf="!viewData" type="text" placeholder="Enter User Department" (input)="trimInput()"
                                    [(ngModel)]="selectedDMRData.user_department" class="inputstyles">
                            </div>
                        
                            <div class="col-md-3">
                                <label>Status<span class="mandetory">*</span></label>
                                <!-- <p *ngIf="viewData && showViewData.status"> {{showViewData.status}}</p> -->
                                <input *ngIf="viewData && showViewData.status" type="text" class="inputstyles"
                                name="status" [(ngModel)]="showViewData.status" required readonly>
                                <select *ngIf="!viewData" class="dropdownstyles" [(ngModel)]="selectedDMRData.status">
                                    <option [value]="undefine" selected disabled>Status</option>

                                    <option>Open</option>
                                    <option>Close</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-3 mt-2">
                            <div class="col-md-6">
                                <label>Discrepancy open note<span class="mandetory">*</span></label>
                                <!-- <p *ngIf="viewData && showViewData.descripency_open_note">
                                    {{showViewData.descripency_open_note}} </p> -->
                                    <input *ngIf="viewData && showViewData.descripency_open_note" type="text" class="inputstyles"
                                    name="descripency_open_note" [(ngModel)]="showViewData.descripency_open_note" required readonly>
                                <input class="input1" placeholder="Discrepancy open note:" *ngIf="!viewData"
                                    [(ngModel)]="selectedDMRData.descripency_open_note" (input)="trimInput()"/>

                            </div>
                            <div class="col-md-6">
                                <label>Action Taken<span class="mandetory">*</span></label>
                                <!-- <p *ngIf="viewData && showViewData.action_token">{{showViewData.action_token}} </p> -->
                                <input *ngIf="viewData && showViewData.action_token" type="text" class="inputstyles"
                                name="action_token" [(ngModel)]="showViewData.action_token" required readonly>
                                <input class="input1" placeholder="Action Taken:" *ngIf="!viewData"
                                    [(ngModel)]="selectedDMRData.action_token" (input)="trimInput()"/>
                            </div>
                        </div>
                        
                        <div class="row " >
                            <div class="col-12">
                                <mat-accordion *ngIf="editData">
                                    <mat-expansion-panel [expanded]="editpanelOpenState"
                                        (opened)="editpanelOpenState = true" (closed)="editpanelOpenState = false">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title class="edit1" >
                                                Edit Items
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <div class="mt-3" *ngIf="editData">
                                            <form #myaddeditform="ngForm" (ngSubmit)="saveaddededitdata(myaddeditform)">
                                                <div class="row">
                                                    <div class="col-md-2">
                                                        <label>Material Code/Name<span
                                                                class="mandetory">*</span></label>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <mat-form-field class="example-full-width" appearance="none">
                                                            <input type="text" placeholder="Search Material Code/Name "
                                                                aria-label="Number" matInput 
                                                                class="inputdropdown dropdn" name="matcode"
                                                                [(ngModel)]="model2.matcode"
                                                                readonly
                                                                [matAutocomplete]="auto6" />

                                                            <mat-autocomplete autoActiveFirstOption
                                                                #auto6="matAutocomplete"
                                                                (optionSelected)="selectedmastereditgroup()">

                                                                <mat-option *ngFor="let item of masterData"
                                                                    [value]="item.system_reference_1">
                                                                    {{item.system_reference_1}}</mat-option>
                                                            </mat-autocomplete>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row mt-3" >                                                    
                                                    <div class="col-md-4">
                                                        <label>Unit Of Measurement<span
                                                                class="mandetory">*</span></label>
                                                        <input type="text" class="inputstyles" name="uom"
                                                            required [(ngModel)]="model2.uom" readonly>
                                                       
                                                        <div  class="validation1"
                                                            *ngIf="myaddeditform.controls['uom'].errors?.required && (myaddeditform.controls['uom'].dirty || myaddeditform.submitted)">
                                                            Unit Of Measurement is Required
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4" >
                                                        <label>Challan Quantity<span class="mandetory">*</span></label>
                                                        <input type="text" class="inputstyles" name="matQuantity"
                                                            required [(ngModel)]="model2.matQuantity"
                                                            (keypress)="keyPressNumbers($event)"
                                                            (keyup)="Invoiceeditvalue($event)">
                                                       
                                                        <div class="validation1"
                                                            *ngIf="myaddeditform.controls['matQuantity'].errors?.required && (myaddeditform.controls['matQuantity'].dirty || myaddeditform.submitted)">
                                                            Challan Quantity is Required
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Received Quantity<span class="mandetory">*</span></label>
                                                        <input type="text" class="inputstyles" name="received_quantity"
                                                            [(ngModel)]="model2.received_quantity" required
                                                            (keypress)="keyPressNumbers($event)"
                                                            (keyup)="Receivevalueedit($event)">
                                                        <div class="validation1"
                                                            *ngIf="myaddeditform.controls['received_quantity'].errors?.required && (myaddeditform.controls['received_quantity'].dirty || myaddeditform.submitted)">
                                                            Received Quantity is Required
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4 mt-1" >
                                                        <label>Remarks</label>
                                                        <input type="text" class="inputstyles" name="remark"
                                                            [(ngModel)]="model2.remark" required>
                                                            
                                                        <div  class="validation1"
                                                            *ngIf="myaddeditform.controls['remark'].errors?.required && (myaddeditform.controls['remark'].dirty || myaddeditform.submitted)">
                                                            Remarks is Required
                                                        </div>
                                                    </div>
                                                </div><br>
                                                <div class="col-md-12 text-center">
                                                    <button mat-raised-button class="savebutton1" [disabled]="!model2.uom || !model2.matQuantity || !model2.received_quantity">Update</button>
                                                </div>
                                            </form>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                        <div class="col mt-4 text-left">
                            <p>No of items : {{countdmr}}</p>
                        </div>
                        <div class="row p-t-10 col-12 col-md-12">
                            <div class="table-responsive mat-table-wrapper">
                                <div class="example-table-container" >
                                    <table mat-table [dataSource]="dataSource">
                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Action
                                            </th>
                                            <td mat-cell *matCellDef="let row;let i=index;"
                                                class="text-center">
                                                <span class="d-flex justify-content-center">
                                                    <button mat-icon-button aria-label="Example icon button with a vertical three dot icon"
                                                        (click)="editMaterialData(row,i)&& editpanelOpenState === true;"
                                                        [disabled]="viewData===true">
                                                        <mat-icon class="edit" matTooltip="Edit">edit</mat-icon>
                                                    </button>
                                                    <button mat-icon-button *ngIf="!viewData"
                                                        (click)="deleterow(i)"
                                                        aria-label="Example icon button with a vertical three dot icon">
                                                        <mat-icon class="delete1" matTooltip="Delete">delete</mat-icon>
                                                    </button>
                                                </span>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="sno">
                                            <th mat-header-cell *matHeaderCellDef>S.NO</th>
                                            <td mat-cell *matCellDef="let row;let i=index;">
                                                <p class="tablemargin"> {{i+1}} </p>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="material_code" class="text-center">
                                            <th mat-header-cell *matHeaderCellDef>Material Code
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                <p class="tablemargin"> {{row.material_code}}</p>

                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="material_description">
                                            <th mat-header-cell *matHeaderCellDef >Material
                                                Description
                                            </th>
                                            <td mat-cell *matCellDef="let row"  class="description">
                                                <!-- <p *ngIf="!viewData"> {{ row.material_description}} </p> -->
                                                <p class="tablemargin" *ngIf="!viewData"> {{ row.matrerial_description || row.material_description}} </p>
                                                <p class="tablemargin" *ngIf="viewData">{{row.matrerial_description}} </p>
                                            </td>
                                        </ng-container>


                                        <ng-container matColumnDef="unit_of_measurment">
                                            <th mat-header-cell *matHeaderCellDef>UOM
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                <p class="tablemargin">{{row.unit_of_measurement}} </p>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="challan_quantity">
                                            <th mat-header-cell *matHeaderCellDef>Challan
                                                Quantity
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                <p class="tablemargin" *ngIf="!viewData">{{ row.matQuantity }}</p>
                                                <p class="tablemargin" *ngIf="viewData">{{row.challan_quantity}} </p>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="received_quantity">
                                            <th mat-header-cell *matHeaderCellDef>Received
                                                Quantity</th>
                                            <td mat-cell *matCellDef="let row">
                                                <p class="tablemargin"> {{ row.received_quantity }}</p>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="remarks">
                                            <th mat-header-cell *matHeaderCellDef> Remarks</th>
                                            <td mat-cell *matCellDef="let row">
                                                <p class="tablemargin">{{row.remark}} </p>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <ng-template #pomodel>
                            <div mat-dialog-title class="dialog-title mb-0">
                                <div class="row">
                                    <div class="col-11">
                                        <h2>Select DMR Number</h2>
                                    </div>
                                    <div class="col-1 text-right">
                                        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
                                            <mat-icon class="close1">close</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <form>
                                <div class="row">
                                    <div class="col-md-10 mt-2">
                                        <mat-form-field class="example-full-width1" appearance="outline">
                                            <input type="text" placeholder="Search DMR Number" aria-label="Number" matInput class="inputdropdown dropdwn" name="number" [(ngModel)]="number" (input)="trimInput()" (keyup)="getData()" [matAutocomplete]="auto4" />
                                            <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete">
                                                <ng-container *ngIf="filteredData.length > 0; else noRecords">
                                                    <mat-option *ngFor="let item of filteredData" [value]="item.number">{{item.number}}</mat-option>
                                                </ng-container>
                                                <ng-template #noRecords>
                                                    <mat-option>No records found</mat-option>
                                                </ng-template>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-2 mt-2">
                                        <button mat-raised-button class="savebutton submitBtn" mat-dialog-close (click)="submitData(dmrctrl.value)" [disabled]="!number || filteredData.length === 0">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </ng-template>
                        
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label> List </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <ng-container>
                            <div class="row">
                                <div class="col-md-5 col-lg-4 mt-2 mb-3 d-flex">
                                    <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                                    <select class="dropdownstyles" name="columnname" [(ngModel)]="columnname"
                                        (change)="onChange()">
                                        <option value="" selected disabled>Select Column</option>
                                        <option value="esdn_number">ESDN Number</option>
                                        <option value="dmr_number">DMR Number</option>
                                        <option value="vendor_name">Vendor Name</option>
                                        <option value="po_number">PO Number</option>
                                    </select>
                                </div>
                                <div class="col-md-3 col-lg-3 mt-2 mb-3 ">
                                    <input type="search" class="searchfield" placeholder="Search"
                                        [(ngModel)]="searchData" (input)="searchInputData()">
                                </div>
                                <div class="col-lg-5 col-md-4 mt-2 mb-3 text-right">
                                    <button mat-icon-button value="Refresh" matTooltip="Refresh" (click)="getEsdndata1()"
                                        class="custom-icon-button ml-3">
                                        <span class="material-icons">
                                            refresh
                                        </span>
                                    </button>
                                </div>
                                <div class="col-12 col-md-12">
                                    <div class="table-responsive mat-table-wrapper tableres">
                                        <div class="example-table-container">
                                            <table mat-table [dataSource]="dataSourcemain1" class="table1" matSort>
                                                <ng-container matColumnDef="sno">
                                                    <th mat-header-cell *matHeaderCellDef
                                                        class="text-center">
                                                        S.NO
                                                    </th>
                                                    <td mat-cell *matCellDef="let row; let i = index">
                                                        {{(i+1) + (paginator?.pageIndex *
                                                        paginator?.pageSize)}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="misNumber" class="text-center">
                                                    <th mat-header-cell *matHeaderCellDef 
                                                        class="text-center" mat-sort-header="esdn_number">ESDN Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{ row.esdn_number }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="Date" class="text-center">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="dmr_date">DMR
                                                        Date</th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{ row.dmr_date | date: "dd-MM-yyyy" }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="company_Name" class="text-center">
                                                    <th mat-header-cell *matHeaderCellDef 
                                                        mat-sort-header="dmr_number">
                                                        <!-- Company Name -->
                                                        DMR Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{ row.dmr_number }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="tocompanyname" class="text-center">
                                                    <th mat-header-cell *matHeaderCellDef 
                                                        mat-sort-header="purchase_order_number">
                                                        <!-- To Company Name -->
                                                        PO Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">

                                                        {{ row.purchase_order_number }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="contractor_name" class="text-center">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="vendor_name">
                                                        <!-- Contractor Name -->
                                                        Vendor Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{ row.vendor_name }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="count">
                                                    <th mat-header-cell *matHeaderCellDef 
                                                        mat-sort-header="totalCount">
                                                        <!-- Request Number -->
                                                        Count
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">

                                                        {{ row.totalCount }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="action">
                                                    <th mat-header-cell *matHeaderCellDef 
                                                        class="text-center">
                                                        Action
                                                    </th>
                                                    <td mat-cell *matCellDef="let row" class="text-center">
                                                        <div class="mb-0">
                                                            <mat-icon matTooltip="View" class="view1"                                                                
                                                                (click)="viewreason(row)">remove_red_eye</mat-icon>&nbsp;
                                                            <mat-icon matTooltip="Edit" class="edit"
                                                                (click)="editsaveddata(row, editmodel)">edit</mat-icon>
                                                            <mat-icon matTooltip="Delete" class="delete1"
                                                                (click)="deleteItem(row, deleteItemModel)">delete</mat-icon>&nbsp;
                                                            <mat-icon matTooltip="Print"
                                                                (click)="printesdn(row)">local_printshop</mat-icon>
                                                            <mat-icon matTooltip="Upload"
                                                                (click)="openfileuploadmodel(fileuploadmodel, row)" class="file1"
                                                                >file_upload</mat-icon>
                                                        </div>
                                                    </td>
                                                </ng-container>

                                                <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true">
                                                </tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
                                            </table>
                                            <div *ngIf="reaData == true" class="text-center">
                                                <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                                    Loading...
                                                </h4>
                                                <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                                                    No Records Found
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="totalRecords > 0">
                                            <mat-paginator [length]="totalRecords" [pageSize]="pageSize"
                                                [pageSizeOptions]="[5, 10, 50, 100, 500, 1000]" #paginator
                                                (page)="onpageevent($event)">
                                            </mat-paginator>
                                            </ng-container>
                                        </div>
                                        <!-- <div *ngIf="reaData == true" class="text-center">
                                            <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                                Loading...
                                            </h4>
                                            <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                                                No Records Found
                                            </h4>
                                        </div> -->
                                    <!-- </div>
                                </div> -->
                            </div>
                        </ng-container>
                        <ng-template #editmodel>
                            <div mat-dialog-title>
                                <div class="row">
                                    <div class="col-12 text-right">
                                    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon"
                                    (click)="closemodel()">
                                    <mat-icon class="close1">close</mat-icon>
                                </button>
                                    </div>
                                </div>
                            </div>
                            <div mat-dialog-content>
                                <div class="row">
                                    <div class="col-md-2">
                                        <label class="reason1">Reason</label>
                                    </div>
                                    <div class="col-md-8">
                                        <!-- <input type="text" [(ngModel)]="editModelReason.reason" (input)="trimInput()"
                                            placeholder="Please Enter Reason" class="inputstyles reason2" name="reason"
                                            > -->
                                            <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                                            [ngModel]="editModelReason.reason" (ngModelChange)="editModelReason.reason=$event.trim()" 
                                            (input)="editModelReason.reason=$event.target.value.trim()">
                                    </div>
                                </div>
                            </div><br>
                            <div mat-dialog-actions class="justify-content-center">
                                <button mat-button mat-dialog-close cdkFocusInitial class="matButton sbt"
                                    (click)="saveeditreason()" [disabled]="!editModelReason.reason"
                                    >Submit</button>
                            </div>
                        </ng-template>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                Activity Log
            </ng-template>
            <ng-template matTabContent>
                <app-activitylog [activitylogfor]="'esdn'"></app-activitylog>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
<ng-template #deleteItemModel>
    <div mat-dialog-title class="mb-0">
        <div class="row">
            <div class="col-md-10">
                <h1 mat-dialog-title>Delete ESDN</h1>
            </div>
            <div class="col-md-2">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon"
                    (click)="closemodel()" >
                    <mat-icon class="close1">close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div mat-dialog-content>
        <p>Are you sure, you want to delete record?</p>
        <div class="row">
            <div class="col-md-2">
                <label class="reason1">Reason</label>
            </div>
            <div class="col-md-8">
                    <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                                            [ngModel]="deletemodel.reason" (ngModelChange)="deletemodel.reason=$event.trim()" 
                                            (input)="deletemodel.reason=$event.target.value.trim()">
            </div>
        </div>
    </div>
    <br />
    <div mat-dialog-actions class="justify-content-center">
        <button mat-button cdkFocusInitial mat-dialog-close class="matButton" (click)="deleteFile()"
            [disabled]="!deletemodel.reason">
            Delete
        </button>
    </div>
</ng-template>
<ng-template #fileuploadmodel>
    <div mat-dialog-title class="dialog-title mb-0">
       
        <div class="row">
            <div class="col-11">
                <h2>ESDN : {{ esdnnumber }}</h2>
            </div>
            <div class="col-1 text-right">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()" >
                    <mat-icon class="close1">close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div mat-dialog-content class="row">
        <div *ngIf="filedata">
            <table>
                <thead class="table2">
                  
                    <th>Created By</th>
                    <th>Created Date</th>
                    <th>File Name</th>
                    <th>Action</th>
                  
                </thead>
                <tbody class="text-center">
                    <tr *ngFor="let item of filedata">
                        <td>{{ item.created_by }}</td>
                        <td>{{ item.created_date }}</td>
                        <td class="tablebody">
                                 {{ item.file_name }}
                        </td>
                        <td><i class="fas fa-eye text-cyan m-r-20" style="cursor: pointer"
                            title="View File" (click)="viewDoc(item)"></i>
                            <button mat-icon-button style="color: red; border: none; cursor: pointer"
                aria-label="Example icon button with a vertical three dot icon"
                (click)="deleterowfile(deletefileModel, item)">
                <mat-icon>delete</mat-icon>
              </button>
                        </td>
                        
                    </tr>
                </tbody>
            </table>
            <div class="text-center mt-3" *ngIf="filedata.length===0">
                No Data Found
            </div>
        </div>
    </div>
    <br />
    <button mat-raised-button type="button" (click)="autoFileUpload1.click()">
        Attach File&nbsp; <mat-icon class="file2">file_upload</mat-icon>
    </button>
    <div class="file">
        <input hidden class="form-control-file" name="file" type="file" multiple
            (change)="uploadWbsFile($event); showContent(i)" #autoFileUpload1 />
       
    </div>
</ng-template>
<ng-container *ngIf="activityLogDownload.length>0">
    <div class="row p-t-10">
        <div class="col-12 col-md-12">
            <div class="table-responsive mat-table-wrapper">
                <div #TABLELog class="example-table-container" class="tableclass">
                    <table class="table tbl1">
                        <tr class="center" class="headerstyles">
                            <th scope="col" class="border">S.No</th>
                            <th scope="col" class="border" *ngFor="let header of getHeaders()">{{header}}</th>
                        </tr>

                        <tr *ngFor="let user of activityLogDownload;let i=index;">
                            <td class="border">{{ i+1 }}</td>
                            <td class="border border1" *ngFor="let key of getHeaders()">
                                {{user[key]}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #deletefileModel>
    <div mat-dialog-title class="mb-0">
  
        <div class="row">
            <div class="col-11">
                <h1>Delete File</h1>
            </div>
            <div class="col-1 text-right">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
                    <mat-icon class="close1">close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div mat-dialog-content>Are you sure, you want to delete file?</div>
    <div mat-dialog-actions class="justify-content-center">
      
        <button mat-button cdkFocusInitial (click)="deleteexistingfile()" class="matButton">
            Delete
        </button>
    </div>
</ng-template>

<ng-template #newFileUploadModal>
    <div mat-dialog-title class="dialog-title mb-0">
       
        <div class="row">
            <div class="col-11">
                <h2>File Upload</h2>
            </div>
            <div class="col-1 text-right">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon"
                (click)="closemodel()" >
                <mat-icon class="close1">close</mat-icon>
            </button>
            </div>
        </div>
    </div>
    <div *ngIf="!viewEdit">
    <div mat-dialog-content class="row">
        <div *ngIf="selectedfiles.length > 0">
            <table>
                <thead class="table2">
                    <th>S.NO</th>
                    <th>File Name</th>
                    <th>Action</th>
                </thead>
                <tbody class="text-center">
                    <tr *ngFor="let item of selectedfiles;let i=index;">
                        <td>{{i+1}}</td>
                        <td class="tablebody1">{{item.name}}</td>
                        <td>
                            <button mat-icon-button class="delete1"
                            (click)="deleteFileItem(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div><br>
    <button type="button" mat-raised-button class="attachfile" color="secondary" (click)="autoFileUpload2.click()" >
        Attach File&nbsp; <mat-icon class="file2">file_upload</mat-icon></button>
    <div class="file">
        <input hidden class="form-control-file" name="file" type="file" multiple (change)="uploaddmrfiles($event)"
            #autoFileUpload2>
    </div>
</div>

    <div *ngIf="viewEdit">
        <div mat-dialog-content class="row">
            <div *ngIf="filedata">
                <table>
                    <thead class="table2">
                        <!-- <tr> -->
                        <th>Created By</th>
                        <th>Created Date</th>
                        <th>File Name</th>
                        <th>Action</th>
                        <!-- </tr> -->
                    </thead>
                    <tbody class="text-center">
                        <tr *ngFor="let item of filedata">
                            <td>{{ item.created_by }}</td>
                            <td>{{ item.created_date }}</td>
                            <td class="tablebody">
                                     {{ item.file_name }}
                            </td>
                            <td><i class="fas fa-eye text-cyan m-r-20" style="cursor: pointer"
                                title="View File" (click)="viewDoc(item)"></i>
                                <button mat-icon-button style="color: red; border: none; cursor: pointer"
                    aria-label="Example icon button with a vertical three dot icon"
                    (click)="deleterowfile(deletefileModel, item)">
                    <mat-icon>delete</mat-icon>
                  </button>
                            </td>
                            
                        </tr>
                    </tbody>
                </table>
                <div class="text-center mt-3" *ngIf="filedata.length===0">
                    No Data Found
                </div>
            </div>
        </div>
        <br />
        <button mat-raised-button type="button" (click)="autoFileUpload1.click()">
            Attach File
        </button>
        <div class="file">
            <input hidden class="form-control-file" name="file" type="file" multiple
                (change)="uploadWbsFile($event); showContent(i)" #autoFileUpload1 />
           
        </div>
    </div>
</ng-template>

