<!-- <p>gamdashboard works!</p> -->

<div class="material-tab">
    <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms" [(selectedIndex)]="demo1TabIndex">
        <mat-tab>
            <ng-template mat-tab-label> RGP Dashboard </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <h1 class="header">RGP Status as on {{ currentDate | date:'dd.MM.yyyy' }} against Open RGP's up to {{ currentDate | date:'dd-MMM-yyyy' }}</h1>                        
                        <div class="row justify-content-center m-2">
                            <table>
                                <tr class="header">
                                    <th colspan="18" class="header1">OPEN RGP's UP TO {{ currentDate | date:'dd.MM.yyyy' }}</th>
                                </tr>
                                <tr class="text-center">
                                    <th colspan="2">RGP Category</th>
                                    <th colspan="4">RGP - 60 days to due</th>
                                    <th colspan="4">RGP - No due</th>
                                    <th colspan="4" class="rgpOverdue">RGP - Over due</th>
                                    <th colspan="4">Total Open RGP</th>
                                </tr>
                                <!-- <tr>
                                    <td> -->
                                <tr>
                                    <th colspan="2">Vertical</th>
                                    <th colspan="2" class="text-right"> Open Items </th>
                                    <th colspan="2" class="text-right"> Open Items Value </th>

                                    <th colspan="2" class="text-right"> Open Items </th>
                                    <th colspan="2" class="text-right"> Open Items Value </th>
                                    <th colspan="2" class="rgpOverdue text-right"> Open Items </th>
                                    <th colspan="2" class="rgpOverdue text-right"> Open Items Value </th>
                                    <th colspan="2" class="text-right">Total Open Items </th>
                                    <th colspan="2" class="text-right">Total Open Items Value </th>
                                </tr>
                                <tr *ngFor="let data of rgpDetails">
                                    <td colspan="2" class="text-center">{{data.vertical}}</td>
                                    <td colspan="2" class="text-right">{{data.dueItemsCount | number}}</td>
                                    <td colspan="2" class="text-right">{{data.dueItemsValue.toFixed(2) | number}}</td>

                                    <td colspan="2" class="text-right">{{data.noDueItemsCount | number}}</td>
                                    <td colspan="2" class="text-right">{{data.noDueItemsValue.toFixed(2) | number}} </td>
                                    <td colspan="2" class="rgpOverdue text-right">{{data.overDueItemsCount | number}}</td>
                                    <td colspan="2" class="rgpOverdue text-right">{{data.overDueItemsValue.toFixed(2) | number}}</td>
                                    <!-- <td colspan="2">data</td>
                                    <td colspan="2">data</td> -->
                                    <td colspan="2" class="text-right">{{data.dueItemsCount + data.noDueItemsCount +
                                        data.overDueItemsCount | number}}</td>
                                    <td colspan="2" class="text-right">{{(data.dueItemsValue + data.noDueItemsValue +
                                        data.overDueItemsValue).toFixed(2) | number}}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="grandtotal1">Grand Total</td>
                                    <td colspan="2" class="grandtotal">{{ calculateGrandTotal('dueItemsCount') | number}}</td>
                                    <td colspan="2" class="grandtotal">{{
                                        calculateGrandTotal('dueItemsValue').toFixed(2) | number}}</td>
                                    <td colspan="2" class="grandtotal">{{ calculateGrandTotal('noDueItemsCount') | number}}</td>
                                    <td colspan="2" class="grandtotal">{{
                                        calculateGrandTotal('noDueItemsValue').toFixed(2) | number}}</td>
                                    <td colspan="2" class="rgpOverdue grandtotal">{{
                                        calculateGrandTotal('overDueItemsCount') | number}}
                                    </td>
                                    <td colspan="2" class="rgpOverdue grandtotal">{{
                                        calculateGrandTotal('overDueItemsValue').toFixed(2) | number}}
                                    </td>
                                    <td colspan="2" class="grandtotal">
                                        {{calculateGrandTotalOpenItems('totalOpenItemsCount') | number}}</td>
                                    <td colspan="2" class="grandtotal">{{calculateGrandTotalOpenItemsValue() | number}}</td>
                                </tr>
                            </table>
                        </div>

                        <div class="row justify-content-center m-2 mt-5">
                            <table>
                                <tr class="header">
                                    <th colspan="18" class="header1">SITE TO SITE</th>
                                </tr>
                                <tr class="text-center">
                                    <th colspan="2">RGP Category</th>
                                    <th colspan="4">RGP - 60 days to due</th>
                                    <th colspan="4">RGP - No due</th>
                                    <th colspan="4" class="rgpOverdue">RGP - Over due</th>
                                    <th colspan="4">Total Open RGP</th>
                                </tr>
                                <!-- <tr>
                                    <td> -->
                                <tr>
                                    <th colspan="2" class="text-center">Vertical</th>
                                    <th colspan="2" class="text-right"> Open Items </th>
                                    <th colspan="2" class="text-right"> Open Items Value </th>

                                    <th colspan="2" class="text-right"> Open Items </th>
                                    <th colspan="2" class="text-right"> Open Items Value </th>
                                    <th colspan="2" class="rgpOverdue text-right"> Open Items </th>
                                    <th colspan="2" class="rgpOverdue text-right"> Open Items Value </th>
                                    <th colspan="2" class="text-right">Total Open Items </th>
                                    <th colspan="2" class="text-right">Total Open Items Value </th>
                                </tr>
                                <tr *ngFor="let data of siteToSitedata">
                                    <td colspan="2" class="text-center">{{data.vertical}}</td>
                                    <td colspan="2" class="text-right">{{data.dueItemsCount | number}}</td>
                                    <td colspan="2" class="text-right">{{data.dueItemsValue.toFixed(2) | number}}</td>

                                    <td colspan="2" class="text-right">{{data.noDueItemsCount | number}}</td>
                                    <td colspan="2" class="text-right">{{data.noDueItemsValue.toFixed(2) | number}} </td>
                                    <td colspan="2" class="rgpOverdue text-right">{{data.overDueItemsCount | number}}</td>
                                    <td colspan="2" class="rgpOverdue text-right">{{data.overDueItemsValue.toFixed(2) | number}}</td>
                                    <!-- <td colspan="2">data</td>
                                    <td colspan="2">data</td> -->
                                    <td colspan="2" class="text-right">{{data.dueItemsCount + data.noDueItemsCount +
                                        data.overDueItemsCount | number}}</td>
                                    <td colspan="2" class="text-right">{{(data.dueItemsValue + data.noDueItemsValue +
                                        data.overDueItemsValue).toFixed(2) | number}}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="grandtotal1">Grand Total</td>
                                    <td colspan="2" class="grandtotal">{{ calculateGrandTotalsitetosite('dueItemsCount') | number
                                        }}</td>
                                    <td colspan="2" class="grandtotal">{{
                                        calculateGrandTotalsitetosite('dueItemsValue').toFixed(2) | number}}</td>
                                    <td colspan="2" class="grandtotal">{{
                                        calculateGrandTotalsitetosite('noDueItemsCount') | number}}</td>
                                    <td colspan="2" class="grandtotal">{{
                                        calculateGrandTotalsitetosite('noDueItemsValue').toFixed(2) | number}}</td>
                                    <td colspan="2" class="rgpOverdue grandtotal">{{
                                        calculateGrandTotalsitetosite('overDueItemsCount') | number}}
                                    </td>
                                    <td colspan="2" class="rgpOverdue grandtotal">{{
                                        calculateGrandTotalsitetosite('overDueItemsValue').toFixed(2) | number}}
                                    </td>
                                    <td colspan="2" class="grandtotal">{{calculateGrandTotalOpenItemssite() | number}}</td>
                                    <td colspan="2" class="grandtotal">{{calculateGrandTotalOpenItemsValuesite() | number}}</td>
                                </tr>
                            </table>
                        </div>

                        <div class="row justify-content-center m-2 mt-5">
                            <table>
                                <tr class="header">
                                    <th colspan="18" class="header1">REPAIR</th>
                                </tr>
                                <tr class="text-center">
                                    <th colspan="2">RGP Category</th>
                                    <th colspan="4">RGP - 60 days to due</th>
                                    <th colspan="4">RGP - No due</th>
                                    <th colspan="4" class="rgpOverdue">RGP - Over due</th>
                                    <th colspan="4">Total Open RGP</th>
                                </tr>
                                <!-- <tr>
                                    <td> -->
                                <tr>
                                    <th colspan="2" class="text-center">Vertical</th>
                                    <th colspan="2" class="text-right"> Open Items </th>
                                    <th colspan="2" class="text-right"> Open Items Value </th>

                                    <th colspan="2" class="text-right"> Open Items </th>
                                    <th colspan="2" class="text-right"> Open Items Value </th>
                                    <th colspan="2" class="rgpOverdue text-right"> Open Items </th>
                                    <th colspan="2" class="rgpOverdue text-right"> Open Items Value </th>
                                    <th colspan="2" class="text-right">Total Open Items </th>
                                    <th colspan="2" class="text-right">Total Open Items Value </th>
                                </tr>
                                <tr *ngFor="let data of repairData">
                                    <td colspan="2" class="text-center">{{data.vertical}}</td>
                                    <td colspan="2" class="text-right">{{data.dueItemsCount | number}}</td>
                                    <td colspan="2" class="text-right">{{data.dueItemsValue.toFixed(2) | number}}</td>

                                    <td colspan="2" class="text-right">{{data.noDueItemsCount | number}}</td>
                                    <td colspan="2" class="text-right">{{data.noDueItemsValue.toFixed(2) | number}} </td>
                                    <td colspan="2" class="rgpOverdue text-right">{{data.overDueItemsCount | number}}</td>
                                    <td colspan="2" class="rgpOverdue text-right">{{data.overDueItemsValue.toFixed(2) | number}}</td>
                                    <!-- <td colspan="2">data</td>
                                    <td colspan="2">data</td> -->
                                    <td colspan="2" class="text-right">{{(data.dueItemsCount + data.noDueItemsCount +
                                        data.overDueItemsCount).toFixed(2) | number}}</td>
                                    <td colspan="2" class="text-right">{{(data.dueItemsValue + data.noDueItemsValue +
                                        data.overDueItemsValue).toFixed(2) | number}}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="grandtotal1">Grand Total</td>
                                    <td colspan="2" class="grandtotal">{{ calculateGrandTotalRepair('dueItemsCount') | number}}
                                    </td>
                                    <td colspan="2" class="grandtotal">{{
                                        calculateGrandTotalRepair('dueItemsValue').toFixed(2) | number}}</td>
                                    <td colspan="2" class="grandtotal">{{ calculateGrandTotalRepair('noDueItemsCount') | number
                                        }}</td>
                                    <td colspan="2" class="grandtotal">{{
                                        calculateGrandTotalRepair('noDueItemsValue').toFixed(2) | number}}</td>
                                    <td colspan="2" class="rgpOverdue grandtotal">{{
                                        calculateGrandTotalRepair('overDueItemsCount') | number}}
                                    </td>
                                    <td colspan="2" class="rgpOverdue grandtotal">{{
                                        calculateGrandTotalRepair('overDueItemsValue').toFixed(2) | number}}
                                    </td>
                                    <td colspan="2" class="grandtotal">{{calculateGrandTotalOpenItemsrepair() | number}}</td>
                                    <td colspan="2" class="grandtotal">{{calculateGrandTotalOpenItemsValuerepair() | number}}
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div class="row justify-content-center m-2 mt-5">
                            <h1 class="header">RGP Overdue Analysis as on {{ currentDate | date:'dd.MM.yyyy' }}</h1>
                            <table>
                                <tr class="header">
                                    <th colspan="18" class="header1">OVERDUE OPEN RGP's UP TO {{ currentDate | date:'dd.MM.yyyy' }}
                                    </th>
                                </tr>
                                <tr class="text-center">
                                    <th colspan="2">RGP Category</th>
                                    <th colspan="4">Site to Site</th>
                                    <th colspan="4">Repair</th>
                                    <th colspan="4">Total</th>
                                </tr>
                                <!-- <tr>
                                    <td> -->
                                <tr>
                                    <th colspan="2" class="text-center">Vertical</th>
                                    <th colspan="2" class="text-right"> Open Items </th>
                                    <th colspan="2" class="text-right"> Open Items Value </th>

                                    <th colspan="2" class="text-right"> Open Items </th>
                                    <th colspan="2" class="text-right"> Open Items Value </th>
                                    <th colspan="2" class="text-right">Total Open Items </th>
                                    <th colspan="2" class="text-right">Total Open Items Value </th>
                                </tr>
                                <tr *ngFor="let data of overdueRgpData">
                                    <td colspan="2" class="text-center">{{data.vertical}}</td>
                                    <td colspan="2" class="text-right">{{data.overDueSiteToSiteItemsCount | number}}</td>
                                    <td colspan="2" class="text-right">{{data.overDueSiteToSiteItemsValue.toFixed(2) | number}}</td>

                                    <td colspan="2" class="text-right">{{data.overDueRepairItemsCount | number}}</td>
                                    <td colspan="2" class="text-right">{{data.overDueRepairItemsValue.toFixed(2) | number}} </td>
                                    <td colspan="2" class="text-right">{{data.totalOpenItems | number}}</td>
                                    <td colspan="2" class="text-right">{{data.totalOpenItemsValues.toFixed(2) | number}}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="grandtotal1">Grand Total</td>
                                    <td colspan="2" class="grandtotal">{{
                                        calculateGrandTotaloverdueRgpData('overDueSiteToSiteItemsCount') | number}}</td>
                                    <td colspan="2" class="grandtotal">{{
                                        calculateGrandTotaloverdueRgpData('overDueSiteToSiteItemsValue').toFixed(2) | number}}
                                    </td>
                                    <td colspan="2" class="grandtotal">{{
                                        calculateGrandTotaloverdueRgpData('overDueRepairItemsCount') | number}}</td>
                                    <td colspan="2" class="grandtotal">{{
                                        calculateGrandTotaloverdueRgpData('overDueRepairItemsValue').toFixed(2) | number}}</td>
                                    <td colspan="2" class="grandtotal">{{
                                        calculateGrandTotaloverdueRgpData('totalOpenItems') | number}}</td>
                                    <td colspan="2" class="grandtotal">{{
                                        calculateGrandTotaloverdueRgpData('totalOpenItemsValues').toFixed(2) | number}}</td>
                                </tr>
                            </table>
                        </div>

                        <div class="row justify-content-center m-2 mt-5">
                            <h1 class="header">Total RGP Material Status as on {{ currentDate | date:'dd-MMM-yyyy' }}</h1>
                            <table>
                                <!-- <tr class="header">
                                    <th colspan="18" class="header1">REPAIR</th>
                                </tr> -->
                                <tr class="text-center">
                                    <th colspan="4" class="header1"></th>
                                    <th colspan="4" class="header1">Closed</th>
                                    <th colspan="4" class="header1">Open</th>
                                    <th colspan="4" class="header1">Total</th>
                                    <th colspan="2" class="header1 rgpOverdue">Closed in %</th>
                                </tr>
                                <!-- <tr>
                                    <td> -->
                                <tr class="header1">
                                    <th colspan="4" class="text-center">Vertical</th>
                                    <th colspan="2" class="text-right">Material Count</th>
                                    <th colspan="2" class="text-right">Value in Cr.</th>

                                    <th colspan="2" class="text-right"> Material Count </th>
                                    <th colspan="2" class="text-right">Value in Cr.</th>
                                    <th colspan="2" class="text-right">Material Count </th>
                                    <th colspan="2" class="text-right">Value in Cr.</th>
                                    <th colspan="1" class="rgpOverdue text-right">Material Count </th>
                                    <th colspan="1" class="rgpOverdue text-right">Value in Cr.</th>
                                </tr>
                                <tr *ngFor="let data of materialData">
                                    <td colspan="4" class="text-center">{{data.vertical}}</td>
                                    <td colspan="2" class="text-right">{{data.dueClosedItemsCount | number}}</td>
                                    <td colspan="2" class="text-right">{{data.dueClosedItemsValue.toFixed(2) | number}}</td>

                                    <td colspan="2" class="text-right">{{data.dueOpenItemsCount | number}}</td>
                                    <td colspan="2" class="text-right">{{data.dueOpenItemsValue.toFixed(2) | number}} </td>
                                    <td colspan="2" class="text-right">{{data.totalItems | number}}</td>
                                    <td colspan="2" class="text-right">{{data.totalItemsValues.toFixed(2) | number}}</td>
                                    <td colspan="1" class="rgpOverdue text-right">{{data.closedItemPercentage.toFixed(2) | number}}</td>
                                    <td colspan="1" class="rgpOverdue text-right">{{data.closedItemValuePercentage.toFixed(2) | number}}
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="grandtotal1 bodycolor">Grand Total</td>
                                    <td colspan="2" class="grandtotal bodycolor">{{
                                        calculateGrandTotalmaterialData('dueClosedItemsCount') | number}}</td>
                                    <td colspan="2" class="grandtotal bodycolor">{{
                                        calculateGrandTotalmaterialData('dueClosedItemsValue').toFixed(2) | number}}</td>
                                    <td colspan="2" class="grandtotal bodycolor">{{
                                        calculateGrandTotalmaterialData('dueOpenItemsCount') | number}}</td>
                                    <td colspan="2" class="grandtotal bodycolor">{{
                                        calculateGrandTotalmaterialData('dueOpenItemsValue').toFixed(2) | number}}</td>
                                    <td colspan="2" class="grandtotal bodycolor">{{
                                        calculateGrandTotalmaterialData('totalItems') | number}}</td>
                                    <td colspan="2" class="grandtotal bodycolor">{{
                                        calculateGrandTotalmaterialData('totalItemsValues').toFixed(2) | number}}</td>
                                    <td colspan="1" class="rgpOverdue grandtotal">{{
                                        calculateGrandTotalmaterialData('closedItemPercentage').toFixed(2) | number}}</td>
                                    <td colspan="1" class="rgpOverdue grandtotal">{{
                                        calculateGrandTotalmaterialData('closedItemValuePercentage').toFixed(2) | number}}</td>
                                </tr>
                            </table>
                        </div>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label> DMR Dashboard </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <form id="form">
                            <div class="row">
                                <div class="col-md-2">
                                    <label>From Date<span class="mandetory">*</span></label>
                                    <input type="date" class="inputstyles" dateFormat="yyyy/mm/dd"
                                        [(ngModel)]="dmrdashboardfromdate" name="dmrdashboardfromdate" [max]="getCurrentDate()">
                                </div>

                                <div class="col-md-2">
                                    <label>To Date<span class="mandetory">*</span></label>
                                    <input type="date" class="inputstyles" dateFormat="yyyy/mm/dd"
                                        [(ngModel)]="dmrdashboardtodate" name="dmrdashboardtodate" [max]="getCurrentDate()">
                                    <div *ngIf="!validateDateRange()">
                                        <p class="error-message">To Date must be greater than the From Date.</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label class="label1">Select Company<span class="mandetory">*</span></label>
                                    <mat-form-field class="selectC" appearance="outline">
                                        <mat-select placeholder="Select the Company"
                                            [(ngModel)]="dmrcompanyname" name="dmrcompanyname"
                                            multiple #selectdmr>
                                            <div class="select-all pl-3">
                                                <mat-checkbox [(ngModel)]="allSelecteddmr"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (change)="toggleAllSelectionofdmr()">Select All</mat-checkbox>
                                            </div>
                                            <mat-option *ngFor="let item of companyData" [value]="item.company_name"
                                                (click)="optionClickdmr()">{{
                                                item.company_name }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-3 col-sm-6 col-md-3 col-xs-6 mt-4 pt-2">
                                    <button mat-raised-button type="button"
                                        [disabled]="!dmrdashboardfromdate || !dmrdashboardtodate || !dmrcompanyname || (dmrcompanyname.length == 0 ) || !validateDateRange()"
                                        (click)="getDmrData()" class="view">
                                        VIEW
                                    </button>
                                    <button mat-raised-button class="mx-2 view" type="button"
                                        [disabled]="!dmrdashboardfromdate || !dmrdashboardtodate || !dmrcompanyname || (dmrcompanyname.length == 0 ) || !validateDateRange()"
                                        (click)="clearFormDmrdata()">
                                        RESET
                                    </button>
                                </div>

                                <div class="col-md-2 col-lg-2 mt-4 pt-2 text-right" *ngIf="DmrReportTable && dmrData.length > 0">
                                    <button mat-raised-button class="btn btn-success excelbtn"
                                        (click)="exportExcelDmrData()">Export to Excel</button>
                                </div>
                            </div>
                        </form>
                        <div class="row justify-content-center mt-4" *ngIf="DmrReportTable">
                            <div class="col table-responsive" class="tableclass col-12 m-2" #TABLE>
                                <table class="table table-bordered" *ngIf="reaData==false">
                                    <tr class="d-none">
                                        <td>Report Generated Date:</td>
                                        <td>{{currentDate | date: "dd-MM-yyyy"}}</td>
                                    </tr>
                                    <tr class="d-none">
                                        <td>
                                            From Date:</td>
                                        <td>{{dmrdashboardfromdate | date: "dd-MM-yyyy"}}</td>
                                        <td>To Date:</td>
                                        <td>{{dmrdashboardtodate | date: "dd-MM-yyyy"}}</td>
                                        <td>Company Name:</td>
                                        <td>{{dmrcompanyname}}</td>
                                    </tr>
                                    <tr></tr>
                                    <tr class="header1">
                                        <th colspan="6">Period({{dmrdashboardfromdate | date:"dd-MM-yyyy"}}  To  {{dmrdashboardtodate | date:"dd-MM-yyyy"}})</th>
                                        <th colspan="6">NO Of DMR</th>
                                        <th colspan="6">Value Of DMR</th>
                                    </tr>
                                    <tr>
                                        <th colspan="2">S.NO</th>
                                        <th colspan="4" class="text-center">Company Name</th>
                                        <th colspan="2" class="text-right">Prepared</th>
                                        <th colspan="2" class="text-right">Pending</th>
                                        <th colspan="2" class="text-right">GR Completed</th>
                                        <th colspan="2" class="text-right">Prepared</th>
                                        <th colspan="2" class="text-right">Pending</th>
                                        <th colspan="2" class="text-right">GR Completed</th>
                                    </tr>
                                    <tr *ngFor="let item of dmrData; let i = index">
                                        <td colspan="2">{{i + 1}}</td>
                                        <td colspan="4" class="text-center">{{item._id}}</td>
                                        <td colspan="2" class="text-right">{{item.prepared}}</td>
                                        <td colspan="2" class="text-right">{{item.pending}}</td>
                                        <td colspan="2" class="text-right">{{item.grnCompleted}}</td>
                                        <td colspan="2" class="text-right">{{item.totalPrice.toFixed(2) | number}}</td>
                                        <td colspan="2" class="text-right">{{item.pendingGrnTotalPrice.toFixed(2) | number}}</td>
                                        <td colspan="2" class="text-right">{{item.CompletedGrnTotalPrice.toFixed(2) | number}}</td>
                                    </tr>
                                    
                                </table>
                                <div *ngIf="reaData==true" class="text-center">
                                    <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                        Loading...
                                    </h4>
                                    <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                                        No Records Found
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>