<mat-card class="maincard">
    <mat-card-content>
            <div class="row mb-2 bg-wave" >
                <div class="col-md-0">
                    <div class="d-flex justify-content-start">
                     <img class="p-1" src="assets/Storedashboard/Store-icon.png">
                 </div>
                     </div>
                 <div class="col-md-10">
                     <div class="row">
                     <span class="ml-3 mt-2">
                         <h1 class="page-title mb-0"><b>Store Dashboard</b></h1>
                     </span>
                 </div>
                 <div class="row">
                     <div class="col-md-3 range">
                        <input type="text" onkeydown="return false;" placeholder="Enter Date Range" [showDropdowns]="true" class="daterangepicker" [locale]="{applyLabel: 'ok', format: 'DD-MM-YYYY'}" ngxDaterangepickerMd startKey="start" endKey="end"  [(ngModel)]="model"
                        (datesUpdated)="datesUpdated($event)" [ranges]="ranges" alwaysShowCalendars="true" [autoApply]="true">
                     <!-- <mat-form-field appearance="none" class="d-block">
                         <mat-label>Enter a date range</mat-label>
                         <mat-date-range-input [rangePicker]="picker">
                             <input matStartDate matInput placeholder="Start date"  #dateRangeStart/>
                             <input matEndDate matInput placeholder="End date"  #dateRangeEnd (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"/>
                       </mat-date-range-input>
                             <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                             <mat-date-range-picker #picker></mat-date-range-picker>
                     </mat-form-field> -->
                 </div>
                 <div class="col-md-3 formfield">
                     <mat-form-field class="selectC" appearance="none" style="font-size:12px; width:100%;">
                         <mat-select placeholder="Select Company List" [(ngModel)]="companyName" name="companyName" #select   multiple>
                         <div class="select-all">
                             <mat-checkbox [(ngModel)]="allSelected"
                                 [ngModelOptions]="{standalone: true}"
                                 (change)="toggleAllSelection()">Select All
                             </mat-checkbox>
                         </div>
                         <mat-option (click)="optionClick()"
                             *ngFor="let item of companyData1"
                             [value]="item.company_name">{{ item.company_name }}
                         </mat-option>
                     </mat-select>
                     </mat-form-field>
                 </div>
                 <div class="col-md-1 submitbtn">
                     <button [disabled]="!companyName || !fromdate || !todate"
                     mat-raised-button
                     class="downloadbtn mt-4"
                     type="button" (click)="getDashboardData()">
                     SUBMIT
               </button>
                    </div>
                 </div>

                 </div>
                 <!-- liveBtn -->
                 <div class="col-md-1 mt-4 SldeToggle">
                    <mat-slide-toggle class=" mt-4"
                    [(ngModel)]="myFlagForSlideToggle" (change)="autoRefreshingData(myFlagForSlideToggle)">
                   Live
                  </mat-slide-toggle>
                    <!-- <button mat-raised-button class="btn customBtn" type="button" (click)="autoRefreshingData()">Live</button> -->
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
    <div class="row">
    <div class="col-md-6 minheight">
        <div class="stockValue">
            <img class="p-1" src="assets/Storedashboard/Stock-Value-icon.png">
            <h3 class="heading3">Stock Value</h3>
            <p class="stockValueData">{{stockValueData.stock_value}}</p>
            <p class="Inlacks"><span class="stcok">Stock</span>&nbsp;-&nbsp;In Lacs</p>
       </div>
    </div>
       <div class="col-md-6 minheight">
        <div class="stockValue">
            <img class="p-1" src="assets/Storedashboard/Co--Transit-icon.png">
            <h3 class="heading3">Consignment In Transit</h3>
            <p class="pt-1 pb-2  cotdata">{{COTData}}</p>
       </div>
       </div>
       <div class="col-md-12 mt-2">
        <div class="stockgraph" #chartWrapper2>
            <img class="p-1" src="assets/Storedashboard/stock-icon.png">
        <h3 class="heading3">Stock</h3>
        <div class="graphsdblk d-block">
            <ngx-charts-bar-vertical *ngIf="stockData.length>0"
                [view]="[chartWrapper2.offsetWidth]"
            [scheme]="{domain: ['#1a3b7f']}"
            [legend]="showLegend"
            [legendTitle]="legendTitle"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabelStock"
            [yAxisLabel]="yAxisLabelStock"
            [timeline]="timeline"
            [results]="stockGraphData" [showGridLines]="false">
            </ngx-charts-bar-vertical>
          </div>
       </div>
       </div>
       <div class="col-md-12 receipt mt-2">
        <div class="stockValue" #chartWrapper1>
            <img class="p-1" src="assets/Storedashboard/Receipt-icon.png">
        <h3 class="heading3">Receipt</h3>
        <div class="graphsdblk d-block">
            <ngx-charts-line-chart *ngIf="receiptData.length>0"
            (window:resize)="onResize1($event)"
            [view]="view1"
            [scheme]="{domain: ['#00FF00']}"
            [legend]="showLegend"
            [legendTitle]="legendTitle"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabelReceipt"
            [yAxisLabel]="yAxisLabelReceipt"
            [timeline]="timeline"
            [results]="receiptGraphData" [showGridLines]="true" 
            >
          </ngx-charts-line-chart>
          </div>
       </div>
       </div>
       <div class="col-md-12 consumption mt-2">
        <div class="stockValue" #chartWrapper>
            <img class="p-1" src="assets/Storedashboard/Consumption-icon.png">
        <h3 class="heading3">Consumption</h3>
        <div class="graphsdblk d-block">
        <ngx-charts-line-chart *ngIf="consumptionData.length>0"
        [view]="view"
        (window:resize)="onResize($event)"
        [scheme]="{domain: ['#FF00FF']}"
        [legend]="showLegend"
        [legendTitle]="legendTitle"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabelCon"
        [yAxisLabel]="yAxisLabelCon"
        [timeline]="timeline"
        [results]="consumptionGraphData" [showGridLines]="false"
        >
      </ngx-charts-line-chart>
        </div>
       </div>
       </div>
</div>

                </div>
                <div class="col-md-4">
<div class="row">
    <div class="col-md-12 overview">
        <div class="stockValue">
            <img class="p-1" src="assets/Storedashboard/overview-icon.png">
        <h3 class="heading3">Overview</h3>
        <div class="table-responsive tablediv">
            <table class="table">
                <thead class="headerclass">
                    <tr>
                        <th class="text-left">Transaction</th>
                        <th class="text-right">Count</th>
                        <th class="text-right">Value({{ovwmetrix}})</th>
                    </tr>
                </thead>
                <tbody *ngIf="overViewData.length > 0">
                    <tr *ngFor="let item of overViewData">
                        <td><p class="textwrap">{{item.trans}}</p></td>
                        <td class="text-right">{{item.count}}</td>
                        <td class="text-right">{{item.value | number:'1.2-2'}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="overViewData.length === 0">
                    <tr>
                        <td colspan="3" class="text-center">No Data Found</td>
                    </tr>
                </tbody>
            </table>
        </div>
       </div>
       </div>

       <div class="col-md-12 qc mt-2" #chartWrapper4>
        <div class="stockValue">
            <img class="p-1" src="assets/Storedashboard/Quality-Check-icon.png">
        <h3 class="heading3">Quality Check</h3>
        <div style="display: block">
        <ngx-charts-pie-chart class="displayinline"
        [view]="view4"
   (window:resize)="onResize4($event)"
   [legendTitle]="legendTitle"
[legendPosition] = "'bottom'"
[results]="QualityCheckGraphData"
[scheme]="{domain: ['#00D100','#808080']}"
[legend]="true" 
[labels]="false"
[doughnut]="true"
[animations]="false">
</ngx-charts-pie-chart>
</div>
       </div>
       </div>

       <div class="col-md-12 topvalue mt-2">
        <div class="stockValue">
            <img class="p-1" src="assets/Storedashboard/Top--Value.png">
        <h3 class="heading3">Top {{topValueData.length}} In Value</h3>
        <div  class="table-responsive tablediv">
            <table class="table">
                <thead class="headerclass">
                    <tr>
                        <th>Description</th>
                        <th>Unit</th>
                        <th>Quantity</th>
                        <th>Value({{tvmmetrix}})</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of topValueData">
                        <td><p class="textwrap">{{item.material_description}}</p></td>
                        <td>{{item.unit_of_measurement}}</td>
                        <td>{{item.quantity | number:'1.2-2'}}</td>
                        <td>{{item.value | number:'1.2-2'}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center mt-3 nodata" *ngIf="topValueData.length === 0">No Data Found</div>
        </div>
    </div>
       </div>
       
       <div class="col-md-12 topcs mt-2">
        <div class="stockValue">
            <img class="p-1" src="assets/Storedashboard/Consumption-icon.png">
        <h3 class="heading3">Top {{topConsumptionData.length}} In Consumption</h3>
        <div class="table-responsive tablediv">
            <table class="table">
                <thead class="headerclass">
                    <tr>
                        <th>Description</th>
                        <th>Unit</th>
                        <th>Quantity</th>
                        <th>Value({{tcmmetrix}})</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of topConsumptionData">
                        <td><p class="textwrap">{{item.material_description}}</p></td>
                        <td>{{item.unit_of_measurement}}</td>
                        <td>{{item.quantity | number:'1.2-2'}}</td>
                        <td>{{item.value | number:'1.2-2'}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center mt-3 nodata" *ngIf="topConsumptionData.length === 0">No Data Found</div>
        </div>
    </div>
       </div>
       <div class="col-md-12 rgp mt-2">
        <div class="stockValue" #chartWrapper3>
            <img class="p-1" src="assets/Storedashboard/Pass-icon.png">
        <h3 class="heading3">Returnable Gate Pass</h3>
        <div style="display: block">
            <ngx-charts-pie-chart class="displayinline"
            [view]="view3"
            (window:resize)="onResize3($event)"
[results]="RGPGraphData"
[legendTitle]="legendTitle"
[scheme]="{domain: ['#808080', '#00D100']}"
[legend]="true"
[labels]="false"
[doughnut]="true"
[legendPosition] = "'bottom'"
[animations]="false">
</ngx-charts-pie-chart>
          </div>
      </div>
       </div>
</div>
                </div>
            </div>
    </mat-card-content>
</mat-card>