<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- User Profile-->
        <li>
            <div class="user-profile d-flex no-block m-t-20">
                <div class="user-pic">
                    <img src="assets/greenko/profile.png" alt="users" class="rounded-circle" width="40" />
                </div>
                <!-- overflow-inherit -->
                <div class="dropdown user-content hide-menu  " ngbDropdown placement="bottom-right">
                    <a ngbDropdownToggle href="javascript:void(0)" class="dropdown-toggle" id="Userdd" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <h3 class="m-b-0 user-name font-medium">{{userDetails.preferred_username}}
                            <i class="fa fa-angle-down"></i>
                        </h3>
                        <span class="op-5 user-email userEmail" [title]="userDetails.email">{{userDetails.email}}</span>
                    </a>
                    <!-- <div class="dropdown-menu dropdown-menu-right" aria-labelledby="Userdd" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0)">
                            <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>

                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript:void(0)">
                            <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
                    </div> -->
                </div>
            </div>
        </li>
        <!-- <li class="p-15 m-t-10">
            <a href="javascript:void(0)" class="btn btn-block create-btn text-white no-block d-flex align-items-center">
                <i class="fas fa-plus"></i>
                <span class="hide-menu m-l-5">Add User</span>
            </a>
        </li> -->
        <!-- First level menu -->
        <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem.title"
            *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap cursor" *ngIf="(sidebarnavItem.extralink === true)"
                (click)="getSideBarAction(sidebarnavItem)">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu">{{sidebarnavItem.title }}</span>
            </div>
            <!-- *ngIf="!sidebarnavItem.extralink && permissionCheck(sidebarnavItem.roles) && permissionCheckCopany(sidebarnavItem.company)" -->
            <a class="sidebar-link waves-effect waves-dark"
                [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                [ngClass]="[sidebarnavItem.class]"
                *ngIf="!sidebarnavItem.extralink && checkamGreenEnv(sidebarnavItem.isamGreenEnv) && permissionCheck(sidebarnavItem.roles)"
                (click)="addExpandClass(sidebarnavItem.title)"
                [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu">
                    {{sidebarnavItem.title }}
                    <span *ngIf="sidebarnavItem.label != '' "
                        [ngClass]="[sidebarnavItem.labelClass]">{{sidebarnavItem.label }}</span>
                </span>
            </a>
            <!-- Second level menu -->
            <!-- *ngIf="!sidebarnavSubItem.extralink && permissionCheck(sidebarnavSubItem.roles)&& permissionCheckCopany(sidebarnavSubItem.company)" -->
            <ul aria-expanded="false" class="collapse first-level" *ngIf="sidebarnavItem.submenu.length > 0"
                [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
                <li class="sidebar-item" *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
                    [class.active]="showSubMenu === sidebarnavSubItem.title"
                    [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'">
                    <a class="sidebar-link" [title]="sidebarnavSubItem.title"
                        [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]"
                        [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'"
                        [ngClass]="[sidebarnavSubItem.class]"
                        *ngIf="!sidebarnavSubItem.extralink && checkamGreenEnv(sidebarnavSubItem.isamGreenEnv) && permissionCheck(sidebarnavSubItem.roles)"
                        (click)="addActiveClass(sidebarnavSubItem.title)">
                        <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                        <span class="hide-menu">{{sidebarnavSubItem.title}}</span>
                    </a>
                    <!-- Third level menu -->
                    <!-- *ngIf="!sidebarnavSubsubItem.extralink && permissionCheck(sidebarnavSubsubItem.roles)&& permissionCheckCopany(sidebarnavSubsubItem.company)" -->
                    <ul aria-expanded="false" class="collapse Second-level" *ngIf="sidebarnavSubItem.submenu.length > 0"
                        [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }">
                        <li class="sidebar-item" *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu"
                            routerLinkActive="active" [ngClass]="[sidebarnavSubsubItem.class]">
                            <a class="sidebar-link" [routerLink]="[sidebarnavSubsubItem.path]"
                                *ngIf="!sidebarnavSubsubItem.extralink && checkamGreenEnv(sidebarnavSubItem.isamGreenEnv) && permissionCheck(sidebarnavSubsubItem.roles)"
                                [routerLinkActive]="sidebarnavSubsubItem.submenu.length > 0 ? '' : 'router-link-active'">
                                <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                                <span class="hide-menu">{{sidebarnavSubsubItem.title}}</span>
                            </a>
                        </li>
                    </ul>
                    <!-- Fourth level menu -->
                    <!-- <ul aria-expanded="false" class="collapse Third-level" *ngIf="sidebarnavSubsubItem.submenu.length > 0"
                        [ngClass]="{'in' : showSubsubMenu === sidebarnavSubsubItem.title }">
                        <li class="sidebar-item" *ngFor="let sidebarnavSubsubsubItem of sidebarnavSubsubItem.submenu"
                            routerLinkActive="active" [ngClass]="[sidebarnavSubsubsubItem.class]">
                            <a class="sidebar-link" [routerLink]="[sidebarnavSubsubsubItem.path]"
                                *ngIf="!sidebarnavSubsubsubItem.extralink && permissionCheck(sidebarnavSubsubsubItem.roles)&& permissionCheckCopany(sidebarnavSubsubsubItem.company)"
                                [routerLinkActive]="sidebarnavSubsubsubItem.submenu.length > 0 ? '' : 'router-link-active'">
                                <i [ngClass]="[sidebarnavSubsubsubItem.icon]"></i>
                                <span class="hide-menu">{{sidebarnavSubsubsubItem.title}}</span>
                            </a>
                        </li>
                    </ul> -->
                </li>
            </ul>
        </li>
    </ul>
</nav>