import { Component, Input, NgZone, OnInit, ElementRef, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormsModule } from '@angular/forms';
import * as moment from 'moment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CustomerService } from 'src/app/services/customer.service';
import { error } from 'jquery';
import { MachineryModule } from '../machinery.module';
import * as XLSX from 'xlsx';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AlertCallsService } from 'src/app/auth/alert-calls.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';



export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

export interface User {
  name: string;
  value: string;
}

@Component({
  selector: 'app-vehiclelog',
  templateUrl: './vehiclelog.component.html',
  styleUrls: ['./vehiclelog.component.scss']
})



export class VehiclelogComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('masterpaginator') masterpaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() page = new EventEmitter<PageEvent>();
  goTo: any;
  pageNumbers: any = []
  equidata: boolean;
  totalRecordsequi: any;
  loadingRecords: any = false;
  logdata: any;
  totalhours = "";
  totalkm: number = 0;
  selectedvehicledata: any;
  vehicledata: any;
  selectedVehicle: any[] = [{ vehicle_model: '', contractor_name: '' }];
  fileUploadUrls: any[] = [];
  filenamearray: any[] = []
  selectedfiles: any[] = []
  fileUploadUrlspr: any[] = [];
  filenamearray1: any[] = [];
  demo1TabIndex: any = 0;
  viewData: boolean = false;
  totalRecords: any = 0;
  selectedIndex = -1;
  btn: any = "Save"
  toogle: any = true;
  editednumber: any;
  vehnumdata: any = {}
  dialogRef: any = null;
  createNewFile: any = {}
  reaData: boolean;
  closingError: boolean = false;
  @ViewChild('ListTABLE', { static: false }) ListTABLE: ElementRef;

  reaDatalog: boolean;
  resData: boolean;
  totalRecordslog: any;
  pageIndex: any = 1;
  pageSize: any = 10;
  editabledata: any;
  editModel: any;
  deleteNumber: any;
  machinerynumber: any;
  filedata: any = [];
  deleteid: any;
  logsearchDataa: any;
  searchref: any;
  searchcol: any = "";
  refcode: any;
  resultqcnumber: any;
  remarks: any;
  errormsgs: boolean;
  errormsg: string = '';
  editeddata: boolean;
  resultdcnumber: any;
  dcnumber: any;
  dcnumber1: any;
  issubmitClicked: boolean;
  pageIndex1: any = 1;
  logpagesize: number = 10;
  vechiclenames: string;
  vendornames: string;
  dateM: any;
  editbtn: boolean=true;
  refnumber: any;
  unitsmeasurement: string = 'KMS'; 
  exportExcelData: []=[];
  getCurrentDateTime(): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    const milliseconds = now.getMilliseconds().toString().padStart(3, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
  calculateTotalKM() {
    this.closingError = false
    if (!isNaN(this.startingkm) && !isNaN(this.closingkm)) {
      if (this.closingkm < this.startingkm) {
        this.totalkm = 0;
        this.closingError = true;
      } else {
        this.totalkm = parseFloat((this.closingkm - this.startingkm).toFixed(3));
        this.closingError = false;
      }
    } else {
      this.totalkm = 0;
      this.closingError = false;
    } 
  }
  openNewFileUploadModal(data: any, toogle: any) {
    this.dialog.open(data, {
      width: '800px'
    })
    if (toogle === false) {
      this.getexistingfiles()
    }
  }

  // deleteFileItem(index: any) {
  //   this.selectedfiles.splice(index, 1)
  // }

  deleteFileItem(index: any) {
    const fileName = this.selectedfiles[index].name;
    this.selectedfiles.splice(index, 1);
  
    const filenameArrayIndex = this.filenamearray.indexOf(fileName);
    if (filenameArrayIndex !== -1) {
      this.filenamearray.splice(filenameArrayIndex, 1);
    }
  
    this.alertcall.showSuccess('Accepted', 'File removed successfully');
  }
  
  exportExcelList() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.ListTABLE.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `ListData${moment().format('LL,LTS')}.xlsx`);
  }
  
  getCurrentDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const currentDate = `${year}-${month}-${day}`;
    const currentDateTime = currentDate + "T" + today.toTimeString().slice(0, 5);
    return currentDateTime;
  }
  private date= moment().format('yyyy-MM-ddd ')
  public disabled = false;
  public showSpinners = true;
  myControl = new FormControl();

  // demo1TabIndex: any = 0;
  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required])
  })
  public dateControl = new FormControl(new Date());

  options: User[] = [
    { name: "Mary", value: "1" },
    { name: "Shelley", value: "2" },
    { name: "Igor", value: "3" },
    { name: "marry", value: "4" }
  ];
  filteredOptions: Observable<User[]>;
  model1: any = {};
  today: any;
  txnId: string;
  vehiclenumber: string;
  vehiclename: string;
  companyname = '';
  vendorname: any;
  Units: any;
  StartingDate: any;
  endingdate: any;
  hsdissued: any;
  startingkm: any;
  closingkm: any;
  hsdreading: any;
  descriptionOfWork: any
  equidetails: any;
  fromdate: any;
  deletemodel:any;
  @ViewChild('autoCompleteTrigger', { read: MatAutocompleteTrigger }) autoCompleteTrigger: MatAutocompleteTrigger;

  // fileUploadUrls: any[] = [];
  fileUploadUrlsdmr: any[] = [];
  todate: any;
  saveddataarray: any[] = []
  companyData1: any;
  dataSourcemainlog = new MatTableDataSource();
  dataSourcemain = new MatTableDataSource();
  logsearchData: any;
  listcolumns: any[] = [
    "sno",
    "Date",
    "reference_number",
    "company_Name",
    "vendorname",
    "vechiclenumber",
    "unitofmeasurement",
    "totalkm",
    "Action",
  ];
  constructor(private http: HttpClient, private zone: NgZone, private custservice: CustomerService, private formbilder: FormBuilder, private alertcall: AlertCallsService, private dialog: MatDialog, private router: Router, private injector: Injector

  ) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
    // this.date = this.getPresentDate()
    // this.StartingDate = this.getCurrentDateTime();
    // this.endingdate = this.getCurrentDateTime();
  }
  model: NgbDateStruct;
  ngOnInit(): void {
    this.dateM = moment(new Date()).format("YYYY-MM-DD")
    this.vechiclenames = '';
    this.vendornames = '';
    this.getEquipmentdetailss();
    this.getTransactionId();
    this.getcompanydata1();
    // this.calculateTotalHours();
    // this.calculateTotalKM();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map(value => (typeof value === "string" ? value : value.name)),
      map(name => (name ? this._filter(name) : this.options.slice()))
    );
    this.formGroup = new FormGroup({
      activeEndDate: new FormControl(new Date())
    }, { updateOn: 'change' });

  }
  triminput() {
    // this.hsdissued = this.hsdissued.trim()
    if (typeof this.hsdissued === 'string') {
      this.hsdissued = this.hsdissued.trim();
    } else {
      this.hsdissued = String(this.hsdissued).trim();
    }
  }



  filter(val: string): void {   
      console.log(val);
      this.custservice.vehicleMasterListData({ "command": "lst", "key": val , showLoader: 'hide',field:'vehicle_number' }).subscribe((res: any) => {
        console.log(res);
        this.vehicledata = res.data.filter((item: any) => {

          return item.vehicle_number.toLowerCase().includes(val.toLowerCase());
        });
        console.log(this.vehicledata, "filtered vehicle data");
        const matchedVehicle = this.vehicledata.find((item:any)=> item.vehicle_number.toLowerCase() === val.toLowerCase());
        if (!matchedVehicle) {
            this.vechiclenames = '';
            this.vendornames = '';
            this.autoCompleteTrigger.openPanel();
        }        
      });
  }
  getUserDetails(ev: any) {
    this.vehicledata.find((ele: any) => {
      if (ele.vehicle_number === this.vehiclenumber) {
        this.vechiclenames = ele.vehicle_model
        this.vendornames = ele.contractor_name

      }

    })
  }

  displayFn(user?: User): string | undefined {
    return user ? user.name : undefined;
  }
  returnFn(user?: User): string | undefined {
    return user ? user.value : undefined;
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(
      option => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  formatDatee(dateString: any) {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  formatDate(dateString: any) {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  postCreateEquipmentdetails() {
    this.btn = "Save"

    let obj = {
      "date": this.dateM,
      "company_name": this.companyname,
      "vehicle_number": this.vehiclenumber,
      "vehicle_name": this.vechiclenames,
      "vendor_name": this.vendornames,
      "unit_of_measurment": this.unitsmeasurement,
      "start_date": this.StartingDate,
      "end_date": this.endingdate,
      "total_hours" :this.totalhours,
      "total_kms":this.totalkm,
      "hsd_issued": Number(this.hsdissued),
      "start_kms": this.startingkm,
      "closing_kms": this.closingkm,
      "hsd_reading": this.hsdreading,
      "description_of_work": this.descriptionOfWork,
      "remarks": this.remarks,
      "showLoader":'hide'
    }
    this.custservice.createEquipmentdetails(obj).subscribe((res: any) => {
      if (res && res['status'] == 'Success') {

        // res.data = obj
        Swal.fire({
          text: res["message"],
          title: res["data"],
          icon: "success",
          width: 500,
        })
        this.demo1TabIndex = 0;
        this.resultdcnumber = res['data']
        this.clearpage1()

        // if (this.fileUploadUrlspr.length > 0) {
          this.uploadselectedfiles();
        // }
        this.getEquipmentdetailss()
      }

      else {
        this.alertcall.showWarning("Accecpted", res["message"])
      }
    }
    )
  }
  savedisablebtn() {
    if (
      !this.dateM ||
      !this.vehiclenumber ||
      !this.companyname ||
      !this.vechiclenames ||
      !this.vendornames ||
      !this.unitsmeasurement ||
      // !this.StartingDate ||
      // !this.endingdate ||
      // !this.totalhours ||
      // this.hsdissued === null ||
      this.hsdissued === null ||
      this.hsdissued === undefined ||
      this.hsdissued === '' ||
      // !this.startingkm ||
      // !this.closingkm ||
      // !this.totalkm ||
      this.startingkm === null ||
      this.startingkm === undefined ||
      this.startingkm === '' ||
      this.closingkm === null ||
      this.closingkm === undefined ||
      this.closingkm === '' ||
      this.totalkm === null ||
      this.totalkm === undefined ||
      this.hsdreading === null ||
      this.hsdreading === undefined ||
      this.hsdreading === '' ||
      // !this.hsdreading ||
      this.errormsgs ||
      this.closingError
    ) {
      return true;
    } else {
      return false;
    }
  }

  updatedisablebtn() {
    if (
      !this.dateM ||
      !this.vehiclenumber ||
      !this.companyname ||
      !this.vechiclenames ||
      !this.vendornames ||
      !this.unitsmeasurement ||
      // !this.StartingDate ||
      // !this.endingdate ||
      // !this.totalhours ||
      // !this.closingkm ||
      this.hsdissued === null ||
      this.hsdissued === undefined ||
      this.hsdissued === '' ||
      this.startingkm === null ||
      this.startingkm === undefined ||
      this.startingkm === '' ||
      this.closingkm === null ||
      this.closingkm === undefined ||
      this.closingkm === '' ||
      this.totalkm === null ||
      this.totalkm === undefined ||
      this.hsdreading === null ||
      this.hsdreading === undefined ||
      this.hsdreading === '' ||
      this.errormsgs||
      this.closingError
    ) {
      return true;
    } else {
      return false;
    }
  } 
  editgidata(data: any, dialog: any) {
    this.dcnumber1 = data.number
    this.dialog.open(dialog, {
      width: "400px",
    });
    this.editednumber = data._id
    this.editModel = ''
  }

  deleteItem(rw: any, data: any) {
    this.dialog.open(data, {
      width: "400px",
    });
    this.deleteNumber = rw._id;
    this.deletemodel=""
  }
  closemodel() {
    this.dialog.closeAll();

  }
  deleterecord() {
    let obj = {
      _id: this.deleteNumber,
      reason: this.deletemodel
     
    }
    this.custservice.deleteequipmentdetails(obj).subscribe((res: any) => {

      this.alertcall.showSuccess("Accepted", res["message"]);
      this.getEquipmentdetailss();
      this.dialog.closeAll();

    });
  }
  clearpage1() {
    this.getTransactionId();
    this.dataSourcemain.data = [];
    this.companyname = "";
    this.vehiclenumber = "";
    this.vechiclenames = "";
    this.vendornames = "";
    this.Units = [];
    this.unitsmeasurement = 'KMS';
    this.dateM=moment(new Date()).format("YYYY-MM-DD")
    this.StartingDate = [];
    this.endingdate = [];
    this.hsdissued = null; 
    this.startingkm = null; 
    this.totalhours = ""; 
    this.closingkm = null; 
    this.totalkm = 0; 
    this.hsdreading = null; 
    this.descriptionOfWork = "";
    this.remarks = "";
    this.closingError = false;
    this.errormsg = "";
    this.errormsgs = false;
    this.filenamearray1 = [];
    this.toogle = true;
  }
  saveeditdata() {

    this.viewData = false
    this.toogle = true;
    let obj = {
      "_id": this.editednumber,
      "date":this.dateM,
      "company_name":this.companyname,
      "start_date": this.StartingDate,
      "end_date": this.endingdate,
      "start_kms": this.startingkm,
      "closing_kms": this.closingkm,
      "total_kms": this.totalkm,
      "total_hours": this.totalhours,
      "hsd_reading": this.hsdreading,
      "hsd_issued": Number(this.hsdissued),
      "unit_of_measurment": this.unitsmeasurement,
      "reason": this.editModel,
      "description_of_work": this.descriptionOfWork,
      "remarks": this.remarks,
      "showLoader":'hide'
    }
    this.custservice.editequipmentdetails(obj).subscribe((res: any) => {
      // this.alertcall.showSuccess('Accepted', res['message']);
      this.dialog.closeAll()  
      Swal.fire({
        text: res['message'],
        title: res['data'],
        icon: 'success',
        // title: res['reference'],
        width: 500,
      });
      this.demo1TabIndex = 1;
      this.getEquipmentdetailss()
      this.changeUnitMeasurement()
      this.clearpage1()
     
    })
    this.resultdcnumber = this.editednumber
    // if (this.fileUploadUrlspr.length > 0) {
      this.uploadselectedfiles();
    // }
  };


  backbutton() {
    this.demo1TabIndex = 1;
  }
  viewdataa(data: any) {
    let obj = {
      _id: data._id
    }
    this.custservice.getEquipmentdetails(obj).subscribe((res: any) => {
      if (res.data.length > 0) {
        this.viewData = true
        this.saveddataarray = res.data
        this.dataSourcemain.data = this.saveddataarray
        this.editabledata = res.data[0];
        this.dateM = moment(this.editabledata.date).format('DD-MM-YYYY')
        this.refnumber=this.editabledata.number
        this.companyname = this.editabledata.company_name !== null ? this.editabledata.company_name : "NA"
        this.vehiclenumber = this.editabledata.vehicle_number !== null ? this.editabledata.vehicle_number : "NA"
        this.vehiclename = this.editabledata.vehicle_name !== null ? this.editabledata.vehicle_name : "NA"
        this.vendorname = this.editabledata.vendor_name !== null ? this.editabledata.vendor_name : "NA"
        this.unitsmeasurement = this.editabledata.unit_of_measurment !== null ? this.editabledata.unit_of_measurment : "NA"
        this.StartingDate = this.editabledata.start_date ? moment.utc(this.editabledata.start_date).format('DD-MM-YYYY HH:mm'): 'NA'
        this.endingdate = this.editabledata.end_date ? moment.utc(this.editabledata.end_date).format('DD-MM-YYYY HH:mm'): 'NA'
        this.totalhours = this.editabledata.total_hours && this.editabledata.total_hours || 'NA'
        this.totalkm = this.editabledata.total_kms && this.editabledata.total_kms
        this.hsdissued = this.editabledata.hsd_issued !== null ? this.editabledata.hsd_issued : "NA"
        this.startingkm = this.editabledata.start_kms !== null ? this.editabledata.start_kms : "NA"
        this.closingkm = this.editabledata.closing_kms !== null ? this.editabledata.closing_kms : "NA"
        this.hsdreading = this.editabledata.hsd_reading !== null ? this.editabledata.hsd_reading : "NA"
        this.descriptionOfWork = this.editabledata.description_of_work && this.editabledata.description_of_work || "NA"
        this.remarks = this.editabledata.remarks && this.editabledata.remarks || "NA"
        this.demo1TabIndex = 0;

      }
      else {
        this.alertcall.showWarning("Accepted", "No Data Found");
      }
    })
  }
  onpageevent(event: any) {

    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getEquipmentdetailss()
  }
  goToChange() {
    this.paginator.pageIndex = this.goTo - 1;
    const event: PageEvent = {
      length: this.paginator.length,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
    };
    this.paginator.page.next(event);
  }
  getEquipmentdetailss() {
    this.pageNumbers = []
    let params = new HttpParams()
    params = params.append('page', this.pageIndex)
    params = params.append('perPage', this.pageSize)
    if (this.searchref && this.searchcol) { 
      params = params.append('field', this.searchcol)
      params = params.append('key', this.searchref)
    } 
    this.custservice.getEquipmentdetails(params).subscribe((res: any) => {
      this.reaDatalog = false
      this.equidata = true;
      this.equidetails = res.data;
      this.exportExcelData=res.data
      this.totalRecords = res?.count;
      // this.dataSourcemain = res.data;
      this.dataSourcemain.data = res.data;
      this.dataSourcemain.sort = this.sort;
      
    })
}
  getEquipmentdetailss1(){
    if (this.searchref) {
      if (this.paginator) {
        this.paginator.firstPage();
      }
      this.getEquipmentdetailss();
    } else {
      this.searchref = "";
      this.searchcol=""
      this.getEquipmentdetailss();
    }
  }
  clearSearch() {
    this.vehiclenumber = '';
    this.filter('');
}

  getequipdata() {
    this.searchref = ""
    this.searchcol = ""
    this.getEquipmentdetailss()
  }

  savesubmitdata() {
    this.issubmitClicked = true;
    this.viewData = false
    this.editbtn=false
    this.toogle = false;
    let obj = {
      "_id": this.editednumber,
    }
    this.custservice.getEquipmentdetails(obj).subscribe((res: any) => {
      if (res && res['status'] === "Success") {
        this.alertcall.showSuccess('Accepted', res.message);
        this.dialog.closeAll()
        this.saveddataarray = res.data
        this.dataSourcemain.data = this.saveddataarray
        this.editabledata = res.data[0]
        this.dateM =  moment(this.editabledata.date).format('YYYY-MM-DD');
        this.companyname = this.editabledata.company_name,
          this.vehiclenumber = this.editabledata.vehicle_number,
          this.vechiclenames = this.editabledata.vehicle_name,
          this.vendornames = this.editabledata.vendor_name,
          this.unitsmeasurement = this.editabledata.unit_of_measurment,
          // this.StartingDate = this.formatDate(this.editabledata.start_date);
          this.StartingDate = moment(this.editabledata.start_date).toISOString().slice(0, 16);
        // this.endingdate = this.formatDate(this.editabledata.end_date),
        console.log(this.StartingDate,"oooooooo");
        
        this.endingdate = moment(this.editabledata.end_date).toISOString().slice(0, 16);
        this.totalhours = this.editabledata.total_hours,
          this.totalkm = this.editabledata.total_kms,
          this.hsdissued = this.editabledata.hsd_issued,
          this.startingkm = this.editabledata.start_kms,
          this.closingkm = this.editabledata.closing_kms,
          this.hsdreading = this.editabledata.hsd_reading,
          this.remarks = this.editabledata.remarks,
          this.descriptionOfWork = this.editabledata.description_of_work
        this.demo1TabIndex = 0;
        this.editeddata = true;
        this.getEquipmentdetailss()
        

      }

      else {
        this.alertcall.showWarning("Warning", res.message);
      }
    })
  };

  calculateTotalHours() {
    if (this.StartingDate && this.endingdate) {
      const startTime = new Date(this.StartingDate).getTime() / 1000;
      const endTime = new Date(this.endingdate).getTime() / 1000;
      if (!isNaN(startTime) && !isNaN(endTime) && endTime >= startTime) {
        const timeDiff = endTime - startTime;
        const totalHours = Math.floor(timeDiff / 3600); 
        const remainingSeconds = timeDiff % 3600; 
        const totalMinutes = Math.floor(remainingSeconds / 60); 
        this.errormsgs = false;
        this.totalhours = totalHours +  ' : ' + totalMinutes ; 
        this.errormsg = '';
      } else if (this.StartingDate.trim() !== "" && this.endingdate.trim() !== "") {
        this.totalhours = '',
        this.errormsgs = true;
        this.alertcall.showWarning("Error", "Ending date must be greater than or equal to starting date");
      } else {
        this.totalhours = '';
        this.errormsgs = false;
        this.errormsg = '';
      }
    }   
  } 
  getToday(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  onTabChanged(ev: any) {
    if (this.demo1TabIndex === 1) {
      this.viewData = false
      this.editbtn = true
      this.closingError = false;
      this.saveddataarray = [],
        this.dataSourcemain.data = [],
        this.dateM =moment(new Date()).format("YYYY-MM-DD"),
        this.companyname = "",
        this.vehiclenumber = "",
        this.vechiclenames = "",
        this.vendornames = "",
        this.searchcol=""
        this.unitsmeasurement= 'KMS';
        this.Units = [],
        this.hsdissued = null; 
        this.startingkm = null; 
        this.totalhours = ""; 
        this.closingkm = null; 
        this.totalkm = 0; 
        this.hsdreading = null;
        this.StartingDate = [],
        this.endingdate = [],
        this.totalhours = '',
        this.totalkm = 0,
        this.pageIndex = 1;
        this.pageSize = 10;
        this.pageIndex1 = 1;
        this.logpagesize = 10;
        this.selectedfiles=[]
       // this.vechiclenames=""
      this.issubmitClicked = false
        this.descriptionOfWork = "",
        this.remarks = "",
        this.editModel = ""
      this.pageIndex = 1
      this.searchref=""
      this.savedisablebtn() 
      this.getEquipmentdetailss()
      this.editeddata = false;
      this.dataSourcemain.sort = this.sort;
      this.router.navigate(['/machinery/vehiclelog'])
      this.toogle = true;
    } else if (this.demo1TabIndex === 2) {
      // alert(1)
      this.viewData = false
      this.editbtn = true
      this.saveddataarray = [],
        this.dataSourcemain.data = [],
        this.closingError = false;
        this.date = "",
        this.dateM =moment(new Date()).format("YYYY-MM-DD"),
        this.searchcol="",
        this.companyname = "",
        this.vehiclenumber = "",
        this.vehiclename = "",
        this.vendorname = [],
        this.unitsmeasurement= 'KMS';
        this.Units = [],
        this.hsdissued = null; 
        this.startingkm = null; 
        this.totalhours = ""; 
        this.closingkm = null; 
        this.totalkm = 0; 
        this.hsdreading = null;
        this.StartingDate = [],
        this.endingdate = [],
        this.totalkm = 0,
        this.descriptionOfWork = "",
        this.remarks = "",
        this.editModel = "",
        this.pageIndex = 1,
        this.searchref=""
        this.selectedfiles=[]
        // this.logsearch()
        this.issubmitClicked = false
      this.getEquipmentdetailss()
        this.editeddata = false;

      this.router.navigate(['/machinery/vehiclelog'])
    }
  }
  getTransactionId() {
    this.txnId = ""
    this.custservice.getTransactionId().subscribe((res: any) => {
      if (res) {
        this.txnId = res.txn_id
      }
    })
  }
  getcompanydata1() {
    let obj = {
      command: "lst",
      lmt: 100000,
      pid: 1
    };
    this.custservice.usercompanyData(obj).subscribe((res: any) => {
      this.companyData1 = res.data;
    });
  }
  onChange() {
    this.searchref = ""
    if (this.searchref == "") {
      this.getEquipmentdetailss()
    }
  }
  changeUnitMeasurement(){
    if(this.unitsmeasurement == "KMS"){
      return 'KMS'
    }
    if(this.unitsmeasurement == "HRS"){
      return 'HRS'
    }
  }
  onUnitChange() {
    this.startingkm = null;
    this.closingkm = null;
    this.totalkm = 0;
    this.calculateTotalKM();  
}

  deleterowfile(row: any, data: any) {
    this.deleteid = data.id
    this.dialogRef = this.dialog.open(row, {
      width: "400px"
    })
  }
  showContent(index: any) {
    this.selectedIndex = index;
  }
uploadWbsFile(fileInput: any) {
  if (
    fileInput &&
    fileInput.target &&
    fileInput.target.files &&
    fileInput.target.files.length > 0
  ) {
    this.fileUploadUrls = fileInput.target.files;
    this.createNewFile.fileName = fileInput.target.files[0].name;
    for (const file of this.fileUploadUrls) {
      this.filenamearray1.push(file.name)
    }
  }
  console.log(this.fileUploadUrls);
  const postData = new FormData();
  postData.append("document_type", "EquipmentDetails");
  postData.append("document_number", this.dcnumber1);
  for (const file of this.fileUploadUrls) {
    postData.append("doc", file)
  }
  this.custservice.addfileupload(postData).subscribe((res: any) => {
    if (res && res['status_code'] == '200') {
      this.alertcall.showSuccess("Accepted", "File uploaded successfully")
      this.getexistingfiles()
      this.filenamearray = []
      this.fileUploadUrls = []
    }
     else {
      this.alertcall.showWarning("Error", res['message'])
    }
  })
  fileInput.target.value = null;
}
  deleteexistingfile() {
    let params = new HttpParams();
    params = new HttpParams()
      .set("document_number", this.dcnumber)
      .set("document_type", "EquipmentDetails")
      .set("id", this.deleteid);
    this.custservice.deletefiles(params).subscribe((res: any) => {
      if (res && res["status_code"] == "200") {
        this.alertcall.showSuccess("Accepted", "File Deleted Successfully");
        this.getexistingfiles();
        this.dialogRef.close();
      } else {
        this.alertcall.showWarning("Error", res["message"]);
      }
    });
  }

  uploadgifiles(fileInput: any) {
    if (
      fileInput &&
      fileInput.target &&
      fileInput.target.files &&
      fileInput.target.files.length > 0
    ) {
      this.fileUploadUrlspr = fileInput.target.files;
      for (const file of this.fileUploadUrlspr) {
        const existingFile = this.selectedfiles.find(selectedFile => selectedFile.name === file.name);
        if (this.isFileSelected1(file.name) && existingFile) {
          this.alertcall.showWarning('Alert', 'Some files are already selected');
        } else if(this.fileUploadUrlspr.length >1){
          this.filenamearray.push(file.name)
          this.selectedfiles.push(file)
          this.alertcall.showSuccess("Accepted", "Files are uploaded Successfully")
        }
        else {
          this.filenamearray.push(file.name)
          this.selectedfiles.push(file)
          this.alertcall.showSuccess("Accepted", "File is uploaded Successfully")
        }
      }
      fileInput.target.value = null;
    }
  }
  // isFileSelected1(selectedFilePath: string): boolean {
  //   return this.selectedfiles.some((file: any) => file.name === selectedFilePath);
  // }
  isFileSelected1(fileName: string): boolean {
    return this.selectedfiles.some((selectedFile) => selectedFile.name === fileName);
  }
  uploadselectedfiles() {
    const postData = new FormData();
    postData.append("document_type", "EquipmentDetails");
    postData.append("document_number", this.resultdcnumber);
    const uniqueFileNames = new Set();

    for (const file of this.selectedfiles) {

      if (!uniqueFileNames.has(file.name)) {
        postData.append("doc", file);
        uniqueFileNames.add(file.name);
      }
    }
    this.custservice.addfileupload(postData).subscribe((res: any) => {
      if (res && res["status_code"] == "200") {
        this.fileUploadUrlspr = [];
        this.filenamearray = [];
        this.selectedfiles = [];
      } else {
      }
    });
  }
  openfileuploadmodel(data: any, row1: any) {
    this.dialog.open(data, {
      width: "800px",
    });
    this.dcnumber = row1.data;
    this.dcnumber1 = row1.number
    
    this.getexistingfiles();
  }
  getexistingfiles() {
    let params = new HttpParams();
    params = new HttpParams()
      .set("document_number", this.dcnumber1)
      .set("document_type", "EquipmentDetails");
    this.custservice.getexistingfies(params).subscribe((res: any) => {
      if (res && res["status_code"] == "200") {
        const uniqueFiles = [];
        const uniqueFileNames = new Set();

        for (const file of res.data) {

          if (!uniqueFileNames.has(file.file_name)) {
            uniqueFileNames.add(file.file_name);
            uniqueFiles.push(file);
          }
        }
        this.filedata = uniqueFiles;
        console.log(this.filedata, "Unique files array");
        this.createNewFile.fileName = "";
      } else {
        this.filedata = [];
        console.log(this.filedata, "Empty filedata");
      }
    });
  }
  viewDoc(file: any) {
    const url = file.file_path;

    window.open(url, '_blank');
  }
}
