<div class="material-tab">
    <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms" [(selectedIndex)]="demo1TabIndex">
        <mat-tab>
            <ng-template mat-tab-label> Storage Location </ng-template>
            <ng-template matTabContent>
                <mat-card class="dashboard-card">
                    <mat-card-content class="dashboard-card-content">
                        <form id="slform" #slform="ngForm" (ngSubmit)="saveformdata(slform)">
                            <div class="row">
                                <div class="col-3">
                                    <label>Company Name<span class="mandatory">*</span></label>
                                    <mat-form-field class="example-full-width" style=" width:100%;
                                      border: 1px solid rgba(0, 0, 0, 0.12);
                                      border-radius: 5px;
                                      height: 35px;" appearance="none">
                                        <input type="text" class="inputdropdown1" placeholder="Search Company"
                                            aria-label="Number" matInput name="companyName" [(ngModel)]="companyName"
                                            (keyup)="SearchCompanyData($event)" [matAutocomplete]="auto1" />
                                        <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                                            <mat-option *ngFor="let item of companyData1" [value]="item.company_name">
                                                {{ item.company_name }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="col-3">
                                    <label>Material<span class="mandatory">*</span></label>
                                    <mat-form-field class="example-full-width materialName" 
                                        appearance="none">
                                        <input type="text" placeholder="Search Material Code/Name " aria-label="Number"
                                            matInput style="border: none;" class="inputdropdown1" name="selectematcode"
                                            [(ngModel)]="selectematcode" (keyup)="getmaterialDATA($event)"
                                            [matAutocomplete]="auto4" />
                                        <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete"
                                            (optionSelected)="selectedmastergroup()">
                                            <mat-option *ngFor="let item of masterData"
                                                [value]="item.system_reference_1">
                                                {{ item.system_reference_1 }}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="col-2 mt-4 pt-1 btn1">
                                    <span class="d-flex mt-1">
                                        <button mat-raised-button class="savebutton "  [disabled]="!companyName || !selectematcode">
                                            Search
                                        </button>
                                        <button mat-raised-button class="ml-3 downloadbtn" type="button" [disabled]="!companyName && !selectematcode" (click)="clearForm()">
                                            RESET
                                        </button>
                                    </span>
                                </div>
                               
                            </div>
                        </form>
                        <div class="row mt-3" *ngIf="smsdata.length>0">
                            <div class="col-6">
                                <h2 style="font-weight: 400; font-size: 18px">
                                  Material Code:&nbsp;
                                  <span class="spanstyles">{{ selectedmatcode }}</span>
                                </h2>
                              </div>
                            <div class="col-6">
                              <h2 style="font-weight: 400; font-size: 18px">
                                Material Description:&nbsp;
                                <span class="spanstyles">{{ selectedmatName }}</span>
                              </h2>
                            </div>
                            
                          <div class="col-6">
                            <h2 style="font-weight: 400; font-size: 18px">
                                Company Name:&nbsp;
                                <span class="spanstyles">{{ companyName }}</span>
                              </h2>
                          </div>
                          <div class="col-6">
                            <h2 style="font-weight: 400; font-size: 18px">
                                Available Stock:&nbsp;
                                <span class="spanstyles">{{avalstockqty}}</span>
                              </h2>
                          </div>

                          <div class="col-12 tableclass">
                          <table>
                            <thead>
                              <tr class="tablestyles">
                                  <th class="text-center">Storage Location</th>
                                  <th class="text-center">Valuation Type</th>
                                  <th class="text-center">Quantity</th>
                                  <th class="text-center">Action</th>
                                 
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of smsdata">
                                <td class="text-center">{{ item.storage_location }}</td>
                                <td class="text-center">{{ item.valuation_type }}</td>
                                <td class="text-right qty">{{ item.quantity }}</td>
                                <td class="text-center"> <mat-icon matTooltip="Edit" style="
                                    border: none;background: none;cursor: pointer;color:blue;" (click)="editgidata(item,slmodel)">edit</mat-icon></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        </div>
                        <div *ngIf="smsdata.length == 0 && noData" class="text-center mt-4 font-bold">No Data Found</div>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #slmodel>
    <!-- <h1 mat-dialog-title></h1> -->
     <div  mat-dialog-title class="mb-0">
      <div class="row">
        <div class="col-12 text-right">
          <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon">
              <mat-icon class="icon1">close</mat-icon>
          </button>
      </div>
      </div>
     </div>
    <div mat-dialog-content class="editmdl">
      <div class="row">
        <div class="col-md-12 mt-3">
            <label>Enter New Location To Move</label>
            <mat-form-field class="example-full-width" style=" width:100%;
            border: 1px solid rgba(0, 0, 0, 0.12); border-radius: 5px;height: 35px;"
                appearance="none">
                <input type="text" placeholder="Search Material Code/Name " aria-label="Number"
                    matInput style="border: none;" class="inputdropdown1" name="newstoragelocation"
                    [(ngModel)]="newstoragelocation" [matAutocomplete]="auto5" />
                <mat-autocomplete autoActiveFirstOption #auto5="matAutocomplete">
                    <mat-option *ngFor="let item of storagelocations"
                        [value]="item">
                        {{ item }}</mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
      </div>
    </div>
    <br />
    <div mat-dialog-actions class="justify-content-center mb-2">
      <!-- <button mat-button mat-dialog-close class="matButton">Close</button>&nbsp; -->
      <button mat-button cdkFocusInitial (click)="saveeditreason()" class="matButton1" [disabled]="!newstoragelocation">
        Submit
      </button>
    </div>
  </ng-template>