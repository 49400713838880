import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogOverviewExampleDialog implements OnInit {
  @Output() approveClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
  ngOnInit(): void {  
  }

  onYesClick(reason: string): void {
    if (this.data.status === 'APPROVE') {
      // Emit event with data
      this.approveClicked.emit({ reason: reason });
    } else if(this.data.status === 'REJECT'){
      this.approveClicked.emit({ reason: reason });
    }else {
      this.dialogRef.close(reason);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
 
}
