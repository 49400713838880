<!-- <div class="page-breadcrumb page-header">
    <div class="row">
        <div class="col-12 align-self-center">
            <h4 class="page-title">Company Master</h4>
            <div class="d-flex align-items-center m-t-10">
                <span class="m-r-20">Start Date : {{startDate | date}} </span>
                <span>End Date : {{endDate | date}} </span>
            </div>
        </div>
    </div>
</div> -->
<div class="material-tab">
    <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms">
        <mat-tab>
            <ng-template mat-tab-label>
                Company Master
            </ng-template>
            <ng-template matTabContent>

        <mat-card>
            <mat-card-content>
                <div class="row p-10">
<div class="col-6">
    <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
    <input type="search" class="searchfield"  placeholder="Search By Name/Code" [(ngModel)]="searchData" (input)="searchdata()">
</div>
                    <div class="col-6  float-right text-right">

                        <button mat-icon-button value="Add" matTooltip="Add" class="custom-icon-button "
                            (click)="addcompany(companymodel)">
                            <span class="material-icons">
                                add
                            </span>
                        </button>


                    </div>

                </div>

                <ng-container>

                    <div class="row p-t-10">
                        <div class="col-12 col-md-12">
                            <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                            <div class="table-responsive mat-table-wrapper">
                                <div class="example-table-container" style="width: 116%;">
                                    <table mat-table [dataSource]="dataSource">


                                        <ng-container matColumnDef="sno">
                                            <th mat-header-cell *matHeaderCellDef >S.NO</th>
                                            <td mat-cell *matCellDef="let row;let i=index;">
                                                {{(i+1) + (masterpaginator?.pageIndex * masterpaginator?.pageSize)}}

                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="code">
                                            <th mat-header-cell *matHeaderCellDef style="width:4%">
                                                Code</th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.code}}

                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="project_name">
                                            <th mat-header-cell *matHeaderCellDef >
                                                Project Name</th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.project_name}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef >
                                                Name</th>
                                            <td mat-cell *matCellDef="let row" matTooltip="{{row.name}}">
                                                {{row.name.length>40 ? (row.name | slice:0:40)+'...' : row.name  }}
                                            </td>
                                        </ng-container>


                                        <ng-container matColumnDef="billing_address">
                                            <th mat-header-cell *matHeaderCellDef >Billing Address
                </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.address_1 }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="shipping_address">
                                            <th mat-header-cell *matHeaderCellDef >Shipping Address
                </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.address_2 }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="contact_number">
                                            <th mat-header-cell *matHeaderCellDef >Contact Number</th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.contact_number}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="gst_number">
                                            <th mat-header-cell *matHeaderCellDef >GST Number
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.gst_number}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="pan_number">
                                            <th mat-header-cell *matHeaderCellDef >PAN Number
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.pan_number}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="city">
                                            <th mat-header-cell *matHeaderCellDef >City</th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.city }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="state">
                                            <th mat-header-cell *matHeaderCellDef > State</th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.state }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="country">
                                            <th mat-header-cell *matHeaderCellDef > Country
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.country }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="pincode">
                                            <th mat-header-cell *matHeaderCellDef >
                                                Pincode
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.pin_code}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="prefix">
                                            <th mat-header-cell *matHeaderCellDef >
                                                Prefix
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.prefix}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="logo">
                                            <th mat-header-cell *matHeaderCellDef >
                                                Logo
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.logo}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="business_unit_name">
                                            <th mat-header-cell *matHeaderCellDef >
                                                Business Unit Name
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.business_unit_name}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="cluster_name">
                                            <th mat-header-cell *matHeaderCellDef >
                                                Cluster Name
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.cluster_name}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="vertical_name">
                                            <th mat-header-cell *matHeaderCellDef >
                                                Vertical Name
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.vertical_name}}
                                            </td>
                                        </ng-container>
                                        <!-- <ng-container matColumnDef="ActiveStatus">
                                            <th mat-header-cell *matHeaderCellDef style="width:5%">
                                                Active Status
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.is_active}}
                                            </td>
                                        </ng-container> -->
                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef style="width:11%">
                                                Actions
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                <button mat-icon-button matTooltip="Edit"
                                                    aria-label="Example icon button with a vertical three dot icon"
                                                   (click)="editdata(row,companyeditmodel)" >
                                                    <mat-icon style="color: blue;">edit</mat-icon>
                                                </button>

                                                <button mat-icon-button style="color: red;" matTooltip="Delete"
                                                    aria-label="Example icon button with a vertical three dot icon" (click)="deletedata(row,deleteFileModal)"
                                                    >
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>


                                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                    </table>
                                </div>
                                <div *ngIf="companydata==true" class="text-center">
                                    <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                        Loading...
                                    </h4>
                                    <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                                        No Records Found
                                    </h4>
                                </div>
                                
                            </div>
                           <ng-container *ngIf="totalRecords >0">
                            <mat-paginator [length]="totalRecords" [pageSize]="10"
                            [pageSizeOptions]="[5,10, 50, 100, 500, 1000]" #masterpaginator
                            (page)="onpageevent($event)">
                            </mat-paginator>
                           </ng-container>
                        </div>
                    </div>
                </ng-container>

            </mat-card-content>
        </mat-card>
        </ng-template>
        </mat-tab>
        </mat-tab-group>
        </div>
        <ng-template #companymodel>
            <div mat-dialog-title class="dialog-title mb-0">
                <div class="row">
                    <div class="col-11">
                <h2>Add Company Master</h2>
                </div>
                <div class="col-1 text-right">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon">
                    <mat-icon class="icon1">close</mat-icon>
                </button>
                </div>
                </div>
            </div>
            <mat-dialog-content>
                <form #myform="ngForm" (ngSubmit)="savecompanydata(myform)">
                 
                    <div class="row">
                        <div class="col-md-2">
                            <label>Project Name<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <select class="inputstyles" name="projectname" [(ngModel)]="projectname" (change)="selectedproject()">
                <option value="" selected disabled>Select One</option>
               <option *ngFor="let item of cmpydata" [ngValue]="item.proj_id">{{item.proj_short_name}}</option>  
            </select> 
                        </div>
                        <div class="col-md-2">
                            <label>Name<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter Name" class="inputstyles" name="companyname"
                                [(ngModel)]="companyname">
                        </div>
        
                        <!-- </div> -->
                        <!-- <div class="col-md-2">
                            <label>Address</label>
                        </div>
                        <div class="col-md-4">
                            <textarea rows="2" style="width:88%;height:88%;border-radius: 5px;" name="address"
                                [(ngModel)]="address" class="nguntouched"></textarea>
                        </div> -->
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Billing Address<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter Billing Address" class="inputstyles" name="address"
                                [(ngModel)]="address">
                        </div>
        
                        <!-- </div> -->
                        <div class="col-md-2">
                            <label>Shipping Address<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter Shipping Address" class="inputstyles" name="shippingaddress"
                            [(ngModel)]="shippingaddress">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Contact Number<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter Contact Number" class="inputstyles" name="contactnumber" [(ngModel)]="contactnumber" (keypress)="keyPressNumbers($event)"   pattern="[1-9]{1}[0-9]{9}"
                            maxlength="10">
                           
                        </div>
                        <div class="col-md-2">
                            <label>GST Number<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter GST Number" class="inputstyles" name="gstnumber" [(ngModel)]="gstnumber">
                            
                        </div>
        
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>PAN Number<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" placeholder="Enter PAN Number"
                             name="pan" [(ngModel)]="pan" (keypress)="keyPressAlphaNumeric($event)" pattern="[a-zA-Z0-9]*"  (ngModelChange)="pan = $event.toUpperCase()"
                             maxlength="10"> 
                        </div>
                        <div class="col-md-2">
                            <label>City<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter City" class="inputstyles" name="city" [(ngModel)]="city"> 
                        </div>
                        
        
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>State<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter State" class="inputstyles" name="state" [(ngModel)]="state">
                        </div>
                        <div class="col-md-2">
                            <label>Country<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter Country" class="inputstyles" name="country" [(ngModel)]="country">
                        </div>
                        
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Pin Code<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter Pincode" class="inputstyles" name="pincode" [(ngModel)]="pincode" maxlength="6">
                        </div>
                        <div class="col-md-2">
                            <label>Prefix<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter Prefix" class="inputstyles" name="prefix" [(ngModel)]="prefix"  (keypress)="keyPressAlphaNumeric($event)">
                        </div>
                        
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Logo<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="example-full-width" appearance="none" class="matformfieldstyles">
                                <input type="text" placeholder="Search Company Logo " aria-label="Number" matInput
                                    style="border:none" class="inputdropdown" name="logo" [(ngModel)]="logo"
                                        (keyup)="getlogoDATA1($event)"
                                    [matAutocomplete]="auto7" />
            
                                <mat-autocomplete autoActiveFirstOption #auto7="matAutocomplete">
            
                                    <mat-option *ngFor="let item of companylogoData" [value]="item.logo">
                                        {{item.logo }}</mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <!-- <input type="text" class="inputstyles" name="logo" [(ngModel)]="logo"> -->
                        </div>
                        <div class="col-md-2">
                            <label>Vertical Name<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <select class="inputstyles" name="verticalname" [(ngModel)]="verticalname" (change)="selectedVerticalData()">
                                <option value="" selected disabled>Select One</option>
                               <option *ngFor="let item of verticalmasterData" [ngValue]="item.name">{{item.name}}</option>  
                            </select> 
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Cluster Name<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <select class="inputstyles" name="clustername" [(ngModel)]="clustername" (change)="selectedClusterMasterData()">
                                <option value="" selected disabled>Select One</option>
                               <option *ngFor="let item of ClusterMasterdata" [ngValue]="item.name">{{item.name}}</option>  
                            </select>  
                        </div>
                        <div class="col-md-2">
                            <label>Bussiness Unit<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <select class="inputstyles" name="businessunitname" [(ngModel)]="businessunitname" (change)="selectedBusinessUnitData()">
                                <option value="" selected disabled>Select One</option>
                               <option *ngFor="let item of BusinessUnitdata" [ngValue]="item.name">{{item.name}}</option>  
                            </select> 
                        </div>
                    </div><br>
                    <button mat-raised-button type="submit" class="savebutton mb-4" [disabled]="!projectname || !companyname || !address || !shippingaddress || !contactnumber || !gstnumber || !pan || !city || !state || !country || !pincode || !prefix || !logo || !verticalname || !clustername || !businessunitname">Save</button>
                </form>
            </mat-dialog-content>
        </ng-template>
        <ng-template #companyeditmodel>
            <div mat-dialog-title class="dialog-title">
                <div class="row">
                    <div class="col-11">
                        <h2>Edit Company Master</h2>
                    </div>
                    <div class="col-1 text-right">
                        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon">
                            <mat-icon class="icon1">close</mat-icon>
                        </button> 
                    </div>
                </div>
            </div>
            <mat-dialog-content>
                <form #myeditform="ngForm" (ngSubmit)="savecompanyeditdata(myeditform)">
                    
                    <div class="row">
                        <div class="col-md-2">
                            <label>Project Name<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <select class="dropdownstyles" name="projectnameedit" [(ngModel)]="projectnameedit" (change)="selectedprojectedit()">
                <option selected disabled value="">Select One</option>
               <option *ngFor="let item of cmpydata" [ngValue]="item.proj_id">{{item.proj_short_name}}</option>
              
            </select> 
                        </div>
                        <div class="col-md-2">
                            <label>Name<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" readonly class="inputstyles" name="companyeditname"
                                [(ngModel)]="companyeditname">
                        </div>
        
                        <!-- </div> -->
                        <!-- <div class="col-md-2">
                            <label>Address</label>
                        </div>
                        <div class="col-md-4">
                            <textarea rows="2" style="width:88%;height:88%;border-radius: 5px;" name="addressedit"
                                [(ngModel)]="addressedit" class="nguntouched"></textarea>
                        </div> -->
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Billing Address<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter Billing Address" class="inputstyles" name="addressedit"
                                [(ngModel)]="addressedit">
                        </div>
        
                        <!-- </div> -->
                        <div class="col-md-2">
                            <label>Shipping Address<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter Shipping Address" class="inputstyles" name="shippingaddressedit"
                            [(ngModel)]="shippingaddressedit">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Contact Number<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="contactnumberedit" (keypress)="keyPressNumbers($event)"   pattern="[1-9]{1}[0-9]{9}"
                  maxlength="10" [(ngModel)]="contactnumberedit">
                           
                        </div>
                        <div class="col-md-2">
                            <label>GST Number<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="gstnumberedit" [(ngModel)]="gstnumberedit">
                            
                        </div>
        
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>PAN Number<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" 
                             name="panedit" [(ngModel)]="panedit" (keypress)="keyPressAlphaNumeric($event)"
                             maxlength="10" pattern="[a-zA-Z0-9]*"  (ngModelChange)="panedit = $event.toUpperCase()"> 
                        </div>
                        <div class="col-md-2">
                            <label>City<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="cityedit" [(ngModel)]="cityedit"> 
                        </div>
                       
        
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>State<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text"  class="inputstyles" name="stateedit" [(ngModel)]="stateedit">
                        </div>
                        <div class="col-md-2">
                            <label>Country<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="countryedit" [(ngModel)]="countryedit">
                        </div>
                    
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Pin Code<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="pincodeedit" [(ngModel)]="pincodeedit">
                        </div>
                        <div class="col-md-2">
                            <label>Prefix<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="prefixedit" [(ngModel)]="prefixedit" (keypress)="keyPressAlphaNumeric($event)">
                        </div>
                        
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Logo<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="example-full-width" appearance="none" class="matformfieldstyles">
                                <input type="text" placeholder="Search Company Logo " aria-label="Number" matInput
                                    style="border:none" class="inputdropdown" name="logoedit" [(ngModel)]="logoedit"
                                        (keyup)="getlogoDATA2($event)"
                                    [matAutocomplete]="auto7" />
            
                                <mat-autocomplete autoActiveFirstOption #auto7="matAutocomplete">
            
                                    <mat-option *ngFor="let item of companylogoData" [value]="item.logo">
                                        {{item.logo}}</mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <!-- <input type="text" class="inputstyles" name="logoedit" [(ngModel)]="logoedit"> -->
                        </div>
                        <div class="col-md-2">
                            <label>Vertical Name<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <select class="inputstyles" name="verticalnameedit" [(ngModel)]="verticalnameedit" (change)="selectedVerticalDataedit()">
                                <option value="" selected disabled>Select One</option>
                               <option *ngFor="let item of verticalmasterData" [ngValue]="item.name">{{item.name}}</option>  
                            </select> 
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Cluster Name<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <select class="inputstyles" name="clusternameedit" [(ngModel)]="clusternameedit" (change)="selectedClusterMasterDataedit()">
                                <option value="" selected disabled>Select One</option>
                               <option *ngFor="let item of ClusterMasterdata" [ngValue]="item.name">{{item.name}}</option>  
                            </select>  
                        </div>
                        <div class="col-md-2">
                            <label>Bussiness Unit<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <select class="inputstyles" name="businessunitnameedit" [(ngModel)]="businessunitnameedit" (change)="selectedBusinessUnitDataedit()">
                                <option value="" selected disabled>Select One</option>
                               <option *ngFor="let item of BusinessUnitdata" [ngValue]="item.name">{{item.name}}</option>  
                            </select> 
                        </div>
                    </div><br>
                    <button mat-raised-button type="submit" class="savebutton mb-4" [disabled]="!projectnameedit || !companyeditname || !addressedit || !shippingaddressedit || !contactnumberedit || !gstnumberedit || !panedit || !cityedit || !stateedit || !countryedit || !pincodeedit || !prefixedit || !logoedit || !verticalnameedit || !clusternameedit || !businessunitnameedit">Update</button>
                </form>
            </mat-dialog-content>
        </ng-template>
        <ng-template #deleteFileModal>
            <div class="row">
                <div class="col-10">
                    <h1 mat-dialog-title>Delete Company Master</h1>
                </div>
                <div class="col-2">
                    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
                      >
                       <mat-icon class="icon1">close</mat-icon>
                    </button>
                </div>
            </div>
            <div mat-dialog-content>Are you sure, you want to delete record?</div>
            <div mat-dialog-actions class="justify-content-center">
                <!-- <button mat-button mat-dialog-close style="background-color: rgb(239, 164, 164);">Close</button> -->
                <button mat-button cdkFocusInitial (click)="deleteFile()">Delete</button>
            </div>
        </ng-template>
        
    

   


