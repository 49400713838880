<!-- <div class="page-breadcrumb page-header">
    <div class="row">
      <div class="col-12 align-self-center">
        <h4 class="page-title">Goods Issue</h4> -->
<!-- <div class="d-flex align-items-center m-t-10">
                  <span class="m-r-20">Start Date : {{startDate | date}} </span>
                  <span>End Date : {{endDate | date}} </span>
              </div> -->
<!-- </div>
    </div>
  </div> -->
  <div class="material-tab">
    <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms" [(selectedIndex)]="demo1TabIndex"
      (selectedTabChange)="onTabChanged()">
      <mat-tab>
        <ng-template mat-tab-label> Goods Issue </ng-template>
        <ng-template matTabContent>
          <mat-card>
            <mat-card-content>
              <form #myform="ngForm" (ngSubmit)="savefinaldata(myform)">
                <div class="row mb-3">
  
                  <div *ngIf="viewData" class="col-md-0 ml-2 mb-2 ">
                    <button mat-raised-button type="button" (click)="BacktoList()" class="savebutton">Back</button>
                  </div>
                  <div *ngIf="viewData" class="col-md-10 mt-1 text-right">
                    <p *ngIf="viewData" style="font-size: 16px;font-weight: 500;">View of Document Number:
                      {{viewnumber}}</p>
                  </div>
                  <!-- <div *ngIf="!viewData" class="col-md-0 ml-2  mr-1 mb-2">
                    <button mat-raised-button class="savebutton" color="secondary" [disabled]="saveddataarray.length == 0 "
                      [disabled]="updatebtn()  && dataSource.data.length > 0">
                      {{ btn }}
                    </button>
                  </div> -->
                  <div *ngIf="!viewData" class="col-md-0 ml-2 mr-1 mb-2">
                    <button mat-raised-button class="savebutton" color="secondary" 
                            [disabled]="saveddataarray.length == 0 || (updatebtn() && dataSource.data.length > 0)">
                      {{ btn }}
                    </button>
                  </div>
                  <div *ngIf="!viewData" class="col-md-0 ml-2 mb-2">
                    <button mat-raised-button type="button" (click)="clearpage1()" class="savebutton">Add New</button>
                  </div>
                  <div *ngIf="!viewData" class="col-md-2">
                    <button type="button" mat-raised-button class=" attachfile" color="secondary"
                      (click)="openNewFileUploadModal(newFileUploadModal , btn)">
                      Attach File&nbsp;
                      <mat-icon style="cursor: pointer; font-size: 24px">file_upload</mat-icon>
                    </button>
                    <!-- <button *ngIf="btn === 'Save" type="button" mat-raised-button class=" attachfile" color="secondary" (click)="openNewFileUploadModal(newFileUploadModal , 'Save')"
                    >
                    Attach File&nbsp;
                    <mat-icon style="cursor: pointer; font-size: 24px">file_upload</mat-icon>
                  </button> -->
                    <div class="col-md-0 mb-2">
                      <input hidden class="form-control-file" name="file" type="file" multiple
                        (change)="uploaddmrfiles($event)" #autoFileUpload2 accept=".doc, .pdf, .jpeg, .jpg, .png, .xlsx">
                      <ng-container *ngIf=" fileUploadUrlsdmr.length > 0">
                        <!-- <div *ngFor="let item of filenamearray;let i=index;">{{i+1}}.&nbsp;{{item}}
                              </div> -->
                      </ng-container>
                    </div>
                  </div>
                  <!-- <div *ngIf="!viewData && txnId" class="col-md-8 mt-1 text-right">
                    <p *ngIf="!viewData && txnId">Transaction Id: {{txnId}}</p>
                </div> -->
                </div>
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label>Request Number<span class="mandetory">*</span></label>
                    <p *ngIf="viewData" class="viewdata"> {{model1.requestnumber}}</p>
                    <input *ngIf="!viewData" type="text" class="inputstyles" name="requestnumber" 
                    [(ngModel)]="model1.requestnumber" (input)="trimInput()" placeholder="Enter Request Number" [disabled]="requestnumbervalue"/>
                    <!-- <select *ngIf="!viewData" class="dropdownstyles" name="requestnumber" [(ngModel)]="model1.requestnumber"
                      (change)="selectedreqnumber()">
                      <option [value]="undefined" selected disabled>Select One</option>
                      <option *ngFor="let item of materialreqdata" [ngValue]="item.number">
                        {{ item.number }}
                      </option>
                    </select> -->
  
  
                    <!-- <mat-form-field *ngIf="!viewData" class="example-full-width" style=" width:100%;
                    border: 1px solid rgba(0, 0, 0, 0.12);
                    border-radius: 5px;
                    height: 35px;" appearance="none">
                      <input type="text" placeholder="Search Request Number " aria-label="Number" matInput
                        style="border:none" class="inputdropdown1" name="requestnumber" (keyup)="getmaterialreqdata($event)"
                        [(ngModel)]="model1.requestnumber"[matAutocomplete]="auto11"  />
  
                      <mat-autocomplete autoActiveFirstOption #auto11="matAutocomplete"
                      (optionSelected)="selectedreqnumber()">
  
                      <mat-option *ngFor="let item of materialreqdata" [value]="item.number">
                        {{item.number}}</mat-option>
                    </mat-autocomplete>
                    </mat-form-field> -->
                    <!-- <input type="text" placeholder="Enter Request No"
                                            class="inputstyles" name="requestnumber" [(ngModel)]="model1.requestnumber"> -->
                  </div>
                  <div class="col-md-3">
                    <label>Date<span class="mandetory">*</span></label>
                    <p *ngIf="viewData" class="viewdata"> {{model1.dateee}}</p>
                    <input *ngIf="!viewData" type="date"  class="inputstyles"
                      name="dateee" [(ngModel)]="model1.dateee" [max]="today" dateFormat="yyyy/mm/dd" />
                  </div>
                  <div class="col-md-3">
                    <label>Company Name<span class="mandetory">*</span></label>
                    <p *ngIf="viewData" class="viewdata"> {{model1.companyname}}</p>
                    <select *ngIf="!viewData" class="dropdownstyles" name="companyname" [(ngModel)]="model1.companyname" readonly>
                      <option [value]="undefined" selected disabled>Select One</option>
                      <option *ngFor="let item of companyData1" [ngValue]="item.company_name">
                        {{ item.company_name }}
                      </option>
                    </select>
                  </div>
                  <!-- <div class="col-md-3">
                    <label>To Company Name<span class="mandetory">*</span></label>
                    <p *ngIf="viewData" class="viewdata"> {{model1.tocompany}}</p>
                    <select *ngIf="!viewData" class="dropdownstyles" name="tocompany" [(ngModel)]="model1.tocompany">
                      <option [value]="undefined" selected disabled>Select One</option>
                      <option *ngFor="let item of companyData1" [ngValue]="item.company_name">
                        {{ item.company_name }}
                      </option>
                    </select> -->
                  <!-- <input type="text" style="height:110%" placeholder="Enter To Company Name"
                                            class="inputstyles" name="tocompany" [(ngModel)]="model1.tocompany"> -->
                  <!-- </div> -->
                  <div class="col-md-3">
                    <label>Contractor Name<span class="mandetory">*</span></label>
                    <p *ngIf="viewData" class="viewdata"> {{model1.contractorname}}</p>
                    <mat-form-field *ngIf="!viewData" class="example-full-widthgi" style=" width:100%;
                    border: 1px solid rgba(0, 0, 0, 0.12);
                    border-radius: 5px;
                    height: 35px;" appearance="none">
                      <input type="text" class="inputdropdown" placeholder="Search Contractor Name" aria-label="Number"
                        matInput name="contractorname" [(ngModel)]="model1.contractorname"
                        (keyup)="filtecontractordatadata($event)" [matAutocomplete]="auto1" readonly/>
                      <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                        <mat-option *ngFor="let item of contracordata " [value]="item.name">
                          {{ item.name }}</mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <!-- <select class="dropdownstyles" name="contractorname" [(ngModel)]="model1.contractorname">
                                            <option selected disabled>Select One</option>
                                            <option *ngFor="let item of contracordata" [ngValue]="item.name">{{item.name}}
                                            </option>
                                        </select> -->
                    <!-- <input type="text" style="height:110%;" placeholder="Enter Contractor Name"
                                            class="inputstyles" name="contractorname" [(ngModel)]="model1.contractorname"> -->
                  </div>
                </div>
  
                <div class="row mb-3">
  
                  <div class="col-md-3">
                    <label>Request Date<span class="mandetory">*</span></label>
                    <p *ngIf="viewData" class="viewdata"> {{model1.requestdate}}</p>
                    <input *ngIf="!viewData" type="date" [max]="today" 
                      class="inputstyles" name="requestdate" [(ngModel)]="model1.requestdate" />
                  </div>
                  <div class="col-md-3">
                    <label>Receiver Name<span class="mandetory">*</span></label>
                    <p *ngIf="viewData" class="viewdata"> {{model1.receivername}}</p>
                    <input *ngIf="!viewData" type="text" class="inputstyles" name="receivername"
                      [(ngModel)]="model1.receivername" (input)="trimInput()" placeholder="Enter Receiver Name" />
                  </div>
                  <div class="col-md-3">
                    <label>Locations<span class="mandetory">*</span></label>
                    <p *ngIf="viewData" class="viewdata"> {{model1.locations}}</p>
                    <input *ngIf="!viewData" type="text" placeholder="Enter Locations" class="inputstyles"
                      name="locations" (input)="trimInput()" [(ngModel)]="model1.locations" />
                  </div>
                  <div class="col-md-3">
                    <label>Vehicle Number<span class="mandetory">*</span></label>
                    <p *ngIf="viewData" class="viewdata"> {{model1.vehiclenumber}}</p>
                    <!-- <mat-form-field *ngIf="!viewData" class="example-full-widthgi" style=" width:100%;
                    border: 1px solid rgba(0, 0, 0, 0.12);
                    border-radius: 5px;
                    height: 35px;" appearance="none">
                      <input type="text" id="inputdropdown" placeholder=" Search Vehicle Number" aria-label="Number"
                        matInput name="vehiclenumber" [(ngModel)]="model1.vehiclenumber"
                        (keyup)="filtervehicledata($event)" [matAutocomplete]="autovehicle" >
                      <mat-autocomplete autoActiveFirstOption #autovehicle="matAutocomplete"
                        (optionSelected)="selectedvehiclenumber()">
  
                        <mat-option *ngFor="let item of vehicledata" [value]="item.vehicle_number">
                          {{item.vehicle_number}}</mat-option>
                      </mat-autocomplete>
                    </mat-form-field> -->
  
                    <mat-form-field *ngIf="!viewData" class="example-full-widthgi" style="width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      height: 35px;" appearance="none">
                      <input type="text" id="inputdropdown" placeholder="Search Vehicle Number" aria-label="Number" maxlength="11"
                        matInput name="vehiclenumber" [(ngModel)]="model1.vehiclenumber"
                        (keyup)="filtervehicledata($event)" [matAutocomplete]="autovehicle">
                      <mat-autocomplete autoActiveFirstOption #autovehicle="matAutocomplete"
                        (optionSelected)="onVehicleNumberSelected($event)">
  
                        <mat-option *ngFor="let item of vehicledata" [value]="item.vehicle_number">
                          {{item.vehicle_number}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
  
                    <!-- <input
                        type="text"
                        placeholder="Enter Vehicle Number"
                        class="inputstyles"
                        name="vehiclenumber"
                        [(ngModel)]="model1.vehiclenumber"
                      /> -->
                  </div>
                </div>
                <!-- <div class="col-md-2">
                                        <label>Issue Type</label>
                                    </div>
                                    <div class="col-md-4">
                                        <select class="dropdownstyles" name="issuetype" [(ngModel)]="model1.issuetype">
                                            <option selected disabled >Select One</option>
                                            <option > Type1 </option>
                                            <option > Type2 </option>
                                        </select>
                                       
                                    </div> -->
                <div class="row">
                  <div class="col-md-3">
                    <label>Work Order Number<span class="mandetory">*</span></label>
                    <p *ngIf="viewData" class="viewdata"> {{model1.work_order_number}}</p>
                    <input *ngIf="!viewData" type="text" placeholder="Enter Comments" class="inputstyles"
                      name="work_order_number" (input)="trimInput()" [(ngModel)]="model1.work_order_number" />
                  </div>
                  <div class="col-md-3">
                    <label>Request Type<span class="mandetory">*</span></label>
                    <p *ngIf="viewData" class="viewdata"> {{model1.requesttype}}</p>
                    <select *ngIf="!viewData" class="dropdownstyles" name="requesttype" [(ngModel)]="model1.requesttype">
                      <option value="" selected disabled>Select One</option>
                      <option>Material</option>
                      <!-- <option>Fuel</option> -->
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label>Comments</label>
                    <p *ngIf="viewData" class="viewdata"> {{model1.comments}}</p>
                    <textarea rows="2" cols="50" style="resize:none" *ngIf="!viewData" type="text"
                      placeholder="Enter Comments" class="inputstyles" name="comments" (input)="trimInput()"
                      [(ngModel)]="model1.comments"></textarea>
                  </div>
  
  
                </div>
  
              </form>
  
              <!-- <div class="row">
                                <div class="col-md-2">
                                    <label>Total Value:</label>
                                </div>
                                <div class="col-md-4">
                                    {{gettotalvalue()}}
                                </div>
                                <div class="col-md-2">
                                    <label>No Of Items:</label>
                                </div>
                                <div class="col-md-4">
                                    {{saveddataarray.length}}
                                </div>
                            </div> -->
              <!-- <button mat-raised-button class="m-t-10" class="savebutton" style="width:6%;background-color: rgba(0, 0, 0, 0.12)" color="secondary" (click)="saveupdateddata()">Update</button><br> -->
              <div *ngIf="!viewData" class="row p-10">
                <!-- <div class="col-6">
            <input type="search" class="searchfield"  placeholder="Search By Name/Code" [(ngModel)]="searchData" (input)="searchdata()">
        </div> -->
                <!-- <div class="col-12 float-right text-right">
                  <button mat-icon-button value="Add" matTooltip="Add" class="custom-icon-button"
                    (click)="addgoodsissue()&&'panelOpenState === true'">
                    goodsissuemodel
                    <span class="material-icons"> add </span>
                  </button>
                </div> -->
              </div>
              <div class="row" *ngIf="!viewData">
                <div class="col-12">
                  <mat-accordion *ngIf="intialData">
                    <mat-expansion-panel [expanded]="panelOpenState" (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false" #matExpansionPanel1>
                      <mat-expansion-panel-header>
                        <mat-panel-title style="font-weight:bold" (click)="addmaterialreceipt()">
                          Add Items
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <!-- <p>I'm visible because I am open</p> -->
                      <div class="mt-3" *ngIf="intialData">
                        <form #myaddform="ngForm" (ngSubmit)="saveaddeddata(myaddform)">
                          <div class="row mb-4">
                            <div class="col-md-2">
                              <label>Material Code/Name<span class="mandetory">*</span></label>
                            </div>
                            <div class="col-md-10">
                              <mat-form-field class="example-full-width" style="
                                          width:100%;
                                          border: 1px solid rgba(0, 0, 0, 0.12);
                                          border-radius: 5px;
                                          height: 35px;
                                          background-color: #fff;
                                        " appearance="none">
                                <input type="text" placeholder="Search Material Code/Name " aria-label="Number" matInput
                                  style="border: none" class="inputdropdown1" name="matcode" [(ngModel)]="model.matcode"
                                  (keyup)="getmaterialDATA($event)" [matAutocomplete]="auto4" />
  
                                <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete"
                                  (optionSelected)="selectedmastergroup()">
                                  <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1">
                                    {{ item.system_reference_1 }}</mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                            </div>
  
                          </div>
                          <div class="row">
                            <div class="col-md-2">
                              <label>Storage Location<span class="mandetory">*</span></label>
                            </div>
                            <div class="col-md-4">
                              <mat-form-field class="example-full-width" style="
                                          width: 100%;
                                          border: 1px solid rgba(0, 0, 0, 0.12);
                                          border-radius: 5px;
                                          height: 35px;
                                          background-color: #fff;
                                        " appearance="none">
                                <input type="text" placeholder="Search Storage Location " aria-label="Number" matInput
                                  style="border: none" class="inputdropdown" name="storagelocation"
                                  [(ngModel)]="model.storagelocation" (keyup)="getstorageDATA($event)"
                                  [matAutocomplete]="auto5" />
  
                                <mat-autocomplete autoActiveFirstOption #auto5="matAutocomplete">
                                  <mat-option *ngFor="let item of smsdata1" [value]="item">
                                    {{ item }}</mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                            </div>
                            <div class="col-md-2">
                              <label>Unit Of Measurement<span class="mandetory">*</span></label>
                            </div>
                            <div class="col-md-4">
                              <input type="text" class="inputstyles" name="uom" [(ngModel)]="model.uom" readonly
                                placeholder="Unit Of Measurement" />
                            </div>
  
                          </div>
                          <br />
  
                          <div class="row">
                            <div class="col-md-2">
                              <label>Request Quantity<span class="mandetory">*</span></label>
                            </div>
                            <div class="col-md-4">
                              <input type="text" class="inputstyles" name="reqqty" [(ngModel)]="model.reqqty" (keyup)="selectedreqqty()"
                                placeholder="Enter Request Quantity" (keypress)="keyPressNumbers($event)"
                                 />
                            </div>
                            <div class="col-md-2">
                              <label>Issue Quantity<span class="mandetory">*</span></label>
                            </div>
                            <div class="col-md-4">
                              <input type="text" class="inputstyles" name="qty" [(ngModel)]="model.qty"
                                placeholder="Enter Issue Quantity" (keyup)="selectedqty()"
                                (keypress)="keyPressNumbers($event)" />
                            </div>
  
                          </div>
                          <br />
  
                          <div class="row">
                            <div class="col-md-2">
                              <label>Item Remarks<span class="mandetory">*</span></label>
                            </div>
                            <div class="col-md-4">
                              <input type="text" class="inputstyles" name="itemremarks" [(ngModel)]="model.itemremarks"
                                placeholder="Enter Item Remarks" />
                            </div>
                            <div class="col-md-2">
                              <label>Valuation Type<span class="mandetory">*</span></label>
                            </div>
                            <div class="col-md-4">
                              <select class="dropdownstyles" name="valution_type" [(ngModel)]="model.valutiontype">
                                <option value="">Select One</option>
                                <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">
                                  {{ valution.code }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <br />
                          <div class="row mb-2">
                            <div class="col-12 text-center">
                              <button mat-raised-button class="savebutton " style="font-weight: 500"
                                [disabled]="!model.matcode || !model.qty || !model.reqqty || !model.uom || !model.valutiontype ||!model.itemremarks ">
                                Add
                              </button>
                            </div>
                          </div>
                        </form>
                        <div *ngIf="smsdata" class="dialogdata">
                          <table class="mattable">
                            <thead>
                              <tr class="tablestyles">
                                <th>Company Name</th>
                                <th>Storage Location</th>
                                <th>Valuation Type</th>
                                <th>Quantity</th>
                                <!-- <th>Value</th> -->
                              </tr>
                            </thead>
                            <tbody >
                              <tr *ngFor="let item of smsdata" >
                                <td style="width: 28%">{{ item.company_name }}</td>
                                <td>{{ item.storage_location }}</td>
                                <td>{{ item.valuation_type }}</td>
                                <td>{{ item.quantity }}</td>
                                <!-- <td>{{item.value | number:'1.2-2'}}</td> -->
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </mat-expansion-panel>
  
                  </mat-accordion>
                  <mat-accordion *ngIf="editData">
                    <mat-expansion-panel [expanded]="editpanelOpenState" (opened)="editpanelOpenState = true"
                      (closed)="editpanelOpenState = false">
                      <mat-expansion-panel-header>
                        <mat-panel-title style="font-weight:bold">
                          Edit Items
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <!-- <p>I'm visible because I am open</p> -->
                      <div class="mt-3" *ngIf="editData">
                        <form #myaddeditform="ngForm" (ngSubmit)="saveaddededitdata(myaddeditform)">
                          <div class="row">
                            <div class="col-md-2">
                              <label>Material Code/Name<span class="mandetory">*</span></label>
                            </div>
                            <div class="col-md-10">
                              <!-- <select class="dropdownstyles" name="matcode" [(ngModel)]="model2.matcode"  (change)="selectedmastereditgroup()">
                                                <option selected disabled>Select One</option>
                                                <option *ngFor="let item of masterData" [ngValue]="item.system_reference_1">{{item.system_reference_1}}</option>
                                            </select> -->
                              <mat-form-field class="example-full-width" style="
                                          width: 100%;
                                          border: 1px solid rgba(0,0,0,0.12);
                                          border-radius: 5px;
                                          height: 71%;
                                          background-color: #fff;
                                        " appearance="none">
                                <input type="text" placeholder="Search Material Code/Name " aria-label="Number" matInput
                                  style="border: none" class="inputdropdown" name="matcode" [(ngModel)]="model2.matcode"
                                  (keyup)="getmaterialDATA1($event)" [matAutocomplete]="auto6" />
  
                                <mat-autocomplete autoActiveFirstOption #auto6="matAutocomplete"
                                  (optionSelected)="selectedmastereditgroup()">
                                  <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1">
                                    {{ item.system_reference_1 }}</mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                            </div>
  
                          </div>
                          <div class="row">
                            <div class="col-md-2">
                              <label>Storage Location<span class="mandetory">*</span></label>
                            </div>
                            <div class="col-md-4">
                              <!-- <select class="dropdownstyles" name="storagelocation" [(ngModel)]="model2.storagelocation">
                                                <option selected disabled>Select One</option>
                                               
                                                <option *ngFor="let item of storageData" [ngValue]="item.storage_location">{{item.storage_location}}</option>
                                            </select> -->
                              <mat-form-field class="example-full-width" style="
                                          width: 89%;
                                          border: 1px solid rgba(0,0,0,0.12);
                                          border-radius: 5px;
                                          height: 71%;
                                          background-color: #fff;
                                        " appearance="none">
                                <input type="text" placeholder="Search Storage Location " aria-label="Number" matInput
                                  style="border: none" class="inputdropdown txtlocation" name="storagelocation"
                                  [(ngModel)]="model2.storagelocation" (keyup)="getstorageDATA1($event)"
                                  [matAutocomplete]="auto7" />
  
                                <mat-autocomplete autoActiveFirstOption #auto7="matAutocomplete">
                                  <mat-option *ngFor="let item of smsdata1" [value]="item">
                                    {{ item}}</mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                            </div>
                            <div class="col-md-2">
                              <label>Unit Of Measurement<span class="mandetory">*</span></label>
                            </div>
                            <div class="col-md-4">
                              <input type="text" class="inputstyles" name="uom" [(ngModel)]="model2.uom" readonly />
                              <!-- <select class="dropdownstyles" name="uom" [(ngModel)]="model.uom">
                                                    <option selected disabled>Select One</option>
                                                    <option>hello</option>
                                                   
                                                </select> -->
                            </div>
  
                          </div>
                          <br />
  
                          <div class="row">
                            <div class="col-md-2">
                              <label>Request Quantity<span class="mandetory">*</span></label>
                            </div>
                            <div class="col-md-4">
                              <input type="text" class="inputstyles" name="reqqty" [(ngModel)]="model2.reqqty"
                                (keypress)="keyPressNumbers($event)" (keyup)="selectedreqqtyEdit()" />
                            </div>
                            <div class="col-md-2">
                              <label> Issue Quantity<span class="mandetory">*</span></label>
                            </div>
                            <div class="col-md-4">
                              <input type="text" class="inputstyles" name="qty" [(ngModel)]="model2.qty"
                                (keypress)="keyPressNumbers($event)" (keyup)="selectedreqqtyEdit()" />
                            </div>
  
                          </div>
                          <br />
                          <div class="row">
                            <div class="col-md-2">
                              <label>Item Remarks<span class="mandetory">*</span></label>
                            </div>
                            <div class="col-md-4">
                              <input type="text" class="inputstyles" name="itemremarks" [(ngModel)]="model2.itemremarks"
                                placeholder="Enter Item Remarks" />
                            </div>
                            <!-- <div class="col-md-2">
                                                        <label>Batch No<span class="mandetory">*</span></label>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input type="text" class="inputstyles" name="batchno"
                                                            [(ngModel)]="model2.batchno" >
                                                    </div> -->
                            <div class="col-md-2">
                              <label>Valuation Type<span class="mandetory">*</span></label>
                            </div>
                            <div class="col-md-4">
                              <select class="dropdownstyles" name="valution_type" [(ngModel)]="model2.valutiontype">
                                <option selected disabled>Select One</option>
                                <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">
                                  {{ valution.code }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <br />
  
                          <button mat-raised-button class="savebutton"
                            style="width: 8%; font-weight: 500; margin-left: 43%; margin-top: 3%" [disabled]="!model2.storagelocation || !model2.matcode || !model2.qty || !model2.reqqty || !model2.uom || !model2.valutiontype ||!model2.itemremarks ">
                            Update
                          </button>
                        </form>
                        <br />
                        <table *ngIf="smsdata" class="mattable">
                          <thead>
                            <tr class="tablestyles">
                              <th>Company Name</th>
                              <th>Storage Location</th>
                              <th>Valuation Type</th>
                              <th>Quantity</th>
                              <!-- <th>Value</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of smsdata">
                              <td style="width: 28%">{{ item.company_name }}</td>
                              <td>{{ item.storage_location }}</td>
                              <td>{{ item.valuation_type }}</td>
                              <td>{{ item.quantity }}</td>
                              <!-- <td>{{item.value | number:'1.2-2'}}</td> -->
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </mat-expansion-panel>
  
                  </mat-accordion>
                </div>
              </div>
              <ng-container>
                <div class="row p-t-10">
                  <div class="col-12 col-md-12">
                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                    <div class="table-responsive mat-table-wrapper">
                      <div class="example-table-container">
                        <table mat-table [dataSource]="dataSource">
                          <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%">
                              Action
                            </th>
                            <td mat-cell *matCellDef="let row; let i = index" class="iconaligment">
                              <!-- *ngIf="actiondisabled" -->
                              <span class="d-flex">
                                <button *ngIf="!viewData" mat-icon-button class="editiconbtn"
                                  aria-label="Example icon button with a vertical three dot icon"
                                  (click)="editdata(row, i)&&'editpanelOpenState === true'">
                                  <mat-icon matTooltip="Edit">edit</mat-icon>
                                </button>
                                <!-- goodsissueeditmodel -->
                                <button *ngIf="!viewData" mat-icon-button style="color: red; border: none"
                                  aria-label="Example icon button with a vertical three dot icon" (click)="deleterow(i)">
                                  <mat-icon matTooltip="Delete">delete</mat-icon>
                                </button>
                              </span>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef style="width: 5%">
                              S.NO
                            </th>
                            <td mat-cell *matCellDef="let row; let i = index">
                              {{ i + 1 }}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="material_code">
                            <th mat-header-cell *matHeaderCellDef style="width: 5%">
                              Material Code
                            </th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.material_code }}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="material_description">
                            <th mat-header-cell *matHeaderCellDef style="width: 5%">
                              Material Description
                            </th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.material_description }}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="unit_of_measurement">
                            <th mat-header-cell *matHeaderCellDef style="width: 4%">
                              UOM
                            </th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.uom || row.unit_of_measurment }}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="storage_location">
                            <th mat-header-cell *matHeaderCellDef style="width: 5%">
                              Storage Location
                            </th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.storagelocation || row.storage_location }}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="request_quantity">
                            <th mat-header-cell *matHeaderCellDef style="width: 6%">
                              Request Quantity
                            </th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.reqqty || row.request_quantity || 0 }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="quantity">
                            <th mat-header-cell *matHeaderCellDef style="width: 6%">
                              Issued Quantity
                            </th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.qty || row.quantity || 0 }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="item_remarks">
                            <th mat-header-cell *matHeaderCellDef style="width: 6%">
                              Item Remarks
                            </th>
                            <td mat-cell *matCellDef="let row">
                              <p class="m-0">{{ row.itemremarks || row.item_remarks }}</p>
                            </td>
                          </ng-container>
                          <!-- <ng-container matColumnDef="batch_no">
                              <th
                                mat-header-cell
                                *matHeaderCellDef
                                style="width: 5%"
                              >
                                Batch No
                              </th>
                              <td mat-cell *matCellDef="let row">
                                <p>{{ row.batchno || row.batch_number }}</p>
                              </td>
                            </ng-container> -->
                          <ng-container matColumnDef="valution_type">
                            <th mat-header-cell *matHeaderCellDef style="width: 5%">
                              Valuation Type
                            </th>
                            <td mat-cell *matCellDef="let row">
                              <p class="m-0">{{ row.valutiontype || row.valuation_type }}</p>
                            </td>
                          </ng-container>
  
  
  
                          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container><br />
            </mat-card-content>
          </mat-card>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label> MR Pending List </ng-template>
        <ng-template matTabContent>
          <mat-card>
            <mat-card-content>
              <ng-container>
                <div class="row m-t-10">
                  <div class="col-3 col-md-3 mt-2 mb-3 ">
                    <input type="search" class="searchfield" placeholder="Search MIR Number"
                     [(ngModel)]="pndsearchnumber" (input)="pndSearchData()">
                  </div>
                  <div class="col-9 col-md-9 mt-2 mb-3 text-right">
                    <button mat-icon-button value="Refresh" matTooltip="Refresh" (click)="refreshPndData()"
                      class="custom-icon-button ml-3">
                      <span class="material-icons">
                        refresh
                      </span>
                    </button>
                  </div>
                  <div class="col-md-12 col-12">
                    <div class="table table-responsive text-center" mat-table-wrapper>
                      <div class="example-table-container">
                        <table mat-table [dataSource]="dataSourceListpnd">
                          <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef class="text-center">S.NO</th>
                            <td mat-cell *matCellDef="let row; let i = index" class="text-center">
                              {{ i + 1 + matreqpaginatorpnd.pageIndex * matreqpaginatorpnd.pageSize}}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="work_order_number">
                            <th mat-header-cell *matHeaderCellDef>Work Order Number</th>
                            <td mat-cell *matCellDef="let row">{{ row.work_order_number }}</td>
                          </ng-container>
                          <ng-container matColumnDef="MSRNnum">
                            <th mat-header-cell *matHeaderCellDef>MR Number</th>
                            <td mat-cell *matCellDef="let row">{{ row.number }}</td>
                          </ng-container>
                          <ng-container matColumnDef="cmpnyName">
                            <th mat-header-cell *matHeaderCellDef>Company Name</th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.company_name }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="contractorName">
                            <th mat-header-cell *matHeaderCellDef>contractor Name</th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.contractor_name }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="created_date">
                            <th mat-header-cell *matHeaderCellDef>
                              Date
                            </th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.created_date | date: "dd/MM/yyyy" }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>Action</th>
                            <td mat-cell *matCellDef="let row" class="text-center">
                              <div class="mb-0">
                                <mat-icon   matTooltip="Proceed " class="proceedDoc"
                                (click)="selectedreqnumber(row)">note_add</mat-icon>
                                </div> 
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="displayedColumnsListpnd; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsListpnd"></tr>
                        </table>
                        <div *ngIf="reaDatapnd == true" class="text-center">
                          <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                            Loading...
                          </h4>
                          <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                            No Records Found
                          </h4>
                        </div>
                        
                          <mat-paginator   [length]="totalRecordspnd" [pageSize]="pageSizepnd" [pageIndex]="pageIndexpnd"
                            [pageSizeOptions]="[5, 10, 25, 50, 75, 100]" #matreqpaginatorpnd (page)="onpageeventpnd($event)">
                          </mat-paginator>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </mat-card-content>
          </mat-card>
  
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>GI List </ng-template>
        <ng-template matTabContent>
          <mat-card>
            <mat-card-content>
              <ng-container>
                <div class="row p-t-10">
                  <div class="col-md-4 mt-2 mb-3 d-flex">
                    <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                    <select class="dropdownstyles" name="columnname" [(ngModel)]="columnname" (change)="onChange()">
                      <option value="" selected disabled>Select Column</option>
                      <option value="number">MIS Number</option>
                      <!-- <option value="company_name">Company Name</option>
                      <option value="to_company_name">To Company Name</option> -->
                      <option value="contractor_name">Contractor Name</option>
                      <option value="request_number">Request Number</option>
                    </select>
                  </div>
                  <div class="col-md-3 mt-2 mb-3 ">
                    <input type="search" class="searchfield" placeholder="Search" [(ngModel)]="searchData"
                      (input)="search()">
                  </div>
                  <div class="col-5 col-md-5 mt-2 mb-3 text-right">
                    <button mat-icon-button value="Refresh" matTooltip="Refresh" (click)="getData()"
                      class="custom-icon-button ml-3">
                      <span class="material-icons">
                        refresh
                      </span>
                    </button>
                  </div>
                  <div class="col-12 col-md-12">
                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                    <div class="table-responsive mat-table-wrapper">
                      <div class="example-table-container">
                        <table mat-table [dataSource]="dataSourcemain" matSort>
                          <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef style="width: 6%">
                              S.NO
                            </th>
                            <td mat-cell *matCellDef="let row; let i = index" class="text-center">
                              {{(i+1) + (paginator?.pageIndex *
                              paginator?.pageSize)}}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="misNumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="number">MIS Number</th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.number }}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="Date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="date">Date</th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.date | date: "dd/MM/yyyy" }}
                            </td>
                          </ng-container>
  
                          <ng-container matColumnDef="company_Name">
                            <th mat-header-cell *matHeaderCellDef style="width: 14%" mat-sort-header="company_name">
                              Company Name
                            </th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.company_name }}
                            </td>
                          </ng-container>
  
                          <!-- <ng-container matColumnDef="tocompanyname">
                            <th mat-header-cell *matHeaderCellDef style="width: 14%">
                              To Company Name
                            </th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.to_company_name }}
                            </td>
                          </ng-container> -->
  
                          <ng-container matColumnDef="contractor_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="contractor_name">
                              Contractor Name
                            </th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.contractor_name }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="request_number">
                            <th mat-header-cell *matHeaderCellDef style="width: 7%" mat-sort-header="request_number">
                              Request Number
                            </th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.request_number }}
                            </td>
                          </ng-container>
                          <!-- <ng-container matColumnDef="issue_type">
                                                        <th mat-header-cell *matHeaderCellDef>Issue Type
                                                        </th>
                                                        <td mat-cell *matCellDef="let row">
                                                            {{row.issue_type}}
                                                        </td>
                                                    </ng-container> -->
                          <!-- <ng-container matColumnDef="ActiveStatus">
                                                        <th mat-header-cell *matHeaderCellDef>Active Status
                                                        </th>
                                                        <td mat-cell *matCellDef="let row">
                                                            {{row.is_active}}
                                                        </td>
                                                    </ng-container> -->
                          <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="width: 16%" class="text-center">
                              Action
                            </th>
                            <td mat-cell *matCellDef="let row" class="text-center">
                              <!-- <button mat-icon-button style="margin-left:-11%"
                                                                aria-label="Example icon button with a vertical three dot icon"
                                                                (click)="editsaveddata(row);tabGroup.selectedIndex = 0">
                                                                <mat-icon>edit</mat-icon>
                                                            </button> -->
                              <div class="mb-0">
                                <mat-icon matTooltip="View" style="border:none;background: none;cursor: pointer;"
                                  (click)="viewreason(row)">remove_red_eye</mat-icon>&nbsp;
                                <mat-icon matTooltip="Edit" style="
                                      border: none;
                                      background: none;
                                      cursor: pointer;color:blue; 
                                    " (click)="editgidata(row, editmodel)">edit</mat-icon>
                                <mat-icon matTooltip="Delete" style="
                                      color: red;
                                      border: none;
                                      background: none;
                                      cursor: pointer;
                                    " (click)="deleteItem(row, deleteItemModel)">delete</mat-icon>&nbsp;
                                <mat-icon matTooltip="Print" (click)="printdmr(row,'notificationsissue')" style="cursor: pointer">
                                  local_printshop</mat-icon>
  
                                &nbsp;
                                <mat-icon matTooltip="Upload" (click)="
                                      openfileuploadmodel(fileuploadmodel, row)
                                    " style="cursor: pointer; font-size: 24px;color:#28b351">file_upload</mat-icon>
                              </div>
                              <!-- <button mat-icon-button
                                                                style="color: red;border:none;background: none;"
                                                                aria-label="Example icon button with a vertical three dot icon"
                                                                (click)="deleteItem(row,deleteItemModel)">
                                                                <mat-icon>delete</mat-icon>
                                                            </button>
                                                            <button 
                                                            class="savebutton" style="margin-left: 1%;height: 21px;padding-right: 33px;font-size: 93%;"
                                                            aria-label="Example icon button with a vertical three dot icon"
                                                            (click)="printdmr(row)">Print
                                                          
                                                        </button>&nbsp;
                                                        <i class="fa fa-paperclip" aria-hidden="true" (click)="openfileuploadmodel(fileuploadmodel,row)"
                                                        style="cursor:pointer;font-size:16px"></i> -->
                            </td>
                          </ng-container>
  
                          <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
                        </table>
                      </div>
  
                      <div *ngIf="reaData == true" class="text-center">
                        <h4 class="text-secondary m-3" *ngIf="dataSourcemain.data.length >0">
                          Loading...
                        </h4>
                        <h4 class="text-secondary m-3" *ngIf="dataSourcemain.data.length === 0">
                          No Records Found
                        </h4>
                      </div>
                      <!-- <mat-paginator [length]="totalRecords" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50, 75, 100]"
                        #masterpaginator (page)="onpageevent($event)">
                      </mat-paginator> -->
                      <div class="d-flex justify-content-end">
                        <ng-container *ngIf="totalRecords>0">
                          <mat-paginator [length]="totalRecords" [pageSize]="10" [pageSizeOptions]="[5,10, 25, 50, 75, 100]"
                          #paginator (page)="onpageevent($event)">
                        </mat-paginator>
                        <!-- <div class="go-to-container mt-2">
                          <div class="go-to-label">Page Nos: </div>
                          <mat-form-field>
                            <mat-select [(ngModel)]="goTo" (selectionChange)="goToChange()">
                              <mat-option *ngFor="let pageNumber of pageNumbers"
                                [value]="pageNumber">{{pageNumber}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div> -->
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </mat-card-content>
          </mat-card>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>All Pending Approvals</ng-template>
        <ng-template matTabContent>
          <mat-card>
            <mat-card-content>
              <ng-container>
                <div class="row m-t-10">
                <div class="col-3 col-md-3 mt-2 mb-3 ">
                    <input type="search" class="searchfield" placeholder="Search MR Number"
                        [(ngModel)]="allpndsearchnumber" (input)="allpndSearchData()">
                    </div>
                    <div class="col-9 col-md-9 mt-2 mb-3 text-right">
                    <button mat-icon-button value="Refresh" matTooltip="Refresh" (click)="getDataallpnd()"
                        class="custom-icon-button ml-3">
                        <span class="material-icons">
                        refresh
                        </span>
                    </button>
                    </div>
                  <div class="col-md-12 col-12">
                    <div class="table table-responsive text-center" mat-table-wrapper>
                      <div class="example-table-container">
                        <table mat-table [dataSource]="dataSourceListallpnd">
                          <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef class="text-center">S.NO</th>
                            <td mat-cell *matCellDef="let row; let i = index" class="text-center">
                              {{
                              i +
                              1 +
                              mrpaginatorallpnd.pageIndex * mrpaginatorallpnd.pageSize
                              }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="document_name">
                            <th mat-header-cell *matHeaderCellDef>Document Name</th>
                            <td mat-cell *matCellDef="let row">{{ row.document_name }}</td>
                          </ng-container>
                          <ng-container matColumnDef="MSRNnum">
                            <th mat-header-cell *matHeaderCellDef>MR Number</th>
                            <td mat-cell *matCellDef="let row">{{ row.document_number }}</td>
                          </ng-container>
                          <ng-container matColumnDef="cmpnyName">
                            <th mat-header-cell *matHeaderCellDef>Company Name</th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.company_name }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="contractorName">
                            <th mat-header-cell *matHeaderCellDef>Concern Name</th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.concern_name }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef>
                              Description
                            </th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.description }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="level">
                            <th mat-header-cell *matHeaderCellDef>
                              Level
                            </th>
                            <td mat-cell *matCellDef="let row">
                              {{ row.level }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>Action</th>
                            <td mat-cell *matCellDef="let row" class="text-center">
                              <div class="mb-0">
                                <mat-icon 
                                  (click)="printdmr(row,'allpndnotificationsissue')" matTooltip="Print" style="cursor:pointer;">
                                  local_printshop</mat-icon>
                              </div>
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="displayedColumnsListallpnd; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumnsListallpnd"></tr>
                        </table>
                        <div *ngIf="reaDataallpnd == true" class="text-center">
                          <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                            Loading...
                          </h4>
                          <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                            No Records Found
                          </h4>
                        </div>
                        <mat-paginator [length]="totalRecordsallpnd" [pageSize]="pageSizeallpnd" [pageIndex]="pageIndexallpnd"
                          [pageSizeOptions]="[5, 10, 25, 50, 75, 100]" #mrpaginatorallpnd (page)="onpageeventallpnd($event)">
                        </mat-paginator>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </mat-card-content>
          </mat-card>
  
        </ng-template>
      </mat-tab>
     <!-- end -->
      <mat-tab>
        <ng-template mat-tab-label>
          Activity Log
        </ng-template>
        <ng-template matTabContent>
          <app-activitylog [activitylogfor]="'GoodsIssue'"></app-activitylog>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  
  <ng-template #goodsissuemodel>
    <div mat-dialog-title class="dialog-title">
      <h2>Add Items</h2>
      <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
        style="border: none; background: none">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-dialog-content style="overflow: initial">
      <form #myaddform="ngForm" (ngSubmit)="saveaddeddata(myaddform)">
        <div class="row mb-4">
          <div class="col-md-2">
            <label>Material Code/Name<span class="mandetory">*</span></label>
          </div>
          <div class="col-md-10">
            <!-- <select class="dropdownstyles" name="matcode" [(ngModel)]="model.matcode"  (change)="selectedmastergroup()">
                        <option selected disabled>Select One</option>
                        <option *ngFor="let item of masterData" [ngValue]="item.system_reference_1">{{item.system_reference_1}}</option>
                    </select> -->
            <mat-form-field class="example-full-width" style="
                  width:100%;
                  border: 1px solid rgba(0, 0, 0, 0.12);
                  border-radius: 5px;
                  height: 35px;
                " appearance="none">
              <input type="text" placeholder="Search Material Code/Name " aria-label="Number" matInput
                style="border: none" class="inputdropdown" name="matcode" [(ngModel)]="model.matcode"
                (keyup)="getmaterialDATA($event)" [matAutocomplete]="auto4" />
  
              <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete" (optionSelected)="selectedmastergroup()">
                <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1">
                  {{ item.system_reference_1 }}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
  
        </div>
        <div class="row">
          <div class="col-md-2">
            <label>Storage Location<span class="mandetory">*</span></label>
          </div>
          <div class="col-md-4">
            <!-- <select class=selectedmastergroup"dropdownstyles" name="storagelocation" [(ngModel)]="model.storagelocation">
                        <option selected disabled>Select One</option>
                       
                        <option *ngFor="let item of storageData" [ngValue]="item.storage_location">{{item.storage_location}}</option>
                    </select> -->
            <mat-form-field class="example-full-width" style="
                  width: 100%;
                  border: 1px solid rgba(0, 0, 0, 0.12);
                  border-radius: 5px;
                  height: 35px;
                " appearance="none">
              <input type="text" placeholder="Search Storage Location " aria-label="Number" matInput style="border: none"
                class="inputdropdown" name="storagelocation" [(ngModel)]="model.storagelocation"
                (keyup)="getstorageDATA($event)" [matAutocomplete]="auto5" />
  
              <mat-autocomplete autoActiveFirstOption #auto5="matAutocomplete">
                <mat-option *ngFor="let item of smsdata1" [value]="item">
                  {{ item }}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <label>Unit Of Measurement<span class="mandetory">*</span></label>
          </div>
          <div class="col-md-4">
            <input type="text" class="inputstyles" name="uom" [(ngModel)]="model.uom" placeholder="Unit Of Measurement" />
            <!-- <select class="dropdownstyles" name="uom" [(ngModel)]="model.uom">
                            <option selected disabled>Select One</option>
                            <option>hello</option>
                           
                        </select> -->
          </div>
  
        </div>
        <br />
  
        <div class="row">
          <div class="col-md-2">
            <label>Request Quantity<span class="mandetory">*</span></label>
          </div>
          <div class="col-md-4">
            <input type="text" class="inputstyles" name="reqqty" [(ngModel)]="model.reqqty"
              (keypress)="keyPressNumbers($event)" (keyup)="selectedreqqty()" />
          </div>
          <div class="col-md-2">
            <label>Issue Quantity<span class="mandetory">*</span></label>
          </div>
          <div class="col-md-4">
            <input type="text" class="inputstyles" name="qty" [(ngModel)]="model.qty"  (keypress)="keyPressNumbers($event)" (keyup)="selectedqty()" />
          </div>
  
        </div>
        <br />
  
        <div class="row">
          <div class="col-md-2">
            <label>Item Remarks<span class="mandetory">*</span></label>
          </div>
          <div class="col-md-4">
            <input type="text" class="inputstyles" name="itemremarks" [(ngModel)]="model.itemremarks" />
          </div>
          <!-- <div class="col-md-2">
                                <label>Batch No<span class="mandetory">*</span></label>
                            </div>
                            <div class="col-md-4">
                                <input type="text" class="inputstyles" name="batchno"
                                    [(ngModel)]="model.batchno" >
                            </div> -->
          <div class="col-md-2">
            <label>Valuation Type<span class="mandetory">*</span></label>
          </div>
          <div class="col-md-4">
            <select class="dropdownstyles" name="valution_type" [(ngModel)]="model.valutiontype">
              <option value="">Select One</option>
              <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">
                {{ valution.code }}
              </option>
            </select>
          </div>
        </div>
        <br />
  
        <!-- <div class="row">
                    <div class="col-md-2">
                        <label>Freight Split</label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" style="height:102%" class="inputstyles" name="freightsplit" [(ngModel)]="model.freightsplit">
                    </div>
                    <div class="col-md-2">
                        <label>Other Charges Split</label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" style="height:102%" class="inputstyles" name="otherchargessplit" [(ngModel)]="model.otherchargessplit">
                    </div>
                </div><br> -->
        <!-- <div class="row">
                    <div class="col-md-2">
                        <label>Other Tax Split</label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" style="height:102%" class="inputstyles" name="othertaxsplit" [(ngModel)]="model.othertaxsplit">
                    </div>
                    <div class="col-md-2">
                        <label>Total Price</label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" style="height:102%"  class="inputstyles" name="totalprice" [(ngModel)]="model.totalprice"  >
                    </div>
                </div><br> -->
        <div class="row mb-2">
          <div class="col-12 text-center">
            <button mat-raised-button class="savebutton " style="font-weight: 500" [disabled]="!model.matcode">
              Add
            </button>
          </div>
        </div>
      </form>
    </mat-dialog-content>
    <div *ngIf="smsdata" class="dialogdata">
      <table class="mattable">
        <thead>
          <tr class="tablestyles">
            <th>Company Name</th>
            <th>Storage Location</th>
            <th>Valuation Type</th>
            <th>Quantity</th>
            <!-- <th>Value</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of smsdata">
            <td style="width: 28%">{{ item.company_name }}</td>
            <td>{{ item.storage_location }}</td>
            <td>{{ item.valuation_type }}</td>
            <td>{{ item.quantity }}</td>
            <!-- <td>{{item.value | number:'1.2-2'}}</td> -->
          </tr>
        </tbody>
        
      </table>
      
    </div>
  </ng-template>
  <ng-template #goodsissueeditmodel>
    <div mat-dialog-title class="dialog-title">
      <h2>Edit Items</h2>
      <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
        style="border: none; background: none">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-dialog-content style="overflow: initial">
      <form #myaddeditform="ngForm" (ngSubmit)="saveaddededitdata(myaddeditform)">
        <div class="row">
          <div class="col-md-2">
            <label>Material Code/Name<span class="mandetory">*</span></label>
          </div>
          <div class="col-md-10">
            <!-- <select class="dropdownstyles" name="matcode" [(ngModel)]="model2.matcode"  (change)="selectedmastereditgroup()">
                        <option selected disabled>Select One</option>
                        <option *ngFor="let item of masterData" [ngValue]="item.system_reference_1">{{item.system_reference_1}}</option>
                    </select> -->
            <mat-form-field class="example-full-width" style="
                  width: 100%;
                  border: 1px solid rgba(0,0,0,0.12);
                  border-radius: 5px;
                  height: 52%;
                " appearance="none">
              <input type="text" placeholder="Search Material Code/Name " aria-label="Number" matInput
                style="border: none" class="inputdropdown" name="matcode" [(ngModel)]="model2.matcode"
                (keyup)="getmaterialDATA1($event)" [matAutocomplete]="auto6" />
  
              <mat-autocomplete autoActiveFirstOption #auto6="matAutocomplete"
                (optionSelected)="selectedmastereditgroup()">
                <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1">
                  {{ item.system_reference_1 }}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
  
        </div>
        <div class="row">
          <div class="col-md-2">
            <label>Storage Location<span class="mandetory">*</span></label>
          </div>
          <div class="col-md-4">
            <!-- <select class="dropdownstyles" name="storagelocation" [(ngModel)]="model2.storagelocation">
                        <option selected disabled>Select One</option>
                       
                        <option *ngFor="let item of storageData" [ngValue]="item.storage_location">{{item.storage_location}}</option>
                    </select> -->
            <mat-form-field class="example-full-width" style="
                  width: 89%;
                  border: 1px solid rgba(0,0,0,0.12);
                  border-radius: 5px;
                  height: 52%;
                " appearance="none">
              <input type="text" placeholder="Search Storage Location " aria-label="Number" matInput style="border: none"
                class="inputdropdown" name="storagelocation" [(ngModel)]="model2.storagelocation"
                (keyup)="getstorageDATA1($event)" [matAutocomplete]="auto7" />
  
              <mat-autocomplete autoActiveFirstOption #auto7="matAutocomplete">
                <mat-option *ngFor="let item of smsdata1" [value]="item">
                  {{ item}}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <label>Unit Of Measurement<span class="mandetory">*</span></label>
          </div>
          <div class="col-md-4">
            <input type="text" class="inputstyles" name="uom" [(ngModel)]="model2.uom" />
            <!-- <select class="dropdownstyles" name="uom" [(ngModel)]="model.uom">
                            <option selected disabled>Select One</option>
                            <option>hello</option>
                           
                        </select> -->
          </div>
  
        </div>
        <br />
  
        <div class="row">
          <div class="col-md-2">
            <label>Request Quantity<span class="mandetory">*</span></label>
          </div>
          <div class="col-md-4">
            <input type="text" class="inputstyles" name="reqqty" [(ngModel)]="model2.reqqty"
              (keypress)="keyPressNumbers($event)" />
          </div>
          <div class="col-md-2">
            <label> Issue Quantity<span class="mandetory">*</span></label>
          </div>
          <div class="col-md-4">
            <input type="text" class="inputstyles" name="qty" [(ngModel)]="model2.qty"
              (keypress)="keyPressNumbers($event)" />
          </div>
  
        </div>
        <br />
        <div class="row">
          <div class="col-md-2">
            <label>Item Remarks<span class="mandetory">*</span></label>
          </div>
          <div class="col-md-4">
            <input type="text" class="inputstyles" name="itemremarks" [(ngModel)]="model2.itemremarks" />
          </div>
          <!-- <div class="col-md-2">
                                <label>Batch No<span class="mandetory">*</span></label>
                            </div>
                            <div class="col-md-4">
                                <input type="text" class="inputstyles" name="batchno"
                                    [(ngModel)]="model2.batchno" >
                            </div> -->
          <div class="col-md-2">
            <label>Valuation Type<span class="mandetory">*</span></label>
          </div>
          <div class="col-md-4">
            <select class="dropdownstyles" name="valution_type" [(ngModel)]="model2.valutiontype">
              <option selected disabled>Select One</option>
              <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">
                {{ valution.code }}
              </option>
            </select>
          </div>
        </div>
        <br />
  
        <button mat-raised-button class="savebutton"
          style="width: 8%; font-weight: 500; margin-left: 43%; margin-top: 3%">
          Update
        </button>
      </form>
    </mat-dialog-content><br />
    <table *ngIf="smsdata" class="mattable">
      <thead>
        <tr class="tablestyles">
          <th>Company Name</th>
          <th>Storage Location</th>
          <th>Valuation Type</th>
          <th>Quantity</th>
          <!-- <th>Value</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of smsdata">
          <td style="width: 28%">{{ item.company_name }}</td>
          <td>{{ item.storage_location }}</td>
          <td>{{ item.valuation_type }}</td>
          <td>{{ item.quantity }}</td>
          <!-- <td>{{item.value | number:'1.2-2'}}</td> -->
        </tr>
      </tbody>
    </table>
  </ng-template>
  
  <ng-template #deleteItemModel>
    <h1 mat-dialog-title>Delete Activity</h1>
    <div mat-dialog-content>
      <p>Are you sure, you want to delete record?</p>
      <div class="row">
        <div class="col-md-2">
          <label style="padding-top: 5px">Reason</label>
        </div>
        <div class="col-md-4">
          <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
            [(ngModel)]="deletemodel.reason" style="width: 181%" />
        </div>
      </div>
    </div>
    <br />
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">Close</button>&nbsp;
      <button mat-button cdkFocusInitial (click)="deleteFile()" class="matButton" [disabled]="!deletemodel.reason">
        Delete
      </button>
    </div>
  </ng-template>
  <ng-template #fileuploadmodel>
    <div mat-dialog-title class="dialog-title">
      <div class="row">
        <div class="col-8">
         <h2>Goods Issue: {{ ginumber }}</h2> 
        </div>
        <div class="col-4">
          <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
          <mat-icon class="icon1">close</mat-icon>
        </button>
        </div>
      </div>
    </div>
    <div mat-dialog-content class="row">
      <div *ngIf="filedata">
        <!-- <span *ngFor="let item of filedata"> -->
        <!-- <span *ngFor="let item of filedata"> <a href="{{item.file_path}}">{{item.file_path}}</a></span> -->
        <!-- <h4>View File:&nbsp;&nbsp; <i class="fas fa-eye text-cyan m-r-20 " 
                style="cursor: pointer;" (click)="viewDoc(item)"></i></h4> -->
        <table>
          <thead style="background: #e9ecef" class="text-center">
            <!-- <tr> -->
            <th>Created By</th>
            <th>Created Date</th>
            <th>File Name</th>
            <th>Action</th>
            <!-- </tr> -->
          </thead>
          <tbody>
            <tr *ngFor="let item of filedata">
              <td>{{ item.created_by }}</td>
              <td>{{ item.created_date }}</td>
              <td>
                {{ item.file_name }} &nbsp;&nbsp;&nbsp;<i class="fas fa-eye text-cyan m-r-20" style="cursor: pointer"
                matTooltip="View File" (click)="viewDoc(item)"></i>
              </td>
              <td>
                <button mat-icon-button style="color: red; border: none; cursor: pointer" 
                  (click)="deleterowfile(deletefileModel, item)">
                  <mat-icon matTooltip="Delete">delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <a style="cursor: pointer;" (click)="viewDoc(item)">{{item.file_name}}</a> -->
        <!-- </span> -->
        <div class="text-center" *ngIf="filedata.length===0">
          No Data Found
      </div>
      </div>
    </div>
    <br />
    <button mat-raised-button type="button" (click)="autoFileUpload1.click()">
      Attach File
    </button>
    <div class="file">
      <input hidden class="form-control-file" name="file" type="file" multiple
        (change)="uploadWbsFile($event); showContent(i)" #autoFileUpload1 />
      <ng-container *ngIf="fileUploadUrls.length > 0">
        <!-- <span>{{ fileUploadUrls.length }}</span> -->
      </ng-container>
    </div>
  </ng-template>
  <ng-template #deletefileModel>
    <h1 mat-dialog-title>Delete Activity</h1>
    <div mat-dialog-content>Are you sure, you want to delete file?</div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">Close</button>&nbsp;
      <button mat-button cdkFocusInitial (click)="deleteexistingfile()" class="matButton">
        Delete
      </button>
    </div>
  </ng-template>
  <ng-template #editmodel>
    <h1 mat-dialog-title></h1>
    <div mat-dialog-content>
      <div class="row">
        <div class="col-md-2">
          <label style="padding-top: 5px">Reason</label>
        </div>
        <div class="col-md-4">
          <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
            [(ngModel)]="editModel.reason" style="width: 181%" />
        </div>
      </div>
    </div>
    <br />
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">Close</button>&nbsp;
      <button mat-button cdkFocusInitial (click)="saveeditreason()" class="matButton" [disabled]="!editModel.reason"
        style="background-color: #8dd94a;">
        Submit
      </button>
    </div>
  </ng-template>
  
  <ng-template #newFileUploadModal>
    <div mat-dialog-title class="dialog-title">
      <div class="row">
        <div class="col-8">
          <h2>File Upload</h2>
        </div>
        <div class="col-4">
          <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
          >
          <mat-icon class="icon1">close</mat-icon>
        </button>
        </div>
      </div>
    </div>
    <div *ngIf="!issubmitClicked">
      <div mat-dialog-content>
        <div *ngIf="selectedfiles.length > 0">
          <table>
            <thead style="background: #e9ecef;text-align: center;">
              <th>S.NO</th>
              <th>File Name</th>
              <th>Action</th>
            </thead>
            <tbody style="text-align: center;">
              <tr *ngFor="let item of selectedfiles;let i=index;">
                <td>{{i+1}}</td>
                <td>{{item.name}}</td>
                <td>
                  <button mat-icon-button style="color: red;border:none;cursor: pointer;" (click)="deleteFileItem(i)">
                    <mat-icon matTooltip="Delete">delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
  
        </div>
      </div><br>
      <button type="button" mat-raised-button class="attachfile ml-1" color="secondary" (click)="autoFileUpload2.click()">
        Attach File&nbsp; <mat-icon class="file2">file_upload</mat-icon></button>
      <div class="file">
        <input hidden class="form-control-file" name="file" type="file" multiple (change)="uploadgifiles($event)"
          #autoFileUpload2>
      </div>
    </div>
  
    <!-- editfile -->
    <div *ngIf="issubmitClicked">
      <div *ngIf="filedata && filedata.length>0">
        <!-- <span *ngFor="let item of filedata"> -->
        <!-- <span *ngFor="let item of filedata"> <a href="{{item.file_path}}">{{item.file_path}}</a></span> -->
        <!-- <h4>View File:&nbsp;&nbsp; <i class="fas fa-eye text-cyan m-r-20 " 
              style="cursor: pointer;" (click)="viewDoc(item)"></i></h4> -->
        <table>
          <thead style="background: #e9ecef">
            <!-- <tr> -->
            <th>Created By</th>
            <th>Created Date</th>
            <th>File Name</th>
            <th>Action</th>
            <!-- </tr> -->
          </thead>
          <tbody>
            <tr *ngFor="let item of filedata">
              <td>{{ item.created_by }}</td>
              <td>{{ item.created_date }}</td>
              <td>
                {{ item.file_name }} &nbsp;&nbsp;&nbsp;<i class="fas fa-eye text-cyan m-r-20" style="cursor: pointer"
                  title="View File" (click)="viewDoc(item)"></i>
              </td>
              <td>
                <button mat-icon-button style="color: red; border: none; cursor: pointer" 
                  aria-label="Example icon button with a vertical three dot icon"
                  (click)="deleterowfile(deletefileModel, item)">
                  <mat-icon matTooltip="Delete">delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <a style="cursor: pointer;" (click)="viewDoc(item)">{{item.file_name}}</a> -->
        <!-- </span> -->
      </div>
  
      <br />
      <button type="button" mat-raised-button class="attachfile ml-1" color="secondary" (click)="autoFileUpload1.click()">
        Attach File&nbsp; <mat-icon class="file2">file_upload</mat-icon></button>
      <div class="file">
        <input hidden class="form-control-file" name="file" type="file" multiple
          (change)="uploadWbsFile($event); showContent(i)" #autoFileUpload1 />
        <!-- <ng-container *ngIf="fileUploadUrls.length > 0"> -->
          <!-- <span>{{ fileUploadUrls.length }}</span> -->
        <!-- </ng-container> -->
      </div>
    </div>
  
  
  
  
  </ng-template>
  