<!-- <div class="page-breadcrumb page-header">
    <div class="row">
        <div class="col-12 align-self-center">
            <h4 class="page-title">Out Gate Pass</h4> -->
            <!-- <div class="d-flex align-items-center m-t-10">
                <span class="m-r-20">Start Date : {{startDate | date}} </span>
                <span>End Date : {{endDate | date}} </span>
            </div> -->
        <!-- </div>
    </div>
</div> -->
<div class="material-tab">
    <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms" #tabGroup [(selectedIndex)]="demo1TabIndex">
        <mat-tab >
            <ng-template mat-tab-label>
               Out Gate Pass
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <form #myform="ngForm" (ngSubmit)="savefinaldata(myform)">
                            <div class="row mb-3">
                                <div class="col-md-1">
                                    <button mat-raised-button class="m-t-10" class="savebutton" type="submit"
                                    color="secondary" [disabled]="saveddataarray.length==0">{{btn}}</button>
                                </div>
                                <div class="col-md-1">
                                    <button
                                    type="button"
                                    mat-raised-button
                                    class=" attachfile"
                                    color="secondary"
                                    (click)="autoFileUpload2.click()"
                                  >
                                    Attach File&nbsp;  <mat-icon 
                                    class="attachFileIcon">file_upload</mat-icon></button>
                                    <div class="file">
                                      <input hidden class="form-control-file" name="file" type="file" multiple
                                      (change)="uploadgtfiles($event)" #autoFileUpload2  >
                                      <ng-container *ngIf=" fileUploadUrlsgt.length > 0" class="mt-4">
                                    <div *ngFor="let item of filenamearray;let i=index;" style="white-space: nowrap;">{{i+1}}.&nbsp;{{item}}</div> 
    
                                      <!-- <span style="margin-left:3px">Selected : {{fileUploadUrlsgreturn.length}} files</span>   -->
                                      </ng-container>         
                                      </div>
                                </div>
                               </div>
    
                            <div class="row mb-3">
                                <div class="col-md-4">
                                    <label>Company Name<span class="mandetory">*</span></label>
                                    <select class="dropdownstyles" name="companyname" [(ngModel)]="model1.companyname">
                                        <option selected disabled>Select One</option>
                                        <option *ngFor="let item of companyData" [ngValue]="item.company_name">{{item.company_name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-2">
                                    <label>Date<span class="mandetory">*</span></label>
                                    <input type="date" style="text-transform: uppercase;"
                                    class="inputstyles" name="dateee" [(ngModel)]="model1.dateee"
                                    dateFormat="yyyy/mm/dd">
                                </div>
                                <div class="col-md-3">
                                    <label>Order Number<span class="mandetory">*</span></label>
                                    <input type="text" class="inputstyles" name="wonumber"
                                    [(ngModel)]="model1.wonumber">
                                </div>
                                <div class="col-md-3">
                                    <label>Concern Name<span class="mandetory">*</span></label>
                                    <!-- <input type="text" style="height:110%" placeholder="Enter To Company Name"
                                        class="inputstyles" name="tocompany" [(ngModel)]="model1.tocompany"> -->
                                        <select class="dropdownstyles" name="tocompany" [(ngModel)]="model1.tocompany">
                                            <option selected disabled>Select One</option>
                                            <option *ngFor="let item of companyData1" [ngValue]="item.name">{{item.name}}
                                            </option>
                                        </select>
                                </div>
                            </div>
                            
                            <!-- <div class="row">
                                <div class="col-md-2">
                                    <label>Contractor Name</label>
                                </div>
                                <div class="col-md-4">
                                   
                                        <select class="dropdownstyles" name="contractorname" [(ngModel)]="model1.contractorname">
                                            <option selected disabled>Select One</option>
                                            <option *ngFor="let item of contracordata" [ngValue]="item.name">{{item.name}}
                                            </option>
                                        </select>
                                </div>
                                <div class="col-md-2">
                                    <label>Giver Name</label>
                                </div>
                                <div class="col-md-4">
                                    <input type="text" class="inputstyles" name="givername"
                                        [(ngModel)]="model1.givername" placeholder="Enter Giver Name">
                                </div>
                               
                               
                            </div><br> -->
                            <div class="row mb-3">
                                <div class="col-md-4">
                                    <label>Receiver Name<span class="mandetory">*</span></label>
                                    <input type="text" class="inputstyles" name="receivername"
                                    [(ngModel)]="model1.receivername" placeholder="Enter Receiver Name">
                                </div>
                                <div class="col-md-2">
                                    <label>Request Date<span class="mandetory">*</span></label>
                                    <input type="date" style="text-transform:uppercase;" 
                                        class="inputstyles" name="requestdate" [(ngModel)]="model1.requestdate">
                                </div>
                                <div class="col-md-3">
                                    <label>Request Number<span class="mandetory">*</span></label>
                                    <input type="text" placeholder="Enter Request No"
                                    class="inputstyles" name="requestnumber" [(ngModel)]="model1.requestnumber">
                                </div>
                                <div class="col-md-3">
                                    <label>Transfer Type<span class="mandetory">*</span></label>
                                    <select class="dropdownstyles" name="transfertype" [(ngModel)]="model1.transfertype">
                                        <option selected disabled >Select One</option>
                                        <option > NRGP </option>
                                        <option > RGP </option>
                                    </select>
                                    <!-- <input type="text" style="height:102%;" placeholder="Enter Issue Type"
                                        class="inputstyles" name="issuetype" [(ngModel)]="model1.issuetype"> -->
                                </div>
                                </div>
                                
                            <div class="row mb-3">
                                <div class="col-md-4">
                                    <label>Vehicle Number<span class="mandetory">*</span></label>
                                    <input type="text" placeholder="Enter Vehicle No" class="inputstyles"
                                        name="vehiclenum" [(ngModel)]="model1.vehiclenum">
                                </div>
                                <div class="col-md-2">
                                    <label>Expected Return Date<span class="mandetory">*</span></label>
                                    <input type="date" style="text-transform: uppercase;"  class="inputstyles"
                                    name="expectedredate" [(ngModel)]="model1.expectedredate">
                                </div>
                                <div class="col-md-3">
                                    <label>Transporter Name<span class="mandetory">*</span></label>
                                    <input type="text" placeholder="Enter Transporter Name" class="inputstyles"
                                    name="transportername" [(ngModel)]="model1.transportername">
                                </div>
                                <div class="col-md-3">
                                    <label>LR Number<span class="mandetory">*</span></label>
                                    <input type="text" placeholder="Enter LR Number" class="inputstyles"
                                    name="lrnumber" [(ngModel)]="model1.lrnumber">
                                </div>
                                </div>
                               
                                <div class="row mb-3">
                                    <div class="col-md-4">
                                        <label>Comments<span class="mandetory">*</span></label>
                                        <input type="text" placeholder="Enter Comments"
                                        class="inputstyles" name="comments" [(ngModel)]="model1.comments">
                                    </div>
                                    <div class="col-md-2">
                                        <label>LR Date<span class="mandetory">*</span></label>
                                        <input type="date" style="text-transform: uppercase;"  class="inputstyles"
                                            name="lrdate" [(ngModel)]="model1.lrdate">
                                    </div>
                                </div>
                            
                          
                           

                        </form>
                        <div class="row p-10">
                        
                            <div class="col-12  float-right text-right">

                                <button mat-icon-button value="Add" matTooltip="Add" class="custom-icon-button "
                                    (click)="addgoodstransfer(goodstransfermodel)">
                                    <span class="material-icons">
                                        add
                                    </span>
                                </button>


                            </div>

                        </div>
                        <ng-container>

                            <div class="row p-t-10">
                                <div class="col-12 col-md-12">
                                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                    <div class="table-responsive mat-table-wrapper">
                                        <div class="example-table-container" >
                                            <table mat-table [dataSource]="dataSource">


                                                <ng-container matColumnDef="sno">
                                                    <th mat-header-cell *matHeaderCellDef style="width:5%">SNO</th>
                                                    <td mat-cell *matCellDef="let row;let i=index;">
                                                        {{i+1}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="material_code">
                                                    <th mat-header-cell *matHeaderCellDef style="width:5%">Material Code
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.material_code}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="material_description">
                                                    <th mat-header-cell *matHeaderCellDef style="width:5%">Material
                                                        Description
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{ row.material_description}}
                                                    </td>
                                                </ng-container>


                                                <ng-container matColumnDef="unit_of_measurement">
                                                    <th mat-header-cell *matHeaderCellDef style="width:4%">UOM
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.uom || row.unit_of_measurment}}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="storage_location">
                                                    <th mat-header-cell *matHeaderCellDef style="width:5%">Storage
                                                        Location</th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.storagelocation || row.storage_location }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="request_quantity">
                                                    <th mat-header-cell *matHeaderCellDef style="width:6%">Request
                                                        Quantity
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.reqqty || row.request_quantity }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="quantity">
                                                    <th mat-header-cell *matHeaderCellDef style="width:6%">
                                                        Quantity</th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.qty || row.quantity}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="item_remarks">
                                                    <th mat-header-cell *matHeaderCellDef style="width:6%">Item Remarks
                                                        </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        <p > {{row.itemremarks || row.item_remarks }}</p>
                                                       

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="batch_no">
                                                    <th mat-header-cell *matHeaderCellDef style="width:5%"> Batch No
                                                        
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        <p > {{row.batchno || row.batch_number}}</p>
                                                       

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="valution_type">
                                                    <th mat-header-cell *matHeaderCellDef style="width:5%"> Valuation Type
                                                        
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        <p > {{row.valutiontype || row.valuation_type}}</p>
                                                       

                                                    </td>
                                                </ng-container>
                                             
                                                <ng-container matColumnDef="action">
                                                    <th mat-header-cell *matHeaderCellDef style="width:10%">
                                                        Action
                                                    </th>
                                                    <td mat-cell *matCellDef="let row;let i=index;">
                                                        <button mat-icon-button
                                                            aria-label="Example icon button with a vertical three dot icon"
                                                            (click)="editdata(row,i,goodstransferditmodel)">
                                                            <mat-icon>edit</mat-icon>
                                                        </button>

                                                        <button mat-icon-button style="color: red;border:none"
                                                            aria-label="Example icon button with a vertical three dot icon"
                                                            (click)="deleterow(i)">
                                                            <mat-icon>delete</mat-icon>
                                                        </button>
                                                    </td>
                                                </ng-container>


                                                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                                </tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                            </table>
                                        </div>
                                      

                                    </div>
                                </div>
                            </div>
                        </ng-container><br>
                      


                    </mat-card-content>
                </mat-card>
               
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                List
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <ng-container>
                            <div class="row p-t-10">
                                <div class="col-4 mt-2 mb-3 d-flex">
                                    <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                                    <select class="dropdownstyles" name="columnname" [(ngModel)]="columnname" (change)="onChange()">
                                        <option [value]="undefined" selected disabled>Select Column</option>
                                        <option value="number">Stock Transfer Number</option>
                                        <option value="company_name">Company Name</option>
                                        <option value="to_company_name">To Company Name</option>
                                        <option value="request_number">Request Number</option>
                                        <!-- <option value="transfer_type">Transfer Type</option> -->
                                    </select>
                                </div>
                                <div class="col-3 mt-2 mb-3 ">
                                    <input type="search" class="searchfield"  placeholder="Search" [(ngModel)]="searchData" (input)="search()">
                                </div>
                                <div class="col-12 col-md-12">
                                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                    <div class="table-responsive mat-table-wrapper">
                                        <div class="example-table-container">
                                            <table mat-table [dataSource]="dataSourcemain">


                                                <ng-container matColumnDef="sno">
                                                    <th mat-header-cell *matHeaderCellDef style="width:6%">SNO</th>
                                                    <td mat-cell *matCellDef="let row;let i=index;" class="text-center">
                                                        {{(i+1) + (masterpaginator.pageIndex *
                                                        masterpaginator.pageSize)}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="StockTransferNumber">
                                                    <th mat-header-cell *matHeaderCellDef style="width:10%">Stock Transfer Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.number}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="Date">
                                                    <th mat-header-cell *matHeaderCellDef style="width:9%">Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.date | date: 'dd/MM/yyyy'}}
                                                    </td>
                                                </ng-container>


                                                <ng-container matColumnDef="company_Name">
                                                    <th mat-header-cell *matHeaderCellDef style="width:19%">Company Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.company_name }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="tocompanyname">
                                                    <th mat-header-cell *matHeaderCellDef style="width:19%">To Company Name</th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.to_company_name}}
                                                    </td>
                                                </ng-container>

                                                <!-- <ng-container matColumnDef="contractor_name">
                                                    <th mat-header-cell *matHeaderCellDef>Contractor Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.contractor_name}}
                                                    </td>
                                                </ng-container> -->
                                                <ng-container matColumnDef="request_number">
                                                    <th mat-header-cell *matHeaderCellDef>Request Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.request_number}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="transfer_type">
                                                    <th mat-header-cell *matHeaderCellDef>Transfer Type
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.Transfer_type}}
                                                    </td>
                                                </ng-container>
                                                <!-- <ng-container matColumnDef="ActiveStatus">
                                                    <th mat-header-cell *matHeaderCellDef>Active Status
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.is_active}}
                                                    </td>
                                                </ng-container> -->
                                                <ng-container matColumnDef="action">
                                                    <th mat-header-cell *matHeaderCellDef style="width:15%" class="text-center">
                                                        Action
                                                    </th>
                                                    <td mat-cell *matCellDef="let row" class="text-center">
                                                        <div class="mb-0">
                                                            
                                                        </div>
                                                        <!-- <button mat-icon-button style="margin-left:-11%"
                                                            aria-label="Example icon button with a vertical three dot icon"
                                                            (click)="editsaveddata(row);tabGroup.selectedIndex = 0">
                                                            <mat-icon>edit</mat-icon>
                                                        </button> -->

                                                        <mat-icon matTooltip="Edit" style="border:none;background: none;cursor: pointer;"
                                                        (click)="editgtdata(row,Editmodel)" >edit</mat-icon>
                                                            <mat-icon matTooltip="Delete" style="color: red;border:none;background: none;cursor: pointer;"
                                                            (click)="deleteItem(row,deleteItemModel)">delete</mat-icon>&nbsp; 
                                                         <mat-icon matTooltip="Print" (click)="printoutgatepass(row)" style="cursor:pointer;"> local_printshop</mat-icon>&nbsp;
                                                              <mat-icon matTooltip="Upload" (click)="openfileuploadmodel(fileuploadmodel,row)"
                                                            class="attachFileIcon">file_upload</mat-icon>
                                                        <!-- </button>
                                                        <i class="fa fa-paperclip" aria-hidden="true" (click)="openfileuploadmodel(fileuploadmodel,row)"
                                                        style="cursor:pointer;font-size:16px"></i> -->
                                                    </td>
                                                </ng-container>


                                                <tr mat-header-row *matHeaderRowDef="displayedColumns1;sticky: true">
                                                </tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
                                            </table>
                                        </div>
                                        <div *ngIf="reaData==true" class="text-center">
                                            <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                                Loading...
                                            </h4>
                                            <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                                                No Records Found
                                            </h4>
                                        </div>
                                        <mat-paginator [length]="totalRecords" [pageSize]="10"
                                            [pageSizeOptions]="[5,10, 25, 50, 75, 100]" #masterpaginator
                                            (page)="onpageevent($event)">
                                        </mat-paginator>

                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Activity Log
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <!-- <h2 *ngIf="logdata" class="activitylog">Activity Log</h2> -->
                        <div class="tablediv" *ngIf="logdata">
                            <table >
                                <thead style="background: #e9ecef;" class="text-center headerclass">
                                    <!-- <tr> -->
                                        <th>SNO</th>
                                        <th style="padding:10px;">User Name</th>
                                        <th>Created Date</th>
                                        <th>Reference Number</th>
                                        <th>Description</th>
                                        <th>Reason</th>
                                        <th>Action</th>
                                    <!-- </tr> -->
                                </thead>
                                <tbody class="text-center">
                                    <tr *ngFor="let item of logdata;let i=index;">
                                        <td>{{i+1}}</td>
                                        <td>{{item.username}}</td>
                                        <td>{{item.date_time | date:'dd-MM-yyyy HH:mm:ss'}}</td>
                                        <td >{{item.reference_number}} </td>
                                        <td >{{item.description}}</td>
                                        <td >{{item.reason}} </td>
                                            <td>{{item.action}}
                                                <!-- <button mat-icon-button style="color: red;border:none;cursor: pointer;"
                                                   aria-label="Example icon button with a vertical three dot icon"
                                                    >
                                                     <mat-icon>delete</mat-icon>
                                                     </button> -->
                                            </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        </mat-tab-group>
        
</div>



        <ng-template #goodstransfermodel>
            <div mat-dialog-title class="dialog-title">
                <h2>Add Items</h2>
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon"
                    (click)="closemodel()" style="border:none;background:none">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <mat-dialog-content style="overflow: initial;">
                <form #myaddform="ngForm" (ngSubmit)="saveaddeddata(myaddform)">
                    <div class="row">
                        <div class="col-md-2">
                            <label>Material Code/Name<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <!-- <select class="dropdownstyles" name="matcode" [(ngModel)]="model.matcode"  (change)="selectedmastergroup()">
                    <option selected disabled>Select One</option>
                    <option *ngFor="let item of masterData" [ngValue]="item.system_reference_1">{{item.system_reference_1}}</option>
                </select> -->
                            <mat-form-field class="example-full-width" style=" width:100%;
                border: 1px solid rgba(0, 0, 0, 0.12);
                border-radius: 5px;
                height: 52%;" appearance="none">
                                <input type="text" placeholder="Search Material Code/Name " aria-label="Number" matInput
                                    style="border:none" class="inputdropdown" name="matcode"
                                    [(ngModel)]="model.matcode" (keyup)="getmaterialDATA($event)"
                                    [matAutocomplete]="auto4" />

                                <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete"
                                    (optionSelected)="selectedmastergroup()">

                                    <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1">
                                        {{item.system_reference_1}}</mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div class="col-md-2">
                            <label>Storage Location<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <!-- <select class="dropdownstyles" name="storagelocation" [(ngModel)]="model.storagelocation">
                    <option selected disabled>Select One</option>
                   
                    <option *ngFor="let item of storageData" [ngValue]="item.storage_location">{{item.storage_location}}</option>
                </select> -->
                            <mat-form-field class="example-full-width" style=" width: 100%;
                border: 1px solid rgba(0, 0, 0, 0.12);
                border-radius: 5px;
                height: 52%;" appearance="none">
                                <input type="text" placeholder="Search Storage Location " aria-label="Number" matInput
                                    style="border:none" class="inputdropdown" name="storagelocation"
                                    [(ngModel)]="model.storagelocation" (keyup)="getstorageDATA($event)"
                                    [matAutocomplete]="auto5" />

                                <mat-autocomplete autoActiveFirstOption #auto5="matAutocomplete">

                                    <mat-option *ngFor="let item of storageData" [value]="item.storage_location">
                                        {{item.storage_location}}</mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Unit Of Measurement<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="uom"
                                [(ngModel)]="model.uom">
                            <!-- <select class="dropdownstyles" name="uom" [(ngModel)]="model.uom">
                        <option selected disabled>Select One</option>
                        <option>hello</option>
                       
                    </select> -->
                        </div>
                        <div class="col-md-2">
                            <label>Request Quantity<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="reqqty"
                                [(ngModel)]="model.reqqty" (keypress)="keyPressNumbers($event)"
                               >
                        </div>
                        
                    </div><br>

                    <div class="row">
                        <div class="col-md-2">
                            <label>Quantity<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="qty"
                                [(ngModel)]="model.qty" >
                        </div>
                        <div class="col-md-2">
                            <label>Item Remarks<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="itemremarks"
                                [(ngModel)]="model.itemremarks" 
                               >
                        </div>
                     
                    </div><br>
                  
                    <div class="row">
                        
                        <div class="col-md-2">
                            <label>Batch No<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="batchno"
                                [(ngModel)]="model.batchno" >
                        </div>
                        <div class="col-md-2">
                            <label>Valuation Type<span class="mandetory">*</span></label>
                        </div>
                            <div class="col-md-4">
                            <select class="dropdownstyles" name="valution_type" [(ngModel)]="model.valutiontype">
                                <option selected disabled>Select One</option>
                                <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">{{valution.code}}
                                </option>
                            </select>  
                            </div> 
                       
                    </div>
                   <div class="row">
                    <div class="col-12">
                        <button mat-raised-button  class="savebutton text-center" [disabled]="!model.matcode">Add</button>
                    </div>
                   </div>
                </form>
            </mat-dialog-content>
        </ng-template>
        <ng-template #goodstransferditmodel>
            <div mat-dialog-title class="dialog-title">
                <h2>Edit Items</h2>
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon"
                    (click)="closemodel()" style="border:none;background:none">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <mat-dialog-content style="overflow: initial;">
                <form #myaddeditform="ngForm" (ngSubmit)="saveaddededitdata(myaddeditform)">
                    <div class="row">
                        <div class="col-md-2">
                            <label>Material Code/Name<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <!-- <select class="dropdownstyles" name="matcode" [(ngModel)]="model2.matcode"  (change)="selectedmastereditgroup()">
                    <option selected disabled>Select One</option>
                    <option *ngFor="let item of masterData" [ngValue]="item.system_reference_1">{{item.system_reference_1}}</option>
                </select> -->
                            <mat-form-field class="example-full-width" style=" width: 100%;
                border: 1px solid rgba(0, 0, 0, 0.12);
                border-radius: 5px;
                height: 52%;" appearance="none">
                                <input type="text" placeholder="Search Material Code/Name " aria-label="Number" matInput
                                    style="border:none" class="inputdropdown" name="matcode"
                                    [(ngModel)]="model2.matcode" (keyup)="getmaterialDATA1($event)"
                                    [matAutocomplete]="auto6" />

                                <mat-autocomplete autoActiveFirstOption #auto6="matAutocomplete"
                                    (optionSelected)="selectedmastereditgroup()">

                                    <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1">
                                        {{item.system_reference_1}}</mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div class="col-md-2">
                            <label>Storage Location<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <!-- <select class="dropdownstyles" name="storagelocation" [(ngModel)]="model2.storagelocation">
                    <option selected disabled>Select One</option>
                   
                    <option *ngFor="let item of storageData" [ngValue]="item.storage_location">{{item.storage_location}}</option>
                </select> -->
                            <mat-form-field class="example-full-width" style=" width: 100%;
                border: 1px solid rgba(0, 0, 0, 0.12);
                border-radius: 5px;
                height: 52%;" appearance="none">
                                <input type="text" placeholder="Search Storage Location " aria-label="Number" matInput
                                    style="border:none" class="inputdropdown" name="storagelocation"
                                    [(ngModel)]="model2.storagelocation" (keyup)="getstorageDATA1($event)"
                                    [matAutocomplete]="auto7" />

                                <mat-autocomplete autoActiveFirstOption #auto7="matAutocomplete">

                                    <mat-option *ngFor="let item of storageData" [value]="item.storage_location">
                                        {{item.storage_location}}</mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Unit Of Measurement<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="uom"
                                [(ngModel)]="model2.uom">
                            <!-- <select class="dropdownstyles" name="uom" [(ngModel)]="model.uom">
                        <option selected disabled>Select One</option>
                        <option>hello</option>
                       
                    </select> -->
                        </div>
                        <div class="col-md-2">
                            <label>Request Quantity<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="reqqty"
                                [(ngModel)]="model2.reqqty" (keypress)="keyPressNumbers($event)"
                                >
                        </div>
                    </div><br>

                    <div class="row">
                        <div class="col-md-2">
                            <label> Quantity<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="qty"
                                [(ngModel)]="model2.qty" (keypress)="keyPressNumbers($event)"
                                >
                        </div>
                        <div class="col-md-2">
                            <label>Item Remarks<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="itemremarks"
                                [(ngModel)]="model2.itemremarks" readonly>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Batch No<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="batchno"
                                [(ngModel)]="model2.batchno" readonly>
                        </div>
                        <div class="col-md-2">
                            <label>Valuation Type<span class="mandetory">*</span></label>
                        </div>
                            <div class="col-md-4">
                            <select class="dropdownstyles" name="valution_type" [(ngModel)]="model2.valutiontype">
                                <option selected disabled>Select One</option>
                                <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">{{valution.code}}
                                </option>
                            </select>  
                            </div> 
                           
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button mat-raised-button class="savebutton text-center" >Update</button>
                        </div>
                    </div>
                   
                </form>
            </mat-dialog-content>
        </ng-template>

        <ng-template #deleteItemModel>
            <h1 mat-dialog-title>Delete Activity</h1>
            <div mat-dialog-content>
                <p>Are you sure, you want to delete record?</p>
                <div class="row">
                    <div class="col-md-2">
                        <label style="padding-top:5px;">Reason</label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                  [(ngModel)]="deletemodel.reason" style="width: 181%;">  
                    </div>
                </div>
            </div><br>
            <div mat-dialog-actions>
                <button mat-button mat-dialog-close class="matButton">Close</button>&nbsp;
                <button mat-button cdkFocusInitial (click)="deleteFile()" class="matButton"
                [disabled]="!deletemodel.reason">Delete</button>
            </div>
        </ng-template>
        <ng-template #fileuploadmodel>
            <div mat-dialog-title class="dialog-title">
                <h2>Out Gate Pass: {{goodstransfernum}}</h2>
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon"
                    (click)="closemodel()" style="border:none;background:none;margin-top: -9%;">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
           <div  mat-dialog-content class="row">
            <div *ngIf="filedata">
            <!-- <span *ngFor="let item of filedata"> -->
           <!-- <span *ngFor="let item of filedata"> <a href="{{item.file_path}}">{{item.file_path}}</a></span> -->
           <!-- <h4>View File:&nbsp;&nbsp; <i class="fas fa-eye text-cyan m-r-20 " 
            style="cursor: pointer;" (click)="viewDoc(item)"></i></h4> -->
            <table>
                <thead style="background: #e9ecef;">
                    <!-- <tr> -->
                        <th>Created By</th>
                        <th>Created Date</th>
                        <th>File Name</th>
                        <th>Action</th>
                    <!-- </tr> -->
                </thead>
                <tbody>
                    <tr *ngFor="let item of filedata">
                        <td>{{item.created_by}}</td>
                        <td>{{item.created_date}}</td>
                        <td >{{item.file_name}} &nbsp;&nbsp;&nbsp;<i class="fas fa-eye text-cyan m-r-20 " 
                            style="cursor: pointer;" title="View File" (click)="viewDoc(item)"></i></td>
                            <td>
                                <button mat-icon-button style="color: red;border:none;cursor: pointer;"
                                   aria-label="Example icon button with a vertical three dot icon"
                                    (click)="deleterowfile(deletefileModel,item)">
                                     <mat-icon>delete</mat-icon>
                                     </button>
                            </td>
                    </tr>
                </tbody>
            </table>
            <!-- <a style="cursor: pointer;" (click)="viewDoc(item)">{{item.file_name}}</a> -->
        <!-- </span> -->
        </div>
           </div><br>
 <button mat-raised-button type="button" (click)="autoFileUpload1.click()">Attach File</button>
        <div class="file">
        <input hidden class="form-control-file" name="file" type="file" multiple
        (change)="uploadWbsFile($event);showContent(i);" #autoFileUpload1 />
        <ng-container *ngIf="fileUploadUrls.length>0">
            <div *ngFor="let item of filenamearray1;let i=index">{{i+1}}.&nbsp;{{item}}</div>
    <!-- <span >Selected: {{fileUploadUrls.length}} files</span>   -->
    </ng-container>         
    </div>
        </ng-template>
        <ng-template #deletefileModel>
            <h1 mat-dialog-title>Delete Activity</h1>
            <div mat-dialog-content>Are you sure, you want to delete file?</div>
            <div mat-dialog-actions>
                <button mat-button mat-dialog-close class="matButton" >Close</button>&nbsp;
                <button mat-button cdkFocusInitial (click)="deleteexistingfile()" class="matButton">Delete</button>
            </div>
        </ng-template>
        <ng-template #Editmodel>
            <h1 mat-dialog-title></h1>
            <div mat-dialog-content>
        <div class="row">
            <div class="col-md-2">
                <label style="padding-top:5px;">Reason</label>
            </div>
            <div class="col-md-4">
                <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
          [(ngModel)]="editModel.reason" style="width: 181%;">  
            </div>
        </div>
        </div><br>
        <div mat-dialog-actions>
            <button mat-button mat-dialog-close class="matButton" >Close</button>&nbsp;
            <button mat-button cdkFocusInitial (click)="saveeditreason()" class="matButton"
            [disabled]="!editModel.reason">Submit</button>
        </div>
        </ng-template>