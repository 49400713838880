import { Component, ElementRef, EventEmitter, Injector, OnInit, Output, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { CustomerService } from "src/app/services/customer.service";
import * as moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NoopScrollStrategy } from "@angular/cdk/overlay";
import { Overlay } from "@angular/cdk/overlay";
import { AlertCallsService } from "src/app/auth/alert-calls.service";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { HttpParams } from "@angular/common/http";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatAccordion } from "@angular/material/expansion";
import { MatSort } from "@angular/material/sort";
import { FormControl } from "@angular/forms";

@Component({
  selector: 'app-esdn',
  templateUrl: './esdn.component.html',
  styleUrls: ['./esdn.component.scss']
})
export class EsdnComponent implements OnInit {
  POID: any[] = [];
  dataSource = new MatTableDataSource<any>();
  dataSource1 = new MatTableDataSource();
  dataSourcemain = new MatTableDataSource();
  dataSourcemain1 = new MatTableDataSource();
  dataSourcemainlog = new MatTableDataSource();
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() page = new EventEmitter<PageEvent>();
  @ViewChild('deletefileModel') deletefileModel: ElementRef;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('masterpaginator') masterpaginator:MatPaginator;
  txnId: string;
  viewEdit: boolean = false
  viewData: boolean = false;
  EditData:boolean = true;
  saveddataarray: any[] = [];
  SNO: any[];
  matCode: any[];
  UOM: any[];
  matName: any[];
  storageLocation: any[];
  InvoiceQuantity: any[];
  RecQTY: any[];
  shrQTY: any[];
  editModelReason: any = {};
  showViewData: any = []
  excsQTY: any[];
  unitPrice: any[];
  discPERCENT: any[];
  discVALUE: any[];
  basicPRICE: any[];
  taxDESC: any[];
  taxVALUE: any[];
  taxPERCEN: any[];
  otherTAXSPLIT: any[];
  freightSPLIT: any[];
  otherchargesSPLIT: any[];
  totalPRICE: any[];
  valuationType: any[];
  model1: {};
  countdmr: number=0;
  reaDatalog: boolean;
  data: any = [];
  selectednumber: any;
  number: any;
  selectedIndex = -1;
  panelOpenState: boolean = false;
  intialData: boolean = false;
  editpanelOpenState: boolean = false;
  editData: boolean = false;

  editPanelData : boolean = false
  SUM: any = 0;
  pageIndex: any = 1;
  pageIndex1: any = 1;
  pageNumbers: any = []
  pageSize: number = 10;
  logpagesize:number = 10;
  totalRecords: any = 0;
  dmrctrl = new FormControl();
  btn: any = "Save"
  dmrNumberData: any[] = [];
  selectedDMRData: any = {
    number: '',
    date1:this.getCurrentDate(),
    date: '',
    vendor_name: '',
    purchase_order_number: '',
    transporter_name: '',
    vehicle_number: '',
    lr_number: '',
    lr_date: '',
    company_name: '',

  };
  showTable = false;
  submitdata: any = [];
  data1: any = [];
  editednumber: any;
  deleteNumber: any;
  dmrnumber: any;
  fileUploadUrls: any[] = [];
  fileUploadUrlsdmr: any[] = [];
  filenamearray: any[] = [];
  selectedfiles: any[] = []
  logsearchData: any;
  logdata: any;
  activityLogDownload: any = [];
  totalRecordslog: any=0;
  editabledata: any;
  viewnumber: any;
  deletemodel: any = {};
  saveDataArray: any[] = [];
  data2: any = [];
  model2: any = {};
  systemref: any;
  matcodedata: any;
  masterData: any;
  model2matcode: any;
  materialCODE: any;
  materialNAME: any;
  valuefrminvedit: any = 0;
  valuefrmrecedit: any = 0;
  unitValueedit: any = 0;
  selectedmaterial: any;
  selectedmaterial1: any;
  deleteid: string;
  edit_id: any;
  filedata: any=[];
  dialogRef: any = null;
  createNewFile: any;
  reaData: boolean;
  columnname: string ='';
  searchData: any;
  goTo: any;
  editid: any;
  listData: any[] = [];
  viewid: any;
  viewcompany_name: any;
  Edit_id: any;
  data_id: any;
  reqIndex: any;
  selectedMenus$: any = [];
  @ViewChild('menuInput')
  menuInput!: ElementRef<HTMLInputElement>;
  menuControl = new FormControl();
  resultqcnumber: any;
  matDESCRIPTION: any[];
  ITEMREMARKS: any[];
  inv_qty: any[];
  QC_QTY: any[];
  esdnnumber: any;
  deleteEsdn: any;
  today: string;
  ngAfterViewInit() {
    this.dataSourcemain1.paginator = this.paginator;
  }
  openpurchaseordermodel(data: any) {
    this.POID = []
    this.dialog.open(data, {
      width: '600px'
    })
    this.number = '';
  }

  closemodel() {
    this.dialog.closeAll()
  }

  displayedColumns: any[] = [
    'action',
    'sno',
    'material_code',
    'material_description',
    'unit_of_measurment',
    'challan_quantity',
    'received_quantity',
    'remarks'

  ];

  displayedColumns1: any[] = [
    "sno",
    "misNumber",
    "Date",
    "company_Name",
    "tocompanyname",
    "contractor_name",
    "count",
    "action",
  ];
 
  demo1TabIndex: any = 0;
  
  constructor(
    private dialog: MatDialog,
    private custservice: CustomerService,
    private snackbar: MatSnackBar,
    public overlay: Overlay,
    private alertcall: AlertCallsService,
    private router: Router,
    private route: ActivatedRoute,
    private injector: Injector,

  ) { }


  ngOnInit(): void {
    this.getData();
    this.getTransactionId();
    this.getEsdndata();
    this.getmasterdata();
    this.route.queryParams.subscribe((params: any) => {
      if (params.tab == "notifications") {
        this.demo1TabIndex = 1;
      } else {
        this.demo1TabIndex = 0;
      }
    });
    this.selectedDMRData.date1=this.getCurrentDate()
  }


  getpurchaseorderDATA(ev: any) {
    this.selectednumber = ev.target.value
    if (this.selectednumber.length > 2) {
      this.getData()
    }
    if (!this.selectednumber) {
      this.getData()
    }
  }


  getEsdndata() {
    this.pageNumbers = []
    let obj = {
      "command": "list",
      "perpage": this.pageSize,
      "page": this.pageIndex,

    }
    this.custservice.getEsdndata(obj).subscribe((res: any) => {
      this.reaData = false;
      this.totalRecords = res?.count;
      this.dataSourcemain1.data = res.data
      this.dataSourcemain1.sort = this.sort;
      for (let i = 1; i <= Math.ceil(this.totalRecords / this.pageSize); i++) {
        this.pageNumbers.push(i);
      }
      this.goTo = this.pageIndex
      if (res.data.length == 0) {
        this.reaData = true
      }
    })
  }

  goToChange() {
    this.paginator.pageIndex = this.goTo - 1;
    const event: PageEvent = {
      length: this.paginator.length,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
    };
    this.paginator.page.next(event);
  }

  saveData() {
    this.viewData = (this.btn === "Save") ? false : false;
    
    let material_data:any = []
    this.dmrNumberData.map((rec) => {
      var matObj = {
        "action": "string",
        "line_item": "string",
        "material_code": rec.material_code,
        "matrerial_description": rec.material_description,
        "unit_of_measurement": rec.uom || rec.unit_of_measurment,
        "challan_quantity": Number(rec.matQuantity),
        "received_quantity": Number(rec.received_quantity),
        "remark": rec.remark || "NA",
        "_id":(this.btn !== "Save") ? rec._id : 0,
      };
      if(this.btn === "Save"){
        delete matObj._id;
       
      }
      material_data.push(matObj);
    })

    let obj = {
      "command": (this.btn === "Save") ? "add" : "update",
      "date": moment(this.selectedDMRData.date1).format("YYYY-MM-DD"),
      "dmr_number": this.selectedDMRData.number,
      "dmr_date": moment(this.selectedDMRData.date).format("YYYY-MM-DD"),
      "vendor_name": this.selectedDMRData.vendor_name,
      "purchase_order_number": this.selectedDMRData.purchase_order_number,
      "transporter_name": this.selectedDMRData.transporter_name,
      "vehicle_number": this.selectedDMRData.vehicle_number,
      "lr_no": this.selectedDMRData.lr_number,
      "lr_date": moment(this.selectedDMRData.lr_date).format("YYYY-MM-DD"),
      "company_name": this.selectedDMRData.company_name,
      "user_department": this.selectedDMRData.user_department,
      "buyer_name": this.selectedDMRData.buyer_name,
      "status": this.selectedDMRData.status,
      "descripency_open_note": this.selectedDMRData.descripency_open_note,
      "action_token": this.selectedDMRData.action_token,
      "material_data": material_data,
      "reason":(this.btn !== "Save") ? this.editModelReason.reason : "",
      "esdn_number":(this.btn !== "Save") ? this.selectedDMRData.esdn_number : ""


    }

    if(this.btn === "Save"){
      delete obj.reason;
      delete obj.esdn_number;
    }

    this.custservice.createEsdndata(obj).subscribe(
      (res: any) => {
        if (res && res['status'] == "Success") {
          Swal.fire({
            text: res['message'],
            title: res['reference'] || res['data'],
            icon: 'success',
            width: 500,
          });
          this.submitdata = res.data
          this.resetInputFields()
          this.getEsdndata();
          this.editData = false;
          this.getTransactionId();
          this.getData();
          this.dataSource = new MatTableDataSource<any>()
          this.resultqcnumber=res['data' || 'reference']
            this.uploadselectedfiles()
          this.selectedfiles = [];
          this.viewEdit = false;
        } else {
          this.alertcall.showWarning('Accepted', res['message']);
          this.resetInputFields()
          this.selectedfiles = [];
          this.dataSource = new MatTableDataSource<any>()
        }
      },
    );
    
  }
 
  resetData(){
    this.resetInputFields();
    this.selectedfiles = []
    this.getTransactionId();
    this.viewEdit = false;
  }
  resetInputFields() {
    this.selectedDMRData = {};
    this.editModelReason.reason = ""
    this.number = '';
    this.EditData = true;
    this.intialData = false
    this.panelOpenState = false
    this.editData = false;
    this.editpanelOpenState = false;
    this.countdmr = 0
    this.dataSource = new MatTableDataSource<any>()
    this.btn = "Save"
    this.selectedDMRData.date1=this.getCurrentDate()
    this.displayedColumns = ['action', 'sno', 'material_code', 'material_description', 'unit_of_measurment', 'challan_quantity', 'received_quantity', 'remarks'
    ];
  }

  getTransactionId() {
    this.txnId = ""
    this.custservice.getTransactionId().subscribe((res: any) => {
      if (res) {
        this.txnId = res.txn_id
      }
    })
  }

  getData() {
    let params = new HttpParams()
    params = new HttpParams()
      .set("dmr_number", this.number || "")
    this.custservice.getDmrNumber(params).subscribe((res: any) => {
      this.data = res.data
      this.filteredData = this.data;
    })
  }


  submitData() {

    this.POID = []
    let obj = {
      "dmr_number": this.number,
    }
    this.custservice.getDmrNumberMaterials(obj).subscribe((res: any) => {
      this.viewData = false
      this.dmrNumberData = res.data
      // this.dmrNumberData['unit_of_measurement'] = res.data['']
      this.dmrNumberData.map((rec) => {
        rec['unit_of_measurement'] = rec.unit_of_measurment;
        rec['matQuantity'] = rec.invoice_quantity;
        rec['remark'] = rec.item_remarks;
      })
      this.masterData = res.data
      res.data.forEach((ele: any) => {
        this.POID.push(ele.number)
      })

      const selectedDMRData = this.dmrNumberData.find((item: any) => item.number === this.number);
      this.selectedDMRData = selectedDMRData;
      this.selectedDMRData.date = moment(this.selectedDMRData.date).format("YYYY-MM-DD")
      this.selectedDMRData.date1 = moment(new Date()).format("YYYY-MM-DD")
      this.selectedDMRData.lr_date = moment(this.selectedDMRData.lr_date).format("YYYY-MM-DD")
      this.dataSource.data = this.dmrNumberData;
      this.selectedfiles = [];
      this.countdmr=0
      this.dmrNumberData.forEach((ele: any,index) => {
        this.countdmr =  index+1
      })
    })
  }

  filteredData: any[] = [];

  // onSearch(event: any) {
  //   const searchTerm = event.target.value.toLowerCase();
  //   this.filteredData = this.data.filter((item: any) => item.number.toLowerCase().includes(searchTerm));
  // }
  editMaterialData(row1: any, index: any) {
  
    this.reqIndex = index;
    this.matcodedata = row1.material_code;
    this.getmasterdata(); 
  this.editpanelOpenState = true;
    this.editData = true;
    this.intialData = false;
    this.panelOpenState = false;
  
    this.selectedIndex = this.dmrNumberData.indexOf(row1);
    this.editData = true;
    this.intialData = false;
    this.panelOpenState = false;
  
    this.model2.matcode = row1.material_code;
    this.model2.uom = row1.hasOwnProperty('unit_of_measurement') ? row1['unit_of_measurement']  : row1.unit_of_measurment;
    this.model2.matQuantity =  row1.hasOwnProperty('challan_quantity') ? row1.challan_quantity : row1.invoice_quantity;
    this.model2.remark = row1.hasOwnProperty('remark') ? row1.remark : row1.item_remarks   
    this.model2.id = row1.id;
    this.model2.storagelocation = row1.storage_location;
    this.model2.valuationType = row1.valuation_type;   
    this.model2.received_quantity = row1.received_quantity;
    this.Edit_id = row1.id;
  }

  saveaddededitdata(data: any) {
    this.data_id = data.id
 
    this.intialData = false
    this.panelOpenState = false
    this.editData = false;
    this.editpanelOpenState = false;
    this.dmrNumberData[this.reqIndex]['uom'] = this.model2.uom;
    this.dmrNumberData[this.reqIndex]['matQuantity'] = this.model2.matQuantity;
    this.dmrNumberData[this.reqIndex].received_quantity = this.model2.received_quantity;
    this.dmrNumberData[this.reqIndex].remark = this.model2.remark;
    this.dataSource.data = this.dmrNumberData
    this.model2 = {}
    this.dialog.closeAll()
    // this.matcodedata = ""
  }
 

  selectedmastereditgroup() {
    this.masterData.forEach((el: any) => {
      if (el.system_reference_1 == this.model2.matcode) {
        this.model2.uom = el.uom_1
        this.materialCODE = el.code
        this.materialNAME = el.name
      }
    });
  }
  Invoiceeditvalue(ev: any) {
    if (this.model2.invqty == '' && this.model2.recqty == '') {
      this.valuefrminvedit = ev.target.value
      this.model2.shrtqty = this.valuefrminvedit - this.valuefrmrecedit
      this.model2.excsqty = this.valuefrmrecedit - this.valuefrminvedit
    } else {
      this.model2.shrtqty = this.model2.invqty - this.model2.recqty
      this.model2.excsqty = this.model2.recqty - this.model2.invqty
    }
    if (this.model2.unitValue == '') {
      this.model2.basicprice = 0
    } else if (this.unitValueedit) {
      this.model2.basicprice = (this.model2.recqty * this.unitValueedit) - this.model2.disvalue
    }
    else {
      this.model2.basicprice = (this.model2.recqty * this.model2.unitprice) - this.model2.disvalue
    }
    if (this.model2.shrtqty < 0) {
      this.model2.shrtqty = 0
    }
    if (this.model2.excsqty < 0) {
      this.model2.excsqty = 0
    }
  }

  Receivevalueedit(ev1: any) {
    if (this.model2.invqty == '' && this.model2.recqty == '') {
      this.valuefrmrecedit = ev1.target.value;
      this.model2.shrtqty = this.valuefrminvedit - this.valuefrmrecedit
      this.model2.excsqty = this.valuefrmrecedit - this.valuefrminvedit
    } else {
      this.model2.shrtqty = this.model2.invqty - this.model2.recqty
      this.model2.excsqty = this.model2.recqty - this.model2.invqty
    }
    if (this.model2.unitValue == '') {
      this.model2.basicprice = 0
    } else if (this.unitValueedit) {
      this.model2.basicprice = (this.model2.recqty * this.unitValueedit) - this.model2.disvalue
    }
    else {
      this.model2.basicprice = (this.model2.recqty * this.model2.unitprice) - this.model2.disvalue
    }
    if (this.model2.shrtqty < 0) {
      this.model2.shrtqty = 0
    }
    if (this.model2.excsqty < 0) {
      this.model2.excsqty = 0
    }
  }

  getmaterialDATA(ev: any) {
    this.selectedmaterial = ev.target.value
    if (this.selectedmaterial.length > 2) {
      this.getmasterdata()
    }
    if (!this.selectedmaterial) {
      this.getmasterdata()
    }
  }
  getmaterialDATA1(ev: any) {
    this.selectedmaterial1 = ev.target.value
    if (this.selectedmaterial1.length > 2) {
      this.getData()
    }
    if (!this.selectedmaterial1) {
      this.getData()
    }
  }

  getmasterdata() {
    let obj = {
      "command": 'lst',
      "lmt": 100,
      "pid": 1,
      "key": this.selectedmaterial || this.selectedmaterial1 || this.matcodedata
      
    }
    this.custservice.getmaterialmasterdata(obj).subscribe((res: any) => {
      if (res) {
        this.masterData = res.data
        this.masterData.forEach((ele: any) => {
          if (ele.code == this.matcodedata) {
            this.model2.matcode = ele.system_reference_1
          }
        });
        this.matcodedata = ""
        this.selectednumber = ""
      }
    })
  }

  keyPressNumbers(evt: any) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }
  uploaddmrfiles(fileInput: any) {
    if (
      fileInput &&
      fileInput.target &&
      fileInput.target.files &&
      fileInput.target.files.length > 0
    ) {
      this.fileUploadUrlsdmr = fileInput.target.files;
      for (const file of this.fileUploadUrlsdmr) {
        const existingFile = this.selectedfiles.find(selectedFile => selectedFile.name === file.name);
        if(this.isFileSelected(file.name) && existingFile){
          this.alertcall.showWarning('Alert', 'Some files are already selected');
        }else if (this.fileUploadUrlsdmr.length > 1){
          this.alertcall.showSuccess('Accepted', "Files are uploaded successfully");
          this.filenamearray.push(file.name)
          this.selectedfiles.push(file)
        }
        else{
          this.alertcall.showSuccess('Accepted', "File is uploaded successfully");
          this.filenamearray.push(file.name)
          this.selectedfiles.push(file)
        }
      }
      fileInput.target.value = '';
    }
  
  }
  isFileSelected(selectedFilePath: string): boolean {
    return this.selectedfiles.some((file:any) => file.name == selectedFilePath);
  }

  uploadselectedfiles(){
    const postData = new FormData();
    postData.append("document_type","esdn");
    postData.append("document_number",this.resultqcnumber);
    for(const file of this.selectedfiles){
      postData.append("doc",file)
    }
    this.custservice.addfileupload(postData).subscribe((res:any)=>{
if(res&&res['status']=='Success'){
this.fileUploadUrls=[]
this.selectedfiles=[]
this.filenamearray=[]
}else{
 
}
    })
  }

  viewreason(data: any) {
    this.displayedColumns = ['sno', 'material_code', 'material_description', 'unit_of_measurment', 'challan_quantity', 'received_quantity', 'remarks'
    ];

    this.viewcompany_name = data.company_name;
    this.viewid = data.esdn_number
    let obj = {
      "command": "view",
      "esdn_number": this.viewid
    }
    this.custservice.editEsdndata(obj).subscribe((res: any) => {
      if (res.data.length > 0) {
        this.viewData = true
        this.listData = res.data

        this.dataSource.data = this.listData
        this.editabledata = data
        this.showViewData = this.editabledata
        this.showViewData.date = moment(this.editabledata.date).format("DD-MM-YYYY");
        this.showViewData.dmr_date = moment(this.editabledata.dmr_date).format("DD-MM-YYYY");
        this.showViewData.lr_date = moment(this.editabledata.lr_date).format("DD-MM-YYYY");
        this.demo1TabIndex = 0;
        this.countdmr=0
        this.listData.forEach((ele: any,index) => {
        this.countdmr =  index+1
      })
      } else {
        this.alertcall.showWarning("Accepted", "No Data Found");
      }
    })
  }
  editsaveddata(data: any, dialog: any) {
    this.dialog.open(dialog, {
      width: "400px"
    })
    this.editModelReason.reason = "";
    this.editednumber = data.esdn_number
    this.edit_id = data._id
   
  }

  saveeditreason() {
    let obj = {
      "command": "view",
      "esdn_number": this.editednumber,
    }

    this.custservice.getEsdndata(obj).subscribe((res: any) => {
      this.viewData = false
      this.viewEdit = true
      this.EditData= false;
      this.editPanelData = true;
      this.dialog.closeAll()
      this.selectedDMRData = res.data;
      this.selectedDMRData.map((rec:any) => {
        rec['matQuantity'] = rec.challan_quantity;
        rec['remark'] = rec.remark;
      })
      this.dmrNumberData = this.selectedDMRData;
      this.dataSource.data = this.selectedDMRData
      this.editabledata = res.data[0]

      this.selectedDMRData.company_name = this.editabledata.company_name
      this.selectedDMRData.date1 = moment(this.editabledata.date).format("YYYY-MM-DD")
      this.selectedDMRData.date = moment(this.editabledata.dmr_date).format("YYYY-MM-DD")
      this.selectedDMRData.number = this.editabledata.dmr_number
      this.selectedDMRData.purchase_order_number = this.editabledata.purchase_order_number
      this.selectedDMRData.purchase_order_date = this.editabledata.purchase_order_date
      this.selectedDMRData.material_description = this.editabledata.matrerial_description
      this.selectedDMRData.material_code = this.editabledata.material_code
      this.selectedDMRData.received_quantity = this.editabledata.received_quantity
      this.selectedDMRData.unit_of_measurment = this.editabledata.unit_of_measurement
      this.selectedDMRData.uom = this.editabledata.unit_of_measurement
      this.selectedDMRData.invoice_quantity = this.editabledata.challan_quantity
      this.selectedDMRData.matQuantity = this.editabledata.challan_quantity
      this.selectedDMRData.invoice_number = this.editabledata.invoice_number
      this.selectedDMRData.invoice_date = this.editabledata.invoice_date
      this.selectedDMRData.vendor_name = this.editabledata.vendor_name
      this.selectedDMRData.transporter_name = this.editabledata.transporter_name
      this.selectedDMRData.vehicle_number = this.editabledata.vehicle_number
      this.selectedDMRData.lr_number = this.editabledata.lr_no
      this.selectedDMRData.lr_date = moment(this.editabledata.lr_date).format("YYYY-MM-DD")
      this.selectedDMRData.status = this.editabledata.status
      this.selectedDMRData.descripency_open_note = this.editabledata.descripency_open_note
      this.selectedDMRData.action_token = this.editabledata.action_token
      this.selectedDMRData.user_department = this.editabledata.user_department
      this.selectedDMRData.buyer_name = this.editabledata.buyer_name
      this.selectedDMRData.remark = this.editabledata.remark
      this.selectedDMRData.esdn_number = this.editabledata.esdn_number
      this.countdmr = 0
      this.dmrNumberData.forEach((ele: any,index) => {
        this.countdmr =  index+1
      })
      this.demo1TabIndex = 0;
      this.btn = "Update" 
    })
  }
  getEsdndata1(){
      this.columnname = ""
      this.searchData = ""
      this.getEsdndata();
  }
  onChange() {
    this.searchData = ""
    if(!this.searchData){
      this.getEsdndata();
    }
  }
  searchInputData(){
    if(this.searchData.length > 2){
      if(this.paginator){
        this.paginator.firstPage();
      }
    this.search()
    }
    else if(!this.searchData){
      this.columnname = "";
      this.getEsdndata();
    }
  }
  search() {
    let obj: any = {
      "command": "list",
      "perpage": this.pageSize,
      "page": this.pageIndex,
      "showLoader":'hide'
    }
    if (this.columnname === 'esdn_number') {
      obj['esdn_number'] = this.searchData.trim()

    }
    if (this.columnname === 'vendor_name') {
      obj['vendor_name'] = this.searchData.trim()

    }
    if (this.columnname === 'dmr_number') {
      obj['dmr_number'] = this.searchData.trim()

    }
    if (this.columnname === 'po_number') {
      obj['po_number'] = this.searchData.trim()

    }
    if (this.searchData.length > 2 && this.columnname) {
      this.custservice.getSearchEsdn(obj).subscribe((res: any) => {
        this.reaData = false;
        this.totalRecords = res?.count;
        this.dataSourcemain1.data = res.data
        if (res.data.length == 0) {
          this.reaData = true
        }
      })
    }else if (!this.searchData) {
      this.getEsdndata()
      this.columnname = ""
    }
  }

  deleteItem(rw: any, data: any) {
    this.dialog.open(data, {
      width: '400px',
      scrollStrategy: new NoopScrollStrategy()
    })
    this.deletemodel.reason = "";
    this.deleteid = rw._id
    this.deleteEsdn = rw.esdn_number
  }
  deleteFile() {
    let obj = {
      "command": "delete",
      "esdn_number":this.deleteEsdn,
      "txn_id": this.txnId,
      "reason": this.deletemodel.reason

    }
    this.custservice.deleteEsdndata(obj).subscribe((res: any) => {
      if (res) {
        this.alertcall.showSuccess('Accepted', res['message']);
        this.dialog.closeAll()
        this.deletemodel.reason = ""
        this.getTransactionId();
        this.getEsdndata();
      }
      else {
        this.alertcall.showSuccess('Accepted', res['message']);
      }
    })
  }
  openfileuploadmodel(data: any, row1: any) {
    this.dialog.open(data, {
      width: '800px'
    })
    this.dmrnumber = row1.dmr_number
    this.esdnnumber = row1.esdn_number
    this.getexistingfiles()
  }
  getexistingfiles() {
    let params = new HttpParams();
    params = new HttpParams()
      .set("document_number", this.esdnnumber,)
      .set("document_type", "esdn")

    this.custservice.getexistingfies(params).subscribe((res: any) => {
      if (res && res['status_code'] == '200') {
        this.filedata = res.data
      } else {
        this.filedata = []
      }
    })
  }
  viewDoc(file: any) {
    const url = file.file_path;
    window.open(url, '_blank');
  }
  deleterowfile(row: any, data: any) {
    this.deleteid = data.id
    this.dialogRef = this.dialog.open(row, {
      width: "400px"
    })
  }
  deleteexistingfile() {
    let params = new HttpParams()
    params = new HttpParams()
      .set("document_number", this.esdnnumber,)
      .set("document_type", "esdn")
      .set("id", this.deleteid)
    this.custservice.deletefiles(params).subscribe((res: any) => {
      if (res && res['status_code'] == '200') {
        this.alertcall.showSuccess("Accepted", "File Deleted Successfully")
        this.getexistingfiles()
        this.dialogRef.close()
      } else {
        this.alertcall.showWarning("Error", res['message'])
      }
    })
  }
  uploadWbsFile(fileInput: any) {
    if (
      fileInput &&
      fileInput.target &&
      fileInput.target.files &&
      fileInput.target.files.length > 0
    ) {
      this.fileUploadUrls = fileInput.target.files;
    }
    const postData = new FormData();
    postData.append("document_type", "esdn");
    postData.append("document_number", this.esdnnumber);
    for (const file of this.fileUploadUrls){
        postData.append("doc", file)
    }
    this.custservice.addfileupload(postData).subscribe((res: any) => {
      
      if (res['status_code'] == '200' && this.fileUploadUrls.length > 1) {
        this.alertcall.showSuccess("Accepted", "Files are uploaded successfully")
        this.getexistingfiles()
        this.fileUploadUrls = []
      }
      else if (res['status_code'] == '200' && this.fileUploadUrls.length == 1){
        this.alertcall.showSuccess("Accepted", "File is uploaded Successfully")
        this.getexistingfiles()
        this.fileUploadUrls = []
      } else {
        this.alertcall.showWarning("Error", res['message'])
      }
      fileInput.target.value = '';
    })
    
  }
  isFileSelected1(selectedFilePath: string): boolean {
    return this.filedata.some((file:any) => file.file_name == selectedFilePath);
  }
  logsearch() {
    let obj = {
      command: "log",
      lmt: this.logpagesize,
      pid: this.pageIndex1,
      key: "esdn",
      number: this.logsearchData,
      "showLoader":'hide'
    }
    this.custservice.getActivityLog(obj).subscribe((res: any) => {
      if (res.log.length > 0) {
        this.logdata = res.log
      }
      this.logdata = res.log;
      this.reaDatalog = false;
      this.totalRecordslog = res?.count;
      this.dataSourcemainlog = res.log;
      if (res.log.length == 0) {
        this.reaDatalog = true;
      }
    })
  }

  BacktoList() {
    this.demo1TabIndex = 1;
    this.viewData = false
    this.displayedColumns = ['action', 'sno', 'material_code', 'material_description', 'unit_of_measurment', 'challan_quantity', 'received_quantity', 'remarks'
    ];
  }

  onTabChanged(ev: any) {
    if (this.demo1TabIndex === 1) {
      this.viewData = false
      this.saveddataarray = []
      this.dataSource.data = []
      this.SNO = []
      this.matCode = []
      this.matName = []
      this.UOM = []
      this.storageLocation = []
      this.InvoiceQuantity = []
      this.RecQTY = []
      this.shrQTY = []
      this.excsQTY = []
      this.RecQTY = []
      this.unitPrice = []
      this.discPERCENT = []
      this.discVALUE = []
      this.basicPRICE = []
      this.taxDESC = []
      this.taxPERCEN = []
      this.taxVALUE = []
      this.otherTAXSPLIT = []
      this.freightSPLIT = []
      this.otherchargesSPLIT = []
      this.otherTAXSPLIT = []
      this.totalPRICE = []
      this.valuationType = []
      this.POID = []
      this.model1 = {}
      this.countdmr = 0
      this.pageSize = 10
      this.getEsdndata();
      this.selectedDMRData = {};
      this.number = '';
      this.resetInputFields()
      this.dataSource = new MatTableDataSource<any>()
      this.router.navigate(['/inventory/esdn'])
      this.dataSourcemain1.sort = this.sort;
      this.searchData = ""
      this.columnname = ""
      this.logsearchData = ""
      this.pageIndex = 1;
      this.pageIndex1 = 1;
      this.viewEdit = false;
      this.selectedDMRData.date1=this.getCurrentDate()
    } else if (this.demo1TabIndex === 2) {
      
      this.viewData = false
      this.model1 = {};
      this.saveddataarray = []
      this.dataSource.data = []
      this.SNO = []
      this.matCode = []
      this.matName = []
      this.UOM = []
      this.storageLocation = []
      this.InvoiceQuantity = []
      this.RecQTY = []
      this.shrQTY = []
      this.excsQTY = []
      this.resetInputFields()
      this.unitPrice = []
      this.discPERCENT = []
      this.discVALUE = []
      this.basicPRICE = []
      this.taxDESC = []
      this.taxPERCEN = []
      this.taxVALUE = []
      this.otherTAXSPLIT = []
      this.freightSPLIT = []
      this.otherchargesSPLIT = []
      this.totalPRICE = []
      this.valuationType = []
      this.POID = []
      this.model1 = {}
      this.countdmr = 0
      this.searchData = ""
      this.columnname = ""
      this.logsearchData = ""
      this.selectedDMRData = {};
      this.number = '';
      this.logpagesize = 10;
      this.pageIndex = 1;
      this.pageIndex1 = 1;
      this.dataSource = new MatTableDataSource<any>()
      this.viewEdit = false;
      this.selectedDMRData.date1=this.getCurrentDate()
    }

  }

  deleterow(index: any) {
    this.dmrNumberData.splice(index, 1);
    this.countdmr = this.dmrNumberData.length
    this.POID.splice(index, 1);
    this.dataSource.data = this.dmrNumberData    
  }

  onpageevent(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if(!this.searchData){
      this.getEsdndata()
    }else{
      this.search()
    }
  }

  printesdn(data: any) {
    this.router.navigate(["/inventory/printesdn"], {
      queryParams: { misnumber: data.esdn_number, 'doc_sign': data.doc_sign },
    });
    
  }

  openNewFileUploadModal(data: any) {
    this.dialog.open(data, {
      width: '800px'
    })
    this.esdnnumber = this.editednumber
    this.getexistingfiles()
  }

  deleteFileItem(index:any){
    this.selectedfiles.splice(index,1)
   }

  savedisable() {
    if (!this.selectedDMRData.date1 || !this.selectedDMRData.buyer_name || !this.selectedDMRData.user_department || !this.selectedDMRData.status || !this.selectedDMRData.descripency_open_note || !this.selectedDMRData.action_token) {
      return true;
    }
    else {
      return false;
    }
  }
  
  // trimInput() {
  //   this.number = this.number.trim();
  //   this.selectedDMRData["number"] = this.selectedDMRData["number"].trim();
  //   this.selectedDMRData["vendor_name"] = this.selectedDMRData["vendor_name"].trim();
  //   this.selectedDMRData["purchase_order_number"] = this.selectedDMRData["purchase_order_number"].trim();
  //   this.selectedDMRData["transporter_name"] = this.selectedDMRData["transporter_name"].trim();
  //   this.selectedDMRData["vehicle_number"] = this.selectedDMRData["vehicle_number"].trim();
  //   this.selectedDMRData["lr_number"] = this.selectedDMRData["lr_number"].trim();
  //   this.selectedDMRData["buyer_name"] = this.selectedDMRData["buyer_name"].trim();
  //   this.selectedDMRData["company_name"] = this.selectedDMRData["company_name"].trim();
  //   this.selectedDMRData["user_department"] = this.selectedDMRData["user_department"].trim();
  //   this.selectedDMRData["descripency_open_note"] = this.selectedDMRData["descripency_open_note"].trim();
  //   this.selectedDMRData["action_token"] = this.selectedDMRData["action_token"].trim();
  // }

  trimInput() {
    this.number = this.number?.trim() || '';

    Object.keys(this.selectedDMRData).forEach(key => {
        this.selectedDMRData[key] = this.selectedDMRData[key]?.trim() || '';
    });
}

  
  getCurrentDate(): string {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    let month: string | number = currentDate.getMonth() + 1;
    let day: string | number = currentDate.getDate();
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return `${year}-${month}-${day}`;
  }
  keyPressAlphaNumeric(event:any) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}


