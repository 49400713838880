<div class="page-breadcrumb page-header">
    <div class="row">
        <div class="col-12 align-self-center">
            <h4 class="page-title">Contractor Master</h4>
            <!-- <div class="d-flex align-items-center m-t-10">
                <span class="m-r-20">Start Date : {{startDate | date}} </span>
                <span>End Date : {{endDate | date}} </span>
            </div> -->
        </div>
    </div>
</div>

        <mat-card>
            <mat-card-content>
                <div class="row p-10">
                    <div class="col-6">
                        <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                        <input type="search" class="searchfield"  placeholder="Search By Name/Code" [(ngModel)]="searchData" (input)="searchdata()">
                    </div>
                    <div class="col-6  float-right text-right">

                        <button mat-icon-button value="Add" matTooltip="Add" class="custom-icon-button "
                            (click)="addcontractor(contractormodel)">
                            <span class="material-icons">
                                add
                            </span>
                        </button>


                    </div>

                </div>

                <ng-container>

                    <div class="row p-t-10">
                        <div class="col-12 col-md-12">
                            <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar>
                            <div class="table-responsive mat-table-wrapper">
                                <div class="example-table-container">
                                    <table mat-table [dataSource]="dataSource">


                                        <ng-container matColumnDef="sno">
                                            <th mat-header-cell *matHeaderCellDef style="width:5%">S.NO</th>
                                            <td mat-cell *matCellDef="let row;let i=index;">
                                                {{(i+1) + (masterpaginator?.pageIndex * masterpaginator?.pageSize)}}

                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="code">
                                            <th mat-header-cell *matHeaderCellDef style="width:5%">
                                                Code</th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.code}}

                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef style="width:12%">
                                                Name</th>
                                            <td mat-cell *matCellDef="let row"  matTooltip="{{row.name}}">
                                                {{ row.name.length > 40 ? (row.name | slice:0:40) + '...' : row.name }}
                                            </td>
                                        </ng-container>


                                        <ng-container matColumnDef="address">
                                            <th mat-header-cell *matHeaderCellDef style="width:9%">Address
                </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.address_1 }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="contact_number">
                                            <th mat-header-cell *matHeaderCellDef style="width:10%">Contact Number</th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.contact_number}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="gst_number">
                                            <th mat-header-cell *matHeaderCellDef style="width:10%">GST Number
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.gst_number}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="pan_number">
                                            <th mat-header-cell *matHeaderCellDef style="width:10%">PAN Number
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.pan_number}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="city">
                                            <th mat-header-cell *matHeaderCellDef style="width:7%">City</th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.city }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="state">
                                            <th mat-header-cell *matHeaderCellDef style="width:8%"> State</th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.state }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="country">
                                            <th mat-header-cell *matHeaderCellDef style="width:5%"> Country
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.country }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="pincode">
                                            <th mat-header-cell *matHeaderCellDef style="width:8%">
                                                Pincode
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.pin_code}}
                                            </td>
                                        </ng-container>
                                     
                                        <!-- <ng-container matColumnDef="ActiveStatus">
                                            <th mat-header-cell *matHeaderCellDef style="width:5%">
                                                Active Status
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.is_active}}
                                            </td>
                                        </ng-container> -->
                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef style="width:11%">
                                                Actions
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                <button mat-icon-button matTooltip="Edit"
                                                    aria-label="Example icon button with a vertical three dot icon"
                                                   (click)="editdata(row,contractoreditmodel)" >
                                                    <mat-icon style="color: blue;">edit</mat-icon>
                                                </button>

                                                <button mat-icon-button style="color: red;" matTooltip="Delete"
                                                    aria-label="Example icon button with a vertical three dot icon" (click)="deletedata(row,deleteFileModal)"
                                                    >
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>


                                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                    </table>
                                </div>                           
                            </div>
                            <div *ngIf="contracordata==true" class="text-center">
                                <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                    Loading...
                                </h4>
                                <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                                    No Records Found
                                </h4>
                            </div>
                                <ng-container *ngIf="totalRecords>0">
                                    <mat-paginator [length]="totalRecords" [pageSize]="10"
                                    [pageSizeOptions]="[5,10, 50, 100, 500, 1000]" #masterpaginator
                                    (page)="onpageevent($event)">
                                </mat-paginator>
                                </ng-container>
                        </div>
                    </div>
                </ng-container>

            </mat-card-content>
        </mat-card>
        <ng-template #contractormodel>
            <div mat-dialog-title class="dialog-title">
                <!-- <h2>Add Contractor Master</h2>
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon">
                    <mat-icon>close</mat-icon>
                </button> -->
                <div class="row">
                    <div class="col-11">
                        <h2>Add Contractor Master</h2>
                    </div>
                    <div class="col-1 text-right">
                        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon">
                            <mat-icon class="icon1">close</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <mat-dialog-content>
                <form #myform="ngForm" (ngSubmit)="savecontractordata(myform)">
                    <div class="row">
                        <div class="col-md-2">
                            <label>Name<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter Name" class="inputstyles" name="contractorname"
                                [(ngModel)]="contractorname">
                        </div>
        
                        <!-- </div> -->
                        <div class="col-md-2">
                            <label>Address<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <textarea rows="2" style="width:88%;height:88%;border-radius: 5px;" class="nguntouched" name="address"
                                [(ngModel)]="address" placeholder="Enter Address"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Contact Number<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter Contact Number" class="inputstyles" name="contactnumber" [(ngModel)]="contactnumber" maxlength="10" minlength="10" (keypress)="keyPressNumbers($event)" (ngModelChange)="onContactNumber($event)" (blur)="checkContactNumber()">
                            <div *ngIf="showErrorCnt" class="error">
                                Contact number must be exactly 10 characters long.
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label>GST Number<span class="mandetory">*</span></label>
                        </div>
                        <!-- <div class="col-md-4">
                            <input type="text" placeholder="Enter GST Number" class="inputstyles" name="gstnumber" [(ngModel)]="gstnumber" maxlength="15" minlength="15" (keypress)="keyPressAlphanumeric($event)" (ngModelChange)="gstnumber = $event.toUpperCase()">
                            <div *ngIf="gstnumber.length !== 15 && gstnumber.length > 0" class="error">
                                GST number must be exactly 15 characters long.
                            </div>
                        </div> -->
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter GST Number" class="inputstyles" name="gstnumber" 
                                   [(ngModel)]="gstnumber" maxlength="15" minlength="15" 
                                   (keypress)="keyPressAlphanumeric($event)" 
                                   (ngModelChange)="onGstNumberChange($event)" 
                                   (blur)="checkGstNumber()">
                            <div *ngIf="showError" class="error">
                                GST number must be exactly 15 characters long.
                            </div>
                        </div>
                        
        
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>PAN Number<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter PAN Number" class="inputstyles" name="pannumber" [(ngModel)]="pannumber" maxlength="10" minlength="10" (keypress)="keyPressAlphanumeric($event)" (ngModelChange)="onPanNumberChange($event)" 
                            (blur)="checkPanNumber()">
                            <div *ngIf="showErrorPan" class="error">
                                PAN number must be exactly 10 characters long.
                            </div> 
                        </div>
                        <div class="col-md-2">
                            <label>City<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter City" class="inputstyles" name="city" [(ngModel)]="city"> 
                        </div>
                       
        
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>State<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text"  placeholder="Enter State" class="inputstyles" name="state" [(ngModel)]="state">
                        </div>
                        <div class="col-md-2">
                            <label>Country<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter Country" class="inputstyles" name="country" [(ngModel)]="country">
                        </div>
                     
                    </div><br>
                  <div class="row">
                    <div class="col-md-2">
                        <label>Pin Code<span class="mandetory">*</span></label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" placeholder="Enter Pincode" class="inputstyles" name="pincode" [(ngModel)]="pincode" maxlength="6" (keypress)="keyPressNumbers($event)" (ngModelChange)="onPinCode($event)" (blur)="checkPinCode()">
                        <div *ngIf="showErrorpin" class="error">
                            Pin Code must be exactly 6 characters long.
                        </div>
                    </div>
                  </div>
                    <button mat-raised-button type="submit" class="savebutton" [disabled]="!contractorname || !address || !contactnumber || !gstnumber || !pannumber || !city || !state || !country || !pincode || showError || showErrorPan || showErrorCnt || showErrorpin">Save</button>
                </form>
            </mat-dialog-content>
        </ng-template>
        <ng-template #contractoreditmodel>
            <div mat-dialog-title class="dialog-title">
                <!-- <h2>Edit Contractor Master</h2>
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon">
                    <mat-icon>close</mat-icon>
                </button> -->
                <div class="row">
                    <div class="col-11">
                        <h2>Edit Contractor Master</h2>
                    </div>
                    <div class="col-1 text-right">
                        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon">
                            <mat-icon class="icon1">close</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <mat-dialog-content>
                <form #myeditform="ngForm" (ngSubmit)="savecontractoreditdata(myeditform)">
                    <div class="row">
                        <div class="col-md-2">
                            <label>Name<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" readonly class="inputstyles" name="contracteditname"
                                [(ngModel)]="contracteditname">
                        </div>
        
                        <!-- </div> -->
                        <div class="col-md-2">
                            <label>Address<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <textarea rows="2" style="width:88%;height:88%;border-radius: 5px;" name="addressedit"
                                [(ngModel)]="addressedit" class="nguntouched"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <label>Contact Number<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="contactnumberedit" [(ngModel)]="contactnumberedit" maxlength="10" minlength="10" (keypress)="keyPressNumbers($event)" (ngModelChange)="onContactNumber($event)" (blur)="checkContactNumber()">
                            <div *ngIf="showErrorCnt" class="error">
                                Contact number must be exactly 10 characters long.
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label>GST Number<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="gstnumberedit" [(ngModel)]="gstnumberedit" maxlength="15" minlength="15" (keypress)="keyPressAlphanumeric($event)" (ngModelChange)="onGstNumberChange($event)" 
                            (blur)="checkGstNumber()" >
                            <div *ngIf="showError" class="error">
                                GST number must be exactly 15 characters long.
                            </div>
                        </div>
        
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>PAN Number<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="pannumberedit" [(ngModel)]="pannumberedit" maxlength="10" minlength="10" (keypress)="keyPressAlphanumeric($event)" (ngModelChange)="onPanNumberChange($event)" 
                            (blur)="checkPanNumber()"> 
                            <div *ngIf="showErrorPan" class="error">
                                PAN number must be exactly 10 characters long.
                            </div>   
                        </div>
                        <div class="col-md-2">
                            <label>City<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="cityedit" [(ngModel)]="cityedit"> 
                        </div>
                        
        
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>State<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text"  class="inputstyles" name="stateedit" [(ngModel)]="stateedit">
                        </div>
                        <div class="col-md-2">
                            <label>Country<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text"  class="inputstyles" name="countryedit" [(ngModel)]="countryedit">
                        </div>
                        
                    </div><br>
                  <div class="row">
                    <div class="col-md-2">
                        <label>Pin Code<span class="mandetory">*</span></label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="inputstyles" name="pincodeedit" [(ngModel)]="pincodeedit" maxlength="6" (keypress)="keyPressNumbers($event)" (ngModelChange)="onPinCode($event)" (blur)="checkPinCode()">
                        <div *ngIf="showErrorpin" class="error">
                            Pin Code must be exactly 6 characters long.
                        </div>
                    </div>
                  </div>
                    <button mat-raised-button type="submit" class="savebutton" [disabled]="!contracteditname || !addressedit || !contactnumberedit || !gstnumberedit || !pannumberedit || !cityedit || !stateedit || !countryedit || !pincodeedit || showErrorPan || showError || showErrorCnt || showErrorpin">Update</button>
                </form>
            </mat-dialog-content>
        </ng-template>
        <ng-template #deleteFileModal>
            <h1 mat-dialog-title>Delete Activity</h1>
            <div mat-dialog-content>Are you sure, you want to delete record?</div>
            <div mat-dialog-actions>
                <button mat-button mat-dialog-close style="background-color: rgb(239, 164, 164);">Close</button>
                <button mat-button cdkFocusInitial (click)="deleteFile()">Delete</button>
            </div>
        </ng-template>
    

   

