<mat-card>
    <div class=" print" style=" margin-left: 19px">
        <button [print]="['demo', config]" class="btn btn-info">
          Print
        </button>&nbsp;&nbsp;
        <button (click)="backtogoodsreturn()" class="btn btn-info">
            Back
          </button>
      </div>
  <div class="container-fluid printableArea" id="demo">
    <div class="goodsissuereceipt background">
      <div class="formbody">
        <div class="container-fluid justify-content-center">
          <div class="row justify-content-center">
            <div class="col-4">
              <img *ngIf="companylogo==='greenko'"
              src="https://testgeps.greenko.net/ui/assets/greenko/newlogo_greeenko_eps.svg"
                width="200px"
              />
            </div>
            <div class="col-8 float-right text-right">
              <ngx-qrcode
              [elementType]="elementType" [width]="80"  
              [errorCorrectionLevel]="correctionLevel" 
              [value]="data1"
              alt="Demo QR Code"
              cssClass="bshadow">
            </ngx-qrcode> 
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-8">      
              <h4 *ngIf="companylogo==='greenko'" style="font-size:16px;font-weight:400;"><b>GREENKO INTEGRATED MANAGEMENT SYSTEM</b></h4>
            </div>
            <div class="col float-right text-right">
              <h5 style="font-size:16px;font-weight:400;"><b>MATERIAL RETURN NOTE</b></h5> 
            </div>
          </div>
          <br />
          <div class="row justify-content-center text-center">
            <p>(Department to Stores)</p>
          </div>
          <div class="row justify-content-center">
            <div class="col">
              <p>Dept:</p>
            </div>
            <div class="col-7">
              <p *ngFor="let user of userData">
                <b>{{ user.company_name }} </b>
              </p>
            </div>
  
            <div class="col float-right text-right">
              <p>S.No:</p>
              <p>Date:</p>
            </div>
            <div class="col-3">
              <p *ngFor="let user of userData">
                <b>{{ user.number }}</b>
              </p>
              <p *ngFor="let user of userData">
                <b>{{ user.date | date: "dd-MM-yyyy" }}</b>
              </p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12">
              <p>To</p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12">
              <p>Stores</p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12">
              <p>Please receive the following Goods</p>
            </div>
          </div>
  
          <div class="row justify-content-center">
            <div class="col-7">
              <p>Nature of Item: Unused/Used & Reusable</p>
            </div>
            <div class="col-5">
              <p>Repaired & Reusable/Capital Items/Scrap</p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-6">
              <p>Reasons:</p>
            </div>
  
            <div class="col-2 float-right text-right">
              <p><b>COST CENTRE:</b></p>
            </div>
            <div class="col-4" *ngFor="let user of userData">
              <p>{{ user.company_name }}</p>
            </div>
          </div>
  
          <div class="row justify-content-center">
            <table class="table table-bordered ">
              <tr class="center ">
                <th scope="col" class="border">S. No.</th>
                <th scope="col" class="border">Item Code</th>
                <th scope="col" class="border">Item Description</th>
                <th scope="col" class="border">UOM</th>
                <th scope="col" class="border">
                  Qty
                  <p>Returned</p>
                </th>
                <th scope="col" class="border">
                  Qty
                  <p>Received</p>
                </th>
                <th scope="col" class="border">Value (Rs.)</th>
              </tr>
  
              <tr *ngFor="let user of table">
                <td class="center border">{{ user.line_item }}</td>
                <td class="border">{{ user.material_code }}</td>
                <td class="border">{{ user.material_description }}</td>
                <td class="center border">{{ user.unit_of_measurment }}</td>
                <td class="center border">{{ user.quantity }}</td>
                <td class="center border">{{ user.quantity }}</td>
                <td class="center border">
                  {{ user.total_value | currency: "INR":"" }}
                </td>
              </tr>
  
              <tr class="text-center">
                <td colspan="12" class="border">
                  <div class="row justify-content-center text-center">
                    <div class="col ">Total</div>
                    <!-- <div class="col right"><b>{{ total | number: "2.0-0" }}.00</b></div> -->
                     <div class="col right"><b>{{ total | currency: "INR":"" }}</b></div>
                  </div>
                </td>
              </tr>
             
            </table>
          </div>
        </div>
  
        <div class="row container-fluid">
          <!-- <div class="col-12"> -->
            <p>Inspection Remarks:&nbsp;</p>
            <p *ngFor="let user of userData"><b>{{ user.comments }}</b></p>
          <!-- </div> -->
        </div>
  
        <div class="row remarks-margin padding ">
          <div class="col-4">
            <p class="ml-0">(Inspected by)</p>
          </div>
          <div class="col-4 text-center">
            <p>(Returned by)</p>
          </div>
          <div class="col-4 ">
            <p class="ml-2 text-center">(Approved by)</p>
          </div>
        </div>
  
        <div class="row container-fluid">
          <div class="col-6">
            <p >Name:</p>
           
          </div>
        </div>
        
        <br />
        <div class="row justify-content-center">
          <p><b>Finance/Stores/Indenter</b></p>
        </div>
      </div>
      
    </div>
  </div>
</mat-card>