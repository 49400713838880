<mat-card>
    <div class="print ">
          <!-- <button onclick="getPrint()">Print</button> -->
    <button  [print]="['demo', config]" class="btn btn-info" style="margin-right:1%;margin-left:1%;background-color: #85AEB2;color: white;border: none;">
        Print
      </button>
      <button (click)="backtoesdn()" class="btn btn-info mr-2" style="background-color: #BFBFBF;color: black;border: none;">
        Back
      </button>
        <div class="formbody" id="demo">
            <div>
              <table>
                <tr class="text-center">
                    <th colspan="6">{{dataaa.company_name}}</th>
                </tr>
                <tr class="text-center">
                    <th colspan="6">Store management</th>
                </tr>
                <tr class="text-center">
                    <th colspan="6">Excess/shortage/damage material receipt note(ESDN)</th>
                </tr>
                <tr>
                    <th>Esdn No: </th>
                    <td colspan="3">&nbsp;{{dataaa.esdn_number}}</td>
                    <th>Date:</th>
                    <td>{{dataaa.date | date: "dd-MM-yyyy"}}</td>
                </tr>
                <tr>
                    <th>Receipt stores:</th>
                    <td colspan="3">&nbsp;</td>
                    <th>ESDN Status:</th>
                    <td> {{dataaa.status}}</td>
                </tr>
                <tr>
                    <th>1.PO No.</th>
                    <td colspan="2">{{dataaa.purchase_order_number}}</td>
                    <th>6.Date of receipt</th>
                    <td colspan="2">{{dataaa.dmr_date | date: "dd-MM-yyyy"}}</td>
                </tr>
                <tr>
                    <th>2.Buyer name</th>
                    <td  colspan="2">{{dataaa.buyer_name}}</td>
                    <th>7.transporter</th>
                    <td  colspan="2">{{dataaa.transporter_name}}</td>
                </tr>
                <tr>
                    <th>3.Project name</th>
                    <td  colspan="2">{{dataaa.company_name}}</td>
                    <th>8.vehicle no</th>
                    <td  colspan="2">{{dataaa.vehicle_number}}</td>
                </tr>
                <tr>
                    <th>4.user dept</th>
                    <td  colspan="2">{{dataaa.user_department}}</td>
                    <th>9.lr no</th>
                    <td  colspan="2">{{dataaa.lr_no}}</td>
                </tr>
                <tr>
                    <th >5.supplier/Vendor name</th>
                    <td  colspan="2">{{dataaa.vendor_name}}</td>
                    <th>10.Lr date</th>
                    <td  colspan="2">{{dataaa.lr_date | date: "dd-MM-yyyy"}}</td>
                </tr>
                <tr>
                    <th colspan="6">11. physical material verified details</th>
                </tr>
                <tr>
                    <th>PO.SI no</th>
                    <th>Item Description</th>
                    <th>UOM</th>
                    <th>Challan Qty</th>
                    <th>Received Qty</th>
                    <th>Remarks</th>
                </tr>
                
                <tr *ngFor="let data of userData;let i=index">
                    <td>{{i+1}} </td>
                    <td>{{data.matrerial_description}}</td>
                    <td>{{data.unit_of_measurement}}</td>
                    <td>{{data.challan_quantity}}</td>
                    <td>{{data.received_quantity}}</td>
                    <td>{{data.remark}}</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td colspan="6">Discrepancy open note : {{dataaa.descripency_open_note}}</td>
                </tr>
                <tr>
                    <td colspan="6">
                        <div class="row mt-2">
                            <div class="col-6">
                                <p>Consignment Verified by :</p>
                                <p>Date :</p>
                            </div>
                            <div class="col-6">
                                <p>Signature : </p>
                                <p>Contact no : </p>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td rowspan="2" colspan="4">
                        Action Taken:  {{dataaa.action_token}}
                    </td>
                    <td colspan="2">Date: </td>
                </tr>
                <tr>
                    <td colspan="2">Contact no :</td>
                </tr>
                <tr>
                    <td colspan="6">
                        Discrepancy close note:
                    </td>
                </tr>
                <tr>
                    <td colspan="2">Shortage recieved : </td>
                    <td >Damage rectification done : </td>
                    <td colspan="3">Discrepancy : </td>
                </tr>
                <tr>
                    <td>INWARD NO:</td>
                    <td></td>
                    <td>Work carried by:</td>
                    <td  colspan="3">Replacement requirement:</td>
                </tr>
                <tr>
                    <td>challan No:</td>
                    <td></td>
                    <td>Date:</td>
                    <td  colspan="3">Acceptance by QC/Indenter:</td>
                </tr>
                <tr>
                    <td>Recieved date :</td>
                    <td></td>
                    <td>Acceptance by QC:</td>
                    <td  colspan="3">Other</td>
                </tr>
                <tr>
                    <td>Recieved By :</td>
                    <td></td>
                    <td>Closed by:</td>
                    <td  colspan="3"></td>
                </tr>
                <tr>
                    <td colspan="6">Encl:</td>
                </tr>
              </table>
            </div>
        </div>
    </div>



    <!-- /Test -->
    <!-- <table style="width:1200px; border: 1px solid black;">
        <table style="width:1200px; border: 1px solid black;">
            <tr class="border-bottom text-center">
                <th>papilian infrastructure private ltd</th>
            </tr>
            <tr class="border-bottom text-center">
                <th>Store management</th>
            </tr>
            <tr class="border-bottom text-center">
                <th>Excess/shortage/damage material recipt note(ESDN)</th>
            </tr>
        </table>
        <table style="border-right:1px solid black;width:1200px; border-left: 2px solid black;">
            <tr style="border-top: 1px solid black; border-right: 1px solid black;">
                <td style="border-bottom: 1px solid black; border-right: 1px solid black;">Esdn No:</td>
                <td style="border-bottom: 1px solid black;border-right: 1px solid black;text-align: right;">
                    Date:</td>
                <td style="border-bottom: 1px solid black;border-right: 1px solid black;">23-23-3023</td>
            </tr>
            <tr>
                <td style="border-right: 1px solid black;">Recipt stores:</td>
                <td style="border-right: 1px solid black;text-align: right; "> ESDN STATUS</td>
                <td>open/close</td>
            </tr>
        </table>
        <table style="border-right:1px solid black;width:1200px; border-left: 2px solid black;">
            <tr style="border-top: 1px solid black; border-right: 1px solid black;  ">
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">1.PO no</td>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">
                    PIPL/po/oh/2023-24/010</td>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">6.Date of receipt
                </td>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">23-23-3909</td>
            </tr>
            <tr>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">2.Buyer name</td>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">mr.nageshwar rao
                </td>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">7.transporter
                </td>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">paleti ganga
                    lorry transport</td>
            </tr>
            <tr style="border-right: 1px solid black;border-bottom: 1px solid black; ">
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">3.Project name
                </td>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">piplai
                    infrsturture pvt ltd</td>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">8.vechile no</td>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">AP 38 uh 9898
                </td>
            </tr>
            <tr>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">4.user dept</td>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">batching plant
                </td>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">9.lr no</td>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">nil</td>
            </tr>
            <tr>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">5.supplier/Vendor
                    name</td>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">barathi cement
                </td>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">10.Lr date</td>
                <td style="border-right: 1px solid black;border-bottom: 1px solid black; ">23-23-3909</td>
            </tr>
            <tr style="border-right: 1px solid black;border-bottom: 1px solid black;">
                <td>11. pyshical material verified details</td>
            </tr>
        </table>
        <table style="border-right:1px solid black;width:1200px; border-left: 2px solid black;">
            <tr class="text-center" style="border-top:2px solid black;">
                <td style="border-bottom: 1px solid black; border-right:1px solid black;width:30px;">PO.SI
                    no</td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;width:280px;">Item
                    Description</td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;width:40px;">UOM
                </td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;width:50px;">Challan
                    Qty</td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;width:40px;">
                    Received Qty</td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;width:40px;">Remarks
                </td>
            </tr>
            <tr class="text-center">
                <td style="border-right:1px solid black;width:30px;">1</td>
                <td style="border-right:1px solid black;width:280px;">Cement opx g3 loose (bulk)</td>
                <td style="border-right:1px solid black;width:40px;">MT</td>
                <td style="border-right:1px solid black;width:50px;">40.28</td>
                <td style="border-right:1px solid black;width:40px;">20.02</td>
                <td style="border-right:1px solid black;width:40px;">shortage 2.2 mt</td>
            </tr>
            <tr class="text-center">
                <td style="border-right:1px solid black;width:30px; height: 300px;"></td>
                <td style="border-right:1px solid black;width:280px ;height: 300px"></td>
                <td style="border-right:1px solid black;width:40px ;height: 300px"></td>
                <td style="border-right:1px solid black;width:50px ;height: 300px"></td>
                <td style="border-right:1px solid black;width:40px ;height: 300px;"></td>
            </tr>
        </table>
        <table style="border-right:1px solid black;width:1200px; border-left: 2px solid black;">
            <tr style="border-bottom: 1px solid black;border-top: 1px solid black;">
                <td>Disperency open note :</td>
            </tr>
        </table>
        <table style="border-right:1px solid black;width:1200px; border-left: 2px solid black;">
            <tr>
                <td>Consignment Verified by :</td>
                <td>Signature : </td>
            </tr>
            <tr>
                <td>Date :</td>
                <td>Contact no : </td>
            </tr>
        </table>
        <table style="border-right:1px solid black;width:1200px; border-left: 2px solid black;">
            <tr style="border-top:1px solid black;">
                <td>Action Taken : </td>
                <td style="border-left:1px solid black;">Date :</td>
            </tr>
            <tr>
                <td></td>
                <td style="border-top:1px solid black;border-left:1px solid black;">copy of evaluation</td>
            </tr>
        </table>
        <table style="border-right:1px solid black;width:1200px; border-left: 2px solid black;">
            <tr style="border-top:2px solid black;">
                <td>Discrepency close note:</td>
            </tr>
        </table>
        <table style="border-right:1px solid black;width:1200px; border-left: 2px solid black;">
            <tr style="border-top:2px solid black;">
                <td style="border-right:1px solid black;">Shortage recieved : </td>
                <td style="border-right:1px solid black;">Damage rectification done : </td>
                <td style="border-right:1px solid black;">Discrepency : </td>
            </tr>
        </table>
        <table style="border-right:1px solid black;width:1200px; border-left: 2px solid black;">
            <tr style="border-top:2px solid black;">
                <td style="border-bottom: 1px solid black; border-right:1px solid black;">INWARD NO:</td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;;">315</td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;">Work carried by:
                </td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;">Replacement
                    requirement:</td>
            </tr>
            <tr>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;">challan No:</td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;;">63254</td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;">Date:</td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;">Acceptance by
                    QC/Indenter:</td>
            </tr>
            <tr>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;">Recieved date :
                </td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;;">23-09-2035</td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;">Acceptance by QC:
                </td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;">Other</td>
            </tr>
            <tr>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;">Recieved By :</td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;;">Mr. hariath</td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;">Closed by:</td>
                <td style="border-bottom: 1px solid black; border-right:1px solid black;"></td>
            </tr>
        </table>
        <table style="border-right:1px solid black;width:1200px; border-left: 2px solid black;">
            <tr>
                <td>Informed Concerned : </td>
                <td style="border-left:1px solid black;">Closed on:</td>
                <td></td>
            </tr>
            <tr>
                <td></td>
            </tr>
        </table>
    </table> -->

</mat-card>