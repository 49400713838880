<!-- 
<div class="page-breadcrumb page-header">
    <div class="row">
        <div class="col-12 align-self-center">
            <h4 class="page-title">Goods Receipt</h4> -->
<!-- <div class="d-flex align-items-center m-t-10">
                <span class="m-r-20">Start Date : {{startDate | date}} </span>
                <span>End Date : {{endDate | date}} </span>
            </div> -->
<!-- </div>
    </div>
</div> -->
<div class="material-tab">
    <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms" [(selectedIndex)]="demo1TabIndex" (selectedTabChange)="onTabChanged($event)"
        class="remove-border-bottom">
        <mat-tab>
            <ng-template mat-tab-label>
                Goods Receipt
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <div class="row mb-3" *ngIf="showbtn">
                            <div class="col-md-0 ml-2" *ngIf="!viewData">
                                <button mat-raised-button type="submit" (click)="savefinaldata()" class="savebutton"
                                    [disabled]="initialdata || edit"> {{btn}}</button>
                            </div>
                            <div class="col-md-1 mr-3" *ngIf="!viewData">
                                <button [disabled]="initialdata" type="button" mat-raised-button class="attachfile"
                                    color="secondary" (click)="openNewFileUploadModal(newFileUploadModal)">
                                    Attach File&nbsp; <mat-icon
                                        class="attachFileIcon">file_upload</mat-icon></button>
                                <!-- <div class="file">
                                    <input hidden class="form-control-file" name="file" type="file" multiple
                                        (change)="uploadgrfiles($event)" #autoFileUpload2>
                                    <ng-container *ngIf=" fileUploadUrlsgr.length > 0">
                                        <div *ngFor="let item of filenamearray;let i=index;">{{i+1}}.&nbsp;{{item}}
                                        </div>

                                        <span style="margin-left:3px">Selected : {{fileUploadUrlsgr.length}} files</span>  
                                    </ng-container>
                                </div> -->
                            </div>
                            <div *ngIf="viewData" class="col-md-0 ml-2 mb-2 ">
                                <button mat-raised-button type="button" (click)="BacktoList()"
                                    class="savebutton">Back</button>
                            </div>
                            <div *ngIf="viewData" class="col-md-0 mb-2 ml-2 ">
                                <button mat-raised-button type="button" (click)="addNew()" class="savebutton">Add
                                    New</button>
                            </div>
                            <!-- <div *ngIf="viewData" class="col-md-1 mb-2 ">
                                <button mat-raised-button type="button" (click)="addHold()" class="savebutton">Hold</button>
                            </div> -->
                            <div *ngIf="viewData && updatebtn" class="col-md-0 ml-2 mb-2 ">
                                <button mat-raised-button type="button" (click)="Update()"
                                    class="savebutton">Update</button>
                            </div>
                            <div *ngIf="!viewData && txnId" class="col col-md-0 mt-1 text-right">
                                <p *ngIf="!viewData && txnId" style="font-size: 16px;font-weight: 500;">Transaction Id:
                                    {{txnId}}</p>
                            </div>
                            <div *ngIf="viewData && txnId && updatebtn" class="col col-md-0 mt-1 text-right">
                                <p *ngIf="viewData && txnId" style="font-size: 16px;font-weight: 500;">Transaction Id:
                                    {{txnId}}</p>
                            </div>
                            <div *ngIf="editednumber && !txnId" class="col-md-12 mt-1 text-right">
                                <p *ngIf="editednumber" style="font-size: 16px;font-weight: 500;">View of Document
                                    Number:
                                    {{editednumber}}</p>
                            </div>
                            <div *ngIf="grnnumber" class="col-md-12 mt-1 text-right">
                                <p *ngIf="grnnumber" style="font-size: 16px;font-weight: 500;">View of Document
                                    Number:
                                    {{grnnumber}}</p>
                            </div>
                        </div>
                        <div class="row mt-2 mb-2" *ngIf="!viewData && editednumber  && txnId && showChangemode">
                            <div class="col-md-12">
                                <p  style="font-size: 16px;font-weight: 500;">Change Mode:
                                    {{editednumber}}</p>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <!-- <div class="col-md-1">
               
            </div> -->
                            <div class="col-md-4">
                                <label>Purchase Order Number<span class="mandetory">*</span></label>
                                <!-- <select *ngIf="!viewData" class="dropdownstyles" name="ponumber" [(ngModel)]="ponumber"
                                    (change)="getselectedpo()" style="height: 33px; width: 100%;">
                                    <option [value]="undefined" selected disabled>Select One</option>
                                    <option *ngFor="let item of purchaseorderdata"
                                        [ngValue]="item.purchase_order_number">{{item.purchase_order_number}}</option>
                                </select> -->
                                <mat-form-field *ngIf="!viewData || editData" class="example-full-width145 inputfield1" appearance="none">
                                    <input type="text" placeholder="Search Purchase Order Number " aria-label="Number" matInput
                                        style="border:none;margin-bottom: 1%;margin-left: 1%;" class="inputdropdown purchesnum"
                                        name="ponumber" [(ngModel)]="ponumber"
                                        (keyup)="PurchaseOrderdata($event)" [matAutocomplete]="auto44" />

                                    <mat-autocomplete autoActiveFirstOption #auto44="matAutocomplete" (optionSelected)="onOptionSelected($event)">

                                        <mat-option *ngFor="let item of purchaseorderdata" [value]="item.purchase_order_number"  >
                                            {{item.purchase_order_number}}</mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <p *ngIf="viewData && !editData" class="viewdata"> {{ponumber}}</p>
                            </div>

                            <div class="col-md-2">
                                <label>Date<span class="mandetory">*</span></label>
                                <input *ngIf="!viewData || editData" type="date" [max]="today"  [min]="'2000-01-01'"
                                    style="height:33px;text-transform: uppercase;width:100%" class="inputstyles"
                                    name="dateee" [(ngModel)]="dateee" dateFormat="dd/mm/yyyy">
                                <p *ngIf="viewData && !editData" class="viewdata"> {{dateee | date:'dd/MM/yyyy'}}</p>
                            </div>
                            <!-- <div class="col-md-2" >
               
            </div> -->

                            <div *ngIf="!viewData" class="col-md-6 mt-2 mb-3  text-right">
                                <label>&nbsp;</label>
                                <button mat-icon-button value="Add" matTooltip="Add" class="custom-icon-button mt-4"
                                    (click)="addgoodsreceipt(goodsreceiptmodel)" >
                                    <span class="material-icons">
                                        add
                                    </span>
                                </button>
                            </div>
                        </div>
                        <!-- <p>Total Price: {{getTotal()}}</p> -->


                        <div *ngIf="Initialdata">
                            <mat-accordion *ngFor="let item of addeddmrlist;let i=index">

                                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">

                                    <mat-expansion-panel-header class="mt-3">
                                        <mat-panel-title style="font-weight:bold">
                                            {{addeddmrlist[i].dmr_number}}
                                            <p style="margin-left: 7%;">{{gettotalprice(item) | number:'1.2-2'}}</p>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <!-- </mat-expansion-panel-header> -->
                                    <!-- <p >I'm visible because I am open</p> -->
                                    <div class="row" *ngIf="!viewData">
                                        <div class="col-md-6">
                                            <label>Miscellaneous Charges Description</label>
                                            <input class="inputstyles" placeholder="Enter Charges Description"
                                                name="chargesdesc"
                                                [(ngModel)]="addeddmrlist[i].miscellaneous_charges_description ">
                                        </div>

                                        <div class="col-md-6">
                                            <label>Miscellaneous Charges Value</label>
                                            <input type="number" class="inputstyles" placeholder="Enter Charges Value"
                                                name="chargesvalue" (keypress)="keyPressNumbers($event)"
                                                [(ngModel)]="addeddmrlist[i].miscellaneous_charges_value ">
                                        </div>


                                    </div>
                                    <div class="row mt-2" *ngIf="!viewData">
                                        <div class="col-3">
                                            <label><b>QC Number:</b> </label>
                                            <!-- {{item[i].qc_number}} -->
                                            <input type="text" class="inputstyles"
                                                [(ngModel)]="addeddmrlist[i].qc_number" readonly>
                                        </div>
                                        <div class="col-3">
                                            <label><b>QC Date:</b></label>
                                            <!-- {{item[i].qc_date | date: "dd/MM/yyyy"}} -->
                                            <input type="text" class="inputstyles" [(ngModel)]="addeddmrlist[i].qc_date"
                                                readonly>
                                        </div>
                                        <div class="col-3">
                                            <label><b>Invoice Number:</b> <span class="mandetory">*</span></label>
                                            <input type="text" class="inputstyles"
                                                [(ngModel)]="addeddmrlist[i].invoice_number">
                                        </div>
                                        <div class="col-3">
                                            <label><b>Invoice Date:</b><span class="mandetory">*</span> </label>
                                            <input type="date" [max]="today" class="inputstyles"
                                                [(ngModel)]="addeddmrlist[i].invoice_date">
                                        </div>
                                        <div class="col-3">
                                            <label><b>ESDN Number:</b> </label>
                                            <!-- {{item[i].esdn_number}} -->
                                            <input type="text" class="inputstyles"
                                                [(ngModel)]="addeddmrlist[i].esdn_number" readonly>
                                        </div>
                                        <div class="col-3">
                                            <label><b>Vendor Name:</b> </label>
                                            <!-- {{item[i].vendor_name}} -->
                                            <!-- <input type="text" class="inputstyles"
                                                [(ngModel)]="addeddmrlist[i].vendor_name"> -->
                                                <mat-form-field  class="example-full-width" appearance="none"
                                                style=" width:100%;
                                                border: 1px solid rgba(0, 0, 0, 0.12);
                                                border-radius: 5px;
                                                height: 35px;">
                                                <input type="text" placeholder="Select Vendor Name " aria-label="Number"
                                                    matInput style="border: none" class="inputdropdown purchesnum" name="vendorname{{i}}"
                                                    [(ngModel)]="addeddmrlist[i].vendor_name"  #vendorname="ngModel" (keyup)="getvendordropdowndata($event)" readonly
                                                    [matAutocomplete]="auto9" required/>
                                                <!-- (keyup)="checkPin($event)"  -->
                                                <mat-autocomplete autoActiveFirstOption #auto9="matAutocomplete">
                                                    <mat-option *ngFor="let item of vendorData" [value]="item.name" multiple>{{
                                                        item.name }}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-3">
                                            <label><b>Transporter Name:</b> </label>
                                            <!-- {{item[i].transporter_name}} -->
                                            <input type="text" class="inputstyles"
                                                [(ngModel)]="addeddmrlist[i].transporter_name">
                                        </div>
                                        <div class="col-3">
                                            <label><b>Vehicle Number:</b></label>
                                            <input type="text" class="inputstyles"
                                                [(ngModel)]="addeddmrlist[i].vehicle_number">
                                        </div>
                                        <div class="col-md-3">
                                            <label>Gate Entry Number<span class="mandetory">*</span></label>
                                            <input class="inputstyles" placeholder="Enter Gate Entry Number"
                                                name="gate_entry_number"
                                                [(ngModel)]="addeddmrlist[i].gate_entry_number ">
                                        </div>
                                        <div class="col-md-3">
                                            <label>Gate Entry Date<span class="mandetory">*</span></label>
                                            <input type="date" [max]="today" class="inputstyles"
                                                placeholder="Enter Gate Entry Date" name="gate_entry_date"
                                                [(ngModel)]="addeddmrlist[i].gate_entry_date">

                                        </div>
                                        <div class="col-md-3">
                                            <label>LR Number</label>
                                            <input class="inputstyles" placeholder="Enter Gate Entry Number"
                                                name="lr_number" [(ngModel)]="addeddmrlist[i].lr_number ">
                                        </div>
                                        <div class="col-md-3">
                                            <label>LR Date</label>
                                            <input type="date" [max]="today" class="inputstyles"
                                                placeholder="Enter Gate Entry Date" name="lr_date"
                                                [(ngModel)]="addeddmrlist[i].lr_date">

                                        </div>
                                        <div class="col-md-3">
                                            <label>Eway Bill Number</label>
                                            <input class="inputstyles" placeholder="Enter Gate Entry Number"
                                                name="way_bill_number" [(ngModel)]="addeddmrlist[i].way_bill_number ">
                                        </div>
                                        <div class="col-md-3">
                                            <label>Delivery Challan Number</label>
                                            <input class="inputstyles" placeholder="Enter Delivery Challan Number"
                                                name="dc_number" [(ngModel)]="addeddmrlist[i].dc_number ">
                                        </div>
                                        <div class="col-md-3">
                                            <label>Delivery Challan Date</label>
                                            <input type="date" [max]="today" class="inputstyles"
                                                placeholder="Enter Delivery Challan Date" name="dc_date"
                                                [(ngModel)]="addeddmrlist[i].dc_date">
                                        </div>
                                        <div class="col-md-3">
                                            <label>PO Date</label>
                                            <input type="date" [max]="today" class="inputstyles"
                                                placeholder="Enter PO Date" name="purchase_order_date"
                                                [(ngModel)]="addeddmrlist[i].purchase_order_date">
                                        </div>
                                        <div class="col-9">
                                            <label><b>Comments:</b> </label>
                                            <textarea class="inputstyles" rows="2" cols="50" id="" style="resize: none;"
                                                [(ngModel)]="addeddmrlist[i].comments">
                                            </textarea>
                                        </div>


                                    </div>
                                    <div class="row" *ngIf="viewData">

                                        <div class="col-md-6">
                                            <label><b>Miscellaneous Charges
                                                    Description:</b>{{addeddmrlist[i].miscellaneous_charges_description}}</label>
                                            <!-- <mat-form-field class="example-full-width"> -->
                                            <!-- <input class="inputstyles" placeholder="Enter Charges Description"
                                                name="chargesdesc"
                                                [(ngModel)]="item[i].miscellaneous_charges_description "> -->
                                            <!-- </mat-form-field> -->
                                        </div>
                                        <div class="col-md-6">
                                            <label><b>Miscellaneous Charges
                                                    Value:</b>{{addeddmrlist[i].miscellaneous_charges_value}}</label>
                                            <!-- <mat-form-field class="example-full-width"> -->
                                            <!-- <input class="inputstyles" placeholder="Enter Charges Value"
                                                name="chargesvalue" [(ngModel)]="item[i].miscellaneous_charges_value "> -->
                                            <!-- </mat-form-field> -->
                                        </div>

                                    </div>
                                    <div class="row mt-2" *ngIf="viewData">
                                        <div class="col-3">
                                            <label><b>QC Number:</b> {{addeddmrlist[i].qc_number}}</label>
                                        </div>
                                        <div class="col-3">
                                            <label><b>QC Date:</b> {{addeddmrlist[i].qc_date | date:
                                                "dd/MM/yyyy"}}</label>
                                        </div>
                                        <div class="col-3">
                                            <label><b>Invoice Number:</b> {{addeddmrlist[i].invoice_number}}</label>
                                        </div>
                                        <div class="col-3">
                                            <label><b>Invoice Date:</b> {{addeddmrlist[i].invoice_date | date:
                                                "dd/MM/yyyy"}}</label>
                                        </div>
                                        <div class="col-3">
                                            <label><b>ESDN Number:</b> {{addeddmrlist[i].esdn_number}}</label>
                                        </div>
                                        <div class="col-3">
                                            <label><b>Vendor Name:</b> {{addeddmrlist[i].vendor_name}}</label>
                                        </div>
                                        <div class="col-3">
                                            <label><b>Transporter Name:</b> {{addeddmrlist[i].transporter_name}}</label>
                                        </div>
                                        <div class="col-3">
                                            <label><b>Vehicle Number:</b> {{addeddmrlist[i].vehicle_number}}</label>
                                        </div>

                                        <div class="col-md-3">
                                            <label><b>Gate Entry
                                                    Number:</b>{{addeddmrlist[i].gate_entry_number}}</label>
                                            <!-- <input  class="inputstyles" placeholder="Enter Gate Entry Number"
                                            name="gate_entry_number" [(ngModel)]="item[i].gate_entry_number "> -->
                                        </div>
                                        <div class="col-md-3">
                                            <label><b>Gate Entry Date:</b>{{addeddmrlist[i].gate_entry_date | date:
                                                'dd/MM/yyyy'}}</label>
                                            <!-- <input   class="inputstyles" placeholder="Enter Gate Entry Date"
                                            name="gate_entry_date" [ngModel]=""> -->

                                        </div>
                                        <div class="col-md-3">
                                            <label><b>LR Number:</b>{{addeddmrlist[i].lr_number}}</label>
                                        </div>
                                        <div class="col-md-3">
                                            <label><b>LR Date:</b>{{addeddmrlist[i].lr_date | date:
                                                'dd/MM/yyyy'}}</label>
                                        </div>
                                        <div class="col-md-3">
                                            <label><b>Eway Bill Number:</b>{{addeddmrlist[i].way_bill_number}}</label>
                                        </div>
                                        <div class="col-md-3">
                                            <label><b>DC Number:</b>{{addeddmrlist[i].dc_number}}</label>
                                        </div>
                                        <div class="col-md-3">
                                            <label><b>DC Date:</b>{{addeddmrlist[i].dc_date | date:
                                                'dd/MM/yyyy'}}</label>
                                        </div>
                                        <div class="col-md-3">
                                            <label><b>PO Date:</b>{{addeddmrlist[i].purchase_order_date | date:
                                                'dd/MM/yyyy'}}</label>
                                        </div>
                                        <div class="col-9">
                                            <label><b>Comments:</b> {{addeddmrlist[i].comments}}</label>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                    <div class="row p-t-10">
                                        <div class="col-12 col-md-12">
                                            <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                            <div class="table-responsive mat-table-wrapper">
                                                <div class="example-table-container"
                                                    style="overflow-x:acroll;width:125%">

                                                    <table style="height:100px;">
                                                        <tr
                                                            style=" background: #e9ecef;font-weight: 600;height: 56px;text-transform: capitalize;">
                                                            <th *ngIf="!viewData">Action</th>
                                                            <th>S.NO</th>
                                                            <th>Material Code</th>
                                                            <th>Material Description</th>
                                                            <th>UOM</th>
                                                            <th>Invoice Qty</th>
                                                            <th>Received Qty</th>
                                                            <th>Shortage Qty</th>
                                                            <th>Excess Qty</th>
                                                            <th>Accepted Qty</th>
                                                            <th>Rejected Qty</th>
                                                            <th>Unit Price</th>
                                                            <th>Discount %</th>
                                                            <th>Discount Value</th>
                                                            <th>Basic Price</th>
                                                            <th>Tax Description</th>
                                                            <th>Tax %</th>
                                                            <th>Tax Value</th>
                                                            <th>Freight Split</th>
                                                            <th>Basic Freight</th>
                                                            <th>Other Charges Split</th>
                                                            <th>Other Tax Split</th>
                                                            <th>Total Price</th>
                                                            <th>Storage Location</th>
                                                            <th>Valuation Type</th>
                                                            <th>Item Remarks</th>

                                                        </tr>

                                                        <tr *ngFor="let model of item;let i=index;" class="text-center">
                                                            <td *ngIf="!viewData">
                                                                <button mat-icon-button matTooltip="Delete"
                                                                    aria-label="Example icon button with a vertical three dot icon"
                                                                    (click)="deleterowItem(item,i)">
                                                                    <mat-icon style="color: red;">delete</mat-icon>
                                                                </button>
                                                            </td>
                                                            <td>{{i+1}}</td>
                                                            <!-- <td width="20%"><span class="example-full-width" >{{model.LABEL_NAME}}</span></td> -->
                                                            <td>
                                                                {{model.material_code}}
                                                                <!-- <mat-form-field class="example-full-width">
                      <input matInput placeholder="Enter text" value="" name="matcode" [(ngModel)]="model.material_code"  readonly>
                    </mat-form-field> -->
                                                            </td>
                                                            <td>
                                                                {{model.material_description}}
                                                                <!-- <mat-form-field class="example-full-width">
                      <input matInput placeholder="Enter text" value="" name="matname" [(ngModel)]="model.material_description" readonly>
                    </mat-form-field> -->
                                                            </td>
                                                            <td>
                                                                {{model.unit_of_measurment}}
                                                                <!-- <mat-form-field class="example-full-width">
                      <input matInput placeholder="Enter text" value="" name="uom" [(ngModel)]="model.unit_of_measurment" readonly>
                    </mat-form-field> -->
                                                            </td>

                                                            <td>
                                                                {{model.invoice_quantity}}
                                                                <!-- <mat-form-field class="example-full-width">
                      <input matInput placeholder="Enter text" value="" name="acceptedqty{{i}}" [(ngModel)]="MATDATA[i].accepted_quantity" >
                    </mat-form-field> -->
                                                            </td>
                                                            <td>
                                                                {{model.received_quantity}}
                                                                <!-- <mat-form-field class="example-full-width">
                      <input matInput placeholder="Enter text" value="" name="rejectedqty{{i}}" [(ngModel)]="MATDATA[i].rejeted_quantity" >
                    </mat-form-field> -->
                                                            </td>
                                                            <td>

                                                                {{model.shortage_quantity}}
                                                            </td>
                                                            <td>
                                                                {{model.excess_quantity}}
                                                            </td>
                                                            <td>
                                                                {{model.accepted_quantity}}
                                                            </td>
                                                            <td>
                                                                {{model.rejected_quantity}}
                                                            </td>
                                                            <td>
                                                                {{model.unit_price | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.discount_percent | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.discount_value | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.basic_price | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.tax_description}}
                                                            </td>
                                                            <td>
                                                                {{model.tax_percent| number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.tax_value | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.freight_split | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.basic_freight | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.other_charges_split | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.other_tax_split | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.total_price | number:'1.2-2'}}
                                                            </td>

                                                            <td>
                                                                <mat-form-field class="example-full-width"
                                                                    appearance="none" class="matformfieldstyles">
                                                                    <input type="text"
                                                                        placeholder="Search Storage Location "
                                                                        aria-label="Number" matInput style="border:none"
                                                                        class="inputdropdown" name="storage_location"
                                                                        [(ngModel)]="item[i].storage_location"
                                                                        (keyup)="getstorageDATA1($event)"
                                                                        [matAutocomplete]="auto7" />

                                                                    <mat-autocomplete autoActiveFirstOption
                                                                        #auto7="matAutocomplete">

                                                                        <mat-option *ngFor="let item of storageData"
                                                                            [value]="item.storage_location">
                                                                            {{item.storage_location}}</mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <select class="dropdownstyles" name="valution_type"
                                                                    [(ngModel)]="item[i].valuation_type">
                                                                    <option selected disabled>Select One</option>
                                                                    <option *ngFor="let valution of valutiondata"
                                                                        [ngValue]="valution.code">{{valution.code}}
                                                                    </option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <!-- {{model.item_remarks}} -->
                                                                <!-- <mat-form-field class="example-full-width"> -->
                                                                <input class="inputstyles" style="height: 35px;"
                                                                    placeholder="Enter Remarks" name="item_remarks"
                                                                    [(ngModel)]="item[i].item_remarks">
                                                                <!-- </mat-form-field> -->
                                                            </td>
                                                            <!-- <button mat-icon-button
                    aria-label="Example icon button with a vertical three dot icon"
                   >
                    <mat-icon #myButton (click)="savedata(model.received_quantity,model.acceptedqty,model.rejectedqty,model.itemremarks)" >save</mat-icon>
                </button> -->


                                                        </tr>
                                                        <!-- <tr>
                  <td style="height: 45px;text-align: center;" *ngIf="commentsList == 0" colspan="4">No Records Found</td>
                </tr> -->
                                                    </table>

                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div><br>
                        <div class="mt-2" style="margin-top:8px;" *ngIf="editeddata">
                            <mat-accordion *ngFor="let item of addeddmrlist;let i=index">

                                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">

                                    <mat-expansion-panel-header>
                                        <mat-panel-title style="font-weight:bold">
                                            {{addeddmrlist[i].dmr_number}}
                                            <p style="margin-left: 7%;">{{gettotalprice(item) | number:'1.2-2'}}</p>
                                        </mat-panel-title>



                                    </mat-expansion-panel-header>
                                    <!-- </mat-expansion-panel-header> -->
                                    <!-- <p >I'm visible because I am open</p> -->
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>Miscellaneous Charges Description</label>
                                            <input class="inputstyles" placeholder="Enter Charges Description"
                                                name="chargesdesc"
                                                [(ngModel)]="addeddmrlist[i].miscellaneous_charges_description " readonly>
                                        </div>

                                        <div class="col-md-6">
                                            <label>Miscellaneous Charges Value</label>
                                            <input type="number" class="inputstyles" placeholder="Enter Charges Value"
                                                name="chargesvalue" (keypress)="keyPressNumbers($event)"
                                                [(ngModel)]="addeddmrlist[i].miscellaneous_charges_value " readonly>
                                        </div>


                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-3">
                                            <label><b>QC Number:</b> </label>
                                            <!-- {{item[i].qc_number}} -->
                                            <input type="text" class="inputstyles"
                                                [(ngModel)]="addeddmrlist[i].qc_number" readonly>
                                        </div>
                                        <div class="col-3">
                                            <label><b>QC Date:</b></label>
                                            <!-- {{item[i].qc_date | date: "dd/MM/yyyy"}} -->
                                            <input type="text" class="inputstyles"
                                                [ngModel]="addeddmrlist[i].qc_date | date: 'dd/MM/yyyy'" readonly>
                                        </div>
                                        <div class="col-3">
                                            <label><b>Invoice Number:</b><span class="mandetory">*</span> </label>
                                            <input type="text" class="inputstyles"
                                                [(ngModel)]="addeddmrlist[i].invoice_number">
                                        </div>
                                        <div class="col-3">
                                            <label><b>Invoice Date:</b><span class="mandetory">*</span> </label>
                                            <input type="date" [max]="today" class="inputstyles"
                                                [(ngModel)]="addeddmrlist[i].invoice_date">
                                        </div>
                                        <div class="col-3">
                                            <label><b>ESDN Number:</b> </label>
                                            <!-- {{item[i].esdn_number}} -->
                                            <input type="text" class="inputstyles"
                                                [(ngModel)]="addeddmrlist[i].esdn_number" readonly>
                                        </div>
                                        <div class="col-3">
                                            <label><b>Vendor Name:</b> </label>
                                            <!-- {{item[i].vendor_name}} -->
                                            <!-- <input type="text" class="inputstyles"
                                                [(ngModel)]="addeddmrlist[i].vendor_name"> -->
                                                <mat-form-field  class="example-full-width" appearance="none"
                                                style=" width:100%;
                                                border: 1px solid rgba(0, 0, 0, 0.12);
                                                border-radius: 5px;
                                                height: 35px;">
                                                <input type="text" placeholder="Select Vendor Name " aria-label="Number"
                                                    matInput style="border: none" class="inputdropdown purchesnum" name="vendorname{{i}}"
                                                    [(ngModel)]="addeddmrlist[i].vendor_name"  #vendorname="ngModel" (keyup)="getvendordropdowndata($event)" readonly
                                                    [matAutocomplete]="auto9" required/>
                                                <!-- (keyup)="checkPin($event)"  -->
                                                <mat-autocomplete autoActiveFirstOption #auto9="matAutocomplete">
                                                    <mat-option *ngFor="let item of vendorData" [value]="item.name" multiple>{{
                                                        item.name }}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-3">
                                            <label><b>Transporter Name:</b> </label>
                                            <!-- {{item[i].transporter_name}} -->
                                            <input type="text" class="inputstyles"
                                                [(ngModel)]="addeddmrlist[i].transporter_name">
                                        </div>
                                        <div class="col-3">
                                            <label><b>Vehicle Number:</b></label>
                                            <input type="text" class="inputstyles"
                                                [(ngModel)]="addeddmrlist[i].vehicle_number">
                                        </div>
                                        <div class="col-md-3">
                                            <label>Gate Entry Number<span class="mandetory">*</span></label>
                                            <input class="inputstyles" placeholder="Enter Gate Entry Number"
                                                name="gate_entry_number"
                                                [(ngModel)]="addeddmrlist[i].gate_entry_number ">
                                        </div>
                                        <div class="col-md-3">
                                            <label>Gate Entry Date<span class="mandetory">*</span></label>
                                            <input type="date" [max]="today" class="inputstyles"
                                                placeholder="Enter Gate Entry Date" name="gate_entry_date"
                                                [(ngModel)]=" addeddmrlist[i].gate_entry_date">

                                        </div>
                                        <div class="col-md-3">
                                            <label>LR Number</label>
                                            <input class="inputstyles" placeholder="Enter Gate Entry Number"
                                                name="lr_number" [(ngModel)]="addeddmrlist[i].lr_number ">
                                        </div>
                                        <div class="col-md-3">
                                            <label>LR Date</label>
                                            <input type="date" [max]="today" class="inputstyles"
                                                placeholder="Enter Gate Entry Date" name="lr_date"
                                                [(ngModel)]="addeddmrlist[i].lr_date">

                                        </div>
                                        <div class="col-md-3">
                                            <label>Eway Bill Number</label>
                                            <input class="inputstyles" placeholder="Enter Gate Entry Number"
                                                name="way_bill_number" [(ngModel)]="addeddmrlist[i].way_bill_number ">
                                        </div>
                                        <div class="col-md-3">
                                            <label>Delivery Challan Number</label>
                                            <input class="inputstyles" placeholder="Enter Delivery Challan Number"
                                                name="dc_number" [(ngModel)]="addeddmrlist[i].dc_number ">
                                        </div>
                                        <div class="col-md-3">
                                            <label>Delivery Challan Date</label>
                                            <input type="date" [max]="today" class="inputstyles"
                                                placeholder="Enter Delivery Challan Date" name="dc_date"
                                                [(ngModel)]="addeddmrlist[i].dc_date">
                                        </div>
                                        <div class="col-md-3">
                                            <label>PO Date</label>
                                            <input type="date" [max]="today" class="inputstyles"
                                                placeholder="Enter PO Date" name="purchase_order_date"
                                                [(ngModel)]="addeddmrlist[i].purchase_order_date">
                                        </div>
                                        <div class="col-9">
                                            <label><b>Comments:</b> </label>
                                            <textarea class="inputstyles" rows="2" cols="50" id="" style="resize: none;"
                                                [(ngModel)]="addeddmrlist[i].comments">
                                            </textarea>
                                        </div>


                                    </div>
                                    <!-- </div> -->

                                    <br>
                                    <div class="row p-t-10">
                                        <div class="col-12 col-md-12">
                                            <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                            <div class="table-responsive mat-table-wrapper">
                                                <div class="example-table-container"
                                                    style="overflow-x:acroll;width:125%">

                                                    <table style="height:100px;">
                                                        <tr style=" background: #e9ecef;
                    font-weight: 600;height: 56px;text-transform: capitalize;">
                                                            <th>Action</th>
                                                            <th>S.NO</th>
                                                            <th>Material Code</th>
                                                            <th>Material Description</th>
                                                            <th>UOM</th>

                                                            <th>Invoice Qty</th>
                                                            <th>Received Qty</th>
                                                            <th>Shortage Qty</th>
                                                            <th>Excess Qty</th>
                                                            <th>Accepted Qty</th>
                                                            <th>Rejected Qty</th>
                                                            <th>Unit Price</th>
                                                            <th>Discount %</th>
                                                            <th>Discount Value</th>
                                                            <th>Basic Price</th>
                                                            <th>Tax Description</th>
                                                            <th>Tax %</th>
                                                            <th>Tax Value</th>
                                                            <th>Freight Split</th>
                                                            <th>Basic Freight</th>
                                                            <th>Other Charges Split</th>
                                                            <th>Other Tax Split</th>
                                                            <th>Total Price</th>
                                                            <th>Storage Location</th>
                                                            <th>Valuation Type</th>
                                                            <th>Item Remarks</th>

                                                        </tr>

                                                        <tr *ngFor="let model of item;let i=index">
                                                            <td>
                                                                <button mat-icon-button
                                                                    aria-label="Example icon button with a vertical three dot icon"
                                                                    (click)="deleterowItem(item,i)">
                                                                    <mat-icon style="color: red;">delete</mat-icon>
                                                                </button>
                                                            </td>
                                                            <td>{{i+1}}</td>
                                                            <!-- <td width="20%"><span class="example-full-width" >{{model.LABEL_NAME}}</span></td> -->
                                                            <td>
                                                                {{model.material_code}}
                                                                <!-- <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter text" value="" name="matcode" [(ngModel)]="model.material_code"  readonly>
                        </mat-form-field> -->
                                                            </td>
                                                            <td>
                                                                {{model.material_description}}
                                                                <!-- <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter text" value="" name="matname" [(ngModel)]="model.material_description" readonly>
                        </mat-form-field> -->
                                                            </td>
                                                            <td>
                                                                {{model.unit_of_measurment}}
                                                                <!-- <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter text" value="" name="uom" [(ngModel)]="model.unit_of_measurment" readonly>
                        </mat-form-field> -->
                                                            </td>

                                                            <td>
                                                                {{model.invoice_quantity}}
                                                                <!-- <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter text" value="" name="acceptedqty{{i}}" [(ngModel)]="MATDATA[i].accepted_quantity" >
                        </mat-form-field> -->
                                                            </td>
                                                            <td>
                                                                {{model.received_quantity}}
                                                                <!-- <mat-form-field class="example-full-width">
                          <input matInput placeholder="Enter text" value="" name="rejectedqty{{i}}" [(ngModel)]="MATDATA[i].rejeted_quantity" >
                        </mat-form-field> -->
                                                            </td>
                                                            <td>

                                                                {{model.shortage_quantity}}
                                                            </td>
                                                            <td>
                                                                {{model.excess_quantity}}
                                                            </td>
                                                            <td>
                                                                {{model.accepted_quantity}}
                                                            </td>
                                                            <td>
                                                                {{model.rejected_quantity||0}}
                                                            </td>
                                                            <td>
                                                                {{model.unit_price | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.discount_percent | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.discount_value | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.basic_price | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.tax_description}}
                                                            </td>
                                                            <td>
                                                                {{model.tax_percent| number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.tax_value | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.freight_split | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.basic_freight | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.other_charges_split | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.other_tax_split | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                {{model.total_price | number:'1.2-2'}}
                                                            </td>
                                                            <td>
                                                                <input class="inputstyles" style="height: 35px;"
                                                                placeholder="Storage Location" name="storage_location"
                                                                [(ngModel)]="item[i].storage_location" readonly>
                                                                <!-- <mat-form-field class="example-full-width"
                                                                    appearance="none" class="matformfieldstyles">
                                                                    <input type="text"
                                                                        placeholder="Search Storage Location "
                                                                        aria-label="Number" matInput style="border:none"
                                                                        class="inputdropdown" name="storage_location"
                                                                        [(ngModel)]="model.storage_location"
                                                                        (keyup)="getstorageDATA1($event)"
                                                                        [matAutocomplete]="auto7" />

                                                                    <mat-autocomplete autoActiveFirstOption
                                                                        #auto7="matAutocomplete">

                                                                        <mat-option *ngFor="let item of storageData"
                                                                            [value]="item.storage_location">
                                                                            {{item.storage_location}}</mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-form-field> -->
                                                            </td>
                                                            <td>
                                                                <input class="inputstyles" style="height: 35px;"
                                                                placeholder="Enter Remarks" name="valution_type"
                                                                [(ngModel)]="model.valuation_type" readonly>
                                                                <!-- <select class="dropdownstyles" name="valution_type"
                                                                    [(ngModel)]="model.valuation_type">
                                                                    <option selected disabled>Select One</option>
                                                                    <option *ngFor="let valution of valutiondata"
                                                                        [ngValue]="valution.code">{{valution.code}}
                                                                    </option>
                                                                </select> -->
                                                            </td>
                                                            <td>
                                                                <!-- {{model.item_remarks}} -->
                                                                <!-- <mat-form-field class="example-full-width"> -->
                                                                <input class="inputstyles" style="height: 35px;"
                                                                    placeholder="Enter Remarks" name="item_remarks"
                                                                    [(ngModel)]="item[i].item_remarks" readonly>
                                                                <!-- </mat-form-field> -->
                                                            </td>
                                                            <!-- <button mat-icon-button
                        aria-label="Example icon button with a vertical three dot icon"
                       >
                        <mat-icon #myButton (click)="savedata(model.received_quantity,model.acceptedqty,model.rejectedqty,model.itemremarks)" >save</mat-icon>
                    </button> -->



                                                        </tr>
                                                        <!-- <tr>
                      <td style="height: 45px;text-align: center;" *ngIf="commentsList == 0" colspan="4">No Records Found</td>
                    </tr> -->
                                                    </table>

                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                </mat-expansion-panel>

                            </mat-accordion>
                        </div>



                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
              GRN  Pending List
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <ng-container>

                            <div class="row p-t-10">
                                <div class="col-md-4 mt-2 mb-3 d-flex">
                                    <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                                    <select class="dropdownstyles" name="columnname" [(ngModel)]="columnnamegrnpnd"
                                        (change)="onChangegrnpnd()">
                                        <option value="" selected disabled>Select Column</option>
                                        <!-- <option value="qc_number">QC Number</option> -->
                                        <option value="number">DMR Number</option>
                                        <option value="vendor_name">Vendor Name</option>
                                        <!-- <option value="invoice_number">Invoice Number</option> -->
                                        <!-- <option value="company_name">Company Name</option> -->
                                        <!-- <option value="purchase_order_number">PO Number</option> -->
                                        <!-- <option value="count">Count</option>
                                        <option value="value">Value</option> -->
                                    </select>
                                </div>
                                <div class="col-md-3 mt-2 mb-3 ">
                                    <input type="search" class="searchfield" placeholder="Search"
                                        [(ngModel)]="searchDatagrnpnd" (input)="searchgrnpnd()">
                                </div>
                                <div class="col-5 col-md-5 mt-2 mb-3 text-right">
                                    <button mat-icon-button value="Refresh" matTooltip="Refresh"
                                        (click)="getgoodslistpnd()" class="custom-icon-button ml-3">
                                        <span class="material-icons">
                                            refresh
                                        </span>
                                    </button>
                                </div>
                                <div class="col-12 col-md-12">
                                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                    <div class="table-responsive mat-table-wrapper">
                                        <div class="example-table-container">
                                            <table mat-table [dataSource]="dataSourcemaingrnpnd" style="border:none;" matSort>


                                                <ng-container matColumnDef="sno">
                                                    <th mat-header-cell *matHeaderCellDef
                                                        style="width:6%;font-weight: 600;">S.NO</th>
                                                    <td mat-cell *matCellDef="let row;let i=index;" class="text-center">
                                                        {{(i+1) + (masterpaginator?.pageIndex *
                                                        masterpaginator?.pageSize)}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="qc_number">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="qc_number">QC
                                                        Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.qc_number}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="qc_date">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="qc_date">QC
                                                        Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.qc_date | date: 'dd/MM/yyyy '}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="dmr_number">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="dmr_number">DMR
                                                        Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.dmr_number }}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="dmr_date">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="dmr_date">DMR
                                                        Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.dmr_date | date: 'dd/MM/yyyy '}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="qc_sent_date">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="qc_sent_date">Sent
                                                        From Stores
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.qc_sent_date | date: 'dd/MM/yyyy hh:mm a'}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="qc_release_date">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="qc_release_date">QC
                                                        Release Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.qc_release_date | date: 'dd/MM/yyyy hh:mm a'}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="purchase_order_number">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="purchase_order_number">PO
                                                        Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.purchase_order_number }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="company_name">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="company_name">
                                                        Company Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.company_name}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="vendor_name">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="vendor_name">
                                                        Vendor Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.vendor_name}}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="count">
                                                    <th mat-header-cell style="width: 1%;" *matHeaderCellDef style="font-weight: 600" mat-sort-header="count">Count
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.count}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="action">
                                                    <th mat-header-cell *matHeaderCellDef
                                                        style="width:16%;font-weight: 600">
                                                        Action
                                                    </th>
                                                    <td mat-cell *matCellDef="let row" class="text-center">
                                                        <mat-icon class="ml-2 attachFileDoc"
                                                            matTooltip="View Attachments"
                                                            (click)="viewAttachment1(viewFilemodel,row)">attach_file</mat-icon>
                                                        <mat-icon style="cursor:pointer" class="ml-2"
                                                            matTooltip="Pick Document"
                                                            (click)="CreateNewDoc(row)">note_add</mat-icon>
                                                    </td>
                                                </ng-container>


                                                <tr mat-header-row
                                                    *matHeaderRowDef="displayedColumnsgrnpnd;sticky: true">
                                                </tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumnsgrnpnd"></tr>
                                            </table>
                                        </div>
                                        <div  class="text-center">
                                            <h4 class="text-secondary m-3" *ngIf="loadingRecordsgrnpnd">
                                                Loading...
                                            </h4>
                                            <h4 class="text-secondary m-3" *ngIf=" totalRecordsgrnpnd == 0">
                                                No Records Found
                                            </h4>
                                        </div>
                                        <!-- <div *ngIf="totalRecordsgrnpnd == 0" class="text-center">
                                            <h4 class="m-3">No Records Found</h4>

                                        </div> -->
                                        <ng-container *ngIf="totalRecordsgrnpnd > 0">
                                        <mat-paginator [length]="totalRecordsgrnpnd" [pageSize]="pageSize1"
                                            [pageSizeOptions]="[5,10, 25, 50, 75, 100]" #masterpaginator
                                            (page)="onpageeventgrnpnd($event)">
                                        </mat-paginator>
                                    </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
              GRN  Completed List
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <ng-container>
                            <div class="row p-t-10">
                                <div class="col-md-4 mt-2 mb-3 d-flex">
                                    <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                                    <select class="dropdownstyles" name="columnname" [(ngModel)]="columnname"
                                        (change)="onChange()">
                                        <option value="" selected disabled>Select Column</option>
                                        <option value="number">GRN Number</option>
                                        <option value="dmr_number">DMR Number</option>
                                        <option value="vendor_name">Vendor Name</option>
                                        <option value="invoice_number">Invoice Number</option>
                                        <!-- <option value="company_name">Company Name</option> -->
                                        <option value="purchase_order_number">PO Number</option>
                                       
                                    </select>
                                </div>
                                <div class="col-md-3 mt-2 mb-3 ">
                                    <input type="search" class="searchfield" placeholder="Search"
                                        [(ngModel)]="searchData" (input)="search()">
                                </div>
                                <div class="col-5 col-md-5 mt-2 mb-3 text-right">
                                    <button mat-icon-button value="Refresh" matTooltip="Refresh"
                                        (click)="getgoodslist()" class="custom-icon-button ml-3">
                                        <span class="material-icons">
                                            refresh
                                        </span>
                                    </button>
                                </div>
                                <div class="col-12 col-md-12">
                                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                    <div class="table-responsive mat-table-wrapper">
                                        <div class="example-table-container">
                                            <table mat-table [dataSource]="dataSourcemain" style="border:none;" matSort>


                                                <ng-container matColumnDef="sno">
                                                    <th mat-header-cell *matHeaderCellDef
                                                        style="width:6%;font-weight: 600;">S.NO</th>
                                                    <td mat-cell *matCellDef="let row;let i=index;" class="text-center">
                                                        {{(i+1) + (masterpaginator1?.pageIndex *
                                                        masterpaginator1?.pageSize)}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="grn_number">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="number">GRN
                                                        Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.number}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="dmr_number">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="dmr_number">DMR
                                                        Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.dmr_number }}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="date">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="date">Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.date | date: 'dd/MM/yyyy '}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="purchase_order_number">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="purchase_order_number">PO
                                                        Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.purchase_order_number }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="purchase_order_date">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="purchase_order_date">PO
                                                        Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.purchase_order_date | date: 'dd/MM/yyyy HH:mm'}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="company_name">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="company_name">
                                                        Company Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.company_name}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="vendor_name">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="vendor_name">
                                                        Vendor Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.vendor_name}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="invoice_number">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="invoice_number">
                                                        Invoice Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.invoice_number}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="count">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="count">Count
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.count}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="value">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="value">Value
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.value}}
                                                    </td>
                                                </ng-container>
                                                <!-- <ng-container matColumnDef="ActiveStatus">
                                <th mat-header-cell *matHeaderCellDef style="font-weight: 600">Active Status
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    {{row.is_active}}
                                </td>
                            </ng-container> -->
                                                <ng-container matColumnDef="action">
                                                    <th mat-header-cell *matHeaderCellDef
                                                        style="width:16%;font-weight: 600">
                                                        Action
                                                    </th>
                                                    <td mat-cell *matCellDef="let row" class="text-center">
                                                        <mat-icon matTooltip="View"
                                                            style="border:none;background: none;cursor: pointer;"
                                                            (click)="viewdata(row)">remove_red_eye</mat-icon>
                                                        <button matTooltip="Edit" mat-icon-button
                                                            (click)="editdata(row,editmodel)">
                                                            <mat-icon style="color:blue">edit</mat-icon>
                                                        </button>

                                                        <!-- <button mat-icon-button style="color: red;border:none;background: none;"
                                        aria-label="Example icon button with a vertical three dot icon" 
                                        (click)="deleteItem(row,deleteItemModel)"> -->

                                                        <mat-icon matTooltip="Delete"
                                                            style="color: red;border:none;background: none;cursor: pointer;"
                                                            (click)="deleteItem(row,deleteItemModel)">delete</mat-icon>&nbsp;
                                                            <mat-icon matTooltip="Hold"
                                                            style="border:none;background: none;cursor: pointer;"
                                                            (click)="addHold(row,holdItemModel)">pause_circle_outline</mat-icon>&nbsp;
                                                            <!-- (click)="printFormats(row) printdmr -->
                                                        <mat-icon matTooltip="Print" (click)="printFormats(row)"
                                                            style="cursor:pointer;"> local_printshop</mat-icon>
                                                        &nbsp;
                                                        <mat-icon matTooltip="Upload"
                                                            (click)="openfileuploadmodel(fileuploadmodel,row)"
                                                            style="cursor:pointer;font-size:24px;color:#28b351">file_upload</mat-icon>
                                                        &nbsp;
                                                        <mat-icon matTooltip="Proceed for Approvals"
                                                            [ngClass]="row.doc_sign === 'SENT' ? 'disabled' : 'enabled'"
                                                            (click)="getApprovals(approvalsmodel,row)"> how_to_reg
                                                        </mat-icon>
                                                        <!-- </button>
                                    <button 
                                    class="savebutton" style="margin-left: -2%;
                                    height: 21px;padding-right: 33px;font-size: 93%;"
                                    aria-label="Example icon button with a vertical three dot icon"
                                    (click)="printdmr(row)">Print
                                    
                                </button>&nbsp;
                                <i class="fa fa-paperclip" aria-hidden="true" (click)="openfileuploadmodel(fileuploadmodel,row)"
                                style="cursor:pointer;font-size:16px"></i> -->
                                                    </td>
                                                </ng-container>


                                                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                                </tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                            </table>
                                        </div>
                                    </div>
                                        <div class="text-center">
                                            <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                                Loading...
                                            </h4>
                                            <h4 class="text-secondary m-3" *ngIf="totalcompletedlistcount == 0">
                                                No Records Found
                                            </h4>
                                        </div>
                                        <!-- <div *ngIf="totalcompletedlistcount == 0 " class="text-center">
                                            <h4 class="m-3">
                                                No Records Found
                                            </h4>
                                        </div> -->
                                        <ng-container *ngIf="totalcompletedlistcount > 0">
                                        <mat-paginator [length]="totalcompletedlistcount" [pageSize]="10"
                                            [pageSizeOptions]="[5,10, 25, 50, 75, 100]" #masterpaginator1
                                            (page)="onpageevent($event)">
                                        </mat-paginator>
                                        </ng-container>
                                    
                                        
                                </div>
                            </div>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
              GRN  List of Hold
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <ng-container>

                            <div class="row p-t-10">
                                <div class="col-md-4 mt-2 mb-3 d-flex">
                                    <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                                    <select class="dropdownstyles" name="columnnamegrnlistofhold" [(ngModel)]="columnnamegrnlistofhold"
                                        (change)="onChange()">
                                        <option value="" selected disabled>Select Column</option>
                                        <option value="number">GRN Number</option>
                                        <option value="dmr_number">DMR Number</option>
                                        <option value="vendor_name">Vendor Name</option>
                                        <option value="invoice_number">Invoice Number</option>
                                        <!-- <option value="company_name">Company Name</option> -->
                                        <option value="purchase_order_number">PO Number</option>
                                        <!-- <option value="count">Count</option>
                                        <option value="value">Value</option> -->
                                    </select>
                                </div>
                                <div class="col-md-3 mt-2 mb-3 ">
                                    <input type="search" class="searchfield" placeholder="Search"
                                        [(ngModel)]="searchDatagrnlistofhold" (input)="searchgrnlistofhold()">
                                </div>
                                <div class="col-5 col-md-5 mt-2 mb-3 text-right">
                                    <button mat-icon-button value="Refresh" matTooltip="Refresh"
                                        (click)="getlistofhold()" class="custom-icon-button ml-3">
                                        <span class="material-icons">
                                            refresh
                                        </span>
                                    </button>
                                </div>
                                <div class="col-12 col-md-12">
                                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                    <div class="table-responsive mat-table-wrapper">
                                        <div class="example-table-container">
                                            <table mat-table [dataSource]="dataSourcemaingrnlistofhold" style="border:none;" matSort>


                                                <ng-container matColumnDef="sno">
                                                    <th mat-header-cell *matHeaderCellDef
                                                        style="width:6%;font-weight: 600;">S.NO</th>
                                                    <td mat-cell *matCellDef="let row;let i=index;" class="text-center">
                                                        {{(i+1) + (masterpaginatorhold?.pageIndex *
                                                            masterpaginatorhold?.pageSize)}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="grn_number">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="number">GRN
                                                        Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.number}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="dmr_number">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="dmr_number">DMR
                                                        Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.dmr_number }}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="date">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="date">Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.date | date: 'dd/MM/yyyy '}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="purchase_order_number">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="purchase_order_number">PO
                                                        Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.purchase_order_number }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="purchase_order_date">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="purchase_order_date">PO
                                                        Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.purchase_order_date | date: 'dd/MM/yyyy HH:mm'}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="company_name">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="company_name">
                                                        Company Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.company_name}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="vendor_name">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="vendor_name">
                                                        Vendor Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.vendor_name}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="invoice_number">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="invoice_number">
                                                        Invoice Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.invoice_number}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="count">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="count">Count
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.count}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="value">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="value">Value
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.value}}
                                                    </td>
                                                </ng-container>
                                                <!-- <ng-container matColumnDef="ActiveStatus">
                                <th mat-header-cell *matHeaderCellDef style="font-weight: 600">Active Status
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    {{row.is_active}}
                                </td>
                            </ng-container> -->
                                                <ng-container matColumnDef="action">
                                                    <th mat-header-cell *matHeaderCellDef
                                                        style="width:16%;font-weight: 600">
                                                        Action
                                                    </th>
                                                    <td mat-cell *matCellDef="let row" class="text-center">
                                                        <button matTooltip="Release" mat-icon-button
                                                            (click)="releaseFile(row)">
                                                            <mat-icon>open_in_new</mat-icon>
                                                        </button>
                                                        <mat-icon matTooltip="Delete"
                                                        style="color: red;border:none;background: none;cursor: pointer;"
                                                        (click)="deleteItem(row,deleteItemModel)">delete</mat-icon>
                                                    </td>
                                                </ng-container>


                                                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                                </tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                            </table>
                                        </div>
                                        <div class="text-center">
                                            <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                                Loading...
                                            </h4>
                                            <h4 class="text-secondary m-3" *ngIf="totalRecordsgrnlistofhold === 0">
                                                No Records Found
                                            </h4>
                                        </div>
                                        <ng-container *ngIf="totalRecordsgrnlistofhold > 0">
                                        <mat-paginator [length]="totalRecordsgrnlistofhold" [pageSize]="pageSizehold"
                                            [pageSizeOptions]="[5,10, 25, 50, 75, 100]" #masterpaginatorhold
                                            (page)="onpageeventgrnlsthold($event)">
                                        </mat-paginator>
                                        </ng-container>
                                    
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        <!-- APProval tabs -->
        <mat-tab>
            <ng-template mat-tab-label>My Pending Approvals </ng-template>
            <ng-template matTabContent>
              <mat-card>
                <mat-card-content>
                  <ng-container>
                    <div class="row m-t-10">
                    <div class="col-3 col-md-3 mt-2 mb-3 ">
                        <input type="search" class="searchfield" placeholder="Search GRN Number"
                            [(ngModel)]="pndsearchnumber" (input)="pndSearchData()">
                        </div>
                        <div class="col-9 col-md-9 mt-2 mb-3 text-right">
                        <button mat-icon-button value="Refresh" matTooltip="Refresh" (click)="getDatapnd()"
                            class="custom-icon-button ml-3">
                            <span class="material-icons">
                            refresh
                            </span>
                        </button>
                        </div>
                      <div class="col-md-12 col-12">
                        <div class="table table-responsive text-center" mat-table-wrapper>
                          <div class="example-table-container">
                            <table mat-table [dataSource]="dataSourceListpnd">
                              <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef class="text-center">S.NO</th>
                                <td mat-cell *matCellDef="let row; let i = index" class="text-center">
                                  {{
                                  i +
                                  1 +
                                  grnpaginatorpnd.pageIndex * grnpaginatorpnd.pageSize
                                  }}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="document_name">
                                <th mat-header-cell *matHeaderCellDef>Document Name</th>
                                <td mat-cell *matCellDef="let row">{{ row.document_name }}</td>
                              </ng-container>
                              <ng-container matColumnDef="MSRNnum">
                                <th mat-header-cell *matHeaderCellDef>GRN Number</th>
                                <td mat-cell *matCellDef="let row">{{ row.document_number }}</td>
                              </ng-container>
                              <ng-container matColumnDef="cmpnyName">
                                <th mat-header-cell *matHeaderCellDef>Company Name</th>
                                <td mat-cell *matCellDef="let row">
                                  {{ row.company_name }}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="contractorName">
                                <th mat-header-cell *matHeaderCellDef>Concern Name</th>
                                <td mat-cell *matCellDef="let row">
                                  {{ row.concern_name }}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef>
                                  Description
                                </th>
                                <td mat-cell *matCellDef="let row">
                                  {{ row.description }}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="level">
                                <th mat-header-cell *matHeaderCellDef>
                                  Level
                                </th>
                                <td mat-cell *matCellDef="let row">
                                  {{ row.level }}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>Action</th>
                                <td mat-cell *matCellDef="let row" class="text-center">
                                  <div class="mb-0">
                                    <mat-icon 
                                      (click)="printapproval(row,'pendingApproval')" matTooltip="Print" style="cursor:pointer;">
                                      local_printshop</mat-icon>
                                  </div>
                                </td>
                              </ng-container>
                              <tr mat-header-row *matHeaderRowDef="displayedColumnsListpnd; sticky: true"></tr>
                              <tr mat-row *matRowDef="let row; columns: displayedColumnsListpnd"></tr>
                            </table>
                            <div *ngIf="reaDatapnd == true" class="text-center">
                              <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                Loading...
                              </h4>
                              <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                                No Records Found
                              </h4>
                            </div>
                            <mat-paginator [length]="totalRecordspnd" [pageSize]="pageSizepnd" [pageIndex]="pageIndexpnd"
                              [pageSizeOptions]="[5, 10, 25, 50, 75, 100]" #grnpaginatorpnd (page)="onpageeventpnd($event)">
                            </mat-paginator>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </mat-card-content>
              </mat-card>
      
            </ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label> Completed Approvals </ng-template>
            <ng-template matTabContent>
              <mat-card>
                <mat-card-content>
                  <ng-container>
                    <div class="row m-t-10">
                        <div class="col-3 col-md-3 mt-2 mb-3 ">
                            <input type="search" class="searchfield" placeholder="Search GRN Number"
                                [(ngModel)]="cmpsearchnumber" (input)="cmpSearchData()">
                            </div>
                            <div class="col-9 col-md-9 mt-2 mb-3 text-right">
                            <button mat-icon-button value="Refresh" matTooltip="Refresh" (click)="getDatacmp()"
                                class="custom-icon-button ml-3">
                                <span class="material-icons">
                                refresh
                                </span>
                            </button>
                            </div>
                      <div class="col-md-12 col-12">
                        <div class="table table-responsive text-center" mat-table-wrapper>
                          <div class="example-table-container">
                            <table mat-table [dataSource]="dataSourceListcmp">
                              <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef class="text-center">S.NO</th>
                                <td mat-cell *matCellDef="let row; let i = index" class="text-center">
                                  {{
                                  i +
                                  1 +
                                  grnpaginatorcmp.pageIndex * grnpaginatorcmp.pageSize
                                  }}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="document_name">
                                <th mat-header-cell *matHeaderCellDef>Document Name</th>
                                <td mat-cell *matCellDef="let row">{{ row.document_name }}</td>
                              </ng-container>
                              <ng-container matColumnDef="MSRNnum">
                                <th mat-header-cell *matHeaderCellDef>GRN Number</th>
                                <td mat-cell *matCellDef="let row">{{ row.document_number }}</td>
                              </ng-container>
                              <ng-container matColumnDef="cmpnyName">
                                <th mat-header-cell *matHeaderCellDef>Company Name</th>
                                <td mat-cell *matCellDef="let row">
                                  {{ row.company_name }}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="contractorName">
                                <th mat-header-cell *matHeaderCellDef>Concern Name</th>
                                <td mat-cell *matCellDef="let row">
                                  {{ row.concern_name }}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef>
                                  Description
                                </th>
                                <td mat-cell *matCellDef="let row">
                                  {{ row.description }}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="level">
                                <th mat-header-cell *matHeaderCellDef>
                                  Level
                                </th>
                                <td mat-cell *matCellDef="let row">
                                  {{ row.level }}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>Action</th>
                                <td mat-cell *matCellDef="let row" class="text-center">
                                  <div class="mb-0">
                                    <mat-icon 
                                      (click)="printapproval(row,'completedApproval')" matTooltip="Print" style="cursor:pointer;">
                                      local_printshop</mat-icon>
                                  </div>
                                </td>
                              </ng-container>
                              <tr mat-header-row *matHeaderRowDef="displayedColumnsListcmp; sticky: true"></tr>
                              <tr mat-row *matRowDef="let row; columns: displayedColumnsListcmp"></tr>
                            </table>
                            <div *ngIf="reaDatacmp == true" class="text-center">
                              <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                Loading...
                              </h4>
                              <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                                No Records Found
                              </h4>
                            </div>
                            <mat-paginator [length]="totalRecordscmp" [pageSize]="pageSizecmp" [pageIndex]="pageIndexcmp"
                              [pageSizeOptions]="[5, 10, 25, 50, 75, 100]" #grnpaginatorcmp (page)="onpageeventcmp($event)">
                            </mat-paginator>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </mat-card-content>
              </mat-card>
      
            </ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>All Pending Approvals</ng-template>
            <ng-template matTabContent>
              <mat-card>
                <mat-card-content>
                  <ng-container>
                    <div class="row m-t-10">
                    <div class="col-3 col-md-3 mt-2 mb-3 ">
                        <input type="search" class="searchfield" placeholder="Search GRN Number"
                            [(ngModel)]="allpndsearchnumber" (input)="allpndSearchData()">
                        </div>
                        <div class="col-9 col-md-9 mt-2 mb-3 text-right">
                        <button mat-icon-button value="Refresh" matTooltip="Refresh" (click)="getDataallpnd()"
                            class="custom-icon-button ml-3">
                            <span class="material-icons">
                            refresh
                            </span>
                        </button>
                        </div>
                      <div class="col-md-12 col-12">
                        <div class="table table-responsive text-center" mat-table-wrapper>
                          <div class="example-table-container">
                            <table mat-table [dataSource]="dataSourceListallpnd">
                              <ng-container matColumnDef="sno">
                                <th mat-header-cell *matHeaderCellDef class="text-center">S.NO</th>
                                <td mat-cell *matCellDef="let row; let i = index" class="text-center">
                                  {{
                                  i +
                                  1 +
                                  grnpaginatorallpnd.pageIndex * grnpaginatorallpnd.pageSize
                                  }}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="document_name">
                                <th mat-header-cell *matHeaderCellDef>Document Name</th>
                                <td mat-cell *matCellDef="let row">{{ row.document_name }}</td>
                              </ng-container>
                              <ng-container matColumnDef="MSRNnum">
                                <th mat-header-cell *matHeaderCellDef>GRN Number</th>
                                <td mat-cell *matCellDef="let row">{{ row.document_number }}</td>
                              </ng-container>
                              <ng-container matColumnDef="cmpnyName">
                                <th mat-header-cell *matHeaderCellDef>Company Name</th>
                                <td mat-cell *matCellDef="let row">
                                  {{ row.company_name }}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="contractorName">
                                <th mat-header-cell *matHeaderCellDef>Concern Name</th>
                                <td mat-cell *matCellDef="let row">
                                  {{ row.concern_name }}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef>
                                  Description
                                </th>
                                <td mat-cell *matCellDef="let row">
                                  {{ row.description }}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="level">
                                <th mat-header-cell *matHeaderCellDef>
                                  Level
                                </th>
                                <td mat-cell *matCellDef="let row">
                                  {{ row.level }}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>Action</th>
                                <td mat-cell *matCellDef="let row" class="text-center">
                                  <div class="mb-0">
                                    <mat-icon 
                                      (click)="printapproval(row,'allpendingApproval')" matTooltip="Print" style="cursor:pointer;">
                                      local_printshop</mat-icon>
                                  </div>
                                </td>
                              </ng-container>
                              <tr mat-header-row *matHeaderRowDef="displayedColumnsListallpnd; sticky: true"></tr>
                              <tr mat-row *matRowDef="let row; columns: displayedColumnsListallpnd"></tr>
                            </table>
                            <div *ngIf="reaDataallpnd == true" class="text-center">
                              <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                Loading...
                              </h4>
                              <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                                No Records Found
                              </h4>
                            </div>
                            <mat-paginator [length]="totalRecordsallpnd" [pageSize]="pageSizeallpnd" [pageIndex]="pageIndexallpnd"
                              [pageSizeOptions]="[5, 10, 25, 50, 75, 100]" #grnpaginatorallpnd (page)="onpageeventallpnd($event)">
                            </mat-paginator>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </mat-card-content>
              </mat-card>
      
            </ng-template>
          </mat-tab>
         <!-- end -->
        <mat-tab>
            <ng-template mat-tab-label>
                Activity Log
            </ng-template>
            <ng-template matTabContent>
                <app-activitylog [activitylogfor]="'GoodsReceipt'"></app-activitylog>
                  </ng-template>
        </mat-tab>
    </mat-tab-group>
    <ng-template #goodsreceiptmodel>
        <div mat-dialog-title class="dialog-title mb-0">
            <div class="row">
                <div class="col-8">
                    <h2>Add DMR List</h2>
                </div>
                <div class="col-4 text-right">
                    <button mat-icon-button aria-label="close dialog" class="closeicon1" (click)="closemodel()"
                    style="border:none;background:none">
                    <mat-icon class="icon1">close</mat-icon>
                </button>
                </div>
            </div>
        </div>
        <mat-dialog-content style="overflow: initial;">
            <form #goodsform="ngForm" (ngSubmit)="saveformdata(goodsform)">

                <div class="row">
                    <div class="col-md-4 mt-2">
                        <label>DMR Number<span class="mandetory">*</span></label>
                    </div>
                    <div class="col-md-4">
                        <!-- <select class="dropdownstyles" name="dmrnumber" [(ngModel)]="model.dmrnumber" (change)="selecteddmr()">
                <option selected disabled>Select One</option>
               <option *ngFor="let item of dmrDATA" [ngValue]="item.number">{{item.number}}</option>
              
            </select> -->
                        <mat-form-field appearance="outline" style="font-size:12px; width: 180%;">
                            <mat-select placeholder="Select DMR Number" [(ngModel)]="model1.selectedDMR"
                                [ngModelOptions]="{standalone: true}" multiple (selectionChange)="getselecteddata()">
                                <mat-option *ngFor="let item of dmrDATA" [value]="item.number" style="font-size:12px; width:200%">
                                    {{item.number}}
                                </mat-option>
                            </mat-select>

                        </mat-form-field>

                    </div>
                    <!-- <div class="col-md-2">
            <label>Date</label>
        </div>
        <div class="col-md-4">
            <input type="date" style="height:110%;text-transform: uppercase;" class="inputstyles" name="dateee"
                            [(ngModel)]="model1.dateee" dateFormat="yyyy/mm/dd">
        </div> -->

                </div>
                <div class="row">
                    <div class="col-md-4 mt-3">
                        <label>Selected List<span class="mandetory">*</span></label>
                    </div>
                    <div class="col-md-4">
                        <textarea style="height:110%;width:182%" class="inputstyles" [(ngModel)]="model1.selectionList"
                            [ngModelOptions]="{standalone: true}"></textarea>

                    </div>
                    <!-- <div class="col-md-2">
            <label>Purchase Order Date</label>
        </div>
        <div class="col-md-4">
            <input type="date" style="height:110%;text-transform: uppercase;" class="inputstyles" name="podate" [(ngModel)]="model1.podate">
            
        </div> -->

                </div><br>
                <button mat-raised-button class="savbtn" [disabled]="!model1.selectedDMR || !model1.selectionList">Proceed</button>
            </form>
        </mat-dialog-content>

    </ng-template>
    <ng-template #deleteItemModel>
        <!-- <h1 mat-dialog-title>Delete Activity</h1> -->
         <div mat-dialog-title class="mb-0">
            <div class="row">
                <div class="col-11">
                    <h1>Delete Activity</h1>
                </div>
                <div class="col-1 text-right">
                    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon1" (click)="closemodel()" >
                        <mat-icon class="icon1">close</mat-icon>
                    </button>
                </div>
            </div>
         </div>
        <div mat-dialog-content>
            <p>Are you sure, you want to delete record?</p>
            <div class="row">
                <div class="col-md-2">
                    <label style="padding-top:5px;">Reason</label>
                </div>
                <div class="col-md-8">
                    <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                        [(ngModel)]="deletemodel.reason">
                </div>
            </div>
        </div><br>
        <div mat-dialog-actions class="justify-content-center">
            <!-- <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">Close</button>&nbsp; -->
            <button mat-button cdkFocusInitial (click)="deleteFile()" class="matButton"
                [disabled]="!deletemodel.reason">Delete</button>
        </div>
    </ng-template>
    <ng-template #fileuploadmodel>
        <div mat-dialog-title class="dialog-title mb-0">
            <div class="row">
                <div class="col-8">
                    <h2>Goods Receipt: {{grnumber}}</h2>
                </div>
                <div class="col-4 text-right">
                    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon1" (click)="closemodel()">
                    <mat-icon class="icon1">close</mat-icon>
                </button>
                </div>
            </div>
        </div>
        <div mat-dialog-content class="row">
            <div *ngIf="filedata">
                <!-- <span *ngFor="let item of filedata"> -->
                <!-- <span *ngFor="let item of filedata"> <a href="{{item.file_path}}">{{item.file_path}}</a></span> -->
                <!-- <h4>View File:&nbsp;&nbsp; <i class="fas fa-eye text-cyan m-r-20 " 
    style="cursor: pointer;" (click)="viewDoc(item)"></i></h4> -->
                <table>
                    <thead style="background: #e9ecef;">
                        <!-- <tr> -->
                        <th>S.NO</th>
                        <th>Created By</th>
                        <th>Created Date</th>
                        <th>File Name</th>
                        <th>Action</th>
                        <!-- </tr> -->
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filedata;let i = index">
                            <td>{{i+1}}</td>
                            <td>{{item.created_by}}</td>
                            <td>{{item.created_date}}</td>
                            <td class="tablebody">{{item.file_name}} &nbsp;&nbsp;&nbsp;<i class="fas fa-eye text-cyan m-r-20 "
                                    style="cursor: pointer;" title="View File" (click)="viewDoc(item)"></i></td>
                            <td>
                                <button mat-icon-button class="delete1" matTooltip="Delete"
                                    aria-label="Example icon button with a vertical three dot icon"
                                    (click)="deleterowfile(deletefileModel,item)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- <a style="cursor: pointer;" (click)="viewDoc(item)">{{item.file_name}}</a> -->
                <!-- </span> -->
            </div>
        </div><br>
        <button mat-raised-button type="button" (click)="autoFileUpload1.click()">Attach File</button>
        <div class="file">
            <input hidden class="form-control-file" name="file" type="file" multiple
                (change)="uploadWbsFile($event);showContent(i);" #autoFileUpload1 />
            <ng-container *ngIf="fileUploadUrls.length>0">
                <!-- <span>Selected: {{fileUploadUrls.length}} files</span> -->
            </ng-container>
        </div>
    </ng-template>
    <ng-template #deletefileModel>
        <h1 mat-dialog-title>Delete Activity</h1>
        <div mat-dialog-content>Are you sure, you want to delete file?</div>
        <div mat-dialog-actions>
            <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">Close</button>&nbsp;
            <button mat-button cdkFocusInitial (click)="deleteexistingfile()" class="matButton">Delete</button>
        </div>
    </ng-template>

    <!-- Approvals -->
    <ng-template #approvalsmodel>
        <!-- <span class="d-flex justify-content-between">
            <h1 mat-dialog-title>Document Number:{{documnet_nodata}}</h1>
            <button mat-icon-button class="close-button" [mat-dialog-close]="true">
                <mat-icon class="close-icon">close</mat-icon>
            </button>
        </span> -->
        <div mat-dialog-title class="mb-0">
            <div class="row">
                <div class="col-11">
                    <h1 mat-dialog-title>Document Number:{{documnet_nodata}}</h1>
                </div>
                <div class="col-1 text-right">
                    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon1" (click)="closemodel()" >
                        <mat-icon class="icon1">close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div mat-dialog-content>
            <p>Do you confirm to proceed for Signatures</p>
        </div>
        <div mat-dialog-actions>
            <button mat-button cdkFocusInitial class="matButton" (click)="getConfirmData()">
                Confirm
            </button>&nbsp;
            <button mat-button mat-dialog-close class="matButton">
                Cancel</button>
        </div>
    </ng-template>
    <ng-template #editmodel>
        <!-- <h1 mat-dialog-title></h1> -->
         <div class="row" mat-dialog-title>
            <div class="col-12 text-right">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon1" (click)="closemodel()" >
                    <mat-icon class="icon1">close</mat-icon>
                </button>
            </div>
         </div>
        <div mat-dialog-content>
            <div class="row">
                <div class="col-md-2">
                    <label style="padding-top: 5px">Reason</label>
                </div>
                <div class="col-md-8">
                    <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                        [(ngModel)]="editModel.reason" />
                </div>
            </div>
        </div>
        <br />
        <div mat-dialog-actions class="justify-content-center">
            <!-- <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">Close</button>&nbsp; -->
            <button mat-button cdkFocusInitial (click)="viewDataEdit()" class="matButton"
                [disabled]="!editModel.reason" style="background-color: #8dd94a;">
                Submit
            </button>
        </div>
    </ng-template>

    <ng-template #viewFilemodel>
        <div mat-dialog-title class="dialog-title mb-0">
            <!-- <h2>Daily Material Receipt: {{selectedDMR}}</h2>
            <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon1" (click)="closemodel()" >
                <mat-icon class="icon1">close</mat-icon>
            </button> -->
            <div class="row">
                <div class="col-11">
                    <h2>Daily Material Receipt: {{selectedDMR}}</h2>
                </div>
                <div class="col-1 text-right">
                    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon1" (click)="closemodel()" >
                        <mat-icon class="icon1">close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div mat-dialog-content class="row">
            <div *ngIf="filedataDMR">
                <!-- <span *ngFor="let item of filedata"> -->
                <!-- <span *ngFor="let item of filedata"> <a href="{{item.file_path}}">{{item.file_path}}</a></span> -->
                <!-- <h4>View File:&nbsp;&nbsp; <i class="fas fa-eye text-cyan m-r-20 " 
                style="cursor: pointer;" (click)="viewDoc(item)"></i></h4> -->
                <table>
                    <thead style="background: #e9ecef;">
                        <th>S.NO</th>
                        <th>Created By</th>
                        <th>Created Date</th>
                        <th>File Name</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of filedataDMR;let i = index">
                            <td>{{i+1}}</td>
                            <td>{{item.created_by}}</td>
                            <td>{{item.created_date}}</td>
                            <td class="tablebody">{{item.file_name}} &nbsp;&nbsp;&nbsp;<i class="fas fa-eye text-cyan m-r-20  "
                                    style="cursor: pointer;" title="View File" (click)="viewDocDMR(item)"></i></td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center" *ngIf="filedataDMR.length===0">
                    No Data Found
                </div>
            </div>
        </div><br>
    </ng-template>

    <ng-template #holdItemModel>
        <!-- <h1 mat-dialog-title>Hold Activity</h1> -->
         <div class="mb-0" mat-dialog-title>
            <div class="row">
                <div class="col-11">
                    <h1>Hold Activity</h1>
                </div>
                <div class="col-1 text-right">
                    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon1" (click)="closemodel()" >
                        <mat-icon class="icon1">close</mat-icon>
                    </button>
                </div>
            </div>
         </div>
        <div mat-dialog-content>
            <p>Are you sure, you want to Hold record?</p>
            <div class="row">
                <div class="col-md-2">
                    <label style="padding-top:5px;">Reason</label>
                </div>
                <div class="col-md-8">
                    <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                        [(ngModel)]="holdmodel.reason">
                </div>
            </div>
        </div><br>
        <div mat-dialog-actions class="justify-content-center">
            <!-- <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">Close</button>&nbsp; -->
            <button mat-button cdkFocusInitial (click)="holdFile()" class="matButton"
                [disabled]="!holdmodel.reason">Hold</button>
        </div>
    </ng-template>

    <ng-template #newFileUploadModal>
        <div mat-dialog-title class="dialog-title mb-0">
              <div class="row">
                <div class="col-8">
                  <h2>File Upload</h2>
                </div>
                <div class="col-4 text-right">
                  <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon1"
                        (click)="closemodel()" >
                        <mat-icon class="icon1">close</mat-icon>
                    </button>
                </div>
              </div>
        </div>
        <div >
        
        <div mat-dialog-content class="row">
            <div>
              <table>
                <thead class="table2 text-center">
                  <th>S.NO</th>
                  <th>File Name</th>
                  <th>Action</th>
                </thead>
                <tbody class="text-center">
                  <tr *ngFor="let file of selectedfiles; let i=index;">
                    <td>{{i+1}}</td>
                    <td class="tablebody1">{{file.name}}</td>
                    <td>
                      <button mat-icon-button class="delete1" (click)="deleteFileItem(i)" matTooltip="Delete">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </td>
                  </tr>
                  <tr *ngFor="let file of filedataDMR; let i=index;">
                    <td>{{selectedfiles.length + i + 1}}</td>
                    <td class="tablebody1">{{file.file_name}}</td>
                    <td>
                      <button mat-icon-button class="delete1" (click)="deleteExistingFileItem(i)" matTooltip="Delete">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        
        <br>
        <button type="button" mat-raised-button class="attachfile ml-1" color="secondary" (click)="autoFileUpload2.click()" >
            Attach File&nbsp; <mat-icon class="file2">file_upload</mat-icon></button>
        <div class="file">
            <input hidden class="form-control-file" name="file" type="file" multiple (change)="uploadgifiles($event)"
                #autoFileUpload2>
        </div>
      </div>
        
      </ng-template>

     