<div class="material-tab">
    <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms" [(selectedIndex)]="demo1TabIndex"
        (selectedTabChange)="onTabChanged($event)">
        <mat-tab>
            <ng-template mat-tab-label>Machinery/Equipment/Vehicle </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <div class="row ">
                            <div class="col-md-0 ml-3 mb-2">
                                <button *ngIf="toogle && !viewData" mat-raised-button class="savebutton"
                                    color="secondary" (click)="postCreateEquipmentdetails()" [disabled]="savedisablebtn()">
                                    Save
                                </button>
                                <button *ngIf="!toogle && !viewData " mat-raised-button class="savebutton" 
                                    color="secondary" (click)="saveeditdata()" [disabled]="updatedisablebtn()"
                                    
                                     >
                                     Update
                                </button>
                                <button *ngIf=" viewData " mat-raised-button class="savebutton" color="secondary"
                                    (click)="backbutton()" >Back
                                </button>
                            </div>
                            <div class="col-md-0 ml-2 mb-2">
                                <button *ngIf="!viewData && editbtn" mat-raised-button type="button" (click)="clearpage1()"
                                    class="savebutton">Add New</button>
                            </div>
                            <div class=" col-md-1 ml-1 mb-2">
                                        <button  *ngIf="!viewData" type="button" mat-raised-button class="attachfile" color="secondary"
                                        (click)="openNewFileUploadModal(newFileUploadModal , toogle)">
                                        Attach File&nbsp; <mat-icon
                                            >file_upload</mat-icon></button>
                            </div>
                            <div class="col-md-0 mb-2">
                                <input hidden class="form-control-file" name="file" type="file" multiple
                                    (change)="uploadprfiles($event)" #autoFileUpload2
                                    accept=".doc, .pdf, .jpeg, .jpg, .png, .xlsx">
                                <ng-container *ngIf=" fileUploadUrlsdmr.length > 0">
                                </ng-container>
                            </div>
                            <div class="col text-right" *ngIf="viewData">
                                <p class="idnumber">View of Document Number : {{refnumber}}</p>
                              
                            </div>
                        </div>                     
                        <div class="p-2">
                            <div class="row">
                                <div class="col-md-3">
                                    <label>Date<span class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData " class="viewdata">{{ dateM }}</p> -->
                                    <input
                                    *ngIf="!viewData "
                                      type="date"
                                      class="inputstyles"
                                      name="date"
                                      [(ngModel)]="dateM"
                                      [max]="getToday()"
                                      dateFormat="yyyy-MM-dd"
                                      />
                                    <input *ngIf="viewData" type="text" class=" dropdownstyles"
                                     [(ngModel)]="dateM" [readonly]="viewData" [class.readonly-input]="viewData"
                                    >
                                  </div>
                                  
                                <div class="col-md-7">
                                    <label>Company Name<span class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{companyname}}</p> -->
                                    <select *ngIf="!viewData && toogle" class="dropdownstyles" [(ngModel)]="companyname"
                                        name="companyname">
                                        <option value="" selected disabled>Select One</option>
                                        <option *ngFor="let item of companyData1" [ngValue]="item.company_name">
                                            {{ item.company_name }}
                                        </option>
    
                                    </select>
                                    <input *ngIf="!viewData && !toogle" class="dropdownstyles" [(ngModel)]="companyname"
                                    name="companyname" readonly>
                                    <input *ngIf="viewData" class="dropdownstyles" [(ngModel)]="companyname"
                                    name="companyname" [readonly]="viewData" [class.readonly-input]="viewData">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <label>Vehicle Reg No/Serial No<span class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{vehiclenumber}}</p> -->
                                    <mat-form-field *ngIf="!viewData && !issubmitClicked" class="dropdown matformfieldstyles" appearance="none">
                                        <input type="search" id="inputdropdown" placeholder="Search Vehicle Number" class="vechicleno custom-placeholder"
                                            aria-label="Number" matInput name="vehiclenumber" [(ngModel)]="vehiclenumber"
                                            (keyup)="filter($event.target.value)" [matAutocomplete]="autovehicle"
                                            (focus)="filter($event.target.value); autoCompleteTrigger.openPanel()"
                                            #autoCompleteTrigger="matAutocompleteTrigger">
                                        <button mat-icon-button matSuffix *ngIf="vehiclenumber" aria-label="Clear" (click)="clearSearch()">
                                            <mat-icon>clear</mat-icon>
                                        </button>
                                        <mat-autocomplete autoActiveFirstOption #autovehicle="matAutocomplete"
                                            (optionSelected)="getUserDetails($event.option.value)">
                                            <mat-option *ngFor="let item of vehicledata" [value]="item.vehicle_number">
                                                {{item.vehicle_number}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    
                                    <mat-form-field *ngIf="!viewData && issubmitClicked" class="dropdown" appearance="none"
                                        class="matformfieldstyles">
                                        <input type="text" id="inputdropdown" placeholder="Search Vehicle Number" class="vechicleno holdergap"
                                            aria-label="Number" matInput name="vehiclenumber" [(ngModel)]="vehiclenumber" 
                                            (keyup)="filter($event.target.value)" [matAutocomplete]="autovehicle" readonly
                                            >
                                        <mat-autocomplete autoActiveFirstOption #autovehicle="matAutocomplete"
                                            (optionSelected)="getUserDetails($event.option.value )">
                                            <mat-option *ngFor="let item of vehicledata" [value]="item.vehicle_number" >
                                                {{item.vehicle_number}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    <input *ngIf="viewData" type="text" class="dropdownstyles"
                                     [(ngModel)]="vehiclenumber" readonly [class.readonly-input]="viewData">
                                </div>
                                <!-- <div class="col-md-3">
                                    <label for="exampleInputEmail1">Vehicle Name<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{vehiclename}}</p>
                                    <input *ngIf="!viewData" type="text" class=" dropdownstyles"
                                        id="vechiclename" name="vehiclename" [(ngModel)]="selectedVehicle[0].vehicle_model" readonly
                                        placeholder="Equip/vechicle Name">
                                </div>
                                <div class="col-md-3">
                                    <label for="exampleInputEmail1">Vendor Name<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{vendorname}}</p>
                                    <input *ngIf="!viewData" type="text" class=" dropdownstyles"
                                        id="vechiclename" name="vendorname" [(ngModel)]="selectedVehicle[0].contractor_name" readonly
                                        placeholder="Vendor Name">
                                </div> -->
                                <div class="col-md-3">
                                    <label for="exampleInputEmail1">Vehicle Model</label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{vehiclename}}</p> -->
                                    <input  *ngIf="!viewData" type="text" class=" dropdownstyles custom-placeholder"
                                        id="vechiclename" name="vehiclename"  [(ngModel)]="vechiclenames" readonly
                                        placeholder="Equip/vechicle Name" >
                                        <input *ngIf="viewData" type="text" class="dropdownstyles"
                                        [(ngModel)]="vehiclename" readonly [class.readonly-input]="viewData">
                                </div>
                                <div class="col-md-6">
                                    <label for="exampleInputEmail1">Vendor Name</label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{vendorname}}</p> -->
                                    <input *ngIf="!viewData"  type="text" class=" dropdownstyles custom-placeholder"
                                        id="vechiclename" name="vendorname" [(ngModel)]="vendornames" readonly
                                        placeholder="Vendor Name" >
                                        <input *ngIf="viewData" type="text" class="dropdownstyles"
                                        [(ngModel)]="vendorname" readonly [class.readonly-input]="viewData">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
    
                                    <label for="exampleInputEmail1">Units of Measurement<span
                                            class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{Units}}</p> -->
                                    <select *ngIf="!viewData" class="dropdownstyles" placeholder="Units of Measurement"
                                     [(ngModel)]="unitsmeasurement" (ngModelChange)="onUnitChange()"
                                        name="unitsmeasurement">
                                        <option value="" disabled>Select One</option>
                                        <option value="KMS">KMS</option>
                                        <option value="HRS">HRS</option>
                                        
                                    </select>
                                    <!-- <input *ngIf="!viewData" type="text" class="dropdownstyles"
                                        id="vechiclename" name="Units" [(ngModel)]="Units" 
                                        placeholder="Units of Measurement" > -->
                                        <input *ngIf="viewData" type="text" class="dropdownstyles"
                                        [(ngModel)]="unitsmeasurement" readonly [class.readonly-input]="viewData">
                                        
                                </div>
                                <div class="col-md-3">
                                    <label for="exampleInputEmail1">Starting {{changeUnitMeasurement()}} <span
                                        class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{startingkm}}</p> -->
                                    <input *ngIf="!viewData" type="number" class="dropdownstyles" required min="0" pattern="^[0-9]+(\.[0-9]{1,6})?$"
                                        id="vechiclename" name="startingkm" [(ngModel)]="startingkm"
                                        placeholder="Starting {{changeUnitMeasurement()}}" (keyup)="calculateTotalKM()" >
                                        <input *ngIf="viewData" type="text" class="dropdownstyles"
                                        [(ngModel)]="startingkm" readonly [class.readonly-input]="viewData">
                                </div>
                                <div class="col-md-3">
                                    <label for="exampleInputEmail1">Closing {{changeUnitMeasurement()}}<span
                                        class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{closingkm}}</p> -->
                                    <input *ngIf="!viewData" type="number" class="dropdownstyles" required min="0" pattern="^[0-9]+(\.[0-9]{1,6})?$"
                                        id="vechiclename" name="closingkm" [(ngModel)]="closingkm" placeholder="Closing {{changeUnitMeasurement()}}"
                                        (keyup)="calculateTotalKM()">
                                    <div *ngIf="closingError" class="text-danger">
                                        Closing KMS/HRS should be greater than or equal
                                        to Starting KMS/HRS</div>                
                                        <input *ngIf="viewData" type="number" class="dropdownstyles" [readonly]="viewData"
                                         name="closingkm" [(ngModel)]="closingkm" [class.readonly-input]="viewData" >
                                </div>
                                <div class="col-md-3">
                                    <label for="exampleInputEmail1">Total {{changeUnitMeasurement()}}<span
                                        class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{totalkm}}</p> -->
                                    <input *ngIf="!viewData" type="number" class=" dropdownstyles"
                                        id="vechiclename" name="totalkm" [(ngModel)]="totalkm" placeholder="Total KM" readonly
                                        (keyup)="calculateTotalKM()" >
                                        <input *ngIf="viewData" type="text" class="dropdownstyles"
                                        [(ngModel)]="totalkm" readonly [class.readonly-input]="viewData">
                                </div>
                                
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <label for="exampleInputEmail1">HSD Issued<span class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{hsdissued}}</p> -->
                                    <input *ngIf="!viewData" type="number" class=" dropdownstyles" required min="0" pattern="^[0-9]+(\.[0-9]{1,6})?$"
                                         id="vechiclename" name="hsdissued" [(ngModel)]="hsdissued"
                                        placeholder="HSD Issued" >
                                        <input *ngIf="viewData" type="text" class="dropdownstyles"
                                        [(ngModel)]="hsdissued" readonly [class.readonly-input]="viewData">

                                </div>
                          
                                <div class="col-md-3">
                                    <label for="exampleInputEmail1">HSD Reading<span class="mandetory">*</span></label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{hsdreading}}</p> -->
                                    <input *ngIf="!viewData" type="number" class=" dropdownstyles" required min="0" pattern="^[0-9]+(\.[0-9]{1,6})?$"
                                        id="vechiclename" name="hsdreading" [(ngModel)]="hsdreading"
                                        placeholder="HSD Reading" >
                                        <input *ngIf="viewData" type="text" class="dropdownstyles"
                                        [(ngModel)]="hsdreading" readonly [class.readonly-input]="viewData">
    
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-6 hwwork">
                                    <label for="exampleInputEmail1">Description</label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{descriptionOfWork}}</p> -->
                                    <textarea *ngIf="!viewData" type="text"
                                        class=" dropdownstyles heightdec pb-5" id="vechiclename"
                                        [(ngModel)]="descriptionOfWork" placeholder="Description of Work" ></textarea>
                                        <input *ngIf="viewData" type="text" class="dropdownstyles"
                                        [(ngModel)]="descriptionOfWork" readonly [class.readonly-input]="viewData">
    
                                </div>
                                <div class="col-md-6 hwwork">
                                    <label for="exampleInputEmail1">Remarks</label>
                                    <!-- <p *ngIf="viewData" class="viewdata"> {{remarks}}</p> -->
    
                                    <textarea *ngIf="!viewData" type="text"
                                        class=" dropdownstyles heightdec pb-5" [(ngModel)]="remarks"
                                        id="vechiclename" placeholder="Remarks" ></textarea>
                                        <input *ngIf="viewData" type="text" class="dropdownstyles"
                                        [(ngModel)]="remarks" readonly [class.readonly-input]="viewData">
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label> List </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <div class="row ">
                            <div class="col-md-4 col-lg-4 mt-2 mb-3 d-flex">
                                <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                                <select class="dropdownstyles" name="searchcol" [(ngModel)]="searchcol" (change)="onChange()">
                                    <option value="" selected disabled>Select Column</option>
                                    <option value="number">Reference Number</option>
                                    <option value="vendor_name">Vendor Name</option>
                                    <option value="vehicle_number">Vehicle Reg No/Serial No</option>
                                </select>
                            </div>
                            <div class="col-md-3 mt-2 mb-3 d-flex ">
                                <input type="search" class="searchfield dropdownstyles"
                                    placeholder="Search" [(ngModel)]="searchref"
                                    (input)="getEquipmentdetailss1()">
                            </div>
                            <div class=" col-md-1  excelbtn" >
                                <button mat-raised-button class="btn btn-success mt-2" (click)="exportExcelList()"
                                style="border: none;background-color: #62beda;">Export to Excel</button>
                            </div>
                            <div class="col-md-4 col-lg-4 mt-2 text-right">
                                <button mat-icon-button value="Refresh"(click)="getequipdata()" matTooltip="Refresh"
                                class="custom-icon-button ml-3">
                                <span class="material-icons">
                                    refresh
                                </span>
                            </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-12">
                                <div class="table-responsive mat-table-wrapper">
                                    <div class="example-table-container">
                                        <table mat-table [dataSource]="dataSourcemain" matSort>
                                            <ng-container matColumnDef="sno">
                                                <th mat-header-cell *matHeaderCellDef class="table-width5">
                                                    S.NO
                                                </th>
                                                <td mat-cell *matCellDef="let row; let i = index">
                                                    {{(i + 1 )
                                                    + ( paginator?.pageIndex *
                                                    paginator?.pageSize)
                                                    }}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Date">
                                                <th mat-header-cell *matHeaderCellDef class="table-width6" mat-sort-header="date">Date
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{ row.date | date: 'dd-MM-yyyy' }}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="reference_number">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header="number" class="table-width6">Reference Number
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{ row.number }}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="company_Name">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header="company_name" class="table-width12">
                                                    company Name
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{ row.company_name}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="vendorname">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header="vendor_name" class="table-width12">
                                                    Vendor Name
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{ row.vendor_name }}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="vechiclenumber">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header="vehicle_number" class="table-width12">
                                                    Vehicle Reg No/Serial No
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{ row.vehicle_number}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="unitofmeasurement">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header="unit_of_measurment" class="table-width12">
                                                    unit of measurement
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.unit_of_measurment}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="totalkm">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header="total_kms" class="table-width12">
                                                    Total 
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.total_kms}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Action">
                                                <th mat-header-cell *matHeaderCellDef class="table-width12">
                                                    Action
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    <div class="mb-0">
                                                        <mat-icon 
                                                            class="eyeicon" matTooltip="View"
                                                            (click)="viewdataa(row)">remove_red_eye</mat-icon>
                                                        <mat-icon  class="editicon" matTooltip="Edit"
                                                                (click)="editgidata(row, editmodel)">edit</mat-icon>
                                                        <mat-icon  class="deleteicon" matTooltip="Delete"
                                                            (click)="deleteItem(row,deleteItemModel)">delete</mat-icon>
                                                        <mat-icon
                                                            class="fileupload" matTooltip="File Upload"
                                                            (click)="openfileuploadmodel(fileuploadmodel, row)">file_upload</mat-icon>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="listcolumns; sticky: true">
                                            </tr>
                                            <tr mat-row *matRowDef="let row; columns: listcolumns"></tr>
                                        </table>
                                    </div>
                                    <div *ngIf="reaDatalog == true || totalRecords==0" class="text-center">
                                        <h4 class="text-secondary m-3" *ngIf="dataSourcemain.length>0">
                                            Loading...
                                        </h4>
                                        <h4 class="text-secondary m-3" *ngIf="dataSourcemain.length === 0">
                                            No Records Found
                                        </h4>
                                    </div>
                                    <div *ngIf="totalRecords==0">
                                        <p class="mt-4 text-center"> No Records Found</p>
                                    </div>
                                     <ng-container *ngIf="totalRecords > 0">                                 
                                            <mat-paginator [length]="totalRecords" [pageSize]="10" [pageSizeOptions]="[5,10, 50, 100,500, 1000]"
                                              #paginator (page)="onpageevent($event)">
                                            </mat-paginator>
                                    </ng-container>
                                </div>
                            </div>
                           
                        </div>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        <!-- <mat-tab> -->
        <ng-template #editmodel>
                    <div mat-dialog-title>
                        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
                        <mat-icon class="icon1">close</mat-icon>
                        </button>
                    </div>
            <div mat-dialog-content>
                <div class="row">
                    <div class="col-md-2">
                        <label class="pb-2">Reasons<span class="mandetory">*</span></label>
                    </div>
                    <div class="col-md-6 ml-2">
                        <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                            [(ngModel)]="editModel" />
                    </div>
                </div>
            </div>
            <br />
            <div mat-dialog-actions class="editsubmitbtn">
                <!-- <button mat-button mat-dialog-close (click)="closemodel()" class="matButton">Close</button>&nbsp; -->
                <button mat-button cdkFocusInitial (click)="savesubmitdata()" [disabled]="!editModel" class="matButton">
                    Submit
                </button>
            </div>
        </ng-template>
        <ng-template #deleteItemModel>
            <div class="row">
                <div class="col-8">
                    <h1 mat-dialog-title>Delete Machinery</h1>
                </div>
                <div class="col-4">
                    <div mat-dialog-title>
                        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
                        >
                        <mat-icon class="icon1">close</mat-icon>
                    </button>
                    </div>
                </div>
            </div>
            <div mat-dialog-content class="delactivity">
                <p>Are you sure, you want to delete record?</p>
                <div class="row">
                    <div class="col-md-2">
                        <label>Reason<span class="mandetory">*</span></label>
                    </div>
                    <div class="col-md-8">
                        <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                            [(ngModel)]="deletemodel">
                    </div>
                </div>
            </div>
            <div mat-dialog-actions class="delbtn" >
                <button mat-button cdkFocusInitial (click)="deleterecord()" class="matButton " [disabled]="!deletemodel">Delete</button>
            </div>
        </ng-template>
        <ng-template #fileuploadmodel class="cardsize">
            <div mat-dialog-title class="dialog-title">
                <div class="row">
                    <div class="col-8">
                        <h2>Machinery/vechiclelog {{dcnumber1}}</h2>
                    </div>
                    <div class="col-4">
                        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon "
                            (click)="closemodel()" >
                            <mat-icon class="closeicon1">close</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div mat-dialog-content class="row ">
                <div *ngIf="filedata" class="tablewidth">
                    <table>
                        <thead class="table-h">
                            <tr>
                                <th>S.NO</th>
                                <th>Created By</th>
                                <th>Created Date</th>
                                <th>File Name</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of filedata let i=index">
                                <td class="txt-center">{{i+1}}</td>
                                <td>{{item.created_by}}</td>
                                <td>{{item.created_date}}</td>
                                <td class="fileSize">{{item.file_name}} &nbsp;&nbsp;&nbsp;<i class="fas fa-eye text-cyan  m-r-20 "
                                         title="View File" matTooltip="View" (click)="viewDoc(item)"></i></td>
                                <td>
                                    <button mat-icon-button class="deleteicon"
                                        aria-label="Example icon button with a vertical three dot icon" matTooltip="Delete"
                                        (click)="deleterowfile(deletefileModel, item)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-center" *ngIf="filedata.length===0">
                        No Data Found
                    </div>
                </div>
            </div><br>
            <button mat-raised-button type="button" (click)="autoFileUpload1.click()">Attach File  <mat-icon class="file2">file_upload</mat-icon></button>
            <div class="file">
                <input hidden class="form-control-file" name="file" type="file"
                    (change)="uploadWbsFile($event);showContent(i);" #autoFileUpload1 multiple />
            </div>
        </ng-template>
        <ng-template #deletefileModel>
            <h1 mat-dialog-title>Delete File</h1>
            <div mat-dialog-content>Are you sure, you want to delete file?</div>
            <div mat-dialog-actions>
                <button mat-button mat-dialog-close class="matButton" class="deletefilebtn">Close</button>&nbsp;
                <button mat-button cdkFocusInitial (click)="deleteexistingfile()" class="matButton">Delete</button>
            </div>
        </ng-template>
        <mat-tab>
            <ng-template mat-tab-label> Activity Log </ng-template>
            <ng-template matTabContent>
                <app-activitylog [activitylogfor]="'Machinery'"></app-activitylog>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<div class="table-responsive mat-table-wrapper">
    <div #ListTABLE class="example-table-container">
      <table class="table d-none" >
        <tr class="center headerstyles">
          <th scope="col" class="border">S.No</th>
          <th scope="col" class="border">Date</th>
          <th scope="col" class="border">Reference Number</th>
          <th scope="col" class="border">Company Name</th>
          <th scope="col" class="border">Vendor Name</th>
          <th scope="col" class="border">Vehicle Reg No/Serial No</th>
          <th scope="col" class="border">Unit of Measurement</th>
          <th scope="col" class="border">Total</th>
        </tr>
        <tr *ngFor="let user of exportExcelData; let i=index;">
          <td class="border">{{ i+1 }}</td>
          <td class="border">{{ user.date }}</td>
          <td class="border">{{ user.number }}</td>
          <td class="border">{{ user.company_name }}</td>
          <td class="border">{{ user.vendor_name }}</td>
          <td class="border">{{ user.vehicle_number }}</td>
          <td class="border">{{ user.unit_of_measurment }}</td>
          <td class="border">{{ user.total_kms }}</td>
        </tr>
      </table>
    </div>
  </div>

<ng-template #newFileUploadModal>
    <div mat-dialog-title class="dialog-title">
        <div class="row">
            <div class="col-8">
                <h2>File Upload</h2>
            </div>
            <div class="col-4">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon mt-0 " (click)="closemodel()"
                >
                <mat-icon class="closeicon1">close</mat-icon>
              </button>
            </div>
        </div>
      </div>
      <div *ngIf="!issubmitClicked">
        <div mat-dialog-content>
          <div *ngIf="selectedfiles.length > 0">
            <table>
              <thead class="table-h text-center">
                <th>S.NO</th>
                <th>File Name</th>
                <th>Action</th>
              </thead>
              <tbody class="text-center">
                <tr *ngFor="let item of selectedfiles;let i=index;">
                  <td>{{i+1}}</td>
                  <td class="fileSize">{{item.name}}</td>
                  <td>
                    <button mat-icon-button class="deleteicon" (click)="deleteFileItem(i)">
                      <mat-icon matTooltip="Delete">delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
    
          </div>
        </div><br>
        <button type="button" mat-raised-button class="attachfile ml-1" color="secondary" (click)="autoFileUpload2.click()">
          Attach File&nbsp; <mat-icon class="file2">file_upload</mat-icon></button>
        <div class="file">
          <input hidden class="form-control-file" name="file" type="file" multiple (change)="uploadgifiles($event)"
            #autoFileUpload2>
        </div>
      </div>
    
      <!-- editfile -->
      <div *ngIf="issubmitClicked">
        <div *ngIf="filedata && filedata.length>0">
          <table>
            <thead class="table-h">
              <th>Created By</th>
              <th>Created Date</th>
              <th>File Name</th>
              <th>Action</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of filedata">
                <td>{{ item.created_by }}</td>
                <td>{{ item.created_date }}</td>
                <td class="fileSize">
                  {{ item.file_name }} &nbsp;&nbsp;&nbsp;<i class="fas fa-eye text-cyan m-r-20 tablebody"
                    title="View File" matTooltip="View" (click)="viewDoc(item)"></i>
                </td>
                <td>
                  <button mat-icon-button class="deleteicon"
                    aria-label="Example icon button with a vertical three dot icon"
                    (click)="deleterowfile(deletefileModel, item)">
                    <mat-icon matTooltip="Delete">delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    
        <br />
        <button type="button" mat-raised-button class="attachfile ml-1" color="secondary" (click)="autoFileUpload1.click()">
          Attach File&nbsp; <mat-icon class="file2">file_upload</mat-icon></button>
        <div class="file">
          <input hidden class="form-control-file" name="file" type="file" multiple
            (change)="uploadWbsFile($event); showContent(i)" #autoFileUpload1 />
          <ng-container *ngIf="fileUploadUrls.length > 0">
          </ng-container>
        </div>
      </div>  
  </ng-template>