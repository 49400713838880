import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialreceiptComponent } from './materialreceipt/materialreceipt.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { UserRolesAuthGuard } from '../auth/user-roles-auth.guard';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedMaterialModule } from '../shared/material/shared-material-module';
import { QualitycheckComponent } from './qualitycheck/qualitycheck.component';
import { GoodsreceiptComponent } from './goodsreceipt/goodsreceipt.component';
import { GoodsissueComponent } from './goodsissue/goodsissue.component';
import { GoodsreturnComponent } from './goodsreturn/goodsreturn.component';
import { GoodstransferComponent } from './goodstransfer/goodstransfer.component';
import { PrintdmrComponent } from './printdmr/printdmr.component';
import { NgxPrintElementModule } from "ngx-print-element";
import { PrintgoodsissueComponent } from './printgoodsissue/printgoodsissue.component';
import { PrintgoodsreturnComponent } from './printgoodsreturn/printgoodsreturn.component';
import { PrintoutgatepassComponent } from './printoutgatepass/printoutgatepass.component';
import { PrintoutgatepassrgpComponent } from './printoutgatepassrgp/printoutgatepassrgp.component';
import { SubContractGoodsIssueComponent } from './sub-contract-goods-issue/sub-contract-goods-issue.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TestComponent } from './test/test.component';
import { DeliverychallanComponent } from './deliverychallan/deliverychallan.component';
import { Outgatepassrgp1Component } from './outgatepassrgp1/outgatepassrgp1.component';
import { Printoutgatepassrgp1Component } from './printoutgatepassrgp1/printoutgatepassrgp1.component';
import { Printoutgatepassrgpnrgp1Component } from './printoutgatepassrgpnrgp1/printoutgatepassrgpnrgp1.component';
import { NrgpComponent } from './nrgp/nrgp.component';
import { PrintDcComponent } from './print-dc/print-dc.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { MatExpansionModule } from '@angular/material/expansion';
import { EsdnComponent } from './esdn/esdn.component';
import { PrintesdnComponent } from './printesdn/printesdn.component';
import { StoragelocationComponent } from './storagelocation/storagelocation.component';
import { StoresdashboardComponent } from './storesdashboard/storesdashboard.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatSortModule } from '@angular/material/sort';
import { Printgoodsreceipt2Component } from './printgoodsreceipt2/printgoodsreceipt2.component';
import { AuditComponent } from './audit/audit.component';
import { StartauditComponent } from './startaudit/startaudit.component';
import { Printoutgatepassrgp2Component } from './printoutgatepassrgp2/printoutgatepassrgp2.component';
import { Printoutgatepassnrgp2Component } from './printoutgatepassnrgp2/printoutgatepassnrgp2.component';
import { DmrbulkupdateComponent } from './dmrbulkupdate/dmrbulkupdate.component';
import { MatInputModule } from '@angular/material/input';
import { GamdashboardComponent } from './gamdashboard/gamdashboard.component';
import { QrprintDmrComponent } from './qrprint-dmr/qrprint-dmr.component';
import { ReusableModule } from 'src/app/reusable/reusable.module';

const routes: Routes = [
  {
    path: 'dmr',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'Daily Material Receipt',
      permission: {
        only: ['SUPER_ADMIN', 'DAILY_MATERIAL_RECEIPT'],
        redirectTo: '/ui/403',
      },
    },
    component: MaterialreceiptComponent,
  },
  {
    path: 'qualitycheck',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'Quality Check',
      permission: {
        only: ['SUPER_ADMIN', 'QUALITY_CHECK'],
        redirectTo: '/ui/403',
      },
    },
    component: QualitycheckComponent
  },
  {
    path: 'goodsreceipt',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'Goods Receipt',
      permission: {
        only: ['SUPER_ADMIN', 'GOODS_RECEIPT'],
        redirectTo: '/ui/403',
      },
    },
    component: GoodsreceiptComponent
  },
  {
    path: 'goodsissue',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'Goods Issue',
      permission: {
        only: ['SUPER_ADMIN', 'GOODS_ISSUE'],
        redirectTo: '/ui/403',
      },
    },
    component: GoodsissueComponent
  },
  {
    path: 'goodsreturn',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'Goods Return',
      permission: {
        only: ['SUPER_ADMIN', 'GOODS_RETURN'],
        redirectTo: '/ui/403',
      },
    },
    component: GoodsreturnComponent
  },
  {
    path: 'goodstransfer',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'Out Gate Pass',
      permission: {
        only: ['SUPER_ADMIN', 'OUT_GATE_PASS'],
        redirectTo: '/ui/403',
      },
    },
    component: GoodstransferComponent
  },
  { path: 'printdmr', component: PrintdmrComponent },
  { path: 'printgoodsreceipt2', component: Printgoodsreceipt2Component },
  { path: 'printgoodsissue', component: PrintgoodsissueComponent },
  { path: 'printgoodsreturn', component: PrintgoodsreturnComponent },
  { path: 'printoutgatepass', component: PrintoutgatepassComponent },
  { path: 'printrgppass', component: PrintoutgatepassrgpComponent },
  {
    path: 'deliverychallan',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'Delivery Challan',
      permission: {
        only: ['SUPER_ADMIN', 'DELIVERY_CHALLAN'],
        redirectTo: '/ui/403',
      },
    },
    component: DeliverychallanComponent
  },
  { path: 'print-dc', component: PrintDcComponent },

  {
    path: 'subContractGoodsIssue',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'Sub-Contract Goods Issue',
      permission: {
        only: ['SUPER_ADMIN', 'OUT_GATE_PASS'],
        redirectTo: '/ui/403',
      },
    },
    component: SubContractGoodsIssueComponent
  },
  { path: 'test', component: TestComponent },
  {
    path: 'outgatepassrgp1',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'OutGatePass RGP',
      permission: {
        only: ['SUPER_ADMIN', 'OUT_GATE_PASS_RGP'],
        redirectTo: '/ui/403',
      },
    },
    component: Outgatepassrgp1Component,

  },
  { path: 'printoutgatepassrgp1', component: Printoutgatepassrgp1Component },
  { path: 'printoutgatepassrgpnrgp1', component: Printoutgatepassrgpnrgp1Component },
  { path: 'printoutgatepassrgp2', component: Printoutgatepassrgp2Component },
  { path: 'printoutgatepassrgpnrgp2', component: Printoutgatepassnrgp2Component },
  { path: 'printesdn', component: PrintesdnComponent },
  {
    path: 'nrgp',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'OutGatePass NRGP',
      permission: {
        only: ['SUPER_ADMIN', 'OUT_GATE_PASS_NRGP'],
        redirectTo: '/ui/403',
      },
    },
    component: NrgpComponent
  },
  {
    path: 'esdn',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'ESDN',
      permission: {
        only: ['SUPER_ADMIN','ESDN'],
        redirectTo: '/ui/403',
      },
    },
    component: EsdnComponent
  },
  {
    path: 'storagelocation',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'Storage Location',
      permission: {
        only: ['SUPER_ADMIN', 'STORAGE_LOCATION'],
        redirectTo: '/ui/403',
      },
    },
    component: StoragelocationComponent
  },
  {
    path: 'storesdashboard',
    // AuthGuard,
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'Stores Dashboard',
      permission: {
        only: ['SUPER_ADMIN', 'STORES_DASHBOARD'],
        redirectTo: '/ui/403',
      },
    },
    component: StoresdashboardComponent
  },
  {
    path: 'gamdashboard',
    // AuthGuard,
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'Gams Dashboard',
      permission: {
        only: ['SUPER_ADMIN', 'GAM_DASHBOARD'],
        redirectTo: '/ui/403',
      },
    },
    component: GamdashboardComponent
  },
  {
    path: 'audit',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'Audit',
      permission: {
        only: ['SUPER_ADMIN', 'AUDIT'],
        redirectTo: '/ui/403',
      },
    },
    component: AuditComponent
  },
  {
    path:'qrprint-dmr',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'qrprint-dmr',
      permission: {
        only: ['SUPER_ADMIN', 'QR_PRINT_DMR'],
        redirectTo: '/ui/403',
      },
    },
    component:QrprintDmrComponent
  },

  {
    path:'dmrbulkupdate',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'DMRBulkUpdate',
      permission: {
        only: ['SUPER_ADMIN', 'DMR_BULK_UPDATE'],
        redirectTo: '/ui/403',
      },
    },
    component:DmrbulkupdateComponent
  },
  {
    path:'dmrbulkupdate',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'DMRBulkUpdate',
      permission: {
        only: ['SUPER_ADMIN','AUDIT'],
        redirectTo: '/ui/403',
      },
    },
    component:DmrbulkupdateComponent
  },
  {
    path:'startaudit',
    canActivate: [UserRolesAuthGuard],
    data: {
      title: 'Audit',
      permission: {
        only: ['SUPER_ADMIN', 'AUDIT'],
        redirectTo: '/ui/403',
      },
    },
    component: StartauditComponent
  },
]

@NgModule({
  declarations: [
    MaterialreceiptComponent,
    QualitycheckComponent,
    GoodsreceiptComponent,
    GoodsissueComponent,
    GoodsreturnComponent,
    GoodstransferComponent,
    PrintdmrComponent,
    PrintgoodsissueComponent,
    PrintgoodsreturnComponent,
    PrintoutgatepassComponent,
    PrintoutgatepassrgpComponent,
    SubContractGoodsIssueComponent,
    TestComponent,
    DeliverychallanComponent,
    Outgatepassrgp1Component,
    Printoutgatepassrgp1Component,
    Printoutgatepassrgpnrgp1Component,
    NrgpComponent,
    PrintDcComponent,
    EsdnComponent,
    DmrbulkupdateComponent,
    PrintesdnComponent,
    StoragelocationComponent,
    StoresdashboardComponent,
    Printgoodsreceipt2Component,
    AuditComponent,
    StartauditComponent,
    
    Printoutgatepassrgp2Component,
    Printoutgatepassnrgp2Component,
    GamdashboardComponent,
    QrprintDmrComponent,
    
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatDialogModule,
    SharedMaterialModule,
    NgxPrintElementModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    NgxQRCodeModule,
    ZXingScannerModule,
    MatExpansionModule,
    NgxChartsModule,
    MatInputModule,
    NgxDaterangepickerMd.forRoot(),
    MatSortModule,
    ReusableModule
  ],

})
export class InventoryModule { }
