<mat-card>
    <div class=" print" style=" margin-left: 19px">
        <button *ngIf="printstatus===true" [print]="['demo', config]" class="btn btn-info">
            Print
        </button>&nbsp;&nbsp;
        <button (click)="backtooutgatepass()" class="btn btn-info mr-2">
            Back
        </button>
        <button  *ngIf="!document_no1&&!document_no&&doc_sign !== 'SENT'" (click)="getApprovals(approvalsmodel)" [ngClass]="doc_sign === 'SENT' ? 'disabled' : 'enabled'" class="btn btn-info" [disabled]="approvalbtn === true">
            Proceed for Approvals
        </button>
    </div>
    <!-- <div id="demo">
        <div class="row justify-content-center section1"> -->
    <!-- <table class="table" style="width: 100%">
                <thead class="header">
                    <td rowspan="1" class="text-left" style="width: 200px">
                        <img src="https://testgeps.greenko.net/ui/assets/greenko/newlogo_greeenko_eps.svg"
                            height="100px" width="170px" />
                    </td>
                    <td colspan="11">
                        <p class="text-right">
                            <b>GREENKO INTEGRATED MANAGEMENT SYSTEM</b>
                        </p>
                        <p class="text-right">RETURNABLE GATE PASS</p>
                    </td>
                  
                </thead>
                <td class="text-left" id="spacer">&nbsp;</td>
                <tbody class="body">
                    <tr style="display: contents; border: none">
                        <th colspan="6"></th>
                    </tr>
                    <tr>
                        <th class="border">S.No</th>
                        <td class="border" colspan="2">
                            <span *ngFor="let user of userData">{{ user.number }}</span>
                        </td>
                        <th class="border">Date</th>
                        <td class="border" colspan="2">
                            <span *ngFor="let user of userData">{{
                                user.date | date: "dd-MM-yyyy"
                                }}</span>
                        </td>
                    </tr>

                    <tr>
                        <th class="border" colspan="1">From</th>
                        <th class="border" colspan="5">Stores</th>
                    </tr>

                    <tr>
                        <th class="border" colspan="1">To</th>
                        <th class="border" colspan="5">Security</th>
                    </tr>

                 
                    <tr>
                        <td colspan="6" style="border: none !important;">
                            <p>
                                Please allow M/S
                                <span *ngFor="let user of userData"><b>{{ user.receiver_name }}</b></span>
                                in person /by vehicle No
                                <span *ngFor="let user of userData"><b>{{ user.vehicle_number }}</b></span>
                                to carry the following materials
                            </p>
                        </td>
                    </tr>
                    <tr class="text-center">
                        <th class="border">S. No</th>
                        <th class="border">Material Code</th>
                        <th class="border">Material Description</th>
                        <th class="border">UOM</th>
                        <th class="border">Quantity</th>
                        <th class="border">Remarks</th>
                    </tr>
                    <tr scope="row" class="text-center">
                        <td class="border">
                            <span *ngFor="let user of userData">{{ user.line_item }}</span>
                        </td>
                        <td class="border">
                            <span *ngFor="let user of userData">{{
                                user.material_code
                                }}</span>
                        </td>
                        <td class="border">
                            <span *ngFor="let user of userData">{{
                                user.material_description
                                }}</span>
                        </td>
                        <td class="border">
                            <span *ngFor="let user of userData">{{
                                user.unit_of_measurment
                                }}</span>
                        </td>
                        <td class="border">
                            <span *ngFor="let user of userData">{{ user.quantity }}</span>
                        </td>
                        <td class="right border">
                            <span *ngFor="let user of userData">{{ user.item_remarks }}</span>
                        </td>
                    </tr>

                   
                    <tr>
                        <th colspan="6"  style="border: none !important;">Purpose:</th>
                    </tr>
                    <tr>
                        <td style="border: none !important;">
                            <p *ngFor="let user of userData">{{ user.purpose }}</p>
                        </td>
                    </tr>
                  
                    <tr>
                        <th colspan="6" style="border: none !important;">Expected date of return:</th>
                    </tr>
                    <tr>
                        <td style="border: none !important;">
                            <p *ngFor="let user of userData">
                                {{ user.expected_return_date | date: "dd-MM-yyyy" }}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="6" style="border: none !important;">Prepared by:</th>
                    </tr>
                    <tr>
                        <td style="border: none !important;">
                            <p *ngFor="let user of userData">{{ user.created_by }}</p>
                        </td>
                    </tr>
                  
                    <tr>
                        <th colspan="3" style="border: none !important;">Stores In-charge</th>
                        <th class="text-right" colspan="3" style="border: none !important;">Authorized by</th>
                    </tr>
                    <tr>
                        <th style="border: none !important;">Received by</th>
                    </tr>
                </tbody>
                <tfoot style="color: rgb(209, 208, 208);width: 100%;text-align: center;
                font-weight: 900;">
                    <tr>
                        <td class="text-left" colspan="2">F-CO-STR-10</td>
                        <td class="text-center" >I 4 / R0</td>
                        <td class="text-center" colspan="2">Date: 20.01.2017</td>
                        <td class="text-right" colspan="2">CONFIDENTIAL</td>
                    </tr>
                </tfoot>
            </table> -->
    <!-- <div id="footer">
                <table style="
              width: 100%;
              /* margin-left: -88%; */
              color: rgb(209, 208, 208);
              font-weight: 900;
            ">
                    <tr>
                        <td class="text-left">F-CO-STR-10</td>
                        <td class="text-center">I 4 / R0</td>
                        <td class="text-center">Date: 20.01.2017</td>
                        <td class="text-right">CONFIDENTIAL</td>
                    </tr>
                </table>
            </div> -->
    <!-- </div>
    </div> -->
    <div class=" container-fluid  formbody" id="demo">
        <div class="row mt-3">
            <div class="col-3">
                <img *ngIf="companylogo==='greenko'" src="https://testgeps.greenko.net/ui/assets/greenko/newlogo_greeenko_eps.svg"
                    style="width: 150px" />
            </div>
            <!-- <div class="col-9 text-right">
                
                <h4 style="font-size: 20px;font-weight: 400;"><b>GREENKO INTEGRATED MANAGEMENT SYSTEMS</b></h4>
            </div> -->
            <div class="col-6 text-center">
                <!-- <h4 class="text-center mb-0" style="color: #888;">
                    RETURNABLE GATEPASS
                </h4> -->
                <!-- <h2 class="mb-0"><b>GREENKO INTEGRATED MANAGEMENT SYSTEMS</b></h2> -->
                <h2 class="mb-0 " *ngFor="let user of userData"><b>{{ user.company_name }}</b></h2>
                <h2 class="mb-0 companyAddr" *ngFor="let user of userData"><i>{{user.company_address}}</i></h2>
                <h2 class="text-center mb-0">
                    RETURNABLE GATEPASS
                </h2>
            </div>
            <div class="col-3 text-right">
                <ngx-qrcode *ngIf="data1" [elementType]="elementType" [width]="170" [errorCorrectionLevel]="correctionLevel"
                    [value]="value2" alt="Demo QR Code" cssClass="bshadow">
                </ngx-qrcode>
                <ngx-qrcode *ngIf="document_no" [elementType]="elementType" [width]="170" [errorCorrectionLevel]="correctionLevel"
                [value]="value" alt="Demo QR Code" cssClass="bshadow">
              </ngx-qrcode>
              <ngx-qrcode *ngIf="document_no1" [elementType]="elementType" [width]="170" [errorCorrectionLevel]="correctionLevel"
              [value]="value1" alt="Demo QR Code" cssClass="bshadow">
            </ngx-qrcode>
            </div>
        </div>
        <!-- <div class="row text-center head">
           
        </div> -->
        <div class="row text-center">
            <div class="col-12">
                <!-- <h2 *ngFor="let user of userData">{{ user.company_name }}</h2> -->
                <!-- <h4>
                    <i *ngFor="let user of userData">
                        &nbsp;&nbsp;{{ user.receiver_name }}
                    </i>
                </h4> -->
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <p class="ptext">
                    <i>S.NO/Date : </i><b><span *ngFor="let user of userData">{{ user.number }} : {{ user.date | date: "dd-MM-yyyy"}}</span>
                    </b> </p>
            </div>
            <!-- <div class="col-4 text-center">
                <p class="ptext">
                    <i>Date : </i>
                <b> <span *ngFor="let user of userData">
                        {{
                        user.date | date: "dd-MM-yyyy"
                        }}</span></b>
                </p>
             
            </div> -->
            <div class="col-4 text-right">
                <p class="ptext">
                    <i>RGP Type : </i><b><span *ngFor="let user of userData">{{ user.Transfer_type }}</span>
                    </b> </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="ptext">
                    <i>From : </i><span>
                        <!-- *ngFor="let user of userData" -->
                        <!--{{  user.to_company_name}}-->
                        STORES</span>
                </p>
                <p class="ptext">
                    <i>To : </i> <span>Security</span>
                </p>

                <p class="ptext">
                    <i>Consignee: </i><span *ngFor="let user of userData">{{ user.consignee_name }}</span>
                </p>
                <p class="ptext">
                    <i>Consignee Address: </i><span *ngFor="let user of userData">{{ user.consignee_address }}</span>
                </p>
                <!-- <p class="ptext">
                    <i>M/s. </i><span *ngFor="let user of userData">{{ user.receiver_name }}</span>
                </p> -->
            </div>
        </div>
        

        <div class="row">
            <div class="col-12">
                <p class="ptext">
                    Please allow M/S &ensp;<span style="text-decoration: underline;font-weight: 500;"
                        *ngFor="let user of userData">{{ user.receiver_name }}</span> &ensp;
                    in person/ by vehicle No: <span style="text-decoration: underline;font-weight: 500;"
                        *ngFor="let user of userData">{{user.vehicle_number}}</span> to Carry the following materials.
                </p>
                <!-- <hr class="pline"> -->
            </div>
        </div>
        <div class="row">
            <div class="col-12 table-responsive">
                <table class="border">
                    <tr class="border text-center">
                        <th class="border">S.NO</th>
                        <th class="border">Item Code</th>
                        <th class="border">Description of Material</th>
                        <th class="border">UOM</th>
                        <th class="border">Quantity</th>
                        <th class="border">Remarks</th>
                    </tr>
                    <tr scope="row"  class="border text-center" *ngFor="let user of table"  style="padding-bottom: 5%">
                        <td class="border">
                            <span>{{ user.line_item }}</span>
                        </td>
                        <td class="border">
                            <span>{{
                                user.material_code
                                }}</span>
                        </td>
                        <td class="border">
                            <span>{{
                                user.material_description
                                }}</span>
                        </td>
                        <td class="border">
                            <span>{{
                                user.unit_of_measurment
                                }}</span>
                        </td>
                        <td class="border">
                            <span>{{ user.quantity }}</span>
                        </td>
                        <td class="border">{{user.item_remarks}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <p class="ptext">
                    Purpose:
                    <span *ngFor="let user of userData">{{ user.purpose }}</span>
                </p>
            </div>
            <div class="col-12">
                <p class="ptext">Expected date of return : <span
                        *ngFor="let user of userData">{{user.expected_return_date  | date: "dd-MM-yyyy"}}</span></p>
            </div>
            <div class="col-12">
                <p class="ptext">
                    Prepared By :
                    <span *ngFor="let user of userData">{{ user.created_by }}</span>
                </p>
            </div>
        </div>

        <!-- <div class="row mt-5 " style=" margin-bottom: 10%;">
            <div class="col-4">
                <p class="ptext">Stores Incharge</p>
            </div>
            <div class="col-4 text-center">
                <p class="ptext">Authorised By</p>
            </div>
            <div class="col-4 text-right">
                <p class="ptext">Received By</p>
            </div>
        </div> -->
        <!-- <div class="col-12 mt-3 text-center">
            <p class="ptext">Supplier / Security / Stores</p>
        </div> -->
        <div class="row padding1  align-items-center  justify-content-between mt-5" *ngIf="signStatus "
        style="margin-bottom: 10%;">
        <div class="col"   *ngFor="let item of SignData">
            <!-- <span  *ngIf="item.description!== 'Recommanded by' &&  item.description!=='Approved by' "> -->
          <img [src]="item.signature" class="sign-width" />
          <p class="ml-1 ">{{item.updated_by}}</p>
          <p class="ml-1 ">{{item.description}}</p>
        <!-- </span> -->
        </div>
        <div class="col">
            <p class="ml-1">&nbsp;</p>
            <!-- <p class="ml-1 "><b>{{item.updated_by}}</b></p> -->
            <p class="ml-1 ">Receieved by</p>
          </div>
      </div>
      <div class="row padding1  align-items-center  justify-content-between mt-5" *ngIf="!signStatus "
        style=" margin-bottom: 10%;"  >
      <div class="col"  *ngFor="let item of SignData" >
        <!-- <span  *ngIf="item.description!== 'Recommanded by' && item.description!== 'Approved by' "> -->
        <img *ngIf="item.signature!==null" [src]="item.signature" class="sign-width" />
        <p class="ml-1" *ngIf="item.signbtn===false&&item.signature===null">Need to be Signature</p>
        <span>
            <button type="button" class="btn btn-custom mr-3" *ngIf="item.signbtn===true&&item.signature===null"
              (click)="getSignatureView(item,viewSignmodel)">Sign</button>
            <button type="button" class="btn btn-custom" *ngIf="item.signbtn===true&&item.signature===null"
            (click)="deleteItem(row,deleteitemofList)">Cancel</button>
        </span>
        <p class="ml-1 ">{{item.updated_by}}</p>
        <p class="ml-1 " >{{item.description}}</p>
        <!-- </span> -->
      </div>
      <div class="col">
        <p class="ml-1">&nbsp;</p>
        <!-- <p class="ml-1 "><b>{{item.updated_by}}</b></p> -->
        <p class="ml-1 ">Receieved by</p>
      </div>
      <!-- <div class="col"   *ngFor="let item of SignData">
            <img [src]="item.signature" class="sign-width" />
            <p  class="ml-1 "><b>{{item.updated_by}}</b></p>
            <p class="ml-1 "><b>{{item.description}}</b></p>
        </div> -->
      <!-- <div class="col-4 text-center">
          <p><b>Inspected by</b></p>
        </div> -->
      <!-- <div class="col-4 text-right">
          <p class="mr-4"><b>Project In-charge/ Authorized Signatory</b></p> -->
      <!-- <p><b>Authorized Signatory</b></p> -->
      <!-- </div> -->
    </div>
        <div class="row mt-2 footerTable" style="color:#888">
            <div class="col-4 text-left">
                F-CO-SIM-01-10
            </div>
            <div class="col-4 text-center">
                I3.RO/30.11.2022<br>
                PROTECTED
            </div>
            <div class="col-4 text-right">
               Page 1 of 1
            </div>
            <!-- <div class="col-3">
                Revision No:00
            </div>
            <div class="col-3">
                <span> Date:20.01.2017</span>
            </div> -->
            <!-- *ngFor="let user of userData">{{
            user.date | date: "dd-MM-yyyy"
            }}</span> -->

        </div>
    </div>
</mat-card>
<!-- model -->
<ng-template #viewSignmodel>
    <h1 mat-dialog-title>View Signature</h1>
    <div mat-dialog-content>
      <div class="row">
        <div class="col-12 text-center">
          <img [src]="Viewsign" class="sign-width" />
        </div>
      </div>
    </div><br>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close class="matButton">Close</button>&nbsp;
      <button mat-button cdkFocusInitial (click)="SignatureFile()" class="matButton"
        [disabled]="!Viewsign">Confirm</button>
    </div>
  </ng-template>

  <!-- Cancel -->
  <ng-template #deleteitemofList>
    <h1 mat-dialog-title>Delete Activity</h1>
    <div mat-dialog-content>
        <p>Are you sure, you want to delete record?</p>
        <div class="row">
            <div class="col-md-12">
                <label style="padding-top: 5px">Reason</label>
                <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                    [(ngModel)]="deletemodel.reason" style="width: 100%" />
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close class="matButton">
            Close</button>&nbsp;
        <button  mat-button cdkFocusInitial (click)="deleteFile()" class="matButton" [disabled]="!deletemodel.reason">
            Delete
        </button>
    </div>
</ng-template>
<ng-template #deletedocsigned>
    <span class="d-flex justify-content-between">
        <h1 mat-dialog-title>Document Signed Already</h1>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </span>
    <div mat-dialog-content>
        <p>Still want to delete ? </p>
    </div>
    <div mat-dialog-actions>
        <button mat-button cdkFocusInitial class="matButton" (click)="deleteSeleted(deletedocsigneddata)" >
            Yes
        </button>&nbsp;
        <button mat-button mat-dialog-close class="matButton" >
            No</button>
    </div>
</ng-template>

<ng-template #deletedocsigneddata>
    <span class="d-flex justify-content-between">
        <h1 mat-dialog-title>&nbsp;</h1>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </span>
    <div mat-dialog-content>
       <p><b>Document Number :</b> {{deleteNumber}}</p>
       <div class="row">
        <div class="col-md-12">
            <label style="padding-top: 5px">Reason</label>
            <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                [(ngModel)]="deletemodel.reason" style="width: 100%" />
        </div>
        <div class="col-md-12">
            <label style="padding-top: 5px">Password</label>
            <input type="text" placeholder="Enter Password" class="inputstyles" name="pwd"
                [(ngModel)]="deletemodel.pwd" style="width: 100%" />
        </div>
    </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button cdkFocusInitial class="matButton" (click)="deleteFile()"  [disabled]="!deletemodel.reason">
            Confirm
        </button>
        <!-- <button mat-button mat-dialog-close class="matButton" style="background: transparent">
            No</button> -->
    </div>
</ng-template>

<!-- Approvals -->
<ng-template #approvalsmodel> 
    <span class="d-flex justify-content-between">
        <h1 mat-dialog-title>Document Number:{{data1}}</h1>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </span>
    <div mat-dialog-content>
        <p>Do you confirm to proceed for Signatures</p>
    </div>
    <div mat-dialog-actions>
        <button mat-button cdkFocusInitial class="matButton" (click)="getConfirmData()" >
            Confirm
        </button>&nbsp;
        <button mat-button mat-dialog-close class="matButton" >
            Cancel</button>
    </div>
  </ng-template>