<mat-card>
    <div class=" print" style=" margin-left: 19px">
        <button [print]="['demo', config]" class="btn btn-info">
          Print
        </button>&nbsp;&nbsp;
        <button (click)="backtooutgatepass()" class="btn btn-info">
            Back
          </button>
      </div>
    <div id="demo">
      <div class="row justify-content-center section1">
        <table class="table" style="width: 96%">
          <thead class="header">
            <td rowspan="1" class="text-left" style="width: 200px">
              <img *ngIf="companylogo==='greenko'"
                src="https://testgeps.greenko.net/ui/assets/greenko/newlogo_greeenko_eps.svg"
                height="100px"
                width="170px"
              />
            </td>
            <td colspan="11">
              <p *ngIf="companylogo==='greenko'" class="text-right">
                <b>GREENKO INTEGRATED MANAGEMENT SYSTEM</b>
              </p>
              <p class="text-right">RETURNABLE GATE PASS</p>
            </td>
            <!-- <td class="text-center">Date: 20.01.2017</td>
                <td class="text-right">CONFIDENTIAL</td> -->
          </thead>
          <br />
          <br />
  
          <td class="text-left" id="spacer">&nbsp;</td>
          <tbody class="body">
            <tr style="display: contents; border: none">
              <th colspan="6"></th>
            </tr>
            <tr>
              <th class="border">S.No</th>
              <td class="border" colspan="2">
                <span *ngFor="let user of userData">{{ user.number }}</span>
              </td>
              <th class="border">Date</th>
              <td class="border" colspan="2">
                <span *ngFor="let user of userData">{{
                  user.date | date: "dd-MM-yyyy"
                }}</span>
              </td>
            </tr>
  
            <tr>
              <th class="border" colspan="1">From</th>
              <th class="border" colspan="5">Stores</th>
            </tr>
  
            <tr>
              <th class="border" colspan="1">To</th>
              <th class="border" colspan="5">Security</th>
            </tr>
  
            <td class="text-left" id="spacer">&nbsp;</td>
            <tr>
              <td colspan="4">
                <p>
                  Please allow M/S
                  <span *ngFor="let user of userData"
                    ><b>{{ user.receiver_name }}</b></span
                  >
                  in person /by vehicle No
                  <span *ngFor="let user of userData"
                    ><b>{{ user.vehicle_number }}</b></span
                  >
                  to carry the following materials
                </p>
              </td>
            </tr>
            <br />
            <br />
  
            <tr class="text-center">
              <th class="border">S. No</th>
              <th class="border">Material Code</th>
              <th class="border">Material Description</th>
              <th class="border">UOM</th>
              <th class="border">Quantity</th>
              <th class="border">Remarks</th>
            </tr>
            <tr scope="row" class="text-center">
              <td class="border">
                <span *ngFor="let user of userData">{{ user.line_item }}</span>
              </td>
              <td class="border">
                <span *ngFor="let user of userData">{{
                  user.material_code
                }}</span>
              </td>
              <td class="border">
                <span *ngFor="let user of userData">{{
                  user.material_description
                }}</span>
              </td>
              <td class="border">
                <span *ngFor="let user of userData">{{
                  user.unit_of_measurment
                }}</span>
              </td>
              <td class="border">
                <span *ngFor="let user of userData">{{ user.quantity }}</span>
              </td>
              <td class="right border">
                <span *ngFor="let user of userData">{{ user.item_remarks }}</span>
              </td>
            </tr>
  
            <br />
            <br />
            <tr>
              <th colspan="4">Purpose:</th>
            </tr>
            <tr>
              <td>
                <p *ngFor="let user of userData">{{ user.purpose }}</p>
              </td>
            </tr>
            <br />
            <br />
            <tr>
              <th colspan="4">Expected date of return:</th>
            </tr>
            <tr>
              <td>
                <p *ngFor="let user of userData">
                  {{ user.expected_return_date | date: "dd-MM-yyyy" }}
                </p>
              </td>
            </tr>
  
            <br />
            <br />
            <tr>
              <th colspan="4">Prepared by:</th>
            </tr>
            <tr>
              <td>
                <p *ngFor="let user of userData">{{ user.created_by }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p></p>
              </td>
            </tr>
            <tr>
              <td>
                <p></p>
              </td>
            </tr>
  
            <br />
            <br />
  
            <tr class="text-left" id="spacer">
              &nbsp;
            </tr>
            <tr>
              <th>Stores In-charge</th>
              <th class="text-right" colspan="3">Authorized by</th>
            </tr>
            <tr>
              <th>Received by</th>
            </tr>
          </tbody>
          <tfoot>
            <tr class="footer">
              <td class="text-left" id="spacer">&nbsp;</td>
              <td class="text-center"></td>
              <td class="text-center"></td>
              <td class="text-right"></td>
            </tr>
          </tfoot>
        </table>
        <div id="footer">
          <table
            style="
              width: 276%;
              margin-left: -88%;
              color: rgb(209, 208, 208);
              font-weight: 900;
            "
          >
            <tr>
              <td class="text-left">F-CO-STR-10</td>
              <td class="text-center">I 4 / R0</td>
              <td class="text-center">Date: 20.01.2017</td>
              <td class="text-right">CONFIDENTIAL</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </mat-card>
  
