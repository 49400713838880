<!-- <app-secondcomp (SendValue)="ValueFromComp1($event)"></app-secondcomp>

{{ValueFromComponent1}} -->

<mat-card> 
  <div class="print ">
    <!-- <button onclick="getPrint()">Print</button> -->
    <button [print]="['demo', config]" class="btn btn-info" style="margin-right:1%;margin-left:1%">
      Print
    </button>
    <button (click)="printFormats1()" class="btn btn-info mr-2">
      Print as PDF
    </button>
    <button (click)="backtodmr()" class="btn btn-info mr-2">
      Back
    </button>
    <button (click)="resetSignaturesModal(resetapprovalsmodel)" *ngIf="resetsignStatus" class="btn btn-info mr-2">
      Reset Signatures
    </button>
    <button *ngIf="doc_sign !== 'SENT'"  (click)="getApprovals(approvalsmodel)" [ngClass]="doc_sign === 'SENT' ? 'disabled' : 'enabled'" class="btn btn-info" [disabled]="approvalbtn === true">
    Proceed for Approvals
    </button>
    <!-- <form action="#" (ngSubmit)="getUserFormData()" class="savebutton">
      <input
        type="text"
        name="key"
        id="key"
        [(ngModel)]="key"
        placeholder="Enter Code"
      />
      <input type="hidden" name="field" id="field" value="number" />
      <input type="hidden" name="command" id="command" value="mat" />
      <br /><br />
      <button>Submit</button>
    </form> -->

    <!-- GEPPL/22-23/GRN/001 -->

    <div class="formbody" id="demo">
      <div>
        <!-- <div class="row">
          <div class="col-12 float-right text-right">
            <ngx-qrcode
            [elementType]="elementType" [width]="80"  
            [errorCorrectionLevel]="correctionLevel" 
            [value]="data1"
            alt="Demo QR Code"
            cssClass="bshadow">
          </ngx-qrcode> 
          </div>
        </div> -->
        <div class="row">
          <div class="col-6" >
            <img  *ngIf="companylogo==='greenko'" src="https://testgeps.greenko.net/ui/assets/greenko/newlogo_greeenko_eps.svg" width="200px" />
          </div>
          <div class="col-6 float-right text-right">
            <ngx-qrcode *ngIf="data1" [elementType]="elementType" [width]="80" [errorCorrectionLevel]="correctionLevel"
              [value]="data1" alt="Demo QR Code" cssClass="bshadow">
            </ngx-qrcode>
            <ngx-qrcode *ngIf="document_no" [elementType]="elementType" [width]="80" [errorCorrectionLevel]="correctionLevel"
              [value]="document_no" alt="Demo QR Code" cssClass="bshadow">
            </ngx-qrcode>
            <ngx-qrcode *ngIf="document_no1" [elementType]="elementType" [width]="80" [errorCorrectionLevel]="correctionLevel"
              [value]="document_no1" alt="Demo QR Code" cssClass="bshadow">
            </ngx-qrcode>
          </div>
        </div>
        <div class="row ">
          <div class="col-6">
            <h5><b style="margin-left:1%" *ngFor="let user of userData">{{user.company_name}} </b></h5>

          </div>
          <div class="col-6 float-right text-right">
            <h5 *ngIf="companylogo==='greenko'"><b>GREENKO INTEGRATED MANAGEMENT SYSTEM</b></h5>
            <h5>GOODS RECEIPT NOTE</h5>
          </div>
        </div>


        <div class="row ">
          <div class="col-2 border" style="border-left: none !important;">
            <p>GRN No:</p>
          </div>
          <div class="col-2 border" style="border-left: none !important;">
            <p *ngFor="let user of userData">{{ user.number }}</p>
          </div>
          <div class="col-2 border" style="border-left: none !important;">
            <div class=" row">
              <div class="col-2">
                <p>Date:</p>
              </div>
              <div class="col-10">
                <p *ngFor="let user of userData">
                  &nbsp;{{ user.date | date: "dd-MM-yyyy" }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-2 border" style="border-left: none !important;">
            <p>Gate Entry No:</p>
          </div>
          <div class="col-2 border" style="border-left: none !important;">
            <p *ngFor="let user of userData">{{ user.gate_entry_number }}</p>
          </div>
          <div class="col-2 border" style="border-left: none !important;border-right:none !important">
            <div class=" row">
              <div class="col-2" style="border-left: none !important">
                <p>Date:</p>
              </div>
              <div class="col-10">
                <p *ngFor="let user of userData">
                  &nbsp;&nbsp;{{ user.gate_entry_date | date: "dd-MM-yyyy" }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row ">
          <div class="col-6 " style="
          border-right: 1px solid #000">
            <div class="row">
              <p style="margin-left: 1%;"><b>Supplier's Name: </b></p>
              &nbsp;&nbsp;<p *ngFor="let user of userData">{{ user.vendor_name }}</p>
            </div>
            <div class="row">
              <p style="margin-left: 1%;">
                <b>Address:</b>
               &nbsp;&nbsp;
              <span style="line-height: 20px;" *ngFor="let user of userData">
              {{ getaddress(user.vendor_name) }}</span>
              </p>
            </div>
            <!-- <div class="row">
              <p class="break" *ngFor="let user of dataSource">
                {{user.address_1}}
                
              </p>
            </div> -->
          </div>
          <div class="col-6">
            <div class="row sub " style="
            border-bottom: 1px solid #000;padding: 12px 2px;">
              <div class="col-4 ">P.O No:</div>
              <div class="col-8" *ngFor="let user of userData">
                {{ user.purchase_order_number }}
              </div>
            </div>
            <div class="row sub " style="
            border-bottom: 1px solid #000;padding: 12px 2px;">
              <div class="col-4">P.O Date:</div>
              <div class="col-8" *ngFor="let user of userData">
                {{ user.purchase_order_date | date: "dd-MM-yyyy" }}
              </div>
            </div>
            <div class="row sub " style="
            border-bottom: 1px solid #000;padding:-1px">
              <!-- <div class="col-3"></div>
              <div class="col-2"*ngFor="let data of table"> -->
              <table>
                <tr>
                  <td style="width:33%">Invoice No:</td>
                  <td>
                    <span *ngFor="let data of duplicateinv_num; let i = index">
                      {{data}}<span *ngIf="i < duplicateinv_num.length - 1">&#44;&nbsp;</span>
                    </span>
                    <!-- <span *ngFor="let data of duplicateinv_num | slice:0:4; let $last=last">{{data}}
                      <span *ngIf="!$last; LastElem" class="list-format">&#44;&nbsp;</span>
                      <ng-template #LastElem>
                        <span *ngIf="record.referrerItemList.length > 4" class="hellip-format">
                          &hellip;
                        </span>
                      </ng-template>

                    </span> -->
                  </td>
                </tr>
              </table>
              <!-- <p></p> -->
              <!-- </div> -->
            </div>
            <div class="row sub " style="
            border-bottom: 1px solid #000; padding:-1px">
              <!-- <div class="col-3">Date:</div>
              <div class="col-3" *ngFor="let user of userData">
                {{ user.date | date: "dd-MM-yyyy" }}
              </div> -->
              <table>
                <tr>
                  <td style="width:33%">Invoice Date:</td>
                  <td>
                    <span *ngFor="let data of duplicateinv_date; let i = index">
                      {{data | date: "dd-MM-yyyy"}}<span *ngIf="i < duplicateinv_date.length - 1">&#44;&nbsp;</span>
                    </span>
                    <!-- <span *ngFor="let data of duplicateinv_date | slice:0:4; let $last=last">{{data | date:
                      "dd-MM-yyyy"}}
                      <span *ngIf="!$last; LastElem" class="list-format">&#44;&nbsp;</span>
                      <ng-template #LastElem>
                        <span *ngIf="record.referrerItemList.length > 4" class="hellip-format">
                          &hellip;
                        </span>
                      </ng-template>

                    </span> -->
                  </td>
                </tr>
              </table>
            </div>
            <div class="row sub " style="
            border-bottom: 1px solid #000; padding:-1px">
              <!-- <div class="col-3">Delivery Challan No.:</div>
              <div class="col-3" *ngFor="let user of userData">
                {{ user.dc_number }}
              </div> -->
              <table>
                <tr>
                  <td style="width:33%">Delivery Challan No:</td>
                  <td>
                    <span *ngFor="let data of duplicatedc_number; let i = index">
                      {{data}}<span *ngIf="i < duplicatedc_number.length - 1">&#44;&nbsp;</span>
                    </span>
                    <!-- <span *ngFor="let data of duplicatedc_number | slice:0:4; let $last=last">{{data}}
                      <span *ngIf="!$last; LastElem" class="list-format">&#44;&nbsp;</span>
                      <ng-template #LastElem>
                        <span *ngIf="record.referrerItemList.length > 4" class="hellip-format">
                          &hellip;
                        </span>
                      </ng-template>

                    </span> -->
                  </td>
                </tr>
              </table>
            </div>
            <div class="row sub " style="padding: 12px 2px;">
              <div class="col-4">DC Date:</div>
              <div class="col-8" *ngFor="let user of userData">
                {{ user.dc_date | date: "dd-MM-yyyy" }}
              </div>

            </div>
          </div>
        </div>

        <div class="row m-1">

          <table class="table table-bordered">
            <tr class="center ">
              <th rowspan="2" class="border" style="width: 40px;">S.No.</th>
              <th rowspan="2" class="border" style="width: 150px;">Item Code</th>
              <th rowspan="2" class="border" style="width: 200px;">Item Description</th>
              <th rowspan="2" class="border" style="width: 50px;">UOM</th>
              <th colspan="5" class="border">Quantity</th>
              <th rowspan="2" class="border"  style="width: 150px;">Rate (Rs.)</th>
              <th colspan="3" class="border">
                Amount
              </th>
            </tr>

            <tr scope="row" class="center ">
              <th class="border">Invoiced</th>
              <th class="border">Received</th>
              <th class="border">Accepted</th>
              <th class="border">Rejected</th>
              <th class="border">Shortage</th>
              <!-- <th class="border">Exccess</th> -->
              <th class="border"  colspan="2" style="width: 100px;">Rs.</th>
              <th class="border"   style="width: 30px;">Ps.</th>
            </tr>

            <tr scope="row" *ngFor="let user of table;let i=index" ng-init="$parent.total=total+">
              <td class="center border" style="width: 40px;">{{ i+1}}</td>
              <td class="center border" style="width: 150px;">{{ user.material_code }}</td>
              <td class="center border" style="width: 200px;">{{ user.material_description }}</td>
              <td class="center border" style="width: 50px;">{{ user.unit_of_measurment }}</td>
              <td class="center border">{{ user.invoice_quantity }}</td>
              <td class="center border">{{ user.received_quantity }}</td>
              <td class="center border">{{ user.accepted_quantity }}</td>
              <td class="center border">{{ user.rejected_quantity }}</td>
              <td class="center border">{{ user.shortage_quantity }}</td>
              <!-- <td class="center border">{{ user.excess_quantity }}</td> -->
              <td class="right border" style="width: 150px;">{{ user.unit_price | number: "2.2-3"}}</td>
              <td class="right border" colspan="2" style="width: 100px;">
                <!-- {{ user.accepted_quantity*user.unit_price | number: "1.0-0" }} -->
               <!-- {{ user.accepted_quantity*user.unit_price | number: "2.2-3" }} -->
                {{(+user.basic_price1)}}
                <!-- {{((+user.accepted_quantity)*(+user.basic_price))-(((+user.accepted_quantity)*(+user.unit_price))%1) }} -->
              </td>
              <td class="right border" style="width: 30px;">
                {{(+user.paisa!==0 ? (( user.paisa  % 1).toFixed(2).substring(2)):'00')}}
                <!-- {{ (( (+user.accepted_quantity)*(+user.unit_price))+"").split(".")[1] ? (( (+user.accepted_quantity)*(+user.unit_price)| number:"2.2-2")+"").split(".")[1]   : "00" }} -->
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-6">
          <p><b>Amount In Words:</b></p>
          <p>{{ outputWords | titlecase }}</p>
          <div class="datanumbers">
            <p>Dmr No : 
              <span *ngFor="let user of duplicatedmr_num; let i = index">
                &nbsp;{{user !==null ?user:'NA' }}
                <span *ngIf="i !== duplicatedmr_num.length - 1">&nbsp;,&nbsp;</span>
              </span>
            </p>
            <p>Dmr Date :
              <span *ngFor="let user1 of duplicatedmr_date; let i = index">
                {{ user1 !==null ?user1 :'NA' }}
                <span *ngIf="i !== duplicatedmr_date.length - 1">&nbsp;,&nbsp;</span>
              </span>
            </p>
            <p>QC No :
              <span *ngFor="let user of duplicateqc_num; let i = index">
                &nbsp;{{user !==null ?user:'NA' }}
                <span *ngIf="i !== duplicateqc_num.length - 1">&nbsp;,&nbsp;</span>
              </span>
            </p>
            <p>QC Date :
              <span *ngFor="let user1 of duplicateqc_date; let i = index">
                {{ user1 !==null ?user1 :'NA' }}
                <span *ngIf="i !== duplicateqc_date.length - 1">&nbsp;,&nbsp;</span>
              </span>
            </p>
            <p>ESDN No :
              <span *ngFor="let user of duplicateesdn_num; let i = index">
                &nbsp;{{user !==null ?user:'NA' }}
                <span *ngIf="i !== duplicateesdn_num.length - 1">&nbsp;,&nbsp;</span>
              </span>
            </p>
            <p>ESDN Date :
              <span *ngFor="let user1 of duplicateesdn_date; let i = index">
                {{ user1 !==null ?user1 :'NA' }}
                <span *ngIf="i !== duplicateesdn_date.length - 1">&nbsp;,&nbsp;</span>
              </span>
            </p>
          </div>
          
          <!-- <div class="datanumbers">
            <p >Dmr No : <span *ngFor="let user of duplicatedmr_num;let i=index">&nbsp;{{ user !==null ?user:'NA'}}<span *ngIf="i!=duplicatedmr_num .length-1">&nbsp;,&nbsp;</span></span> </p>
            <p >Dmr Date :<span *ngFor="let user1 of duplicatedmr_date;let i=index">{{user1 !==null? (user1):'NA'}}<span *ngIf="i!=duplicatedmr_date .length-1">&nbsp;,&nbsp;</span></span> </p>
            <p >QC No :<span *ngFor="let user of duplicateqc_num;let i=index">&nbsp;{{ user!==null?user:'NA'}}<span *ngIf="user!==null">&nbsp;,&nbsp;</span><span *ngIf="i!=duplicateqc_num.length-1">&nbsp;,&nbsp;</span></span> </p>
            <p >QC Date :<span *ngFor="let user1 of duplicateqc_date;let i=index">{{user1 !==null? (user1):'NA'}}<span *ngIf="i!=duplicateqc_date .length-1">&nbsp;,&nbsp;</span></span> </p>
            <p >ESDN No:<span *ngFor="let user of duplicateesdn_num;let i=index">&nbsp;{{ user!==null?user:'NA'}}<span *ngIf="i!=duplicateesdn_num.length-1">&nbsp;,&nbsp;</span></span></p>
            <p >ESDN Date :<span *ngFor="let user1 of duplicateesdn_date;let i=index">{{user1 !==null? (user1):'NA'}}<span *ngIf="i!=duplicateesdn_date .length-1">&nbsp;,&nbsp;</span></span> </p>
          </div> -->
        </div>
        <div class="col-6 row">
          <div class="col-6 font-weight-bold border-bottom ">
            <p>Total Value(A):</p>
          </div>
          <div class="col-6 float-right text-right font-weight-bold border-bottom">
            <p>{{ total | currency: "INR":"" }}</p>
            <!-- <p *ngFor="let sum of basic_price">{{sum}}</p> -->
          </div>
          <div class="col-6 font-weight-bold border-bottom ">
            <p>Discount Value(B):</p>
          </div>
          <div class="col-6 float-right text-right font-weight-bold border-bottom">
            <p>{{ totalDiscount | currency: "INR":"" }}</p>
          </div>
          <div class="col-6 font-weight-bold border-bottom ">
            <p>
              Total (C = A-B):
            </p>
          </div>
          <div class="col-6 float-right text-right font-weight-bold border-bottom">
            <p>{{ total - totalDiscount | currency: "INR":"" }}</p>
          </div>
          <div class="col-6 font-weight-bold border-bottom ">
            <p>
              GST value(D):
            </p>
          </div>
          <div class="col-6 float-right text-right font-weight-bold border-bottom">
            <p>{{ totalTax | currency: "INR":"" }}</p>
          </div>
          <div class="col-6 font-weight-bold border-bottom ">
            <p>OtherTax(E):</p>
          </div>
          <div class="col-6 float-right text-right font-weight-bold border-bottom">
            <p *ngFor="let user of userData">
              {{ other_tax_split | currency: "INR":"" }}
            </p>
          </div>
          <div class="col-6 font-weight-bold border-bottom ">
            <p>Transportaion Charges (F):</p>
          </div>
          <div class="col-6 float-right text-right font-weight-bold border-bottom">
            <p *ngFor="let user of userData">
              {{ freight_split | currency: "INR":"" }}
            </p>
          </div>
          <div class="col-6 font-weight-bold border-bottom ">
            <p>Other Charges (G):</p>
          </div>
          <div class="col-6 float-right text-right font-weight-bold border-bottom">
            <p>
              {{ otherCharges | currency: "INR":"" }}
            </p>
          </div>
          <div class="col-6 font-weight-bold border-bottom ">
            <p>Round off:</p>
          </div>
          <div class="col-6 float-right text-right font-weight-bold border-bottom">
            <p>{{ roundOff | number: "0.0-2" }}</p>
          </div>
          <div class="col-6 font-weight-bold border-bottom ">
            <p>Grand Total (C+D+E+F+G):</p>
          </div>
          <div class="col-6 float-right text-right font-weight-bold border-bottom">
            <p>{{ grandTotall | number: "2.0-0" }}.00</p>
          </div>
        </div>
          <!-- <p *ngFor="let user of userData">{{ user.dmr_number }}</p> -->
      </div>

      <div class="remarks ">
        <div class="row grey " style="border-top:1px solid #000;">
          <p class="ml-3" style="line-height: 16px;"><b>Remarks:</b></p>
          &nbsp;&nbsp;  <p *ngFor="let user of userData" style="line-height: 16px;">{{ user.comments }}</p>
        </div>
        <div class="row grey" style="border-top:1px solid #000;">
          <p class="ml-3" style="line-height: 16px;"><b>Inspection Comments and Advice:</b></p>
          &nbsp;&nbsp;  <p *ngFor="let user of userData" style="line-height: 16px;">{{ user.qc_comments }}</p>
        </div>
        <!-- <div class="row padding" *ngIf="data1" style="border-top:1px solid #000; border-bottom:1px solid #000">
          <div class="col-4">
              <p class="ml-1 "><b>Store InCharge</b></p>
          </div>
          <div class="col-4 text-center">
            <p><b>Inspected by</b></p>
          </div>
          <div class="col-4 text-right">
            <p class="mr-4"><b>Project In-charge/ Authorized Signatory</b></p>  -->
        <!-- <p><b>Authorized Signatory</b></p> -->
        <!-- </div>
        </div>  -->
        <div class="row padding1  align-items-center  justify-content-between" *ngIf="signStatus"
        style="border-top:1px solid #000; border-bottom:1px solid #000">
        <div class="col" *ngFor="let item of SignData">
          <img *ngIf="item.status=='COMPLETED' && item.signature" [src]="item.signature" class="sign-width" />
          <p class="ml-1 "><b>{{item.description}}</b></p>
          <p *ngIf="item.status=='COMPLETED' && item.updated_by !== null" class="ml-1 "><b>{{item.updated_by}}</b></p>
          <!-- <p *ngIf="item.status=='COMPLETED' && item.updated_date !== null">Date: {{getFormattedDate(item?.updated_date)}}</p> -->
          <p  style="line-height: 16px;" *ngIf="item.status=='COMPLETED' && !(item.comments == null || item.comments == '')">Comments:{{item?.comments}}</p>
        </div>
      </div>
      <div class="row padding1  align-items-center  justify-content-between" *ngIf="!signStatus"
        style="border-top:1px solid #000; border-bottom:1px solid #000">
      <div class="col pt-4 mt-4" *ngFor="let item of SignData">
        <img *ngIf="item && item.status == 'COMPLETED'" [src]="item.signature" class="sign-width" />
        <p class="ml-1" *ngIf="item.sign_button_enabled && item.signature && item?.signature===null">Need to be Signature</p>
        <button type="button" class="btn btn-custom" *ngIf="item.sign_button_enabled  && item.status === 'PENDING'"
          (click)="getSignatureView(item,viewSignmodel)">Sign</button>
          <p class="ml-1 "><b>{{item.description}}</b></p>
          <p class="ml-1 " *ngIf="item.status=='COMPLETED' && item.updated_by !== null">Name:{{item.updated_by}}</p>
          <!-- <p *ngIf="item.status=='COMPLETED' && item.updated_date !== null">Date: {{getFormattedDate(item?.updated_date)}}</p> -->
          <p style="line-height: 16px;" *ngIf="item.status=='COMPLETED' && !(item.comments == null || item.comments == '')">Comments:{{item?.comments}}</p>
      </div>
</div>
    </div>

    <br />
    <div class="row justify-content-center">

      <p><b>Accounts/ Indenter/ Stores</b></p>
    </div>

      <div class="row mt-2 footerTable" style="color:#888">
        <div class="col-4 text-left" *ngIf="isnonGroup == 'no'">
            F-GEP-SIM-01-02
        </div>
        <div class="col-4 text-center" *ngIf="isnonGroup == 'no'">
            I1.RO/05.10.2023<br>
            PROTECTED
        </div>
        <div class="col-4 text-right">
          1 OF 1
        </div>
        <!-- <div class="col-4 text-right">
            1 OF 1
        </div> -->
        <!-- <div class="col-3">
            Revision No:00
        </div>
        <div class="col-3">
            <span> Date:20.01.2017</span>
        </div> -->
        <!-- *ngFor="let user of userData">{{
        user.date | date: "dd-MM-yyyy"
        }}</span> -->
    
    </div>
  </div>
  </div>
  
</mat-card>
<!-- model -->
<ng-template #viewSignmodel>
  <h1 mat-dialog-title>View Signature</h1>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-6">
        <label>Comments</label>
        <textarea rows="5" cols="50" style="resize:none" type="text" placeholder="Enter Comments" class="textinputstyles"
          [(ngModel)]="comments" name="comments"></textarea>
      </div>
      <div class="col-6 text-center">
        <img [src]="Viewsign" class="sign-width" />
      </div>
    </div>
  </div><br>
  <div mat-dialog-actions class="d-flex justify-content-center">
    <button mat-button mat-dialog-close class="matButton">Close</button>&nbsp;
    <button mat-button cdkFocusInitial (click)="SignatureFile()" class="matButton"
      [disabled]="!Viewsign">Confirm</button>
  </div>
</ng-template>

<!-- Approvals -->
<ng-template #approvalsmodel> 
  <span class="d-flex justify-content-between">
      <h1 mat-dialog-title>Document Number:{{data1}}</h1>
      <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="close-icon">close</mat-icon>
      </button>
  </span>
  <div mat-dialog-content>
      <p>Do you confirm to proceed for Signatures</p>
  </div>
  <div mat-dialog-actions>
      <button mat-button cdkFocusInitial class="matButton" (click)="getConfirmData()" >
          Confirm
      </button>&nbsp;
      <button mat-button mat-dialog-close class="matButton" >
          Cancel</button>
  </div>
</ng-template>

<!-- Reset Approvals -->
<ng-template #resetapprovalsmodel> 
  <span class="d-flex justify-content-between">
      <h3 mat-dialog-title>Document Number:{{ this.data1 || this.document_no || this.document_no1}}</h3>
      <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="close-icon">close</mat-icon>
      </button>
  </span>
  <div mat-dialog-content>
      <p>Do you confirm to Reset Signatures</p>
      <div class="col-md-12">
        <label>Select Position<span class="mandetory">*</span></label>
        <select class="dropdownstyles" name="resetlevel" [(ngModel)]="resetlevel" >
          <option value="">Select One</option>
          <option *ngFor="let item of filteredSignData" >{{item.level}}</option>
        </select>
    </div>
  </div>
  <div mat-dialog-actions>
      <button mat-button cdkFocusInitial class="matButton" [disabled]="!resetlevel" (click)="resetSignatures()" >
          Confirm
      </button>&nbsp;
      <button mat-button mat-dialog-close class="matButton" >
          Cancel</button>
  </div>
</ng-template>