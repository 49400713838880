import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AlertCallsService } from 'src/app/auth/alert-calls.service';
import { CustomerService } from 'src/app/services/customer.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import {  HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IpService } from 'src/app/ip.service';
import * as XLSX from 'xlsx'; 
import { MatSort } from '@angular/material/sort';
import { event } from 'jquery';
import { MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'app-qualitycheck',
  templateUrl: './qualitycheck.component.html',
  styleUrls: ['./qualitycheck.component.scss']
})
export class QualitycheckComponent implements OnInit {
  ipAddress: string;
  @ViewChild('remarks') remarks1: ElementRef;
  @ViewChild('viewFilemodel') viewFilemodel: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource();
  dataSource1=new MatTableDataSource()
  dataSourcemain = new MatTableDataSource();
  dataSourcemainpnd = new MatTableDataSource();
  activitylogcolumns :any[]=[
    "sno",
    "username",
    "created_date",
    "reference_number",
    "description",
    "remarks",
    // "reason",
    "action",
    'download',
  ];
  displayedColumns: any[] = [
    'sno',
    'material_code',
    'material_description',
    'unit_of_measurement',
    'qc_quantity',
    'accepted_quantity',
    'rejected_quantity',
    'action'
      
    ];
    displayedColumns1: any[] = [
      'sno',
      'qc_Number',
      'qc_date',
      'DMRNumber',
      'VendorName',
      'InvoiceNumber',
      'company_name',
      'po_number',
      'po_date',
      // 'ActiveStatus',
      'action'
        
      ];
      displayedColumns1pnd: any[] = [
        'sno',  
        'qc_date',
        'DMRNumber',
        'dmr_date',
        'po_number',
        // 'date',
        'company_name',
        'VendorName',
        'InvoiceNumber',
        'count',
        'action'
        // 'ActiveStatus',
        // 'action'
          
        ];
model1:any={}
model:any={}
model2:any={}
  // dmrDATA: any;
  materialRECEIPTDATA: any;
  materialcode: any;
  MATDATA: any;
  qcqty: any=0
  recqty: any=0
  accqty: any=0
  materialCODE:any[]=[]
  matDESCRIPTION:any[]=[]
  UOM:any[]=[]
  SNO:any[]=[]
  QCQTY:any[]=[]
  AcceptedQTY:any[]=[]
  RejectedQTY:any[]=[]
  remarks: any;
  REMARKS:any[]=[]
  qcQuantity: any;
  itemremarks: any;
  pageIndex: any=0;
  pageSize:any=10;
  pageSizepnd = 10;
  pageIndexpnd = 0;
  pageSizelog = 10;
  pageIndexlog = 0;
  reaData: boolean;
  totalRecords:any=0
  loadingRecords:any=false;
  deletedItem: any;
  finalarray:any[]=[]
  selectedIndex:any=-1
  QC_QTY:any[]=[]
  ACC_QTY:any[]=[]
  REJ_QTY:any[]=[]
  ITEMREMARKS:any[]=[]
  selecteddmr1: any;
  dialogRef:any=null;
  qualitychecknumber: any;
  filedata: any=[]
  filedataDMR:any=[]
  createNewFile:any={}
  imageUrl=environment.base_url
  deleteid: any;
  fileUploadUrls: any[]=[]
  fileUploadUrlsqc:any[]=[]
  resultqcnumber: any;
  selectedfiles:any[]=[]
  filenamearray:any[]=[]
  editednumber: any;
  editdataa: any;
  dmrDATA: any[]=[];
  demo1TabIndex:any=0
  btn:any="Save"
  initialdmr:any=true;
  editdmr:any=false;
  logdata: any;
  initialdata:any=false;
  deletemodel:any={}
  searchData: any;
  columnname: any="";
  reaDatalog: boolean;
  totalRecordslog: any;
  dataSourcemainlog = new MatTableDataSource();
  logsearchData: any;
  selecteddmrdata: any;
  viewData: boolean=false;
  dmrnumber: any="";
  selectedDMR:any;
  userDeviceData: string;
  searchDatapnd: any;
  columnnamepnd: any="";
  totalRecordspnd: any;
  reaDatapnd: boolean;
  selectdmr: boolean=false;
  activityLogDownload: any=[];
  today:any
  @ViewChild('TABLELog', { static: false }) TABLELog: ElementRef; 
  txnId: any;
  filteredGiData: any;
  filteredqcData: any[]=[];
  dailymatres: any;
  dmridnumber: any;
// onTabChanged: any;
  constructor(public custservice:CustomerService,private dialog:MatDialog,
    private alertcall:AlertCallsService,private injector:Injector,
    private ip: IpService) { 
      this.dialogRef = this.injector.get(MatDialogRef, null);
    }

  ngOnInit(): void {
    // this.ip.getIpAddress().subscribe((res:any)=>{
    //   this.ipAddress = res.ip;
    // });
    // this.userDeviceData =  navigator.userAgent
    // this.ipService.getIpAddress().then(ipAddress => {
    //   this.ipAddress = ipAddress;
    // }).catch(error => {
    //   console.error('Error retrieving IP address:', error);
    // });
    this.today=moment(new Date()).format("YYYY-MM-DD")
    this.model1.dateee=moment(new Date()).format("YYYY-MM-DD")
    this.model1.podate=moment(new Date()).format("YYYY-MM-DD")
    // this.getMaterialdata()
    this.getTransactionId();
    this.getdata()
    this.getdatapnd();
  }
  getTransactionId(){
    this.txnId=""
    this.custservice.getTransactionId().subscribe((res:any)=>{
      if(res){
        // console.log(res.txn_id);
        this.txnId=res.txn_id
      }
    })
  }
  openfileuploadmodel(data:any,row1:any){
    this.dialog.open(data,{
      width:'800px'
    })
    this.qualitychecknumber=row1.dmr_number
    this.resultqcnumber=row1.number
    this.getexistingfiles()
  }
getexistingfiles() {
  let params = new HttpParams();
  params = new HttpParams()
    .set("document_number", this.qualitychecknumber)
    .set("document_type", "Quality Check");
  this.custservice.getexistingfies(params).subscribe((res: any) => {
    if (res && res["status_code"] == "200") {
      const uniqueFiles = [];
      const uniqueFileNames = new Set();

      for (const file of res.data) {

        if (!uniqueFileNames.has(file.file_name)) {
          uniqueFileNames.add(file.file_name);
          uniqueFiles.push(file);
        }
      }
      this.filedata = uniqueFiles;
      this.createNewFile.fileName = "";
    } else {
      this.filedata = [];
    }
  });
}

  viewDoc(file: any) {
    // const url = this.imageUrl + '/' + file.file_path;
const url =  file.file_path;
    window.open(url, '_blank');
  }
  deleterowfile(row:any,data:any){
    this.deleteid=data.id
    this.dialogRef=this.dialog.open(row,{
      width:"400px"
    })
  }
  deleteexistingfile(){ 
    let params=new HttpParams()
    params=new HttpParams()
    .set("document_number", this.qualitychecknumber,)
     .set( "document_type","Quality Check")
     .set( "id",this.deleteid)
     this.custservice.deletefiles(params).subscribe((res:any)=>{
      if(res&&res['status_code']=='200'){
        this.alertcall.showSuccess("Accepted","File Deleted Successfully")
        this.getexistingfiles()
        this.dialogRef.close()
      }else{
        this.alertcall.showWarning("Error",res['message'])
       
        
       
      }
     })
  }
uploadWbsFile(fileInput: any) {
  if (
    fileInput &&
    fileInput.target &&
    fileInput.target.files &&
    fileInput.target.files.length > 0
  ) {
    this.fileUploadUrls = fileInput.target.files;
    this.createNewFile.fileName = fileInput.target.files[0].name;

    const postData = new FormData();
    postData.append("document_type", "Quality Check");
    postData.append("document_number", this.qualitychecknumber);

    let hasDuplicates = false; 

    for (const file of this.fileUploadUrls) {
      if (this.filedata && this.filedata.length > 0 && this.isFileSelected1(file.name)) {
        hasDuplicates = true;
        break; 
      } else {
        postData.append("doc", file);
      }
    }

    if (hasDuplicates) {
      this.alertcall.showWarning('Alert', 'Some files are already uploaded');
    } else {
      this.custservice.addfileupload(postData).subscribe((res: any) => {
        if (res['status_code'] == '200' && this.fileUploadUrls.length === 1) {
          this.alertcall.showSuccess("Accepted", "File is Uploaded Successfully")
          this.getexistingfiles()
          // this.fileUploadUrls = []
        }else if(res['status_code'] == '200' && this.fileUploadUrls.length>1){
          this.alertcall.showSuccess("Accepted", "Files are Uploaded Successfully")
          this.getexistingfiles()
          // this.fileUploadUrls = []
        } 
        else {
          this.alertcall.showWarning("Error", res['message'])
        }
        fileInput.target.value = '';
      });
    }
  }
}
  uploadqcfiles(fileInput: any) {
    if (
      fileInput &&
      fileInput.target &&
      fileInput.target.files &&
      fileInput.target.files.length > 0
    ) {
      this.fileUploadUrlsqc = fileInput.target.files;
      this.createNewFile.fileName = fileInput.target.files[0].name;
      for(const file of this.fileUploadUrlsqc){
        if(this.isFileSelected(file.name)){
          this.alertcall.showWarning('Alert', 'Some files are already selected');
        }else if(this.fileUploadUrlsqc.length == 1){
          this.alertcall.showSuccess("Accepeted","File is uploaded successfully")
          this.filenamearray.push(file.name)
          this.selectedfiles.push(file)
        }
        else{
          this.alertcall.showSuccess("Accepeted","Files are uploaded successfully")
          this.filenamearray.push(file.name)
          this.selectedfiles.push(file)
        }
      }
      fileInput.target.value = '';
    }
  
  }
  isFileSelected(selectedFilePath: string): boolean {
    return this.selectedfiles.some((file:any) => file.name == selectedFilePath);
  }
  isFileSelected1(selectedFilePath: string): boolean {
    return this.filedata.some((file:any) => file.file_name == selectedFilePath);
  }
  uploadselectedfiles(){
    const postData = new FormData();
    postData.append("document_type","Quality Check");
    // postData.append("document_number",this.resultqcnumber);
    postData.append("document_number",this.dmridnumber);
    for(const file of this.selectedfiles){
      postData.append("doc",file)
    }
    this.custservice.addfileupload(postData).subscribe((res:any)=>{
if(res&&res['status_code']=='200'){

this.fileUploadUrls=[]
this.selectedfiles=[]
this.filenamearray=[]
}else{
 
}
    })
  }
  onpageevent(event:any){
    this.pageIndex=event.pageIndex;
    this.pageSize= event.pageSize;
    this.getdata()
  }
  getdata(){
    let obj:any={}
    obj={
      "command":"lst",
      "lmt" : this.pageSize,
      "pid" : this.pageIndex+1,
      showLoader: 'hide'
    }
    if(this.searchData){
     obj.field = this.columnname,
      obj.key = this.searchData
    }
    this.custservice.getQualityCheck(obj).subscribe((res:any)=>{
      this.reaData=false;
      this.totalRecords = res?.count;
      this.dataSourcemain.data = res.data
      this.dataSourcemain.sort = this.sort
      if(res.data.length==0){
       this.reaData = true
     }
    })
  }

  getcompletedata(){
    this.searchData=""
    this.columnname=""
    this.getdata();
  }
  onpageeventpnd(event:any){
    this.pageIndexpnd=event.pageIndex;
    this.pageSizepnd= event.pageSize;
    this.getdatapnd();
  }
  getdatapnd(){
    let obj:any={}
    obj={
      "command":"pnd",
      "lmt" : this.pageSizepnd,
      "pid" : this.pageIndexpnd+1,
      showLoader: 'hide'
    }
    if(this.searchDatapnd){
     obj.field = this.columnnamepnd,
      obj.key = this.searchDatapnd
    }
    this.custservice.getQualityCheck(obj).subscribe((res:any)=>{
      this.reaDatapnd=false;
      this.totalRecordspnd = res?.count;
      this.dataSourcemainpnd.data = res.data
      this.dataSourcemainpnd.sort = this.sort
      if(res.data.length==0){
       this.reaDatapnd = true
     }
    })
  }

  getdatapnddata(){
    this.searchDatapnd=""
    this.columnnamepnd=""
    this.getdatapnd();


  }
  deleteItem(data:any,rw:any){
    this.deletedItem=data.number
    this.dialog.open(rw,{
      width:'400px'
    })
    this.deletemodel.reason="";
  }
  deleteFile(){
    let obj={
      "command" : "del", 
      "number" : this.deletedItem,
      "reason":this.deletemodel.reason,
      "txn_id":this.txnId,
    }
    this.custservice.deleteQualityCheck(obj).subscribe((res:any)=>{
      if(res&&res['status_code']=="200"){
        this.alertcall.showSuccess('Accepted', res['message']);
        
        this.dialog.closeAll()
        this.deletemodel.reason=""
        this.getTransactionId();
        this.getdata()
        this.getdatapnd();
           }else{
            this.alertcall.showWarning('Accepted', res['message']);
           }
    })
      }

      getdmrdata(ev:any){
      const searchTerm = ev.target.value.toLowerCase();
      this.filteredqcData = this.dmrDATA.filter((item: any) => item.number.toLowerCase().includes(searchTerm));
      }
  getMaterialdata(){
    let obj={
      "command":"dmr",
      // "lmt" : 100000,
      // "pid" : 1,
      // "key" : this.selecteddmr1
    }
    this.custservice.getQualityCheckdmrdata(obj).subscribe((res:any)=>{
     this.dmrDATA=res.data
     this.filteredqcData=this.dmrDATA

      
    })
  }
  selecteddmr(){
    this.dmrnumber= this.model1.dmrnumber
    let obj={
      command: "set",
       key: this.model1.dmrnumber || this.dmrnumber,
        field: "number"
    }
    this.custservice.getQualityCheckdmrdata(obj).subscribe((res:any)=>{
      this.MATDATA=res.data
      this.materialRECEIPTDATA=res.data[0]
      this.model1.ponumber=this.materialRECEIPTDATA.purchase_order_number
      this.model1.dmrdateee=moment(this.materialRECEIPTDATA.dmr_date).format("YYYY-MM-DD");
      this.model1.podate=moment(this.materialRECEIPTDATA.purchase_order_date).format("YYYY-MM-DD");
      this.model1.companyname=this.materialRECEIPTDATA.company_name
      this.model1.vendorname=this.materialRECEIPTDATA.vendor_name
      this.model1.invoicenumber=this.materialRECEIPTDATA.invoice_number
      this.materialcode=this.materialRECEIPTDATA.material_code
      this.model1.receiveddateee=moment(this.materialRECEIPTDATA.qc_sent_date).format("YYYY-MM-DD"),
      this.model1.dmr_created_date=moment(this.materialRECEIPTDATA.dmr_created_date).format("YYYY-MM-DD"),
      this.model1.dmr_date=moment(this.materialRECEIPTDATA.dmr_date).format("YYYY-MM-DD")
    })
  }
  selectedqcqty(ev:any){
    this.qcqty=ev.target.value

    
  }
  selectedaccqty(ev:any,data:any){
    this.accqty=ev.target.value
    if(this.accqty>((this.qcqty||data)-this.recqty)){
      this.alertcall.showWarning("Accepted","Accepted Qty Exceed The Limit")
    }
  //  this.qcQuantity=data
  }
  selectedrecqty(ev:any,data:any){
this.recqty=ev.target.value
if(this.recqty>((this.qcqty||data)-this.accqty)){
  this.alertcall.showWarning("Accepted","Rejected Qty Exceeds The Limit")
}

  }
  selectedremarks(ev:any,index:any){
    this.selectedIndex=index

    this.REMARKS.push(ev.target.value)
  }
  // savetabledata(){
  //   const b1: HTMLElement = window.document.getElementById("myTable")!
  //   b1?.addEventListener('click', (e: Event) => {
  //     // Do stuff.
  //     console.log(e);
  //   });
  //   // var b2=b1.rows.length
  //   // console.log(b1,b2);
    
  // }
  deleterowItem(index:any){
this.MATDATA.splice(index,1)
  }
  savefinaldata(fr:any){
   
    if(this.btn==="Save"){
    this.MATDATA.forEach((element:any,index:any) => {
      // if(this.MATDATA[index].accepted_quantity>(this.MATDATA[index].received_quantity-this.MATDATA[index].rejeted_quantity)){
      //   // console.log((this.qcqty||data)-this.recqty);
        
      //   this.alertcall.showWarning("Accepted","Accepted Qty Exceed The Limit")
      // }
      // if(this.MATDATA[index].rejeted_quantity>(this.MATDATA[index].received_quantity-this.MATDATA[index].accepted_quantity)){
      //   this.alertcall.showWarning("Accepted","Rejected Qty Exceeds The Limit")
      // }
      this.QC_QTY.push(Number(element.received_quantity))
      this.ACC_QTY.push(Number(element.accepted_quantity))
      this.REJ_QTY.push(Number(element.rejeted_quantity))
      this.ITEMREMARKS.push(element.item_remarks)
    });
    
    this.MATDATA.forEach((element:any,index:any) => {
      this.materialCODE.push(element.material_code)
      this.matDESCRIPTION.push(element.material_description)
      this.UOM.push(element.unit_of_measurment)
      this.SNO.push(element.line_item)
    //   this.QCQTY.push(this.qcQuantity)
    // this.AcceptedQTY.push(this.accqty)
    // this.RejectedQTY.push(this.recqty)
    
    });
    
    let obj={
    // "number": "GEPPL/22-23/QC/009",     
    "dmr_number": this.model1.dmrnumber, 
    "comments": this.model1.comments,     
    "date": moment(this.model1.dateee).format("YYYY-MM-DD"),                  
    "purchase_order_number": this.model1.ponumber,    
    "purchase_order_date": this.model1.podate,
    "company_name": this.model1.companyname, 
    "vendor_name": this.model1.vendorname, 
    "invoice_number": this.model1.invoicenumber, 
    "line_item": this.SNO,	 
    "item_remarks": this.ITEMREMARKS,   
    "material_code": this.materialCODE ,

    "material_description":this.matDESCRIPTION,

    "unit_of_measurment": this.UOM,  

    "qc_quantity": this.QC_QTY,  

    "accepted_quantity": this.ACC_QTY, 
    "rejeted_quantity": this.REJ_QTY, 
    "txn_id":this.txnId,
    "command": "add"
  }
 
  
  this.custservice.addQualityCheck(obj).subscribe((res:any)=>{
    if(res&&res['status_code']=='200'){
      // this.alertcall.showSuccess('Accepted', res['message']);
      Swal.fire({
        text: res['message'],
        title: res['reference'],
         icon: 'success',
        // title: res['reference'],
        width: 500,
        });
      this.MATDATA=null
      this.dmridnumber=this.model1.dmrnumber
      fr.reset()
      this.QC_QTY=[]
      this.ACC_QTY=[]
      this.REJ_QTY=[]
      this.ITEMREMARKS=[]
      this.materialCODE=[]
      this.matDESCRIPTION=[]
      this.UOM=[]
      this.SNO=[]
      this.model1 = {};
      this.model1.dateee=moment(new Date()).format("DD-MM-YYYY")
      this.model1.ponumber=null
      this.model1.podate=null
      this.model1.companyname=null
      this.model1.vendorname=null
      this.model1.invoicenumber=null
      this.materialcode=null
      this.model1.dmrnumber=null
      this.dailymatres=this.model1.dmrnumber
      
      
      
      this.getTransactionId();
    this.getdata()
    this.getdatapnd();
    this.resultqcnumber=res['reference']
    // if(this.fileUploadUrlsqc.length>0){
      this.uploadselectedfiles()
    // }
       }else{
        this.alertcall.showWarning('Accepted', res['message']);
     this.QC_QTY=[]
      this.ACC_QTY=[]
      this.REJ_QTY=[]
      this.ITEMREMARKS=[]
      this.materialCODE=[]
      this.matDESCRIPTION=[]
      this.UOM=[]
      this.SNO=[]
      // this.model1.ponumber=''
      // this.model1.podate=''
      // this.model1.companyname=''
      // this.model1.vendorname=''
      // this.model1.invoicenumber=''
      // this.materialcode=''
       } 
    
  })
}else{
  this.MATDATA.forEach((element:any,index:any) => {
   
    this.QC_QTY.push(Number(element.received_quantity))
    this.ACC_QTY.push(Number(element.accepted_quantity))
    this.REJ_QTY.push(Number(element.rejeted_quantity))
    this.ITEMREMARKS.push(element.item_remarks)
  });
  
  this.MATDATA.forEach((element:any,index:any) => {
    this.materialCODE.push(element.material_code)
    this.matDESCRIPTION.push(element.material_description)
    this.UOM.push(element.unit_of_measurment)
    this.SNO.push(element.line_item)
  
  
  });
  
  let obj={
  "number": this.editednumber,     
  "dmr_number": this.model1.dmrnumber, 
  "comments": this.model1.comments,     
  "date": moment(this.model1.dateee).format("YYYY-MM-DD"),                  
  "purchase_order_number": this.model1.ponumber,    
  "purchase_order_date": this.model1.podate,
  "company_name": this.model1.companyname, 
  "vendor_name": this.model1.vendorname, 
  "invoice_number": this.model1.invoicenumber, 
  "line_item": this.SNO,	 
  "item_remarks": this.ITEMREMARKS,   
  "material_code": this.materialCODE ,

  "material_description":this.matDESCRIPTION,

  "unit_of_measurment": this.UOM,  

  "qc_quantity": this.QC_QTY,  

  "accepted_quantity": this.ACC_QTY, 
  "rejeted_quantity": this.REJ_QTY, 
  "txn_id":this.txnId,
  "command": "edt"
}
this.custservice.addQualityCheck(obj).subscribe((res:any)=>{
  if(res&&res['status_code']=='200'){
    // this.alertcall.showSuccess('Accepted', res['message']);
    Swal.fire({
      text: res['message'],
      title: res['reference'],
       icon: 'success',
      // title: res['reference'],
      width: 500,
      });
    this.MATDATA=null
    fr.reset()
    this.QC_QTY=[]
    this.ACC_QTY=[]
    this.REJ_QTY=[]
    this.ITEMREMARKS=[]
    this.materialCODE=[]
    this.matDESCRIPTION=[]
    this.UOM=[]
    this.SNO=[]
    this.model1.ponumber=null
    this.model1.podate=null
    this.model1.companyname=null
    this.model1.vendorname=null
    this.model1.invoicenumber=null
    this.materialcode=null
    this.model1.dmrnumber=null
    this.model1.dateee=moment(new Date()).format("YYYY-MM-DD")
 
    this.getTransactionId();
  this.getdata()
  this.getdatapnd();
  this.resultqcnumber=this.editednumber
  // if(this.fileUploadUrlsqc.length>0){
    this.uploadselectedfiles()
  // }
  this.logdata=""
     }else{
      this.alertcall.showWarning('Accepted', res['message']);
   this.QC_QTY=[]
    this.ACC_QTY=[]
    this.REJ_QTY=[]
    this.ITEMREMARKS=[]
    this.materialCODE=[]
    this.matDESCRIPTION=[]
    this.UOM=[]
    this.SNO=[]
   
     } 
  
}) 
}
  }
  getdmrDATA(ev:any){
    this.selecteddmr1=ev.target.value
    if(this.selecteddmr1.length>2){
this.getMaterialdata()
    }
  }
  editdata(data:any){
    this.initialdmr=false;
  this.editdmr=true;
    this.editednumber=data.number
    let obj={
      command: "mat",
      field: "number",
      key: data.number
    }
    this.custservice.addQualityCheck(obj).subscribe((res:any)=>{
      // this.logdata=res.log
      if(res){
        this.viewData=true
        this.editdataa=res.data[0]
        this.MATDATA=res.data
     this.model1.dmrnumber=this.editdataa.dmr_number, 
     this.model1.comments=this.editdataa.comments, 
     this.model1.dmr_comments=this.editdataa.dmr_comments || "NA"   
     this.model1.dmrdateee=moment(this.editdataa.dmr_date).format("DD-MM-YYYY");
     this.model1.dateee=moment(this.editdataa.date).format("DD-MM-YYYY"),                  
     this.model1.ponumber=this.editdataa.purchase_order_number,    
     this.model1.podate=moment(this.editdataa.purchase_order_date).format("DD-MM-YYYY"),
     this.model1.companyname=this.editdataa.company_name, 
     this.model1.vendorname=this.editdataa.vendor_name, 
     this.model1.invoicenumber=this.editdataa.invoice_number,   
     this.model1.receiveddateee=moment(this.editdataa.qc_sent_date).format("DD-MM-YYYY"),
     this.model1.dmr_created_date=moment(this.editdataa.dmr_created_date).format("DD-MM-YYYY"),
     this.model1.dmr_date=moment(this.editdataa.dmr_date).format("DD-MM-YYYY"),
     this.model1.item_remarks=this.editdataa.item_remarks !=="" ? this.editdataa.item_remarks : "NA"
         this.demo1TabIndex=0;
         this.initialdata=true;
      }else{
        this.alertcall.showWarning('Accepted', 'Data Not Found');
      }
    })
  }

  viewdata(data:any){
  //   this.initialdmr=false;
  // this.editdmr=true;
    // this.editednumber=data.dmr_number
   
    let obj={
      command: "set",
      key:  data.dmr_number,
      field: "number"
    }
    this.custservice.getQualityCheckdmrdata(obj).subscribe((res:any)=>{
      if(res){
        this.model1.dmrnumber = data.dmr_number
        this.getexistinguploadedfiles(data.dmr_number)
        this.demo1TabIndex=0;
        this.MATDATA=res.data    
        this.materialRECEIPTDATA=res.data[0]
        this.model1.ponumber=this.materialRECEIPTDATA.purchase_order_number
        this.model1.dmrdateee=moment(this.materialRECEIPTDATA.dmr_date).format("YYYY-MM-DD");
        this.model1.podate=moment(this.materialRECEIPTDATA.purchase_order_date).format("YYYY-MM-DD");
        this.model1.companyname=this.materialRECEIPTDATA.company_name
        this.model1.vendorname=this.materialRECEIPTDATA.vendor_name
        this.model1.invoicenumber=this.materialRECEIPTDATA.invoice_number
        this.materialcode=this.materialRECEIPTDATA.material_code
        this.model1.receiveddateee=moment(this.materialRECEIPTDATA.qc_sent_date).format("YYYY-MM-DD"),
        this.model1.dmr_created_date=moment(this.materialRECEIPTDATA.dmr_created_date).format("YYYY-MM-DD"),
        this.model1.dmr_date=moment(this.materialRECEIPTDATA.dmr_date).format("YYYY-MM-DD")     
      }
    })
  }
  
  openactivitylog(data:any){
    this.dialog.open(data,{
      width:'900px'
    })
  }
  onChange() {
    this.searchData="" 
    if(!this.searchData){
      this.getdata()
    }   
  // this.searchData=this.columnname
  // let selectedColumn=this.searchData
  }
  @ViewChild('masterpaginator') masterpaginator:MatPaginator;
  search(){
    if(this.searchData.length > 2){
      if(this.masterpaginator){
        this.masterpaginator.firstPage();
      }
      this.getdata()
    }
    if(!this.searchData){
      this.getdata()
      this.columnname="";
    }
  //  let obj= {"command":"lst","field":this.columnname ,"key":this.searchData,"lmt":this.pageSize,"pid":this.pageIndex}
  //   if(this.searchData.length>2){
  //     this.custservice. getSearchQualityCheck(obj).subscribe((res:any)=>{
  //       this.reaData=false;
  //       this.totalRecords = res?.count;
  //       this.dataSourcemain.data = res.data
  //       this.dataSourcemain  = new MatTableDataSource(res.data);
  //       if(res.data.length==0){
  //        this.reaData = true
  //      }
  //     })
  //   }else if(!this.searchData){
  //     this.getdata()
  //     this.columnname=""
  //   }
  }
  onChangepnd() {
    this.searchDatapnd=""
    if(!this.searchDatapnd){
      this.getdatapnd()
    }    
  }
  @ViewChild('masterpaginatorpnd') masterpaginatorpnd:MatPaginator;
  searchpnd(){
    if(this.searchDatapnd.length > 2){
      if(this.masterpaginatorpnd){
        this.masterpaginatorpnd.firstPage();
      }
      this.getdatapnd()
    }
    if(!this.searchDatapnd){
      this.getdatapnd();
      this.columnnamepnd="";
    }
    // let obj= {"command":"pnd","field":this.columnnamepnd ,"key":this.searchDatapnd,"lmt":this.pageSize,"pid":this.pageIndex}
    //  if(this.searchDatapnd.length>2){
    //    this.custservice. getSearchQualityCheck(obj).subscribe((res:any)=>{
    //      this.reaDatapnd=false;
    //      this.totalRecordspnd = res?.count;
    //      this.dataSourcemainpnd.data = res.data
    //      this.dataSourcemainpnd  = new MatTableDataSource(res.data);
    //      if(res.data.length==0){
    //       this.reaDatapnd = true
    //     }
    //    })
    //  }else if(!this.searchData){
    //   this.getdatapnd();
    //    this.columnname=""
    //  }
   }
  BacktoList(){
     this.demo1TabIndex=2;
     this.MATDATA=null
      this.model1={}
     this.QC_QTY=[]
     this.ACC_QTY=[]
     this.REJ_QTY=[]
     this.ITEMREMARKS=[]
     this.materialCODE=[]
     this.matDESCRIPTION=[]
     this.UOM=[]
     this.SNO=[]
     this.model1.ponumber=null
     this.model1.podate=null
     this.model1.companyname=null
     this.model1.vendorname=null
     this.model1.invoicenumber=null
     this.materialcode=null
     this.model1.dmrnumber=null
     this.viewData=false
     this.initialdata=false;
  }

  //select dmr
  opendmrmodel(data: any) {
    // this.dmrnumber=[]
    this.getMaterialdata();
    this.dialog.open(data, {
      width: '600px'
    })
    this.dmrnumber =""
  }

  submitdmrno(fr:any){
    let obj={
      command: "set",
       key: this.dmrnumber,
        field: "number"
    }
    this.custservice.getQualityCheckdmrdata(obj).subscribe((res:any)=>{
      if(res){
        this.dialog.closeAll()
        this.MATDATA=res.data  
        this.model1.dmrnumber=this.dmrnumber
        this.materialRECEIPTDATA=res.data[0]
        this.model1.dmrdateee=moment(this.materialRECEIPTDATA.dmr_date).format("YYYY-MM-DD");
        this.model1.ponumber=this.materialRECEIPTDATA.purchase_order_number
        this.model1.podate=moment(this.materialRECEIPTDATA.purchase_order_date).format("YYYY-MM-DD");
        this.model1.companyname=this.materialRECEIPTDATA.company_name
        this.model1.vendorname=this.materialRECEIPTDATA.vendor_name
        this.model1.invoicenumber=this.materialRECEIPTDATA.invoice_number
        this.materialcode=this.materialRECEIPTDATA.material_code
        this.model1.receiveddateee=moment(this.materialRECEIPTDATA.qc_sent_date).format("YYYY-MM-DD"),
        this.model1.dmr_created_date=moment(this.materialRECEIPTDATA.dmr_created_date).format("YYYY-MM-DD"),
        this.model1.dmr_date=moment(this.materialRECEIPTDATA.dmr_date).format("YYYY-MM-DD")
      }
    })
  }
  viewAttachment(data:any,number:any){
    console.log(number);
    
    this.dialog.open(data, {
      width: '800px'
    })
    this.selectedDMR = number
    this.getexistinguploadedfiles(number)
  }
  viewAttachment1(data:any,row:any){
    // console.log(row);
    
    this.dialog.open(data, {
      width: '800px'
    })
    this.selectedDMR = row.dmr_number
    this.getexistinguploadedfiles(row.dmr_number)
  }
  getexistinguploadedfiles(number:any) {
    let params = new HttpParams();
    params = new HttpParams()
      .set("document_number", number)
      .set("document_type", "Daily Material Receipt")
    this.custservice.getexistingfies(params).subscribe((res: any) => {
      if (res && res['status_code'] == '200') {
        this.filedataDMR = res.data   
      } else {
        this.filedataDMR = []
      }
    })
  }
  viewDocDMR(file: any) {
    // const url = this.imageUrl + '/' + file.file_path;
const url =  file.file_path;
    window.open(url, '_blank');
  }
  closemodel(){
    this.dialog.closeAll()
  }
  clearpage1(){
    this.MATDATA=null
    this.model1={}
   this.QC_QTY=[]
   this.ACC_QTY=[]
   this.REJ_QTY=[]
   this.ITEMREMARKS=[]
   this.materialCODE=[]
   this.matDESCRIPTION=[]
   this.UOM=[]
   this.SNO=[]
   this.model1.ponumber=null
   this.model1.podate=null
   this.model1.companyname=null
   this.model1.vendorname=null
   this.model1.invoicenumber=null
   this.materialcode=null
   this.model1.dmrnumber=null
   this.viewData=false
   this.initialdata=false;
   this.btn="Save"
    if(this.model1.dateee ==null || this.model1.dateee =='' || this.model1.dateee == undefined){
    this.model1.dateee =moment(new Date()).format("YYYY-MM-DD")
   }
   this.getTransactionId();
   this.filedataDMR=[];
   this.selectedfiles = [];
   this.filedataDMR = [];
  }

    openNewFileUploadModal(data: any) {
      this.dialog.open(data, {
        width: '800px'
      })
    }
    deleteFileItem(index:any){
      this.selectedfiles.splice(index,1)
     }
    //  deleteExistingFileItem(index:any){
    //   this.filedataDMR.splice(index,1)
    //  }

     onTabChanged(ev:any){
      if(this.demo1TabIndex===1){
        this.columnnamepnd=""
        this.searchDatapnd=""
         if(this.model1.dateee ==null || this.model1.dateee =='' || this.model1.dateee == undefined){
          this.model1.dateee =moment(new Date()).format("YYYY-MM-DD")
         }
        this.getdatapnd();
        this.dataSourcemainpnd.sort = this.sort;
      }
      else if(this.demo1TabIndex === 2){
        this.columnname=""
        this.searchData=""
         if(this.model1.dateee ==null || this.model1.dateee =='' || this.model1.dateee == undefined){
          this.model1.dateee =moment(new Date()).format("YYYY-MM-DD")
         }
        this.getdata();
        this.dataSourcemain.sort = this.sort;
      }
      else{
        this.logsearchData=""
         if(this.model1.dateee ==null || this.model1.dateee =='' || this.model1.dateee == undefined){
          this.model1.dateee =moment(new Date()).format("YYYY-MM-DD")
         }
      }
    }
}
