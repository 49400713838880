import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AlertCallsService } from 'src/app/auth/alert-calls.service';
import { CustomerService } from 'src/app/services/customer.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'app-goodsreceipt',
  templateUrl: './goodsreceipt.component.html',
  styleUrls: ['./goodsreceipt.component.scss']
})
export class GoodsreceiptComponent implements OnInit {
  @ViewChild('grnpaginatorpnd') grnpaginatorpnd:MatPaginator;
  @ViewChild('grnpaginatorcmp') grnpaginatorcmp:MatPaginator;
  @ViewChild('grnpaginatorallpnd') grnpaginatorallpnd:MatPaginator;
  activityLogDownload: any = [];
  @ViewChild('TABLELog', { static: false }) TABLELog: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('masterpaginator') masterpaginator:MatPaginator;
  @ViewChild('masterpaginatorhold') masterpaginatorhold:MatPaginator;
  @ViewChild('masterpaginator1') masterpaginator1:MatPaginator;
  dataSourcemain = new MatTableDataSource();
  dataSourcemaingrnlistofhold= new MatTableDataSource();
  dataSourcemaingrnpnd = new MatTableDataSource();
  displayedColumns: any[] = [
    'sno',
    'grn_number',
    'dmr_number',
    'date',
    'purchase_order_number',
    'purchase_order_date',
    'company_name',
    'vendor_name',
    'invoice_number',
    'count',
    'value',
    // 'ActiveStatus',
    'action'

  ];
  displayedColumnsgrnpnd: any[] = [
    'sno',
    'qc_sent_date',
    'dmr_number',
    'dmr_date',
    'qc_number',
    'qc_date',
    'qc_release_date',
    'purchase_order_number',
    'company_name',
    'vendor_name',
    'count',
    'action'

  ];
  activitylogcolumns: any[] = [
    "sno",
    "username",
    "created_date",
    "reference_number",
    "description",
    "remarks",
    // "reason",
    "action",
    'download',
  ];
  reaDatalog: boolean;
  totalRecordslog: any;
  dataSourcemainlog = new MatTableDataSource();
  panelOpenState = false;
  goodsreceptdata: any;
  dmrDATA: any;
  model: any = {}
  selectedDMR: any;
  selectionList: any;
  addeddmrlist: any[] = [];
  finallist: any;
  adddmrlist1: any;
  dmrnumbers: any[] = []
  companyname: any[] = []
  fileUploadUrlsgr:any[]=[]
  storageData: any;
  valutiondata: any;
  showbtn: any = false;
  object: any;
  model1: any = {}
  deletefile: any;
  deletemodel: any = {}
  pageIndex: any = 1;
  pageSize: any = 10;
  totalRecords: any = 0
  tab: any;
  demo1TabIndex: any = 0;
  btn: any = "Save"
  purchaseorderdata: any;
  ponumber: any;
  dateee: any;
  dialogRef: any = null;
  grnumber: any;
  filedata: any[]=[];
  createNewFile: any = {}
  imageUrl = environment.base_url
  deleteid: any;
  fileUploadUrls: any[] = []
  resultgrnumber: any;
  selectedfiles: any[] = []
  filenamearray: any[] = []
  editednumber: any;
  logdata: any;
  editdataa: any;
  Initialdata: any = true;
  editeddata: any = false;
  totalPrice: number;
  initialdata: any = false;
  columnname: any="";
  searchData: any;
  documnet_nodata: any;
  logsearchData: any;
  viewData: boolean = false;
  selectedstorage1: any;
  invoice_number: any[] = [];
  invoice_date: any[] = [];
  vehicle_number: any[] = [];
  SNO: any[] = [];
  number: any;
  dmr_number: any[] = [];
  lr_date: any[] = [];
  lr_number: any[] = [];
  dc_date: any[] = [];
  dc_number: any[] = [];
  way_bill_number: any[] = [];
  gate_entry_date: any[] = [];
  gate_entry_number: any[] = [];
  ReqQTY: any;
  qty: any;
  editModel: any = {};
  updatebtn: boolean = false;
  comments: any[] = [];
  totalRecordsgrnpnd: any;
  columnnamegrnpnd: any="";
  searchDatagrnpnd: any;
  filedataDMR: any;
  reaDatagrnpnd: boolean;
  today: any
  txnId: any;
  selectedPurchaseOderNumber: any;
  selectedvendor: any;
  vendorData: any[] = [];
  editData: boolean=false;
  searchDatagrnlistofhold: any;
  columnnamegrnlistofhold: any="";
  totalRecordsgrnlistofhold: any;
  holdfile: any;
  holdmodel: any={};
  grnnumber: any;
  base64Pdfdata: string;
  purchase_order_date: any[]=[];
  showChangemode: boolean;
  adddmrpoup: boolean;
  dmrnumber1: any;
  pendingfiles: boolean;
  dmrnumberdata: any;
  totalcompletedlistcount: any;
  logpageIndex: any=1;
  logpageSize: any=10;
  pageIndex1: any=1;
  pageSize1: any=10;
  pageSizehold: any=10;
  pageIndexhold: any=1;
  shouldSkipReset: boolean =false;

  //
  dataSourceListpnd = new MatTableDataSource();
  dataSourceListcmp = new MatTableDataSource();
  dataSourceListallpnd = new MatTableDataSource();
  displayedColumnsListpnd: any[] = [
    "sno",
    "MSRNnum",
    "document_name",
    "cmpnyName",
    "contractorName",
    "description",
    "level",
    "action",
  ];
  displayedColumnsListallpnd: any[] = [
    "sno",
    "MSRNnum",
    "document_name",
    "cmpnyName",
    "contractorName",
    "description",
    "level",
    "action",
  ];
  displayedColumnsListcmp: any[] = [
    "sno",
    "MSRNnum",
    "document_name",
    "cmpnyName",
    "contractorName",
    "description",
    "level",
    "action",
  ];
  pageIndexpnd: any = 0;
  pageSizepnd: any = 10;
  totalRecordspnd: any = 0;
  reaDatapnd: boolean;
  pageIndexallpnd: any = 0;
  pageSizeallpnd: any = 10;
  totalRecordsallpnd: any = 0;
  reaDataallpnd: boolean;
  pageIndexcmp: any = 0;
  pageSizecmp: any = 10;
  totalRecordscmp: any = 0;
  reaDatacmp: boolean;
  pndsearchnumber: any;
  allpndsearchnumber: any;
  cmpsearchnumber: any;
  //
  constructor(public custservice: CustomerService, public dialog: MatDialog,
    public alertcall: AlertCallsService,
    private route: ActivatedRoute,
    private router: Router, private injector: Injector) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
  }

  ngOnInit(): void {
    this.today = moment(new Date()).format("YYYY-MM-DD")
    this.dateee = moment(new Date()).format("YYYY-MM-DD")
    this.getpurchaseorderdata()
    this.route.queryParams.subscribe((params: any) => {
      if (params.tab === 'notifications') {
        this.demo1TabIndex = 2;
      } else {
        this.demo1TabIndex = 0;
      }
      if (params.tab === 'pendingApproval') {
        this.demo1TabIndex = 4;
        this.getDatapnd()
      } else if (params.tab === 'completedApproval') {
        this.demo1TabIndex = 5;
        this.getDatacmp()
      }else if (params.tab === 'allpendingApproval') {
        this.demo1TabIndex = 6;
        this.getDataallpnd()
      }
    });
    
    this.getgoodslist()
    this.getTransactionId();
    this.getgoodslistpnd();
    this.getlistofhold();
    // this.getMaterialdata()
    this.getstoragelocData()
    this.getvalutionData()
  }
  getTransactionId() {
    this.txnId = ""
    this.custservice.getTransactionId().subscribe((res: any) => {
      if (res) {
        this.txnId = res.txn_id
      }
    })
  }

  openfileuploadmodel(data: any, row1: any) {
    this.dialog.open(data, {
      width: '800px'
    })
    this.grnumber = row1.dmr_number
    this.resultgrnumber=row1.number
    this.getexistingfiles()
  }
  getexistingfiles() {
    let params = new HttpParams();
    params = new HttpParams()
      // .set("document_number", this.dmrnumberdata,)
      .set("document_number", this.grnumber,)
      .set("document_type", "Goods Receipt")
    // let obj={
    //   "document_number" : this.dmrnumber,
    //   "document_type": "Daily Material Receipt"
    // }
    this.custservice.getexistingfies(params).subscribe((res: any) => {
      if (res && res['status_code'] == '200') {
        this.filedata = res.data
        this.createNewFile.fileName = ''
      } else {
        this.filedata = []
        console.log(this.filedata);
      }
    })
  }
  viewDoc(file: any) {
    // const url = this.imageUrl + '/' + file.file_path;
    const url = file.file_path;
    window.open(url, '_blank');
  }
  deleterowfile(row: any, data: any) {
    this.deleteid = data.id
    this.dialogRef = this.dialog.open(row, {
      width: "400px"
    })
  }
  deleteexistingfile() {
    let params = new HttpParams()
    params = new HttpParams()
      .set("document_number", this.grnumber,)
      .set("document_type", "Goods Receipt")
      .set("id", this.deleteid)
    this.custservice.deletefiles(params).subscribe((res: any) => {
      if (res && res['status_code'] == '200') {
        this.alertcall.showSuccess("Accepted", "File Deleted Successfully")
        this.getexistingfiles()
        this.dialogRef.close()
      } else {
        this.alertcall.showWarning("Error", res['message'])
      }
    })
  }

  deleteFileItem(index:any){
    this.selectedfiles.splice(index,1)
   }

  uploadgifiles(fileInput: any) {
    if (
      fileInput &&
      fileInput.target &&
      fileInput.target.files &&
      fileInput.target.files.length > 0
    ) {
      
      this.fileUploadUrlsgr = fileInput.target.files;
      this.createNewFile.fileName = fileInput.target.files[0].name;
      for (const file of this.fileUploadUrlsgr) {
        // const existingFile = this.selectedfiles.find(selectedFile => selectedFile.name === file.name);
        // if (existingFile) {
        //   this.alertcall.showWarning('Alert', 'Some files are already selected');
        // } 
        if (this.isFileSelected1(file.name)) {
          this.alertcall.showWarning('Alert', 'Duplicate file: ' + file.name + ' already selected');
        }
         else {
          console.log(this.selectedfiles , 'this.selectedfiles');
          
          
          this.filenamearray.push(file.name);
          this.selectedfiles.push(file);
        }
      }
    }
    // this.getexistinguploadedfiles(this.resultgrnumber);
  }

  isFileSelected1(selectedFilePath: string): boolean {
    return this.selectedfiles.some((file: any) => file.name === selectedFilePath);
  }

  uploadselectedfiles() {
    const postData = new FormData();
    postData.append("document_type", "Goods Receipt");
    postData.append("document_number", this.dmrnumberdata);
  
    const uniqueFileNames = new Set(); 
  
    for (const file of this.selectedfiles) {
     
      if (!uniqueFileNames.has(file.name)) {
        postData.append("doc", file);
        uniqueFileNames.add(file.name); 
      }
    }
  
    this.custservice.addfileupload(postData).subscribe((res: any) => {
      if (res && res["status_code"] == "200") {
        this.fileUploadUrlsgr=[]
        this.filenamearray = [];
        this.selectedfiles = [];
      } else {
        
      }
    });
  }
  uploadWbsFile(fileInput: any) {
    if (
      fileInput &&
      fileInput.target &&
      fileInput.target.files &&
      fileInput.target.files.length > 0
    ) {
      this.fileUploadUrls = fileInput.target.files;
      this.createNewFile.fileName = fileInput.target.files[0].name;
  
      const postData = new FormData();
      postData.append("document_type", "Goods Receipt");
      postData.append("document_number", this.grnumber);
  
      let hasDuplicates = false; 
  
      for (const file of this.fileUploadUrls) {
        if (this.filedata && this.filedata.length > 0 && this.isFileSelected1(file.name)) {
          hasDuplicates = true;
          break; 
        } else {
          postData.append("doc", file);
        }
      }
  
      if (hasDuplicates) {
        this.alertcall.showWarning('Alert', 'Some files are already uploaded');
      } else {
        this.custservice.addfileupload(postData).subscribe((res: any) => {
          if (res && res['status_code'] == '200') {
            this.alertcall.showSuccess("Accepted", res['message'])
            this.getexistingfiles()
            this.fileUploadUrls = []
          } else {
            this.alertcall.showWarning("Error", res['message'])
          }
          fileInput.target.value = '';
        });
      }
    }
  }
  // uploadWbsFile(fileInput: any) {
  //   if (
  //     fileInput &&
  //     fileInput.target &&
  //     fileInput.target.files &&
  //     fileInput.target.files.length > 0
  //   ) {
  //     this.fileUploadUrls = fileInput.target.files;
  //     this.createNewFile.fileName = fileInput.target.files[0].name;
  
  //     const postData = new FormData();
  //     postData.append("document_type", "Goods Receipt");
  //     postData.append("document_number", this.grnumber);
  
  //     let hasDuplicates = false; 
  
  //     for (const file of this.fileUploadUrls) {
  //       if (this.filedata && this.filedata.length > 0 && this.isFileSelected1(file.name)) {
  //         hasDuplicates = true;
  //         break; 
  //       } else {
  //         postData.append("doc", file);
  //       }
  //     }

  //     if (hasDuplicates) {
  //       this.alertcall.showWarning('Alert', 'Some files are already uploaded');
  //     } else {
  //       this.custservice.addfileupload(postData).subscribe((res: any) => {
  //         if (res['status_code'] == '200' && this.fileUploadUrls.length === 1) {
  //       this.alertcall.showSuccess("Accepted", "File is Uploaded Successfully")
  //       this.getexistingfiles()
  //       this.fileUploadUrls = []
  //     }else if(res['status_code'] == '200' && this.fileUploadUrls.length>1){
  //       this.alertcall.showSuccess("Accepted", "Files are Uploaded Successfully")
  //       this.getexistingfiles()
  //       this.fileUploadUrls = []
  //     } 
  //     else {
  //       this.alertcall.showWarning("Error", res['message'])
  //     }
  //       });
  //     }
  //   }
  // }

  printFormats(row:any){
    Swal.fire({
      title: '<strong>Print Format</strong>',
      icon: 'info',
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      cancelButtonText: 'Close',
      html: `
        <button id="print1" class="swal2-confirm swal2-styled" style="margin-right: 10px;">
          <i class="fa fa-print"></i> Print1
        </button>
        <button id="print2" class="swal2-confirm swal2-styled">
          <i class="fa fa-print"></i> Print2
        </button>
      `,
      didOpen: () => {
        const print1Button = document.getElementById('print1') as HTMLButtonElement;
        const print2Button = document.getElementById('print2') as HTMLButtonElement;
        if (print1Button) {
          print1Button.addEventListener('click', () => {
            Swal.close();
            this.printdmr(row);
          });
        }
    
        if (print2Button) {
          print2Button.addEventListener('click', () => {
            Swal.close();
            this.printgoodsreceipt2(row);
          });
        }
      }
    });
  }
  printFormats1(row: any) {
  let params = new HttpParams()
      params = params.append('number', row.number)
       this.custservice.generateStoresPdf(params).subscribe((res: any) => {
        if(res['status'] == 'Success'){
          const data = res.base64;
          // console.log(res.base64Pdf);
          this.base64Pdfdata = res.base64;      
         setTimeout(() => {
           this.openPdfInNewTab(data);
         }, 10);
        }
  })
   }
   printFormats2(row: any) {
    let params = new HttpParams()
        params = params.append('number', row.number)
         this.custservice.generateStoresPdf2(params).subscribe((res: any) => {
          if(res['status'] == 'Success'){
            const data = res.base64;
            // console.log(res.base64Pdf);
            this.base64Pdfdata = res.base64;
           setTimeout(() => {
             this.openPdfInNewTab(data);
           }, 10);
          }
    })
     }

  openPdfInNewTab(base64Content:any) {
    return new Promise<void>((resolve, reject) => {
      const byteArray = new Uint8Array(atob(base64Content).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      // document.querySelector("iframe").src = url;
      resolve();
      window.open(url, '_blank');
    }).catch(error => {
      console.error('Error displaying PDF:', error);
    });
  }
  printdmr(data: any) {
    // this.getPrintStatus(data);  
    this.router.navigate(['/inventory/printdmr'], { queryParams: { 'dmrumber': data.number, 'doc_sign': data.doc_sign } })
    console.log(data);
    
    // let navigationExtras: NavigationExtras = {
    //   queryParams: {
    //     "dmrnumber": data.number
    //   } 
    // };
    // const dialogRef = this.dialog.open(PrintdmrComponent, {data: {'dmrumber': data.number} 
    // });
    //     const url = this.router.serializeUrl(
    //       this.router.createUrlTree(['/inventory/printdmr'],{ queryParams: {'dmrumber': data.number}}))
    //  window.open(url,"_blank")
    // this.router.navigate(['/inventory/printdmr'], { queryParams: { 'dmrumber': data.number,'doc_sign':data.doc_sign } })
    // this.dialog.open(PrintdmrComponent,{
    //   width:"500px",
    //   height:"auto",
    //   data:data.number
    // })

  }
  printgoodsreceipt2(data:any){
    this.router.navigate(['/inventory/printgoodsreceipt2'], { queryParams: { 'dmrumber': data.number, 'doc_sign': data.doc_sign } })
  }
  // getPrintStatus(data:any) {
  //   const postData = new FormData();
  //   postData.append("command","mat");
  //   postData.append("document_number", data.number);
  //   postData.append("document_name", "GoodsReceipt" );
  //   this.custservice.DocumentSign(postData).subscribe((res: any) => {
  //     if(res&& res.print_status === true){
  //       this.router.navigate(['/inventory/printdmr'], { queryParams: { 'dmrumber': data.number,'doc_sign':data.doc_sign } }) 
  //     }else{
  //       this.alertcall.showWarning("Warning", "Not Approved,Unable to Print");
  //     } 
  //   });
  // }
  gettotalprice(data: any) {
    let sum = 0
    data.forEach((ele: any) => {
      sum += ele.total_price
    });

    return sum
  }
  onpageevent(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if(!this.searchData){
    this.getgoodslist()
    }
    else{
      this.search();
    }
  }
  deleteItem(row: any, data: any) {
    this.deletefile = row.number
    this.dialog.open(data, {
      width: '400px'
    })
    this.deletemodel.reason = "";
  }
  deleteFile() {
    let obj = {
      "command": "del",
      "number": this.deletefile,
      "reason": this.deletemodel.reason,
      "txn_id": this.txnId,
    }
    this.custservice.deletegoodsreceiptlist(obj).subscribe((res: any) => {
      if (res && res['status_code'] == '200') {
        this.alertcall.showSuccess('Accepted', res['message']);
        this.dialog.closeAll()
        this.deletemodel.reason = ""
        this.getTransactionId();
        this.getgoodslist()
        this.getgoodslistpnd()
        this.getlistofhold()
      } else {
        this.alertcall.showWarning('Accepted', res['message']);
      }

    })
  }
  savefinaldata() {
    if (this.btn === "Save") {
      console.log(this.addeddmrlist);
      this.addeddmrlist.forEach((ele: any, i: any) => {
        ele.forEach((element: any) => {
          console.log(element);
          element.gate_entry_date = this.addeddmrlist[i].gate_entry_date
          element.invoice_date = this.addeddmrlist[i].invoice_date
          element.comments = this.addeddmrlist[i].comments
          element.lr_date = this.addeddmrlist[i].lr_date
          element.lr_number = this.addeddmrlist[i].lr_number
          element.dc_date = this.addeddmrlist[i].dc_date
          element.dc_number = this.addeddmrlist[i].dc_number
          element.gate_entry_number = this.addeddmrlist[i].gate_entry_number
          element.transporter_name = this.addeddmrlist[i].transporter_name
          element.vehicle_number = this.addeddmrlist[i].vehicle_number
          element.way_bill_number = this.addeddmrlist[i].way_bill_number
          element.invoice_number = this.addeddmrlist[i].invoice_number
          element.miscellaneous_charges_description = this.addeddmrlist[i].miscellaneous_charges_description
          element.miscellaneous_charges_value = this.addeddmrlist[i].miscellaneous_charges_value
          element.purchase_order_date =this.addeddmrlist[i].purchase_order_date
          this.dmrnumbers.push(element.dmr_number)
          this.companyname.push(element.company_name)
        });

      })



      // this.finallist=this.addeddmrlist
      // let i=0;
      // this.addeddmrlist[i].forEach((element: any) => {
      //   element.gate_entry_date = this.addeddmrlist[i].gate_entry_date
      //   // this.gate_entry_date=(element.gate_entry_date);
      //   console.log(element);
      //   this.dmrnumbers.push(element.dmr_number)
      //   this.companyname.push(element.company_name)

      //   console.log(this.companyname);
      //   i++;
      //   // this.gate_entry_date = ["2023-06-01"]
      // });
      // console.log(this.gate_entry_date);

      let obj = {
        "dmr_number": this.addeddmrlist,
        "company_name": this.companyname[0],
        "date": moment(this.dateee).format("YYYY-MM-DD"),
        "txn_id": this.txnId,
        "command": "add",
      }
      // console.log(obj);

      this.custservice.addfinalgoodsreceiptlist(obj).subscribe((res: any) => {
        console.log(res);
        if (res && res['status_code'] == '200') {
          // this.alertcall.showSuccess('Accepted', res['message']);
          Swal.fire({
            text: res['message'],
            title: res['reference'],
            icon: 'success',
            // title: res['reference'],
            width: 500,
          }).then(() => {
            this.router.navigate(['/inventory/printdmr'], { queryParams: { 'dmrumber': res['reference'] } })
          });
          this.dmrnumberdata=this.addeddmrlist[0].dmr_number
          this.model1.selectedDMR = ''
          this.model1.selectionList = ''
          this.addeddmrlist = []
          this.showbtn = false;
          this.getTransactionId();
          this.getgoodslist()
          this.getgoodslistpnd()
          this.getlistofhold()
          this.resultgrnumber = res['reference']       
          
          // if (this.fileUploadUrlsgr.length > 0) {
          //   this.uploadselectedfiles()
          // }
          if(this.fileUploadUrlsgr.length > 0){
            this.uploadselectedfiles()
          }
        } else {
          this.alertcall.showWarning('Accepted', res['message']);
          //  this.saveformdata("")
    
        }


      })
    }else if(this.btn === 'Release'){
      this.addeddmrlist.forEach((ele: any, i: any) => {
        ele.forEach((element: any) => {
          console.log(element);
          element.gate_entry_date = this.addeddmrlist[i].gate_entry_date
          element.invoice_date = this.addeddmrlist[i].invoice_date
          element.comments = this.addeddmrlist[i].comments
          element.lr_date = this.addeddmrlist[i].lr_date
          element.lr_number = this.addeddmrlist[i].lr_number
          element.dc_date = this.addeddmrlist[i].dc_date
          element.dc_number = this.addeddmrlist[i].dc_number
          element.gate_entry_number = this.addeddmrlist[i].gate_entry_number
          element.transporter_name = this.addeddmrlist[i].transporter_name
          element.vehicle_number = this.addeddmrlist[i].vehicle_number
          element.way_bill_number = this.addeddmrlist[i].way_bill_number
          element.invoice_number = this.addeddmrlist[i].invoice_number
          element.miscellaneous_charges_description = this.addeddmrlist[i].miscellaneous_charges_description
          element.miscellaneous_charges_value = this.addeddmrlist[i].miscellaneous_charges_value
          element.purchase_order_date =this.addeddmrlist[i].purchase_order_date
          this.dmrnumbers.push(element.dmr_number)
          this.companyname.push(element.company_name)
        });

      })
      let obj = {
        "number":this.grnnumber,
        "dmr_number": this.addeddmrlist,
        "company_name": this.companyname[0],
        "date": moment(this.dateee).format("YYYY-MM-DD"),
        "txn_id": this.txnId,
        "command": "rel",
      }
      // console.log(obj);

      this.custservice.addfinalgoodsreceiptlist(obj).subscribe((res: any) => {
        console.log(res);
        if (res && res['status_code'] == '200') {
          // this.alertcall.showSuccess('Accepted', res['message']);
          Swal.fire({
            text: res['message'],
            title: res['reference'],
            icon: 'success',
            // title: res['reference'],
            width: 500,
          }).then(() => {
            this.router.navigate(['/inventory/printdmr'], { queryParams: { 'dmrumber': res['reference'] } })
          });
          this.model1.selectedDMR = ''
          this.model1.selectionList = ''
          this.addeddmrlist = []
          this.showbtn = false;
          this.getTransactionId();
          this.getgoodslist()
          this.getgoodslistpnd()
          this.getlistofhold()
          this.resultgrnumber = res['reference']
          // if (this.fileUploadUrlsgr.length > 0) {
          //   this.uploadselectedfiles()
          // }
          if(this.fileUploadUrlsgr.length > 0){
            this.uploadselectedfiles()
          }
          this.btn='Save'
        } else {
          this.alertcall.showWarning('Accepted', res['message']);
          //  this.saveformdata("")

        }


      })
    }else {
      console.log(this.editdataa.invoice_date);

      let obj = {
        "po_number":this.ponumber,
        "dmr_number": [this.editdataa.dmr_number],
        "company_name": this.editdataa.company_name,
        "date": moment(this.dateee).format("YYYY-MM-DD"),
        "command": "edt",
        "txn_id": this.txnId,
        "number": this.editednumber
      }

      this.custservice.addfinalgoodsreceiptlist(obj).subscribe((res: any) => {
        console.log(res);
        this.dmrnumber1=this.editdataa.dmr_number    
        if (res && res['status_code'] == '200') {
          // this.alertcall.showSuccess('Accepted', res['message']);
          Swal.fire({
            text: res['message'],
            title: res['reference'],
            icon: 'success',
            // title: res['reference'],
            width: 500,
          });
          this.model1.selectedDMR = ''
          this.model1.selectionList = ''
          this.addeddmrlist = []
          this.editdataa = ""
          this.Initialdata = true;
          this.editeddata = false;
          this.showbtn = false;
          this.btn = "Save"
          this.getTransactionId();
          this.getgoodslist()
          this.getgoodslistpnd()
          this.getlistofhold()
          this.resultgrnumber = this.editednumber
          // if (this.fileUploadUrlsgr.length > 0) {
          //   this.uploadselectedfiles()
          // }
          if(this.fileUploadUrlsgr.length > 0){
            this.uploadselectedfiles()
          }

        } else {
          this.alertcall.showWarning('Accepted', res['message']);
          //  this.saveformdata("")

        }


      })
    }
  }
  openNewFileUploadModal(data: any) {
    this.dialog.open(data, {
      width: '800px'
    })
  }
  getvalutionData() {
    let obj = {
      "lmt": 100000,
      'pid': 1,
      "command": "lst",
      "key": ""
    }
    this.custservice.getvalutiondata(obj).subscribe((res: any) => {
      this.valutiondata = res.data

      // setTimeout(()=>{
      //   this.dataSourcevalution.paginator = this.paginatorvalue;
      //  })  
    })
  }
  getstorageDATA1(ev: any) {
    console.log(ev.target.value);
    this.selectedstorage1 = ev.target.value
    if (this.selectedstorage1.length > 2) {
      this.getstoragelocData()
    }
  }
  getstoragelocData() {
    let obj = {
      "command": "mat",
      "field": "storage_location",
      "key": ""
    }
    this.custservice.getmatstoragelocdata(obj).subscribe((res: any) => {
      this.storageData = res.data
      console.log(this.storageData);



    })
  }
  getselecteddata() {
    console.log(this.model1.selectedDMR);
    this.model1.selectionList = this.model1.selectedDMR
  }
  closemodel() {
    this.dialog.closeAll()
  }
  getselectedpo() {
    console.log(this.ponumber);

  }
  getMaterialdata() {
    console.log(this.ponumber);

    let obj = {
      "command": "dmr",
      "purchase_order_number": this.ponumber,
      // "lmt" : 100000,
      // "pid" : 1,
      // "key" : ""
    }
    this.custservice.getgoodsreceiptdmrlist(obj).subscribe((res: any) => {
      this.dmrDATA = res.data
    })
  }
  saveformdata(fr: any) {
    let obj = {
      "dmr_number": this.model1.selectedDMR,
      "command": "set"
    }
    this.custservice.addgoodsreceiptlist(obj).subscribe((res: any) => {
      // console.log(res);
      if (res) {
        if (res.data.length > 0) {
          // this.editdataa = res.data[0][0]
          this.addeddmrlist = res.data
          console.log(this.addeddmrlist);
          console.log(this.addeddmrlist[0]);
          this.showbtn = true; 
          this.Initialdata = true;
          this.viewData=false
          this.editeddata = false;     
          fr.reset()
          //  this.selectedDMR=''
          // this.selectionList=''
          this.dialog.closeAll()
          this.addeddmrlist.forEach((element: any, i: any) => {
            element.forEach((ele: any) => {
              // console.log(ele.dmr_number);
              this.addeddmrlist[i].dmr_number = ele.dmr_number
              this.addeddmrlist[i].qc_number = ele.qc_number
              this.addeddmrlist[i].esdn_number = ele.esdn_number
              this.addeddmrlist[i].vendor_name = ele.vendor_name
              this.addeddmrlist[i].qc_date = moment(ele.qc_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].invoice_date = moment(ele.invoice_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].gate_entry_date = moment(ele.gate_entry_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].lr_date = moment(ele.lr_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].dc_date = moment(ele.dc_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].purchase_order_date = moment(ele.purchase_order_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].comments = ele.comments
              this.addeddmrlist[i].lr_number = ele.lr_number
              this.addeddmrlist[i].dc_number = ele.dc_number
              this.addeddmrlist[i].gate_entry_number = ele.gate_entry_number
              this.addeddmrlist[i].transporter_name = ele.transporter_name
              this.addeddmrlist[i].vehicle_number = ele.vehicle_number
              this.addeddmrlist[i].way_bill_number = ele.way_bill_number
              this.addeddmrlist[i].invoice_number = ele.invoice_number
              this.addeddmrlist[i].miscellaneous_charges_description = ele.miscellaneous_charges_description
              this.addeddmrlist[i].miscellaneous_charges_value = ele.miscellaneous_charges_value
            });

          })
          // console.log( this.addeddmrlist[0].invoice_date);

        } else {
          // this.editdataa = res.data[0][0]
          this.addeddmrlist = res.data
          this.showbtn = false;
          this.Initialdata = true;
          this.viewData=false
          this.editeddata = false;
          this.addeddmrlist.forEach((element: any, i: any) => {
            element.forEach((ele: any) => {
              console.log(ele.dmr_number);
              this.addeddmrlist[i].dmr_number = ele.dmr_number
              this.addeddmrlist[i].qc_number = ele.qc_number
              this.addeddmrlist[i].esdn_number = ele.esdn_number
              this.addeddmrlist[i].vendor_name = ele.vendor_name
              this.addeddmrlist[i].qc_date = moment(ele.qc_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].invoice_date = moment(ele.invoice_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].gate_entry_date = moment(ele.gate_entry_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].lr_date = moment(ele.lr_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].dc_date = moment(ele.dc_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].purchase_order_date = moment(ele.purchase_order_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].comments = ele.comments
              this.addeddmrlist[i].lr_number = ele.lr_number
              this.addeddmrlist[i].dc_number = ele.dc_number
              this.addeddmrlist[i].gate_entry_number = ele.gate_entry_number
              this.addeddmrlist[i].transporter_name = ele.transporter_name
              this.addeddmrlist[i].vehicle_number = ele.vehicle_number
              this.addeddmrlist[i].way_bill_number = ele.way_bill_number
              this.addeddmrlist[i].invoice_number = ele.invoice_number
              this.addeddmrlist[i].miscellaneous_charges_description = ele.miscellaneous_charges_description
              this.addeddmrlist[i].miscellaneous_charges_value = ele.miscellaneous_charges_value
            });

          })
        }

      }


    })
  }

  CreateNewDoc(data: any) {
    let obj = {
      "dmr_number": [data.dmr_number],
      "command": "set"
    }
    this.custservice.addgoodsreceiptlist(obj).subscribe((res: any) => {
      // console.log(res);
      if (res) {
        if (res.data.length > 0) {
          // this.editdataa = res.data[0][0]
          this.addeddmrlist = res.data
          this.dmrnumber1=data.dmr_number     
          this.demo1TabIndex = 0;
          this.Initialdata = true;
          this.viewData=false
          this.editeddata = false;
          this.shouldSkipReset = true;
          console.log(this.addeddmrlist);
          console.log(this.addeddmrlist[0]);
          this.showbtn = true;
          this.pendingfiles=true
          this.getexistinguploadedfiles(data.dmr_number)
          //  this.selectedDMR=''
          // this.selectionList=''
          this.dialog.closeAll()
          this.addeddmrlist.forEach((element: any, i: any) => {
            element.forEach((ele: any) => {
              // console.log(ele.dmr_number);
              this.ponumber = ele.purchase_order_number
              this.dateee = moment(ele.date).format("YYYY-MM-DD")
              this.addeddmrlist[i].dmr_number = ele.dmr_number
              this.addeddmrlist[i].qc_number = ele.qc_number
              this.addeddmrlist[i].esdn_number = ele.esdn_number
              this.addeddmrlist[i].vendor_name = ele.vendor_name
              this.addeddmrlist[i].qc_date = moment(ele.qc_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].invoice_date = moment(ele.invoice_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].gate_entry_date = moment(ele.gate_entry_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].lr_date = moment(ele.lr_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].dc_date = moment(ele.dc_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].purchase_order_date = moment(ele.purchase_order_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].comments = ele.comments
              this.addeddmrlist[i].lr_number = ele.lr_number
              this.addeddmrlist[i].dc_number = ele.dc_number
              this.addeddmrlist[i].gate_entry_number = ele.gate_entry_number
              this.addeddmrlist[i].transporter_name = ele.transporter_name
              this.addeddmrlist[i].vehicle_number = ele.vehicle_number
              this.addeddmrlist[i].way_bill_number = ele.way_bill_number
              this.addeddmrlist[i].invoice_number = ele.invoice_number
              this.addeddmrlist[i].miscellaneous_charges_description = ele.miscellaneous_charges_description
              this.addeddmrlist[i].miscellaneous_charges_value = ele.miscellaneous_charges_value
              this.selectedfiles=[]
            });

          })
          // console.log( this.addeddmrlist[0].invoice_date);

        } else {
          // this.editdataa = res.data[0][0]
          this.addeddmrlist = res.data
          this.showbtn = false;
          this.demo1TabIndex = 0;
          this.Initialdata = true;
          this.editeddata = false;
          this.shouldSkipReset = true;
          this.addeddmrlist.forEach((element: any, i: any) => {
            element.forEach((ele: any) => {
              console.log(ele.dmr_number);
              this.ponumber = ele.purchase_order_number
              this.dateee = moment(ele.date).format("YYYY-MM-DD")
              this.addeddmrlist[i].dmr_number = ele.dmr_number
              this.addeddmrlist[i].qc_number = ele.qc_number
              this.addeddmrlist[i].esdn_number = ele.esdn_number
              this.addeddmrlist[i].vendor_name = ele.vendor_name
              this.addeddmrlist[i].qc_date = moment(ele.qc_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].invoice_date = moment(ele.invoice_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].gate_entry_date = moment(ele.gate_entry_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].lr_date = moment(ele.lr_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].dc_date = moment(ele.dc_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].purchase_order_date = moment(ele.purchase_order_date).format("YYYY-MM-DD")
              this.addeddmrlist[i].comments = ele.comments
              this.addeddmrlist[i].lr_number = ele.lr_number
              this.addeddmrlist[i].dc_number = ele.dc_number
              this.addeddmrlist[i].gate_entry_number = ele.gate_entry_number
              this.addeddmrlist[i].transporter_name = ele.transporter_name
              this.addeddmrlist[i].vehicle_number = ele.vehicle_number
              this.addeddmrlist[i].way_bill_number = ele.way_bill_number
              this.addeddmrlist[i].invoice_number = ele.invoice_number
              this.addeddmrlist[i].miscellaneous_charges_description = ele.miscellaneous_charges_description
              this.addeddmrlist[i].miscellaneous_charges_value = ele.miscellaneous_charges_value
            });

          })
        }

      }


    })
  }
  onOptionSelected(ev:any){
    this.selectedfiles=[]
  }
  
  deleterowItem(data: any, index: any) {
    if (data.length == 1) {
      data.splice(index, 1);
      this.model1.selectedDMR = ''
      // this.showbtn = false;
      // this.saveformdata("")

    } else {
      data.splice(index, 1);
    }


  }
  getgoodslist() {
    let obj = {
      "command": "lst",
      "pid": this.pageIndex,
      "lmt": this.pageSize,
    }
    this.custservice.getgoodsreceiptlist(obj).subscribe((res: any) => {

      this.goodsreceptdata = res.data
      this.dataSourcemain.data = res.data
      this.dataSourcemain.sort = this.sort
      this.totalcompletedlistcount= res?.count
      this.totalRecords = res.count
    }) 
  }
  onpageeventgrnpnd(event: any) {
    this.pageIndex1 = event.pageIndex + 1;
    this.pageSize1 = event.pageSize;
    if(!this.searchDatagrnpnd){
    this.getgoodslistpnd()
    }
    else{
      this.searchgrnpnd();
    }
  }
  getgoodslistpnd() {
    let obj = {
      "command": "pnd",
      "pid": this.pageIndex1,
      "lmt": this.pageSize1,
    }
    this.custservice.getgoodsreceiptlist(obj).subscribe((res: any) => {
      this.reaDatagrnpnd = false;
      this.goodsreceptdata = res.data
      this.totalRecordsgrnpnd = res?.count;
      this.dataSourcemaingrnpnd.data = res.data
      
      this.dataSourcemaingrnpnd = new MatTableDataSource(res.data);
      this.dataSourcemaingrnpnd.sort = this.sort;
      if (res.data.length == 0) {
        this.reaDatagrnpnd = true
      }
    })
  }
  addgoodsreceipt(data: any) {
    if (!this.ponumber ) {
      this.alertcall.showWarning('Error', "Please Select Purchase Order Number");
    }
    if(!this.dateee){
      this.alertcall.showWarning('Error', "Please Select  Valid Date");
    }
    else if (this.ponumber) {
      console.log("hello");
      // this.adddmrpoup=true
      this.dialog.open(data, {
        width: '600px'
      })
      this.getMaterialdata()

    }
  }
  PurchaseOrderdata(ev: any) {
    this.selectedPurchaseOderNumber = ev.target.value;
    if (this.selectedPurchaseOderNumber.length > 2) {
      this.getpurchaseorderdata();
    }
    if (!this.selectedPurchaseOderNumber) {
      this.getpurchaseorderdata();
    }
  }
  getpurchaseorderdata() {
    let obj = {
      "command": "por",
      "field":"purchase_order_number",
      "key": this.selectedPurchaseOderNumber
    }
    this.custservice.getgoodsreceiptlist(obj).subscribe((res: any) => {
      this.purchaseorderdata = res.data

    })
  }
  //vendor data
  getvendordropdowndata(ev: any) {
    console.log(ev.target.value);
    this.selectedvendor = ev.target.value;
    if (this.selectedvendor.length > 2) {
      this.getvendordata();
    }
    if (!this.selectedvendor) {
      this.getvendordata();
    }
  }
  getvendordata() {
    let obj = {
      command: "lst",
      lmt: 100000,
      key: this.selectedvendor || "",
    };
    this.custservice.getvendormasterdata(obj).subscribe((res: any) => {
      if (res) {
        this.vendorData = res.data;
      }
    });
  }
  viewdata(data: any) {
    this.Initialdata = false;
    this.editeddata = true;
    this.editednumber = data.number
    let obj = {
      command: "mat",
      field: "number",
      key: data.number
    }
    this.custservice.addgoodsreceiptlist(obj).subscribe((res: any) => {
      if (res) {
        this.viewData = true
        this.editData= false
        this.editdataa = res.data[0]
        this.addeddmrlist = res.data2
        this.showbtn = true
        this.updatebtn = false
        let sum = 0
        this.addeddmrlist.forEach((ele: any) => {
          sum += ele.total_price
        });
        this.totalPrice = sum
        // this.model1.dmrnumber=this.editdataa.dmr_number, 
        // this.model1.comments=this.editdataa.comments,     
        this.dateee = moment(this.editdataa.date).format("YYYY-MM-DD"),
          this.ponumber = this.editdataa.purchase_order_number,
          this.editdataa.invoice_date = moment(this.editdataa.invoice_date).format("YYYY-MM-DD")
        this.editdataa.lr_date = moment(this.editdataa.invoice_date).format("YYYY-MM-DD")
        // this.editdataa.dc_date=moment(this.editdataa.invoice_date).format("YYYY-MM-DD")
        // this.model1.podate=moment(this.editdataa.purchase_order_date).format("YYYY-MM-DD"),
        // this.model1.companyname=this.editdataa.company_name, 
        // this.model1.vendorname=this.editdataa.vendor_name, 
        // this.model1.invoicenumber=this.editdataa.invoice_number, 
        this.addeddmrlist.forEach((element: any, i: any) => {
          element.forEach((ele: any) => {
            console.log(ele.dmr_number);
            this.addeddmrlist[i].dmr_number = ele.dmr_number
            this.addeddmrlist[i].qc_number = ele.qc_number
            this.addeddmrlist[i].esdn_number = ele.esdn_number
            this.addeddmrlist[i].vendor_name = ele.vendor_name
            this.addeddmrlist[i].qc_date = moment(ele.qc_date).format("YYYY-MM-DD")
            this.addeddmrlist[i].invoice_date = moment(ele.invoice_date).format("YYYY-MM-DD")
            this.addeddmrlist[i].gate_entry_date = moment(ele.gate_entry_date).format("YYYY-MM-DD")
            this.addeddmrlist[i].lr_date = moment(ele.lr_date).format("YYYY-MM-DD")
            this.addeddmrlist[i].dc_date = moment(ele.dc_date).format("YYYY-MM-DD")
            this.addeddmrlist[i].purchase_order_date = moment(ele.purchase_order_date).format("YYYY-MM-DD")
            this.addeddmrlist[i].comments = ele.comments
            this.addeddmrlist[i].lr_number = ele.lr_number
            this.addeddmrlist[i].dc_number = ele.dc_number
            this.addeddmrlist[i].gate_entry_number = ele.gate_entry_number
            this.addeddmrlist[i].transporter_name = ele.transporter_name
            this.addeddmrlist[i].vehicle_number = ele.vehicle_number
            this.addeddmrlist[i].way_bill_number = ele.way_bill_number
            this.addeddmrlist[i].invoice_number = ele.invoice_number
            this.addeddmrlist[i].miscellaneous_charges_description = ele.miscellaneous_charges_description
            this.addeddmrlist[i].miscellaneous_charges_value = ele.miscellaneous_charges_value

          });

        })

        this.demo1TabIndex = 0;
        this.Initialdata = true;
        this.editeddata = false;
        this.shouldSkipReset = true;
        // this.btn="Update"
      }
    })
  }
  onChange() {
    console.log('Selected:', this.columnname);
    this.searchData = ""
    // this.searchData=this.columnname
    // let selectedColumn=this.searchData
  }
  search() {
    // let key = this.columnname
    // let obj={"command":"lst",[key]:this.searchData}
    // this.pageIndex=1;
    let obj = { "command": "lst", "field": this.columnname, "key": this.searchData, "lmt": this.pageSize, "pid": this.pageIndex,showLoader: 'hide' }
    // this.searchData=event.target.value;
    // if(this.searchData){
    //   this.masterpaginator1.firstPage();
    // }
    if (this.searchData.length > 2) {
      this.custservice.getgoodsreceiptlist(obj).subscribe((res: any) => {
        this.reaDatagrnpnd = false;
        this.goodsreceptdata = res.data
        this.totalcompletedlistcount = res?.count;
        this.dataSourcemain.data = res.data
        this.dataSourcemain = new MatTableDataSource(res.data);

        if (res.data.length == 0) {
          this.reaDatagrnpnd = true
        }
      })
    } else if (!this.searchData) {
      this.getgoodslist()
      this.columnname = ""
    }
  }

  onChangegrnpnd() {
    this.searchDatagrnpnd = ""
  }
  searchgrnpnd() {
    // this.pageIndex1=1;
    let obj = { "command": "pnd", "field": this.columnnamegrnpnd, "key": this.searchDatagrnpnd, "lmt": this.pageSize1, "pid": this.pageIndex1 ,showLoader: 'hide' }
    // if(this.searchDatagrnpnd){
    //   this.masterpaginator.firstPage();
    // }
    if (this.searchDatagrnpnd.length > 2) {
      this.custservice.getgoodsreceiptlist(obj).subscribe((res: any) => {
        this.goodsreceptdata = res.data
        this.dataSourcemaingrnpnd.data = res.data
        this.dataSourcemaingrnpnd = new MatTableDataSource(res.data);
        this.totalRecordsgrnpnd = res.count
      })
    } else if (!this.searchDatagrnpnd) {
      this.getgoodslistpnd()
      this.columnnamegrnpnd = ""
    }
  }

  //approval icon
  getApprovals(data: any, row1: any) {
    // console.log(data.number);
    this.dialog.open(data, { // this.getConfirmData();
      width: '600px'
    })
    this.documnet_nodata = row1.number;
  }

  getConfirmData() {
    let obj = {
      command: "add",
      document_name: "GoodsReceipt",
      document_number: this.documnet_nodata
    };
    this.custservice.AutoDocUpdate2(obj).subscribe((res: any) => {
      if (res && res['status_code'] == 200) {
        this.dialog.closeAll()
        this.alertcall.showSuccess("Accepted", res['message'])
        this.getgoodslist()
      } else {
        this.dialog.closeAll()
        this.alertcall.showWarning("Error", res['message'])
      }
    });

  }
  addNew() {
    // this.demo1TabIndex=1;
    this.model1.selectedDMR = ''
    this.model1.selectionList = ''
    this.addeddmrlist = []
    this.showbtn = false;
    this.editdataa = ""
    this.Initialdata = true;
    this.editeddata = false;
    this.viewData = false;
    this.ponumber = ""
    this.showChangemode=false
    // this.btn = "Save"
    this.searchData=""
    this.getTransactionId();
  }
  BacktoList() {
    this.demo1TabIndex = 2;
    this.model1.selectedDMR = ''
    this.model1.selectionList = ''
    this.addeddmrlist = []
    this.showbtn = false;
    this.editdataa = ""
    this.Initialdata = true;
    this.editeddata = false;
    this.viewData = false;
    this.SNO = []
    this.dmr_number = []
    this.invoice_number = []
    this.invoice_date = []
    this.lr_date = []
    this.lr_number = []
    this.dc_number = []
    this.dc_date = []
    this.vehicle_number = []
    this.way_bill_number = []
    this.gate_entry_date = []
    this.gate_entry_number = []
    // this.btn = "Save"
  }

  // onTabChanged(ev:any){
  //   if(this.demo1TabIndex===1 || this.demo1TabIndex === 0 || this.demo1TabIndex === 2 || this.demo1TabIndex === 3 || this.demo1TabIndex===4){
  //     this.searchData=""
  //     this.columnname=""
  //     this.searchDatagrnpnd=""
  //     this.columnnamegrnpnd=""
  //     this.columnnamegrnlistofhold=""
  //     this.searchDatagrnlistofhold=""
  //     this.getgoodslist()
  //     this.getgoodslistpnd()
  //     this.getlistofhold()
  //     this.ponumber="";
  //     this.dateee="";
  //   }
  onTabChanged(ev:any){
  
    if ((this.demo1TabIndex === 0 && !this.shouldSkipReset)) {
      this.resetInitialData();
    }
    this.shouldSkipReset = false;
    if(this.demo1TabIndex===0){
      console.log(this.addeddmrlist,'@@@@@@@@@22');
    // this.Initialdata = true;
    // this.addeddmrlist = [];
    // this.dateee = moment(new Date()).format("YYYY-MM-DD")
    // this.model1.selectedDMR = "";
    // this.model1.selectionList = "";
    }else if(this.demo1TabIndex===1 ){
        this.searchDatagrnpnd="";
      this.columnnamegrnpnd="";
      this.pageSize1=10;
      this.pageIndex1=1;
      this.getgoodslistpnd();
      // this.btn="Save";
      // this.ponumber="";
      // this.dateee="";
      // this.Initialdata = false;
      // this.addeddmrlist = [];
      // this.dateee = moment(new Date()).format("YYYY-MM-DD")
      // this.model1.selectedDMR = "";
      // this.model1.selectionList = "";
      // this.selectedfiles = [];
      }else if (this.demo1TabIndex===2){
        this.searchData="";
        this.columnname="";
      //   this.btn="Save";
      // this.ponumber="";
      // this.dateee="";
      this.pageSize=10;
      this.pageIndex=1;
      this.getgoodslist();
      // this.Initialdata = false;
      // this.addeddmrlist = [];
      // this.dateee = moment(new Date()).format("YYYY-MM-DD")
      // this.model1.selectedDMR = "";
      // this.model1.selectionList = "";
      // this.selectedfiles = [];
      }
      else if(this.demo1TabIndex === 3){
        this.searchDatagrnlistofhold = "";
        this.columnnamegrnlistofhold = "";
      this.pageSizehold = 10;
      this.pageIndexhold = 1;
      this.getlistofhold()
      // this.ponumber="";
      // this.dateee="";
      // this.btn = "Save";
      // this.Initialdata = false;
      // this.addeddmrlist = [];
      // this.dateee = moment(new Date()).format("YYYY-MM-DD")
      // this.model1.selectedDMR = "";
      // this.model1.selectionList = "";
      // this.selectedfiles = [];
      }else if(this.demo1TabIndex===4){
        this.pageIndexpnd=0
        this.pageSizepnd=10
        this.getDatapnd()
      }else if(this.demo1TabIndex===5){
        this.pageIndexcmp=0
        this.pageSizecmp=10
        this.getDatacmp()
      }else if(this.demo1TabIndex===6){
        this.pageIndexallpnd=0
        this.pageSizeallpnd=10
        this.getDataallpnd()
      }
      else if(this.demo1TabIndex===7){
        this.logsearchData = "";
        this.logpageSize =10;
        this.logpageIndex =1;
      //   this.ponumber="";
      //   this.dateee="";
      //   this.btn = "Save";
      //   this.Initialdata = false;
      // this.addeddmrlist = [];
      // this.dateee = moment(new Date()).format("YYYY-MM-DD")
      // this.model1.selectedDMR = "";
      // this.model1.selectionList = "";
      // this.selectedfiles = [];
      }
  }
resetInitialData() {
  this.Initialdata = false;
  this.addeddmrlist = [];
  this.dateee = moment(new Date()).format("YYYY-MM-DD");
  this.model1.selectedDMR = "";
  this.model1.selectionList = "";
  this.selectedfiles = [];
  this.ponumber = "";
  this.dateee = "";
  this.editednumber=''
  this.getTransactionId()
  this.grnnumber=""
  this.btn = "Save";
}

  editdata(data: any, dialog: any) {
    this.dialog.open(dialog, {
      width: "400px",
    });
    this.editednumber = data.number;
    this.editModel.reason=""
    // this.viewDataEdit(data)
  }
  viewDataEdit() {
    let obj = {
      command: "mat",
      field: "number",
      key: this.editednumber
    }
    this.custservice.addgoodsreceiptlist(obj).subscribe((res: any) => {
      if (res) {
        this.dialog.closeAll()
        this.viewData = true
        this.editData= true
        this.editdataa = res.data[0]
        this.addeddmrlist = res.data2
        this.showbtn = true
        this.updatebtn = true
        let sum = 0
        this.addeddmrlist.forEach((ele: any) => {
          sum += ele.total_price
        });
        this.totalPrice = sum
        // this.model1.dmrnumber=this.editdataa.dmr_number, 
        // this.model1.comments=this.editdataa.comments,     
        this.dateee = moment(this.editdataa.date).format("YYYY-MM-DD"),
          this.ponumber = this.editdataa.purchase_order_number,
          this.editdataa.invoice_date = moment(this.editdataa.invoice_date).format("YYYY-MM-DD")
        this.editdataa.lr_date = moment(this.editdataa.invoice_date).format("YYYY-MM-DD")
        this.addeddmrlist.forEach((element: any, i: any) => {
          element.forEach((ele: any) => {
            console.log(ele.dmr_number);
            this.addeddmrlist[i].dmr_number = ele.dmr_number
            this.addeddmrlist[i].qc_number = ele.qc_number
            this.addeddmrlist[i].esdn_number = ele.esdn_number
            this.addeddmrlist[i].vendor_name = ele.vendor_name
            this.addeddmrlist[i].qc_date = moment(ele.qc_date).format("YYYY-MM-DD")
            this.addeddmrlist[i].invoice_date = moment(ele.invoice_date).format("YYYY-MM-DD")
            this.addeddmrlist[i].gate_entry_date = moment(ele.gate_entry_date).format("YYYY-MM-DD")
            this.addeddmrlist[i].lr_date = moment(ele.lr_date).format("YYYY-MM-DD")
            this.addeddmrlist[i].dc_date = moment(ele.dc_date).format("YYYY-MM-DD")
            this.addeddmrlist[i].purchase_order_date = moment(ele.purchase_order_date).format("YYYY-MM-DD")
            this.addeddmrlist[i].comments = ele.comments
            this.addeddmrlist[i].lr_number = ele.lr_number
            this.addeddmrlist[i].dc_number = ele.dc_number
            this.addeddmrlist[i].gate_entry_number = ele.gate_entry_number
            this.addeddmrlist[i].transporter_name = ele.transporter_name
            this.addeddmrlist[i].vehicle_number = ele.vehicle_number
            this.addeddmrlist[i].way_bill_number = ele.way_bill_number
            this.addeddmrlist[i].invoice_number = ele.invoice_number
            this.addeddmrlist[i].miscellaneous_charges_description = ele.miscellaneous_charges_description
            this.addeddmrlist[i].miscellaneous_charges_value = ele.miscellaneous_charges_value
          });

        })
        // this.model1.podate=moment(this.editdataa.purchase_order_date).format("YYYY-MM-DD"),
        // this.model1.companyname=this.editdataa.company_name, 
        // this.model1.vendorname=this.editdataa.vendor_name, 
        // this.model1.invoicenumber=this.editdataa.invoice_number, 
        console.log(this.addeddmrlist,'###################333');
        
        this.demo1TabIndex = 0;
        this.editeddata = true;
        this.Initialdata = false;
        this.shouldSkipReset = true;
        // this.btn="Update"
        console.log(this.addeddmrlist,'s333');
      }
    })
  }
  Update() {
    let obj = {
      // "po_number":this.ponumber,
      "dmr_number": this.addeddmrlist,
      "company_name": this.companyname[0],
      "date": moment(this.dateee).format("YYYY-MM-DD"),
      "reason": this.editModel.reason,
      "txn_id": this.txnId,
      "command": "edt",
      // "number": this.editednumber,
      // "dmr_number": this.dmr_number,
      // "invoice_number": this.invoice_number,
      // "invoice_date":this.invoice_date,
      // "lr_date":this.lr_date,
      // "lr_number":this.lr_number,
      // "vehicle_number":this.vehicle_number,
      // "way_bill_number":this.way_bill_number,
      // "gate_entry_date":this.gate_entry_date,
      // "gate_entry_number":this.gate_entry_number,
      // "reason":this.editModel.reason,
      // "comments":this.comments,
    }
    this.addeddmrlist.forEach((element: any, i: any) => {
      element.forEach((ele: any) => {
        ele.gate_entry_date = this.addeddmrlist[i].gate_entry_date
        ele.invoice_date = this.addeddmrlist[i].invoice_date
        ele.lr_date = this.addeddmrlist[i].lr_date
        ele.dc_date = this.addeddmrlist[i].dc_date
        ele.purchase_order_date = this.addeddmrlist[i].purchase_order_date
        ele.comments = this.addeddmrlist[i].comments
        ele.lr_number = this.addeddmrlist[i].lr_number
        ele.dc_number = this.addeddmrlist[i].dc_number
        ele.gate_entry_number = this.addeddmrlist[i].gate_entry_number
        ele.transporter_name = this.addeddmrlist[i].transporter_name
        ele.vehicle_number = this.addeddmrlist[i].vehicle_number
        ele.vendor_name = this.addeddmrlist[i].vendor_name
        ele.way_bill_number = this.addeddmrlist[i].way_bill_number
        ele.invoice_number = this.addeddmrlist[i].invoice_number
        ele.purchase_order_number=this.ponumber
        ele.miscellaneous_charges_description = this.addeddmrlist[i].miscellaneous_charges_description
        ele.miscellaneous_charges_value = this.addeddmrlist[i].miscellaneous_charges_value
        //   //   this.SNO.push(i);
        //   // this.dmr_number.push(ele.dmr_number);
        //   // this.invoice_number.push(ele.invoice_number);
        //   // this.invoice_date.push(ele.invoice_date);
        //   // this.lr_date.push(ele.lr_date);
        //   // this.lr_number.push(ele.lr_number);
        //   // this.vehicle_number.push(ele.vehicle_number);
        //   // this.way_bill_number.push(ele.way_bill_number);
        //   // this.gate_entry_date.push(ele.gate_entry_date);
        //   // this.gate_entry_number.push(Number(ele.gate_entry_number));
        //   // this.comments.push(ele.comments)
        // this.ReqQTY.push(Number(ele.reqqty || ele.request_quantity));
        // this.qty.push(Number(ele.qty || ele.quantity));
      })
      

    });

    this.custservice.editgoodsreceiptdata(obj).subscribe((res: any) => {
      if (res && res['status_code'] == 200) {
        this.demo1TabIndex = 2
        this.addeddmrlist = []
        this.SNO = []
        this.dmr_number = []
        this.invoice_number = []
        this.invoice_date = []
        this.lr_date = []
        this.lr_number = []
        this.dc_date = []
        this.purchase_order_date=[]
        this.dc_number = []
        this.vehicle_number = []
        this.way_bill_number = []
        this.gate_entry_date = []
        this.gate_entry_number = []
        this.ponumber=''
        this.editData= false
        this.dialog.closeAll()
        Swal.fire({
          text: res['message'],
          title: res['reference'],
          icon: 'success',
          // title: res['reference'],
          width: 500,
        });
        this.getTransactionId();
        // this.alertcall.showSuccess("Accepted", res['message'])
        this.getgoodslist()
        this.getgoodslistpnd()
        this.getlistofhold()
        this.BacktoList()
      } else {
        this.dialog.closeAll()

        this.alertcall.showWarning("Error", res['message'])
      }
    });
  }
  //dmr
  viewAttachment1(data: any, row: any) {
    // console.log(row);

    this.dialog.open(data, {
      width: '800px'
    })
    this.selectedDMR = row.dmr_number
    this.getexistinguploadedfiles(row.dmr_number)
  }
  getexistinguploadedfiles(number: any) {
    let params = new HttpParams();
    params = new HttpParams()
      .set("document_number", number)
      .set("document_type", "Daily Material Receipt")
    this.custservice.getexistingfies(params).subscribe((res: any) => {
      if (res && res['status_code'] == '200') {
        this.filedataDMR = res.data
      } else {
        this.filedataDMR = []
      }
    })  
  }
  viewDocDMR(file: any) {
    // const url = this.imageUrl + '/' + file.file_path;
    const url = file.file_path;
    window.open(url, '_blank');
  }
  // closemodel(){
  //   this.dialog.closeAll()
  // }

  //download log
  // downloadLog(row: any) {
  //   console.log(row);
  //   let obj = {
  //     command: "log",
  //     id: row.id
  //   }
  //   this.custservice.viewActivityLog(obj).subscribe((res: any) => {
  //     console.log(res.log);
  //     if (res.log.length > 0) {
  //       this.activityLogDownload = res.log
  //       this.alertcall.showSuccess('Accepted', 'File Downloading');
  //       console.log(this.activityLogDownload);
  //       setTimeout(() => {
  //         this.exportExcellog()
  //       }, 1000);
  //       //   const button = document.getElementById('buttondwnlog');
  //       //   button?.addEventListener('click', function handleClick(event) {
  //       //   console.log('button clicked');
  //       //   console.log(event);
  //       //   console.log(event.target);
  //       // });
  //       // this.exportExcellog()
  //     } else {
  //       this.alertcall.showWarning("Warning", 'No Data Found');
  //     }
  //   })
  // }
  // getHeaders() {
  //   let headers: string[] = [];
  //   if (this.activityLogDownload) {
  //     this.activityLogDownload.forEach((value: any) => {
  //       // if(value['Unit_Price']!==null){
  //       //   value['Unit_Price']=parseFloat(value.Unit_Price).toFixed(2)
  //       // }if(value['Total_Price']!==null){
  //       //   value['Total_Price']=parseFloat(value.Total_Price).toFixed(2)
  //       // }
  //       Object.keys(value).forEach((key) => {
  //         if (!headers.find((header) => header == key)) {
  //           headers.push(key)
  //         }
  //       })
  //     })
  //   }
  //   return headers;
  // }
  // exportExcellog() {
  //   // console.log("Button clicked!");
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLELog.nativeElement);
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   XLSX.writeFile(wb, `ActivityLogData${moment().format('LL,LTS')}.xlsx`);
  // }

  //list of hold
  getlistofhold() {
    let obj = {
      "command": "hls",
      "pid": this.pageIndexhold,
      "lmt": this.pageSizehold,
    }
    this.custservice.getgoodsreceiptlist(obj).subscribe((res: any) => {

      this.goodsreceptdata = res.data
      this.dataSourcemaingrnlistofhold.data = res.data
      this.dataSourcemaingrnlistofhold.sort = this.sort
      this.totalRecords = res.count
      this.totalRecordsgrnlistofhold=res.count
    })
  }
  onpageeventgrnlsthold(event: any) {
    this.pageIndexhold = event.pageIndex + 1;
    this.pageSizehold = event.pageSize;
    if(!this.searchDatagrnlistofhold){
    this.getlistofhold()
    }
    else{
      this.searchgrnlistofhold();
    }
  }
  onChangegrnlistofhold() {
    this.searchDatagrnlistofhold = ""
  }
  searchgrnlistofhold() {
    // this.pageIndexhold=1;
    let obj = { "command": "hls", "field": this.columnnamegrnlistofhold, "key": this.searchDatagrnlistofhold, "lmt": this.pageSizehold, "pid": this.pageIndexhold,showLoader: 'hide' }
    // if(this.searchDatagrnlistofhold){
    //   this.masterpaginatorhold.firstPage();
    // }
    if (this.searchDatagrnlistofhold.length > 2) {
      this.custservice.getgoodsreceiptlist(obj).subscribe((res: any) => {
        // this.goodsreceptdata = res.data
        this.dataSourcemaingrnlistofhold.data = res.data
        this.dataSourcemaingrnlistofhold = new MatTableDataSource(res.data);
        this.totalRecordsgrnlistofhold = res.count
        // this.totalRecordsgrnlistofhold=this.totalRecords        
      })
    } else if (!this.searchDatagrnlistofhold) {
      this.getlistofhold()
      this.columnnamegrnlistofhold = ""
    }
  }
//hold
addHold(row: any, data: any) {
  this.holdfile = row.number
  this.dialog.open(data, {
    width: '400px'
  })
  this.holdmodel.reason = "";
}
holdFile() {
  let obj = {
    "command": "hld",
    "number": this.holdfile,
    "reason": this.holdmodel.reason,
    "txn_id": this.txnId,
  }
  this.custservice.deletegoodsreceiptlist(obj).subscribe((res: any) => {
    if (res && res['status_code'] == '200') {
      this.alertcall.showSuccess('Accepted', res['message']);
      this.dialog.closeAll()
      this.holdmodel.reason = ""
      this.getTransactionId();
      this.getgoodslist()
      this.getgoodslistpnd()
      this.getlistofhold()
    } else {
      this.alertcall.showWarning('Error', res['message']);
    }

  })
}
releaseFile(data:any){
  this.ponumber=data.purchase_order_number;
  this.grnnumber=data.number;
  this.dateee = moment(data.date).format("YYYY-MM-DD"),
  this.demo1TabIndex=0;
  this.btn='Release'
  this.shouldSkipReset = true;
}
keyPressNumbers(evt: any) {
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
    return false;

  return true;
}
//
onpageeventpnd(event: any) {
  this.pageIndexpnd = event.pageIndex;
  this.pageSizepnd = event.pageSize;
  this.getDatapnd();
}
pndSearchData(){
  if(this.pndsearchnumber.length > 2){
    if(this.grnpaginatorpnd){
      this.grnpaginatorpnd.firstPage();
    }
    this.getDatapnd()
  }else if(this.pndsearchnumber.length === 0){
    this.getDatapnd()
  }
  }
getDatapnd() {
  const postData = new FormData();
  postData.append("command","pnd");
  postData.append("lmt",this.pageSizepnd);
  postData.append("pid",this.pageIndexpnd + 1);
  postData.append("document_name", "GoodsReceipt" );
  if(this.pndsearchnumber && this.pndsearchnumber.length > 2){
    postData.append("key", this.pndsearchnumber);
  }
  this.custservice.DocumentSign(postData).subscribe((res: any) => {
    this.totalRecordspnd =0
    this.dataSourceListpnd.data =[]
    this.reaDatapnd = false;
    if(res.data.length>0){
    this.totalRecordspnd = res?.count;
    this.dataSourceListpnd.data = res.data;
  }
  if (res.data.length == 0) {
    this.reaDatapnd = true;
  }
  });
}
//
//all pnd
onpageeventallpnd(event: any) {
  this.pageIndexallpnd = event.pageIndex;
  this.pageSizeallpnd = event.pageSize;
  this.getDataallpnd();
}
allpndSearchData(){
  if(this.allpndsearchnumber.length > 2){
    if(this.grnpaginatorallpnd){
      this.grnpaginatorallpnd.firstPage();
    }
    this.getDataallpnd()
  }else if(this.allpndsearchnumber.length === 0){
    this.getDataallpnd()
  }
  }
getDataallpnd() {
  const postData = new FormData();
  postData.append("command","lap");
  postData.append("lmt",this.pageSizeallpnd);
  postData.append("pid",this.pageIndexallpnd + 1);
  postData.append("document_name", "GoodsReceipt" );
  if(this.allpndsearchnumber && this.allpndsearchnumber.length > 2){
    postData.append("key", this.allpndsearchnumber);
  }
  this.custservice.DocumentSign(postData).subscribe((res: any) => {
    this.totalRecordsallpnd =0
    this.dataSourceListallpnd.data =[]
    this.reaDataallpnd = false;
    if(res.data.length>0){
    this.totalRecordsallpnd = res?.count;
    this.dataSourceListallpnd.data = res.data;
  }
  if (res.data.length == 0) {
    this.reaDataallpnd = true;
  }
  });
}
//
onpageeventcmp(event: any) {
  this.pageIndexcmp = event.pageIndex;
  this.pageSizecmp = event.pageSize;
  this.getDatacmp();
}
cmpSearchData(){
  if(this.cmpsearchnumber.length > 2){
    if(this.grnpaginatorcmp){
      this.grnpaginatorcmp.firstPage();
    }
    this.getDatacmp()
  }else if(this.cmpsearchnumber.length === 0){
    this.getDatacmp()
  }
  }
getDatacmp() {
  const postData = new FormData();
  postData.append("command","cmp");
  postData.append("lmt",this.pageSizecmp);
  postData.append("pid",this.pageIndexcmp + 1);
  postData.append("document_name", "GoodsReceipt" );
  if(this.cmpsearchnumber && this.cmpsearchnumber.length > 2){
    postData.append("key", this.cmpsearchnumber);
  }
  this.custservice.DocumentSign(postData).subscribe((res: any) => {
    this.totalRecordscmp=0
    this.dataSourceListcmp.data=[]
    this.reaDatacmp = false;
    if(res.data.length>0){
    this.totalRecordscmp = res?.count;
    this.dataSourceListcmp.data = res.data;
  }
  if (res.data.length == 0) {
    this.reaDatacmp = true;
  }
  });
}
printapproval(data: any,tabName:any) {
  this.router.navigate(['/inventory/printdmr'], { queryParams: { 'document_number': data.document_number,'tab':tabName } })
}
}
