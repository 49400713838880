import { Component, OnInit, VERSION } from '@angular/core';
import { CustomerService } from "src/app/services/customer.service";
import { NgxPrintElementService } from "ngx-print-element";
import { ActivatedRoute, Router } from '@angular/router';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-printgoodsissue',
  templateUrl: './printgoodsissue.component.html',
  styleUrls: ['./printgoodsissue.component.scss']
})
export class PrintgoodsissueComponent implements OnInit {
  key: any;
  field: "number";
  command = "mat";
  userData: any[] = [];
  dataa: any[] = [];
  table: any[] = [];
  value: any[];
  total: any=0
  total_value: any[] = [];
  Purpose: any;
  workNumber: any;
  data1: any;
  logoData: any;
  companyName: any;
  companylogo: any;

  constructor(
    private service: CustomerService,
    public print: NgxPrintElementService,
    private route:ActivatedRoute,
    private router:Router
  ) {}
  public config = {
    printMode: 'template', // template
    popupProperties: "window.open",
   stylesheets: [{ rel: 'stylesheet', href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' }],

  };

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.data1=params['misnumber'];
        
        // }
      });
      this.getUserFormData()
      this.getCompanylogosdata()
  }
  name = 'Angular ' + VERSION.major;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  backtodmr(){
    this.router.navigate(['/inventory/goodsissue'],{ queryParams: { tab: 'notificationsissue'}})
  }
  getUserFormData() {
    this.userData = [];
    let obj = {
      number: this.data1,
      // field: "number",
      command: "mat",
    };
    this.service.editgoodsissue(obj).subscribe((res: any) => {
      // this.userData = res.data;
      this.dataa = res.data;
      this.userData.push(this.dataa[0]);
      this.companyName = this.dataa[0].company_name;
      this.userData.push(this.table);
      this.table = res.data;

      this.findsum();
    });
    let purpose = null;
    let work_order_number = null;

    if (purpose == null && work_order_number == null) {
      purpose = '--';
      work_order_number = '--';
    }
    this.Purpose = purpose;
    this.workNumber = work_order_number;
  }

  findsum() {
    this.total = 0;
    this.table.forEach((rec:any)=>{
      this.total += rec.total_value
    })
    this.total = (this.total).toFixed(2)
    // this.value = this.table;
    
    // for (let j = 0; j <= table.length; j++) {
    //   this.total += this.value[j].total_value;
    // }

    
  }

  //logo
    //logo 
    getCompanylogosdata() {
      let obj = {
        "command": "mat",
        "lmt":10000,
        "pid":1
      }
      this.service.getcompanymasterdata(obj).subscribe((res: any) => {
        if (res) {
          this.logoData = res.data;
          // console.log(this.logoData);
          this.logoData.forEach((ele: any) => {
            if (this.companyName === ele.name) {
              this.companylogo = ele.logo
            }
          });
        }
      })
    }

}
