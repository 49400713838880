import { Component, ElementRef, EventEmitter, Injector, OnInit, Output, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CustomerService } from "src/app/services/customer.service";
import * as moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NoopScrollStrategy } from "@angular/cdk/overlay";
import { Overlay } from "@angular/cdk/overlay";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { AlertCallsService } from "src/app/auth/alert-calls.service";
import { I, L } from "@angular/cdk/keycodes";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatAccordion } from "@angular/material/expansion";
import * as XLSX from 'xlsx';
import { MatSort } from "@angular/material/sort";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
@Component({
  selector: "app-goodsissue",
  templateUrl: "./goodsissue.component.html",
  styleUrls: ["./goodsissue.component.scss"],
})
export class GoodsissueComponent implements OnInit {
  @ViewChild('TABLELog', { static: false }) TABLELog: ElementRef;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('masterpaginator') masterpaginator:MatPaginator;
  @ViewChild('masterpaginatorpnd') masterpaginatorpnd:MatPaginator;
  @ViewChild('mrpaginatorallpnd') mrpaginatorallpnd:MatPaginator;
  @Output() page = new EventEmitter<PageEvent>();
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(MatSort) sort: MatSort;
  goTo: any;
  pageNumbers: any = []
  panelOpenState: boolean = false;
  intialData: boolean = false;
  editData: boolean = false;
  editpanelOpenState: boolean = false;
  displayedColumns: any[] = [
    "action",
    "sno",
    "material_code",
    "material_description",
    "unit_of_measurement",
    "storage_location",
    "request_quantity",
    "quantity",
    "item_remarks",
    // "batch_no",
    "valution_type",

  ];
  displayedColumns1: any[] = [
    "sno",
    "misNumber",
    "Date",
    "company_Name",
    // "tocompanyname",
    "contractor_name",
    "request_number",
    // 'issue_type',
    // 'ActiveStatus',
    "action",
  ];
  displayedColumnsListpnd: any[] = [
    "sno",
    "MSRNnum",
    "work_order_number", 
    "created_date",
    "cmpnyName",
    "contractorName",
    "action",
  ];
 
  btn: any = "Save";
  // model: any = {};
  model:any ={
    valutiontype:""
  }
  model1: any = {};
  model2: any = {};
  editmodel: any = {};
  saveddataarray: any[] = [];
  dataSource = new MatTableDataSource();
  dataSource1 = new MatTableDataSource();
  dataSourcemain = new MatTableDataSource();
  dataSourceListpnd = new MatTableDataSource();
  valuefrminv: any = 0;
  valuefrmrec: any = 0;
  unitValue: any = 0;
  discValue: any = 0;
  taxPercent: any = 0;
  masterData: any;
  taxData: any;
  companyData: any;
  vendorData: any;
  transporterName: any;
  vehicleNum: any;
  basicFreight: any = 0;
  fileUploadUrlsdmr: any[] = [];
  freightPercentage: any = 0;
  freightPercentage1: any = 0;
  storageData: any;
  matCode: any[] = [];
  matName: any[] = [];
  UOM: any[] = [];
  storageLocation: any[] = [];
  InvoiceQuantity: any[] = [];
  ReqQTY: any[] = [];
  qty: any[] = [];
  itemremarks: any[] = [];
  batchno: any[] = [];
  valutionType: any[] = [];
  shrQTY: any[] = [];
  excsQTY: any[] = [];
  unitPrice: any[] = [];
  discPERCENT: any[] = [];
  discVALUE: any[] = [];
  basicPRICE: any[] = [];
  taxDESC: any[] = [];
  taxPERCEN: any[] = [];
  taxVALUE: any[] = [];
  otherTAXSPLIT: any[] = [];
  SNO: any[] = [];
  freightSPLIT: any[] = [];
  othertaxSPLIT: any[] = [];
  otherchargesSPLIT: any = [];
  totalPRICE: any[] = [];
  addedTableData: any = true;
  savedTableData: any = false;
  totalRecords: any = 0;
  reaData: boolean;
  loadingRecords: any = false;
  pageIndex: any = 1;
  pageSize: any = 10;
  pageIndexlog:any=1;
  pageSizelog:any=10;
  deleteNumber: any;
  deletemodel: any = {};
  editModel: any = {};
  model2matcode: any;
  model2matdes: any;
  valuefrminvedit: any = 0;
  valuefrmrecedit: any = 0;
  unitValueedit: any = 0;
  discValueedit: any = 0;
  editedtaxdescription: any;
  selectedIndex: number;
  SUM: any = 0;
  editabledata: any;
  editDATAA: any = false;
  materialCODE: any;
  materialNAME: any;
  systemref: any;
  NUMBER: any;
  basicFreight1: any = 0;
  selectedtransporter: any;
  selectedvehiclenum: any;
  selectedvehiclenum1: any;
  selectedtransporter1: any;
  selectedmaterial: any;
  selectedstorage: any;
  selectedstorage1: any;
  selectedmaterial1: any;
  valutiondata: any;
  contracordata: any;
  demo1TabIndex: any = 0;
  smsdata: any;
  materialreqdata: any[]=[];
  dialogRef: any = null;
  ginumber: any;
  filedata: any = [];
  createNewFile: any = {};
  imageUrl = environment.base_url;
  deleteid: any;
  fileUploadUrls: any[] = [];
  fileUploadUrlsgi: any[] = [];
  resultginumber: any;
  selectedfiles: any[] = [];
  filenamearray: any[] = [];
  editednumber: any;
  logdata: any;
  editdataa: any;
  firstrecord: any;
  selectedconname: any;
  requesttype: any;
  selectedvehicledata: any;
  vehicledata: any;
  columnname: string="";
  searchData: any;
  smsdata1: any;
  companyData1: any;
  matCode11: any;
  matDesc: string;
  editableData:boolean = false;
  reaDatalog: boolean;
  totalRecordslog: any;
  dataSourcemainlog = new MatTableDataSource();
  selectedcompany: any;
  viewData: boolean = false;
  viewnumber: any;
  logsearchData: any;
  selectedreqdata: any;
  today: any;
  txnId: string;
  actiondisabled: boolean = true;
  issubmitClicked: boolean;
  isAddNewClicked: boolean;
  fileupdate: boolean;
  pndsearchnumber: any;
  pageIndexpnd: any = 0;
  pageSizepnd: any = 10;
  totalRecordspnd: any = 0;
  reaDatapnd: boolean;
  requestnumbervalue: boolean=false;
  pageIndexallpnd: any = 0;
  pageSizeallpnd: any = 10;
  totalRecordsallpnd: any = 0;
  reaDataallpnd: boolean;
  allpndsearchnumber: any;
  dataSourceListallpnd = new MatTableDataSource();
  displayedColumnsListallpnd: any[] = [
    "sno",
    "MSRNnum",
    "document_name",
    "cmpnyName",
    "contractorName",
    "description",
    "level",
    "action",
  ];
  constructor(
    private dialog: MatDialog,
    private custservice: CustomerService,
    private snackbar: MatSnackBar,
    public overlay: Overlay,
    private alertcall: AlertCallsService,
    private router: Router,
    private route: ActivatedRoute,
    private injector: Injector
  ) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
  }

  ngOnInit(): void {
    this.today = moment(new Date()).format("YYYY-MM-DD")
    // this.model1.requestdate = moment(new Date()).format("YYYY-MM-DD");
    this.model1.dateee = moment(new Date()).format("YYYY-MM-DD");
    this.route.queryParams.subscribe((params: any) => {
      if (params.tab == "notificationsissue") {
        this.demo1TabIndex = 2;
      } else if(params.tab == "allpndnotificationsissue"){
        this.demo1TabIndex = 3;
      } else {
        this.demo1TabIndex = 0;
      }
    });
    this.onTabChanged()
    // this.getmasterdata()
    // this.getTaxlistdata();
    // this.getcompanydata();
    this.getTransactionId();
    this.getmasterdata();
    this.getstoragelocData();
    this.getvalutionData();
    this.getData();
    // this.getvalutionData()
    this.getContractdata();
    this.getmaterialreqData();
    this.getvehiclelist();
    this.getcompanydata1();
  }

  getTransactionId() {
    this.txnId = ""
    this.custservice.getTransactionId().subscribe((res: any) => {
      if (res) {
        // console.log(res.txn_id);
        this.txnId = res.txn_id
      }
    })
  }
  filtervehicledata(ev: any) {
    this.selectedvehicledata = ev.target.value
    if (this.selectedvehicledata.length > 1) {
      this.getvehiclelist()
    } else if (!this.selectedvehicledata) {
      this.getvehiclelist()
    }
  }
  getvehiclelist() {
    let obj = {
      "command": "lst",
      "key": this.selectedvehicledata
    }
    this.custservice.vehicleMasterListData(obj).subscribe((res: any) => {
      this.vehicledata = res.data

    })
  }
  selectedvehiclenumber() {

  }
  selectedreqnumber(data:any) {
    let obj = {
      key: data.number,
      field: "number",
      // command: "mat",
      command: "mat",
      showLoader: 'hide',
    };
    this.custservice.getmaterialrequestdata(obj).subscribe((res: any) => {
      this.dataSource.data=[]
      this.saveddataarray=[]
      this.editpanelOpenState=false
      // console.log(res);
      if (res.data.length > 0) {
        this.requestnumbervalue=true
        this.viewData = false
        this.actiondisabled = true;
        this.demo1TabIndex = 0;
        this.firstrecord = res.data[0];
        res.data.forEach((element: any) => {
          element["request_quantity"] = element.quantity;
          element["quantity"] = "";
        });
        this.saveddataarray = res.data;
        this.dataSource.data = this.saveddataarray;
        this.model1.requestnumber=this.firstrecord.number,
        (this.model1.companyname = this.firstrecord.company_name),
          (this.model1.contractorname = this.firstrecord.contractor_name),
          (this.model1.locations = this.firstrecord.locations),
          (this.model1.comments = this.firstrecord.comments),
          (this.model1.requesttype = this.firstrecord.request_type);
          (this.model1.requestdate = (this.firstrecord.date !== null || this.firstrecord.date !== "") ? moment(this.firstrecord.date).format(
            "YYYY-MM-DD"
          ) : "NA"),
          (this.model1.vehiclenumber = this.firstrecord.vehicle_number !== null ? this.firstrecord.vehicle_number : "NA"),
        (this.model1.work_order_number = this.firstrecord.work_order_number !== null ? this.firstrecord.work_order_number : 'NA')
        }else{
          this.requestnumbervalue=false
        }
    });
  }
 
  openfileuploadmodel(data: any, row1: any) {
    this.dialog.open(data, {
      width: "800px",
    });
    this.resultginumber = row1._id
    this.ginumber = row1.number;
    this.getexistingfiles();
  }
  // getexistingfiles() {
  //   let params = new HttpParams();
  //   params = new HttpParams()
  //     .set("document_number", this.ginumber)
  //     .set("document_type", "Goods Issue");
  //   // let obj={
  //   //   "document_number" : this.dmrnumber,
  //   //   "document_type": "Daily Material Receipt"
  //   // }
  //   this.custservice.getexistingfies(params).subscribe((res: any) => {
  //     if (res && res["status_code"] == "200") {
  //       this.filedata = res.data;
  //       console.log(this.filedata,"heyyyyyyyyyyyyfileeeeeeeeeeeeeeeeks");
  //       this.createNewFile.fileName = "";
  //     } else {
  //       this.filedata = [];
  //       console.log(this.filedata,"heyyyyyyyyyyyyfileeeeeeeeeeeeeeeeks");
  //     }
  //   });
  // }

  getexistingfiles() {
    let params = new HttpParams();
    params = new HttpParams()
      .set("document_number", this.ginumber)
      .set("document_type", "Goods Issue");

    this.custservice.getexistingfies(params).subscribe((res: any) => {
      if (res && res['status_code'] == '200') {
        this.filedata = res.data
        this.createNewFile.fileName = ''
      } else {
        this.filedata = []
        console.log(this.filedata);
      }
      // if (res && res["status_code"] == "200") {
      //   const uniqueFiles = [];
      //   const uniqueFileNames = new Set()

      //   for (const file of res.data) {
      //     if (!uniqueFileNames.has(file.file_name)) {
      //       uniqueFileNames.add(file.file_name);
      //       uniqueFiles.push(file);
      //     }
      //   }
      //   this.filedata = uniqueFiles;
      //   console.log(this.filedata, "Unique files array");
      //   this.createNewFile.fileName = "";
      //   uniqueFileNames.clear();
      // } else {
      //   this.filedata = [];
      //   console.log(this.filedata, "Empty filedata");
      // }
    });
  }


  viewDoc(file: any) {
    // const url = this.imageUrl + "/" + file.file_path;
    const url = file.file_path;
    console.log(url);
    window.open(url, "_blank");
  }
  deleterowfile(row: any, data: any) {
    this.deleteid = data.id;
    this.dialogRef = this.dialog.open(row, {
      width: "400px",
    });
  }
  deleteexistingfile() {
    let params = new HttpParams();
    params = new HttpParams()
      .set("document_number", this.ginumber)
      .set("document_type", "Goods Issue")
      .set("id", this.deleteid);
    this.custservice.deletefiles(params).subscribe((res: any) => {
      if (res && res["status_code"] == "200") {
        this.alertcall.showSuccess("Accepted", "File Deleted Successfully");
        this.getexistingfiles();
        this.dialogRef.close();
      } else {
        this.alertcall.showWarning("Error", res["message"]);
      }
    });
  }
  uploadWbsFile(fileInput: any) {
    if (
      fileInput &&
      fileInput.target &&
      fileInput.target.files &&
      fileInput.target.files.length > 0
    ) {
      this.fileUploadUrls = fileInput.target.files;
      this.createNewFile.fileName = fileInput.target.files[0].name;
  
      const postData = new FormData();
      postData.append("document_type", "Goods Issue");
      postData.append("document_number", this.ginumber);
  
      let hasDuplicates = false; 
  
      for (const file of this.fileUploadUrls) {
        if (this.filedata && this.filedata.length > 0 && this.isFileSelected1(file.name)) {
          hasDuplicates = true;
          break; 
        } else {
          postData.append("doc", file);
        }
      }
  
      if (hasDuplicates) {
        this.alertcall.showWarning('Alert', 'Some files are already uploaded');
      } else {
        this.custservice.addfileupload(postData).subscribe((res: any) => {
          if (res && res['status_code'] == '200') {
            this.alertcall.showSuccess("Accepted", res['message'])
            this.getexistingfiles()
            this.fileUploadUrls = []
          } else {
            this.alertcall.showWarning("Error", res['message'])
          }
          fileInput.target.value = '';
        });
      }
    }
  }
  
  // uploadWbsFile(fileInput: any) {
  //   this.fileUploadUrls=[]
  //   if (
  //     fileInput &&
  //     fileInput.target &&
  //     fileInput.target.files &&
  //     fileInput.target.files.length > 0
  //   ) {
  //     this.fileUploadUrls = fileInput.target.files;
  //     this.createNewFile.fileName = fileInput.target.files[0].name;
  
  //     const postData = new FormData();
  //     postData.append("document_type", "Goods Issue");
  //     postData.append("document_number", this.ginumber);
  
  //     let hasDuplicates = false; 
  
  //     for (const file of this.fileUploadUrls) {
  //       if (this.filedata && this.filedata.length > 0 && this.isFileSelected1(file.name)) {
  //       alert(1)
  //         hasDuplicates = true;
  //         break; 
  //       } else {
  //       alert(2)
  //         postData.append("doc", file);
  //       }
  //     }
  
  //     if (hasDuplicates) {
  //     alert(3)
  //       this.alertcall.showWarning('Alert', 'Some files are already uploaded');
  //     } else {
  //     alert(4)
  //       this.custservice.addfileupload(postData).subscribe((res: any) => {
  //         if (res && res['status_code'] == '200') {
  //           this.alertcall.showSuccess("Accepted", res['message'])
  //           this.getexistingfiles()
  //           this.fileUploadUrls = []
  //         } else {
  //           this.alertcall.showWarning("Error", res['message'])
  //         }
  //       });
  //     }
  //   }
  // }
  

  // uploadgifiles(fileInput: any) {
  //   if (
  //     fileInput &&
  //     fileInput.target &&
  //     fileInput.target.files &&
  //     fileInput.target.files.length > 0
  //   ) {
  //     this.fileUploadUrlsgi = fileInput.target.files;
  //     this.createNewFile.fileName = fileInput.target.files[0].name;
  //     for (const file of this.fileUploadUrlsgi) {
  //       // this.filenamearray.push(file.name);
  //       // this.selectedfiles.push(file);
  //       if (this.isFileSelected(file.name)) {
  //         this.alertcall.showWarning('Alert', 'Some files are already selected');
  //       } else {
  //         this.filenamearray.push(file.name)
  //         this.selectedfiles.push(file)
  //       }
  //     }
  //   }
  // }

  uploadgifiles(fileInput: any) {
    if (
      fileInput &&
      fileInput.target &&
      fileInput.target.files &&
      fileInput.target.files.length > 0
    ) {

      console.log( fileInput.target.files , ' fileInput.target.files');
      
      this.fileUploadUrlsgi = fileInput.target.files;
      this.createNewFile.fileName = fileInput.target.files[0].name;
      for (const file of this.fileUploadUrlsgi) {
        if (this.isFileSelected1(file.name)) {
          this.alertcall.showWarning('Alert', 'Duplicate file: ' + file.name + ' already selected');
        } else {
          console.log(this.selectedfiles , 'this.selectedfiles');
          
          
          this.filenamearray.push(file.name);
          this.selectedfiles.push(file);
        }
      }
    }
  }





  isFileSelected1(selectedFilePath: string): boolean {
    return this.selectedfiles.some((file: any) => file.name === selectedFilePath);
  }
  // uploadselectedfiles() {
  //   const postData = new FormData();
  //   postData.append("document_type", "Goods Issue");
  //   postData.append("document_number", this.resultginumber);
  //   for (const file of this.fileUploadUrlsgi) {
  //     postData.append("doc", file);
  //   }
  //   this.custservice.addfileupload(postData).subscribe((res: any) => {
  //     if (res && res["status_code"] == "200") {
  //       this.fileUploadUrlsgi = [];
  //       this.filenamearray = [];
  //       this.selectedfiles = [];
  //     } else {
  //     }
  //   });
  // }

  uploadselectedfiles() {
    const postData = new FormData();
    postData.append("document_type", "Goods Issue");
    postData.append("document_number", this.resultginumber);
  
    const uniqueFileNames = new Set(); 
  
    for (const file of this.selectedfiles) {
     
      if (!uniqueFileNames.has(file.name)) {
        postData.append("doc", file);
        uniqueFileNames.add(file.name); 
      }
    }
    this.custservice.addfileupload(postData).subscribe((res: any) => {
      if (res && res["status_code"] == "200") {
        this.fileUploadUrlsgi = [];
        this.filenamearray = [];
        this.selectedfiles = [];
      } else {
        
      }
    });
  }
  deleteFileItem(index: any) {
    this.selectedfiles.splice(index, 1)
  }


  printdmr(data: any,tabName:any) {
    console.log(data.number);
    // let navigationExtras: NavigationExtras = {
    //   queryParams: {
    //     "dmrnumber": data.number
    //   }
    // };
    
    // this.dialog.open(PrintdmrComponent,{
    //   width:"500px",
    //   height:"auto",
    //   data:data.number
    // })

    // this.router.navigate(['/inventory/goodsissue'],{ queryParams: { tab: 'notificationsissue'}})
    //
    this.router.navigate(['/planning/printmaterialreq'], { queryParams: { 'mrnumber': data.request_number || data.document_number,'doc_sign':data.doc_sign ,tab: tabName} })
    // this.router.navigate(["/inventory/printgoodsissue"], {
    //   queryParams: { misnumber: data.number },
    // });
  }
  selectedreqqty() {
    if (this.model.qty > this.model.reqqty  ){
      this.alertcall.showWarning("Accepted", "Issue Quantity Not More Than Request Quantity");
      this.model.qty = ""
    } else if (this.model.reqqty == 0) {
      this.model.qty = 0
    }
    console.log(this.model.qty);
  }
  selectedqty() {
    const reqqtyNumber = Number(this.model2.reqqty);
    const qtyNumber = Number(this.model2.qty);
    if (qtyNumber > reqqtyNumber) {
      this.model.qty = ""
      this.alertcall.showWarning("Accepted", "Issued Quantity Not More Than Request Quantity");
    }else if (this.model.reqqty == 0){
      this.model.qty = 0
    }
    // console.log(this.model.qty);
  }
  selectedreqqtyEdit() {
    const reqqtyNumber = Number(this.model2.reqqty);
    const qtyNumber = Number(this.model2.qty);
    if (qtyNumber > reqqtyNumber) {
      this.model2.qty = ""; 
      this.alertcall.showWarning("Warning", "Issued Quantity cannot be more than Requested Quantity");
    } else if (reqqtyNumber == 0) {
      this.model2.qty = 0; 
    } 
    // if ((this.model2.reqqty <= this.model2.qty)) {
    //   this.alertcall.showWarning("Accepted", "Issued Quantity Not More Than Request Quantity");
    //   this.model2.qty = ""
    // }else if(this.model2.reqqty == 0){
    //   this.model2.qty = 0
    // }
  }
  filtecontractordatadata(ev: any) {
    console.log(ev.target.value);
    this.selectedconname = ev.target.value;
    if (this.selectedconname.length > 2) {
      this.getContractdata();
    }
    if (!this.selectedconname) {
      this.getContractdata();
    }
  }
  getContractdata() {
    let obj = {
      command: "lst",
      lmt: 100000,
      pid: 1,
      key: this.selectedconname || "",
    };
    this.custservice.getcontractormasterdata(obj).subscribe((res: any) => {
      this.contracordata = res.data;
    });
  }
  deleterow(index: any) {
    console.log(index);

    this.saveddataarray.splice(index, 1);
    this.dataSource.data = this.saveddataarray;
    console.log(this.dataSource.data);
  }

  onpageevent(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    
    if(!this.searchData){
      this.getData();
    }else{
      this.search()
    }
  }
  deleteItem(rw: any, data: any) {
    this.dialog.open(data, {
      width: "400px",
      // scrollStrategy: new NoopScrollStrategy()
    });
    this.deleteNumber = rw.number;
  }

  deleteFile() {
    let obj = {
      command: "del",
      number: this.deleteNumber,
      reason: this.deletemodel.reason,
      "txn_id": this.txnId,
    };
    this.custservice.deletegoodsissue(obj).subscribe((res: any) => {
      if (res && res["status_code"] == "200") {
        this.alertcall.showSuccess("Accepted", res["message"]);

        this.dialog.closeAll();
        this.getTransactionId();
        this.getData();
        this.deletemodel.reason = "";
      } else {
        this.alertcall.showSuccess("Accepted", res["message"]);
      }
    });
  }
  refreshGetData(){
    this.pageSize=10;
    this.pageIndex=1;
    this.searchData='';
    this.columnname='';
    this.getData();
  }

  getData() {
    this.pageNumbers = []
    let obj = {
      command: "lst",
      lmt: this.pageSize,
      pid: this.pageIndex,
    };
        this.custservice.getSearchGoodsIssue1(obj).subscribe((res: any) => {
      console.log(res);

      this.reaData = false;
      this.totalRecords = res?.count;
      this.dataSourcemain.data = res.data;
      this.dataSourcemain.sort = this.sort
for (let i = 1; i <= Math.ceil(this.totalRecords / this.pageSize); i++) { 
this.pageNumbers.push(i);
}
      this.goTo = this.pageIndex
      if (res.data.length == 0) {
        this.reaData = true;
      }
      this.columnname=''
      this.searchData=''
    });
  }
  goToChange() {
  this.paginator.pageIndex = this.goTo - 1;
  const event: PageEvent = {
  // previousPageIndex: this.pageIndex-1,
  length: this.paginator.length,
  pageIndex: this.paginator.pageIndex,
  pageSize: this.paginator.pageSize,
  };
  this.paginator.page.next(event);
  }
  emitPageEvent(pageEvent: PageEvent) {
    this.paginator.page.next(pageEvent);
  }

  getstorageDATA(ev: any) {
    console.log(ev.target.value);
    this.selectedstorage = ev.target.value;
    if (this.selectedstorage.length > 2) {
      // this.getstoragelocData();
      this.getSMSdata();
    } else {
      this.getSMSdata();
    }
  }
  getstorageDATA1(ev: any) {
    console.log(ev.target.value);
    this.selectedstorage1 = ev.target.value;
    if (this.selectedstorage1.length > 2) {
      // this.getstoragelocData();
      this.getSMSdata();
    } else {
      this.getSMSdata();
    }
  }
  getstoragelocData() {
    let obj = {
      command: "mat",
      field: "storage_location",
      key: this.selectedstorage || this.selectedstorage1,
    };
    this.custservice.getmatstoragelocdata(obj).subscribe((res: any) => {
      this.storageData = res.data;
    });
  }
  getvehiclenumDATA(ev: any) {
    console.log(ev.target.value);
    this.selectedvehiclenum = ev.target.value;
    if (this.selectedvehiclenum.length > 2) {
      this.getvehiclenumdata();
    }
  }
  getvehiclenumDATA1(ev: any) {
    console.log(ev.target.value);
    this.selectedvehiclenum1 = ev.target.value;
    if (this.selectedvehiclenum1.length > 2) {
      this.getvehiclenumdata();
    }
  }
  getvehiclenumdata() {
    let obj = {
      command: "mat",
      field: "vehicle_number",
      key: this.selectedvehiclenum || this.selectedvehiclenum1,
    };
    this.custservice.getmatvehiclenumdata(obj).subscribe((res: any) => {
      this.vehicleNum = res.data;
    });
  }
  gettransportDATA(ev: any) {
    console.log(ev.target.value);
    this.selectedtransporter = ev.target.value;
    if (this.selectedtransporter.length > 2) {
      this.getTransporterdata();
    }
    if (!this.selectedtransporter) {
      this.getTransporterdata();
    }
  }
  gettransportDATA1(ev: any) {
    console.log(ev.target.value);
    this.selectedtransporter1 = ev.target.value;
    if (this.selectedtransporter1.length > 2) {
      this.getTransporterdata();
    }
    if (!this.selectedtransporter1) {
      this.getTransporterdata();
    }
  }
  getTransporterdata() {
    let obj = {
      command: "mat",
      field: "transporter_name",
      key: this.selectedtransporter || this.selectedtransporter1,
    };
    this.custservice.getmattransnamedata(obj).subscribe((res: any) => {
      this.transporterName = res.data;
    });
  }
  getvendordata() {
    let obj = {
      command: "lst",
    };
    this.custservice.getvendormasterdata(obj).subscribe((res: any) => {
      this.vendorData = res.data;
    });
  }
  getcompanydata() {
    let obj = {
      command: "lst",
    };
    this.custservice.getcompanymasterdata(obj).subscribe((res: any) => {
      this.companyData = res.data;
    });
  }
  getcompanydata1() {
    let obj = {
      command: "lst",
      lmt: 100000,
      pid: 1
    };
    this.custservice.usercompanyData(obj).subscribe((res: any) => {
      this.companyData1 = res.data;
    });
  }
  getmaterialDATA(ev: any) {
    console.log(ev.target.value);
    this.selectedmaterial = ev.target.value;
    if (this.selectedmaterial.length > 2) {
      this.getmasterdata();
    }
    if (!this.selectedmaterial) {
      this.getmasterdata();
    }
  }
  getmaterialDATA1(ev: any) {
    this.selectedmaterial1 = ev.target.value;
    if (this.selectedmaterial1.length > 2) {
      this.getmasterdata();
    }
    if (!this.selectedmaterial1) {
      this.getmasterdata();
    }
  }
  getmasterdata() {
    let obj = {
      command: "lst",
      lmt: 100,
      pid: 1,
      key: this.selectedmaterial || this.selectedmaterial1,
    };
    this.custservice.getmaterialmasterdata(obj).subscribe((res: any) => {
      console.log(res);
      this.masterData = res.data;
    });
  }
  getTaxlistdata() {
    let obj = {
      command: "lst",
    };
    this.custservice.gettaxlistdata(obj).subscribe((res: any) => {
      this.taxData = res.data;
    });
  }
  selectedmastergroup() {
    console.log(this.model.matcode);
    this.masterData.forEach((ele: any) => {
      if (ele.system_reference_1 == this.model.matcode) {
        this.model.uom = ele.uom_1;
        this.materialCODE = ele.code;
        this.materialNAME = ele.name;
      }
    });

    this.getSMSdata();
  }

  addgoodsissue(data: any) {
    this.panelOpenState = true;
    this.intialData = true;
    this.editData = false;
    this.editpanelOpenState = false;
    this.smsdata=[]
    // this.getmasterdata()
    // this.getstoragelocData()
    // this.getvalutionData()
    // scrollStrategy: this.overlay.scrollStrategies.noop()
    //  scrollStrategy: new NoopScrollStrategy()
    // this.dialog.open(data, {
    //   width: "1100px",
    // });
  }

  closemodel() {
    this.dialog.closeAll();
  }
  saveaddeddata(form: any) {
    this.model["material_code"] = this.materialCODE;
    this.model["material_description"] = this.materialNAME;
    console.log(this.model);

    // this.editDATAA=true;
    this.saveddataarray.push(this.model);
    console.log(this.saveddataarray);

    this.dataSource.data = this.saveddataarray;
    console.log(this.dataSource.data);
    this.model = {};
    this.smsdata = "";
    this.smsdata1 = "";
    this.selectedmaterial=""
    this.dialog.closeAll();
    this.intialData = false
    this.panelOpenState = false
    this.editData = false;
    this.editpanelOpenState = false;
  }
  getvalutionData() {
    let obj = {
      lmt: 100000,
      pid: 1,
      command: "lst",
      key: "",
    };
    this.custservice.getvalutiondata(obj).subscribe((res: any) => {
      this.valutiondata = res.data;
    });
  }
  savefinaldata(fr: any) {
    if (this.btn == "Save") {
      console.log(this.saveddataarray);
      this.saveddataarray.forEach((ele: any, index) => {
        this.SNO.push(index + 1);
        this.matCode.push(ele.material_code);
        this.matName.push(ele.material_description);
        this.UOM.push(ele.uom);
        this.storageLocation.push(ele.storagelocation);
        this.ReqQTY.push(Number(ele.reqqty));
        this.qty.push(Number(ele.qty));
        this.itemremarks.push(ele.itemremarks);
        this.batchno.push(ele.batchno);
        this.valutionType.push(ele.valutiontype);
      });
      let obj = {
        date: moment(this.model1.dateee).format("YYYY-MM-DD"),

        company_name: this.model1.companyname,
        // to_company_name: this.model1.tocompany,
        contractor_name: this.model1.contractorname,
        receiver_name: this.model1.receivername,
        request_number: this.model1.requestnumber,
        request_date: moment(this.model1.requestdate).format("YYYY-MM-DD"),
        issue_type: this.model1.requesttype,
        locations: this.model1.locations,
        line_item: this.SNO,
        material_code: this.matCode,
        material_description: this.matName,
        unit_of_measurment: this.UOM,
        request_quantity: this.ReqQTY,
        quantity: this.qty,
        item_remarks: this.itemremarks,
        valuation_type: this.valutionType,
        storage_location: this.storageLocation,
        // batch_number: this.batchno,
        comments: this.model1.comments,
        vehicle_number: this.model1.vehiclenumber,
        request_type: this.model1.requesttype,
        work_order_number: this.model1.work_order_number,
        "txn_id": this.txnId,
        command: "add",


      };
      this.custservice.addgoodsissue(obj).subscribe((res: any) => {
        if (res && res["status_code"] == "200") {
          // this.alertcall.showSuccess('Accepted', res['message']);
          Swal.fire({
            text: res["message"],
            title: res["reference"],
            icon: "success",
            // title: res['reference'],
            width: 500,
          });
          fr.reset();
          this.saveddataarray = [];
          this.dataSource.data = [];
          this.SNO = [];
          this.matCode = [];
          this.matName = [];
          this.UOM = [];
          this.storageLocation = [];
          this.ReqQTY = [];
          this.qty = [];
          this.itemremarks = [];
          this.batchno = [];
          this.valutionType = []
          this.smsdata = "";
          this.smsdata1 = "";
          this.getTransactionId();
          this.getData();
          this.getmaterialreqData()
          this.requestnumbervalue=false
          this.resultginumber = res["reference"];
          if (this.fileUploadUrlsgi.length > 0) {
            this.uploadselectedfiles();
          }
        } else {
          this.alertcall.showWarning("Accepted", res["message"]);
          this.SNO = [];
          this.matCode = [];
          this.matName = [];
          this.UOM = [];
          this.storageLocation = [];
          this.ReqQTY = [];
          this.qty = [];
          this.itemremarks = [];
          this.valutionType = [];
          this.batchno = [];
        }
      });
    } else {
      console.log(this.saveddataarray);
      this.saveddataarray.forEach((ele: any, index) => {
        this.SNO.push(index + 1);
        this.matCode.push(ele.material_code);
        this.matName.push(ele.material_description);
        this.UOM.push(ele.uom || ele.unit_of_measurment);
        this.storageLocation.push(ele.storagelocation || ele.storage_location);
        this.ReqQTY.push(Number(ele.reqqty || ele.request_quantity));
        this.qty.push(Number(ele.qty || ele.quantity));
        this.itemremarks.push(ele.itemremarks || ele.item_remarks);
        // this.batchno.push(ele.batchno || ele.batch_number);
        this.valutionType.push(ele.valutiontype || ele.valuation_type);
      });

      let obj = {
        reason: this.editModel.reason,
        date: moment(this.model1.dateee).format("YYYY-MM-DD"),

        company_name: this.model1.companyname,
        // to_company_name: this.model1.tocompany,
        contractor_name: this.model1.contractorname,
        receiver_name: this.model1.receivername,
        request_number: this.model1.requestnumber,
        request_date: moment(this.model1.requestdate).format("YYYY-MM-DD"),
        issue_type: this.model1.requesttype,
        locations: this.model1.locations,
        line_item: this.SNO,
        material_code: this.matCode,
        material_description: this.matName,
        unit_of_measurment: this.UOM,
        request_quantity: this.ReqQTY,
        quantity: this.qty,
        item_remarks: this.itemremarks,
        valuation_type: this.valutionType,
        storage_location: this.storageLocation,
        // batch_number: this.batchno,
        comments: this.model1.comments,
        vehicle_number: this.model1.vehiclenumber,
        request_type: this.model1.requesttype,
        work_order_number: this.model1.work_order_number,
        "txn_id": this.txnId,
        command: "edt",
        number: this.editednumber,
      };
      this.custservice.addgoodsissue(obj).subscribe((res: any) => {
        if (res && res["status_code"] == "200") {
          // this.alertcall.showSuccess('Accepted', res['message']);
          Swal.fire({
            text: res["message"],
            title: res["reference"],
            icon: "success",
            // title: res['reference'],
            width: 500,
          });
          fr.reset();
          this.demo1TabIndex=1
          this.saveddataarray = [];
          this.dataSource.data = [];
          this.SNO = [];
          this.matCode = [];
          this.matName = [];
          this.UOM = [];
          this.storageLocation = [];
          this.ReqQTY = [];
          this.qty = [];
          this.itemremarks = [];
          this.valutionType = []
          this.batchno = [];
          this.smsdata = "";
          this.smsdata1 = "";
          this.getTransactionId();
          this.getData();
          this.getmaterialreqData()
          this.editModel.reason = "";
          this.resultginumber = this.editednumber;
          if (this.fileUploadUrlsgi.length > 0) {
            this.uploadselectedfiles();
          }
          this.btn = "Save";
        } else {
          this.alertcall.showWarning("Accepted", res["message"]);
          this.SNO = [];
          this.matCode = [];
          this.matName = [];
          this.UOM = [];
          this.storageLocation = [];
          this.ReqQTY = [];
          this.qty = [];
          this.itemremarks = [];
          this.valutionType = [];
          this.batchno = [];
        }
      });
    }
  }
  editdata(row1: any, index: any) {
    // this.getmasterdata()
    // this.getstoragelocData()
    // this.getvalutionData()
    // this.editDATAA=false
    this.selectedmaterial1=""
    this.matDesc=""
    this.matCode11=""
    this.materialCODE = ""
    this.materialNAME = ""
    this.model2.matcode=""
    this.systemref=""
    this.editpanelOpenState = true;
    this.editData = true;
    this.intialData = false;
    this.panelOpenState = false;
    console.log(row1);
    this.getmaterialDATA11(row1.material_code)
    this.selectedIndex = this.saveddataarray.indexOf(row1);
    console.log(this.selectedIndex);
    // this.dialog.open(data, {
    //   width: "1100px",
    // });

    this.masterData.forEach((ele: any) => {
      if (ele.code == row1.material_code) {
        this.systemref = ele.system_reference_1;
      }
    });
    // if(row1.sap_code !== "" || row1.sap_code !== "undefined" || row1.sap_code !== null){
    //   row1.sap_code = "-" + row1.sap_code
    //   // this.matDesc = row1.material_code + row1.sap_code +"-" + row1.material_description;
    // }else{
    //   this.matDesc = row1.system_reference_1
    // }
    this.matDesc = row1.material_code +"-" + row1.material_description;
    this.matCode11 = row1.material_code;
    this.materialNAME = row1.material_description;
    this.model2.matcode = this.systemref || this.matDesc;
    this.model2.uom = row1.uom || row1.unit_of_measurment;
    this.model2.storagelocation = row1.storagelocation || row1.storage_location;
    this.model2.reqqty = row1.reqqty || row1.request_quantity;
    this.model2.qty = row1.qty || row1.quantity;
    this.model2.itemremarks = row1.itemremarks || row1.item_remarks;
    // this.model2.batchno = row1.batchno || row1.batch_number;
    this.model2.valutiontype = row1.valutiontype || row1.valuation_type;
    this.getSMSdata();
  }
  saveaddededitdata(fr: any) {
    this.getmaterialDATA11(this.model2.matcode.split("-")[0])
    this.materialCODE = ""
    this.materialNAME = ""
    this.editpanelOpenState = false;
    this.intialData = false;
    this.editData = false;
    this.panelOpenState = false;
    this.masterData.forEach((el: any) => {
      if (el.system_reference_1 == this.model2.matcode) {
        this.model2.matcode = el.system_reference_1;
        this.materialCODE = el.code;
        this.materialNAME = el.name;
      }else if(el.code == this.model2.matcode.split("-")[0]){
        this.model2.matcode = el.system_reference_1;
        this.materialCODE = el.code;
        this.materialNAME = el.name;
      }
    });
    
    this.getSMSdata();
    this.model2["material_code"] = this.materialCODE;
    // this.model2["material_code"] = this.model2.matcode;
    this.model2["material_description"] = this.materialNAME;
    this.saveddataarray.splice(this.selectedIndex, 1, this.model2);
    this.dataSource.data = this.saveddataarray;
    this.saveddataarray[this.selectedIndex].matcode = this.model2.matcode
    this.model2 = {};
    this.smsdata = "";
    this.smsdata1 = "";
    this.selectedmaterial1=""
    this.dialog.closeAll();
  }
  getmaterialDATA11(ev: any) {
    this.selectedmaterial1 = ev;
    if (this.selectedmaterial1.length > 2) {
      this.getmasterdata();
    }
    if (!this.selectedmaterial1) {
      this.getmasterdata();
    }
  }
  selectedmastereditgroup() {
    this.masterData.forEach((el: any) => {
      if (el.system_reference_1 == this.model2.matcode) {
        this.model2.uom = el.uom_1;
        this.materialCODE = el.code;
        this.materialNAME = el.name;
      }
    });
    this.getSMSdata();
  }

  keyPressNumbers(evt: any) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  onSelectionChange(ev: any) { }
  getSMSdata() {
    let obj = {
      material_code: this.materialCODE || this.matCode11,
      command: "sms",
    };

    this.custservice.getsmsdata(obj).subscribe((res: any) => {
      this.smsdata=[]
      if (res && res["status_code"] == "200") {
        this.smsdata = res.message;
        this.smsdata1 = res.storage_locations;
        // this.total=0
        // this.totalvalue=0
        //   this.smsdata.forEach((el:any)=>{
        //     this.total+= el.quantity;
        //     this.totalvalue += el.value;
        //     console.log(el.quantity);
        // })

        // var duplicates =  this.smsdata.reduce(function(acc:any, el:any, i:any, arr:any) {
        //   if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
        // }, []);

        // document.write(duplicates);
        // unique = [...new Set(this.smsdata.map((ele:any) => ele.company_name))];
        // if (unique.length === 1) {
        // console.log(unique);
        // }
        // this.smsdata.forEach((element:any) => {
        // this.invoice_number:any[]=[]
        // this.companyname.push(element.company_name)
        // this.invoice_date.push(element.invoice_date)
        // this.deliverychallanno.push(element.dc_number)
        //  this.duplicate_cmpnynme= [...new Set( this.smsdata)];

        //  if(this.duplicate_cmpnynme){
        //    let strgarray:any=[]
        //    this.duplicate_cmpnynme.forEach((ele:any)=>{
        //     strgarray.push(ele.storage_location)
        //    })
        //    console.log(strgarray);

        //  }
        // })
      } else {
        this.smsdata = "";
        this.smsdata1 = "";
        this.alertcall.showWarning("Warning", res["message"]);
      }
    });
  }

  openNewFileUploadModal(data: any , type:any) {
    // this.selectedfiles = [];
    this.dialog.open(data, {
      width: '800px'

    })
if(type === 'Update'){
  this.getexistingfiles()
}



  }

  // uploaddmrfiles(fileInput: any) {
  //   if (
  //     fileInput &&
  //     fileInput.target &&
  //     fileInput.target.files &&
  //     fileInput.target.files.length > 0
  //   ) {
  //     this.fileUploadUrlsdmr = fileInput.target.files;
  //     console.log(this.fileUploadUrlsdmr, "fileUploadUrlsdmr");
  //     for (const file of this.fileUploadUrlsdmr) {

  //       if (this.isFileSelected(file.name)) {
  //         this.alertcall.showWarning('Alert', 'Some files are already selected');
  //       } else {
  //         this.filenamearray.push(file.name)
  //         this.selectedfiles.push(file)
  //       }
  //     }
  //   }
  // }

  isFileSelected(selectedFilePath: string): boolean {
    return this.selectedfiles.some((file: any) => file.name == selectedFilePath);
  }

  //material
  getmaterialreqdata(ev: any) {
    console.log(ev.target.value);
    this.selectedreqdata = ev.target.value
    if (this.selectedreqdata.length > 2) {
      this.getmaterialreqData()
    }
    if (!this.selectedreqdata) {
      this.getmaterialreqData()
    }
  }
  getmaterialreqData() {
    let obj = {
      command: "pnd",
      lmt: 100000,
      pid: 1,
      field:"number",
      "key": this.selectedreqdata
    };
    this.custservice.getmaterialrequestdata(obj).subscribe((res: any) => {
      if(res.data){
        this.materialreqdata = res.data;
      }
    });
  }
  editgidata(data: any, dialog: any) {
    this.ginumber = data.number
    this.dialog.open(dialog, {
      width: "400px",
    });
    this.editednumber = data.number;
    this.editModel.reason=""
  }
  saveeditreason() {
    this.issubmitClicked = true;
    this.displayedColumns = [
      "action",
      "sno",
      "material_code",
      "material_description",
      "unit_of_measurement",
      "storage_location",
      "request_quantity",
      "quantity",
      "item_remarks",
      // "batch_no",
      "valution_type",

    ];
    let obj = {
      command: "mat",
      field: "number",
      number: this.editednumber,
    };
    this.custservice.addgoodsissue(obj).subscribe((res: any) => {
      this.dialog.closeAll();
      this.fileupdate=true;
      this.viewData = false
      this.actiondisabled = false;
      this.editdataa = res.data[0];
      this.saveddataarray = res.data;
      this.dataSource.data = this.saveddataarray;
      (this.model1.dateee = moment(this.editdataa.date).format("YYYY-MM-DD")),
        (this.model1.companyname = this.editdataa.company_name),
        // (this.model1.tocompany = this.editdataa.to_company_name),
        (this.model1.contractorname = this.editdataa.contractor_name),
        (this.model1.receivername = this.editdataa.receiver_name),
        (this.model1.requestnumber = this.editdataa.request_number),
        (this.model1.requestdate = moment(this.editdataa.request_date).format(
          "YYYY-MM-DD"
        )),
        (this.model1.locations = this.editdataa.locations),
        (this.model1.comments = this.editdataa.comments),
        (this.model1.vehiclenumber = this.editdataa.vehicle_number),
        (this.model1.requesttype = this.editdataa.issue_type);
      (this.model1.work_order_number = this.editdataa.work_order_number)
      this.demo1TabIndex = 0;
      this.btn = "Update";
    });
  }
  onChange() {
    console.log('Selected:', this.columnname);
    this.searchData = ""
  }
  search() {
    console.log(this.searchData);
    let obj = { "command": "lst", "field": this.columnname, "key": this.searchData, "lmt": this.pageSize, "pid": this.pageIndex }
    if (this.searchData.length > 2 && this.columnname) {
      this.custservice.getSearchGoodsIssue1(obj).subscribe((res: any) => {
        console.log(res);
        this.reaData = false;
        this.totalRecords = res?.count;
        this.dataSourcemain.data = res.data;
        if (res.data.length == 0) {
          this.reaData = true;
        }
      });
    } else if (!this.searchData) {
      this.getData()
      this.columnname = ""
    }
  }

  //view
  viewreason(data: any) {
    this.displayedColumns = [
      "sno",
      "material_code",
      "material_description",
      "unit_of_measurement",
      "storage_location",
      "request_quantity",
      "quantity",
      "item_remarks",
      "valution_type",

    ];
    this.viewnumber = data.number;
    let obj = {
      "command": "mat",
      "number": data.number,
      "field": "number"
    }
    this.custservice.addgoodsissue(obj).subscribe((res: any) => {
      if (res.data.length > 0) {
        this.viewData = true
        // this.dialog.closeAll();
        this.editdataa = res.data[0];
        this.saveddataarray = res.data;
        this.dataSource.data = this.saveddataarray;
        (this.model1.dateee = this.editdataa.date !== null ? moment(this.editdataa.date).format("YYYY-MM-DD") : "NA"),
          (this.model1.companyname = this.editdataa.company_name !== "" ? this.editdataa.company_name : "NA"),
          // (this.model1.tocompany = this.editdataa.to_company_name!==""?this.editdataa.to_company_name:"NA"),
          (this.model1.contractorname = this.editdataa.contractor_name !== "" ? this.editdataa.contractor_name : "NA"),
          (this.model1.receivername = this.editdataa.receiver_name !== "" ? this.editdataa.receiver_name : "NA"),
          (this.model1.requestnumber = this.editdataa.request_number !== null ? this.editdataa.request_number : "NA"),
          (this.model1.requestdate = (this.editdataa.request_date !== null || this.editdataa.request_date !== "") ? moment(this.editdataa.request_date).format(
            "YYYY-MM-DD"
          ) : "NA"),
          (this.model1.locations = this.editdataa.locations !== "" ? this.editdataa.locations : "NA"),
          (this.model1.comments = this.editdataa.comments !== "" ? this.editdataa.comments : "NA"),
          (this.model1.vehiclenumber = this.editdataa.vehicle_number !== null ? this.editdataa.vehicle_number : "NA"),
          (this.model1.requesttype = this.editdataa.issue_type !== "" ? this.editdataa.issue_type : "NA");
        (this.model1.work_order_number = this.editdataa.work_order_number !== null ? this.editdataa.work_order_number : 'NA')
        this.demo1TabIndex = 0;
        // this.btn = "Update";

      } else {
        this.alertcall.showWarning("Accepted", "No Data Found");
      }
    });
  }
  BacktoList() {
    this.demo1TabIndex = 2;
    this.viewData = false
    this.displayedColumns = [
      "action",
      "sno",
      "material_code",
      "material_description",
      "unit_of_measurement",
      "storage_location",
      "request_quantity",
      "quantity",
      "item_remarks",
      // "batch_no",
      "valution_type",

    ];
  }
refreshPndData(){
  this.pndsearchnumber='';
  this.pageIndexpnd=0;
  this.pageSizepnd=10;
  this.getDatapnd();
}
  //on change tab
  onTabChanged() {
    if (this.demo1TabIndex === 2) {
      this.pageSize=10
      this.pageIndex=1
      this.getData()
      this.requestnumbervalue=false
    } else if(this.demo1TabIndex === 1){
      this.viewData = false
      this.model1 = {};
      this.model1={
        requestnumber:'',
        requesttype:'',
        dateee:moment(new Date()).format("YYYY-MM-DD")
      }
      this.saveddataarray = [];
      this.dataSource.data = [];
      this.SNO = [];
      this.matCode = [];
      this.matName = [];
      this.logsearchData=""
      this.UOM = [];
      this.logsearchData=""
      this.storageLocation = [];
      this.ReqQTY = [];
      this.qty = [];
      this.itemremarks = [];
      this.batchno = [];
      this.smsdata = "";
      this.smsdata1 = "";
      this.columnname="";
      this.searchData="";
      this.filedata=[]
      this.router.navigate(['/inventory/goodsissue'])
      this.pageSizepnd =10,
      this.pageIndexpnd=0
      this.getDatapnd()
      this.requestnumbervalue=false
    }else if(this.demo1TabIndex == 3){
      this.pageSizeallpnd=10
      this.pageIndexallpnd=0
      this.getDataallpnd()
  }else if (this.demo1TabIndex === 4) {
      // alert(12);
      this.viewData = false
      this.model1 = {};
      this.model1={
        requestnumber:'',
        requesttype:'',
        dateee:moment(new Date()).format("YYYY-MM-DD")
      }
      this.saveddataarray = [];
      this.dataSource.data = [];
      this.SNO = [];
      this.matCode = [];
      this.matName = [];
      this.UOM = [];
      this.logsearchData=""
      this.storageLocation = [];
      this.ReqQTY = [];
      this.qty = [];
      this.itemremarks = [];
      this.batchno = [];
      this.smsdata = "";
      this.smsdata1 = "";
      this.columnname="";
      this.searchData="";
      this.filedata=[]
      this.getData();
      this.router.navigate(['/inventory/goodsissue'])
      this.dataSourcemainlog.sort = this.sort;
      this.requestnumbervalue=false
    }else{
      this.getData();
    }

  }
  clearpage1() {
    this.requestnumbervalue=false
    this.issubmitClicked = false;
    this.selectedfiles = [];
    this.filedata = []
    this.actiondisabled = true;
    this.viewData = false
    this.model1 = {};
    this.saveddataarray = [];
    this.dataSource.data = [];
    this.SNO = [];
    this.isAddNewClicked = true;
    this.matCode = [];
    this.matName = [];
    this.UOM = [];
    this.model1={
      requestnumber:'',
      requesttype:'',
      dateee:moment(new Date()).format("YYYY-MM-DD")
    }
    console.log(this.model1.dateee,"llll");
    
    this.storageLocation = [];
    this.ReqQTY = [];
    this.qty = [];
    this.itemremarks = [];
    this.batchno = [];
    this.smsdata = "";
    this.smsdata1 = "";
    this.btn = 'Save'
    // this.model = {}
    this.model.valutiontype = '';
    this.model2 = {}
    // this.selectedfiles=[]
    // this.filedata=[]
    this.filenamearray = []
    this.getTransactionId();
  }

  onVehicleNumberSelected(event: MatAutocompleteSelectedEvent) {
        this.model1.vehiclenumber = event.option.value.toUpperCase();
  }

  updatebtn() {
    if (!this.model1.requestnumber || !this.model1.dateee || !this.model1.companyname || !this.model1.contractorname || !this.model1.requestdate || !this.model1.receivername || !this.model1.locations || !this.model1.vehiclenumber || !this.model1.requesttype  || !this.model1.work_order_number)  {
      return true;
    }
    else {
      return false;
    }
  }
  
  trimInput() {
    this.model1.requestnumber = this.model1.requestnumber.trim();
    this.model1.receivername = this.model1.receivername.trim();
    this.model1.locations = this.model1.locations.trim();
    this.model1.work_order_number = this.model1.work_order_number.trim();
    this.model1.comments = this.model1.comments.trim()
  }

// pending approval
onpageeventpnd(event: any) {
  this.pageIndexpnd = event.pageIndex;
  this.pageSizepnd = event.pageSize;
  this.getDatapnd();
}
pndSearchData(){
  if(this.pndsearchnumber.length > 2){
      if(this.masterpaginatorpnd){
      this.masterpaginatorpnd.firstPage();
    }
    this.getDatapnd()
  }else if(this.pndsearchnumber.length === 0){
    this.getDatapnd()
  }
  }

getDatapnd() {
  let obj:{ [key: string]: any }={
    command: "pnd",
    field: "number",
    "lmt":this.pageSizepnd,
    "pid":this.pageIndexpnd + 1,
    "showLoader": 'hide'
  }
  if (typeof this.pndsearchnumber === 'string' && this.pndsearchnumber.length > 2) {
    obj.key = this.pndsearchnumber;
  } 
  this.custservice.addrequestmaterialdata(obj).subscribe((res: any) => {
      this.dataSourceListpnd.data=[]
      this.totalRecordspnd=0
      this.reaDatapnd = false;
      if(res.data.length>0){
        this.totalRecordspnd = res?.count;
        this.dataSourceListpnd.data = res.data;
      }
      if (res.data.length == 0) {
        this.reaDatapnd = true;
      }
  });
}
//end

 //all pnd
 onpageeventallpnd(event: any) {
  this.pageIndexallpnd = event.pageIndex;
  this.pageSizeallpnd = event.pageSize;
  this.getDataallpnd();
}
allpndSearchData(){
  if(this.allpndsearchnumber.length > 2){
    if(this.mrpaginatorallpnd){
      this.mrpaginatorallpnd.firstPage();
    }
    this.getDataallpnd()
  }else if(this.allpndsearchnumber.length === 0){
    this.getDataallpnd()
  }
  }
getDataallpnd() {
  const postData = new FormData();
  postData.append("command","lap");
  postData.append("lmt",this.pageSizeallpnd);
  postData.append("pid",this.pageIndexallpnd + 1);
  postData.append("document_name", "MaterialRequest" );
  if(this.allpndsearchnumber && this.allpndsearchnumber.length > 2){
    postData.append("key", this.allpndsearchnumber);
  }
  this.custservice.DocumentSign(postData).subscribe((res: any) => {
    this.totalRecordsallpnd =0
    this.dataSourceListallpnd.data =[]
    this.reaDataallpnd = false;
    if(res.data.length>0){
    this.totalRecordsallpnd = res?.count;
    this.dataSourceListallpnd.data = res.data;
  }
  if (res.data.length == 0) {
    this.reaDataallpnd = true;
  }
  });
}
//
}

