import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";

import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private location: Location
  ) {
    super(router, keycloak);
  }
  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }
    return this.authenticated;
  }
  // async isAccessAllowed(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ): Promise<boolean | UrlTree> {
  //   console.log(this.location.path());
  //   if (!this.authenticated) {
  //     // await this.keycloak.login({
  //     //   redirectUri: window.location.origin + state.url,
  //     // });
  //   }
  //   return this.authenticated;
  // }
}
