<div class="material-tab">
    <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms" [(selectedIndex)]="demo1TabIndex">
        <mat-tab>
            <ng-template mat-tab-label> {{ totalData[0].audity_name}} </ng-template>
            <ng-template matTabContent>
                <mat-card class="dashboard-card">
                    <mat-card-content class="dashboard-card-content">
                       <form #myForm="ngForm" (ngSubmit)="submitData(myForm)">
                        <div class="row mb-2">
                            <div class="col-md-2">
                                <label>Material Name</label>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="example-full-width" appearance="none" class="matformfieldstyles">
                                    <input type="text" placeholder="Search Material Name" aria-label="Number" matInput
                                        style="border:none" class="inputdropdown" name="materialName"
                                        [(ngModel)]="model.materialName" (input)="getData($event)"
                                        [matAutocomplete]="auto1" />
            
                                    <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectionChange($event)">
            
                                        <mat-option *ngFor="let item of materialData" [value]="item">
                                            {{item.material_description}}</mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="col-md-2">
                                <label>Material Code</label>
                            </div>
                            <div class="col-md-4">
                                <input type="text" placeholder="Material Code" class="inputstyles" name="materialcode" [(ngModel)]="model.materialcode" readonly>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <label>Storage Location</label>
                            </div>
                            <div class="col-md-4">
                                <input type="text" placeholder="Storage Location" class="inputstyles" name="storagelocation" [(ngModel)]="model.storagelocation" readonly>
                            </div>
            
                            <div class="col-md-2">
                                <label>Unit Of Measurement</label>
                            </div>
                            <div class="col-md-4">
                                <input type="text" placeholder="Unit Of Measurement" class="inputstyles" name="uom" [(ngModel)]="model.uom" readonly>
                            </div>
            
                        </div><br>
                        <div class="row">
                            <div class="col-md-2">
                                <label>Closing Stock</label>
                            </div>
                            <div class="col-md-4">
                                <input type="text" placeholder="Closing Stock" class="inputstyles" name="closingstock" [(ngModel)]="model.closingstock" readonly>
                            </div>
            
                            <div class="col-md-2">
                                <label>Already Scaneed Stock</label>
                            </div>
                            <div class="col-md-4">
                                <input type="text" placeholder="Already Scaneed Stock" class="inputstyles" name="alreadyscannedstock" [(ngModel)]="model.alreadyscannedstock" readonly>
                            </div>
            
                        </div><br>
                        <div class="row">
                            <div class="col-md-2">
                                <label>Physical Stock</label>
                            </div>
                            <div class="col-md-4">
                                <input type="text" placeholder="Physical Stock" class="inputstyles" name="physicalstock" [(ngModel)]="model.physicalstock"
                                (input)="getPhysicalStock($event)">
                            </div>
            
                            <div class="col-md-2">
                                <label>Difference</label>
                            </div>
                            <div class="col-md-4">
                                <input type="text" placeholder="Difference" class="inputstyles" name="difference" [(ngModel)]="model.difference" readonly>
                            </div>
            
                        </div><br>
                        <div class="row">
                            <div class="col-12" style="text-align: end;">
                                <button  mat-raised-button class="savebutton" color="secondary" type="button" (click)="finishAudit()">Finish Audit</button>&nbsp;&nbsp;&nbsp;
                                <button  mat-raised-button class="savebutton" color="secondary" type="submit">Save and Scan Next</button>&nbsp;&nbsp;&nbsp;
                                <button type="button" mat-raised-button (click)="clearForm()">Cancel</button>
                            </div>
                        </div>
                       </form>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

