import { I } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { KeycloakService } from 'keycloak-angular';
import { AlertCallsService } from 'src/app/auth/alert-calls.service';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-companys',
  templateUrl: './companys.component.html',
  styleUrls: ['./companys.component.scss']
})
export class CompanysComponent implements OnInit {
  displayedColumns: any[] = [
    'sno',
    'code',
    "project_name",
    'name',
    'billing_address',
    "shipping_address",
   'contact_number',
    'gst_number',
    'pan_number',
    'city',
    'state',
    'country',
    'pincode',
    'prefix',
    'logo',
    'business_unit_name',
    'cluster_name',
    'vertical_name',
    // 'ActiveStatus',
    'action'
  ];
  dataSource = new MatTableDataSource();
  totalRecords=0;
  companyname:any;
  address:any;
  contactnumber:any;
  gstnumber:any;
  pincode:any;
  city:any;
  state:any;
  country:any;
  pageIndex: any=1;
  pageSize:any=10;
  vendordata: any;
  companyeditname: any;
  addressedit: any;
  contactnumberedit: any;
  gstnumberedit: any;
  cityedit: any;
  countryedit: any;
  stateedit: any;
  pincodeedit: any;
  companyId: any;
  companyCode: any;
  companydata: any=false;
  prefix: any;
  prefixedit: any;
  deleteId: any;
  searchData: any;
  pan: any;
  panedit: any;
  shippingaddress: any;
  projectname: any;
  projectnameedit: any;
  shippingaddressedit: any;
  cmpydata: any[]=[];
  projectNameData: any;
  projectNameDataedit: any;
  selectedcompanylogo: any;
  companylogoData: any;
  selectedcompanylogoedt: any;
  logoedit: any;
  logo: any;
  userDetails: any={};
  verticalmasterData: any[]=[];
  ClusterMasterdata: any[]=[];
  BusinessUnitdata: any[]=[];
  verticalCode: any;
  clusterCode: any;
  verticalname: any;
  clustername: any;
  businessunitCode: any;
  businessunitname: any;
  verticalnameedit: any;
  clusternameedit: any;
  businessunitnameedit: any;
  verticalCodeedit: any;
  clusterCodeedit: any;
  businessunitCodeedit: any;
  @ViewChild('masterpaginator') masterpaginator: MatPaginator;
  constructor(private dialog:MatDialog,private custservice:CustomerService,
    private keycloackService: KeycloakService,
    private alertcall:AlertCallsService) { }


  ngOnInit(): void {
    this.userDetails = this.keycloackService.getKeycloakInstance().tokenParsed
    this.projectname=""
    this.getCompanysdata()
    this.getcompanydata()
    // this.getCompanylogosdata();
    this.getlogoData();
    this.selectedprojectedit();
    this.selectedproject();
    this.getVerticalMaster();
    this.getClusterMaster();
    this.getBusinessUnit();

  }
  onpageevent(event:any){
    this.pageIndex=event.pageIndex+1;
    this.pageSize= event.pageSize;
    this.getCompanysdata()
  }
  getCompanysdata(){
    let obj={
      "command" : "lst",
      "lmt" : this.pageSize,
      "pid" : this.pageIndex,
      "key" : ""||this.searchData,
      showLoader: 'hide'
    }
    this.custservice.getcompanymasterdata(obj).subscribe((res:any)=>{
     this.companydata = false
     this.totalRecords = res?.count;
     this.dataSource.data = res.data;
     if(res.data.length==0){
      this.companydata=true
     }
    })
  }
  addcompany(data:any){
    this.projectname="";
    this.companyname="";
    this.address="";
    this.shippingaddress="";
    this.contactnumber="";
    this.gstnumber="";
    this.pan="";
    this.city="";
    this.state="";
    this.country="";
    this.pincode="";
    this.prefix="";
    this.logo="";
    this.verticalname="";
    this.clustername="";
    this.businessunitname="";

this.dialog.open(data,{
  width:'800px'
})
  }
  savecompanydata(form:any){
let obj={
 "command" : "add",
 "project_id":this.projectname,
 "project_name":this.projectNameData,
  "name": this.companyname,
  "address_1": this.address,
  "address_2":this.shippingaddress,
  "contact_number": this.contactnumber,
  "gst_number": this.gstnumber,
  "pan_number": this.pan,
  "pin_code": this.pincode,
  "city": this.city,
  "state": this.state,
  "country": this.country,
  "prefix": this.prefix,
  "logo":this.logo === null ? "":this.logo,
  vertical_name:this.verticalname,
  cluster_name:this.clustername,
  business_unit_name:this.businessunitname,
  vertical_code:this.verticalCode,
  cluster_code:this.clusterCode,
  business_unit_code:this.businessunitCode
}
this.custservice.addcompanymaster(obj).subscribe((res:any)=>{
  if(res&&res['status_code']=='200'){
    this.alertcall.showSuccess('Accepted', 'Added Successfully');
    this.dialog.closeAll() ;
    form.reset()
    this.getCompanysdata()
  }else{
    this.alertcall.showWarning('Accepted', res['message']);
  }
})
  }
  editdata(row1:any,data:any){
    console.log(row1,"rrrrrrrrrrrrrrrrr");
    this.dialog.open(data,{
      width:'800px'
    })
    this.companyId=row1.id;
    this.companyCode=row1.code;
    this.projectnameedit=row1.project_id,
    this.shippingaddressedit=row1.address_2,
    this.companyeditname=row1.name;
    this.addressedit=row1.address_1;
    this.contactnumberedit=row1.contact_number;
    this.gstnumberedit=row1.gst_number;
    this.cityedit=row1.city;
    this.stateedit=row1.state;
    this.countryedit=row1.country;
    this.pincodeedit=row1.pin_code;
    this.panedit=row1.pan_number;
    this.prefixedit=row1.prefix;
    this.logoedit=row1.logo;
    this.verticalnameedit=row1.vertical_name;
    this.clusternameedit=row1.cluster_name;
    this.businessunitnameedit=row1.business_unit_name,
    this.verticalCode=row1.vertical_code,
    this.clusterCode=row1.cluster_code,
    this.businessunitCode=row1.business_unit_code
    this.selectedprojectedit()
  }
  savecompanyeditdata(fredit:any){
    let obj={
     "command" : "edt",
     "project_id":this.projectnameedit,
     "project_name":this.projectNameDataedit,
      "name": this.companyname,
      "address_1": this.addressedit,
      "address_2":this.shippingaddressedit,
     
      "contact_number": this.contactnumberedit,
      "gst_number": this.gstnumberedit,
      "pan_number": this.panedit,
      "pin_code": this.pincodeedit,
      "city": this.cityedit,
      "state": this.stateedit,
      "country": this.countryedit,
      'id':this.companyId,
      'code':this.companyCode,
      "prefix": this.prefixedit,
      "logo":this.logoedit,
      vertical_name:this.verticalnameedit,
      cluster_name:this.clusternameedit,
      business_unit_name:this.businessunitnameedit,
      vertical_code:this.verticalCodeedit,
      cluster_code:this.clusterCodeedit,
      business_unit_code:this.businessunitCodeedit
    }
    this.custservice.updatecompanymasterdata(obj).subscribe((res:any)=>{
      if(res&&res['status_code']=='200'){
        this.alertcall.showSuccess('Accepted', 'Updated Successfully');
        this.dialog.closeAll() ;
        fredit.reset()
        this.getCompanysdata()
      }else{
        this.alertcall.showWarning('Accepted', res['message']);
      }
    })
  }
  deletedata(rw:any,data:any){
    this.deleteId=rw.id
this.dialog.open(data,{
  width:'400px'
})
  }
  deleteFile(){
    let obj={
      "command" : "del", 
      "id" :  this.deleteId
    }
    this.custservice.deletecompanymasterdata(obj).subscribe((res:any)=>{
      if(res&&res['status_code']=='200'){
        this.alertcall.showSuccess('Accepted', 'Deleted Successfully');
        this.dialog.closeAll() ;
       
        this.getCompanysdata()
      }else{
        this.alertcall.showWarning('Accepted', res['message']);
      }
    })
  }
  searchdata(){
  this.pageIndex = 1;
    // this.searchData=event.target.value;
    if(this.searchData.length>2){  
      this.masterpaginator.firstPage();
      this.getCompanysdata()
    }
    if(!this.searchData){
      this.searchData=""
       this.getCompanysdata()
    }
  }
  keyPressAlphaNumeric(event:any) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  getcompanydata(){
   
    // let obj={
    //   "command":"prj"
    // }
    // this.custservice.getcompanymasterdata(obj).subscribe((res:any)=>{
      let obj={
        // "command" : "lst",
        // "lmt" : 100000,
        // "pid" : this.pageIndex,
        // "key" : ""||this.searchData
        email:this.userDetails.email,
        role:this.userDetails?.resource_access?.geps?.roles[0]
      }
      // this.custservice.projectmaster(obj).subscribe((res: any) => {
        // this.custservice.getUserRoles(obj).subscribe((res: any) => {
       this.custservice.getUserListProjects(obj).subscribe((res: any) => {
      this.cmpydata=res.data
    })
  }
  selectedproject(){
    this.cmpydata.forEach((element:any) => {
      // element.id==this.projectname || element.project_id == this.projectname || 
      if(element.proj_id == this.projectname){
        // alert(element.project_name + element.project_id)
        // this.projectNameData=element.project_name
        this.projectNameData=element.name
        // console.log(this.projectNameData)
      }
    });
  }
  selectedprojectedit(){
    this.cmpydata.forEach((element:any) => {
      // element.id==this.projectnameedit  || element.project_id == this.projectnameedit ||
      if( element.proj_id == this.projectnameedit){
        // this.projectNameDataedit=element.project_name
        this.projectNameDataedit=element.name
      }
      
    });
  }

  //logo
  getCompanylogosdata(){
    let obj={
      "command" : "mat",
    }
    this.custservice.getcompanymasterdata(obj).subscribe((res:any)=>{
      console.log(res);
      
    })
  }

  getlogoDATA1(ev: any) {
    console.log(ev.target.value);
    this.selectedcompanylogo = ev.target.value
    if (this.selectedcompanylogo.length > 2) {
      this.getlogoData()
    }else{
      this.getlogoData()
    }
  }
  getlogoDATA2(ev: any) {
    console.log(ev.target.value);
    this.selectedcompanylogoedt = ev.target.value
    if (this.selectedcompanylogoedt.length > 2) {
      this.getlogoData()
    }else{
      this.getlogoData()
    }
  }
  getlogoData() {
    let obj = {
      "command": "logo",
    }
    this.custservice.getcompanymasterdata(obj).subscribe((res: any) => {
      if(res){
        this.companylogoData = res.data
        console.log(this.companylogoData);
        // this.getCompanylogosdata();
      }
    })
  }

  //vertical Data
  getVerticalMaster(){
    let params={
      command : "lst",
      lmt: 100000,
      pid: this.pageIndex,
      key: this.searchData
    }
    this.custservice.VerticalMaster(params).subscribe((res:any)=>{
      // if(res&&res['status']=='Success'){
        if(res.data){
      this.verticalmasterData = res.data;
    }
    })
  }
    selectedVerticalData(){
      this.verticalmasterData.forEach((ele:any) => {
        if(ele.name == this.verticalname){
          this.verticalCode = ele.code;
        }
        
      });
    }
    selectedVerticalDataedit(){
      this.verticalmasterData.forEach((ele:any) => {
        if(ele.name == this.verticalnameedit){
          this.verticalCodeedit = ele.code;
        }
        
      });
    }
    //cluster
    getClusterMaster(){
      let params={
        command : "lst",
        lmt: 10000000,
        pid: this.pageIndex,
        key: this.searchData
      }
      this.custservice.ClusterMaster(params).subscribe((res:any)=>{
        // if(res&&res['status']=='Success'){
          if(res.data){
        this.ClusterMasterdata = res.data;
      }
      })
    }
    selectedClusterMasterData(){
      this.ClusterMasterdata.forEach((ele:any) => {
        if(ele.name == this.clustername){
          this.clusterCode = ele.code;
        }
        
      });
    }
    selectedClusterMasterDataedit(){
      this.ClusterMasterdata.forEach((ele:any) => {
        if(ele.name == this.clusternameedit){
          this.clusterCodeedit = ele.code;
        }
        
      });
    }
    getBusinessUnit(){
      let params={
        command : "lst",
        lmt: 1000000,
        pid: this.pageIndex,
        key: this.searchData
      }
      this.custservice.BusinessUnit(params).subscribe((res:any)=>{
        // if(res&&res['status']=='Success'){
          if(res.data){
        this.BusinessUnitdata = res.data;
      }
      })
    }
    selectedBusinessUnitData(){
      this.BusinessUnitdata.forEach((ele:any) => {
        if(ele.name == this.businessunitname){
          this.businessunitCode = ele.code;
        }
        
      });
    }
    selectedBusinessUnitDataedit(){
      this.BusinessUnitdata.forEach((ele:any) => {
        if(ele.name == this.businessunitCodeedit){
          this.businessunitnameedit = ele.code;
        }
        
      });
    }
    keyPressNumbers(evt: any) {
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
  
      return true;
    }
}
