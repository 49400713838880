<!-- <div class="page-breadcrumb page-header">
    <div class="row">
        <div class="col-12 align-self-center">
            <h4 class="page-title">Quality Check</h4> -->
<!-- <div class="d-flex align-items-center m-t-10">
                <span class="m-r-20">Start Date : {{startDate | date}} </span>
                <span>End Date : {{endDate | date}} </span>
            </div> -->
<!-- </div>
    </div>
</div> -->
<div class="material-tab">
    <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms" #tabGroup [(selectedIndex)]="demo1TabIndex" (selectedTabChange)="onTabChanged($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                Quality Check
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>

                        <form #myform="ngForm" (ngSubmit)="savefinaldata(myform)">
                            <div class="row">
                                <div class="col-md-0 ml-2" *ngIf="!viewData">
                                    <button mat-raised-button class="mt-0 savebutton " type="submit"
                                        style="background-color: rgba(0, 0, 0, 0.12);float: right; margin-bottom: 2%;"
                                        [disabled]="!MATDATA || initialdata" color="secondary">{{btn}}</button>
                                </div>
                                <div  class="col-md-0 ml-2 mb-2" *ngIf="!viewData">
                                    <button mat-raised-button type="submit" (click)="clearpage1()"
                                    class="savebutton">Add New</button>
                                </div>
                                <div *ngIf="viewData" class="col-md-0 ml-2 mb-2 ">
                                    <button mat-raised-button type="button" (click)="BacktoList()"
                                        class="savebutton">Back</button>
                                </div>
                                <div class="col-md-0" *ngIf="!viewData">
                                    <button [disabled]="initialdata" mat-raised-button class="savebutton" type="button"
                                        style="left:6%;margin-bottom: 2%;" color="secondary"
                                        (click)="opendmrmodel(dmrmodel)">Select DMR</button>
                                </div>
                                <div class="col-md-1" *ngIf="!viewData">
                                    <button [disabled]="initialdata" type="button" mat-raised-button
                                        class="attachfile ml-1" color="secondary" (click)="openNewFileUploadModal(newFileUploadModal)">
                                        Attach File&nbsp; <mat-icon
                                            class="attachFileIcon">file_upload</mat-icon></button>
                                    <!-- <div class="file">
                                        <input hidden class="form-control-file" name="file" type="file" multiple
                                            (change)="uploadqcfiles($event)" #autoFileUpload2>
                                        <ng-container *ngIf=" fileUploadUrlsqc.length > 0">
                                            <div *ngFor="let item of filenamearray;let i=index;">{{i+1}}.&nbsp;{{item}}
                                            </div> -->
                                            <!-- <span style="margin-left:3px">Selected : {{fileUploadUrlsqc.length}} files</span>   -->
                                        <!-- </ng-container>
                                    </div> -->
                                </div>
                                <div *ngIf="!viewData && txnId" class="col col-md-0 mt-1 text-right">
                                    <p *ngIf="!viewData && txnId">Transaction Id: {{txnId}}</p>
                                    <label class="mb-0 mt-0" *ngIf="model1.dmr_date">DMR Date: {{model1.dmr_date| date: 'dd/MM/yyyy'}}</label><br/>
                                    <label class="mb-0 mt-0" *ngIf="model1.dmr_created_date">DMR Created Date: {{model1.dmr_created_date| date: 'dd/MM/yyyy'}}</label><br/>
                                    <label class="mb-0 mt-0" *ngIf="model1.receiveddateee">Received From Stores: {{model1.receiveddateee| date: 'dd/MM/yyyy'}}</label>
                                </div>
                                <div *ngIf="viewData" class="col-md-11 mt-1 text-right">
                                    <p *ngIf="editednumber" style="font-size: 16px;font-weight: 500;">View of Document
                                        Number:
                                        {{editednumber}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <label>Date<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData">{{model1.dateee}}</p>
                                    <span *ngIf="initialdmr && !viewData">
                                        <input type="date" style="text-transform: uppercase;" class="inputstyles"
                                            name="dateee" [(ngModel)]="model1.dateee" [max]="today" dateFormat="yyyy/mm/dd"></span>
                                    <span *ngIf="editdmr && !viewData">
                                        <input type="date" style="text-transform: uppercase;" class="inputstyles"
                                            name="dateee" [(ngModel)]="model1.dateee" dateFormat="yyyy/mm/dd" readonly>
                                    </span>
                                </div>
                                
                                <!-- <div class="col-md-3 ">
                                    <label class="mb-0 mt-0" *ngIf="model1.dmr_date">DMR Date: {{model1.dmr_date| date: 'dd/MM/yyyy'}}</label>
                                    <label class="mb-0 mt-0" *ngIf="model1.dmr_created_date">DMR Created Date: {{model1.dmr_created_date| date: 'dd/MM/yyyy'}}</label>
                                    <label class="mb-0 mt-0" *ngIf="model1.receiveddateee">Received From Stores: {{model1.receiveddateee| date: 'dd/MM/yyyy'}}</label> -->
                                    <!-- <span *ngIf="initialdmr">
                                        <input type="date" style="text-transform: uppercase;" class="inputstyles"
                                            name="receiveddateee" [(ngModel)]="model1.receiveddateee" dateFormat="yyyy/mm/dd"></span>
                                    <span *ngIf="editdmr">
                                        <input type="date" style="text-transform: uppercase;" class="inputstyles"
                                            name="receiveddateee" [(ngModel)]="model1.receiveddateee" dateFormat="yyyy/mm/dd" readonly>
                                    </span> -->
                                <!-- </div> -->
                            
                                <div class="col-md-3">
                                    <!--   {{userDeviceData}}/ -->
                                    <label>DMR Number<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData">{{model1.dmrnumber}}</p>
                                    <div *ngIf="initialdmr && !viewData" class="d-flex">
                                        <input  type="text" placeholder="DMR Number" class="inputstyles"
                                        name="dmrnumber" [(ngModel)]="model1.dmrnumber" readonly>
                                        <i ngbTooltip="View Files" placement="bottom" *ngIf="model1.dmrnumber" class="fa fa-paperclip viewAttachment" aria-hidden="true" (click)="viewAttachment(viewFilemodel,model1.dmrnumber)"></i>
                                        <!-- <select class="dropdownstyles" name="dmrnumber" [(ngModel)]="model1.dmrnumber" (change)="selecteddmr()"
            (click)="keyup()">
                <option [value]="undefined" selected disabled>Select One</option>
               <option *ngFor="let item of dmrDATA" [ngValue]="item.number">{{item.number}}</option>
              
            </select> -->
                                        <!-- <mat-form-field class="example-full-width" style=" width:100%;
            border: 1px solid rgba(0, 0, 0, 0.12);
            border-radius: 5px;
            height: 35px;" appearance="none">"Search DMR Number " aria-label="Number"
                                                matInput style="border:none"
                                            <input type="text" placeholder= class="inputdropdown1" name="dmrnumber"
                                                [(ngModel)]="model1.dmrnumber" (keyup)="getdmrdata($event)"
                                                [matAutocomplete]="auto11" />

                                            <mat-autocomplete autoActiveFirstOption #auto11="matAutocomplete"
                                                (optionSelected)="selecteddmr()">

                                                <mat-option *ngFor="let item of dmrDATA" [value]="item.number">
                                                    {{item.number}}</mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field> -->
                                    </div>
                                    <span *ngIf="editdmr && !viewData">
                                        <input type="text" class="inputstyles" name="dmrnumber" placeholder="DMR Number"
                                            [(ngModel)]="model1.dmrnumber" readonly>
                                    </span>

                                    <!-- <mat-form-field class="example-full-width" style="width: 89%; border: 1px solid;
            border-radius: 5px;height: 66%;" appearance="none">
                            <input type="text" placeholder="Search DMR " aria-label="Number" matInput
                                style="border:none;margin-bottom: 2%;" class="inputdropdown" name="dmrnumber" [(ngModel)]="model1.dmrnumber" 
                                (keyup)="getdmrDATA($event)" 
                                [matAutocomplete]="auto4" />

                            <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete"
                            (optionSelected)="selecteddmr()">

                                <mat-option *ngFor="let item of dmrDATA" [value]="item.number" name="dmrnumber" [(ngModel)]="model1.dmrnumber" >
                                    {{item.number}}</mat-option>
                            </mat-autocomplete>
                        </mat-form-field> -->
                                </div>
                                <!-- <div class="col-md-3"> -->
                                    <!-- <label>Dmr Date<span class="mandetory">*</span></label>
                                        <input type="date" style="text-transform: uppercase;" class="inputstyles"
                                            name="dmrdateee" [(ngModel)]="model1.dmrdateee" dateFormat="yyyy/mm/dd" readonly> -->
                                    <!-- <span *ngIf="editdmr">
                                        <input type="date" style="text-transform: uppercase;" class="inputstyles"
                                            name="dmrdateee" [(ngModel)]="model1.dmrdateee" dateFormat="yyyy/mm/dd" readonly>
                                    </span> -->
                                    <!-- </div> -->
                                    <div class="col-md-6">                                
                                        <label>Company Name<span class="mandetory">*</span></label>
                                        <p *ngIf="viewData">{{model1.companyname}}</p>
                                        <input  type="text" *ngIf="!viewData" class="inputstyles" name="companyname"
                                        placeholder="Enter your Company Name " [(ngModel)]="model1.companyname"
                                        readonly>  
                                    </div>
                                </div>
                                <div class="row">
                                <div class="col-md-3">
                                    <label>Purchase Order Number<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData">{{model1.ponumber}}</p>
                                    <input type="text" *ngIf="!viewData" placeholder="Enter Purchase Order No" class="inputstyles"
                                        name="ponumber" [(ngModel)]="model1.ponumber" readonly>

                                </div>
                                <div class="col-md-3">
                                    <label>Purchase Order Date<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData">{{model1.podate}}</p>
                                    <input *ngIf="!viewData" type="date" style="text-transform: uppercase;" class="inputstyles"
                                        name="podate" [(ngModel)]="model1.podate" readonly>
                                </div>
                                                           
                                <div class="col-md-3">
                                    <label>Invoice Number<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData">{{model1.invoicenumber}}</p>
                                    <input *ngIf="!viewData"  type="text" placeholder="Enter Invoice No" class="inputstyles"
                                        name="invoicenumber" [(ngModel)]="model1.invoicenumber" readonly>
                                </div>
                                <div class="col-md-3">
                                    <label>Vendor Name<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData">{{model1.vendorname}}</p>
                                    <input *ngIf="!viewData"  type="text" class="inputstyles" name="vendorname"
                                        placeholder="Enter Vendor Name" [(ngModel)]="model1.vendorname" readonly>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label>DMR Comments</label>
                                    <p *ngIf="viewData">{{model1.dmr_comments}}</p>
                                    <textarea *ngIf="!viewData"  type="text" class="inputstyles inputpadding" name="drm_comments" rows="2" style="resize:none"
                                        placeholder="Enter your Comments " [(ngModel)]="model1.dmr_comments" readonly></textarea>

                                </div>
                                <div class="col-md-6">
                                    <label>QC Comments<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData">{{model1.comments}}</p>
                                    <textarea *ngIf="!viewData" rows="2" cols="50" style="resize:none"  class="inputstyles inputpadding"
                                    placeholder="Enter your Comments " [(ngModel)]="model1.comments">

                                    </textarea>
                                    <!-- <input type="text" class="inputstyles" name="comments"
                                        placeholder="Enter your Comments " [(ngModel)]="model1.comments"> -->

                                </div>
                            </div>
                        </form>
                        <ng-container>
                            <div class="row p-t-10" *ngIf="MATDATA">
                                <div class="col-12 col-md-12">
                                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                    <div class="table-responsive mat-table-wrapper">
                                        <div class="example-table-container" style="overflow-x:acroll;width:125%">
                                            <table #myTable>
                                                <tr class="tablestyles">
                                                    <th *ngIf="!viewData">Action</th>
                                                    <th>S.NO</th>
                                                    <th class="th-postion">Material Code</th>
                                                    <th class="th-postion">Material Description</th>
                                                    <th class="th-postion">UOM</th>
                                                    <th class="th-postion"> Storage Location</th>
                                                    <th class="th-postion"*ngIf="!viewData">QC Quantity</th>
                                                    <th class="th-postion"> Accepted Quantity<span class="mandetory">*</span></th>
                                                    <th class="th-postion"> Rejected Quantity</th>
                                                    <th class="th-postion">Item Remarks</th>
                                                   
                                                </tr>

                                                <tr *ngFor="let model of MATDATA;let i=index;trackBy: customTrackBy">
                                                    <td *ngIf="!viewData">
                                                        <!-- <button mat-icon-button
                            aria-label="Example icon button with a vertical three dot icon"
                           >
                            <mat-icon #myButton (click)="savedata(model.received_quantity,model.acceptedqty,model.rejectedqty,model.itemremarks)" >save</mat-icon>
                        </button> -->
                                                        <button mat-icon-button 
                                                            aria-label="Example icon button with a vertical three dot icon"
                                                            (click)="deleterowItem(i)" matTooltip="Delete">
                                                            <mat-icon style="color:red">delete</mat-icon>
                                                        </button>
                                                    </td>
                                                    <td>{{i+1}}</td>
                                                    <!-- <td width="20%"><span class="example-full-width" >{{model.LABEL_NAME}}</span></td> -->
                                                    <td class="th-postion">
                                                        <mat-form-field class="example-full-width">
                                                            <input matInput placeholder="Enter text" value=""
                                                                name="matcode" [(ngModel)]="model.material_code"
                                                                readonly [disabled]="viewData===true">
                                                        </mat-form-field>
                                                    </td>
                                                    <td class="th-postion">
                                                        <mat-form-field class="example-full-width">
                                                            <input matInput placeholder="Enter text" value=""
                                                                name="matname" [(ngModel)]="model.material_description"
                                                                readonly [disabled]="viewData===true">
                                                        </mat-form-field>
                                                    </td>
                                                    <td class="th-postion">
                                                        <mat-form-field class="example-full-width">
                                                            <input matInput placeholder="Enter text" value="" name="uom"
                                                                [(ngModel)]="model.unit_of_measurment" readonly
                                                                [disabled]="viewData===true">
                                                        </mat-form-field>
                                                    </td>
                                                    <td class="th-postion">
                                                        <mat-form-field class="example-full-width">
                                                            <input matInput placeholder="Enter text" value="" name="storage_location"
                                                                [(ngModel)]="model.storage_location" readonly
                                                                [disabled]="viewData===true">
                                                        </mat-form-field>
                                                    </td>
                                                    <td *ngIf="!viewData" class="th-postion">
                                                        <mat-form-field class="example-full-width ">
                                                            <input type="number" matInput placeholder="Enter text"
                                                                value="" name="qcquantity{{i}}"
                                                                [(ngModel)]="MATDATA[i].qc_quantity" pattern="^[0-9]*$"
                                                                [disabled]="viewData===true">
                                                        </mat-form-field>
                                                    </td>
                                                    <td class="th-postion">
                                                        <mat-form-field class="example-full-width">
                                                            <input type="number" matInput placeholder="Enter text"
                                                                value="" name="acceptedqty{{i}}"
                                                                [(ngModel)]="MATDATA[i].accepted_quantity"
                                                                pattern="^[0-9]*$" [disabled]="viewData===true">
                                                        </mat-form-field>
                                                    </td>
                                                    <td class="th-postion">
                                                        <mat-form-field class="example-full-width">
                                                            <input type="number" matInput placeholder="Enter text"
                                                                value="" name="rejectedqty{{i}}"
                                                                [(ngModel)]="MATDATA[i].rejeted_quantity"
                                                                pattern="^[0-9]*$" [disabled]="viewData===true">
                                                        </mat-form-field>
                                                    </td>
                                                    <td class="th-postion">
                                                        <mat-form-field  class="example-full-width "  *ngIf="!viewData">
                                                            <input matInput placeholder="Enter text" 
                                                                name="itemremarks{{i}}"
                                                                [(ngModel)]="MATDATA[i].item_remarks"
                                                                [disabled]="viewData===true">
                                                        </mat-form-field>

                                                        <mat-form-field  class="example-full-width " *ngIf="viewData" >
                                                            <input matInput placeholder="Enter text" 
                                                                name="itemremarks{{i}}"
                                                                [(ngModel)]="model1.item_remarks"
                                                                [disabled]="viewData===true">
                                                        </mat-form-field>

                                                       
                                                    </td>
                                                </tr>
                                                <!-- <tr>
                          <td style="height: 45px;text-align: center;" *ngIf="commentsList == 0" colspan="4">No Records Found</td>
                        </tr> -->
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div><br>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
              Pending  List
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <ng-container>

                            <div class="row p-t-10">
                                <div class="col-md-4 mt-2 mb-3 d-flex">
                                    <label class="mt-0">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                                    <select class="dropdownstyles" name="columnname" [(ngModel)]="columnnamepnd"
                                        (change)="onChangepnd()">
                                        <option value="" selected disabled>Select Column</option>
                                        <!-- <option value="number">QC Number</option> -->
                                        <option value="number">DMR Number</option>
                                        <option value="vendor_name">Vendor Name</option>
                                        <option value="invoice_number">Invoice Number</option>                
                                        <!-- <option value="purchase_order_number">PO Number</option> -->
                                    </select>
                                </div>
                                <div class="col-md-3 mt-2 mb-3 ">
                                    <input type="search" class="searchfield" placeholder="Search"
                                        [(ngModel)]="searchDatapnd" (input)="searchpnd()">
                                </div>
                                <div class="col-5 col-md-5 mt-2 mb-3 text-right">
                                    <button mat-icon-button value="Refresh" matTooltip="Refresh"  (click)="getdatapnddata()"
                                    class="custom-icon-button ml-3">
                                    <span class="material-icons">
                                        refresh
                                    </span>
                                </button>
                                </div>
                                <div class="col-12 col-md-12">
                                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                    <div class="table-responsive mat-table-wrapper">
                                        <div class="example-table-container">
                                            <table mat-table [dataSource]="dataSourcemainpnd" matSort>


                                                <ng-container matColumnDef="sno">
                                                    <th mat-header-cell *matHeaderCellDef style="width:6%">S.NO</th>
                                                    <td mat-cell *matCellDef="let row;let i=index;">
                                                        {{(i+1) + (masterpaginatorpnd?.pageIndex *
                                                            masterpaginatorpnd?.pageSize)}}

                                                    </td>
                                                </ng-container>
                                    
                                                <ng-container matColumnDef="qc_date">
                                                    <th mat-header-cell *matHeaderCellDef style="width:8%" mat-sort-header="qc_sent_date">Sent From Stores
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.qc_sent_date | date: 'dd/MM/yyyy hh:mm a'}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="DMRNumber">
                                                    <th mat-header-cell *matHeaderCellDef style="width:10%" mat-sort-header="dmr_number">DMR Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.dmr_number}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="dmr_date">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="dmr_date">DMR Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.dmr_date| date: 'dd-MM-yyyy'}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="company_name">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="company_name">Company Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.company_name}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="VendorName">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="vendor_name">Vendor Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.vendor_name }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="InvoiceNumber">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="invoice_number">Invoice Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.invoice_number}}
                                                    </td>
                                                </ng-container>
                                             
                                                <ng-container matColumnDef="po_number">
                                                    <th mat-header-cell *matHeaderCellDef style="width:8%" mat-sort-header="purchase_order_number">PO Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.purchase_order_number}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="count">
                                                    <th mat-header-cell *matHeaderCellDef style="width:8%;" mat-sort-header="count">Count
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.count}}

                                                    </td>
                                                </ng-container>
                                                <!-- <ng-container matColumnDef="date">
                                                    <th mat-header-cell *matHeaderCellDef style="width:8%">Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.created_date | date: 'dd/MM/yyyy '}}

                                                    </td>
                                                </ng-container> -->
                                                <ng-container matColumnDef="action">
                                                    <th mat-header-cell *matHeaderCellDef style="width:13%"
                                                        class="text-center">
                                                        Action
                                                    </th>
                                                    <td mat-cell *matCellDef="let row" class="text-center">
                                                        <mat-icon   matTooltip="Proceed " class="proceedDoc"
                                                        (click)="viewdata(row)">note_add</mat-icon> 
                                                        <!-- <mat-icon matTooltip="Proceed " class="proceedDoc"
                                                            (click)="viewdata(row)">how_to_reg</mat-icon> -->
                                                            <mat-icon class="ml-2 attachFileDoc" matTooltip="View Attachments"
                                                            (click)="viewAttachment1(viewFilemodel,row)">attach_file</mat-icon>
                                                        <!-- <mat-icon (click)="openfileuploadmodel(fileuploadmodel,row)"
                                                            class="attachFileIcon">file_upload</mat-icon> -->
                                                    </td>
                                                </ng-container>


                                                <tr mat-header-row *matHeaderRowDef="displayedColumns1pnd;sticky: true">
                                                </tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns1pnd"></tr>
                                            </table>
                                        </div>
                                        <div *ngIf="reaDatapnd==true" class="text-center">
                                            <h4 class="text-secondary m-3" *ngIf="loadingRecordspnd">
                                                Loading...
                                            </h4>
                                            <h4 class="text-secondary m-3" *ngIf="!loadingRecordspnd">
                                                No Records Found
                                            </h4>
                                        </div>
                                        <ng-container *ngIf="totalRecordspnd > 0">
                                        <mat-paginator [length]="totalRecordspnd" [pageSize]="pageSizepnd" [pageIndex]="pageIndexpnd"
                                            [pageSizeOptions]="[5,10, 50, 100, 500, 1000]" #masterpaginatorpnd
                                            (page)="onpageeventpnd($event)">
                                        </mat-paginator>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
              Completed  List
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <ng-container>

                            <div class="row p-t-10">
                                <div class="col-md-4 mt-2 mb-3 d-flex">
                                    <label class="mt-0">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                                    <select class="dropdownstyles" name="columnname" [(ngModel)]="columnname"
                                        (change)="onChange()">
                                        <option value="" selected disabled>Select Column</option>
                                        <option value="number">QC Number</option>
                                        <option value="dmr_number">DMR Number</option>
                                        <option value="vendor_name">Vendor Name</option>
                                        <option value="invoice_number">Invoice Number</option>
                                        <!-- <option value="company_name">Company Name</option> -->
                                        <option value="purchase_order_number">PO Number</option>
                                    </select>
                                </div>
                                <div class="col-md-3 mt-2 mb-3 ">
                                    <input type="search" class="searchfield" placeholder="Search"
                                        [(ngModel)]="searchData" (input)="search()">
                                </div>
                                <div class="col-5 col-md-5 mt-2 mb-3 text-right">
                                    <button mat-icon-button value="Refresh" matTooltip="Refresh"  (click)="getcompletedata()"
                                    class="custom-icon-button ml-3">
                                    <span class="material-icons">
                                        refresh
                                    </span>
                                </button>
                                </div>
                                <div class="col-12 col-md-12">
                                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                    <div class="table-responsive mat-table-wrapper">
                                        <div class="example-table-container">
                                            <table mat-table [dataSource]="dataSourcemain" matSort>


                                                <ng-container matColumnDef="sno">
                                                    <th mat-header-cell *matHeaderCellDef style="width:6%">S.NO</th>
                                                    <td mat-cell *matCellDef="let row;let i=index;">
                                                        {{(i+1) + (masterpaginator?.pageIndex *
                                                        masterpaginator?.pageSize)}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="qc_Number">
                                                    <th mat-header-cell *matHeaderCellDef style="width:9%" mat-sort-header="number">QC Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.number}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="qc_date">
                                                    <th mat-header-cell *matHeaderCellDef style="width:8%" mat-sort-header="date">Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.date | date: 'dd-MM-yyyy '}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="DMRNumber">
                                                    <th mat-header-cell *matHeaderCellDef style="width:10%" mat-sort-header="dmr_number">DMR Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.dmr_number}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="VendorName">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="vendor_name">Vendor Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.vendor_name }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="InvoiceNumber">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="invoice_number">Invoice Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.invoice_number}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="company_name">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="company_name">Company Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.company_name}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="po_number">
                                                    <th mat-header-cell *matHeaderCellDef style="width:8%" mat-sort-header="purchase_order_number">PO Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.purchase_order_number}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="po_date">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="purchase_order_date">PO Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.purchase_order_date| date: 'dd/MM/yyyy'}}
                                                    </td>
                                                </ng-container>
                                                <!-- <ng-container matColumnDef="ActiveStatus">
                                <th mat-header-cell *matHeaderCellDef >Active Status
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    {{row.is_active}}
                                </td>
                            </ng-container> -->
                                                <ng-container matColumnDef="action">
                                                    <th mat-header-cell *matHeaderCellDef style="width:13%"
                                                        class="text-center">
                                                        Action
                                                    </th>
                                                    <td mat-cell *matCellDef="let row" class="text-center">
                                                        <mat-icon matTooltip="View" style="border:none;background: none;cursor: pointer;"
                                                            (click)="editdata(row)">remove_red_eye</mat-icon>

                                                        <!-- <button mat-icon-button style="color: red;border:none;background: none;"
                                        aria-label="Example icon button with a vertical three dot icon" 
                                        (click)="deleteItem(row,deleteItemModel)"> -->
                                                        <mat-icon matTooltip="Delete"
                                                            style="color: red;border:none;background: none;cursor: pointer;"
                                                            (click)="deleteItem(row,deleteItemModel)">delete</mat-icon>
                                                        <!-- </button> -->
                                                        <mat-icon matTooltip="Upload" (click)="openfileuploadmodel(fileuploadmodel,row)"
                                                            class="attachFileIcon fileicon">file_upload</mat-icon>
                                                        <!-- <i class="fa fa-paperclip" aria-hidden="true" (click)="openfileuploadmodel(fileuploadmodel,row)"
                                    style="cursor:pointer;font-size:16px"></i> -->

                                                    </td>
                                                </ng-container>


                                                <tr mat-header-row *matHeaderRowDef="displayedColumns1;sticky: true">
                                                </tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
                                            </table>
                                        </div>
                                        <div *ngIf="reaData==true" class="text-center">
                                            <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                                Loading...
                                            </h4>
                                            <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                                                No Records Found
                                            </h4>
                                        </div>
                                        <ng-container *ngIf="totalRecords > 0">
                                        <mat-paginator [length]="totalRecords" [pageSize]="pageSize" [pageIndex]="pageIndex"
                                            [pageSizeOptions]="[5,10, 50, 100, 500, 1000]" #masterpaginator
                                            (page)="onpageevent($event)">
                                        </mat-paginator>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Activity Log
            </ng-template>
            <ng-template matTabContent>
                <app-activitylog [activitylogfor]="'QualityCheck'"></app-activitylog>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
<ng-template #deleteItemModel>
    <h1 mat-dialog-title>Delete Quality Check</h1>
    <div mat-dialog-content>
        <p>Are you sure, you want to delete record?</p>
        <div class="row">
            <div class="col-md-2">
                <label>Reason</label>
            </div>
            <div class="col-md-4">
                <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                    [(ngModel)]="deletemodel.reason" style="width: 181%;">
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">Close</button>&nbsp;
        <button mat-button cdkFocusInitial (click)="deleteFile()" class="matButton"
            [disabled]="!deletemodel.reason">Delete</button>
    </div>
</ng-template>
<ng-template #fileuploadmodel>
    <div mat-dialog-title class="dialog-title mb-0">
        <!-- <h2>Quality Check: {{qualitychecknumber}}</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            style="border:none;background:none;margin-top: -9%;">
            <mat-icon>close</mat-icon>
        </button> -->
        <div class="row">
            <div class="col-11">
                <h2>Quality Check: {{qualitychecknumber}}</h2>
            </div>
            <div class="col-1 text-right">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
            <mat-icon class="icon1">close</mat-icon>
        </button>
            </div>
        </div>
    </div>
    <div mat-dialog-content class="row">
        <div *ngIf="filedata">
            <!-- <span *ngFor="let item of filedata"> -->
            <!-- <span *ngFor="let item of filedata"> <a href="{{item.file_path}}">{{item.file_path}}</a></span> -->
            <!-- <h4>View File:&nbsp;&nbsp; <i class="fas fa-eye text-cyan m-r-20 " 
    style="cursor: pointer;" (click)="viewDoc(item)"></i></h4> -->
            <table>
                <thead style="background: #e9ecef;">
                    <!-- <tr> -->
                    <th>Created By</th>
                    <th>Created Date</th>
                    <th>File Name</th>
                    <th>Action</th>
                    <!-- </tr> -->
                </thead>
                <tbody>
                    <tr *ngFor="let item of filedata">
                        <td>{{item.created_by}}</td>
                        <td>{{item.created_date}}</td>
                        <td class="tablebody">{{item.file_name}}</td>
                        <td>
                            <i class="fas fa-eye text-cyan m-r-20 "
                                style="cursor: pointer;" title="View File" (click)="viewDoc(item)"></i>
                            <button mat-icon-button style="color: red;border:none;cursor: pointer;"
                                aria-label="Example icon button with a vertical three dot icon"
                                (click)="deleterowfile(deletefileModel,item)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- <a style="cursor: pointer;" (click)="viewDoc(item)">{{item.file_name}}</a> -->
            <!-- </span> -->
        </div>
    </div><br>
    <button mat-raised-button type="button" (click)="autoFileUpload1.click()">Attach File</button>
    <div class="file">
        <input hidden class="form-control-file" name="file" type="file" multiple
            (change)="uploadWbsFile($event);showContent(i);" #autoFileUpload1 />
        <ng-container *ngIf="fileUploadUrls.length>0">
            <!-- <span>Selected: {{fileUploadUrls.length}} files</span> -->
        </ng-container>
    </div>
</ng-template>
<ng-template #deletefileModel>
    <h1 mat-dialog-title>Delete File</h1>
    <div mat-dialog-content>Are you sure, you want to delete file?</div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">Close</button>&nbsp;
        <button mat-button cdkFocusInitial (click)="deleteexistingfile()" class="matButton">Delete</button>
    </div>
</ng-template>
<ng-template #logdialog>
    <div mat-dialog-title class="dialog-title mb-0">
        <!-- <h2>Activity Log</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            style="border:none;background:none;margin-top: -8%;">
            <mat-icon>close</mat-icon>
        </button> -->
        <div class="row">
            <div class="col-11">
                <h2>Activity Log</h2>
            </div>
            <div class="col-1 text-right">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
            <mat-icon class="icon1">close</mat-icon>
        </button>
            </div>
        </div>
    </div>
    <div mat-dialog-content class="dialogdata">
        <table>
            <thead style="background: #e9ecef;">
                <!-- <tr> -->
                <th>User Name</th>
                <th>Created Date</th>
                <th>Reason</th>
                <th>Action</th>
                <!-- </tr> -->
            </thead>
            <tbody>
                <tr *ngFor="let item of logdata">
                    <td>{{item.username}}</td>
                    <td>{{item.date_time | date:'dd-MM-yyyy HH:mm'}}</td>
                    <td>{{item.reason}} </td>
                    <td>{{item.action}}
                        <!-- <button mat-icon-button style="color: red;border:none;cursor: pointer;"
                           aria-label="Example icon button with a vertical three dot icon"
                            >
                             <mat-icon>delete</mat-icon>
                             </button> -->
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- <a style="cursor: pointer;" (click)="viewDoc(item)">{{item.file_name}}</a> -->
        <!-- </span> -->

    </div><br>

</ng-template>


<ng-template #dmrmodel>
    <div mat-dialog-title class="dialog-title">
        <!-- <h2>Select DMR</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            style="border:none;background:none;margin-top: -10%;">
            <mat-icon>close</mat-icon>
        </button> -->
        <div class="row">
            <div class="col-11">
                <h2>Select DMR</h2>
            </div>
            <div class="col-1 text-right">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
            <mat-icon class="icon1">close</mat-icon>
        </button>
            </div>
        </div>
    </div>
    <form #mydmrform="ngForm" (ngSubmit)="submitdmrno(mydmrform)">
        <div class="row">
            <div class="col-md-10 mt-2">
                <!-- <label>DMR Number<span class="mandetory">*</span></label> -->
           
                <mat-form-field class="example-full-width" style=" width:100%;
                border: 1px solid rgba(0, 0, 0, 0.12); border-radius: 5px;  height: 35px;" appearance="none">
                    <input type="text" placeholder="Search DMR Number " aria-label="Number" matInput style="border:none" 
                        class="inputdropdown dmralinement" name="dmrnumber" [(ngModel)]="dmrnumber"
                        (keyup)="getdmrdata($event)" [matAutocomplete]="auto11" />
                    <mat-autocomplete autoActiveFirstOption #auto11="matAutocomplete" >
                        <mat-option *ngFor="let item of filteredqcData" [value]="item.number">
                            {{item.number}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col-md-2 mt-2">
                <button mat-raised-button type="submit" class="savebutton submitBtn" style="font-weight: 500;background-color: #8dd94a;" [disabled]="!dmrnumber">Submit</button>
            </div>
        </div>

        
    </form>
</ng-template>
<ng-template #viewFilemodel>
    <div mat-dialog-title class="dialog-title mb-0">
        <!-- <h2>Daily Material Receipt: {{selectedDMR}}</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            style="border:none;background:none;margin-top: -9%;">
            <mat-icon>close</mat-icon>
        </button> -->
        <div class="row">
            <div class="col-11">
                <h2>Daily Material Receipt: {{selectedDMR}}</h2>
            </div>
            <div class="col-1">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
            <mat-icon class="icon1">close</mat-icon>
        </button>
            </div>
        </div>
    </div>
    <div mat-dialog-content class="row">
        <div *ngIf="filedataDMR">
            <!-- <span *ngFor="let item of filedata"> -->
            <!-- <span *ngFor="let item of filedata"> <a href="{{item.file_path}}">{{item.file_path}}</a></span> -->
            <!-- <h4>View File:&nbsp;&nbsp; <i class="fas fa-eye text-cyan m-r-20 " 
            style="cursor: pointer;" (click)="viewDoc(item)"></i></h4> -->
            <table>
                <thead style="background: #e9ecef;">
                    <!-- <tr> -->
                    <th>Created By</th>
                    <th>Created Date</th>
                    <th>File Name</th>
                    <!-- <th>Action</th> -->
                    <!-- </tr> -->
                </thead>
                <tbody>
                    <tr *ngFor="let item of filedataDMR">
                        <td>{{item.created_by}}</td>
                        <td>{{item.created_date}}</td>
                        <td>{{item.file_name}} &nbsp;&nbsp;&nbsp;<i class="fas fa-eye text-cyan m-r-20 "
                                style="cursor: pointer;" title="View File" (click)="viewDocDMR(item)"></i></td>
                        <!-- <td>
                            <button mat-icon-button style="color: red;border:none;cursor: pointer;"
                                aria-label="Example icon button with a vertical three dot icon"
                                (click)="deleterowfile(deletefileModel,item)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td> -->
                    </tr>
                </tbody>
            </table>
            <div class="text-center" *ngIf="filedataDMR.length===0">
                No Data Found
            </div>
            <!-- <a style="cursor: pointer;" (click)="viewDoc(item)">{{item.file_name}}</a> -->
            <!-- </span> -->
        </div>
    </div><br>
    <!-- <button mat-raised-button type="button" (click)="autoFileUpload1.click()">Attach File</button>
    <div class="file">
        <input hidden class="form-control-file" name="file" type="file" (change)="uploadWbsFile($event);showContent(i);"
            #autoFileUpload1 multiple />
        <ng-container *ngIf="fileUploadUrls.length>0">
            <span>Selected: {{fileUploadUrls.length}} files</span>
        </ng-container>
    </div> -->
</ng-template>

<ng-template #newFileUploadModal>
    <div mat-dialog-title class="dialog-title mb-0">
        <!-- <h2>File Upload</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            style="border:none;background:none;margin-top: -7%;">
            <mat-icon>close</mat-icon>
        </button> -->
        <div class="row">
            <div class="col-11">
                <h2>File Upload</h2>
            </div>
            <div class="col-1 text-right">
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
            <mat-icon class="icon1">close</mat-icon>
        </button>
            </div>
        </div>
    </div>
    <div mat-dialog-content class="row">
        <div >
            <table>
                <thead style="background: #e9ecef;text-align: center;">
                    <th>S.NO</th>
                    <th>File Name</th>
                    <th>Action</th>
                </thead>
                <tbody style="text-align: center;">
                    <tr *ngFor="let item of selectedfiles;let i=index;">
                        <td>{{i+1}}</td>
                        <td class="tablebody1">{{item.name}}</td>
                        <td>
                            <button mat-icon-button style="color: red;border:none;cursor: pointer;"
                                (click)="deleteFileItem(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                    <tr *ngFor="let file of filedataDMR; let i=index;">
                        <td>{{selectedfiles.length + i + 1}}</td>
                        <td class="tablebody1">{{file.file_name}}</td>
                        <td>
                          <button mat-icon-button class="delete1" (click)="deleteExistingFileItem(i)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </td>
                      </tr>
                </tbody>
            </table>
            <!-- <div class="text-center mt-2" *ngIf="filedataDMR.length===0">
                No Data Found
            </div> -->
        </div>

    </div><br>
    <button type="button" mat-raised-button
    class="attachfile ml-1" color="secondary" (click)="autoFileUpload2.click()">
    Attach File&nbsp; <mat-icon
        class="attachFileIcon">file_upload</mat-icon></button>
<div class="file">
    <input hidden class="form-control-file" name="file" type="file" multiple
        (change)="uploadqcfiles($event)" #autoFileUpload2>
</div>
</ng-template>
 