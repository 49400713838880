import { Component, Injector } from "@angular/core";
import { IpService } from "./ip.service";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  // title = "app";
  companyTitle: string = "";
  isCompanyVerified: boolean = false;
  urlName: any;
  hasIpAddress = true;
  keycloakService: any;
  constructor(
    private injector: Injector,
    private router: Router,
    private title: Title
  ) {
    const ipService = this.injector.get(IpService);
    ipService
      .getIpAddress()
      .then((ip: any) => {
        if (ip !== null && ip !== undefined) {
          // console.log('--------------'+ip)
          localStorage.setItem("ip", ip);
          this.hasIpAddress = true;
          //    this.tokentest();
        }
      })
      .catch((err) => {
        this.hasIpAddress = true;
      });
  }
  ngOnInit() {
    // let clients: any = environment.clients;
    let origin = window.location.hostname;
    let client = "";
    let clientObj: any = {};
    if (origin && origin.includes(".")) {
      let clientarr = origin.split(".", 1);
      client = clientarr.length ? clientarr[0] : "";
    }
    console.log(client);
    if(origin.split(".", 1)[0] === "dms" && origin.split(".", 2)[1] === "amgreen"){
      this.title.setTitle("Am Green");
    }
    // clientObj = clients[client];
    // if (clients[client] && Object.keys(clientObj).length) {
      if(client){
      this.companyTitle = clientObj.realm;
      this.isCompanyVerified = true;
      this.title.setTitle(this.companyTitle);
    }
    if (!this.isCompanyVerified) {
      this.title.setTitle("Greenko");
    }
    // const currentUrl = window.location.host;
    // console.log(currentUrl);
    // this.urlName = currentUrl.split(".", 1);
    // environment.clients.forEach((ele: any) => {
    //   if (ele == this.urlName[0]) {
    //     this.companyTitle = this.urlName[0];
    //     this.title.setTitle(this.companyTitle);
    //     this.isCompanyVerified = true;
    //   }
    // });
    // if (!this.isCompanyVerified) {
    //   this.title.setTitle("Geenko");
    // }
  }
}
// import { Component, Injector } from "@angular/core";
// import { IpService } from "./ip.service";
// import { Router } from "@angular/router";
// import { Title } from "@angular/platform-browser";
// import { environment } from "src/environments/environment";
// @Component({
//   selector: "app-root",
//   templateUrl: "./app.component.html",
//   styleUrls: ["./app.component.scss"],
// })
// export class AppComponent {
//   // title = "app";
//   companyTitle: string = "";
//   isCompanyVerified: boolean = false;
//   urlName: any;
//   hasIpAddress = true;
//   keycloakService: any;
//   constructor(
//     private injector: Injector,
//     private router: Router,
//     private title: Title
//   ) {
//     const ipService = this.injector.get(IpService);
//     ipService
//       .getIpAddress()
//       .then((ip: any) => {
//         if (ip !== null && ip !== undefined) {
//           // console.log('--------------'+ip)
//           localStorage.setItem("ip", ip);
//           this.hasIpAddress = true;
//           //    this.tokentest();
//         }
//       })
//       .catch((err) => {
//         this.hasIpAddress = true;
//       });
//   }
//   ngOnInit() {
//     let clients:any = environment.clients;
//     let origin = window.location.hostname;
//     let client =  '';
//     let clientObj:any = {}
//     if (origin && origin.includes('.')) {
//       let clientarr = origin.split('.', 1)
//       client = clientarr.length ? clientarr[0] :"" 
//     }
//     clientObj = clients[client]  
//     if (clients[client] && Object.keys(clientObj).length) {
//       this.companyTitle = clientObj.realm
//       this.isCompanyVerified = true;
//       this.title.setTitle(this.companyTitle);
//     }
//     if (!this.isCompanyVerified) {
//       this.title.setTitle("Geenko");
//     }
//     // const currentUrl = window.location.host;
//     // console.log(currentUrl);
//     // this.urlName = currentUrl.split(".", 1);
//     // environment.clients.forEach((ele: any) => {
//     //   if (ele == this.urlName[0]) {
//     //     this.companyTitle = this.urlName[0];
//     //     this.title.setTitle(this.companyTitle);
//     //     this.isCompanyVerified = true;
//     //   }
//     // });
//     // if (!this.isCompanyVerified) {
//     //   this.title.setTitle("Geenko");
//     // }
//   }
// }
