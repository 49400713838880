import { catchError } from "rxjs/operators";
import { environment } from "./../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { tap, map } from "rxjs/operators";
import Swal from "sweetalert2";
import { LoaderService } from "../shared/loader/loader.service";
import { AppInjector } from "../app.module";
import { param } from "jquery";
// import { AlertCallsService } from "../auth/alert-calls.service";
export class DynamicFlatNode {
  constructor(
    public item: any,
    public level = 1,
    public expandable = false,
    public isLoading = false
  ) {}
}

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  opts = [];
  constructor(private http: HttpClient) {}

  userLogin(params: any) {
    return this.http
      .get(environment.url + "/auth/login", params)
      .pipe(catchError(this.handleError));
  }

  getAuditCount(params:any){
    return this.http.get(environment.pdashboard_url+"/geps/audit/auditing/getAuditcount",{params})
  }
  getAllProjects() {
    return this.http
      .get(environment.url + "/project_list")
      .pipe(catchError(this.handleError));
  }

  getAllFilterProjects() {
    return this.http
      .get(environment.url + "/project_list")
      .pipe(tap((data: any) => (this.opts = data)));
  }

  getProjectDetails(id: any) {
    return this.http
      .get(environment.url + "/project_wbs?project_id=" + id)
      .pipe(catchError(this.handleError));
  }

  getProjectActivityDetails(id: any, activityId: any) {
    return this.http
      .get(
        environment.url +
          "/activities?project_id=" +
          id +
          "&wbs_id=" +
          activityId
      )
      .pipe(catchError(this.handleError));
  }

  uploadFiles(params: any) {
    return this.http
      .post(environment.url + "/upload_task_images", params)
      .pipe(catchError(this.handleError));
  }

  updateTaskActualQtyUpdate(params: any) {
    return this.http
      .post(environment.url + "/task_actual_qty_update", params)
      .pipe(catchError(this.handleError));
  }

  updateTaskActualQtyUpdateNew(params: any) {
    return this.http
      .post(environment.url + "/task_actual_qty_update_new", params)
      .pipe(catchError(this.handleError));
  }
  updateTaskAcceptedQtyUpdate(params: any) {
    return this.http
      .post(environment.url + "/task_accepted_qty_update", params)
      .pipe(catchError(this.handleError));
  }

  getTaskImages(id: any) {
    return this.http
      .get(environment.url + "/get_task_images?task_act_qty_id=" + id)
      .pipe(catchError(this.handleError));
  }

  getProjectActivity(params: any) {
    return this.http
      .get(environment.url + "/get_activity_log?", { params })
      .pipe(catchError(this.handleError));
  }

  getUserProjects() {
    return this.http
      .get(environment.sso_api + "/application_list?app_name=GEPS")
      .pipe(catchError(this.handleError));
  }

  getTicketDepts() {
    return this.http
      .get(environment.url + "/get_ticket_dept_codes")
      .pipe(catchError(this.handleError));
  }

  getTicketSecurity() {
        return this.http
      .get(environment.url + "/get_ticket_severity")
      .pipe(catchError(this.handleError));
  }

  createTicket(params: any) {
    return this.http
      .post(environment.url + "/create_ticket", params)
      .pipe(catchError(this.handleError));
  }

  getTickets(page: any, records: any) {
    return this.http
      .get(
        environment.url +
          "/get_tickets?page_id=" +
          page +
          "&items_per_page=" +
          records
      )
      .pipe(catchError(this.handleError));
  }

  uploadPdfFiles(params: any) {
    return this.http
      .post(environment.url + "/upload_pdfs", params)
      .pipe(catchError(this.handleError));
  }
  uploadExtraPdfFiles(params: any) {
    return this.http
      .post(environment.url + "/upload_extra_pdfs", params)
      .pipe(catchError(this.handleError));
  }
  uploadPmcPdfFiles(params: any) {
    return this.http
      .post(environment.url + "/upload_pmc_pdfs", params)
      .pipe(catchError(this.handleError));
  }
  uploadAsBuiltPdfFiles(params: any) {
    return this.http
      .post(environment.url + "/upload_as_build_pdfs", params)
      .pipe(catchError(this.handleError));
  }
  uploadTenderPdfFiles(params: any) {
    return this.http
      .post(environment.url + "/upload_tender_pdfs", params)
      .pipe(catchError(this.handleError));
  }
  getPdfFiles(params: any) {
    return this.http
      .get(environment.url + "/get_project_pdfs?", { params })
      .pipe(catchError(this.handleError));
  }
  getExtraPdfFiles(params: any) {
    return this.http
      .get(environment.url + "/get_extra_project_pdfs?", { params })
      .pipe(catchError(this.handleError));
  }
  getPmcPdfFiles(params: any) {
    return this.http
      .get(environment.url + "/get_pmc_project_pdfs?", { params })
      .pipe(catchError(this.handleError));
  }
  getAsBuiltPdfFiles(params: any) {
    return this.http
      .get(environment.url + "/get_as_build_pdfs", { params })
      .pipe(catchError(this.handleError));
  }
  getTenderPdfFiles(params: any) {
    return this.http
      .get(environment.url + "/get_tender_pdfs?", { params })
      .pipe(catchError(this.handleError));
  }
  searchProjectPdfs(params: any) {
    return this.http
      .get(environment.url + "/search_project_pdfs?", { params })
      .pipe(catchError(this.handleError));
  }
  createFolder(params: any) {
    return this.http
      .post(environment.url + "/create_design_folder", params)
      .pipe(catchError(this.handleError));
  }

  getFolders(params: any) {
    return this.http
      .get(environment.url + "/get_design_folder?", { params })
      .pipe(catchError(this.handleError));
  }

  deleteFolder(params: any) {
    return this.http
      .get(environment.url + "/delete_design_folder?", { params })
      .pipe(catchError(this.handleError));
  }

  deleteFile(params: any) {
    return this.http
      .get(environment.url + "/delete_pdf?", { params })
      .pipe(catchError(this.handleError));
  }

  createPmcFolder(params: any) {
    return this.http
      .post(environment.url + "/create_pmc_design_folder", params)
      .pipe(catchError(this.handleError));
  }

  createExtraFolder(params: any) {
    return this.http
      .post(environment.url + "/create_extra_design_folder", params)
      .pipe(catchError(this.handleError));
  }
  createAsBuiltFolder(params: any) {
    return this.http
      .post(environment.url + "/create_as_build_folder", params)
      .pipe(catchError(this.handleError));
  }
  createTenderFolder(params: any) {
    return this.http
      .post(environment.url + "/create_tender_folder", params)
      .pipe(catchError(this.handleError));
  }
  getExtraFolders(params: any) {
    return this.http
      .get(environment.url + "/get_extra_design_folder?", { params })
      .pipe(catchError(this.handleError));
  }
  getPmcFolders(params: any) {
    return this.http
      .get(environment.url + "/get_pmc_design_folder?", { params })
      .pipe(catchError(this.handleError));
  }
  getAsBuiltFolders(params: any) {
    return this.http
      .get(environment.url + "/get_as_build_folder?", { params })
      .pipe(catchError(this.handleError));
  }
  getTenderFolders(params: any) {
    return this.http
      .get(environment.url + "/get_tender_folder?", { params })
      .pipe(catchError(this.handleError));
  }

  searchPmcFolders(params: any) {
    return this.http
      .get(environment.url + "/search_pmc_pdfs?", { params })
      .pipe(catchError(this.handleError));
  }
  searchAsBuiltFolders(params: any) {
    return this.http
      .get(environment.url + "/search_as_build_pdfs?", { params })
      .pipe(catchError(this.handleError));
  }
  searchTenderFolders(params: any) {
    return this.http
      .get(environment.url + "/search_tender_pdfs?", { params })
      .pipe(catchError(this.handleError));
  }
  searchExternalFolders(params: any) {
    return this.http
      .get(environment.url + "/search_extra_pdfs?", { params })
      .pipe(catchError(this.handleError));
  }
  deleteExtraFolder(params: any) {
    return this.http
      .get(environment.url + "/delete_extra_design_folder?", { params })
      .pipe(catchError(this.handleError));
  }
  deleteAsBuiltFolder(params: any) {
    return this.http
      .delete(environment.url + "/delete_as_build_folder?", { params })
      .pipe(catchError(this.handleError));
  }
  deleteTenderFolder(params: any) {
    return this.http
      .delete(environment.url + "/delete_tender_folder?", { params })
      .pipe(catchError(this.handleError));
  }

  deletePmcFolder(params: any) {
    return this.http
      .get(environment.url + "/delete_pmc_design_folder?", { params })
      .pipe(catchError(this.handleError));
  }

  editFolder(obj:any) {
    return this.http
      .put(environment.url + "/rename_drawing_folder",obj)
      .pipe(catchError(this.handleError));
  }

  deletePmcFile(params: any) {
    return this.http
      .get(environment.url + "/delete_pdf?", { params })
      .pipe(catchError(this.handleError));
  }

  getPdfFileVersions() {
    return this.http
      .get(environment.url + "/get_pdf_version_list")
      .pipe(catchError(this.handleError));
  }
  addPdfFileVersions(params: any) {
    return this.http
      .post(environment.url + "/add_pdf_version", params)
      .pipe(catchError(this.handleError));
  }
  // addPdfFileVersions(params: any) {
  //   return this.http
  //     .post(environment.url + '/add_pdf_version',params)
  //     .pipe(catchError(this.handleError));
  // }

  getPdfDrawingTypes() {
    return this.http
      .get(environment.url + "/get_pdf_drawing_type_list")
      .pipe(catchError(this.handleError));
  }

  getPODetails(params: any) {
    return this.http
      .get(environment.url + "/get_pos", { params })
      .pipe(catchError(this.handleError));
  }

  uploadPOFile(params: any) {
    return this.http
      .post(environment.url + "/upload_po_temp_data", params)
      .pipe(catchError(this.handleError));
  }

  updatePOFile(params: any) {
    return this.http
      .post(environment.url + "/upload_po_data", params)
      .pipe(catchError(this.handleError));
  }

  cancelPoFile(id: any) {
    return this.http
      .get(environment.url + "/cancel_temp_po?temp_id=" + id)
      .pipe(catchError(this.handleError));
  }

  uploadPOFileItems(params: any) {
    return this.http
      .post(environment.url + "/add_items_to_po", params)
      .pipe(catchError(this.handleError));
  }

  getProjectImages(params: any) {
    return this.http
      .get(environment.url + "/get_project_images", { params })
      .pipe(catchError(this.handleError));
  }
  getActivityInfo(params: any) {
    return this.http
      .get(environment.url + "/get_activity_info", { params })
      .pipe(catchError(this.handleError));
  }

  createIssueLog(params: any) {
    return this.http
      .post(environment.url + "/create_issue_log", params)
      .pipe(catchError(this.handleError));
  }
  updateIssueLog(params: any) {
    // localStorage.setItem("appid","GEPS_APP");
    return this.http
      .post(environment.url + "/update_issue_log", params)
      .pipe(catchError(this.handleError));
  }
  getIssueLog(params: any) {
    return this.http
      .get(environment.url + "/get_issue_log", { params })
      .pipe(catchError(this.handleError));
  }

  getProjectEmails(params: any) {
    return this.http
      .get(environment.url + "/get_project_drawing_mailids", { params })
      .pipe(catchError(this.handleError));
  }

  addProjectEmails(params: any) {
    return this.http
      .post(environment.url + "/add_project_drawing_mailids", params)
      .pipe(catchError(this.handleError));
  }

  getGoodsReceipt(id: any) {
    return this.http
      .get(environment.url + "/get_goods_receipt_data?po_id=" + id)
      .pipe(catchError(this.handleError));
  }
  updateGoodsReceipt(params: any) {
    return this.http
      .post(environment.url + "/update_goods_receipt_data", params)
      .pipe(catchError(this.handleError));
  }

  getSearchUsers(search: any) {
    return (
      this.http
        .get(
          environment.url +
            "/get_user_list?username_given=" +
            search +
            "&username=" +
            search
        )
        // this.http
        // .get(
        //   environment.url +
        //     "/userlist?username_given=" +
        //     search +
        //     "&username=" +
        //     search
        // )
        // .get(environment.sso_api + "/userlist?username=" + search)
        .pipe(catchError(this.handleError))
    );
  }

  getUserRoles(params: any) {
    return this.http
      .post(environment.url + "/get_user_roles", params)
      .pipe(catchError(this.handleError));
  }

  getAllDept() {
    return this.http
      .get(environment.url + "/get_departments")
      .pipe(tap((data: any) => (this.opts = data)));
  }

  getDept(params:any) {
    return this.http
      .get(environment.url + "/get_departments",{params})
      .pipe(tap((data: any) => (this.opts = data)));
  }

  deleteDept(params:any){
    return this.http
    .post(environment.url + "/delete_department", params)
      .pipe(catchError(this.handleError));
  }

  getAllRoles() {
    return this.http
      .get(environment.url + "/get_geps_roles")
      .pipe(tap((data: any) => (this.opts = data)));
  }
  getRoles(params:any) {
    return this.http
      .get(environment.url + "/get_geps_roles",{params})
      .pipe(tap((data: any) => (this.opts = data)));
  }
  deleteRoles(params:any){
    return this.http
    .post(environment.url + "/delete_role", params)
      .pipe(catchError(this.handleError));
  }
  getAllSubRoles() {
    return this.http
      .get(environment.url + "/get_sub_roles")
      .pipe(tap((data: any) => (this.opts = data)));
  }

  addUserRole(params: any) {
    return this.http
      .post(environment.url + "/add_user_roles", params)
      .pipe(catchError(this.handleError));
  }
  getSubRoles(params:any) {
    return this.http
      .get(environment.url + "/get_sub_roles",{params})
      .pipe(tap((data: any) => (this.opts = data)));
  }
  deleteSubRoles(params:any){
    return this.http
    .post(environment.url + "/delete_sub_role", params)
      .pipe(catchError(this.handleError));
  }
  getAllRoleMenus(params: any) {
    return this.http
      .get(environment.url + "/get_role_menus", { params })
      .pipe(catchError(this.handleError));
  }

  getAllMenus() {
    return this.http
      .get(environment.url + "/get_menus")
      .pipe(tap((data: any) => (this.opts = data)));
  }
  getMenus(params:any) {
    return this.http
      .get(environment.url + "/get_menus",{params})
      .pipe(tap((data: any) => (this.opts = data)));
  }
  deleteMenus(params:any){
    return this.http
    .post(environment.url + "/delete_menu", params)
      .pipe(catchError(this.handleError));
  }
  addNewMenu(params: any) {
    return this.http
      .post(environment.url + "/add_menus", params)
      .pipe(catchError(this.handleError));
  }

  addMenuRole(params: any) {
    return this.http
      .post(environment.url + "/add_role_menus", params)
      .pipe(catchError(this.handleError));
  }

  addDepartment(params: any) {
    return this.http
      .post(environment.url + "/add_department", params)
      .pipe(catchError(this.handleError));
  }

  addRole(params: any) {
    return this.http
      .post(environment.url + "/add_roles", params)
      .pipe(catchError(this.handleError));
  }

  addSubRole(params: any) {
    return this.http
      .post(environment.url + "/add_sub_roles", params)
      .pipe(catchError(this.handleError));
  }

  getUserMenuRoles(id: any) {
    return this.http
      .get(environment.url + "/get_user_menus?email=" + id)
      .pipe(catchError(this.handleError));
  }

  getUserListProjects(params: any) {
    return this.http
      .get(environment.url + "/user_project_list", { params })
      .pipe(catchError(this.handleError));
  }

  getUserProjectWbs(params: any) {
    return this.http
      .get(environment.url + "/get_user_project_wbs", { params })
      .pipe(catchError(this.handleError));
  }

  addUserProjectWbs(params: any) {
    return this.http
      .post(environment.url + "/add_user_project_wbs", params)
      .pipe(catchError(this.handleError));
  }

  getProjectWbsDetails(params: any) {
    return this.http
      .get(environment.url + "/project_wbs", { params })
      .pipe(catchError(this.handleError));
  }

  getProjectWbsActivityDetails(params: any) {
    return this.http
      .get(environment.url + "/activities", { params })
      .pipe(catchError(this.handleError));
  }

  deleteUserRole(id: any) {
    return this.http
      .get(environment.url + "/delete_user_roles?id=" + id)
      .pipe(catchError(this.handleError));
  }
  deleteUsermenu(params: any) {
    return this.http
      .post(environment.url + "/del_role_menus", params)
      .pipe(catchError(this.handleError));
  }

  getUserProjectRoles(params: any) {
    return this.http
      .post(environment.url + "/user_role_access", params)
      .pipe(catchError(this.handleError));
  }

  deleteProjectActualQty(id: any) {
    return this.http
      .get(environment.url + "/delete_actual_quantity?act_qty_id=" + id)
      .pipe(catchError(this.handleError));
  }

  handleError(error: Response | any) {
    const loader = AppInjector.get(LoaderService);
    if(error.error.hasOwnProperty('statusCode') && error.error.statusCode == 400) {
      loader.hide();     
      Swal.fire({
        text:  error['error']['message'],
        icon: "error",
        customClass: "swal-wide",
        width: 500,
      });
      
      return throwError(error.error);

    }
     else {
      // const myService = AppInjector.get(AlertCallService);
    
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // myService.showWarning('Alert', error.error.message);
    // Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false,
    //  timer: 3000,  text: error.message, icon: 'error', });
    Swal.fire({
      text: error.message,
      icon: "error",
      customClass: "swal-wide",
      width: 500,
    });
    loader.hide();
    return throwError(() => {
      return errorMessage;
    });

    return throwError(error.error);

    }
    
    
  }
  addmaingroupdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialMainGroup", params)
      .pipe(catchError(this.handleError));
  }
  getmaingroupdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialMainGroup", params)
      .pipe(catchError(this.handleError));
  }
  updatemaingroupdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialMainGroup", params)
      .pipe(catchError(this.handleError));
  }
  deletemaingroupdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialMainGroup", params)
      .pipe(catchError(this.handleError));
  }
  dropdownsubgroupdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialMainGroup", params)
      .pipe(catchError(this.handleError));
  }
  addsubgroupdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialSubGroup", params)
      .pipe(catchError(this.handleError));
  }
  getsubgroupdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialSubGroup", params)
      .pipe(catchError(this.handleError));
  }
  updatesubgroupdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialSubGroup", params)
      .pipe(catchError(this.handleError));
  }
  deletesubgroupdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialSubGroup", params)
      .pipe(catchError(this.handleError));
  }
  addunitdata(params: any) {
    return this.http
      .post(environment.url + "/UnitOfMeasurement", params)
      .pipe(catchError(this.handleError));
  }
  getunitdata(params: any) {
    return this.http
      .post(environment.url + "/UnitOfMeasurement", params)
      .pipe(catchError(this.handleError));
  }
  updateunitdata(params: any) {
    return this.http
      .post(environment.url + "/UnitOfMeasurement", params)
      .pipe(catchError(this.handleError));
  }
  deleteunitdata(params: any) {
    return this.http
      .post(environment.url + "/UnitOfMeasurement", params)
      .pipe(catchError(this.handleError));
  }
  addcategorydata(params: any) {
    return this.http
      .post(environment.url + "/MaterialCategory", params)
      .pipe(catchError(this.handleError));
  }
  getcategorydata(params: any) {
    return this.http
      .post(environment.url + "/MaterialCategory", params)
      .pipe(catchError(this.handleError));
  }
  updatecategorydata(params: any) {
    return this.http
      .post(environment.url + "/MaterialCategory", params)
      .pipe(catchError(this.handleError));
  }
  deletecategorydata(params: any) {
    return this.http
      .post(environment.url + "/MaterialCategory", params)
      .pipe(catchError(this.handleError));
  }
  addvalutiondata(params: any) {
    return this.http
      .post(environment.url + "/MaterialValutionType", params)
      .pipe(catchError(this.handleError));
  }
  getvalutiondata(params: any) {
    return this.http
      .post(environment.url + "/MaterialValutionType", params)
      .pipe(catchError(this.handleError));
  }
  updatevalutiondata(params: any) {
    return this.http
      .post(environment.url + "/MaterialValutionType", params)
      .pipe(catchError(this.handleError));
  }
  deletevalutiondata(params: any) {
    return this.http
      .post(environment.url + "/MaterialValutionType", params)
      .pipe(catchError(this.handleError));
  }
  addmaterialcriticaldata(params: any) {
    return this.http
      .post(environment.url + "/MaterialCriticality", params)
      .pipe(catchError(this.handleError));
  }
  getmaterialcriticaldata(params: any) {
    return this.http
      .post(environment.url + "/MaterialCriticality", params)
      .pipe(catchError(this.handleError));
  }
  updatematerialcriticaldata(params: any) {
    return this.http
      .post(environment.url + "/MaterialCriticality", params)
      .pipe(catchError(this.handleError));
  }
  deletematerialcriticaldata(params: any) {
    return this.http
      .post(environment.url + "/MaterialCriticality", params)
      .pipe(catchError(this.handleError));
  }
  addmaterialclassdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialClass", params)
      .pipe(catchError(this.handleError));
  }
  getmaterialclassdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialClass", params)
      .pipe(catchError(this.handleError));
  }
  updatematerialclassdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialClass", params)
      .pipe(catchError(this.handleError));
  }
  deletematerialclassdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialClass", params)
      .pipe(catchError(this.handleError));
  }
  addmaterialmasterdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialMaster", params)
      .pipe(catchError(this.handleError));
  }
  getmaterialmasterdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialMaster", params)
      .pipe(catchError(this.handleError));
  }
 
  updatematerialmasterdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialMaster", params)
      .pipe(catchError(this.handleError));
  }
  deletematerialmasterdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialMaster", params)
      .pipe(catchError(this.handleError));
  }
  addvendormaster(params: any) {
    return this.http
      .post(environment.url + "/VendorMaster", params)
      .pipe(catchError(this.handleError));
  }
  getvendormasterdata(params: any) {
    return this.http
      .post(environment.url + "/VendorMaster", params)
      .pipe(catchError(this.handleError));
  }
  updatevendormasterdata(params: any) {
    return this.http
      .post(environment.url + "/VendorMaster", params)
      .pipe(catchError(this.handleError));
  }
  deletevendormasterdata(params: any) {
    return this.http
      .post(environment.url + "/VendorMaster", params)
      .pipe(catchError(this.handleError));
  }
  addcontractormaster(params: any) {
    return this.http
      .post(environment.url + "/ContractorMaster", params)
      .pipe(catchError(this.handleError));
  }
  getcontractormasterdata(params: any) {
    return this.http
      .post(environment.url + "/ContractorMaster", params)
      .pipe(catchError(this.handleError));
  }
  updatecontractormasterdata(params: any) {
    return this.http
      .post(environment.url + "/ContractorMaster", params)
      .pipe(catchError(this.handleError));
  }
  deletecontractormasterdata(params: any) {
    return this.http
      .post(environment.url + "/ContractorMaster", params)
      .pipe(catchError(this.handleError));
  }
  addcompanymaster(params: any) {
    return this.http
      .post(environment.url + "/CompanyMaster", params)
      .pipe(catchError(this.handleError));
  }
  getcompanymasterdata(params: any) {
    return this.http
      .post(environment.url + "/CompanyMaster", params)
      .pipe(catchError(this.handleError));
  }
  updatecompanymasterdata(params: any) {
    return this.http
      .post(environment.url + "/CompanyMaster", params)
      .pipe(catchError(this.handleError));
  }
  deletecompanymasterdata(params: any) {
    return this.http
      .post(environment.url + "/CompanyMaster", params)
      .pipe(catchError(this.handleError));
  }
  addtaxlist(params: any) {
    return this.http
      .post(environment.url + "/TaxList", params)
      .pipe(catchError(this.handleError));
  }
  gettaxlistdata(params: any) {
    return this.http
      .post(environment.url + "/TaxList", params)
      .pipe(catchError(this.handleError));
  }
  // updatetaxlistdata(params: any) {
  //   return this.http
  //     .post(environment.url + '/TaxList', params)
  //     .pipe(catchError(this.handleError));
  // }
  deletetaxlistdata(params: any) {
    return this.http
      .post(environment.url + "/TaxList", params)
      .pipe(catchError(this.handleError));
  }
  addfinancialyear(params: any) {
    return this.http
      .post(environment.url + "/FinancialYear", params)
      .pipe(catchError(this.handleError));
  }
  getfinancialyear(params: any) {
    return this.http
      .post(environment.url + "/FinancialYear", params)
      .pipe(catchError(this.handleError));
  }
  updatefinancialyear(params: any) {
    return this.http
      .post(environment.url + "/FinancialYear", params)
      .pipe(catchError(this.handleError));
  }
  deletefinancialyear(params: any) {
    return this.http
      .post(environment.url + "/FinancialYear", params)
      .pipe(catchError(this.handleError));
  }
  getmattransnamedata(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt", params)
      .pipe(catchError(this.handleError));
  }
  getrgpdata(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt", params)
      .pipe(catchError(this.handleError));
  }
  getmatvehiclenumdata(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt", params)
      .pipe(catchError(this.handleError));
  }
  getmatstoragelocdata(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt", params)
      .pipe(catchError(this.handleError));
  }
  addmaterialreceiptdata(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt", params)
      .pipe(catchError(this.handleError));
  }
  getmaterialreceiptdata(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt", params)
      .pipe(catchError(this.handleError));
  }
  deletematerialreceiptdata(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt", params)
      .pipe(catchError(this.handleError));
  }
  editmaterialreceiptdata(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt", params)
      .pipe(catchError(this.handleError));
  }
  getdmrdata(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt", params)
      .pipe(catchError(this.handleError));
  }
  addQualityCheck(params: any) {
    return this.http
      .post(environment.url + "/QualityCheck", params)
      .pipe(catchError(this.handleError));
  }
  getQualityCheck(params: any) {
    return this.http
      .post(environment.url + "/QualityCheck", params)
      .pipe(catchError(this.handleError));
  }
  getQualityCheckdmrdata(params: any) {
    return this.http
      .post(environment.url + "/QualityCheck", params)
      .pipe(catchError(this.handleError));
  }
  deleteQualityCheck(params: any) {
    return this.http
      .post(environment.url + "/QualityCheck", params)
      .pipe(catchError(this.handleError));
  }
  getdmrdatadropdown(params: any) {
    return this.http
      .post(environment.url + "/QualityCheck", params)
      .pipe(catchError(this.handleError));
  }
  getgoodsreceiptlist(params: any) {
    return this.http
      .post(environment.url + "/GoodsReceipt", params)
      .pipe(catchError(this.handleError));
  }
  editgoodsreceiptdata(params: any) {
    return this.http
      .post(environment.url + "/GoodsReceipt", params)
      .pipe(catchError(this.handleError));
  }
  getgoodsreceiptdmrlist(params: any) {
    return this.http
      .post(environment.url + "/GoodsReceipt", params)
      .pipe(catchError(this.handleError));
  }
  addgoodsreceiptlist(params: any) {
    return this.http
      .post(environment.url + "/GoodsReceipt", params)
      .pipe(catchError(this.handleError));
  }
  addfinalgoodsreceiptlist(params: any) {
    return this.http
      .post(environment.url + "/GoodsReceipt", params)
      .pipe(catchError(this.handleError));
  }
  deletegoodsreceiptlist(params: any) {
    return this.http
      .post(environment.url + "/GoodsReceipt", params)
      .pipe(catchError(this.handleError));
  }
  addgoodsissue(params: any) {
    return this.http
      .post(environment.url + "/GoodsIssue", params)
      .pipe(catchError(this.handleError));
  }
  editgoodsissue(params: any) {
    return this.http
      .post(environment.url + "/GoodsIssue", params)
      .pipe(catchError(this.handleError));
  }
  deletegoodsissue(params: any) {
    return this.http
      .post(environment.url + "/GoodsIssue", params)
      .pipe(catchError(this.handleError));
  }
  getgoodsissue(params: any) {
    return this.http
      .post(environment.url + "/GoodsIssue", params)
      .pipe(catchError(this.handleError));
  }
  getavailablestockdata(params: any) {
    return this.http
      .post(environment.url + "/InventoryReports", params)
      .pipe(catchError(this.handleError));
  }
  getsmsdata(params: any) {
    return this.http
      .post(environment.url + "/InventoryReports", params)
      .pipe(catchError(this.handleError));
  }
  addgoodsreturn(params: any) {
    return this.http
      .post(environment.url + "/GoodsReturn", params)
      .pipe(catchError(this.handleError));
  }
  getgoodsreturn(params: any) {
    return this.http
      .post(environment.url + "/GoodsReturn", params)
      .pipe(catchError(this.handleError));
  }
  deletegoodsreturn(params: any) {
    return this.http
      .post(environment.url + "/GoodsReturn", params)
      .pipe(catchError(this.handleError));
  }
  getgoodsreturnprint(params: any) {
    return this.http
      .post(environment.url + "/GoodsReturn", params)
      .pipe(catchError(this.handleError));
  }
  addgoodstransfer(params: any) {
    return this.http
      .post(environment.url + "/GoodsTransfer", params)
      .pipe(catchError(this.handleError));
  }
  getgoodstransfer(params: any) {
    return this.http
      .post(environment.url + "/GoodsTransfer", params)
      .pipe(catchError(this.handleError));
  }
  deletegoodstransfer(params: any) {
    return this.http
      .post(environment.url + "/GoodsTransfer", params)
      .pipe(catchError(this.handleError));
  }
  addRgpData(params: any) {
    return this.http
      .post(environment.url + "/GoodsTransfer", params)
      .pipe(catchError(this.handleError));
  }
  addrequestmaterialdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialRequest", params)
      .pipe(catchError(this.handleError));
  }
  editrequestmaterialdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialRequest", params)
      .pipe(catchError(this.handleError));
  }
  getmaterialrequestdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialRequest", params)
      .pipe(catchError(this.handleError));
  }
  deletematerialrequestdata(params: any) {
    return this.http
      .post(environment.url + "/MaterialRequest", params)
      .pipe(catchError(this.handleError));
  }
  addpurchaseorderdata(params: any) {
    return this.http
      .post(environment.url + "/PurchaseOrder", params)
      .pipe(catchError(this.handleError));
  }
  editpurchaseorderdata(params: any) {
    return this.http
      .post(environment.url + "/PurchaseOrder", params)
      .pipe(catchError(this.handleError));
  }
  getpurchaseorderdata(params: any) {
    return this.http
      .post(environment.url + "/PurchaseOrder", params)
      .pipe(catchError(this.handleError));
  }
  getpurchaseorderdata2(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt2", params)
      .pipe(catchError(this.handleError));
  }
  deletepurchaseorderdata(params: any) {
    return this.http
      .post(environment.url + "/PurchaseOrder", params)
      .pipe(catchError(this.handleError));
  }
  setpurchaseorderdata(params: any) {
    return this.http
      .post(environment.url + "/PurchaseOrder", params)
      .pipe(catchError(this.handleError));
  }
  printpurchaseorderdata(params: any) {
    return this.http
      .post(environment.url + "/PurchaseOrder", params)
      .pipe(catchError(this.handleError));
  }
  getpurchaserequestdata(params: any) {
    return this.http
      .post(environment.url + "/PurchaseOrder", params)
      .pipe(catchError(this.handleError));
  }
  addpurchaserequestdata(params: any) {
    return this.http
      .post(environment.url + "/PurchaseRequest", params)
      .pipe(catchError(this.handleError));
  }
  getpurchaseRequestdata(params: any) {
    return this.http
      .post(environment.url + "/PurchaseRequest", params)
      .pipe(catchError(this.handleError));
  }
  deletepurchaserequestdata(params: any) {
    return this.http
      .post(environment.url + "/PurchaseRequest", params)
      .pipe(catchError(this.handleError));
  }
  getpurchaserequestdataprint(params: any) {
    return this.http
      .post(environment.url + "/PurchaseRequest", params)
      .pipe(catchError(this.handleError));
  }
  getInventoryReport(params: any) {
    return this.http
      .post(environment.url + "/InventoryReports", params)
      .pipe(catchError(this.handleError));
  }
  addqsd(params: any) {
    return this.http
      .post(environment.url + "/QsdSupply", params)
      .pipe(catchError(this.handleError));
  }
  setqsd(params: any) {
    return this.http
      .post(environment.url + "/QsdSupply", params)
      .pipe(catchError(this.handleError));
  }
  getqsd(params: any) {
    return this.http
      .post(environment.url + "/QsdSupply", params)
      .pipe(catchError(this.handleError));
  }
  addqsdserviceswonumber(params: any) {
    return this.http
      .post(environment.url + "/QsdServices", params)
      .pipe(catchError(this.handleError));
  }
  addqsdservices(params: any) {
    return this.http
      .post(environment.url + "/QsdServices", params)
      .pipe(catchError(this.handleError));
  }
  setqsdservices(params: any) {
    return this.http
      .post(environment.url + "/QsdServices", params)
      .pipe(catchError(this.handleError));
  }
  getqsdservices(params: any) {
    return this.http
      .post(environment.url + "/QsdServices", params)
      .pipe(catchError(this.handleError));
  }
  getServicesData(params: any) {
    return this.http
      .post(environment.url + "/ServiceMaster", params)
      .pipe(catchError(this.handleError));
  }
  getServiceMasterData(params: any) {
    return this.http
      .post(environment.url + "/ServiceMaster", params)
      .pipe(catchError(this.handleError));
  }
  deleteservicemasterdata(params: any) {
    return this.http
      .post(environment.url + "/ServiceMaster", params)
      .pipe(catchError(this.handleError));
  }
  addGateInwardData(params: any) {
    return this.http
      .post(environment.url + "/GateInward", params)
      .pipe(catchError(this.handleError));
  }
  getGateInwardData(params: any) {
    return this.http
      .post(environment.url + "/GateInward", params)
      .pipe(catchError(this.handleError));
  }
  deleteGateInwardData(params: any) {
    return this.http
      .post(environment.url + "/GateInward", params)
      .pipe(catchError(this.handleError));
  }
  getGateInwardsearchData(params: any) {
    return this.http
      .post(environment.url + "/GateInward", params)
      .pipe(catchError(this.handleError));
  }
  addGateOutwardData(params: any) {
    return this.http
      .post(environment.url + "/GateOutward", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardData(params: any) {
    return this.http
      .post(environment.url + "/GateOutward", params)
      .pipe(catchError(this.handleError));
  }
  deleteGateOutwardData(params: any) {
    return this.http
      .post(environment.url + "/GateOutward", params)
      .pipe(catchError(this.handleError));
  }
  addfileupload(params: any) {
    return this.http
      .post(environment.url + "/UploadDocuments", params)
      .pipe(catchError(this.handleError));
  }
  getexistingfies(params: any) {
    return this.http
      .get(environment.url + "/ReadDocuments", { params })
      .pipe(catchError(this.handleError));
  }
  getexistingfies1(params: any) {
    return this.http
      .get(environment.url + "/ReadDocuments1", { params })
      .pipe(catchError(this.handleError));
  }
  deletefiles(params: any) {
    return this.http
      .get(environment.url + "/RemoveDocuments", { params })
      .pipe(catchError(this.handleError));
  }
  getGateInwardReport(params: any) {
    return this.http
      .post(environment.url + "/GssReports", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardReport(params: any) {
    return this.http
      .post(environment.url + "/GssReports", params)
      .pipe(catchError(this.handleError));
  }
  getPotDateData(params: any) {
    return this.http
      .post(environment.url + "/PoReports", params)
      .pipe(catchError(this.handleError));
  }
  getPotNumData(params: any) {
    return this.http
      .post(environment.url + "/PoReports", params)
      .pipe(catchError(this.handleError));
  }
  getPrtDateData(params: any) {
    return this.http
      .post(environment.url + "/PrReports", params)
      .pipe(catchError(this.handleError));
  }
  getPrtNumData(params: any) {
    return this.http
      .post(environment.url + "/PrReports", params)
      .pipe(catchError(this.handleError));
  }
  getEsdnDateData(params:any){
    return this.http
    .post(environment.rmc_base_url + "/esdn/generateExcelReport", params)
    .pipe(catchError(this.handleError));
}

getDocumentDescription(){
  return this.http
  .get(environment.rmc_base_url + "/documentDescription/getDocumentDescription")
  .pipe(catchError(this.handleError));
}
  gerservicecodedropdown(params: any) {
    return this.http
      .post(environment.url + "/ServiceMaster", params)
      .pipe(catchError(this.handleError));
  }
  addsiterecommendationsheet(params: any) {
    return this.http
      .post(environment.url + "/SiteRecommendationSheet", params)
      .pipe(catchError(this.handleError));
  }
  getsiterecommendationsheet(params: any) {
    return this.http
      .post(environment.url + "/SiteRecommendationSheet", params)
      .pipe(catchError(this.handleError));
  }
  deletesiterecommendationsheet(params: any) {
    return this.http
      .post(environment.url + "/SiteRecommendationSheet", params)
      .pipe(catchError(this.handleError));
  }
  addworkrequestdata(params: any) {
    return this.http
      .post(environment.url + "/WorkRequest", params)
      .pipe(catchError(this.handleError));
  }
  getWRlistData(params: any) {
    return this.http
      .post(environment.url + "/WorkRequest", params)
      .pipe(catchError(this.handleError));
  }
  getWRdropdownData(params: any) {
    return this.http
      .post(environment.url + "/WorkOrder", params)
      .pipe(catchError(this.handleError));
  }
  deleteWorkRequestData(params: any) {
    return this.http
      .post(environment.url + "/WorkRequest", params)
      .pipe(catchError(this.handleError));
  }
  addWorkOrderRequisitionData(params: any) {
    return this.http
      .post(environment.url + "/WorkRequest", params)
      .pipe(catchError(this.handleError));
  }
  deleteWCRData(params: any) {
    return this.http
      .post(environment.url + "/WorkCompletion", params)
      .pipe(catchError(this.handleError));
  }
  getWCRlistData(params: any) {
    return this.http
      .post(environment.url + "/WorkCompletion", params)
      .pipe(catchError(this.handleError));
  }
  addWCRdata(params: any) {
    return this.http
      .post(environment.url + "/WorkCompletion", params)
      .pipe(catchError(this.handleError));
  }
  getsampledata(params: any) {
    return this.http
      .post(environment.url + "/SampleTable", params)
      .pipe(catchError(this.handleError));
  }
  getWOlistData(params: any) {
    return this.http
      .post(environment.url + "/WorkOrder", params)
      .pipe(catchError(this.handleError));
  }
  gettabledatalist(params: any) {
    return this.http
      .post(environment.url + "/WorkOrder", params)
      .pipe(catchError(this.handleError));
  }
  addworkorderdata(params: any) {
    return this.http
      .post(environment.url + "/WorkOrder", params)
      .pipe(catchError(this.handleError));
  }
  deleteworkOrderdata(params: any) {
    return this.http
      .post(environment.url + "/WorkOrder", params)
      .pipe(catchError(this.handleError));
  }
  addSiteRecommendedSheetData(params: any) {
    return this.http
      .post(environment.url + "/SiteRecommendationSheet", params)
      .pipe(catchError(this.handleError));
  }
  getJMSlistdata(params: any) {
    return this.http
      .post(environment.url + "/JointMeasurementSheet", params)
      .pipe(catchError(this.handleError));
  }
  deleteJMSdata(params: any) {
    return this.http
      .post(environment.url + "/JointMeasurementSheet", params)
      .pipe(catchError(this.handleError));
  }
  addJMSdata(params: any) {
    return this.http
      .post(environment.url + "/JointMeasurementSheet", params)
      .pipe(catchError(this.handleError));
  }
  getWOlist(params: any) {
    return this.http
      .post(environment.url + "/JointMeasurementSheet", params)
      .pipe(catchError(this.handleError));
  }
  getTABLEdata(params: any) {
    return this.http
      .post(environment.url + "/JointMeasurementSheet", params)
      .pipe(catchError(this.handleError));
  }
  getActivityLog(params: any) {
    return this.http
      .post(environment.url + "/GetLog", params)
      .pipe(catchError(this.handleError));
  }
  viewActivityLog(params: any) {
    return this.http
      .post(environment.url + "/ViewLog", params)
      .pipe(catchError(this.handleError));
  }
  addUserCredentials(params: any) {
    return this.http
      .post(environment.url + "/UserCredentials", params)
      .pipe(catchError(this.handleError));
  }
  getUserCredentialsModule(params: any) {
    return this.http
      .post(environment.url + "/UserCredentials", params)
      .pipe(catchError(this.handleError));
  }
  getUserCredentialsMenu(params: any) {
    return this.http
      .post(environment.url + "/UserCredentials", params)
      .pipe(catchError(this.handleError));
  }
  addQsdData(params: any) {
    return this.http
      .post(environment.url + "/QsdServices", params)
      .pipe(catchError(this.handleError));
  }
  printWOdetails(params: any) {
    return this.http
      .post(environment.url + "/WorkOrder", params)
      .pipe(catchError(this.handleError));
  }
  getMIlistData(params: any) {
    return this.http
      .post(environment.url + "/ModelInvoice", params)
      .pipe(catchError(this.handleError));
  }
  deleteMIlistdata(params: any) {
    return this.http
      .post(environment.url + "/ModelInvoice", params)
      .pipe(catchError(this.handleError));
  }
  addmodelinvoicedata(params: any) {
    return this.http
      .post(environment.url + "/ModelInvoice", params)
      .pipe(catchError(this.handleError));
  }
  getMImatData(params: any) {
    return this.http
      .post(environment.url + "/ModelInvoice", params)
      .pipe(catchError(this.handleError));
  }
  getexistingfieswr(params: any) {
    return this.http
      .post(environment.url + "/WorkRequest", params)
      .pipe(catchError(this.handleError));
  }
  addInvoiceData(params: any) {
    return this.http
      .post(environment.url + "/ModelInvoice", params)
      .pipe(catchError(this.handleError));
  }
  printJMSdetails(params: any) {
    return this.http
      .post(environment.url + "/JointMeasurementSheet", params)
      .pipe(catchError(this.handleError));
  }
  printWCRdetails(params: any) {
    return this.http
      .post(environment.url + "/WorkCompletion", params)
      .pipe(catchError(this.handleError));
  }
  vehicleMasterListData(params: any) {
    return this.http
      .post(environment.url + "/VehicleMaster", params)
      .pipe(catchError(this.handleError));
  }
  addVehiclemasterData(params: any) {
    return this.http
      .post(environment.url + "/VehicleMaster", params)
      .pipe(catchError(this.handleError));
  }
  deleteVehicleMasterData(params: any) {
    return this.http
      .post(environment.url + "/VehicleMaster", params)
      .pipe(catchError(this.handleError));
  }
  addjobOrder(params: any) {
    return this.http
      .post(environment.url + "/JobOrder", params)
      .pipe(catchError(this.handleError));
  }
  getJobOrderData(params: any) {
    return this.http
      .post(environment.url + "/JobOrder", params)
      .pipe(catchError(this.handleError));
  }
  updateJobOrder(params: any) {
    return this.http
      .post(environment.url + "/JobOrder", params)
      .pipe(catchError(this.handleError));
  }
  deleteJoFile(body: any) {
    return this.http
      .post(environment.url + "/JobOrder", body)
      .pipe(catchError(this.handleError));
  }
  getJoBOoqList(params: any) {
    return this.http
      .post(environment.url + "/JoBoqRequired", params)
      .pipe(catchError(this.handleError));
  }
  getBoqIdDetails(params: any) {
    return this.http
      .post(environment.url + "/JoBoqRequired", params)
      .pipe(catchError(this.handleError));
  }
  getSubContractGoodsIssueData(params: any) {
    return this.http
      .post(environment.url + "/SubContractGoodsIssue", params)
      .pipe(catchError(this.handleError));
  }
  addSCGIData(params: any) {
    return this.http
      .post(environment.url + "/SubContractGoodsIssue", params)
      .pipe(catchError(this.handleError));
  }
  deleteSCGIdata(params: any) {
    return this.http
      .post(environment.url + "/SubContractGoodsIssue", params)
      .pipe(catchError(this.handleError));
  }
  editSCGIdata(params: any) {
    return this.http
      .post(environment.url + "/SubContractGoodsIssue", params)
      .pipe(catchError(this.handleError));
  }
  getMailData(params: any) {
    return this.http
      .post(environment.url + "/MailDrawings", params)
      .pipe(catchError(this.handleError));
  }
  vendorBunkRequestList(params: any) {
    return this.http
      .post(environment.url + "/VendorBunkRequest", params)
      .pipe(catchError(this.handleError));
  }
  addVendorBunkReqData(params: any) {
    return this.http
      .post(environment.url + "/VendorBunkRequest", params)
      .pipe(catchError(this.handleError));
  }
  deletevendorBunkRequest(params: any) {
    return this.http
      .post(environment.url + "/VendorBunkRequest", params)
      .pipe(catchError(this.handleError));
  }
  editVendorBunkRequest(params: any) {
    return this.http
      .post(environment.url + "/VendorBunkRequest", params)
      .pipe(catchError(this.handleError));
  }

  addfuelReceiptData(params: any) {
    return this.http
      .post(environment.url + "/FuelReceiptNote", params)
      .pipe(catchError(this.handleError));
  }
  getFuelReceiptData(params: any) {
    return this.http
      .post(environment.url + "/FuelReceiptNote", params)
      .pipe(catchError(this.handleError));
  }
  updateFuelReceipt(params: any) {
    return this.http
      .post(environment.url + "/FuelReceiptNote", params)
      .pipe(catchError(this.handleError));
  }
  deleteFuelReceiptFile(params: any) {
    return this.http
      .post(environment.url + "/FuelReceiptNote", params)
      .pipe(catchError(this.handleError));
  }

  //////////////////////////////////////////////////////////

  addFuelIssueReq(params: any) {
    return this.http
      .post(environment.url + "/FuelIssueRequest", params)
      .pipe(catchError(this.handleError));
  }
  getFuelIssueReqData(params: any) {
    return this.http
      .post(environment.url + "/FuelIssueRequest", params)
      .pipe(catchError(this.handleError));
  }
  updateFuelIssueRequest(params: any) {
    return this.http
      .post(environment.url + "/FuelIssueRequest", params)
      .pipe(catchError(this.handleError));
  }
  deleteFuelIssueReqFile(params: any) {
    return this.http
      .post(environment.url + "/FuelIssueRequest", params)
      .pipe(catchError(this.handleError));
  }
  addBunkRequestData(params: any) {
    return this.http
      .post(environment.url + "/BunkConfirmation", params)
      .pipe(catchError(this.handleError));
  }
  getBunkConfirmData(params: any) {
    return this.http
      .post(environment.url + "/BunkConfirmation", params)
      .pipe(catchError(this.handleError));
  }
  addBunkConfirm(params: any) {
    return this.http
      .post(environment.url + "/BunkConfirmation", params)
      .pipe(catchError(this.handleError));
  }
  updateBunkConfirmation(params: any) {
    return this.http
      .post(environment.url + "/BunkConfirmation", params)
      .pipe(catchError(this.handleError));
  }
  deleteBunkConfirmFile(params: any) {
    return this.http
      .post(environment.url + "/BunkConfirmation", params)
      .pipe(catchError(this.handleError));
  }
  getMatVBR(params: any) {
    return this.http
      .post(environment.url + "/VendorBunkRequest", params)
      .pipe(catchError(this.handleError));
  }
  getRequestNumber(params: any) {
    return this.http
      .post(environment.url + "/VendorBunkRequest", params)
      .pipe(catchError(this.handleError));
  }

  //search functionality services
  getSearchDailyMaterialReceipt(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt", params)
      .pipe(catchError(this.handleError));
  }
  getSearchQualityCheck(params: any) {
    return this.http
      .post(environment.url + "/QualityCheck", params)
      .pipe(catchError(this.handleError));
  }
  getSearchGoodsReceipt(params: any) {
    return this.http
      .post(environment.url + "/GoodsReceipt", params)
      .pipe(catchError(this.handleError));
  }
  getSearchGoodsIssue1(params: any) {
    return this.http
      .post(environment.url + "/GoodsIssue", params)
      .pipe(catchError(this.handleError));
  }
  getSearchDeliveryChallan(params: any) {
    return this.http
      .post(environment.url + "/DeliveryChallan", params)
      .pipe(catchError(this.handleError));
  }
  getSearchGoodsGoodsReturn(params: any) {
    return this.http
      .post(environment.url + "/GoodsReturn", params)
      .pipe(catchError(this.handleError));
  }
  getSearchGoodsTransfer(params: any) {
    return this.http
      .post(environment.url + "/GoodsTransfer", params)
      .pipe(catchError(this.handleError));
  }
  getSearchPurchaseRequest(params: any) {
    return this.http
      .post(environment.url + "/PurchaseRequest", params)
      .pipe(catchError(this.handleError));
  }
  getSearchWorkRequest(params: any) {
    return this.http
      .post(environment.url + "/WorkRequest", params)
      .pipe(catchError(this.handleError));
  }
  addDeliveryChallan(params: any) {
    return this.http
      .post(environment.url + "/DeliveryChallan", params)
      .pipe(catchError(this.handleError));
  }
  updateDeliveryChallan(params: any) {
    return this.http
      .post(environment.url + "/DeliveryChallan", params)
      .pipe(catchError(this.handleError));
  }
  getDeliveryChallanData(params: any) {
    return this.http
      .post(environment.url + "/DeliveryChallan", params)
      .pipe(catchError(this.handleError));
  }
  deleteDcFile(body: any) {
    return this.http
      .post(environment.url + "/DeliveryChallan", body)
      .pipe(catchError(this.handleError));
  }
  //purchase request2 service
  addPurchaseRequest2(params: any) {
    return this.http
      .post(environment.url + "/PurchaseRequest2", params)
      .pipe(catchError(this.handleError));
  }
  getpurchaseRequest2(params: any) {
    return this.http
      .post(environment.url + "/PurchaseRequest2", params)
      .pipe(catchError(this.handleError));
  }
  deletepurchaserequest2(params: any) {
    return this.http
      .post(environment.url + "/PurchaseRequest2", params)
      .pipe(catchError(this.handleError));
  }
  getpurchaserequest2print(params: any) {
    return this.http
      .post(environment.url + "/PurchaseRequest2", params)
      .pipe(catchError(this.handleError));
  }
  getSearchPurchaseRequest2(params: any) {
    return this.http
      .post(environment.url + "/PurchaseRequest2", params)
      .pipe(catchError(this.handleError));
  }
  //goods issue request2 services
  addgoodsissuerequest2(params: any) {
    return this.http
      .post(environment.url + "/MaterialRequest2", params)
      .pipe(catchError(this.handleError));
  }
  addgoodsissuerequest22(params: any) {
    return this.http
      .post(environment.url + "/GoodsIssue2", params)
      .pipe(catchError(this.handleError));
  }
  editgoodsissuerequest2(params: any) {
    return this.http
      .post(environment.url + "/MaterialRequest2", params)
      .pipe(catchError(this.handleError));
  }
  getgoodsissuerequest2(params: any) {
    return this.http
      .post(environment.url + "/MaterialRequest2", params)
      .pipe(catchError(this.handleError));
  }
  deletegoodsissuerequest2(params: any) {
    return this.http
      .post(environment.url + "/MaterialRequest2", params)
      .pipe(catchError(this.handleError));
  }
  //Outgatepass rgp services
  addoutgatepassrgp(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassRGP", params)
      .pipe(catchError(this.handleError));
  }
  getoutgatepassrgp(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassRGP", params)
      .pipe(catchError(this.handleError));
  }
  deleteoutgatepassrgp(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassRGP", params)
      .pipe(catchError(this.handleError));
  }
  CancelDocumentSign(params: any) {
    return this.http
      .post(environment.url + "/DocumentSign", params)
      .pipe(catchError(this.handleError));
  }
  printoutgatepassRgpData(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassRGP", params)
      .pipe(catchError(this.handleError));
  }
  //InventoryReports2
  getsmsdata2(params: any) {
    return this.http
      .post(environment.url + "/InventoryReports2", params)
      .pipe(catchError(this.handleError));
  }
  //Quality check in project details
  addQualityCheckForTask(data: any) {
    return this.http
      .post(environment.url + "/task_qty_qc", data)
      .pipe(catchError(this.handleError));
  }
  //po upload services
  addfilepoupload(params: any) {
    return this.http
      .post(environment.url + "/UploadPO", params)
      .pipe(catchError(this.handleError));
  }

  excelUpload(body: any) {
    return this.http
      .post(environment.url + "/SaveXlPo", body)
      .pipe(catchError(this.handleError));
  }

  //Outgatepass rgp2 services
  addoutgatepassrgp2(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassRGP2", params)
      .pipe(catchError(this.handleError));
  }
  getoutgatepassrgp2(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassRGP2", params)
      .pipe(catchError(this.handleError));
  }
  deleteoutgatepassrgp2(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassRGP2", params)
      .pipe(catchError(this.handleError));
  }
  printoutgatepassRgpData2(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassRGP2", params)
      .pipe(catchError(this.handleError));
  }
  //inventory - goods issue
  addgoodsissue2(params: any) {
    return this.http
      .post(environment.url + "/GoodsIssue2", params)
      .pipe(catchError(this.handleError));
  }
  deletegoodsissue2(params: any) {
    return this.http
      .post(environment.url + "/GoodsIssue2", params)
      .pipe(catchError(this.handleError));
  }
  getgoodsissue2(params: any) {
    return this.http
      .post(environment.url + "/GoodsIssue2", params)
      .pipe(catchError(this.handleError));
  }
  getSearchGoodsIssue2(params: any) {
    return this.http
      .post(environment.url + "/GoodsIssue2", params)
      .pipe(catchError(this.handleError));
  }
  //goods return services
  addgoodsreturn2(params: any) {
    return this.http
      .post(environment.url + "/GoodsReturn2", params)
      .pipe(catchError(this.handleError));
  }
  getgoodsreturn2(params: any) {
    return this.http
      .post(environment.url + "/GoodsReturn2", params)
      .pipe(catchError(this.handleError));
  }
  deletegoodsreturn2(params: any) {
    return this.http
      .post(environment.url + "/GoodsReturn2", params)
      .pipe(catchError(this.handleError));
  }
  getgoodsreturnprint2(params: any) {
    return this.http
      .post(environment.url + "/GoodsReturn2", params)
      .pipe(catchError(this.handleError));
  }
  getSearchGoodsGoodsReturn2(params: any) {
    return this.http
      .post(environment.url + "/GoodsReturn2", params)
      .pipe(catchError(this.handleError));
  }
  //goods receipt services integration
  getgoodsreceiptlist2(params: any) {
    return this.http
      .post(environment.url + "/GoodsReceipt2", params)
      .pipe(catchError(this.handleError));
  }
  editgoodsreceiptdata2(params: any) {
    return this.http
      .post(environment.url + "/GoodsReceipt2", params)
      .pipe(catchError(this.handleError));
  }
  getgoodsreceiptdmrlist2(params: any) {
    return this.http
      .post(environment.url + "/GoodsReceipt2", params)
      .pipe(catchError(this.handleError));
  }
  addgoodsreceiptlist2(params: any) {
    return this.http
      .post(environment.url + "/GoodsReceipt2", params)
      .pipe(catchError(this.handleError));
  }
  addfinalgoodsreceiptlist2(params: any) {
    return this.http
      .post(environment.url + "/GoodsReceipt2", params)
      .pipe(catchError(this.handleError));
  }
  deletegoodsreceiptlist2(params: any) {
    return this.http
      .post(environment.url + "/GoodsReceipt2", params)
      .pipe(catchError(this.handleError));
  }
  //quality Check2 services
  addQualityCheck2(params: any) {
    return this.http
      .post(environment.url + "/QualityCheck2", params)
      .pipe(catchError(this.handleError));
  }
  getQualityCheck2(params: any) {
    return this.http
      .post(environment.url + "/QualityCheck2", params)
      .pipe(catchError(this.handleError));
  }
  getQualityCheckdmrdata2(params: any) {
    return this.http
      .post(environment.url + "/QualityCheck2", params)
      .pipe(catchError(this.handleError));
  }
  deleteQualityCheck2(params: any) {
    return this.http
      .post(environment.url + "/QualityCheck2", params)
      .pipe(catchError(this.handleError));
  }
  getdmrdatadropdown2(params: any) {
    return this.http
      .post(environment.url + "/QualityCheck2", params)
      .pipe(catchError(this.handleError));
  }
  getSearchQualityCheck2(params: any) {
    return this.http
      .post(environment.url + "/QualityCheck2", params)
      .pipe(catchError(this.handleError));
  }
  // gate-outward-nrgp
  addGateOutwordNRGP(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardNRGP", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardNRGPList(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardNRGP", params)
      .pipe(catchError(this.handleError));
  }
  deleteGateOutwardNRGPData(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardNRGP", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardNRGPsearchData(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardNRGP", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardNRGPReports(params: any) {
    return this.http
      .post(environment.url + "/Reports_GateOutwardNRGP", params)
      .pipe(catchError(this.handleError));
  }
  // gate-outward-rgp
  addGateOutwordRGP(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardRGP", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardRGPList(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardRGP", params)
      .pipe(catchError(this.handleError));
  }
  deleteGateOutwardRGPData(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardRGP", params)
      .pipe(catchError(this.handleError));
  }
  getRGPsearchData(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardRGP", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardRgpReports(data: any) {
    return this.http
      .post(environment.url + "/Reports_GateOutwardRGP", data)
      .pipe(catchError(this.handleError));
  }
  //
  getOutGatePassNrgp(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassNRGP", params)
      .pipe(catchError(this.handleError));
  }
  addOutGatePassNrgp(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassNRGP", params)
      .pipe(catchError(this.handleError));
  }
  getNrgpDataList(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassNRGP", params)
      .pipe(catchError(this.handleError));
  }
  editNrgpData(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassNRGP", params)
      .pipe(catchError(this.handleError));
  }
  deleteNrgpList(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassNRGP", params)
      .pipe(catchError(this.handleError));
  }
  getNRGPsearchData(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassNRGP", params)
      .pipe(catchError(this.handleError));
  }
  getOgpNRGPReports(params: any) {
    return this.http
      .post(environment.url + "/Reports_OutGatePassNRGP", params)
      .pipe(catchError(this.handleError));
  }
  // gate-outward-gi
  addGateOutwordGI(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardGI", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardGIList(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardGI2", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardGIList1(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardGI", params)
      .pipe(catchError(this.handleError));
  }
  deleteGateOutwardGiData(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardGI", params)
      .pipe(catchError(this.handleError));
  }
  getGIsearchData(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardGI", params)
      .pipe(catchError(this.handleError));
  }
  getGIReportsData(params: any) {
    return this.http
      .post(environment.url + "/Reports_GateOutwardGI", params)
      .pipe(catchError(this.handleError));
  }
  // gate-inward2
  addGateInwardData2(params: any) {
    return this.http
      .post(environment.url + "/GateInward2", params)
      .pipe(catchError(this.handleError));
  }
  getGateInwardData2(params: any) {
    return this.http
      .post(environment.url + "/GateInward2", params)
      .pipe(catchError(this.handleError));
  }
  deleteGateInwardData2(params: any) {
    return this.http
      .post(environment.url + "/GateInward2", params)
      .pipe(catchError(this.handleError));
  }
  getGateInward2searchData(params: any) {
    return this.http
      .post(environment.url + "/GateInward2", params)
      .pipe(catchError(this.handleError));
  }
  // gate-outward-gi2
  addGateOutwordGI2(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardGI2", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardGI2List(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardGI2", params)
      .pipe(catchError(this.handleError));
  }
  deleteGateOutwardGi2Data(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardGI2", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutward2searchData(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardGI2", params)
      .pipe(catchError(this.handleError));
  }
  // gate-outward-nrgp2
  addGateOutwordNRGP2(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardNRGP2", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardNRGP2List(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardNRGP2", params)
      .pipe(catchError(this.handleError));
  }
  deleteGateOutwardNRGP2Data(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardNRGP2", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardNRGP2searchData(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardNRGP2", params)
      .pipe(catchError(this.handleError));
  }
  // gate-outward-rgp2
  addGateOutwordRGP2(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardRGP2", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardRGP2List(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardRGP2", params)
      .pipe(catchError(this.handleError));
  }
  deleteGateOutwardRGP2Data(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardRGP2", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardRGP2searchData(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardRGP2", params)
      .pipe(catchError(this.handleError));
  }

  // nrgp2
  addOutGatePassNrgp2(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassNRGP2", params)
      .pipe(catchError(this.handleError));
  }
  getNrgp2DataList(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassNRGP2", params)
      .pipe(catchError(this.handleError));
  }
  editNrgp2Data(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassNRGP2", params)
      .pipe(catchError(this.handleError));
  }
  deleteNrgp2List(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassNRGP2", params)
      .pipe(catchError(this.handleError));
  }
  getNRGP2searchData(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassNRGP2", params)
      .pipe(catchError(this.handleError));
  }
  printOutGatePassNrgp2(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassNRGP2", params)
      .pipe(catchError(this.handleError));
  }
  // dmr2
  getmattransnamedata2(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt2", params)
      .pipe(catchError(this.handleError));
  }
  getmatvehiclenumdata2(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt2", params)
      .pipe(catchError(this.handleError));
  }
  getmatstoragelocdata2(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt2", params)
      .pipe(catchError(this.handleError));
  }
  addmaterialreceiptdata2(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt2", params)
      .pipe(catchError(this.handleError));
  }
  getmaterialreceiptdata2(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt2", params)
      .pipe(catchError(this.handleError));
  }
  deletematerialreceiptdata2(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt2", params)
      .pipe(catchError(this.handleError));
  }
  editmaterialreceiptdata2(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt2", params)
      .pipe(catchError(this.handleError));
  }
  getdmr2searchData(params: any) {
    return this.http
      .post(environment.url + "/DailyMaterialReceipt2", params)
      .pipe(catchError(this.handleError));
  }

  //accepted quantity images
  getacceptedqtyimages(id: any) {
    return this.http
      .get(environment.url + "/get_acc_images?acc_id=" + id)
      .pipe(catchError(this.handleError));
  }
  //get inventory reports2
  getinventoryreports2(params: any) {
    return this.http
      .post(environment.url + "/InventoryReports2", params)
      .pipe(catchError(this.handleError));
  }
  getContractorData(params: any) {
    return this.http
      .post(environment.url + "/InventoryReports2", params)
      .pipe(catchError(this.handleError));
  }
  // User Credential services
  getAction(params: any) {
    return this.http
      .post(environment.url + "/UserCredentials", params)
      .pipe(catchError(this.handleError));
  }
  //fuel Inventory services2
  addfuelReceiptData2(params: any) {
    return this.http
      .post(environment.url + "/FuelReceiptNote2", params)
      .pipe(catchError(this.handleError));
  }
  getFuelReceiptData2(params: any) {
    return this.http
      .post(environment.url + "/FuelReceiptNote2", params)
      .pipe(catchError(this.handleError));
  }
  updateFuelReceipt2(params: any) {
    return this.http
      .post(environment.url + "/FuelReceiptNote2", params)
      .pipe(catchError(this.handleError));
  }
  deleteFuelReceiptFile2(params: any) {
    return this.http
      .post(environment.url + "/FuelReceiptNote2", params)
      .pipe(catchError(this.handleError));
  }
  //vendor bunk request2
  vendorBunkRequestList2(params: any) {
    return this.http
      .post(environment.url + "/VendorBunkRequest2", params)
      .pipe(catchError(this.handleError));
  }
  addVendorBunkReqData2(params: any) {
    return this.http
      .post(environment.url + "/VendorBunkRequest2", params)
      .pipe(catchError(this.handleError));
  }
  deletevendorBunkRequest2(params: any) {
    return this.http
      .post(environment.url + "/VendorBunkRequest2", params)
      .pipe(catchError(this.handleError));
  }
  editVendorBunkRequest2(params: any) {
    return this.http
      .post(environment.url + "/VendorBunkRequest2", params)
      .pipe(catchError(this.handleError));
  }
  gettaskimagesdata(params: any) {
    return this.http
      .post(environment.url + "/get_project_images", params)
      .pipe(catchError(this.handleError));
  }
  getVendorBunReqprint(params: any) {
    return this.http
      .post(environment.url + "/VendorBunkRequest", params)
      .pipe(catchError(this.handleError));
  }
  getVendorBunReqprint2(params: any) {
    return this.http
      .post(environment.url + "/VendorBunkRequest2", params)
      .pipe(catchError(this.handleError));
  }

  //fuel issue
  addFuelIssueData(params: any) {
    return this.http
      .post(environment.url + "/FuelIssue", params)
      .pipe(catchError(this.handleError));
  }
  getFuelIssueData(params: any) {
    return this.http
      .post(environment.url + "/FuelIssue", params)
      .pipe(catchError(this.handleError));
  }
  editFuelIssue(params: any) {
    return this.http
      .post(environment.url + "/FuelIssue", params)
      .pipe(catchError(this.handleError));
  }
  deleteFuelIssueFile(params: any) {
    return this.http
      .post(environment.url + "/FuelIssue", params)
      .pipe(catchError(this.handleError));
  }
  getFuelRequestData(params: any) {
    return this.http
      .post(environment.url + "/FuelIssueRequest", params)
      .pipe(catchError(this.handleError));
  }
  getFuelRequestNumber(params: any) {
    return this.http
      .post(environment.url + "/FuelIssueRequest", params)
      .pipe(catchError(this.handleError));
  }
  //fuel receipt
  getconfrimationnodata(params: any) {
    return this.http
      .post(environment.url + "/FuelReceiptNote", params)
      .pipe(catchError(this.handleError));
  }
  getconfrimationnodata2(params: any) {
    return this.http
      .post(environment.url + "/FuelReceiptNote2", params)
      .pipe(catchError(this.handleError));
  }
  getQuantityData(params: any) {
    return this.http
      .post(environment.url + "/BunkConfirmation", params)
      .pipe(catchError(this.handleError));
  }
  getQuantityData2(params: any) {
    return this.http
      .post(environment.url + "/BunkConfirmation2", params)
      .pipe(catchError(this.handleError));
  }
  //Bunk Confirmation2
  addBunkRequestData2(params: any) {
    return this.http
      .post(environment.url + "/BunkConfirmation2", params)
      .pipe(catchError(this.handleError));
  }
  getBunkConfirmData2(params: any) {
    return this.http
      .post(environment.url + "/BunkConfirmation2", params)
      .pipe(catchError(this.handleError));
  }
  addBunkConfirm2(params: any) {
    return this.http
      .post(environment.url + "/BunkConfirmation2", params)
      .pipe(catchError(this.handleError));
  }
  updateBunkConfirmation2(params: any) {
    return this.http
      .post(environment.url + "/BunkConfirmation2", params)
      .pipe(catchError(this.handleError));
  }
  deleteBunkConfirmFile2(params: any) {
    return this.http
      .post(environment.url + "/BunkConfirmation2", params)
      .pipe(catchError(this.handleError));
  }
  //fuel issue request
  addFuelIssueReq2(params: any) {
    return this.http
      .post(environment.url + "/FuelIssueRequest2", params)
      .pipe(catchError(this.handleError));
  }
  getFuelIssueReqData2(params: any) {
    return this.http
      .post(environment.url + "/FuelIssueRequest2", params)
      .pipe(catchError(this.handleError));
  }
  updateFuelIssueRequest2(params: any) {
    return this.http
      .post(environment.url + "/FuelIssueRequest2", params)
      .pipe(catchError(this.handleError));
  }
  deleteFuelIssueReqFile2(params: any) {
    return this.http
      .post(environment.url + "/FuelIssueRequest2", params)
      .pipe(catchError(this.handleError));
  }
  //fuel issue2
  addFuelIssueData2(params: any) {
    return this.http
      .post(environment.url + "/FuelIssue2", params)
      .pipe(catchError(this.handleError));
  }
  getFuelIssueData2(params: any) {
    return this.http
      .post(environment.url + "/FuelIssue2", params)
      .pipe(catchError(this.handleError));
  }
  editFuelIssue2(params: any) {
    return this.http
      .post(environment.url + "/FuelIssue2", params)
      .pipe(catchError(this.handleError));
  }
  deleteFuelIssueFile2(params: any) {
    return this.http
      .post(environment.url + "/FuelIssue2", params)
      .pipe(catchError(this.handleError));
  }
  getFuelRequestData2(params: any) {
    return this.http
      .post(environment.url + "/FuelIssueRequest2", params)
      .pipe(catchError(this.handleError));
  }
  getFuelRequestNumber2(params: any) {
    return this.http
      .post(environment.url + "/FuelIssueRequest2", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardNRGP2Dropdown(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardNRGP2", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardRGP2dropdowndata(params: any) {
    return this.http
      .post(environment.url + "/GateOutwardRGP2", params)
      .pipe(catchError(this.handleError));
  }
  getNRGP2data(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassNRGP2", params)
      .pipe(catchError(this.handleError));
  }
  // gss reports2
  getgateinwarddata2(params: any) {
    return this.http
      .post(environment.url + "/ReportsGateInward2", params)
      .pipe(catchError(this.handleError));
  }
  getgateoutwarddata2(params: any) {
    return this.http
      .post(environment.url + "/ReportsGateOutwardGI2", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardNRGP2(params: any) {
    return this.http
      .post(environment.url + "/ReportGateOutwardNRGP2", params)
      .pipe(catchError(this.handleError));
  }
  getGateOutwardRGP2(params: any) {
    return this.http
      .post(environment.url + "/ReportGateOutwardRGP2", params)
      .pipe(catchError(this.handleError));
  }
  editgoodsissue2(params: any) {
    return this.http
      .post(environment.url + "/GoodsIssue2", params)
      .pipe(catchError(this.handleError));
  }
  editgoodissuerequest2(params: any) {
    return this.http
      .post(environment.url + "/MaterialRequest2", params)
      .pipe(catchError(this.handleError));
  }
  getSlide() {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "text/plain; charset=utf-8"
    );
    return this.http
      .get(environment.url + "/testdata", { headers, responseType: "text" })
      .pipe(catchError(this.handleError));
  }
  getContractor(params: any) {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "text/plain; charset=utf-8"
    );
    return this.http
      .post(environment.url + "/InventoryReports2", params, {
        headers,
        responseType: "text",
      })
      .pipe(catchError(this.handleError));
  }
  mmhInventoryReports2(params: any) {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "text/plain; charset=utf-8"
    );
    return this.http
      .post(environment.url + "/InventoryReports2", params, {
        headers,
        responseType: "text",
      })
      .pipe(catchError(this.handleError));
  }
  //companies data
  usercompanyData(params: any) {
    return this.http
      .post(environment.url + "/UserCompany", params)
      .pipe(catchError(this.handleError));
  }
  getDcprint(params: any) {
    return this.http
      .post(environment.url + "/DeliveryChallan", params)
      .pipe(catchError(this.handleError));
  }
  GoodsIssueRequest(params: any) {
    return this.http
      .post(environment.url + "/GoodsIssue", params)
      .pipe(catchError(this.handleError));
  }
  SignatureUpload(params: any) {
    return this.http
      .post(environment.url + "/SignatureUpload", params)
      .pipe(catchError(this.handleError));
  }
  SignLevel(params: any) {
    return this.http
      .post(environment.url + "/SignLevel", params)
      .pipe(catchError(this.handleError));
  }
  DocumentSign(params: any) {
    return this.http
      .post(environment.url + "/DocumentSign", params)
      .pipe(catchError(this.handleError));
  }
  DocumentSign2(params: any) {
    return this.http
      .post(environment.url + "/DocumentSign2", params)
      .pipe(catchError(this.handleError));
  }
  getOutGatePassRGPReports(data: any) {
    return this.http
      .post(environment.url + "/Reports_OutGatePassRGP", data)
      .pipe(catchError(this.handleError));
  }
  getPurpose(data: any) {
    return this.http
      .post(environment.url + "/OutGatePassRGP", data)
      .pipe(catchError(this.handleError));
  }

  getPurposeNRGP(data: any) {
    return this.http
      .post(environment.url + "/OutGatePassNRGP", data)
      .pipe(catchError(this.handleError));
  }
  userCompany(data: any) {
    return this.http
      .post(environment.url + "/UserCompany", data)
      .pipe(catchError(this.handleError));
  }
  GoodsInTransit(data: any) {
    return this.http
      .post(environment.url + "/GoodsInTransit", data)
      .pipe(catchError(this.handleError));
  }

  UserDashboard(data: any) {
    return this.http
      .post(environment.url + "/UserDashBoard", data)
      .pipe(catchError(this.handleError));
  }

  getRGPData(params: any) {
    return this.http
      .post(environment.url + "/OutGatePassRGP", params)
      .pipe(catchError(this.handleError));
  }
  getDCData(params: any) {
    return this.http
      .post(environment.url + "/Reports_DeliveryChallan", params)
      .pipe(catchError(this.handleError));
  }

  PurchaseOrder(params: any) {
    return this.http
      .post(environment.url + "/PurchaseOrder", params)
      .pipe(catchError(this.handleError));
  }
  WorkOrder(params: any) {
    return this.http
      .post(environment.url + "/WorkOrder", params)
      .pipe(catchError(this.handleError));
  }
  AutoDocUpdate2(params: any) {
    return this.http
      .post(environment.url + "/AutoDocUpdate2", params)
      .pipe(catchError(this.handleError));
  }
  //dms
  getDmsData(params: any) {
    return this.http
      .get(environment.dms_base_url + "/document/" + params)
      .pipe(catchError(this.handleError));
  }
  getDmsKeyCloak(params: any) {
    return this.http
      .get(environment.dms_base_url + "/keycloak/keycloakUsers", { params })
      .pipe(catchError(this.handleError));
  }
  getApproversUsers(params:any){
    return this.http.get(environment.sso_url + "/geps/audit/auditing/keycloakUsers" ,{params})
  }

  createDocVerfiers(params: any) {
    return this.http
      .post(
        environment.dms_base_url + "/document/createDocumentVerifiers",
        params
      )
      .pipe(catchError(this.handleError));
  }
  getRootFolder(params: any) {
    return this.http
      .get(environment.dms_base_url + "/folder/" + params)
      .pipe(catchError(this.handleError));
  }
  dmsCreateFolder(params: any) {
    return this.http
      .post(environment.dms_base_url + "/folder/createFolder", params)
      .pipe(catchError(this.handleError));
  }
  dmsgetFolderandFiles(params: any) {
    return this.http
      .get(environment.dms_base_url + "/folder/" + params)
      .pipe(catchError(this.handleError));
  }
  dmsCreateFile(params: any) {
    return this.http
      .post(environment.dms_base_url + "/file", params)
      .pipe(catchError(this.handleError));
  }

  getFileInfo(params: any) {
    return this.http
      .get(environment.dms_base_url + "/vendor/getFileInfo", { params })
      .pipe(catchError(this.handleError));
  }
  getFileVersionsData(params: any){
    return this.http
      .get(environment.dms_base_url + "/files/getFileVersionsData", { params })
      .pipe(catchError(this.handleError));
  }
  getDrawingCycleInfo(params: any) {
    return this.http
      .get(
        environment.dms_base_url + "/vendor/getDrawingCycleInfo/" + params
      )
      .pipe(catchError(this.handleError));
  }
  documentRevision(params: any) {
    return this.http
      .post(environment.dms_base_url + "/drawingCycles", params)
      .pipe(catchError(this.handleError));
  }
  UpdateDoc(params: any) {
    return this.http
      .post(environment.dms_base_url + "/vendor/fileupload", params)
      .pipe(catchError(this.handleError));
  }
  UploadsubDoc(params: any) {
    return this.http
      .post(environment.dms_base_url + "/vendor/uploadMany", params)
      .pipe(catchError(this.handleError));
  }
  sendToVerify(params: any) {
    return this.http
      .post(environment.dms_base_url + "/vendor/sendForReview", params)
      .pipe(catchError(this.handleError));
  }
  approoveFile(params: any) {
    return this.http
      .put(environment.dms_base_url + "/verifier/approve", params)
      .pipe(catchError(this.handleError));
  }
  uploadApprovedDocument(params: any) {
    return this.http
      .post(
        environment.dms_base_url + "/verifier/uploadApprovedDocument",
        params
      )
      .pipe(catchError(this.handleError));
  }
  reject(params: any) {
    return this.http
      .put(environment.dms_base_url + "/verifier/reject", params)
      .pipe(catchError(this.handleError));
  }
  getFileVersion(params: any) {
    return this.http
      .get(environment.dms_base_url + "/vendor/getFileVersions/" + params)
      .pipe(catchError(this.handleError));
  }
  sendContractor(params: any) {
    return this.http
      .put(environment.dms_base_url + "/vendor/sendToContractor", params)
      .pipe(catchError(this.handleError));
  }
  fileDownload(params: any) {
    return this.http
      .post(environment.dms_base_url + "/vendor/fileDownload/", params)
      .pipe(catchError(this.handleError));
  }
  deleteFileVersion(params: any) {
    return this.http
      .put(environment.dms_base_url + "/vendor/deleteFile", params)
      .pipe(catchError(this.handleError));
  }
  deleteSupportingFiles(params: any) {
    return this.http
      .delete(
        environment.dms_base_url + "/vendor/uploadFileDelete/" + params
      )
      .pipe(catchError(this.handleError));
  }
  updateDocVerfiers(params: any) {
    return this.http
      .put(
        environment.dms_base_url + "/document/UpdateDocVerifiers",
        params
      )
      .pipe(catchError(this.handleError));
  }
  getFileList(params: any) {
    return this.http
      .get(environment.dms_base_url + "/files/" + params)
      .pipe(catchError(this.handleError));
  }
  dmsFileUpload(params: any) {
    return this.http
      .post(environment.url + "/DmsFileUpload", params)
      .pipe(catchError(this.handleError));
  }
  getFileComments(params: any) {
    return this.http
      .get(environment.dms_base_url + "/comments/getFileComments/" + params)
      .pipe(catchError(this.handleError));
  }
  addComment(params: any) {
    return this.http
      .post(environment.dms_base_url + "/comments/addComment/", params)
      .pipe(catchError(this.handleError));
  }
  storageLocation(params: any) {
    return this.http
      .post(environment.url + "/StorageLocation", params)
      .pipe(catchError(this.handleError));
  }
  // Project Dashboard
  getProjectDashboardData(params: any) {
    return this.http
      .get(environment.pdashboard_url + "/apiv2/auth/dashboard", {
        params: params,
      })
      .pipe(catchError(this.handleError));
  }

  getWBSDropdownData(params: any) {
    return this.http
      .get(environment.pdashboard_url + "/apiv2/auth/dropdown", {
        params: params,
      })
      .pipe(catchError(this.handleError));
  }
  getScurveData(params: any) {
    return this.http
      .get(environment.pdashboard_url + "/apiv2/auth/s-curve", {
        params: params,
      })
      .pipe(catchError(this.handleError))
      .toPromise();
  }
  getscurveData(params: any) {
    return this.http
      .get(environment.pdashboard_url + "/apiv2/auth/s-curve", {
        params: params,
      })
      .pipe(catchError(this.handleError));
  }
  // stores Dashboard
  getStoresDashboardData(data: any) {
    return this.http
      .post(environment.url + "/StoreDashboard", data)
      .pipe(catchError(this.handleError));
  }
  //transaction id
  getTransactionId() {
    return this.http
      .get(environment.url + "/GetTxnId")
      .pipe(catchError(this.handleError));
  }
  //level of approvals
  LevelOfApproval(data: any) {
    return this.http
      .post(environment.url + "/LevelsOfApproval", data)
      .pipe(catchError(this.handleError));
  }
  //delete
  GetPdfPageInfo(data: any) {
    return this.http
      .post(environment.url + "/GetPdfPageInfo", data)
      .pipe(catchError(this.handleError));
  }
  SetPdfPageInfo(data: any) {
    return this.http
      .post(environment.url + "/SetPdfPageInfo", data)
      .pipe(catchError(this.handleError));
  }

  ssoLogin(headers: any) {
    return this.http
      .post(environment.sso_api + "/login", "", { headers })
      .pipe(catchError(this.handleError));
    // return this.http
    // .post( "http://10.80.17.44:3000/ssov2/login", "", { headers })
    // .pipe(catchError(this.handleError));
  }
//project master
projectmaster(params: any) {
  return this.http
    .post(environment.url + "/ProjectMaster", params)
    .pipe(catchError(this.handleError));
}
//vertical master
VerticalMaster(params:any) {
  return this.http
  .post(environment.url +"/VerticalMaster", params )
  .pipe(catchError(this.handleError));
}
//Cluster master
ClusterMaster(params:any) {
  return this.http
  .post(environment.url +"/ClusterMaster", params )
  .pipe(catchError(this.handleError));
}
//Business Unit
BusinessUnit(params:any) {
  return this.http
  .post(environment.url +"/BusinessUnitMaster", params )
  .pipe(catchError(this.handleError));
}
//cost centers
getCostCenter(params:any) {
  return this.http
  .get(environment.rmc_base_url +"/costCenter/getCostCenter",{ params })
  .pipe(catchError(this.handleError));
}

//plant master
plantmaster(params: any) {
  return this.http
    .post(environment.rmc_base_url + "/plant/createPlant", params)
    .pipe(catchError(this.handleError));
}
getplantMaster(params:any){
  return this.http
  .get(environment.rmc_base_url +"/plant/getPlant",{ params })
  .pipe(catchError(this.handleError));
}
searchplantMaster(params:any){
  return this.http
  .get(environment.rmc_base_url +"/plant/getPlant/" +params )
  .pipe(catchError(this.handleError));
}
updatePlant(params:any){
  return this.http
    .post(environment.rmc_base_url + "/plant/updatePlant", params)
    .pipe(catchError(this.handleError));
}
deletePlant(params: any) {
  return this.http
    .delete(environment.rmc_base_url + "/plant/deletePlant/" + params)
    .pipe(catchError(this.handleError));
}
//cost center
createCostCenter(params:any){
  return this.http
  .post(environment.rmc_base_url +"/costCenter/createCostCenter", params )
  .pipe(catchError(this.handleError));
}
updatecostCentert(params:any){
  return this.http
    .put(environment.rmc_base_url + "/costCenter/updateCostCenter", params)
    .pipe(catchError(this.handleError));
}
deletecostCenter(params: any) {
  return this.http
    .delete(environment.rmc_base_url + "/costCenter/deleteCostCenter/" + params)
    .pipe(catchError(this.handleError));
}
//equipment master
getEquipmentData(params:any) {
  return this.http
  .get(environment.rmc_base_url +"/equipment/getEquipments",{ params })
  .pipe(catchError(this.handleError));
}
getEquipmentsData(params:any) {
  return this.http
  .get(environment.rmc_base_url +"/plant/getEquipmentDetails",{ params })
  .pipe(catchError(this.handleError));
}
addEquipment(params: any) {
  return this.http
    .post(environment.rmc_base_url + "/equipment/createEquipment", params)
    .pipe(catchError(this.handleError));
}
updateEquipment(params: any) {
  return this.http
    .post(environment.rmc_base_url + "/equipment/updateEquipment", params)
    .pipe(catchError(this.handleError));
}
deleteEquipment(params: any) {
  return this.http
    .delete(environment.rmc_base_url + "/equipment/deleteEquipment/" + params)
    .pipe(catchError(this.handleError));
}
// Design Mix
getDesignMixData(params:any) {
  return this.http
  .get(environment.rmc_base_url +"/design/getDesignMix", { params })
  .pipe(catchError(this.handleError));
}
createDesignMixData(params: any) {
  return this.http
    .post(environment.rmc_base_url + "/design/createDesignMix", params)
    .pipe(catchError(this.handleError));
}
updateDesignMixData(params: any) {
  return this.http
    .post(environment.rmc_base_url + "/design/updateDesignMix", params)
    .pipe(catchError(this.handleError));
}
deleteDesignMixData(params: any) {
  return this.http
    .delete(environment.rmc_base_url + "/design/deleteDesignMix/" + params)
    .pipe(catchError(this.handleError));
}
//goods receipt
rmcgoodsreceiptcreate(params: any) {
  return this.http
    .post(environment.rmc_base_url + "/goods/createGoods", params)
    .pipe(catchError(this.handleError));
}
rmcgoodsreceiptget(params:any){
  return this.http
  .get(environment.rmc_base_url +"/goods/getGoodsReceipt", { params })
  .pipe(catchError(this.handleError));
}
rmcgoodsreceiptdelete(params: any) {
  return this.http
    .delete(environment.rmc_base_url + "/goods/deleteGoodsReceipt", { params })
    .pipe(catchError(this.handleError));
}
rmgoodsreceiptset(params:any){
  return this.http
  .get(environment.rmc_base_url +"/goodsIssues/remainingSivQuantity", { params })
  .pipe(catchError(this.handleError));
}
//goods issue
rmcgoodsissuecreate(params: any){
  return this.http
  .post(environment.rmc_base_url + "/goodsIssues/createGoodsIssue", params)
  .pipe(catchError(this.handleError));
  
}
getrmcgoodsissue(params:any){
  return this.http
  .get(environment.rmc_base_url +"/goodsIssues/getGoodsIssues", { params })
  .pipe(catchError(this.handleError));
}
rmcgoodsissuedelete(params: any) {
  return this.http
    .delete(environment.rmc_base_url + "/goodsIssues/deleteGoodsIssues", { params })
    .pipe(catchError(this.handleError));
}

//rmc issue
rmcissuecreate(params: any){
  return this.http
  .post(environment.rmc_base_url + "/rmcSupply/createRmcSupply", params)
  .pipe(catchError(this.handleError));
  
}
getRmcSupplyInfo(params: any){
  return this.http
  .get(environment.rmc_base_url +"/rmcSupply/getRmcSupplyInfo", { params })
  .pipe(catchError(this.handleError));
}
rmcissueupdate(params: any){
  return this.http
  .post(environment.rmc_base_url + "/rmcSupply/updateRmcSupply", params)
  .pipe(catchError(this.handleError));
  
}
getrmcissue(params:any){
  return this.http
  .get(environment.rmc_base_url +"/rmcSupply/getRmcSupply", { params })
  .pipe(catchError(this.handleError));
}
getRmcSupplyReports(params: any){
  return this.http
  .post(environment.rmc_base_url +"/rmcSupply/dashBoardRmcSupply",  params )
  .pipe(catchError(this.handleError));
}
rmcissuedelete(params: any) {
  return this.http
    .delete(environment.rmc_base_url + "/rmcSupply/deleteRmcSupply", { params })
    .pipe(catchError(this.handleError));
}
//goods return
rmcgoodsreturncreate(params: any){
  return this.http
  .post(environment.rmc_base_url + "/goodsReturn/createGoodsReturn", params)
  .pipe(catchError(this.handleError));
  
}
getrmcgoodsreturn(params:any){
  return this.http
  .get(environment.rmc_base_url +"/goodsReturn/getGoodsReturn", { params })
  .pipe(catchError(this.handleError));
}
rmcgoodsreturndelete(params: any) {
  return this.http
    .delete(environment.rmc_base_url + "/goodsReturn/deleteGoodsReturn", { params })
    .pipe(catchError(this.handleError));
}
//goods issue dasboards
rmcgoodsissueReports(params: any){
  return this.http
  .post(environment.rmc_base_url + "/goodsIssues/dashBoardIssues", params)
  .pipe(catchError(this.handleError));
  
}
//stock reports
stackReports(params: any){
  return this.http
  .post(environment.rmc_base_url + "/reports/stackReports", params)
  .pipe(catchError(this.handleError));
}
// ConsolidatedStatement
ConsolidatedStatement(params: any){
  return this.http
  .post(environment.rmc_base_url + "/reports/ConsolidatedStatement", params)
  .pipe(catchError(this.handleError));
}
//raw material calculation
rawMaterialCalculation(params: any){
  return this.http
  .post(environment.rmc_base_url + "/reports/rawMaterialCalculation", params)
  .pipe(catchError(this.handleError));
}
//rmc issue dashboards
rmcissueReports(params: any){
  return this.http
  .post(environment.rmc_base_url + "/rmcIssue/dashBoardRmcIssue", params)
  .pipe(catchError(this.handleError));  
}
//goods receipts dashboards
rmcgoodsreceiptsReports(params: any){
  return this.http
  .post(environment.rmc_base_url + "/goods/dashBoardReceipts", params)
  .pipe(catchError(this.handleError));
  
}

//goods return
rmcgoodsreturnReports(params: any){
  return this.http
  .post(environment.rmc_base_url + "/goodsReturn/dashBoardReturn", params)
  .pipe(catchError(this.handleError));
  
}
//sale order supply
saleordersupply(params: any) {
  return this.http
    .post(environment.url + "/SaleOrderSupplyMaterials", params)
    .pipe(catchError(this.handleError));
}

//purchase boq
savexlpoboq(params: any) {
  return this.http
    .post(environment.url + "/SaveXlPoBOQ", params)
    .pipe(catchError(this.handleError));
}
uploadpoboq(params: any) {
  return this.http
    .post(environment.url + "/UploadPOBOQ", params)
    .pipe(catchError(this.handleError));
}
//credential menu
CredentialModule(params: any) {
  return this.http
    .post(environment.url + "/CredentialModule", params)
    .pipe(catchError(this.handleError));
}
CredentialMenuList(params: any) {
  return this.http
    .post(environment.url + "/CredentialMenu", params)
    .pipe(catchError(this.handleError));
}
// rmc view
getGoodsIssuesNumber(params: any){
  return this.http
  .get(environment.rmc_base_url +"/goodsIssues/getGoodsIssuesNumber", { params })
  .pipe(catchError(this.handleError));
}
getGoodsIssuesmaterialCodeQuantity(params: any){
  return this.http
  .get(environment.rmc_base_url +"/goodsIssues/materialCodeQuantity", { params })
  .pipe(catchError(this.handleError));
}

getGoodsReturnNumber(params: any){
  return this.http
  .get(environment.rmc_base_url +"/goodsReturn/getGoodsReturnNumber", { params })
  .pipe(catchError(this.handleError));
}
getGoodsReceiptNumber(params: any){
  return this.http
  .get(environment.rmc_base_url +"/goods/getGoodsReceiptNumber", { params })
  .pipe(catchError(this.handleError));
}
getRmcSupplyNumber(params: any){
  return this.http
  .get(environment.rmc_base_url +"/rmcSupply/getRmcSupplyNumber", { params })
  .pipe(catchError(this.handleError));
}
dateRestriction(params:any){
  return this.http
  .post(environment.url +"/DateRestriction", params )
  .pipe(catchError(this.handleError));
}
// Audit
getAuditCompanies(){
  return this.http
  .get(environment.pdashboard_url +"/geps/audit/auditing/getCompanies")
  .pipe(catchError(this.handleError));
}
getSearchedAuditCompanies(params:any){
  return this.http
  .post(environment.url +"/UserCompany",params)
  .pipe(catchError(this.handleError));
}

getAuditMasterDocuments(params:any){
  return this.http.get(environment.pdashboard_url + "/geps/audit/auditing/getAuditMasterDocuments", {params:params})
}
getAuditStockDocuments(params:any){
  return this.http.get(environment.pdashboard_url + "/geps/audit/auditing/getAuditStockDocuments",{params:params})
}
uploadAuditDocument(data:any){
  return this.http
  .post(environment.pdashboard_url + "/geps/audit/auditing/uploadAuditDocuments", data)
  .pipe(catchError(this.handleError));
}
getAuditDocuments(params:any){
  return this.http
  .get(environment.pdashboard_url +"/geps/audit/auditing/getDocuments",{params:params})
  .pipe(catchError(this.handleError));
}
getAuditDetails(data:any){
  return this.http
  .post(environment.pdashboard_url + "/geps/audit/auditing/getAuditDetails",data)
  .pipe(catchError(this.handleError))
}
getTotalAuditValues(params:any){
  return this.http.get(environment.pdashboard_url + "/geps/audit/auditing/getTotalAuditValues", {params:params})
  .pipe(catchError(this.handleError))
}
getPlantWiseData(data:any){
  return this.http.post(environment.pdashboard_url + '/geps/audit/auditing/getPlantWiseData',data)
  .pipe(catchError(this.handleError))
}
getScannedData(data:any){
  return this.http
  .post(environment.pdashboard_url + "/geps/audit/auditing/getScannedData", data)
  .pipe(catchError(this.handleError));
}
saveAuditItem(data:any){
  return this.http
  .post(environment.pdashboard_url + "/geps/audit/auditing/auditProduct", data)
  .pipe(catchError(this.handleError));
}
auditComplete(data:any){
  return this.http
  .post(environment.pdashboard_url + "/geps/audit/auditing/auditComplete ", data)
  .pipe(catchError(this.handleError));
}
addApprovers(data:any){
  return this.http
  .post(environment.pdashboard_url + "/geps/audit/auditing/addApprovers  ", data)
  .pipe(catchError(this.handleError));
}
updateApprovedby(data:any){
  return this.http
  .post(environment.pdashboard_url + "/geps/audit/auditing/updateApprovedBy   ", data)
  .pipe(catchError(this.handleError));
}
//planning request
addAuthorizationChain(params: any){
  return this.http
  .post(environment.rmc_base_url + "/authorizationChain/addAuthorizationChain", params)
  .pipe(catchError(this.handleError));
  
}
updateAuthorizationChain(params: any){
  return this.http
  .put(environment.rmc_base_url + "/authorizationChain/updateAuthorizationChain", params)
  .pipe(catchError(this.handleError));
  
}
getAuthorizationChain(params: any){
  return this.http
  .get(environment.rmc_base_url +"/authorizationChain/getAuthorizationChain", { params })
  .pipe(catchError(this.handleError));
}
getAuthorizationDescription(){
  return this.http
  .get(environment.rmc_base_url +"/authorizationChain/getAuthorizationDescription")
  .pipe(catchError(this.handleError));
}
getAuthorizationLevels(params: any){
  return this.http
  .get(environment.rmc_base_url +"/authorizationChain/getAuthorizationLevels", { params })
  .pipe(catchError(this.handleError));
}
getAuthorizationChainDetails(params: any){
  return this.http
  .get(environment.rmc_base_url +"/authorizationChain/getAuthorizationChainDetails", { params })
  .pipe(catchError(this.handleError));
}
deleteAuthorizationChain(params: any) {
  return this.http
    .delete(environment.rmc_base_url + "/authorizationChain/deleteAuthorizationChain", { params } )
    .pipe(catchError(this.handleError));
}
//pending approvals
createPendingApproval(params: any){
  return this.http
  .post(environment.rmc_base_url + "/pendingApproval/createPendingApproval", params)
  .pipe(catchError(this.handleError));
  
}
documemtType(params: any){
  return this.http
  .post(environment.rmc_base_url + "/documentDescription/documentType", params)
  .pipe(catchError(this.handleError));
  
}
purchaseRequestSendForApproval(params: any){
  return this.http
  .get(environment.rmc_base_url + "/pendingApproval/purchaseRequestSendForApproval", {params})
  .pipe(catchError(this.handleError));
  
}
getCompletedApproval(params: any){
  return this.http
  .get(environment.rmc_base_url + "/pendingApproval/getCompletedDocuments", {params})
  .pipe(catchError(this.handleError));
  
}
getPendingLevelDocuments(params: any){
  return this.http
  .get(environment.rmc_base_url +"/pendingApproval/getPendingLevelDocuments", { params })
  .pipe(catchError(this.handleError));
}
getPendingApproval(params: any){
  return this.http
  .get(environment.rmc_base_url +"/pendingApproval/getPendingApproval", { params })
  .pipe(catchError(this.handleError));
}
approveAllPendingDocuments(params: any){
  return this.http
  .post(environment.rmc_base_url +"/pendingApproval/ApproveAllPendingDocuments",  params )
  .pipe(catchError(this.handleError));
}
//pending approval
getDocumentNumber(params: any){
  return this.http
  .get(environment.rmc_base_url +"/pendingApproval/getDocumentNumber", { params })
  .pipe(catchError(this.handleError));
}


//logistics
getLogisticsDashBoardSummary(params:any){
  return this.http.get(environment.logistics_url + '/dashBoard/getGraphDashBoard',{
    params:params
  }).pipe(catchError(this.handleError));
}
getLogisticsKeyCloak(params: any) {
  return this.http
    .get(environment.logistics_url + "/keycloak/keycloakUsers", { params })
    .pipe(catchError(this.handleError));
}

getTeamRoaster(params:any) {
  return this.http.get(environment.logistics_url + '/team/getTeam',{params:params}).pipe(catchError(this.handleError));
}

getFilteredTeamRoaster(params:any) {
  return this.http.get(environment.logistics_url + '/team/getTeam',{params:params}).pipe(catchError(this.handleError));
}


createTeamRoaster(obj: any) {
  return this.http.post(environment.logistics_url + '/team/createTeam', obj).pipe(catchError(this.handleError));
}

updateTeamRoaster(obj: any) {
  return this.http.post(
    environment.logistics_url + '/team/updateTeam',
    obj
  ).pipe(catchError(this.handleError));
}

deleteTeam(params:any){
  return this.http
  .delete(environment.logistics_url + "/team/deleteTeam/"+params).pipe(catchError(this.handleError))
} 


getCriticalRequirements(params:any) {
  return this.http.get(environment.logistics_url + '/criticalRequirements/getIssues' ,  {
    params: params,
  }).pipe(catchError(this.handleError));
}

createCriticalIssue(obj: any) {
  return this.http.post(environment.logistics_url + '/criticalRequirements/createIssue', obj).pipe(catchError(this.handleError));
}

updateCriticalIssue(obj: any) {
  return this.http.post(
    environment.logistics_url + '/criticalRequirements/updateIssue',
    obj
  ).pipe(catchError(this.handleError));
}

getLogisticsProjectDetails(params:any) {

  return this.http.get(environment.logistics_url + '/project/getProject',    {
    params: params,
  }).pipe(catchError(this.handleError));

}

getExpensesReport(obj:any) {

  return this.http.post(environment.logistics_url + '/expenses/getExpenses', obj).pipe(catchError(this.handleError));

}
createLogisticExpenses(obj: any) {
  return this.http.post(environment.logistics_url + '/expenses/createExpenses', obj)
  .pipe(catchError(this.handleError));

}
getLogisticsDashBoard(obj: any) {
  return this.http.post(environment.logistics_url + '/dashBoard/getDashBoard', obj)
  .pipe(catchError(this.handleError));
}
getExcelData(params:any){
  return this.http.get(environment.logistics_url + '/dashBoard/getExcel', {params: params,})
  .pipe(catchError(this.handleError));
}
updateExpenses(obj: any) {
  return this.http.post(
    environment.logistics_url + '/expenses/updateExenses',
    obj
  ).pipe(catchError(this.handleError));
}
getProjectRequirement(params:any) {
  return this.http.get(environment.logistics_url + '/projectRequirement/getProjectRequirement' ,    {
    params: params,
  }).pipe(catchError(this.handleError));
}

createProjectRequirement(obj: any) {
  return this.http.post(environment.logistics_url + '/projectRequirement/createProjectRequirement', obj).pipe(catchError(this.handleError));
}

updateProjectRequirement(obj: any) {
  return this.http.post(
    environment.logistics_url + '/projectRequirement/updateProjectRequirement',
    obj
  ).pipe(catchError(this.handleError));
}

deleteProjectRequirement(params:any){
  return this.http
  .delete(environment.logistics_url + "/projectRequirement/deleteProjectRequirement/"+params).pipe(catchError(this.handleError))

}

createProjectDetails(obj: any) {
  return this.http.post(environment.logistics_url + '/project/createProject', obj).pipe(catchError(this.handleError));
}
fileupload(obj: any) {
  return this.http.post(environment.logistics_url + '/project/fileupload', obj).pipe(catchError(this.handleError));
}
viewFile(params: any) {
  return this.http.get(environment.logistics_url + '/project/viewFile', {params:params} ).pipe(catchError(this.handleError));
}
updateProjectDetails(obj: any) {
  return this.http.put(environment.logistics_url + '/project/updateProject', obj)
  .pipe(catchError(this.handleError));
}
getLogisticPlanning(params:any) {
  return this.http.get(environment.logistics_url + '/shipmentLot/getshipmentLot' ,    {
    params: params,
  }).pipe(catchError(this.handleError));
}
filteredLogisticsPlanning(params:any){
  return this.http.get(environment.logistics_url + '/shipmentLot/getshipmentLot' ,  {params:params}).pipe(catchError(this.handleError))
}
getExpensesOfComponent(params:any){
  return this.http.get(environment.logistics_url + '/expenses/getExpensesOfComponent', {params:params}).pipe(catchError(this.handleError))
}
getComponent(params:any){
  return this.http.get(environment.logistics_url + '/projectComponent/getComponents', {params:params}).pipe(catchError(this.handleError))
}
getLots(params:any){
  return this.http.get(environment.logistics_url + '/shipmentLot/getLot' , {params:params}).pipe(catchError(this.handleError))
}
getItems(params:any){
  return this.http.get(environment.logistics_url + '/shipmentLotItem/getItems' , {params:params}).pipe(catchError(this.handleError))
}
getCategory() {
  return this.http.get(environment.logistics_url + '/category/getCategory').pipe(catchError(this.handleError));
}
getLogisticPlanningItems(params:any) {
  return this.http.get(environment.logistics_url + '/shipmentLotItem/getshipmentitem' ,  {
    params: params,
  }).pipe(catchError(this.handleError));
}
getLogisticPlanningComponents(params:any) {
  return this.http.get(environment.logistics_url + '/projectComponent/getProjectComponents' ,  {
    params: params,
  }).pipe(catchError(this.handleError));
}

deleteComponent(id:any){
  return this.http
    .delete(environment.logistics_url + "/projectComponent/"+ id)
    .pipe(catchError(this.handleError));
}
createLogisticPlanningComponent(obj: any) {
  return this.http.post(environment.logistics_url + '/projectComponent/createProjectComponent', obj).pipe(catchError(this.handleError));
}

createPoNumber(obj:any){
  return this.http.post(environment.logistics_url + '/purchaseOrders/createPurchaseOrders', obj).pipe(catchError(this.handleError))
}

getPoNumber(params:any){
  return this.http.get(environment.logistics_url + '/purchaseOrders/getpurchaseOrders',{ params }).pipe(catchError(this.handleError))
}

updatePoNumber(obj:any){
  return this.http.post(environment.logistics_url + "/purchaseOrders/updatepurchaseOrders",obj).pipe(catchError(this.handleError))
}

deletePoNumber(id:any){
return this.http.delete( environment.logistics_url + '/purchaseOrders/'+ id).pipe(catchError(this.handleError))
}
deleteLogisticLot(params: any) {
  return this.http
    .delete(environment.logistics_url + "/shipmentLot/deleteLot/" + params)
    .pipe(catchError(this.handleError));
}
deleteLogisticItem(params: any) {
  return this.http
    .delete(environment.logistics_url + "/shipmentLotItem/deleteItem/" + params)
    .pipe(catchError(this.handleError));
}
deleteProjectExpenses(params:any){
  return this.http
  .delete(environment.logistics_url + "/expenses/deleteExpenses/"+params)
  .pipe(catchError(this.handleError));
}
createLogisticLot(obj: any) {
  return this.http.post(environment.logistics_url + '/shipmentLot/createLot', obj).pipe(catchError(this.handleError));
}
UpdateLogisticLot(obj: any) {
  return this.http.post(environment.logistics_url + '/shipmentLot/updateShipmentLot', obj).pipe(catchError(this.handleError));
}
createLogisticLotItem(obj: any) {
  return this.http.post(environment.logistics_url + '/shipmentLotItem/createItem', obj)
  .pipe(catchError(this.handleError));
}
UpdateLogisticLotItem(obj: any) {
  return this.http.post(environment.logistics_url + '/shipmentLotItem/updateShipmentLotItem', obj)
  .pipe(catchError(this.handleError));
}

updateLotStages(obj: any) {
  return this.http.post(
    environment.logistics_url + '/shipmentLot/updateShipmentLot',
    obj
  ).pipe(catchError(this.handleError));
}
updateItemStages(obj: any) {
  return this.http.post(
    environment.logistics_url + '/shipmentLotItem/updateShipmentLotItem', 
    obj
  ).pipe(catchError(this.handleError));
}
deleteIssue(obj:any){
  return this.http.delete(
    environment.logistics_url + '/criticalRequirements/deleteIssue/'+obj,
    obj
  ).pipe(catchError(this.handleError));
}
progressFileUpload(obj:any){
  return this.http.post(
    environment.logistics_url + '/shipmentLot/fileupload',obj
  ).pipe(catchError(this.handleError))
}
updateShipmentLotItemAttachments(obj: any) {

  return this.http.post(
    environment.logistics_url + '/shipmentLotItem/updateShipmentLotItemAttachments', 
    obj 
  ).pipe(catchError(this.handleError));
}
createEsdndata(params: any){
  return this.http
  .post(environment.rmc_base_url + "/esdn/createESdn", params)
  .pipe(catchError(this.handleError));
  
}
//document
nfapdfEditor(params: any){
  return this.http
  .post(environment.rmc_base_url + "/nfa/pdfeditor", params)
  .pipe(catchError(this.handleError));
  
}
PdfPageInsert(params: any){
  return this.http
  .post(environment.url + "/PdfPageInsert", params)
  .pipe(catchError(this.handleError));
  
}
PdfPageRemove(params: any){
  return this.http
  .post(environment.url + "/PdfPageRemove", params)
  .pipe(catchError(this.handleError));
  
}
//storage locations
createStorageLocation(params: any){
  return this.http
  .post(environment.rmc_base_url + "/storageLocation/createStorageLocation", params)
  .pipe(catchError(this.handleError));
}
getStorageLocation(params:any){
  return this.http
  .get(environment.rmc_base_url +"/storageLocation/getStorageLocation", { params })
  .pipe(catchError(this.handleError));
}
updateStorageLocation(params: any){
  return this.http
  .put(environment.rmc_base_url + "/storageLocation/updateStorageLocation", params)
  .pipe(catchError(this.handleError));
  
}
deleteStorageLocation(params: any) {
  return this.http
    .delete(environment.rmc_base_url + "/storageLocation/deleteStorageLocation",{ params } )
    .pipe(catchError(this.handleError));
}

deleteEsdndata(obj: any) {
  return this.http
    .post(environment.rmc_base_url + "/esdn/createESdn" , obj )
    .pipe(catchError(this.handleError));
}
editEsdndata(obj: any) {
  return this.http
    .post(environment.rmc_base_url + "/esdn/createESdn" , obj)
    .pipe(catchError(this.handleError));
}
getEsdndata(obj: any) {
  return this.http
    .post(environment.rmc_base_url + "/esdn/createESdn" , obj)
    .pipe(catchError(this.handleError));
}

getSearchEsdn(params: any) {
  return this.http
    .post(environment.rmc_base_url + "/esdn/createESdn", params)
    .pipe(catchError(this.handleError));
}
getDmrNumber(params:any){
  return this.http
      .get(environment.rmc_base_url + "/esdn/getDistinctDmrNumber",{params})
      .pipe(catchError(this.handleError));
}
getDmrNumberMaterials(params:any){
  return this.http
      .get(environment.rmc_base_url + "/esdn/getDmrNumberMaterials",{params})
      .pipe(catchError(this.handleError));
}

createEquipmentdetails(body:any){
  let result = this.http
  .post(environment.rmc_base_url +"/machinaries/createEquipmentDetails", body)
  .pipe(catchError(this.handleError));
  return result
}

getEquipmentdetails(params:any){
  return this.http
  .get(environment.rmc_base_url+"/machinaries/getEquipmentDetails",{params})
  .pipe(catchError(this.handleError));
}
getEquipmentdetails1(params:any){
  return this.http
  .get(environment.rmc_base_url+"/machinaries/getEquipmentData",{params})
  .pipe(catchError(this.handleError));
}
editequipmentdetails(body:any){
  return this.http
  .post(environment.rmc_base_url +"/machinaries/updateEquipmentDetails",body)
  .pipe(catchError(this.handleError));
}
getDailymaterialreceipt(params: any) {
  return this.http
    .post(environment.url + "/DailyMaterialReceipt", params)
    .pipe(catchError(this.handleError));
}
getDmrbulkdata(params: any) {
  let result=this.http
    .post(environment.url + "/DailyMaterialReceipt", params)
    .pipe(catchError(this.handleError));
    return result
}
machineryreports(obj:any){
  let result=this.http
  .post(environment.rmc_base_url + "/machinaries/generateMachinaryReport",obj)
  .pipe(catchError(this.handleError));
  return result
}



// deleteequipmentdetails(params: any) {
//   return this.http
//     .delete(environment.base_url + "/geps/rmc/machinaries/"+params
      
//      )
//     .pipe(catchError(this.handleError));
// }
deleteequipmentdetails(params: any) {
  return this.http
    .delete(environment.rmc_base_url + "/machinaries/deleteEquipmentDetails",{params})
    .pipe(catchError(this.handleError));
}

getgamdashboarddata() {
  return this.http
    .get(environment.rmc_base_url + "/rgp/rgpData")
    .pipe(catchError(this.handleError));
}

generateStoresPdf(params: any)  {
  return this.http.get(environment.rmc_base_url + "/storesreports/generateStoresPdfMake", { params }).pipe(
    catchError(this.handleError)
  );
}
generateStoresPdf2(params: any)  {
  return this.http.get(environment.rmc_base_url + "/storesreports/generatePDFMakeFormat2", { params }).pipe(
    catchError(this.handleError)
  );
}
CreateReusableInward(params: any){
  return this.http
  .post(environment.rmc_base_url +"/inward/reusableInwards",  params )
  .pipe(catchError(this.handleError));
}
getReusableInward(params: any){
  return this.http
  .post(environment.rmc_base_url +"/inward/reusableInwards",  params )
  .pipe(catchError(this.handleError));
}
deleteReusableInward(params: any){
  return this.http
  .post(environment.rmc_base_url +"/inward/reusableInwards",  params )
  .pipe(catchError(this.handleError));
}
CreateReusableOutward(params: any){
  return this.http
  .post(environment.rmc_base_url +"/outward/reusableOutwards",  params )
  .pipe(catchError(this.handleError));
}
getReusableOutward(params: any){
  return this.http
  .post(environment.rmc_base_url +"/outward/reusableOutwards",  params )
  .pipe(catchError(this.handleError));
}
deleteReusableOutward(params: any){
  return this.http
  .post(environment.rmc_base_url +"/outward/reusableOutwards",  params )
  .pipe(catchError(this.handleError));
}
CreateReusableReturn(params: any){
  return this.http
  .post(environment.rmc_base_url +"/return/reusableReturn",  params )
  .pipe(catchError(this.handleError));
}
getReusableReturn(params: any){
  return this.http
  .post(environment.rmc_base_url +"/return/reusableReturn",  params )
  .pipe(catchError(this.handleError));
}
deleteReusableReturn(params: any){
  return this.http
  .post(environment.rmc_base_url +"/return/reusableReturn",  params )
  .pipe(catchError(this.handleError));
}
getrowNumber(){
  return this.http
  .get(environment.rmc_base_url + "/outward/fetchDistinctNumber")
  .pipe(catchError(this.handleError))
}
getavailableqty(params:any){
  return this.http
  .get(environment.rmc_base_url + "/outward/fetchAvaliableQuantity",{params})
  .pipe(catchError(this.handleError))
}
getreusableStockData(params: any) {
  return this.http
    .post(environment.rmc_base_url + "/reusableReports/StockReports", params)
    .pipe(catchError(this.handleError));
}
getreusableInwardData(params: any) {
  return this.http
    .post(environment.rmc_base_url + "/reusableReports/inwardReports", params)
    .pipe(catchError(this.handleError));
}
getreusableOutwardData(params: any) {
  return this.http
    .post(environment.rmc_base_url + "/reusableReports/outwardReports", params)
    .pipe(catchError(this.handleError));
}
getreusableReturnData(params: any) {
  return this.http
    .post(environment.rmc_base_url + "/reusableReports/returnReports", params)
    .pipe(catchError(this.handleError));
}
getreusableSingleMaterialTransactionData(params: any) {
  return this.http
    .post(environment.rmc_base_url + "/reusableReports/RawMaterialData", params)
    .pipe(catchError(this.handleError));
}
getDmrData(params:any){
  return this.http
    .post(environment.rmc_base_url + "/storesreports/gamDmrDashboard", params)
    .pipe(catchError(this.handleError));
}
getAutomatedEmails(params:any){
  return this.http
    .get(environment.rmc_base_url + "/emailSchedular/getEmailSchedular",{params})
    .pipe(catchError(this.handleError));
}
CreateAutomatedEmails(params:any){
  return this.http
    .post(environment.rmc_base_url + "/emailSchedular/createEmailSchedular" , params)
    .pipe(catchError(this.handleError));
}
getReportsData(){
  return this.http
    .get(environment.rmc_base_url + "/emailSchedular/getReportsModule")
    .pipe(catchError(this.handleError));
}
UpdateAutomatedEmails(params:any){
  return this.http
    .post(environment.rmc_base_url + "/emailSchedular/updateEmailSchedular" , params)
    .pipe(catchError(this.handleError));
}
deleteAutomatedEmails(params:any){
  return this.http
    .delete(environment.rmc_base_url + "/emailSchedular/deleteEmailSchedular/"+ params)
    .pipe(catchError(this.handleError));
}
  // return this.http.get(environment.rmc_base_url + "/storesreports/generateStoresPdf", {
  //   params: params,
  //   responseType: 'blob' // Specify responseType as 'blob'
  // }).pipe(
  //   catchError(this.handleError)
  // );
// generateStoresPdf(params:any): Observable<Blob>{
//   return this.http
//   .get(environment.rmc_base_url +"/storesreports/generateStoresPdf", 
//   params: params,
//   responseType: 'blob'
//   )
//   .pipe(catchError(this.handleError));
// }
DownloadFile(params:any){
  return this.http.get(environment.logistics_url + '/shipmentLot/viewFile', {params:params} ).pipe(catchError(this.handleError));
}
DownloadItemFile(params:any){
  return this.http.get(environment.logistics_url + '/shipmentLotItem/viewFile', {params:params} ).pipe(catchError(this.handleError));
}

getScurveDashboard(params: any) {
  return this.http
    .get(environment.pdashboard_url + "/apiv2/auth/dashboard-new", {
      params: params,
    })
    .pipe(catchError(this.handleError))
    .toPromise();
}

getLogisticsDashBoardNew(obj: any) {
  return this.http.get(environment.logistics_url + '/dashBoard/logisticsDashboard', {params:obj})
  .pipe(catchError(this.handleError));
}
private folderTypeSubject = new BehaviorSubject<string>('Default Folder');
folderType$ = this.folderTypeSubject.asObservable();

setFolderType(folderType: string) {
  this.folderTypeSubject.next(folderType);
}
getDocumentTypes() {
  return this.http
    .get(environment.url + "/get_category")
    .pipe(catchError(this.handleError));
}
getDocumnetsFolders(params: any) {
  return this.http
    .get(environment.url + "/get_document_folder?", { params })
    .pipe(catchError(this.handleError));
}
getDocumnetsPdfFiles(params: any) {
  return this.http
    .get(environment.url + "/get_document_pdfs?", { params })
    .pipe(catchError(this.handleError));
}
createDocumentsFolder(params: any) {
  return this.http
    .post(environment.url + "/create_document_folder", params)
    .pipe(catchError(this.handleError));
}
deleteDocumentFolder(params: any) {
  return this.http
    .delete(environment.url + "/delete_document_folder?", { params })
    .pipe(catchError(this.handleError));
}
uploadDocumentsPdfFiles(params: any) {
  return this.http
    .post(environment.url + "/upload_document_pdfs", params)
    .pipe(catchError(this.handleError));
}
getPdfDocumnetTypes(params: any) {
  return this.http
    .get(environment.url + "/get_document_pdfs?", { params })
    .pipe(catchError(this.handleError));
}
deleteDocumnetFile(params: any) {
  return this.http
    .delete(environment.url + "/delete_document_pdfs?", { params })
    .pipe(catchError(this.handleError));
}
editDocumentFolder(obj:any) {
  return this.http
    .put(environment.url + "/rename_document_folder",obj)
    .pipe(catchError(this.handleError));
}
searchDocumentFolders(params: any) {
  return this.http
    .get(environment.url + "/search_document_pdfs?", { params })
    .pipe(catchError(this.handleError));
}
  getDrawingFolders(params: any) {
    return this.http
      .get(environment.url + "/get_drawing_folder?", { params })
      .pipe(catchError(this.handleError));
  }
  getDrawingPdfFiles(params: any) {
    return this.http
      .get(environment.url + "/get_drawing_pdfs?", { params })
      .pipe(catchError(this.handleError));
  }

  MailDocuments(params: any) {
    return this.http
      .post(environment.url + "/MailDocuments", params)
      .pipe(catchError(this.handleError));
  }
}

