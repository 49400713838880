<div class="page-breadcrumb page-header">
    <div class="row">
        <div class="col-12 align-self-center">
            <h4 class="page-title">Tax List</h4>
            <!-- <div class="d-flex align-items-center m-t-10">
                <span class="m-r-20">Start Date : {{startDate | date}} </span>
                <span>End Date : {{endDate | date}} </span>
            </div> -->
        </div>
    </div>
</div>

        <mat-card>
            <mat-card-content>
                <div class="row p-10">
<!-- <div class="col-6">
    <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
    <input type="search" class="searchfield"  placeholder="Search By Description" [(ngModel)]="searchData" (input)="searchdata()">
</div> -->
                    <div class="col float-right text-right">

                        <button mat-icon-button value="Add" matTooltip="Add" class="custom-icon-button "
                            (click)="addtax(taxmodel)">
                            <span class="material-icons">
                                add
                            </span>
                        </button>


                    </div>

                </div>

                <ng-container>

                    <div class="row p-t-10">
                        <div class="col-12 col-md-12">
                            <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                            <div class="table-responsive mat-table-wrapper">
                                <div class="example-table-container">
                                    <table mat-table [dataSource]="dataSource">


                                        <ng-container matColumnDef="sno">
                                            <th mat-header-cell *matHeaderCellDef style="width:5%">S.NO</th>
                                            <td mat-cell *matCellDef="let row;let i=index;">
                                                {{(i+1) + (masterpaginator.pageIndex * masterpaginator.pageSize)}}

                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="code">
                                            <th mat-header-cell *matHeaderCellDef style="width:13%">
                                                Code</th>
                                            <td mat-cell *matCellDef="let row" class="code">
                                                {{row.code}}

                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="description">
                                            <th mat-header-cell *matHeaderCellDef style="width:15%">
                                                Description</th>
                                            <td mat-cell *matCellDef="let row" class="code">
                                                {{row.description}}
                                            </td>
                                        </ng-container>


                                        <ng-container matColumnDef="percentage">
                                            <th mat-header-cell *matHeaderCellDef style="width:7%">Percentage
                </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.percentage }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="i_gst">
                                            <th mat-header-cell *matHeaderCellDef style="width:5%">I GST</th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.igst}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="c_gst">
                                            <th mat-header-cell *matHeaderCellDef style="width:6%">C GST
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.cgst}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="s_gst">
                                            <th mat-header-cell *matHeaderCellDef style="width:5%">S GST</th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.sgst }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="tcs">
                                            <th mat-header-cell *matHeaderCellDef style="width:5%"> TCS</th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.tcs }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="vat">
                                            <th mat-header-cell *matHeaderCellDef style="width:4%"> VAT
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.vat }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="stax">
                                            <th mat-header-cell *matHeaderCellDef style="width:4%">
                                                S TAX
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.stax}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="cst">
                                            <th mat-header-cell *matHeaderCellDef style="width:5%">
                                                CST
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.cst}}
                                            </td>
                                        </ng-container>
                                        <!-- <ng-container matColumnDef="ActiveStatus">
                                            <th mat-header-cell *matHeaderCellDef style="width:4%">
                                                Active Status
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.is_active}}
                                            </td>
                                        </ng-container> -->
                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef style="width:10%">
                                                Action
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                <!-- <button mat-icon-button
                                                    aria-label="Example icon button with a vertical three dot icon"
                                                   (click)="editdata(row,taxeditmodel)" >
                                                    <mat-icon>edit</mat-icon>
                                                </button> -->

                                                <button mat-icon-button style="color: red;" matTooltip="Delete"
                                                    aria-label="Example icon button with a vertical three dot icon" (click)="deletedata(row,deleteFileModal)"
                                                    >
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>


                                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                    </table>
                                </div>
                                <div *ngIf="taxdata==true" class="text-center">
                                    <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                        Loading...
                                    </h4>
                                    <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                                        No Records Found
                                    </h4>
                                </div>
                                    <mat-paginator [length]="totalRecords" [pageSize]="10"
                                        [pageSizeOptions]="[5,10, 50, 100, 500, 1000]" #masterpaginator
                                        (page)="onpageevent($event)">
                                    </mat-paginator>
                                    
                            </div>
                        </div>
                    </div>
                </ng-container>

            </mat-card-content>
        </mat-card>
        <ng-template #taxmodel>
            <div mat-dialog-title class="dialog-title">
                <div class="row">
                    <div class="col-11">
                        <h2>Add Tax List</h2>
                    </div>
                    <div class="col-1 text-right">
                        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon1">
                            <mat-icon class="icon1">close</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <mat-dialog-content>
                <form #myform="ngForm" (ngSubmit)="savetaxdata(myform)">
                    <div class="row">
                        <div class="col-md-2">
                            <label>I GST<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter I GST" class="inputstyles" name="igst"
                                [(ngModel)]="igst" (keypress)="keyPressNumbers($event)">
                        </div>
        
                        <!-- </div> -->
                        <div class="col-md-2">
                            <label>C GST<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter I GST" class="inputstyles" name="cgst"
                            [(ngModel)]="cgst" (keypress)="keyPressNumbers($event)">
                            
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>S GST<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter S GST" class="inputstyles" name="sgst" [(ngModel)]="sgst"
                            (keypress)="keyPressNumbers($event)">
                           
                        </div>
                        <div class="col-md-2">
                            <label>TCS<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter TCS" class="inputstyles" name="tcs" [(ngModel)]="tcs"
                            (keypress)="keyPressNumbers($event)">
                         </div>
                     </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>VAT<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text"  placeholder="Enter VAT" class="inputstyles" name="vat" [(ngModel)]="vat"
                            (keypress)="keyPressNumbers($event)"> 
                        </div>
                        <div class="col-md-2">
                            <label>S TAX<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text"  placeholder="Enter S TAX" class="inputstyles" name="stax" [(ngModel)]="stax"
                            (keypress)="keyPressNumbers($event)">
                        </div>
        
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>CST<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text"  placeholder="Enter CST" class="inputstyles" name="cst" [(ngModel)]="cst"
                            (keypress)="keyPressNumbers($event)">
                        </div>
                       </div><br>
                    <button mat-raised-button type="submit" class="savebutton" [disabled]="!igst || !cgst || !sgst || !tcs || !vat || !stax || !cst">Save</button>
                </form>
            </mat-dialog-content>
        </ng-template>
        <!-- <ng-template #taxeditmodel>
            <div mat-dialog-title class="dialog-title">
                <h2>Edit Tax List</h2>
                <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <mat-dialog-content>
                <form #myeditform="ngForm" (ngSubmit)="savetaxeditdata(myeditform)">
                    <div class="row">
                        <div class="col-md-2">
                            <label>I GST</label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="igstedit"
                                [(ngModel)]="igstedit" (keypress)="keyPressNumbers($event)">
                        </div>
        
                       
                        <div class="col-md-2">
                            <label>C GST</label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="cgstedit"
                            [(ngModel)]="cgstedit" (keypress)="keyPressNumbers($event)">
                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <label>S GST</label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="sgstedit" [(ngModel)]="sgstedit"
                            (keypress)="keyPressNumbers($event)">
                           
                        </div>
                        <div class="col-md-2">
                            <label>TCS</label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="inputstyles" name="tcsedit" [(ngModel)]="tcsedit"
                            (keypress)="keyPressNumbers($event)">
                         </div>
                     </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>VAT</label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" style="height:102%" class="inputstyles" name="vatedit" [(ngModel)]="vatedit"
                            (keypress)="keyPressNumbers($event)"> 
                        </div>
                        <div class="col-md-2">
                            <label>S TAX</label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" style="height:102%" placeholder="Enter S TAX" class="inputstyles" name="staxedit" [(ngModel)]="staxedit"
                            (keypress)="keyPressNumbers($event)">
                        </div>
        
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>CST</label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" style="height:102%" class="inputstyles" name="cstedit" [(ngModel)]="cstedit"
                            (keypress)="keyPressNumbers($event)">
                        </div>
                       </div><br>
                    <button type="submit" class="savebutton">Update</button>
                </form>
            </mat-dialog-content>
        </ng-template> -->
        <ng-template #deleteFileModal>
            <h1 mat-dialog-title>Delete Tax</h1>
            <div mat-dialog-content>Are you sure, you want to delete record?</div>
            <div mat-dialog-actions>
                <button mat-button mat-dialog-close style="background-color: rgb(239, 164, 164);">Close</button>
                <button mat-button cdkFocusInitial (click)="deleteFile()">Delete</button>
            </div>
        </ng-template>
        
    

   



