// import { Component, OnInit } from "@angular/core";
// import { Router } from "@angular/router";
// import { CustomerService } from "../services/customer.service";
// import { KeycloakService } from "keycloak-angular";
// import { HttpHeaders } from "@angular/common/http";
// import { environment } from "src/environments/environment";

// @Component({
//   selector: "app-login",
//   templateUrl: "./login.component.html",
//   styleUrls: ["./login.component.scss"],
// })
// export class LoginComponent implements OnInit {
//   [x: string]: any;

//   constructor(
//     private router: Router,
//     private customerSevr: CustomerService,
//     private keycloakService: KeycloakService
//   ) {}

//   async ngOnInit() {
//     // debugger
//     let clients: any = environment.clients;
//     let origin = window.location.hostname; //  window.origin.split('.', 1)
//     let client = "";
//     let clientObj: any = {};
//     if (origin && origin.includes(".")) {
//       let clientarr = origin.split(".", 1);
//       client = clientarr.length ? clientarr[0] : "";
//     }
//     clientObj = clients[client];

//     if (clients[client] && Object.keys(clientObj).length) {
//       let isLoggedIn = await this.keycloakService.isLoggedIn();
//       if (!isLoggedIn) {
//         this.keycloakService.login();
//         return;
//       } else {
//         let token = await this.keycloakService.getToken();

//         let headers = new HttpHeaders();
//         headers = headers.append("authorization", "Token " + token);
//         headers = headers.append("ids", "true");
//         headers = headers.append("domain", "testgeps.greenko.net");
//         headers = headers.append("realm", clientObj.realm);

//         this.customerSevr.ssoLogin(headers).subscribe((res: any) => {
//           if (res?.success === 1) {
//             localStorage.setItem("redirect_uri", res.data.app_details.app_url);
//             localStorage.setItem("authentication", btoa(JSON.stringify(res)));
//             localStorage.setItem("currentUser", JSON.stringify(res));
//             this.router.navigate(["/ui/reload"], {
//               queryParams: {
//                 response_type: btoa(JSON.stringify(res)),
//                 code: res.data.app_details.app_id,
//                 redirect_uri: clientObj.redirect_uri + "/ui/reload",
//               },
//             });
//           }
//         });
//       }
//     } else {
//       let isLoggedIn = await this.keycloakService.isLoggedIn();
//       if (!isLoggedIn) {
//         this.keycloakService.login();
//         return;
//       } else {
//         let token = await this.keycloakService.getToken();

//         let headers = new HttpHeaders();
//         headers = headers.append("authorization", "Token " + token);
//         headers = headers.append("ids", "true");
//         headers = headers.append("domain", "testgeps.greenko.net");
//         headers = headers.append("realm", "Greenko");

//         this.customerSevr.ssoLogin(headers).subscribe((res: any) => {
//           if (res?.success === 1) {
//             localStorage.setItem("redirect_uri", res.data.app_details.app_url);
//             localStorage.setItem("authentication", btoa(JSON.stringify(res)));
//             localStorage.setItem("currentUser", JSON.stringify(res));
//             this.router.navigate(["/ui/reload"], {
//               queryParams: {
//                 response_type: btoa(JSON.stringify(res)),
//                 code: res.data.app_details.app_id,
//                 redirect_uri: "http://localhost:9201/ui/reload",
//               },
//             });
//           }
//         });
//       }
//     }
//   }
// }

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CustomerService } from "../services/customer.service";
import { KeycloakService } from "keycloak-angular";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  [x: string]: any;

  constructor(
    private router: Router,
    private customerSevr: CustomerService,
    private keycloakService: KeycloakService
  ) {}

  async ngOnInit() {
    // debugger
    // let clients: any = environment.clients;
    let origin = window.location.hostname; //  window.origin.split('.', 1)
    // alert(origin);
    // let client = "";
    // let clientObj: any = {};
    // if (origin && origin.includes(".")) {
    //   let clientarr = origin.split(".", 1);
    //   client = clientarr.length ? clientarr[0] : "";
    // }
    // clientObj = clients[client];

    // if (clients[client] && Object.keys(clientObj).length) {
    let isLoggedIn = await this.keycloakService.isLoggedIn();
    if (!isLoggedIn) {
      this.keycloakService.login();
      return;
    } else {
      let token = await this.keycloakService.getToken();

      let headers = new HttpHeaders();
      // headers = headers.append("authorization", "Token " + token);
      headers = headers.append("ids", "true");
      headers = headers.append("domain", "testgeps.greenko.net");
      headers = headers.append(
        "realm",
        origin.includes(".") ? origin.split(".")[0] : ""
      );

      // this.customerSevr.ssoLogin(headers).subscribe((res: any) => {
      //   if (res?.success === 1) {
          // localStorage.setItem("redirect_uri", res.data.app_details.app_url);
          // localStorage.setItem("authentication", btoa(JSON.stringify(res)));
          // localStorage.setItem("currentUser", JSON.stringify(res));
          localStorage.setItem("authentication", token);
          this.router.navigate(["/ui/reload"], {
            queryParams: {
              // response_type: btoa(JSON.stringify(res)),
              // code: res.data.app_details.app_id,
              // redirect_uri: origin + "/ui/reload",
              response_type: token,
              // code: res.data.app_details.app_id,
              redirect_uri: origin + "/ui/reload",
            },
          });
      //   }
      // });
    }
    // } else {
    //   let isLoggedIn = await this.keycloakService.isLoggedIn();
    //   if (!isLoggedIn) {
    //     this.keycloakService.login();
    //     return;
    //   } else {
    //     let token = await this.keycloakService.getToken();

    //     let headers = new HttpHeaders();
    //     headers = headers.append("authorization", "Token " + token);
    //     headers = headers.append("ids", "true");
    //     headers = headers.append("domain", "testgeps.greenko.net");
    //     headers = headers.append("realm", "Greenko");

    //     this.customerSevr.ssoLogin(headers).subscribe((res: any) => {
    //       if (res?.success === 1) {
    //         localStorage.setItem("redirect_uri", res.data.app_details.app_url);
    //         localStorage.setItem("authentication", btoa(JSON.stringify(res)));
    //         localStorage.setItem("currentUser", JSON.stringify(res));
    //         this.router.navigate(["/ui/reload"], {
    //           queryParams: {
    //             response_type: btoa(JSON.stringify(res)),
    //             code: res.data.app_details.app_id,
    //             redirect_uri: "http://localhost:9201/ui/reload",
    //           },
    //         });
    //       }
    //     });
    //   }
    // }
  }
}
