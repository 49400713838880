<mat-card>
    <mat-card-content>
      <div class="row">
        <div class="col-3 col-md-3 mt-2 mb-3 ">
          <input type="search" class="searchfield" placeholder="Search Reference Number"
            [(ngModel)]="logsearchData" (input)="logSearchData()">
        </div>
        <div class="col-9 col-md-9 mt-2 mb-3 text-right">
          <button mat-icon-button value="Refresh" matTooltip="Refresh" (click)="getlogdata1()"
            class="custom-icon-button ml-3">
            <span class="material-icons">
              refresh
            </span>
          </button>
        </div>
        <div class="col-12 col-md-12">
          <div class="table-responsive mat-table-wrapper">
            <div class="example-table-container">
              <table mat-table [dataSource]="dataSourcemainlog" >
                <ng-container matColumnDef="sno">
                  <th mat-header-cell *matHeaderCellDef style="width: 5%">
                    S.NO
                  </th>
                  <td mat-cell *matCellDef="let row; let i = index">
                   {{ i + 1 + masterpaginatorlog?.pageIndex * masterpaginatorlog?.pageSize}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="username">
                  <th mat-header-cell *matHeaderCellDef style="width:6%;" >User Name
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.username }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="created_date">
                  <th mat-header-cell *matHeaderCellDef style="width:6%;" >Recorded
                    Date</th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.date_time | date: "dd-MM-yyyy HH:mm:ss" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="reference_number">
                  <th mat-header-cell *matHeaderCellDef style="width: 7%" >
                    Reference Number
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.reference_number }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef style="width:12%;" >
                    Description
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.description }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="remarks">
                  <th mat-header-cell *matHeaderCellDef style="width:12%;" >
                    Remarks
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.reason }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef style="width:12%;" >
                    Action
                  </th>
                  <td mat-cell *matCellDef="let row">
                    {{ row.action }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="download">

                  <th mat-header-cell *matHeaderCellDef style="width:12%;">
                    Download
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <p *ngIf="row.action === 'cancelled'">N/A</p>
                    <mat-icon matTooltip="Download" id="buttondwnlog" style="color:#0eb8d6"
                      (click)="downloadLog(row)" *ngIf="row.action !== 'cancelled'">cloud_download</mat-icon>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="activitylogcolumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: activitylogcolumns"></tr>
              </table>
            </div>
          </div>
            <div *ngIf="reaDatalog == true" class="text-center">
              <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                Loading...
              </h4>
              <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                No Records Found
              </h4>
            </div>
           <ng-container *ngIf="totalRecordslog >0">
            <mat-paginator [length]="totalRecordslog" [pageSize]="pageSizelog" [pageSizeOptions]="[5, 10, 25, 50, 75, 100]"
            #masterpaginatorlog (page)="onpageeventlog($event)">
          </mat-paginator>
           </ng-container>
        </div>
      </div>

    </mat-card-content>
  </mat-card>

  <ng-container *ngIf="activityLogDownload.length>0">
    <div class="row p-t-10">
      <div class="col-12 col-md-12">
        <div class="table-responsive mat-table-wrapper">
          <div #TABLELog class="example-table-container" class="tableclass">
            <table class="table" style="display: none;">
              <tr class="center" class="headerstyles">
                <th scope="col" class="border">S.no</th>
                <th scope="col" class="border" *ngFor="let header of getHeaders()">{{header}}</th>
              </tr>
  
              <tr *ngFor="let user of activityLogDownload;let i=index;">
                <td class="border">{{ i+1 }}</td>
                <td class="border" style="white-space: nowrap;" *ngFor="let key of getHeaders()">{{user[key]}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </ng-container>