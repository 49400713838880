<div class="material-tab">
    <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms" #tabGroup>
        <mat-tab>
            <ng-template mat-tab-label>
                QR Print DMR
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <div class="row mb-3">
                            <div class="col-md-3 mt-2">
                                <label>Search DMR Number</label>
                                <mat-form-field class="example-full-width1" appearance="outline">
                                    <input type="text" placeholder="Search DMR Number" aria-label="Number" matInput
                                        class="inputdropdown dropdwn" name="number" [(ngModel)]="number"
                                        (keyup)="onSearch($event)" [matAutocomplete]="auto4" />
                                    <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete">
                                        <ng-container *ngIf="filteredData.length > 0; else noRecords">
                                            <mat-option *ngFor="let item of filteredData"
                                                [value]="item.number">{{item.number}}</mat-option>
                                        </ng-container>
                                        <ng-template #noRecords>
                                            <mat-option>No records found</mat-option>
                                        </ng-template>
                                    </mat-autocomplete>
                                </mat-form-field>

                            </div>
                         <div class="col-2">
                            <div class="btnalign" *ngIf="number">
                                <button mat-icon-button value="Add" matTooltip="Add" class="custom-icon-button addbtn"
                                    (click)="tabledata()"    >

                                    <span class="material-icons addicon"> add </span>
                                </button>
                            </div>
                            <div  class="printdata" *ngIf="!number" [ngClass]="{'btnalign': !number  }">
                                <div class="print">
                                    <button (click)="getMasterData(submitmodel)" *ngIf="isPrintEnable" class="btn btn-info">
                                        Submit
                                    </button>
                                </div>
                            </div>
                         </div>

                         <div class="col text-right mt-4">
                            <button mat-icon-button value="Refresh" matTooltip="Refresh"  (click)="getMasterDataa()"
                                    class="custom-icon-button refreshbtn">
                                    <span class="material-icons">
                                        refresh
                                    </span>
                                </button>
                         </div>
                        </div>
                        

                        <ng-container>

                            <div class="row p-t-10 mt-3">
                                <div class="col-12 col-md-12">
                                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                    <div class="table-responsive mat-table-wrapper">
                                        <div class="example-table-container materialTable">
                                            <table mat-table [dataSource]="dataSource">
                                                <ng-container matColumnDef="sno">
                                                    <th mat-header-cell *matHeaderCellDef>S.NO</th>
                                                    <td mat-cell *matCellDef="let row;let i=index;">
                                                        {{i+1}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="dmr_no">
                                                    <th mat-header-cell *matHeaderCellDef>DMR Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.number}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="material_code">
                                                    <th mat-header-cell *matHeaderCellDef>Material Code
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.material_code}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="material_description">
                                                    <th mat-header-cell *matHeaderCellDef>Material
                                                        Description
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{ row.material_description}}
                                                    </td>
                                                </ng-container>


                                                <ng-container matColumnDef="unit_of_measurement">
                                                    <th mat-header-cell *matHeaderCellDef>UOM
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.uom ||row.unit_of_measurment}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="received_quantity">
                                                    <th mat-header-cell *matHeaderCellDef>Received Quantity
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.uom ||row.received_quantity}}
                                                    </td>
                                                </ng-container>


                                                <ng-container matColumnDef="storage_location">
                                                    <th mat-header-cell *matHeaderCellDef>Storage
                                                        Location</th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.storagelocation || row.storage_location }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="print">
                                                    <th mat-header-cell *matHeaderCellDef>Print</th>
                                                    <td mat-cell *matCellDef="let row" class="printinp">
                                                        <mat-form-field class="exam-full-width">
                                                            <input [(ngModel)]="row.printQty" type="number" matInput
                                                                placeholder="print quantity"
                                                                (keyup)="changePrintQty(row)">
                                                        </mat-form-field>
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="Action">
                                                    <th mat-header-cell *matHeaderCellDef>Action</th>
                                                    <td mat-cell *matCellDef="let row" class="printinp">
                                                        <mat-icon matTooltip="Delete" class="delicon" (click)="deleteItem(row)">delete</mat-icon>
                                                    </td>
                                                </ng-container>

                                                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                                </tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div><br>

                        </ng-container>


                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #submitmodel >
    
    
    <div class="row">
        <div class="col-8">
            <h1 mat-dialog-title> Total Print Count - {{gettotalCount()}}</h1>
        </div>
        <div class="col-4">
            <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()">
                <mat-icon class="close1">close</mat-icon>
            </button>
        </div>

    </div>
    <div mat-dialog-actions class="printbtn">

        <button type="button" class="btn btn-primary" 
            [print]="['receipt', config]">Print</button>
    </div>
</ng-template>

<div class="row" id="receipt">
    <div class="col-6 imgsizee " *ngFor="let imgUrl of imageUrls">
        <img class="a4rotate" [src]="imgUrl" alt="Preview Image">
    </div>
</div>