import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CustomerService } from "src/app/services/customer.service";
import * as moment from "moment";
import { AlertCallsService } from "src/app/auth/alert-calls.service";
import Swal from "sweetalert2";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import * as XLSX from 'xlsx'; 
import { MatSort } from '@angular/material/sort';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { event } from 'jquery';
@Component({
  selector: 'app-deliverychallan',
  templateUrl: './deliverychallan.component.html',
  styleUrls: ['./deliverychallan.component.scss']
})
export class DeliverychallanComponent implements OnInit {
  @ViewChild('TABLELog', { static: false }) TABLELog: ElementRef; 
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('masterpaginator') masterpaginator:MatPaginator;
  @Output() page = new EventEmitter<PageEvent>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('materialCodeInput', { read: MatAutocompleteTrigger }) consigneeInputTrigger: MatAutocompleteTrigger;
  goTo:any;
  pageNumbers:any=[]
  displayedColumns: any[] = [
    "action",
    "sno",
    "material_code",
    "material_description",
    "hsn_code",
    // "description",
    "unit_of_measurement",
    "quantity",
    'unit_price',
    'total_value',
    'storage_location',
    'valution_type',
   
  ];
  displayedColumns1: any[] = [
    "sno",
    "Number",
    "Date",
    "company_name",
    "consignee_name",
    "count",
    "action",
  ];
  demo1TabIndex: any = 0;
  companyData: any;
  dcdata: any = {};
  dcdata1: any = {};
  deliveryChallan: any;
  saveddataarray: any[] = [];
  // remarks: any;
  basicprice: any;
  taxdescription: any;
  lineitem: any;
  hsn_Code: any;
  mat_description: any;
  Qty: any[] = [];
  taxpercent: any;
  // model: any = {};
  hsnCODE: any;
  DESCRIPTION: any;
  dataSource = new MatTableDataSource();
  dataSourcemain = new MatTableDataSource();
  dataSourcemainlog = new MatTableDataSource();
  SNO: any[] = [];
  matCode: any[] = [];
  matName: any[] = [];
  UOM: any[] = [];
  itemremarks: any;
  selectedIndex: number;
  deleteNumber: any;
  pageIndex: any = 1;
  pageIndex1: any = 1;
  logpagesize:number = 10;
  pageSize: any = 10;
  totalRecords: any = 0;
  reaData: boolean;
  editednumber: any;
  editdataa: any;
  remarks: any[];
  date: string;
  consignee_name: any;
  company_name: any;
  consignee_address: any;
  company_address: any;
  consignee_gst: any;
  company_gst: any;
  transporter_name: any;
  delivery_address: any;
  vehicle_number: any;
  lr_number: any;
  total_value: any;
  tax_percent: any;
  tax_description: any;
  line_item: any[] = [];
  hsn_code: any[] = [];
  unit_of_measurement: any[] = [];
  description: any[] = [];
  quantity: any[] = [];
  btn: any = "Save";
  editModel: any = {};
  deleteModel: any = {};
  finaldataarray: any[] = [];
  isactivestatus: any = true;
  data_for_edit: any;
  hsncode: any;
  dcdata2: any = {};
  fileUploadUrls: any[] = [];
  filenamearray: any[] = [];
  filenamearray1: any[] = [];
  fileUploadUrlspr: any[] = [];
  selectedfiles: any[] = [];
  resultdcnumber: any;
  dcnumber: any;
  filedata: any=[];
  createNewFile: any = {};
  imageUrl = environment.base_url;
  deleteid: any;
  dialogRef: any = null;
  logdata: any;
  vendorData: any;
  selectedvendor: any;
  selectedmaterial: any;
  selectedmaterial1: any;
  masterData: any=[]
  unit_price: any[]=[];
  storageLocation: any[] = [];
  valutionType: any[] = [];
  basic_price: any[]=[];
  materialCODE: any;
  materialNAME: any;
  matCode11: any;
  matDesc: string;
  taxData: any;
  columnname: string="";
  searchData: string;
  reaDatalog: boolean;
  totalRecordslog: any=0;
  storageData: any;
  selectedstorage: any;
  valutiondata: any;
  smsdata: any;
  smsdata1: any;
  // matcodedata: string="";
  totalValue: any;
  documnet_nodata: any;
  viewnumber: any;
  viewData: boolean=false;
  logsearchData: any;
  SignData: any;
  approvalbtn: boolean=false;
  docSignature: any;
  doc_no: any;
  dcdata1matcode: any;
  today:any;
  txnId: any;
  editeddata: boolean=false;
  countdc: number=0;
  constructor(
    private dialog: MatDialog,
    private custservice: CustomerService,
    private alertcall: AlertCallsService,
    public router: Router,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.today=moment(new Date()).format("YYYY-MM-DD");
    this.isactivestatus = true;
    this.dcdata.date = moment(new Date()).format("YYYY-MM-DD");
    this.demo1TabIndex = 0;
    this.route.queryParams.subscribe((params: any) => {
      if (params.tab == "notifications") {
        this.demo1TabIndex = 1;
        console.log(params);
      } else {
        this.demo1TabIndex = 0;
      }
    });
    this.getTransactionId();
    this.getcompanydata();
    this.getDcList();
    this.getTaxlistdata();
    this.getstoragelocData();
    this.getvalutionData();
    // this.selectedmastergroup();
    this.getvendordata();
    this.getmasterdata();
  }
  getTransactionId(){
    this.txnId=""
    this.custservice.getTransactionId().subscribe((res:any)=>{
      if(res){
        // console.log(res.txn_id);
        this.txnId=res.txn_id
      }
    })
  }
  //get company data
  getcompanydata() {
    let obj = {
      command: "lst",
      lmt: 1000,
      pid: this.pageIndex,
    };
    this.custservice.usercompanyData(obj).subscribe((res: any) => {
      this.companyData = res.data;
    });
  }
  saveFinalData(fr: any) {
    if (this.btn === "Save") {
      console.log(this.saveddataarray);
      this.saveddataarray.forEach((ele: any, index) => {
        console.log(ele);
        this.line_item.push(index + 1);
        this.hsn_code.push(ele.hsn_code);
        this.matCode.push(ele.material_code);
        this.matName.push(ele.material_description  || ele.matcode);
        // this.description.push(ele.description);
        this.unit_of_measurement.push(ele.unit_of_measurement);
        this.unit_price.push(ele.unit_price);
        this.basic_price.push(ele.basic_price);
        this.quantity.push(Number(ele.quantity));
        this.storageLocation.push(ele.storagelocation)
        this.valutionType.push(ele.valutiontype)
      });
      let obj = {
        command: "add",
        "txn_id":this.txnId,
        date: moment(this.dcdata.date).format("YYYY-MM-DD"),
        consignee_name: this.dcdata.consignee_name,
        company_name: this.dcdata.company_name,
        consignee_address: this.dcdata.consignee_address,
        company_address: this.dcdata.company_address,
        consignee_gst: this.dcdata.consignee_gst,
        // company_gst: this.model1.companygst,
        delivery_address: this.dcdata.delivery_address,
        transporter_name: this.dcdata.transporter_name,
        vehicle_number: this.dcdata.vehicle_number,
        lr_number: this.dcdata.lr_number,
        basic_price: this.dcdata.basic_price,
        tax_percent: this.dcdata.tax_percent,
        tax_description: this.dcdata.tax_description,
        remarks: this.dcdata.remarks || "NA",
        line_item: this.line_item,
        hsn_code: this.hsn_code,
        description: this.description,
        unit_of_measurement: this.unit_of_measurement,
        quantity: this.quantity,
        material_code: this.matCode,
        material_description: this.matName,
        unit_price:this.unit_price,
        total_value:this.total_value,
        valuation_type: this.valutionType,
        storage_location: this.storageLocation,
      };

      this.custservice.addDeliveryChallan(obj).subscribe((res: any) => {
        console.log(obj);
        if (res && res["status_code"] == "200") {
          // this.alertcall.showSuccess('Accepted', res['message']);
          Swal.fire({
            text: res["message"],
            title: res["reference"],
            icon: "success",
            // title: res['reference'],
            width: 500,
          });
          // fr.reset();
          this.saveddataarray = [];
          this.finaldataarray = [];
          this.dataSource.data = [];
          this.line_item = [];
          this.hsn_code = [];
          this.unit_price=[];
          this.total_value=[];
          this.matCode=[];
          this.matName=[];
          this.description = [];
          this.unit_of_measurement = [];
          this.quantity = [];
          this.storageLocation = []
          this.valutionType=[]
          this.getTransactionId();
          this.getDcList();
          this.dcdata={};
          this.dcdata.date = this.today;
          this.countdc=0
          this.resultdcnumber = res["reference"];
          // if (this.fileUploadUrlspr.length > 0) {
            this.uploadedselctedfiles();
          // }
          // this.demo1TabIndex = 1;
        } else {
          this.alertcall.showWarning("Accepted", res["message"]);
          this.line_item = [];
          this.hsn_code = [];
          this.description = [];
          this.unit_of_measurement = [];
          this.quantity = [];
          this.unit_price=[];
          this.basic_price=[];
          this.matCode=[];
          this.matName=[];
          this.storageLocation = []
          this.valutionType=[]
        }
      });
    }
    if (this.btn === "Update") {
      console.log(this.saveddataarray);
      console.log(this.dcdata1);
      console.log(this.dcdata);

      this.line_item = [];
      this.hsn_code = [];
      this.description = [];
      this.unit_of_measurement = [];
      this.quantity = [];
      this.saveddataarray.forEach((ele: any, index) => {
        console.log(ele);
        this.line_item.push(index + 1);
        this.matCode.push(ele.material_code);
        this.matName.push(ele.description||ele.material_description || ele.matcode);
        this.hsn_code.push(ele.hsn_code);
        // this.description.push(ele.description);
        this.unit_of_measurement.push(ele.unit_of_measurement);
        this.quantity.push(Number(ele.quantity));
        this.unit_price.push(ele.unit_price);
        this.basic_price.push(ele.basic_price);
        this.storageLocation.push(ele.storagelocation || ele.storage_location)
        this.valutionType.push(ele.valutiontype || ele.valuation_type)
      });
      let obj = {
        reason: this.editModel.reason,
        date: moment(this.dcdata.date).format("YYYY-MM-DD"),
        consignee_name: this.dcdata.consignee_name,
        company_name: this.dcdata.company_name,
        consignee_address: this.dcdata.consignee_address,
        company_address: this.dcdata.company_address,
        consignee_gst: this.dcdata.consignee_gst,
        // company_gst: this.model1.companygst,
        delivery_address: this.dcdata.delivery_address,
        transporter_name: this.dcdata.transporter_name,
        vehicle_number: this.dcdata.vehicle_number,
        lr_number: this.dcdata.lr_number,
        total_value:this.dcdata.total_value,
        tax_percent: this.dcdata.tax_percent,
        tax_description: this.dcdata.tax_description,
        remarks: this.dcdata.remarks,
        line_item: this.line_item,
        hsn_code: this.hsn_code,
        // description: this.description,
        unit_of_measurement: this.unit_of_measurement,
        quantity: this.quantity,
        material_code: this.matCode,
        material_description: this.matName,
        unit_price:this.unit_price,
        basic_price: this.basic_price,
        number: this.editednumber,
        valuation_type: this.valutionType,
        storage_location: this.storageLocation,
        "txn_id":this.txnId,
        command: "edt",
      };
      this.custservice.updateDeliveryChallan(obj).subscribe((res: any) => {
        if (res && res["status_code"] == "200") {
          Swal.fire({
            text: res["message"],
            title: res["reference"],
            icon: "success",
            // title: res['reference'],
            width: 500,
          });
          fr.reset();
          this.saveddataarray = [];
          this.finaldataarray = [];
          this.dataSource.data = [];
          this.line_item = [];
          this.hsn_code = [];
          this.description = [];
          this.unit_of_measurement = [];
          this.quantity = [];
          this.unit_price=[];
          this.basic_price=[];
          this.matCode=[];
          this.matName=[];
          this.getTransactionId();
          this.getDcList();
          this.dcdata={};          
          // this.editednumber = "";
          this.editModel.reason = "";
          this.editeddata = false;
          this.resultdcnumber = this.editednumber;
          // if (this.fileUploadUrlspr.length > 0) {
            this.uploadedselctedfiles();
          // }
          // this.smsdata = "";
          this.btn = "Save";
          this.editednumber = "";
          this.countdc=0;
          this.demo1TabIndex = 1;
        } else {
          this.alertcall.showWarning("Accepted", res["message"]);
          this.line_item = [];
          this.hsn_code = [];
          this.description = [];
          this.unit_price=[];
          this.basic_price=[];
          this.matCode=[];
          this.matName=[];
          this.unit_of_measurement = [];
          this.quantity = [];
        }
      });
    }
    // this.saveddataarray = [];
  }
  keyPressAlphanumeric(event:any) {

    var inp = String.fromCharCode(event.keyCode);

    if (/^[A-Z0-9]$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  addDeliveryChallanmodel(data: any) {
    this.materialNAME=""
    this.materialCODE=""
    this.dcdata2 = {};
    this.dialog.open(data, {
      width: "1100px",
      // scrollStrategy: this.overlay.scrollStrategies.noop()
      //  scrollStrategy: new NoopScrollStrategy()
    });
  }

  closemodel() {
    this.dialog.closeAll();
  }
  saveaddeddata(form: any) {

    // this.model["hsn_code"] = this.hsnCODE;
    // this.model["description"] = this.DESCRIPTION;
    console.log(this.dcdata1);

    // this.editDATAA=true;
    this.saveddataarray.push(this.dcdata2);
    console.log(this.saveddataarray);

    this.dataSource.data = this.saveddataarray;
    console.log(this.dataSource.data);
    // this.dcdata = {};
    // this.smsdata = "";
    this.dialog.closeAll();
    this.gettotalvalue11();
    this.countdc=0
      this.saveddataarray.forEach((ele: any,index) => {
        this.countdc =  index+1
      })
  }

  editdata(row1: any, index: any, data: any) {
    console.log(row1);
    // this.matcodedata=row1.material_code
    // this.getmasterdata()
    this.materialCODE=""
    this.materialNAME=""
    this.selectedIndex = this.saveddataarray.indexOf(row1);
    console.log(this.selectedIndex);
    this.dialog.open(data, {
      width: "1100px",
    });
    this.matDesc=row1.material_code+"-"+(row1.description || row1.material_description || row1.matcode);
    this.materialCODE=row1.material_code;
    this.materialNAME=row1.description || row1.material_description || row1.matcode;
    this.dcdata1.matcode=  row1.matcode||  this.matDesc ;
    this.dcdata1.hsn_code = row1.hsn_code;
    this.dcdata1.unit_of_measurement = row1.unit_of_measurement;
    // this.dcdata1.description = row1.description;
    this.dcdata1.quantity = row1.quantity;
    this.dcdata1.unit_price=row1.unit_price;
    this.dcdata1.basic_price=row1.basic_price
    this.dcdata1.storagelocation=row1.storage_location || row1.storagelocation;
    this.dcdata1.valutiontype=row1.valuation_type || row1.valuationtype || row1.valutiontype;

    // this.dcdata1["material_code"] = this.materialCODE;
    // this.dcdata1["material_description"] = this.materialNAME;
  }
  saveaddededitdata(fr: any) {
    this.materialCODE=""
    this.materialNAME=""
    console.log(this.masterData);
    this.masterData.forEach((el: any) => {
      if (el.system_reference_1 == this.dcdata1.matcode) {
        console.log(el);
        this.dcdata1matcode = el.code;
        this.materialCODE = el.code;
        this.materialNAME = el.name;
      }
    });
    this.getSMSdata();
    this.dcdata1["material_code"] = this.materialCODE;
    // this.dcdata1["material_code"] = this.matCode11;
    this.dcdata1["material_description"] = this.materialNAME;
    console.log(this.dcdata1);
    
    this.saveddataarray.splice(this.selectedIndex, 1, this.dcdata1);
    this.dataSource.data = this.saveddataarray;
    console.log(this.dataSource.data);
    this.saveddataarray[this.selectedIndex].matcode = this.dcdata1.matcode

    this.dcdata1 = {};
   
    //  this.smsdata = "";
    this.dialog.closeAll();
    this.gettotalvalue11();
    // setTimeout(() => {
    //   this.matcodedata=""
    // }, 100);
  }
  deleterow(index: any) {
    console.log(index);
    this.saveddataarray.splice(index, 1);
    this.countdc = this.saveddataarray.length
    this.dataSource.data = this.saveddataarray;
    console.log(this.dataSource.data);
    this.gettotalvalue11();
  }
  deleteItem(rw: any, data: any) {
    this.dialog.open(data, {
      width: "400px",
      // scrollStrategy: new NoopScrollStrategy()
    });
    this.deleteModel.reason = "";
    this.deleteNumber = rw.number;
  }
  getDcList1(){
    this.columnname = "";
    this.searchData = "";
    this.getDcList();
  }
  getDcList() {
    this.pageNumbers=[]
    let obj = {
      command: "lst",
      lmt: this.pageSize,
      pid: this.pageIndex,
      // key: "",
    };
    this.custservice.getDeliveryChallanData(obj).subscribe((res: any) => {
      console.log(res);

      this.reaData = false;
      this.totalRecords = res?.count;
      this.dataSourcemain.data = res.data;
      this.dataSourcemain.sort = this.sort
      for (let i = 1; i <= Math.ceil(this.totalRecords / this.pageSize); i++) {
        this.pageNumbers.push(i);
      }
      this.goTo=this.pageIndex
      if (res.data.length == 0) {
        this.reaData = true;
      }
    });
  }
  goToChange(){
    this.paginator.pageIndex = this.goTo - 1;
    const event: PageEvent = {
      // previousPageIndex: this.pageIndex-1,
      length: this.paginator.length,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
    };
    this.paginator.page.next(event);
  }
  emitPageEvent(pageEvent: PageEvent) {
    this.paginator.page.next(pageEvent);
  }
  onpageevent(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if(!this.searchData){
       this.getDcList();
    }else{
      this.search();
    }
  }
  deleteFile() {
    let obj = {
      command: "del",
      number: this.deleteNumber,
      reason: this.deleteModel.reason,
      "txn_id":this.txnId,
    };
    this.custservice.deleteDcFile(obj).subscribe((res: any) => {
      if (res && res["status_code"] == "200") {
        this.alertcall.showSuccess("Accepted", res["message"]);

        this.dialog.closeAll();
        this.getTransactionId();
        this.getDcList();
        this.deleteModel.reason = "";
      } else {
        this.alertcall.showWarning("Accepted", res["message"]);
      }
    });
  }
  edit_data(data: any, index: number, dialog: any) {
    console.log(data);
    this.data_for_edit = data;
    this.dialog.open(dialog, {
      width: "400px",
    });
    this.editModel.reason = "";
    this.editednumber = data.number;
  }
  saveeditreason() {
    let obj = {
      command: "mat",
      field: "number",
      key: this.editednumber,
    };
    this.custservice.addDeliveryChallan(obj).subscribe((res: any) => {
      this.viewData=false
      this.dialog.closeAll();
      this.editdataa = res.data[0];
      console.log(this.editdataa);
      this.saveddataarray = res.data;
      this.dataSource.data = this.saveddataarray;
      console.log(this.dataSource);
      this.dcdata.date = moment(this.editdataa.date).format("YYYY-MM-DD");
      this.dcdata.consignee_name = this.editdataa.consignee_name;
      this.dcdata.company_name = this.editdataa.company_name;
      // this.model1.companygst = this.editdataa.company_gst;
      this.dcdata.consignee_address = this.editdataa.consignee_address;
      this.dcdata.company_address = this.editdataa.company_address;
      this.dcdata.consignee_gst = this.editdataa.consignee_gst;
      this.dcdata.delivery_address = this.editdataa.delivery_address;
      this.dcdata.transporter_name = this.editdataa.transporter_name;
      this.dcdata.vehicle_number = this.editdataa.vehicle_number;
      this.dcdata.lr_number = this.editdataa.lr_number;
      
      this.dcdata.tax_percent = this.editdataa.tax_percent;
      this.dcdata.tax_description = this.editdataa.tax_description;
      this.dcdata.remarks = this.editdataa.remarks;
      this.line_item = this.editdataa.line_item;
      this.hsn_code = this.editdataa.hsn_code;
      // this.mat_code=this.editdataa.material_code;
      // this.description = this.editdataa.description;
      this.unit_of_measurement = this.editdataa.unit_of_measurement;
      this.quantity = this.editdataa.quantity;

      this.demo1TabIndex = 0;
      this.btn = "Update";
      this.editeddata = true;
      this.totalValue=0
      this.saveddataarray.forEach((ele:any)=>{
        this.totalValue+=ele.basic_price;
        this.dcdata.total_value =(this.totalValue * this.dcdata.tax_percent * 0.01) + this.totalValue
      })
      this.countdc=0
        this.saveddataarray.forEach((ele: any,index) => {
        this.countdc =  index+1
        })
    });
  }
  printDc(data: any) {
    this.getPrintStatus(data);  
    console.log(data.number);
  //   const url = this.router.serializeUrl(
  //    this.router.createUrlTree(["/inventory/print-dc"], {queryParams: { dcnumber: data.number }}))
  //  window.open(url,"_blank")
// window.print(url,"_blank")
    //  this.router.navigate(["/inventory/print-dc"], {queryParams: { dcnumber: data.number,doc_sign:data.doc_sign }})
  }
  getPrintStatus(data:any) {
    const postData = new FormData();
    postData.append("command","mat");
    postData.append("document_number", data.number);
    postData.append("document_name", "Delivery Challan" );
    this.custservice.DocumentSign(postData).subscribe((res: any) => {
      if(res&& res.print_status === true){
        this.router.navigate(["/inventory/print-dc"], {queryParams: { dcnumber: data.number,doc_sign:data.doc_sign }})
      }else{
        this.alertcall.showWarning("Warning", "Not Approved,Unable to Print");
      } 
    });
  }
  uploadprfiles(fileInput: any) {
    if (
      fileInput &&
      fileInput.target &&
      fileInput.target.files &&
      fileInput.target.files.length > 0
    ) {
      this.fileUploadUrlspr = fileInput.target.files;
      // for (const file of this.fileUploadUrlspr) {
      //   this.filenamearray1.push(file.name);
      //   this.selectedfiles.push(file);
      // }
      for (const file of this.fileUploadUrlspr) {
        const existingFile = this.selectedfiles.find(selectedFile => selectedFile.name === file.name);
        if(this.isFileSelected(file.name) && existingFile){
          this.alertcall.showWarning('Alert', 'Some files are already selected');
        }else if(this.fileUploadUrlspr.length>1){
          this.alertcall.showSuccess('Accepted', "Files are uploaded successfully");
          this.filenamearray.push(file.name)
          this.selectedfiles.push(file)
        }
        else{
          this.alertcall.showSuccess('Accepted', "File is uploaded successfully");
          this.filenamearray.push(file.name)
          this.selectedfiles.push(file)
        }
      }
      fileInput.target.value = '';
    }
  }
  isFileSelected(selectedFilePath: string): boolean {
    return this.selectedfiles.some((file:any) => file.name == selectedFilePath);
  }
  uploadedselctedfiles() {
    const postData = new FormData();
    postData.append("document_type", "Delivery Challan");
    postData.append("document_number", this.resultdcnumber);
    for (const file of this.selectedfiles) {
      postData.append("doc", file);
    }

    this.custservice.addfileupload(postData).subscribe((res: any) => {
      if (res && res["status_code"] == "200") {
        this.fileUploadUrlspr = [];
        this.selectedfiles = [];
        this.filenamearray1 = [];
      } else {
      }
    });
  }
  openfileuploadmodel(data: any, row1: any) {
    this.dialog.open(data, {
      width: "800px",
    });
    this.dcnumber = row1.number;
    this.getexistingfiles();
  }
  getexistingfiles() {
    let params = new HttpParams();
    params = new HttpParams()
      .set("document_number", this.dcnumber)
      .set("document_type", "Delivery Challan");
    // let obj={
    //   "document_number" : this.dmrnumber,
    //   "document_type": "Daily Material Receipt"
    // }
    this.custservice.getexistingfies(params).subscribe((res: any) => {
      if (res && res["status_code"] == "200") {
        this.filedata = res.data;
        this.createNewFile.fileName = "";
      } else {
        this.filedata = [];
        console.log(this.filedata);
      }
    });
  }
  viewDoc(file: any) {
    const url = this.imageUrl + "/" + file.file_path;
    console.log(url , "L");
    
    window.open(url, "_blank");
  }
  viewDoc1(file: any) {
    const url = file.file_path;
    window.open(url, '_blank');
  }
  deleterowfile(row: any, data: any) {
    this.deleteid = data.id;
    this.dialogRef = this.dialog.open(row, {
      width: "400px",
    });
  }
  deleteexistingfile() {
    let params = new HttpParams();
    params = new HttpParams()
      .set("document_number", this.dcnumber)
      .set("document_type", "Delivery Challan")
      .set("id", this.deleteid);
    this.custservice.deletefiles(params).subscribe((res: any) => {
      if (res && res["status_code"] == "200") {
        this.alertcall.showSuccess("Accepted", "File Deleted Successfully");
        this.getexistingfiles();
        this.dialogRef.close();
      } else {
        this.alertcall.showWarning("Error", res["message"]);
      }
    });
  }
  uploadWbsFile(fileInput: any) {
    if (
      fileInput &&
      fileInput.target &&
      fileInput.target.files &&
      fileInput.target.files.length > 0
    ) {
      this.fileUploadUrls = fileInput.target.files;
      this.createNewFile.fileName = fileInput.target.files[0].name;
      for (const file of this.fileUploadUrls) {
        this.filenamearray.push(file.name);
      }
    }
    console.log(this.fileUploadUrls);
    const postData = new FormData();
    postData.append("document_type", "Dellivery Challan");
    postData.append("document_number", this.dcnumber);
    // for (const file of this.fileUploadUrls) {
    //   postData.append("doc", file);
    // }
    // this.custservice.addfileupload(postData).subscribe((res: any) => {
    //   if (res && res["status_code"] == "200") {
    //     this.alertcall.showSuccess("Accepted", res["message"]);
    //     this.getexistingfiles();
    //     this.filenamearray = [];
    //     this.fileUploadUrls = [];
    //   } else {
    //     this.alertcall.showWarning("Error", res["message"]);
    //   }
    // });
    for (const file of this.fileUploadUrls){
      if(this.filedata && this.filedata.length >  0 && this.isFileSelected1(file.name)){
        this.alertcall.showWarning('Alert', 'Some files are already uploaded');
      }else{
        postData.append("doc", file)
    this.custservice.addfileupload(postData).subscribe((res: any) => {
      
      if (this.fileUploadUrls.length > 1 && res['status_code'] == '200') {
        this.alertcall.showSuccess("Accepted", "Files are uploaded successfully")
        this.getexistingfiles() 
        this.fileUploadUrls = []
      }
      else if (this.fileUploadUrls.length === 1 && res['status_code'] == '200') {
        this.alertcall.showSuccess("Accepted", "File is uploaded successfully")
        this.getexistingfiles()
        this.fileUploadUrls = []
      } else {
        this.alertcall.showWarning("Error", res['message'])
      }
      fileInput.target.value = '';
    })
  }
    }
  }
  isFileSelected1(selectedFilePath: string): boolean {
    return this.filedata.some((file:any) => file.file_name == selectedFilePath);
  }
  
//material code
getmaterialDATA(ev: any) {
  // console.log(234567);
  this.dcdata2.unit_of_measurement=''
  console.log(ev.target.value);
  this.selectedmaterial1 = ev.target.value;
  if (this.selectedmaterial1.length > 2) {
    this.getmasterdata();
  }
  if (!this.selectedmaterial1) {
    this.getmasterdata();
  }
}
getmaterialDATA1(ev: any) {
  // console.log(1123456);
  this.dcdata1.unit_of_measurement=''
  this.selectedmaterial1 = ev.target.value;
  if (this.selectedmaterial1.length > 2) {
    this.getmasterdata();
  }
  if (!this.selectedmaterial1) {
    this.getmasterdata();
  }
}
getmasterdata() {
    let obj = {
      command: "lst",
      lmt: 100,
      pid: 1,
      key: this.selectedmaterial1,
      // "code":this.matcodedata
    };
    this.custservice.getmaterialmasterdata(obj).subscribe((res: any) => {
      console.log(res);
      this.masterData = res.data;
    //   this.masterData.forEach((ele: any) => {
    //     if (ele.code == this.matcodedata) {
    //       // alert(1)
    //       this.dcdata1.matcode = ele.system_reference_1
    //       // this.matcodedata=""
    //       // this.systemref = ele.system_reference_1
    //     }
    // });
  });
 
}

selectedmastergroup() {
  this.dcdata2.consignee_address=''
  this.dcdata2.delivery_address=''
  this.dcdata2.consignee_gst =''
  console.log(this.dcdata2.matcode);
  this.masterData.forEach((ele: any) => {
    if (ele.system_reference_1 == this.dcdata2.matcode) {
      this.dcdata2.unit_of_measurement = ele.uom_1;
      this.materialCODE = ele.code;
      this.materialNAME = ele.name;
    }
  });
  this.dcdata2["material_code"] = this.materialCODE;
  // this.dcdata2["material_code"] = this.matCode11;
  this.dcdata2["material_description"] = this.materialNAME;
  this.getSMSdata();
}

selectedmastereditgroup() {
  this.masterData.forEach((el: any) => {
    if (el.system_reference_1 == this.dcdata2.matcode) {
      this.dcdata2.uom = el.uom_1;
      this.materialCODE = el.code;
      this.materialNAME = el.description;
    }
  });
  this.dcdata1["material_code"] = this.materialCODE;
  // this.dcdata2["material_code"] = this.matCode11;
  this.dcdata1["material_description"] = this.materialNAME;
  this.getSMSdata();
}

//consignee 
getvendordropdowndata(ev:any){
  this.dcdata.consignee_address=''
  this.dcdata.delivery_address=''
  this.dcdata.consignee_gst =''
  console.log(ev.target.value);
  this.selectedvendor=ev.target.value
  if( this.selectedvendor.length>2){
 this.getvendordata()
  }
  if(! this.selectedvendor){
    this.getvendordata()
  }
}
getvendordata(){
  let obj={
    "command":"lst",
    "lmt":100000,
    "key":this.selectedvendor ||"",
    showLoader: 'hide'
  }
  this.custservice.getvendormasterdata(obj).subscribe((res:any)=>{
    if(res.data){
      this.vendorData=res.data
    }
  })
}

selectedConsignee(){
    this.vendorData.forEach((el: any) => {
      if (el.name == this.dcdata.consignee_name) {
        this.dcdata.consignee_address=el.address_1
        this.dcdata.delivery_address=el.address_1
        this.dcdata.consignee_gst =el.gst_number
      }
    });
}
getTaxlistdata(){
  let obj={
    "command" : "lst",
    lmt: 100000,
      pid: 1,
  }
  this.custservice.gettaxlistdata(obj).subscribe((res:any)=>{
  this.taxData=res.data
  })
}
selectedTAXDESC(){
  this.taxData.forEach((element:any) => {
    if(element.code==this.dcdata.tax_description){
      this.dcdata.tax_percent=element.percentage
      // this.model1.othertaxvalue=this.basicFreight1*this.editmodel.frtaxpercentage*0.01
      // this.editmodel.totalfreight= +this.basicFreight1+this.editmodel.frtaxvalue
    }
  });  
  this.gettotalvalue11();
}
//select all
onChange() {
  console.log('Selected:',this.columnname);
  this.searchData=""
  if(!this.searchData){
    this.getDcList()
  }    
}
search1(){
  if(this.searchData.length>2){
    if(this.paginator){
      this.paginator.firstPage();
    }
    this.search();
  }else if(!this.searchData){
    this.getDcList()
    this.columnname=""
  }
}
search(){
  this.pageNumbers=[]
  // console.log(this.searchData);
  // this.pageIndex=1; 
 let obj= {"command":"lst","field":this.columnname ,"key":this.searchData.trim(),"lmt":this.pageSize,"pid":this.pageIndex,showLoader:'hide'}
//  if(this.searchData){
//     this.paginator.firstPage();
//  }
  if(this.searchData.length>2 && this.columnname){
    this.custservice.getSearchDeliveryChallan(obj).subscribe((res: any) => {
      // console.log(res);
      this.reaData = false;
      this.totalRecords = res?.count;    
      this.dataSourcemain.data = res.data;
      for (let i = 1; i <= Math.ceil(this.totalRecords / this.pageSize); i++) {
        this.pageNumbers.push(i);
      }
      this.goTo=this.pageIndex
      if (res.data.length == 0) {
        this.reaData = true;
      }
    });
  }else if(!this.searchData){
    this.getDcList()
    this.columnname=""
  }
}

//
keyPressNumbers(evt: any) {
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode != 46 && charCode > 31
    && (charCode < 48 || charCode > 57))
    return false;

  return true;
}
getTotalValue() {
  // this.dcdata2.unit_price = ev.target.value
  // console.log(this.dcdata2.unit_price);
  this.dcdata2.basic_price = parseFloat((this.dcdata2.unit_price * this.dcdata2.quantity).toFixed(2));
}
getTotalValueedit(ev: any) {
  // this.dcdata1.unit_price = ev.target.value
  // console.log(this.dcdata1.unit_price);
  this.dcdata1.basic_price =parseFloat((this.dcdata1.unit_price * this.dcdata1.quantity).toFixed(2));
}
enableUpdate(){
  if(!this.dcdata1.matcode || !this.dcdata1.hsn_code   || this.dcdata1.quantity <= 0  || !this.dcdata1.unit_price  || !this.dcdata1.storagelocation || !this.dcdata1.valutiontype){
    return true
  }else{
    return false
  }
}
  //get storage data
  getstorageDATA(ev: any) {
    // console.log(ev.target.value);
    this.selectedstorage = ev.target.value
    if (this.selectedstorage.length > 2) {
      this.getstoragelocData();
    }
  }
  getstoragelocData() {
    let obj = {
      "command": "mat",
      "field": "storage_location",
      "key": this.selectedstorage
    }
    this.custservice.getmatstoragelocdata(obj).subscribe((res: any) => {
      this.storageData = res.data
    })
  }
  //get valuation data
  getvalutionData() {
    let obj = {
      "lmt": 100000,
      'pid': 1,
      "command": "lst",
      "key": ""
    }
    this.custservice.getvalutiondata(obj).subscribe((res: any) => {
      this.valutiondata = res.data
    })
  }

  getSMSdata() {
    let obj = {
      material_code: this.materialCODE,
      command: "sms",
    };
    this.custservice.getsmsdata(obj).subscribe((res: any) => {
      console.log(res);
      if (res && res["status_code"] == "200") {
        console.log(res);

        this.smsdata = res.message;
        this.smsdata1=res.storage_locations;
      } else {
        this.smsdata = "";
        this.smsdata1="";
        this.alertcall.showWarning("Warning", res["message"]);
      }
    });
  }

  gettotalvalue11(){
        // console.log(this.saveddataarray);
        // console.log(this.dcdata.tax_percent);
      this.totalValue=0
      this.saveddataarray.forEach((ele:any)=>{
          this.totalValue+=ele.basic_price;
          this.dcdata.total_value =(this.totalValue * this.dcdata.tax_percent * 0.01) + this.totalValue
      })
  // console.log(this.totalValue);
      console.log(this.dcdata.total_value);
  
    }
 

      //approval icon
  getApprovals(data:any, row1: any){
    // console.log(data.number);
    this.approvalbtn=false
    this.dialog.open(data, { // this.getConfirmData();
      width: '600px'
    })
    this.documnet_nodata=row1.number;
  }

  getApprovals1(data:any,row:any){
    this.approvalbtn=false
    this.doc_no=row.number
    // console.log(data.number);
    this.dialog.open(data, { // this.getConfirmData();
      width: '600px'
    })
  }

getConfirmData(){
  let obj = {
    command: "add",
    document_name: "DeliveryChallan",
    document_number:this.documnet_nodata || this.viewnumber ||this.doc_no
  };
  this.custservice.AutoDocUpdate2(obj).subscribe((res: any) => {
    if(res && res['status_code']==200){
      this.dialog.closeAll()
      this.documnet_nodata = "";
      this.viewnumber = "";
      this.doc_no = "";
      this.alertcall.showSuccess("Accepted", res['message'])
      this.getDcList()
      this.approvalbtn=true
    } else {
      this.dialog.closeAll()
      this.alertcall.showWarning("Error", res['message'])
    }      
  });
}
  //view
  viewreason(data:any) {
    this.displayedColumns = [    
      "sno",  "material_code", "material_description","hsn_code","unit_of_measurement","quantity",'unit_price','total_value','storage_location','valution_type',    
    ];
    this.viewnumber=data.number;
    let obj = {
      "command": "mat",
      "key": data.number,
      "field": "number"
    }
    this.custservice.addDeliveryChallan(obj).subscribe((res: any) => {
      if(res.data.length>0){
        this.viewData=true
        // this.dialog.closeAll();
        this.editdataa = res.data[0];
     
        // console.log(this.editdataa);
        this.saveddataarray = res.data;
        this.dataSource.data = this.saveddataarray;
        // console.log(this.dataSource);
        this.dcdata.date =this.editdataa.date!==null? moment(this.editdataa.date).format("DD-MM-YYYY"):"NA";
        this.dcdata.consignee_name = this.editdataa.consignee_name!==""?this.editdataa.consignee_name:"NA";
        this.dcdata.company_name = this.editdataa.company_name !==""? this.editdataa.company_name:"NA";
        // this.model1.companygst = this.editdataa.company_gst;
        this.dcdata.consignee_address = this.editdataa.consignee_address!==""?this.editdataa.consignee_address:"NA";
        this.dcdata.company_address = this.editdataa.company_address!==""?this.editdataa.company_address:"NA";
        this.dcdata.consignee_gst = this.editdataa.consignee_gst!==null?this.editdataa.consignee_gst:"NA";
        this.dcdata.delivery_address = this.editdataa.delivery_address!==null?this.editdataa.delivery_address:"NA";
        this.dcdata.transporter_name = this.editdataa.transporter_name!==""?this.editdataa.transporter_name:"NA";
        this.dcdata.vehicle_number = this.editdataa.vehicle_number!==null?this.editdataa.vehicle_number:"NA";
        this.dcdata.lr_number = this.editdataa.lr_number!==null?this.editdataa.lr_number:"NA";
       
        this.dcdata.tax_percent = this.editdataa.tax_percent!==null?this.editdataa.tax_percent:"NA";
        this.dcdata.tax_description = this.editdataa.tax_description!==""?this.editdataa.tax_description:"NA";
        this.dcdata.remarks = this.editdataa.remarks!==null?this.editdataa.remarks:"NA";
        this.line_item = this.editdataa.line_item;
        this.hsn_code = this.editdataa.hsn_code!==null?this.editdataa.hsn_code:"NA";
        // this.mat_code=this.editdataa.material_code;
        // this.description = this.editdataa.description;
        this.unit_of_measurement = this.editdataa.unit_of_measurement!==null?this.editdataa.unit_of_measurement:"NA";
        this.quantity = this.editdataa.quantity!==null?this.editdataa.quantity:"NA";
        this.docSignature=this.editdataa.doc_sign
        this.demo1TabIndex = 0;
        this.countdc=0
        this.saveddataarray.forEach((ele: any,index) => {
        this.countdc =  index+1
        })
        // this.btn = "Update";
        // this.getSignatureData();
        this.totalValue=0
        this.saveddataarray.forEach((ele:any)=>{
          this.totalValue+=ele.basic_price;          
          this.dcdata.total_value =(this.totalValue * this.dcdata.tax_percent * 0.01) + this.totalValue
        })
    
      }else{
        this.alertcall.showWarning("Accepted", "No Data Found");
      }
      
    });

  }
  BacktoList(){
    this.demo1TabIndex = 1;
    this.viewData=false
    this.approvalbtn=false
    this.displayedColumns = [    
      "action","sno",  "material_code", "material_description","hsn_code","unit_of_measurement","quantity",'unit_price','total_value','storage_location','valution_type',    
    ];
  }
  //signature data
  getSignatureData() {
    const postData = new FormData();
    postData.append("command","mat");
    postData.append("document_number", this.viewnumber );
    postData.append("document_name", "DeliveryChallan" );
    this.custservice.DocumentSign(postData).subscribe((res: any) => {
      if(res){
        this.SignData = res.data;      
      }
    });
  }
  signatureStatus(data: any) {
    this.dialog.open(data, {
      width: '600px'
    })
    this.getSignatureData()
  }

   
  //on change tab
onTabChanged(ev:any){
  if(this.demo1TabIndex===1){
    // alert(1);
    this.viewData=false
    this.dcdata={}
    this.dcdata.data=moment(new Date()).format("YYYY-MM-DD")
    this.saveddataarray = [];
    this.logsearchData=""
    this.columnname=""
    this.searchData=""
          this.finaldataarray = [];
          this.dataSource.data = [];
          this.line_item = [];
          this.hsn_code = [];
          this.unit_price=[];
          this.total_value=[];
          this.matCode=[];
          this.matName=[];
          this.description = [];
          this.unit_of_measurement = [];
          this.quantity = [];
          this.storageLocation = []
          this.valutionType=[] 
          this.pageSize = 10;
          this.pageIndex = 1;
          this.pageIndex1 = 1;         
          this.getDcList();          
    this.router.navigate(['/inventory/deliverychallan'])
    this.dataSourcemain.sort = this.sort;
  this.paginator.pageIndex = this.pageIndex-1
  this.editeddata = false;
  this.btn = "Save";
  this.editednumber = "";
  this.dcdata.date = this.today;
  this.countdc = 0
  this.displayedColumns = [    
    "action","sno",  "material_code", "material_description","hsn_code","unit_of_measurement","quantity",'unit_price','total_value','storage_location','valution_type',    
  ];
  }else if(this.demo1TabIndex===2){
    // alert(12);
    this.viewData=false
    this.viewData=false
    this.dcdata={}
    this.dcdata.data=moment(new Date()).format("YYYY-MM-DD")
    this.saveddataarray = [];
          this.finaldataarray = [];
          this.logsearchData=""
          this.columnname=""
          this.searchData=""
          this.dataSource.data = [];
          this.line_item = [];
          this.hsn_code = [];
          this.unit_price=[];
          this.total_value=[];
          this.matCode=[];
          this.matName=[];
          this.description = [];
          this.unit_of_measurement = [];
          this.quantity = [];
          this.storageLocation = []
          this.valutionType=[]
          this.logpagesize = 10;
          this.pageIndex = 1;
          this.pageIndex1 = 1; 
          this.router.navigate(['/inventory/deliverychallan'])
          this.editeddata = false;
          this.btn = "Save";
          this.editednumber = "";
          this.dcdata.date = this.today;
          this.countdc = 0
    // this.dataSourcemainlog.sort = this.sort
    this.displayedColumns = [    
      "action","sno",  "material_code", "material_description","hsn_code","unit_of_measurement","quantity",'unit_price','total_value','storage_location','valution_type',    
    ];
    }

   }
   clearpage1(){
    this.viewData=false
    this.dcdata={}
    this.saveddataarray = [];
          this.finaldataarray = [];
          this.dataSource.data = [];
          this.line_item = [];
          this.hsn_code = [];
          this.unit_price=[];
          this.total_value=[];
          this.matCode=[];
          this.matName=[];
          this.description = [];
          this.unit_of_measurement = [];
          this.quantity = [];
          this.storageLocation = []
          this.valutionType=[]
          this.btn="Save"
          this.selectedfiles = []
          this.filenamearray1=[]
          this.editednumber=""
          this.getTransactionId();
          this.editeddata = false;
          this.dcdata.date = this.today;
          this.countdc = 0
          this.displayedColumns = [    
            "action","sno",  "material_code", "material_description","hsn_code","unit_of_measurement","quantity",'unit_price','total_value','storage_location','valution_type',    
          ];
   }

      disableButton(){
        console.log(this.saveddataarray);
        if(this.dcdata.company_name && this.dcdata.date && this.dcdata.consignee_name && this.dcdata.transporter_name && this.dcdata.consignee_gst && this.dcdata.vehicle_number && this.dcdata.lr_number && this.dcdata.total_value  >= 0  && this.dcdata.delivery_address && (this.dcdata.tax_description || this.dcdata.tax_description == "") && (this.saveddataarray.length !== 0)){
          return false
        }else{
          return true
        }
      }
      openAutocompletePanel() {
    this.consigneeInputTrigger.openPanel();
  }
  trimInput(){
    this.dcdata.transporter_name = this.dcdata.transporter_name.trim();
    this.dcdata.consignee_gst = this.dcdata.consignee_gst.trim();
    this.dcdata.lr_number = this.dcdata.lr_number.trim();
    this.dcdata.total_value = this.dcdata.total_value.trim();
    this.dcdata.remarks = this.dcdata.remarks.trim();
  }
  openNewFileUploadModal(data: any) {
    this.dialog.open(data, {
      width: '800px'
    })
    this.dcnumber = this.editednumber;
    this.getexistingfiles();
  }

  deleteFileItem(index:any){
    this.selectedfiles.splice(index,1)
   }
}
