<div class="material-tab">
    <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms" #tabGroup>
        <mat-tab>
            <ng-template mat-tab-label>
                DMR Bulk Update
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-lg-2 col-md-3 ">
                                <label>From Date<span class="mandetory">*</span></label><br>
                                <input type="date"  class="inputstyles" name="fromdate"
                                    [(ngModel)]="fromdate" dateFormat="yyyy/mm/dd" [max]="maxDate"  (ngModelChange)="validateForm()"/>
                            </div>
                            <div class="col-lg-2 col-md-3">
                                <label>To Date<span class="mandetory">*</span></label><br>
                                <input type="date"  class="inputstyles" name="todate"
                                    [(ngModel)]="todate" dateFormat="yyyy/mm/dd" [max]="maxDate" (ngModelChange)="validateForm()"  />
                                    <div *ngIf="dmrbulkerrormsg()">
                                        <p class="errormsg">To Date must be greater than the From Date.</p>
                                    </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <label>Company Name<span class="mandetory">*</span></label>
                                <mat-form-field class="custom-input-field" appearance="outline">
                                  <mat-select [(ngModel)]="companyname" name="companyname" #selectcompany multiple>
                                    <div class="select-all">
                                      <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection()">
                                        Select All
                                      </mat-checkbox>
                                    </div>
                                    <mat-option (click)="optionClickCompany()" *ngFor="let item of companyData1" [value]="item.company_name">
                                      {{ item.company_name }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                           
                          
                              
                            <div class="col-md-3">
                            <span class="d-flex">
                                <button mat-raised-button color="primary" class="downloadbtn  viewbtn2" [disabled]="!isFormValid" 
                                (click)="getDmrbulkpdates1()">VIEW</button>
                            <button mat-raised-button  class="downloadbtn  Resetbtn" 
                            (click)="getdmrdataDataa()">RESET</button>
                            </span>
                         </div>
                            </div>
                        
                            <mat-card class="p-0 m-t-20" *ngIf="searchfieldtable" >
                                <mat-card-content>
                                    <div class="row">

                                        <div class="col-4">
                                            <div class="col-md-12">
                                                <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                                              <select class="dropdownstyles" name="searchcol" [(ngModel)]="searchcol" (change)="onChange1()">
                                                <option value="" selected disabled>Select Column</option>
                                                <option value="number">DMR Number</option>
                                                <option value="grn_number">Goods Receipt Number</option>
                                                <option value="purchase_order_number">Purchase Order Number</option>
                                              </select>
                                            </div>
                                          </div>

                                       <div class="col-4 search1">
                                        <input type="search" [placeholder]="Placeholder" class="inputstyles" [(ngModel)]="searchData" 
                                            (input)="searchNumberdata($event)"
                                                 />
                                            </div>
                                             <!-- <div class="col-md-12 search-table" *ngIf="searchcol === 'code'">
                                            <input type="search" placeholder="Search By DMR Number" class="inputstyles" [(ngModel)]="searchdmr" (keyup)="searchbydmr($event.target.value)"
                                            (input)="searchbmr($event.target.value ,searchcol)"
                                                 />
                                            </div> -->
                            
                                       
                                        
                                            <!-- <div class="col-md-12 search-table"  *ngIf="searchcol === 'vendor_name'">
                                                <input type="search" placeholder="Search By Goods Receipt no." class="inputstyles" [(ngModel)]="searchgr" (input)="searchbygrno($event.target.value)"
                                                    (keyup)="searchbygr(searchgr)"  />
                                            </div> -->
                            
                                        
                                     
                                            <!-- <div class="col-md-12 search-table" *ngIf="searchcol === 'vehicle_number'">
                                                <input type="search" placeholder="Search By Purchase Order Number" class="inputstyles" [(ngModel)]="searchpon" (input)="searchbypon($event.target.value)"
                                                    (keyup)="searchbyversion(searchpon)"  />
                                            </div> -->
                            
                                        <!-- </div> -->
                                    </div>
                                </mat-card-content>
                            </mat-card>
                            
                        
                            <div class="col-12 col-md-12 mt-5">
                                <div class="table-responsive mat-table-wrapper">
                                        <div class="example-table-container">
                                                <table mat-table [dataSource]="dataSourcemain" >
                                                    <ng-container matColumnDef="s.no">
                                                        <th mat-header-cell *matHeaderCellDef style="padding-left: 5px !important;" >
                                                            S.NO
                                                        </th>
                                                        <td mat-cell *matCellDef="let row; let i = index">
                                                            {{(i + 1 )
                                                            + ( masterpaginator?.pageIndex *
                                                            masterpaginator?.pageSize)
                                                            }}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DMR_Date">
                                                        <th mat-header-cell *matHeaderCellDef class="txtalignrt" class="dmrtxt">DMR Date
                                                        </th>
                                                        <td mat-cell *matCellDef="let row">
                                                            {{row.date}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DMR_Number">
                                                        <th mat-header-cell *matHeaderCellDef class="txtalign" >
                                                            DMR Number
                                                        </th>
                                                        <td mat-cell *matCellDef="let row">
                                                            {{row.number}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Goods_Receipt_Number">
                                                        <th mat-header-cell *matHeaderCellDef class="text-left">
                                                            Goods Receipt Number
                                                        </th>
                                                        <td mat-cell *matCellDef="let row; let i = index">
                                                            <mat-form-field class="example-full-width">
                                                                <input matInput placeholder="Enter text" value=""
                                                                    class="inputcolor" [ngClass]="{ 'update-pending': isFieldValid(row,'grn_number') }"
                                                                    (keydown.enter)="editDmrbulkupdate(row, i , row.grn_number , 'grn_number')" (keydown)="dataInDraft(row, 'grn_number')"
                                                                    name="receiptnumber"
                                                                    [(ngModel)]="row.grn_number">

                                                            </mat-form-field>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Goods_Receipt_Date">
                                                        <th mat-header-cell *matHeaderCellDef class="text-left">
                                                            Goods Receipt Date
                                                        </th>
                                                        <td mat-cell *matCellDef="let row; let i = index">
                                                            <div class="input-wrapper">

                                                            <mat-form-field class="example-full-width">
                                                                <input type="date" matInput placeholder="DD-MM-YYYY"
                                                                    class="inputcolor" [ngClass]="{ 'update-pending': isFieldValid(row, 'grn_date') }"
                                                                    (keydown.enter)="onDateSelected(row, i, 'grn_date')" 
                                                                    (keydown)="dataInDraft(row, 'grn_date')" 
                                                                    name="receiptnumber"
                                                                    [min]="formatDate1(row.date)" 
                                                                    [(ngModel)]="row.grn_date">
                                                                <mat-error *ngIf="!isGrnDateValid(row)">GRN date must be greater than or equal to the DMR date</mat-error>
                                                                
                                                            </mat-form-field>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ho_dispatch_ref">
                                                        <th mat-header-cell *matHeaderCellDef class="text-left">
                                                            Document Dispatch Referance
                                                        </th>
                                                        <td mat-cell *matCellDef="let row;let i = index">
                                                            <!-- {{row.ho_dispatch_date}} -->
                                                            <mat-form-field class="example-full-width">
                                                                <input matInput placeholder="Enter text"                 class="inputcolor"
                                                                 value="" [ngClass]="{ 'update-pending': isFieldValid(row,'ho_dispatch_ref') }" 
                                                                (keydown.enter)="onDateSelected(row, i, 'ho_dispatch_ref')" 
                                                                (keydown)="dataInDraft(row, 'ho_dispatch_ref')"
                                                                    name="dispachref"[(ngModel)]="row.ho_dispatch_ref" >
                                                            </mat-form-field>

                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Document_Dispatch_Date">
                                                        <th mat-header-cell *matHeaderCellDef class="text-left">
                                                            Document Dispatch Date
                                                        </th>
                                                        <td mat-cell *matCellDef="let row;let i = index">
                                                            <div class="input-wrapper">

                                                            <mat-form-field class="example-full-width">
                                                                <input type="date" matInput placeholder="DD-MM-YYYY" value="" [ngClass]="{ 'update-pending': isFieldValid(row,'ho_dispatch_date') }"
                                                                (keydown.enter)="onDateSelected(row, i, 'ho_dispatch_date')" 
                                                                (keydown)="dataInDraft(row, 'ho_dispatch_date')" 
                                                                [min]="formatDate1(row.grn_date)" 
                                                                    name="receiptnumber"
                                                                    [(ngModel)]="row.ho_dispatch_date">
                                  

                                                            </mat-form-field>
                                                            <span class="err_msg" *ngIf="dateValidation[i] && dateValidationFields[i].ho_dispatch_date">  Date must be in DD-MM-YYYY format.</span>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Ho_Receipt_Date">
                                                        <th mat-header-cell *matHeaderCellDef class="text-left">
                                                            Ho Receipt Date
                                                        </th>
                                                        <td mat-cell *matCellDef="let row;let i = index">
                                                            <div class="input-wrapper">

                                                            <mat-form-field class="example-full-width">
                                                                <input type="date" matInput placeholder="DD-MM-YYYY" value="" [ngClass]="{ 'update-pending': isFieldValid(row,'ho_received_date') }"
                                                                (keydown.enter)="onDateSelected(row, i, 'ho_received_date')" 
                                                                (keydown)="dataInDraft(row, 'ho_received_date')" 
                                                                    name="receiptnumber"
                                                                    [min]="formatDate1(row.ho_dispatch_date)" 

                                                                    [(ngModel)]="row.ho_received_date">
                                                            </mat-form-field>
                                                            <span class="err_msg" *ngIf="dateValidation[i] && dateValidationFields[i].ho_received_date">  Date must be in DD-MM-YYYY format.</span>
                                                        </div>
                                                        </td>

                                                    </ng-container>

                                                    <ng-container matColumnDef="purchase_order_date">
                                                        <th mat-header-cell *matHeaderCellDef class="text-left">
                                                            Purchase order date
                                                        </th>
                                                        <td mat-cell *matCellDef="let row;let i = index">
                                                            <div class="input-wrapper">
                                                            <mat-form-field class="example-full-width">
                                                                <input type="date" matInput placeholder="DD-MM-YYYY" value="" [ngClass]="{ 'update-pending': isFieldValid(row,'purchase_order_date') }"
                                                                (keydown.enter)="onDateSelected(row, i, 'purchase_order_date')" 
                                                                (keydown)="dataInDraft(row, 'purchase_order_date')" [max]="today"
                                                                    name="receiptnumber"
                                                                    [(ngModel)]="row.purchase_order_date">
                                                            </mat-form-field>
                                                            <span class="err_msg" *ngIf="dateValidation[i] && dateValidationFields[i].purchase_order_date">  Date must be in DD-MM-YYYY format.</span>
                                                            </div>
                                                        </td>

                                                    </ng-container>

                                                    <ng-container matColumnDef="purchase_order_number">
                                                        <th mat-header-cell *matHeaderCellDef class="text-left">
                                                            purchase order number
                                                        </th>
                                                        <td mat-cell *matCellDef="let row;let i = index">
                                                            <!-- {{row.ho_dispatch_date}} -->
                                                            <mat-form-field class="example-full-width">
                                                                <input matInput placeholder="Enter text" value="" [ngClass]="{ 'update-pending': isFieldValid(row,'purchase_order_number') }"
                                                                    (keydown.enter)="editDmrbulkupdate(row, i , row.purchase_order_number, 'purchase_order_number')"  (keydown)="dataInDraft(row, 'purchase_order_number')"
                                                                    name="dispachref"[(ngModel)]="row.purchase_order_number" >
                                                            </mat-form-field>

                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="F&A_Submission_Date">
                                                        <th mat-header-cell *matHeaderCellDef class="text-left">
                                                            F&A Submission Date
                                                        </th>
                                                        <td mat-cell *matCellDef="let row;let i = index">
                                                            <div class="input-wrapper">

                                                            <mat-form-field class="example-full-width">
                                                                <input type="date" matInput placeholder="DD-MM-YYYY" value="" [ngClass]="{ 'update-pending': isFieldValid(row,'fa_submitted_date') }"
                                                                    (keydown.enter)="onDateSelected(row, i, 'fa_submitted_date')"  (keydown)="dataInDraft(row, 'fa_submitted_date')"
                                                                    name="receiptnumber"
                                                                    [min]="formatDate1(row.ho_received_date)"  

                                                                    [(ngModel)]="row.fa_submitted_date" >
                                                            </mat-form-field>
                                                            <span class="err_msg" *ngIf="dateValidation[i] && dateValidationFields[i].fa_submitted_date">  Date must be in DD-MM-YYYY format.</span>
                                                            </div>

                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Submitted_To">
                                                        <th mat-header-cell *matHeaderCellDef class="text-left" >
                                                            Submitted to
                                                        </th>
                                                        <td mat-cell *matCellDef="let row;let i = index">
                                                            <mat-form-field class="example-full-width">
                                                                <input matInput placeholder="Enter text" value="" [ngClass]="{ 'update-pending': isFieldValid(row,'fa_submitted_to') }"
                                                                    (keydown.enter)="editDmrbulkupdate(row, i , row.fa_submitted_to, 'fa_submitted_to').trim()"  (keydown)="dataInDraft(row, 'fa_submitted_to')"
                                                                    name="receiptnumber" [(ngModel)]="row.fa_submitted_to" >
                                                            </mat-form-field>

                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="listcolumns; sticky: true">
                                                    </tr>
                                                    <tr mat-row *matRowDef="let row; columns: listcolumns"></tr>
                                                </table>
                                        
                                            <div class="text-center">
                                                <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                                    Loading...
                                                </h4>
                                                <h4 class="text-secondary m-3" *ngIf="totalRecordslog == 0">
                                                    No Records Found
                                                </h4>
                                            </div>
                                        </div>
                                
                                </div>
                                
                                <ng-container *ngIf="totalRecordslog > 0">
                                    <mat-paginator [length]="totalRecordslog" [pageSize]="pageSize"
                                                [pageSizeOptions]="[5, 10, 25, 50, 75, 100]" #masterpaginator
                                                (page)="onpageeventlog($event)">
                                    </mat-paginator>
                                </ng-container>
                            </div>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>