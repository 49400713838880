import { Injectable } from "@angular/core";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: "root",
})
export class KeycloakInit {}

export function initializeKeycloak(keycloak: KeycloakService) {
// console.log(environment);  
  // let clients: any = environment.clients;
  let origin = window.location.hostname; //  window.origin.split('.', 1)
  // let config={
  //   url: environment.keycloak_url,
  //   realm:environment.keycloak_realm,
  //   clientId: environment.keycloak_clientId,
  // }
  // let company = origin.includes(".") ? origin.split(".", 1)[0];
  let config1: any=environment.clients;
  console.log(config1, "@@@@@@@@");
  
  console.log(origin.split(".", 1)[0]);
  var companyName = origin.split(".", 1)[0];
  let config:any=config1[companyName]
  console.log(config1[companyName]);

  // testgeps
  // geps
  // 


  // debugger
  // console.log(origin);
  // console.log(origin.split(".", 1)[0]);
  // console.log(origin.split(".", 2)[1]);
  
  //alert(origin);
  // console.log(origin.includes(".") ? origin.split(".", 1)[0] : "");
  // alert(origin.includes(".") ? origin.split(".", 1)[0] : "");
  // let client = "";
  // let clientObj: any = {};
  // if (origin && origin.includes(".")) {
  //   let clientarr = origin.split(".", 1);
  //   client = clientarr.length ? clientarr[0] : "";
  // }
  // clientObj = clients[client];

  // if (clients[client] && Object.keys(clientObj).length) {
    // let config=  {
    //   url: "https://login.intellaire.com/",
    //   realm: origin.includes(".") ? origin.split(".", 1)[0] : "",
    //   clientId: "geps",
    // }
    // if(origin.split(".", 1)[0] === "testgeps"){
      // config=  {
      //   url: environment.keycloak_url,
      //   realm:environment.keycloak_realm,
      //   clientId: environment.keycloak_clientId,
      // }
    //   config=  {
    //     url: "https://gssfeed2.greenko.net/",
    //     realm:"greenkoIntranet",
    //     clientId: "geps",
    //   }
    // }
    // if(origin.split(".", 1)[0] === "mms-admin"){
    //   config=  {
    //     url: "https://login.intellaire.com/",
    //     realm:"mms-admin",
    //     clientId: "geps",
    //   }
    // }
    // if (origin.split(".", 1)[0] === "gepsv2"){
    //   config=  {
    //     url: "https://login.greenko.net/",
    //     realm:"greenkoIntranet",
    //     clientId: "geps",
    //   }
    // }
    // if (origin.split(".", 1)[0] === "dms" && origin.split(".", 2)[1] === "amgreen"){
    //   config=  {
    //     url: "https://login.amgreen.com/",
    //     realm:"amgreen",
    //     clientId: "geps",
    //   }
    // }
  return () =>
    keycloak.init({
      config,
      initOptions: {
        onLoad: "login-required",
        // onLoad: "check-sso",
        checkLoginIframe: false,
        silentCheckSsoRedirectUri: "/assets/silent-check-sso.html",
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Token',
      bearerExcludedUrls: ["login"],
    });
  // }
  // else {
  //   return () =>
  //     keycloak.init({
  //       config: {
  //         url: "https://gee.greenko.net/",
  //         realm: "Greenko",
  //         clientId: "geps",
  //       },
  //       initOptions: {
  //         onLoad: "login-required",
  //         // onLoad: "check-sso",
  //         checkLoginIframe: false,
  //         silentCheckSsoRedirectUri: "/assets/silent-check-sso.html",
  //       },
  //       enableBearerInterceptor: false,
  //       // bearerExcludedUrls: ["profile/getQrCode"],
  //       // shouldUpdateToken() {
  //       //   keycloak.keycloakEvents$.subscribe({
  //       //     next(event) {
  //       //       if (event.type == KeycloakEventType.OnTokenExpired) {
  //       //         return keycloak.updateToken(180);
  //       //       } else {
  //       //         return false;
  //       //       }
  //       //     },
  //       //   });
  //       //   let isExpired = keycloak.isTokenExpired(1);
  //       //   if (isExpired) {
  //       //     return true;
  //       //   } else {
  //       //     return false;
  //       //   }
  //       // },
  //     });
  // }
}