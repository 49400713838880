import { Component, ElementRef, EventEmitter, Injector, OnInit, Output, ViewChild,NgZone } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomerService } from 'src/app/services/customer.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Overlay } from '@angular/cdk/overlay';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AlertCallsService } from 'src/app/auth/alert-calls.service';
import { I } from '@angular/cdk/keycodes';
import { PrintdmrComponent } from '../printdmr/printdmr.component';
import { NavigationExtras, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { post } from 'jquery';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { AuthService } from "src/app/auth/auth.service";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { sum } from 'lodash';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { KeycloakService } from 'keycloak-angular';
import { MatSort } from '@angular/material/sort';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';



@Component({
  selector: 'app-materialreceipt',
  templateUrl: './materialreceipt.component.html',
  styleUrls: ['./materialreceipt.component.scss']
})
export class MaterialreceiptComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('masterpaginator') masterpaginator: MatPaginator;
  @Output() page = new EventEmitter<PageEvent>();
  @ViewChild('deletefileModel') deletefileModel: ElementRef;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger!: MatAutocompleteTrigger;
  @ViewChild('materialInput') materialInput!: ElementRef<HTMLInputElement>;
  goTo: any;
  pageNumbers: any = []
  displayedColumns: any[] = [
    'action',
    'sno',
    'material_code',
    'material_description',
    'unit_of_measurement',
    'storage_location',
    'valution_type',
    'invoice_quantity',
    'received_quantity',
    'shortage_quantity',
    'excess_quantity',
    'rejected_quantity',
    'unit_price',
    'discount_percent',
    'discount_value',
    'basic_price',
    'tax_description',
    'tax_percent',
    'tax_value',
    'freight_split',
    'other_charges_split',
    'other_tax_split',
    'total_price'


  ];
  displayedColumns1: any[] = [
    'sno',
    'DMRNumber',
    'Date',
    'ProjectName',
    'porNumber',
    'VendorName',
    'InvoiceNumber',
    'count',
    'dmrvalue',
    'senttoqc',
    'directgrn',
    // 'ActiveStatus',
    'action'
  ];
  displayedColumns1pnd: any[] = [
    'sno',
    'qc_date',
    'DMRNumber',
    'dmr_date',
    'po_number',
    // 'date',
    'company_name',
    'VendorName',
    'InvoiceNumber',
    'count',
    // 'action'
    // 'ActiveStatus',
    // 'action'

  ];
  displayedColumnsgrnpnd: any[] = [
    'sno',
    'qc_sent_date',
    'dmr_number',
    'dmr_date',
    'qc_number',
    'qc_date',
    'qc_release_date',
    'purchase_order_number',
    'company_name',
    'vendor_name',
    'count',
    // 'action'

  ];
  model: any = {
    excsqty: 0, invqty: 0, recqty: 0, shrtqty: 0, unitprice: 0, dispercentage: 0, disvalue: 0,
    basicprice: 0, taxpercentage: 0, taxvalue: 0,
  };
  model1: any = {};
  model2: any = {}
  editmodel: any = {}
  saveddataarray: any[] = []
  dataSource = new MatTableDataSource();
  dataSource1 = new MatTableDataSource()
  dataSourcemain = new MatTableDataSource();
  dataSourcemainpnd = new MatTableDataSource();
  dataSourcemaingrnpnd = new MatTableDataSource();
  valuefrminv: any = 0
  valuefrmrec: any = 0
  unitValue: any = 0
  discValue: any = 0
  taxPercent: any = 0
  masterData: any;
  taskObj: any = {};
  taxData: any;
  taskImages$: any = [];
  totalmasterRecords: any[]=[];
  resImg:any;
  storageloc:any[]=[];
  companyData: any;
  vendorData: any;
  transporterName: any;
  vehicleNum: any;
  imageslength: any;
  basicFreight: any = 0
  freightPercentage: any = 0
  freightPercentage1: any = 0
  storageData: any;
  selectedPrintOption: any;
  selectedPrintOptions: string;
  matCode: any[] = [];
  matName: any[] = []
  UOM: any[] = [];
  storageLocation: any[] = []
  valuationType: any[] = []
  InvoiceQuantity: any[] = []
  RecQTY: any[] = []
  RejQTY: any[] = []
  shrQTY: any[] = []
  excsQTY: any[] = []
  unitPrice: any[] = []
  discPERCENT: any[] = []
  discVALUE: any[] = []
  basicPRICE: any[] = []
  taxDESC: any[] = []
  taxPERCEN: any[] = []
  taxVALUE: any[] = []
  POID: any[] = []
  otherTAXSPLIT: any[] = []
  SNO: any[] = []
  freightSPLIT: any[] = []
  othertaxSPLIT: any[] = []
  otherchargesSPLIT: any = []
  totalPRICE: any[] = []
  addedTableData: any = true;
  savedTableData: any = false;
  totalRecords: any = 0
  reaData: boolean;
  loadingRecords: any = false;
  pageIndex: any = 1;
  pageIndex1: any = 1;
  pageSize: any = 10;
  logpagesize: any=10;
  deleteNumber: any;
  model2matcode: any;
  model2matdes: any;
  valuefrminvedit: any = 0
  valuefrmrecedit: any = 0
  unitValueedit: any = 0
  discValueedit: any = 0
  editedtaxdescription: any;
  selectedIndex = -1;
  SUM: any = 0
  editabledata: any;
  editDATAA: any = false;
  materialCODE: any;
  materialNAME: any;
  systemref: any;
  NUMBER: any;
  basicFreight1: any = 0
  selectedtransporter: any;
  selectedvehiclenum: any;
  selectedvehiclenum1: any;
  selectedtransporter1: any;
  selectedmaterial: any;
  selectedstorage: any;
  selectedstorage1: any;
  selectedmaterial1: any;
  copieddmr: any;
  tabledata: any;
  DMRDATA: any;
  selecteddmr: any;
  valutiondata: any;
  ponumber: any;
  PODATA: any;
  selectedponumber: any;
  poiddata: any[] = []
  invoiceqty: any[] = []
  fileUploadUrls: any[] = []
  fileUploadUrlsdmr: any[] = []
  createNewFile: any = {}
  dmrnumber: any;
  filedata: any=[];
  filedataPO: any[] = [];
  imageUrl = environment.base_url;
  deleteid: any;
  dialogRef: any = null;
  generateddmr: any;
  filenamearray: any[] = []
  selectedfiles: any[] = []
  editednumber: any;
  viewnumber: any;
  demo1TabIndex: any = 0;
  btn: any = "Save"
  editdataa: any;
  editeddata:boolean=false;
  logdata: any;
  deletemodel: any = {}
  editModel: any = {}
  selectedgen: any;
  GENDATA: any;
  selectedvendor: any;
  searchData: any;
  columnname: any="";
  projects: any;
  RGPDATA: any;
  rgpnumber: any;
  selectedrgpnumber: any;
  matDesc: string;
  matcodedata: any;
  poiddata1: any[] = [];
  // POID1: any[]=[];
  reaDatalog: boolean;
  totalRecordslog: any;
  dataSourcemainlog = new MatTableDataSource();
  selectedcompany: any;
  viewData: boolean = false;
  logsearchData: any;
  dmrvalue: number = 0;
  countdmr: number = 0;
  panelOpenState: boolean = false;
  intialData: boolean = false;
  editData: boolean = false;
  editpanelOpenState: boolean = false;
  reaDatapnd: boolean;
  totalRecordspnd: any;
  searchDatapnd: any;
  columnnamepnd: any="";
  goodsreceptdata: any;
  totalRecordsgrnpnd: any;
  columnnamegrnpnd: any="";
  searchDatagrnpnd: any;
  reaDatagrnpnd: boolean;
  activityLogDownload: any = [];
  today: any
  txnId: any;
  selectedPO: any;
  dmrno: any;
  matrow: any;
  filenamearray1: any[]=[];
  hidebenumber: boolean =false;
  inputValue:any;
  constructor(private dialog: MatDialog, private custservice: CustomerService,
    private snackbar: MatSnackBar, public overlay: Overlay,
    private alertcall: AlertCallsService, private router: Router,
    private authService: AuthService,
    private keycloackService: KeycloakService,
    private ngZone: NgZone,
    private injector: Injector,private sanitizer: DomSanitizer) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
  }

  public config = {
    printMode: 'template',
    popupProperties: "window.open",
    stylesheets: [{ rel: 'stylesheet', href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' },

   ],
  };


  ngOnInit(): void {
    let origin = window.location.hostname;
    let client = "";
    var companyName:any = origin.split(".", 1)[0];
    if (origin && origin.includes(".")) {
        let clientarr = origin.split(".", 1);
        client = clientarr.length ? clientarr[0] : "";
      }
      if (client && client == 'gam') {
        this.hidebenumber=true
      }else{
        this.hidebenumber=false
      }


    this.today = moment(new Date()).format("YYYY-MM-DD")
    // this.projects = this.authService.currentuserProjectDetails;
    this.projects = this.keycloackService.getKeycloakInstance().tokenParsed
    this.model1.dateee = moment(new Date()).format("YYYY-MM-DD")
    this.dataSourcemain.sort = this.sort;
    this.dataSourcemainlog.sort = this.sort;

    // this.model1.podate = moment(new Date()).format("YYYY-MM-DD")
    // this.model1.gedate = moment(new Date()).format("YYYY-MM-DD")
    // this.model1.invdate = moment(new Date()).format("YYYY-MM-DD")
    // this.model1.dcdate = moment(new Date()).format("YYYY-MM-DD")
    // this.model1.irdate = moment(new Date()).format("YYYY-MM-DD")
    // this.model1.qualitycheck = false;
    // this.model1.directgrn=false;
    this.getTransactionId();

    this.getmasterdata()
    this.getTaxlistdata();
    this.getcompanydata()
    this.getvendordata()
    this.getTransporterdata()
    this.getvehiclenumdata()
    this.getstoragelocData()
    this.getData()
    this.getdatapnd();
    this.getgoodslistpnd()
    this.getdropdownData()
    this.getvalutionData()
    this.getgateentrynumberdata()

  }
  formatDate1(date: string): string {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
  clearInput(event: MouseEvent, inputElement: HTMLInputElement): void {
    event.stopPropagation();

    this.model.matcode = '';

    this.ngZone.run(() => {
      this.getmaterialDATA({ target: { value: '' } });
      setTimeout(() => {
        inputElement.focus();
        if (this.matAutocompleteTrigger) {
          this.matAutocompleteTrigger.openPanel();
          console.log('Autocomplete panel opened');
        } else {
          console.error('matAutocompleteTrigger is not defined');
        }
      }, 0);
    });
  }
  clearInput1(event: MouseEvent, inputElement: HTMLInputElement): void {
    event.stopPropagation();

    this.model2.matcode = '';

    this.ngZone.run(() => {
      this.getmaterialDATA1({ target: { value: '' } });
      setTimeout(() => {
        inputElement.focus();
        if (this.matAutocompleteTrigger) {
          this.matAutocompleteTrigger.openPanel();
          console.log('Autocomplete panel opened');
        } else {
          console.error('matAutocompleteTrigger is not defined');
        }
      }, 0);
    });
  }
  getTransactionId() {
    this.txnId = ""
    this.custservice.getTransactionId().subscribe((res: any) => {
      if (res) {
        // console.log(res.txn_id);
        this.txnId = res.txn_id
      }
    })
  }

  openfileuploadmodel(data: any, row1: any) {
    this.dialog.open(data, {
      width: '800px'
    })
    this.dmrnumber = row1.number
    this.getexistingfiles()
  }
  getexistingfiles() {
    let params = new HttpParams();
    params = new HttpParams()
      .set("document_number", this.dmrnumber,)
      .set("document_type", "Daily Material Receipt")
    
    this.custservice.getexistingfies(params).subscribe((res: any) => {
      if (res && res['status_code'] == '200') {
        this.filedata = res.data
        this.createNewFile.fileName = ''
      } else {
        this.filedata = []
        console.log(this.filedata);
      }
    })
  }
  viewDoc(file: any) {
    const url = file.file_path;
    console.log(url);

    window.open(url, '_blank');
  }
  deleterowfile(row: any, data: any) {
    this.deleteid = data.id
    this.dialogRef = this.dialog.open(row, {
      width: "400px"
    })
  }
  deleteexistingfile() {
    let params = new HttpParams()
    params = new HttpParams()
      .set("document_number", this.dmrnumber,)
      .set("document_type", "Daily Material Receipt")
      .set("id", this.deleteid)
    this.custservice.deletefiles(params).subscribe((res: any) => {
      if (res && res['status_code'] == '200') {
        this.alertcall.showSuccess("Accepted", "File Deleted Successfully")
        this.getexistingfiles()
        this.dialogRef.close()
      } else {
        this.alertcall.showWarning("Error", res['message'])



      }
    })
  }
  uploadWbsFile(fileInput: any) {
    if (
      fileInput &&
      fileInput.target &&
      fileInput.target.files &&
      fileInput.target.files.length > 0
    ) {
      this.fileUploadUrls = fileInput.target.files;
      this.createNewFile.fileName = fileInput.target.files[0].name;
  
      const postData = new FormData();
      postData.append("document_type", "Daily Material Receipt");
    postData.append("document_number", this.dmrnumber);
  
      let hasDuplicates = false; 
  
      for (const file of this.fileUploadUrls) {
        if (this.filedata && this.filedata.length > 0 && this.isFileSelected1(file.name)) {
          hasDuplicates = true;
          break; 
        } else {
          postData.append("doc", file);
        }
      }
  
      if (hasDuplicates) {
        this.alertcall.showWarning('Alert', 'Some files are already uploaded');
      } else {
        this.custservice.addfileupload(postData).subscribe((res: any) => {
          if (res && res['status_code'] == '200') {
            this.alertcall.showSuccess("Accepted", res['message'])
            this.getexistingfiles()
            this.fileUploadUrls = []
          } else {
            this.alertcall.showWarning("Error", res['message'])
          }
          fileInput.target.value = '';
        });
      }
    }
  }
  isFileSelected1(selectedFilePath: string): boolean {
    return this.selectedfiles.some((file: any) => file.name === selectedFilePath);
  }
  
  uploaddmrfiles(fileInput: any) {
    if (
      fileInput &&
      fileInput.target &&
      fileInput.target.files &&
      fileInput.target.files.length > 0
    ) {
      this.fileUploadUrlsdmr = fileInput.target.files;
      this.createNewFile.fileName = fileInput.target.files[0].name;
      console.log(this.fileUploadUrlsdmr);
      for (const file of this.fileUploadUrlsdmr) {
        const existingFile = this.selectedfiles.find(selectedFile => selectedFile.name === file.name);
        if(!existingFile){
        this.filenamearray.push(file.name)
        this.selectedfiles.push(file)
        this.alertcall.showSuccess("Accepted", "File added successfully")
      }
      else if(existingFile){
        this.alertcall.showWarning("Error", 'File name already exist')
      }
    }
      console.log(this.filenamearray);
        

    }

  }

  uploadedselctedfiles() {
    const postData = new FormData();
    postData.append("document_type", "Daily Material Receipt");
    postData.append("document_number", this.generateddmr);
    for (const file of this.selectedfiles) {
      postData.append("doc", file)
    }


    this.custservice.addfileupload(postData).subscribe((res: any) => {
      if (res && res['status_code'] == '200') {
        this.fileUploadUrlsdmr = []
        this.selectedfiles = []
        this.filenamearray = []
      } else {

      }
    })
  }
  getvalutionData() {
    let obj = {
      "lmt": 100000,
      'pid': 1,
      "command": "lst",
      "key": ""
    }
    this.custservice.getvalutiondata(obj).subscribe((res: any) => {
      this.valutiondata = res.data


    })
  }
  clearpage() {
    this.saveddataarray = []
    this.dataSource.data = []
    this.editabledata = null
    this.editmodel = {}
    this.editDATAA = false;
    this.getTransactionId();
  }
  clearpage1() {
    this.getTransactionId();
    this.saveddataarray = []
    this.dataSource.data = []
    this.countdmr = 0
    this.model1 = {}
    this.model = {}
    this.copieddmr = ""
    this.dmrvalue = 0;
    this.btn="Save"
    this.filenamearray =[]
    this.selectedfiles = []
    this.editeddata = false;
    this.editednumber = ""
  }
  copydmr(data: any) {
    this.POID = []
    this.copieddmr=""
    this.dialog.open(data, {
      width: '600px'
    })
  }
  submitdmrno(fr: any) {
    this.POID = []
    this.model1 = {}
    let obj = {
      "command": "mat",
      "key": this.copieddmr,
      "field": "number"
    }
    this.custservice.editmaterialreceiptdata(obj).subscribe((res: any) => {
      this.viewData = false
      this.saveddataarray = res.data
      res.data.forEach((ele: any) => {
        this.POID.push(ele.po_id)
      })
      this.dataSource.data = this.saveddataarray
      this.tabledata = res.data[0]
      this.model1.companyname = this.tabledata.company_name
      this.model1.dateee = moment(this.tabledata.date).format("YYYY-MM-DD")
      this.model1.ponumber = this.tabledata.purchase_order_number
      this.model1.podate = moment(this.tabledata.purchase_order_date).format("YYYY-MM-DD")
      this.model1.genumber = this.tabledata.gate_entry_number
      this.model1.gedate = moment(this.tabledata.gate_entry_date).format("YYYY-MM-DD")
      this.model1.invoicenumber = this.tabledata.invoice_number
      this.model1.invdate = moment(this.tabledata.invoice_date).format("YYYY-MM-DD")
      this.model1.dcnumber = this.tabledata.dc_number
      this.model1.dcdate = moment(this.tabledata.dc_date).format("YYYY-MM-DD")
      this.model1.vendorname = this.tabledata.vendor_name
      this.model1.packdetails = this.tabledata.packing_details
      this.model1.trsname = this.tabledata.transporter_name
      this.model1.vnumber = this.tabledata.vehicle_number
      this.model1.irnumber = this.tabledata.lr_number
      this.model1.irdate = moment(this.tabledata.lr_date).format("YYYY-MM-DD")
      this.model1.basicfreight = this.tabledata.basic_freight
      this.model1.frtaxdescription = this.tabledata.freight_tax_description
      this.model1.frtaxpercentage = this.tabledata.freight_tax_percent
      this.model1.frtaxvalue = this.tabledata.freight_tax_value
      this.model1.totalfreight = this.tabledata.total_freight
      this.model1.othercharges = this.tabledata.other_charges_description
      this.model1.otherchargesval = this.tabledata.other_charges_value
      this.model1.othertaxdescription = this.tabledata.other_tax_description
      this.model1.othertaxper = this.tabledata.other_tax_percent
      this.model1.othertaxvalue = this.tabledata.other_tax_value
      // this.model1.qualitycheck = this.tabledata.quality_check
      this.model1.comments = this.tabledata.comments
      this.model1.waybillno = this.tabledata.waybillno
      this.model1.grn_number = this.tabledata.goods_receipt_number,
        this.model1.grdate = this.tabledata.goods_receipt_date,
        this.model1.doc_reference = this.tabledata.document_dispatch_reference,
        this.model1.doc_date = this.tabledata.document_dispatch_date,
        this.model1.boe_number = this.tabledata.boe_number,
        this.model1.boe_date = this.tabledata.boe_date,
        this.model1.netweight = this.tabledata.net_weight
      // this.model1.directgrn = this.tabledata.direct_grn,
      this.SUM = 0
      this.saveddataarray.forEach((el: any) => {
        this.SUM += (el.basicprice || el.basic_price)
      })
      this.dmrvalue=0
      this.countdmr=0
      this.saveddataarray.forEach((ele: any,index) => {
        this.countdmr =  index+1
        let value=0
      value=((+(ele.basicprice || ele.basic_price) + (ele.taxvalue || ele.tax_value) + (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.totalfreight || 0)) +
      (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.otherchargesval || 0)) +
      (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.othertaxvalue || 0))) || 0)
      this.dmrvalue+=value;
      console.log('value :' + value + 'dmrvalue :' + this.dmrvalue); 
      })
      this.dialog.closeAll()
    })
  }
  deleterow(index: any) {
    this.saveddataarray.splice(index, 1);
    this.countdmr = this.saveddataarray.length
    this.SUM = 0;
    this.saveddataarray.forEach((el: any) => {
      this.SUM += (el.basicprice || el.basic_price)
    })
    this.dmrvalue = 0;
    this.saveddataarray.forEach((ele: any,index) => { 
      let value=0
      value=((+(ele.basicprice || ele.basic_price) + (ele.taxvalue || ele.tax_value) + (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.totalfreight || 0)) +
      (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.otherchargesval || 0)) +
      (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.othertaxvalue || 0))) || 0)
      this.dmrvalue+=value;
      console.log('value :' + value + 'dmrvalue :' + this.dmrvalue); 
    })
    this.POID.splice(index,1);
    this.dataSource.data = this.saveddataarray
  }

  onpageevent(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if(!this.searchData){
      this.getData()
    }
    else{
      this.search()
    }
  }
  deleteItem(rw: any, data: any) {

    this.dialog.open(data, {
      width: '400px',
      scrollStrategy: new NoopScrollStrategy()
    })
    this.deleteNumber = rw.number
    this.deletemodel.reason = "";
  }

  deleteFile() {
    let obj = {
      "command": "del",
      "number": this.deleteNumber,
      "reason": this.deletemodel.reason,
      "txn_id": this.txnId,
    }
    this.custservice.deletematerialreceiptdata(obj).subscribe((res: any) => {
      if (res && res['status_code'] == "200") {
        this.alertcall.showSuccess('Accepted', res['message']);

        this.dialog.closeAll()
        this.deletemodel.reason = ""
        this.getTransactionId();
        this.getData()
        this.getdatapnd();
        this.getgoodslistpnd()
      } else {
        this.alertcall.showSuccess('Accepted', res['message']);
      }
    })
  }
  

  getData() {
    this.pageNumbers = []
    let obj = {
      "command": "lst",
      "lmt": this.pageSize,
      "pid": this.pageIndex,
      "key": ""
    }
    this.custservice.getmaterialreceiptdata(obj).subscribe((res: any) => {
      this.reaData = false;
      this.totalRecords = res?.count;

      this.dataSourcemain.data = res.data;
      this.dataSourcemain.sort = this.sort;
      for (let i = 1; i <= Math.ceil(this.totalRecords / this.pageSize); i++) {
        this.pageNumbers.push(i);
      }
      this.goTo = this.pageIndex
      if (res.data.length == 0) {
        this.reaData = true
      }

    })
  }


  onpageeventpnd(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if(!this.searchDatapnd){
      this.getdatapnd();
    }else{
      this.searchpnd()
    }
  }
  getdatapnd() {
    let obj = {
      "command": "pnd",
      "pid": this.pageIndex,
      "lmt": this.pageSize,
    }
    this.custservice.getQualityCheck(obj).subscribe((res: any) => {
      this.reaDatapnd = false;
      this.totalRecordspnd = res?.count;
      this.dataSourcemainpnd.data = res.data
      this.dataSourcemainpnd.sort = this.sort;
      this.columnnamepnd = "";
      this.searchDatapnd = "";
      if (res.data.length == 0) {
        this.reaDatapnd = true
      }
    })
  }
  goToChange() {
    this.paginator.pageIndex = this.goTo - 1;
    const event: PageEvent = {
      length: this.paginator.length,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
    };
    this.paginator.page.next(event);
  }
  emitPageEvent(pageEvent: PageEvent) {
    this.paginator.page.next(pageEvent);
  }
  getdmrDATA(ev: any) {
    console.log(ev.target.value);
    this.selecteddmr = ev.target.value
    if (this.selecteddmr.length > 2) {
      this.getdropdownData()
    }
    if (!this.selecteddmr) {
      this.getdropdownData()
    }
  }
  getdropdownData() {
    let obj = {
      "command": "lst",
      "lmt": 100,
      "pid": 1,
      "key": this.selecteddmr
    }
    this.custservice.getmaterialreceiptdata(obj).subscribe((res: any) => {

      this.DMRDATA = res.data


    })
  }
  editsaveddata(data: any, dialog: any) {
    this.dialog.open(dialog, {
      width: "400px"
    })
    this.editednumber = data.number
    this.editModel.reason = "";
  }
  saveeditreason() {
    this.POID = []
    let obj = {
      "command": "mat",
      "key": this.editednumber,
      "field": "number"
    }
    this.custservice.editmaterialreceiptdata(obj).subscribe((res: any) => {
      this.viewData = false
      this.dialog.closeAll()
      this.saveddataarray = res.data
      res.data.forEach((ele: any) => {
        this.POID.push(ele.po_id)
      })
      this.dataSource.data = this.saveddataarray
      this.editabledata = res.data[0]
      this.model1.companyname = this.editabledata.company_name
      this.model1.dateee = moment(this.editabledata.date).format("YYYY-MM-DD")
      this.model1.ponumber = this.editabledata.purchase_order_number
      this.model1.podate = moment(this.editabledata.purchase_order_date).format("YYYY-MM-DD")
      this.model1.genumber = this.editabledata.gate_entry_number
      this.model1.gedate = moment(this.editabledata.gate_entry_date).format("YYYY-MM-DD")
      this.model1.invoicenumber = this.editabledata.invoice_number
      this.model1.invdate = moment(this.editabledata.invoice_date).format("YYYY-MM-DD")
      this.model1.dcnumber = this.editabledata.dc_number
      this.model1.dcdate = moment(this.editabledata.dc_date).format("YYYY-MM-DD")
      this.model1.vendorname = this.editabledata.vendor_name
      this.model1.packdetails = this.editabledata.packing_details
      this.model1.trsname = this.editabledata.transporter_name
      this.model1.vnumber = this.editabledata.vehicle_number
      this.model1.irnumber = this.editabledata.lr_number
      this.model1.irdate = moment(this.editabledata.lr_date).format("YYYY-MM-DD")
      this.model1.basicfreight = this.editabledata.basic_freight
      this.model1.frtaxdescription = this.editabledata.freight_tax_description
      this.model1.frtaxpercentage = this.editabledata.freight_tax_percent
      this.model1.frtaxvalue = this.editabledata.freight_tax_value
      this.model1.totalfreight = this.editabledata.total_freight
      this.model1.othercharges = this.editabledata.other_charges_description
      this.model1.otherchargesval = this.editabledata.other_charges_value
      this.model1.othertaxdescription = this.editabledata.other_tax_description
      this.model1.othertaxper = this.editabledata.other_tax_percent
      this.model1.othertaxvalue = this.editabledata.other_tax_value
      this.model1.netweight = this.editabledata.net_weight
      this.model1.comments = this.editabledata.comments
      this.model1.waybillno = this.editabledata.way_bill_number
      this.model1.grn_number=this.editabledata.grn_number,
      this.model1.grdate=moment(this.editabledata.grn_date).format("YYYY-MM-DD"),
      this.model1.doc_reference=this.editabledata.ho_dispatch_ref,
      this.model1.doc_date=moment(this.editabledata.ho_dispatch_date).format("YYYY-MM-DD"),
      this.model1.boe_number=this.editabledata.boe_number,
      this.model1.boe_date=moment(this.editabledata.boe_date).format("YYYY-MM-DD"),
      this.model1.ho_received_date = this.editabledata.ho_received_date?moment(this.editabledata.ho_received_date).format("YYYY-MM-DD"):'NA',
        this.model1.fa_submitted_date= this.editabledata.fa_submitted_date?moment(this.editabledata.fa_submitted_date).format("YYYY-MM-DD"):'NA',
        this.model1.fa_submitted_to=this.editabledata.fa_submitted_to !== null ? this.editabledata.fa_submitted_to :'NA',
      this.SUM = 0
      this.saveddataarray.forEach((el: any) => {
        this.SUM += (el.basicprice || el.basic_price)
      })
      this.dmrvalue=0
      this.countdmr=0
      this.saveddataarray.forEach((ele: any,index) => {
        this.countdmr =  index+1
        let value=0
        value=((+(ele.basicprice || ele.basic_price) + (ele.taxvalue || ele.tax_value) + (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.totalfreight || 0)) +
        (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.otherchargesval || 0)) +
        (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.othertaxvalue || 0))) || 0)
        this.dmrvalue+=value;
        console.log('value :' + value + 'dmrvalue :' + this.dmrvalue); 
      })
      this.editeddata = true;
      this.demo1TabIndex = 0;
      this.btn = "Update"
    })

  }
  //   gettotalvalue(){
  //     console.log(this.saveddataarray);

  //   this.saveddataarray.forEach((ele:any)=>{

  //     // this.freightSPLIT.push(((ele.basicprice|| ele.basic_price)/this.SUM)*this.editmodel.totalfreight)
  //     // this.otherchargesSPLIT.push(((ele.basicprice|| ele.basic_price)/this.SUM)*this.editmodel.otherchargesval)
  //     // this.othertaxSPLIT.push(((ele.basicprice|| ele.basic_price)/this.SUM)*this.editmodel.othertaxvalue)
  //     this.totalPRICE.push((+(ele.basicprice|| ele.basic_price)+(ele.taxvalue|| ele.tax_value)+(((ele.basicprice|| ele.basic_price)/this.SUM)*this.editmodel.totalfreight )+
  //     (((ele.basicprice|| ele.basic_price)/this.SUM)*this.editmodel.otherchargesval)+
  //     (((ele.basicprice|| ele.basic_price)/this.SUM)*this.editmodel.othertaxvalue)))
  //   })
  //   console.log(this.totalPRICE);

  // }
  saveupdateddata() {
    console.log(this.saveddataarray);

    this.saveddataarray.forEach((ele: any, index) => {
      this.SNO.push(index + 1)
      this.matCode.push(ele.material_code || this.materialCODE)
      this.matName.push(ele.material_description || this.materialNAME)
      this.UOM.push(ele.unit_of_measurment || ele.uom)
      this.storageLocation.push(ele.storage_location || ele.storagelocation)
      this.valuationType.push(ele.valuation_type || ele.valuationType)
      this.InvoiceQuantity.push(Number(ele.invoice_quantity) || Number(ele.invqty))
      this.RecQTY.push(Number(ele.received_quantity) || Number(ele.recqty))
      this.shrQTY.push(ele.shortage_quantity || ele.shrtqty)
      this.excsQTY.push(ele.excess_quantity || ele.excsqty)
      this.RejQTY.push(Number(ele.rejqty) || Number(ele.rejected_quantity))
      this.unitPrice.push(Number(ele.unit_price) || Number(ele.unitprice))
      this.discPERCENT.push(Number(ele.discount_percent) || Number(ele.dispercentage))
      this.discVALUE.push(ele.discount_value || ele.disvalue)
      this.basicPRICE.push(ele.basic_price || ele.basicprice)
      this.taxDESC.push(ele.tax_description || ele.taxdescription)
      this.taxPERCEN.push(ele.tax_percent || ele.taxpercentage)
      this.taxVALUE.push(ele.tax_value || ele.taxvalue)
      // this.otherTAXSPLIT.push(ele.other_tax_split)

    })

    this.saveddataarray.forEach((ele: any) => {

      this.freightSPLIT.push(((ele.basicprice || ele.basic_price) / this.SUM) * this.editmodel.totalfreight)
      this.otherchargesSPLIT.push(((ele.basicprice || ele.basic_price) / this.SUM) * this.editmodel.otherchargesval)
      this.othertaxSPLIT.push(((ele.basicprice || ele.basic_price) / this.SUM) * this.editmodel.othertaxvalue)
      this.totalPRICE.push((+(ele.basicprice || ele.basic_price) + (ele.taxvalue || ele.tax_value) + (((ele.basicprice || ele.basic_price) / this.SUM) * this.editmodel.totalfreight) +
        (((ele.basicprice || ele.basic_price) / this.SUM) * this.editmodel.otherchargesval) +
        (((ele.basicprice || ele.basic_price) / this.SUM) * this.editmodel.othertaxvalue)))
    })

    let obj = {
      // "comments": this.editmodel.comments,

      "date": moment(this.editmodel.dateee).format("YYYY-MM-DD"),
      "purchase_order_number": this.editmodel.ponumber,

      "purchase_order_date": moment(this.editmodel.podate).format("YYYY-MM-DD"),

      "company_name": this.editmodel.companyname,

      "vendor_name": this.editmodel.vendorname,

      "invoice_number": this.editmodel.invoicenumber,

      "invoice_date": moment(this.editmodel.invdate).format("YYYY-MM-DD"),

      "dc_number": this.editmodel.dcnumber,

      "dc_date": moment(this.editmodel.dcdate).format("YYYY-MM-DD"),

      "packing_details": this.editmodel.packdetails,

      "gate_entry_number": this.editmodel.genumber,

      "gate_entry_date": moment(this.editmodel.gedate).format("YYYY-MM-DD"),

      "transporter_name": this.editmodel.trsname,

      "vehicle_number": this.editmodel.vnumber,

      "lr_number": this.editmodel.irnumber,

      "lr_date": moment(this.editmodel.irdate).format("YYYY-MM-DD"),
      "line_item": this.SNO,

      "material_code": this.matCode,

      "material_description": this.matName,

      "unit_of_measurment": this.UOM,

      "storage_location": this.storageLocation,

      "invoice_quantity": this.InvoiceQuantity,

      "received_quantity": this.RecQTY,

      "shortage_quantity": this.shrQTY,

      "excess_quantity": this.excsQTY,

      "rejected_quantity": this.RejQTY,

      "unit_price": this.unitPrice,

      "discount_percent": this.discPERCENT,

      "discount_value": this.discVALUE,
      "basic_price": this.basicPRICE,

      "tax_description": this.taxDESC,

      "tax_percent": this.taxPERCEN,

      "tax_value": this.taxVALUE,

      "freight_split": this.freightSPLIT,

      "basic_freight": Number(this.editmodel.basicfreight),

      "freight_tax_description": this.editmodel.frtaxdescription,

      "freight_tax_percent": Number(this.editmodel.frtaxpercentage),

      "freight_tax_value": this.editmodel.frtaxvalue,

      "total_freight": this.editmodel.totalfreight,

      "other_charges_description": this.editmodel.othercharges,

      "other_charges_value": Number(this.editmodel.otherchargesval),

      "other_charges_split": this.otherchargesSPLIT,

      "other_tax_description": this.editmodel.othertaxdescription,

      "other_tax_percent": Number(this.editmodel.othertaxper),

      "other_tax_value": Number(this.editmodel.othertaxvalue),

      "other_tax_split": this.othertaxSPLIT,

      "total_price": this.totalPRICE,

      // "quality_check": this.editmodel.qualitycheck,
      "way_bill_number": this.editmodel.waybillno,
      "valution_type": this.valuationType,

      "command": "edt",

      "number": this.NUMBER
    }
    this.custservice.editmaterialreceiptdata(obj).subscribe((res: any) => {
      console.log(res);
      if (res && res['status_code'] == '200') {
        this.alertcall.showSuccess('Accepted', res['message']);
        // fr1.reset()
        this.editmodel = {}
        this.saveddataarray = []
        this.dataSource.data = []
        this.matCode = []
        this.SNO = []
        this.matCode = []
        this.matName = []
        this.UOM = []
        this.storageLocation = []
        this.InvoiceQuantity = []
        this.RecQTY = []
        this.RejQTY = []
        this.shrQTY = []
        this.excsQTY = []
        this.unitPrice = []
        this.discPERCENT = []
        this.discVALUE = []
        this.basicPRICE = []
        this.taxDESC = []
        this.taxPERCEN = []
        this.taxVALUE = []
        this.freightSPLIT = []
        this.otherchargesSPLIT = []
        this.othertaxSPLIT = []
        this.totalPRICE = []
        this.valuationType = []
        this.getData()
        this.getdatapnd();
        this.getgoodslistpnd()
      } else {
        this.alertcall.showWarning('Accepted', res['message']);
        this.matCode = []
        this.SNO = []
        this.matCode = []
        this.matName = []
        this.UOM = []
        this.storageLocation = []
        this.InvoiceQuantity = []
        this.RecQTY = []
        this.RejQTY = []
        this.shrQTY = []
        this.excsQTY = []
        this.unitPrice = []
        this.discPERCENT = []
        this.discVALUE = []
        this.basicPRICE = []
        this.taxDESC = []
        this.taxPERCEN = []
        this.taxVALUE = []
        this.freightSPLIT = []
        this.otherchargesSPLIT = []
        this.othertaxSPLIT = []
        this.totalPRICE = []
        this.valuationType = []
      }

    })
  }
  getstorageDATA(ev: any) {
    console.log(ev.target.value);
    this.selectedstorage = ev.target.value
    if (this.selectedstorage.length > 2) {
      this.getstoragelocData()
    }
  }
  getstorageDATA1(ev: any) {
    console.log(ev.target.value);
    this.selectedstorage1 = ev.target.value
    if (this.selectedstorage1.length > 2) {
      this.getstoragelocData()
    }
  }
  getstoragelocData() {
    let obj = {
      "command": "mat",
      "field": "storage_location",
      "key": this.selectedstorage || this.selectedstorage1
    }
    this.custservice.getmatstoragelocdata(obj).subscribe((res: any) => {
      this.storageData = res.data



    })
  }
  getvehiclenumDATA(ev: any) {
    console.log(ev.target.value);
    this.selectedvehiclenum = ev.target.value
    if (this.selectedvehiclenum.length > 2) {
      this.getvehiclenumdata()
    }
  }
  getvehiclenumDATA1(ev: any) {
    console.log(ev.target.value);
    this.selectedvehiclenum1 = ev.target.value
    if (this.selectedvehiclenum1.length > 2) {
      this.getvehiclenumdata()
    }
  }
  getvehiclenumdata() {
    let obj = {
      "command": "mat",
      "field": "vehicle_number",
      "key": this.selectedvehiclenum || this.selectedvehiclenum1,
    }
    this.custservice.getmatvehiclenumdata(obj).subscribe((res: any) => {
      this.vehicleNum = res.data
    })
  }
  gettransportDATA(ev: any) {
    console.log(ev.target.value);
    this.selectedtransporter = ev.target.value
    if (this.selectedtransporter.length > 2) {
      this.getTransporterdata()
    }
    if (!this.selectedtransporter) {
      this.getTransporterdata()
    }
  }
  gettransportDATA1(ev: any) {
    console.log(ev.target.value);
    this.selectedtransporter1 = ev.target.value
    if (this.selectedtransporter1.length > 2) {
      this.getTransporterdata()
    }
    if (!this.selectedtransporter1) {
      this.getTransporterdata()
    }
  }
  getTransporterdata() {

    let obj = {
      "command": "mat",
      "field": "transporter_name",
      "key": this.selectedtransporter || this.selectedtransporter1,

    }
    this.custservice.getmattransnamedata(obj).subscribe((res: any) => {
      this.transporterName = res.data
    })
  }
  getvendordropdowndata(ev: any) {
    console.log(ev.target.value,'@@@@@@@@@@@@@@@@@');
    this.selectedvendor = ev.target.value
    if (this.selectedvendor.length > 2) {
      this.getvendordata()
    }
    if (!this.selectedvendor) {
      this.getvendordata()
    }
  }
  getvendordata() {
    let obj = {
      "command": "lst",
      "lmt": 100,
      "pid": 1,
      "key": this.selectedvendor || "",
    }
    this.custservice.getvendormasterdata(obj).subscribe((res: any) => {
      this.vendorData = res.data
    })
  }

  selectedCompanyData(ev: any) {
    console.log(ev.target.value);
    this.selectedcompany = ev.target.value
    if (this.selectedcompany.length > 2) {
      this.getcompanydata()
    }
    if (!this.selectedcompany) {
      this.getcompanydata()
    }
  }
  getcompanydata() {
    let obj = {
      "command": "lst",
      "lmt": 10000,
      "pid": 1,
      "key": this.selectedcompany || "",
    }
    this.custservice.usercompanyData(obj).subscribe((res: any) => {
      this.companyData = res.data

    })
    // this.custservice.getcompanymasterdata(obj).subscribe((res:any)=>{
    // this.companyData=res.data

    // })
  }
  getmaterialDATA(ev: any) {
    console.log(ev.target.value);
    this.selectedmaterial = ev.target.value
    if (this.selectedmaterial.length > 2) {
      this.getmasterdata()
    }
    if (!this.selectedmaterial) {
      this.getmasterdata()
    }
  }
getmaterialDATA1(ev: any) {
  this.selectedmaterial1 = ev.target.value
  if (this.selectedmaterial1.length > 2) {
    this.getmasterdata()
  }
  if (!this.selectedmaterial1) {
    this.getmasterdata()
  }
}
  getmasterdata() {
    let obj = {
      "command": 'lst',
      "lmt": 100,
      "pid": 1,
      "key": this.selectedmaterial || this.selectedmaterial1 || this.matcodedata,
      // "code":this.matcodedata
    }
    this.custservice.getmaterialmasterdata(obj).subscribe((res: any) => {
      console.log(res);
      if (res) {

        this.masterData = res.data
        this.masterData.forEach((ele: any) => {
          // this.model2.matcode = ele.system_reference_1
          if (ele.code == this.matcodedata) {
            this.model2.matcode = ele.system_reference_1
            // alert(1)
            // this.systemref = ele.system_reference_1
          }
        });
        this.matcodedata = ""
        this.selectedmaterial = ""
        this.selectedmaterial1 = ""
      }
    })
  }
  getTaxlistdata() {
    let obj = {
      "command": "lst",
      "lmt": 10000,
      "pid": 1,
    }
    this.custservice.gettaxlistdata(obj).subscribe((res: any) => {
      this.taxData = res.data
    })
  }
  selectedmastergroup() {
    console.log(this.model.matcode);
    this.masterData.forEach((ele: any) => {
      if (ele.system_reference_1 == this.model.matcode) {
        this.model.uom = ele.uom_1
        this.materialCODE = ele.code
        this.materialNAME = ele.name
      }
    });


  }
  // getcompanydropdowndata(){
  //   this.getcompanydata()
  // }
  addmaterialreceipt(data: any) {
    this.panelOpenState = true;
    this.intialData = true;
    this.editData = false;
    this.editpanelOpenState = false;
    // this.dialog.open(data, {
    //   width: '1100px',
    //   height: '570px'
    // })
    // scrollStrategy: this.overlay.scrollStrategies.noop()
    //  scrollStrategy: new NoopScrollStrategy()
    //  this.getTaxlistdata()
    //  this.getmasterdata()
    //  this.getstoragelocData()
  }
  closemodel() {
    this.dialog.closeAll()
  }
  saveaddeddata(form: any) {
    this.model['material_code'] = this.materialCODE;
    this.model['material_description'] = this.materialNAME;
    console.log(this.model);
    this.saveddataarray.push(this.model);
    console.log(this.saveddataarray);
    this.SUM = this.saveddataarray.reduce((sum: number, el: any) => {
      return sum + (el.basicprice || el.basic_price || 0);
    }, 0);
    this.dmrvalue = 0;
    this.countdmr = 0;
    this.saveddataarray.forEach((ele: any, index: number) => {
      this.countdmr = index + 1;
      let basicprice = ele.basicprice || ele.basic_price || 0;
      let taxvalue = ele.taxvalue || ele.tax_value || 0;
      let value = basicprice + taxvalue;
      if (this.SUM > 0) {
        value += (basicprice / this.SUM) * (this.model1.totalfreight || 0)
               + (basicprice / this.SUM) * (this.model1.otherchargesval || 0)
               + (basicprice / this.SUM) * (this.model1.othertaxvalue || 0);
      }
      this.dmrvalue += value;
      // if (ele.taxvalue == 0 || ele.taxpercentage == 0) {
      //   this.dmrvalue += basicprice;
      //   console.log('Basic price value: ' + basicprice + ' | dmrvalue: ' + this.dmrvalue);
      // } else {
      //   this.dmrvalue += value;
      //   console.log('Calculated value: ' + value + ' | dmrvalue: ' + this.dmrvalue);
      // }
    });

    this.dataSource.data = this.saveddataarray;
    console.log(this.dataSource.data);
    this.model = {};
    this.dialog.closeAll();
    console.log(this.SUM);
    this.intialData = false;
    this.panelOpenState = false;
    this.editData = false;
    this.editpanelOpenState = false;
  }
  
  // saveaddeddata(form: any) {
  //   this.model['material_code'] = this.materialCODE
  //   this.model['material_description'] = this.materialNAME
  //   console.log(this.model);

  //   // this.editDATAA=true;
  //   this.saveddataarray.push(this.model)
  //   console.log(this.saveddataarray);
  //   this.SUM = 0
  //   this.saveddataarray.forEach((el: any) => {
  //     this.SUM += (el.basicprice || el.basic_price)
  //   })
  //   this.dmrvalue=0
  //   this.countdmr=0
  //   this.saveddataarray.forEach((ele: any,index) => {
  //     this.countdmr =  index+1
  //     let value=0
  //     value=((+(ele.basicprice || ele.basic_price) + (ele.taxvalue || ele.tax_value) + (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.totalfreight || 0)) +
  //     (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.otherchargesval || 0)) +
  //     (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.othertaxvalue || 0))) || 0)
  //     if(ele.taxvalue == 0 || ele.taxpercentage == 0){
  //       this.dmrvalue+=ele.basicprice
  //       console.log('value :' + value + 'dmrvalue :' + this.dmrvalue); 
  //     }else{
  //       this.dmrvalue+=value;
  //       console.log('value :' + value + 'dmrvalue :' + this.dmrvalue); 
  //     }
  //   })
  //   this.dataSource.data = this.saveddataarray
  //   console.log(this.dataSource.data)
  //   this.model = {}
  //   this.dialog.closeAll()
  //   console.log(this.SUM);
  //   this.intialData = false
  //   this.panelOpenState = false
  //   this.editData = false;
  //   this.editpanelOpenState = false;
  // }
  savefinaldata() {
    if (this.btn == "Save") {
      this.saveddataarray.forEach((ele: any, index) => {
        this.SNO.push(index + 1)
        this.matCode.push(ele.material_code)
        this.matName.push(ele.material_description)
        this.UOM.push(ele.uom || ele.unit_of_measurment)
        this.storageLocation.push(ele.storagelocation || ele.storage_location)
        this.valuationType.push(ele.valuationType || ele.valuation_type)
        this.InvoiceQuantity.push(Number(ele.invqty || ele.invoice_quantity) || 0)
        this.RecQTY.push(Number(ele.recqty || ele.received_quantity) || 0)
        this.shrQTY.push(ele.shrtqty || ele.shortage_quantity || 0)
        this.excsQTY.push(ele.excsqty || ele.excess_quantity || 0)
        this.RejQTY.push(Number(ele.rejqty) || Number(ele.rejected_quantity) || 0)
        this.unitPrice.push(Number(ele.unitprice || ele.unit_price) || 0)
        this.discPERCENT.push(Number(ele.dispercentage || ele.discount_percent) || 0)
        this.discVALUE.push(ele.disvalue || ele.discount_value || 0)
        this.basicPRICE.push(ele.basicprice || ele.basic_price || 0)
        this.taxDESC.push(ele.taxdescription || ele.tax_description)
        this.taxPERCEN.push(ele.taxpercentage || ele.tax_percent || 0)
        this.taxVALUE.push(ele.taxvalue || ele.tax_value || 0)
        this.otherTAXSPLIT.push(ele.othertaxsplit || ele.other_tax_split)
        // this.POID.push(ele.po_id)
      }
      )

      this.saveddataarray.forEach((ele: any) => {

        this.freightSPLIT.push((ele.basicprice / this.SUM) * this.model1.totalfreight || ele.freight_split || 0)
        this.otherchargesSPLIT.push((ele.basicprice / this.SUM) * this.model1.otherchargesval || ele.other_charges_split || 0)
        this.othertaxSPLIT.push((ele.basicprice / this.SUM) * this.model1.othertaxvalue || ele.other_tax_split || 0)
        this.totalPRICE.push((+ele.basicprice + ele.taxvalue + ((ele.basicprice / this.SUM) * this.model1.totalfreight) +
          ((ele.basicprice / this.SUM) * this.model1.otherchargesval) +
          ((ele.basicprice / this.SUM) * this.model1.othertaxvalue)) || ele.total_price || 0)
      })
      let obj = {
        // "comments": this.model1.comments,

        "date": moment(this.model1.dateee).format("YYYY-MM-DD"),
        "purchase_order_number": this.model1.ponumber,

        "purchase_order_date": moment(this.model1.podate).format("YYYY-MM-DD"),

        "company_name": this.model1.companyname,

        "vendor_name": this.model1.vendorname,

        "invoice_number": this.model1.invoicenumber,

        "invoice_date": moment(this.model1.invdate).format("YYYY-MM-DD"),

        "dc_number": this.model1.dcnumber,

        "dc_date": moment(this.model1.dcdate).format("YYYY-MM-DD"),

        "packing_details": this.model1.packdetails,

        "gate_entry_number": this.model1.genumber,

        "gate_entry_date": moment(this.model1.gedate).format("YYYY-MM-DD"),

        "transporter_name": this.model1.trsname,

        "vehicle_number": this.model1.vnumber,

        "lr_number": this.model1.irnumber,

        "lr_date": moment(this.model1.irdate).format("YYYY-MM-DD"),
        "line_item": this.SNO,

        "material_code": this.matCode,

        "material_description": this.matName,

        "unit_of_measurment": this.UOM,

        "storage_location": this.storageLocation,

        "invoice_quantity": this.InvoiceQuantity,

        "received_quantity": this.RecQTY || 0,

        "shortage_quantity": this.shrQTY || 0,

        "excess_quantity": this.excsQTY || 0,
        "rejected_quantity": this.RejQTY || 0,
        "unit_price": this.unitPrice || 0,

        "discount_percent": this.discPERCENT || 0,

        "discount_value": this.discVALUE || 0,
        "basic_price": this.basicPRICE || 0,

        "tax_description": this.taxDESC,

        "tax_percent": this.taxPERCEN || 0,

        "tax_value": this.taxVALUE || 0,

        "freight_split": this.freightSPLIT || 0,

        "basic_freight": Number(this.model1.basicfreight) || 0,

        "freight_tax_description": this.model1.frtaxdescription,

        "freight_tax_percent": Number(this.model1.frtaxpercentage) || 0,

        "freight_tax_value": this.model1.frtaxvalue || 0,

        "total_freight": this.model1.totalfreight || 0,

        "other_charges_description": this.model1.othercharges,

        "other_charges_value": Number(this.model1.otherchargesval) || 0,

        "other_charges_split": this.otherchargesSPLIT || 0,

        "other_tax_description": this.model1.othertaxdescription,

        "other_tax_percent": Number(this.model1.othertaxper) || 0,

        "other_tax_value": Number(this.model1.othertaxvalue) || 0,

        "other_tax_split": this.othertaxSPLIT || 0,

        "total_price": this.totalPRICE || 0,
        "comments": this.model1.comments,
        // "quality_check": this.model1.qualitycheck,
        "way_bill_number": this.model1.waybillno,
        "valution_type": this.valuationType,
        "po_id": this.POID,
        "goods_receipt_number": this.model1.grn_number,
        "goods_receipt_date": this.model1.grdate,
        "document_dispatch_reference": this.model1.doc_reference,
        "boe_number":this.model1.boe_number,
        "boe_date":this.model1.boe_date,
        "document_dispatch_date": this.model1.doc_date,
        "txn_id": this.txnId,
        "net_weight": this.model1.netweight,
        ho_received_date: this.model1.ho_received_date,
        fa_submitted_date: this.model1.fa_submitted_date,
        fa_submitted_to: this.model1.fa_submitted_to,
        // "direct_grn":this.model1.directgrn,
        "command": "add"
      }
      this.custservice.addmaterialreceiptdata(obj).subscribe((res: any) => {
        if (res && res['status_code'] == "200") {
          // this.alertcall.showSuccess('Accepted', res['message']);
          Swal.fire({
            text: res['message'],
            title: res['reference'],
            icon: 'success',
            // title: res['reference'],
            width: 500,
          });
          //  fr.reset();
          this.demo1TabIndex=1
          this.saveddataarray = []
          this.dataSource.data = []
          this.SNO = []
          this.matCode = []
          this.matName = []
          this.UOM = []
          this.storageLocation = []
          this.InvoiceQuantity = []
          this.RecQTY = []
          this.RejQTY = []
          this.shrQTY = []
          this.excsQTY = []
          this.unitPrice = []
          this.discPERCENT = []
          this.discVALUE = []
          this.basicPRICE = []
          this.taxDESC = []
          this.taxPERCEN = []
          this.taxVALUE = []
          this.otherTAXSPLIT = []
          this.freightSPLIT = []
          this.otherchargesSPLIT = []
          this.othertaxSPLIT = []
          this.totalPRICE = []
          this.valuationType = []
          this.POID = []
          this.model1 = {}
          this.generateddmr = res['reference']
          this.countdmr = 0
          this.dmrvalue = 0
          this.getTransactionId();
          this.getData()
          this.getdatapnd();
          this.getgoodslistpnd()
          this.copieddmr = "";
          if (this.fileUploadUrlsdmr.length > 0) {
            this.uploadedselctedfiles()
          }
        } else {
          this.alertcall.showWarning('Accepted', res['message']);
          this.SNO = []
          this.matCode = []
          this.matName = []
          this.UOM = []
          this.storageLocation = []
          this.InvoiceQuantity = []
          this.RecQTY = []
          this.RejQTY = []
          this.shrQTY = []
          this.excsQTY = []
          this.unitPrice = []
          this.discPERCENT = []
          this.discVALUE = []
          this.basicPRICE = []
          this.taxDESC = []
          this.taxPERCEN = []
          this.taxVALUE = []
          this.otherTAXSPLIT = []
          this.freightSPLIT = []
          this.otherchargesSPLIT = []
          this.othertaxSPLIT = []
          this.totalPRICE = []
          this.valuationType = []
          // this.POID=[]
        }
      })
    } else {
      console.log(this.saveddataarray);
      this.saveddataarray.forEach((ele: any, index) => {
        this.SNO.push(index + 1)
        this.matCode.push(ele.material_code)
        this.matName.push(ele.material_description)
        this.UOM.push(ele.uom || ele.unit_of_measurment)
        this.storageLocation.push(ele.storagelocation || ele.storage_location)
        this.valuationType.push(ele.valuationType || ele.valuation_type)
        this.InvoiceQuantity.push(Number(ele.invqty || ele.invoice_quantity) || 0)
        this.RecQTY.push(Number(ele.recqty || ele.received_quantity) || 0)
        this.shrQTY.push(ele.shrtqty || ele.shortage_quantity || 0)
        this.excsQTY.push(ele.excsqty || ele.excess_quantity || 0)
        this.RejQTY.push(Number(ele.rejqty) || Number(ele.rejected_quantity) || 0)
        this.unitPrice.push(Number(ele.unitprice || ele.unit_price) || 0)
        this.discPERCENT.push(Number(ele.dispercentage || ele.discount_percent) || 0)
        this.discVALUE.push(ele.disvalue || ele.discount_value || 0)
        this.basicPRICE.push(ele.basicprice || ele.basic_price || 0)
        this.taxDESC.push(ele.taxdescription || ele.tax_description)
        this.taxPERCEN.push(ele.taxpercentage || ele.tax_percent || 0)
        this.taxVALUE.push(ele.taxvalue || ele.tax_value || 0)
        this.otherTAXSPLIT.push(ele.othertaxsplit || ele.other_tax_split)
        // this.POID.push(ele.po_id)
      }
      )
      this.saveddataarray.forEach((ele: any) => {
        this.freightSPLIT.push((ele.basicprice / this.SUM) * this.model1.totalfreight || ele.freight_split || 0)
        this.otherchargesSPLIT.push((ele.basicprice / this.SUM) * this.model1.otherchargesval || ele.other_charges_split || 0)
        this.othertaxSPLIT.push((ele.basicprice / this.SUM) * this.model1.othertaxvalue || ele.other_tax_split || 0)
        this.totalPRICE.push((+ele.basicprice + ele.taxvalue + ((ele.basicprice / this.SUM) * this.model1.totalfreight) +
          ((ele.basicprice / this.SUM) * this.model1.otherchargesval) +
          ((ele.basicprice / this.SUM) * this.model1.othertaxvalue)) || ele.total_price || 0)
      })

      let obj = {
        "reason": this.editModel.reason,
        "comments": this.model1.comments,

        "date": moment(this.model1.dateee).format("YYYY-MM-DD"),
        "purchase_order_number": this.model1.ponumber,

        "purchase_order_date": moment(this.model1.podate).format("YYYY-MM-DD"),

        "company_name": this.model1.companyname,

        "vendor_name": this.model1.vendorname,

        "invoice_number": this.model1.invoicenumber,

        "invoice_date": moment(this.model1.invdate).format("YYYY-MM-DD"),

        "dc_number": this.model1.dcnumber,

        "dc_date": moment(this.model1.dcdate).format("YYYY-MM-DD"),

        "packing_details": this.model1.packdetails,

        "gate_entry_number": this.model1.genumber,

        "gate_entry_date": moment(this.model1.gedate).format("YYYY-MM-DD"),

        "transporter_name": this.model1.trsname,

        "vehicle_number": this.model1.vnumber,

        "lr_number": this.model1.irnumber,

        "lr_date": moment(this.model1.irdate).format("YYYY-MM-DD"),
        "line_item": this.SNO,

        "material_code": this.matCode,

        "material_description": this.matName,

        "unit_of_measurment": this.UOM,

        "storage_location": this.storageLocation,

        "invoice_quantity": this.InvoiceQuantity,

        "received_quantity": this.RecQTY || 0,

        "shortage_quantity": this.shrQTY || 0,

        "excess_quantity": this.excsQTY || 0,

        "rejected_quantity": this.RejQTY || 0,

        "unit_price": this.unitPrice || 0,

        "discount_percent": this.discPERCENT || 0,

        "discount_value": this.discVALUE || 0,
        "basic_price": this.basicPRICE || 0,

        "tax_description": this.taxDESC,

        "tax_percent": this.taxPERCEN || 0,

        "tax_value": this.taxVALUE || 0,

        "freight_split": this.freightSPLIT || 0,

        "basic_freight": Number(this.model1.basicfreight) || 0,

        "freight_tax_description": this.model1.frtaxdescription,

        "freight_tax_percent": Number(this.model1.frtaxpercentage) || 0,

        "freight_tax_value": this.model1.frtaxvalue || 0,

        "total_freight": this.model1.totalfreight || 0,

        "other_charges_description": this.model1.othercharges,

        "other_charges_value": Number(this.model1.otherchargesval) || 0,

        "other_charges_split": this.otherchargesSPLIT || 0,

        "other_tax_description": this.model1.othertaxdescription,

        "other_tax_percent": Number(this.model1.othertaxper) || 0,

        "other_tax_value": Number(this.model1.othertaxvalue) || 0,

        "other_tax_split": this.othertaxSPLIT || 0,

        "total_price": this.totalPRICE || 0,

        // "quality_check": this.model1.qualitycheck,
        "way_bill_number": this.model1.waybillno,
        "valution_type": this.valuationType,
        "po_id": this.POID,
        "number": this.editednumber,
        "goods_receipt_number": this.model1.grn_number,
        "goods_receipt_date": this.model1.grdate,
        "document_dispatch_reference": this.model1.doc_reference,
        "document_dispatch_date": this.model1.doc_date,
        "boe_number":this.model1.boe_number,
        "boe_date":this.model1.boe_date,
        "txn_id": this.txnId,
        "net_weight": this.model1.netweight,
        ho_received_date: this.model1.ho_received_date,
        fa_submitted_date: this.model1.fa_submitted_date,
        fa_submitted_to: this.model1.fa_submitted_to,
        // "direct_grn":this.model1.directgrn,
        "command": "edt"
      }
      this.custservice.addmaterialreceiptdata(obj).subscribe((res: any) => {
        if (res && res['status_code'] == "200") {
          // this.alertcall.showSuccess('Accepted', res['message']);
          Swal.fire({
            text: res['message'],
            title: res['reference'],
            icon: 'success',
            // title: res['reference'],
            width: 500,
          });
          //  fr.reset();
          this.demo1TabIndex=1
          this.saveddataarray = []
          this.dataSource.data = []
          this.SNO = []
          this.matCode = []
          this.matName = []
          this.UOM = []
          this.storageLocation = []
          this.InvoiceQuantity = []
          this.RecQTY = []
          this.shrQTY = []
          this.excsQTY = []
          this.RejQTY = []
          this.unitPrice = []
          this.discPERCENT = []
          this.discVALUE = []
          this.basicPRICE = []
          this.taxDESC = []
          this.taxPERCEN = []
          this.taxVALUE = []
          this.otherTAXSPLIT = []
          this.freightSPLIT = []
          this.otherchargesSPLIT = []
          this.othertaxSPLIT = []
          this.totalPRICE = []
          this.valuationType = []
          this.POID = []
          this.model1 = {}
          this.countdmr = 0
          this.dmrvalue = 0
          this.generateddmr = this.editednumber
          this.getData()
          this.getTransactionId();
          this.getdatapnd();
          this.getgoodslistpnd()
          this.editModel.reason = ""
          if (this.fileUploadUrlsdmr.length > 0) {
            this.uploadedselctedfiles()
          }
          this.editeddata = false;
          this.editednumber = "";
        } else {
          this.alertcall.showWarning('Accepted', res['message']);
          this.SNO = []
          this.matCode = []
          this.matName = []
          this.UOM = []
          this.storageLocation = []
          this.InvoiceQuantity = []
          this.RecQTY = []
          this.shrQTY = []
          this.excsQTY = []
          this.RejQTY = []
          this.unitPrice = []
          this.discPERCENT = []
          this.discVALUE = []
          this.basicPRICE = []
          this.taxDESC = []
          this.taxPERCEN = []
          this.taxVALUE = []
          this.otherTAXSPLIT = []
          this.freightSPLIT = []
          this.otherchargesSPLIT = []
          this.othertaxSPLIT = []
          this.totalPRICE = []
          this.valuationType = []
          // this.POID=[]
        }
      })
    }
  }
  editdata(row1: any, index: any) {
    // this.editDATAA=false
    this.matcodedata=row1.material_code
    // this.getmasterdata()
    // this.getstoragelocData()
    this.selectedIndex = this.saveddataarray.indexOf(row1)
    this.editpanelOpenState = true;
    this.editData = true;
    this.intialData = false;
    this.panelOpenState = false;
    // console.log(this.selectedIndex);
    // this.dialog.open(data, {
    //   width: '1100px',
    //   height: '570px'
    // })
    this.getmasterdata()
    // this.masterData.forEach((ele: any) => {
    //   console.log(ele);
    //   console.log(row1.material_code);
    //   if (ele.code == row1.material_code) {
    //     this.systemref = ele.system_reference_1
    //   }
    // });
    this.model2.matcode = this.systemref || row1.matcode
    this.model2.uom = row1.uom || row1.unit_of_measurment
    this.model2.storagelocation = row1.storagelocation || row1.storage_location
    this.model2.valuationType = row1.valuationType || row1.valuation_type
    this.model2.invqty = row1.invqty || row1.invoice_quantity || 0
    this.model2.recqty = row1.recqty || row1.received_quantity || 0
    this.model2.rejqty = row1.rejqty || row1.rejected_quantity || 0
    this.model2.shrtqty = row1.shrtqty || row1.shortage_quantity || 0
    this.model2.excsqty = row1.excsqty || row1.excess_quantity || 0
    this.model2.unitprice = row1.unitprice || row1.unit_price || 0
    this.model2.dispercentage = row1.dispercentage || row1.discount_percent || 0
    this.model2.disvalue = row1.disvalue || row1.discount_value || 0
    this.model2.basicprice = row1.basicprice || row1.basic_price || 0
    this.model2.taxdescription = row1.tax_description || row1.taxdescription
    this.model2.taxpercentage = row1.tax_percent || row1.taxpercentage || 0
    this.model2.taxvalue = row1.taxvalue || row1.tax_value || 0
    // this.model2.valuationType=row1.valuation_type||row1.valuationType 

    // this.getTaxlistdata()
  }
  saveaddededitdata(fr: any) {
    this.editpanelOpenState = false;
    this.intialData = false;
    this.editData = false;
    this.panelOpenState = false;
    this.masterData.forEach((el: any) => {
      if (el.system_reference_1 === this.model2.matcode) {
        this.model2matcode = el.code;
        this.materialCODE = el.code;
        this.materialNAME = el.name;
      }
    });
    this.model2['material_code'] = this.materialCODE;
    this.model2['material_description'] = this.materialNAME;
    this.model2.totalprice = (this.model2.basicprice || 0) + (this.model2.taxvalue || 0);
    this.saveddataarray.splice(this.selectedIndex, 1, this.model2);
    this.dataSource.data = this.saveddataarray;
    console.log(this.dataSource.data);
    this.saveddataarray[this.selectedIndex].matcode = this.model2matcode;
    this.SUM = this.saveddataarray.reduce((sum: number, el: any) => {
      return sum + (el.basicprice || el.basic_price || 0);
    }, 0);
    this.model2 = {};
    this.dialog.closeAll();
    this.countdmr = 0;
    this.dmrvalue = 0;
    this.matcodedata = "";
    this.saveddataarray.forEach((ele: any, index: number) => {
      this.countdmr = index + 1;
      console.log('Count DMR:', this.countdmr);
  
      let basicprice = ele.basicprice || ele.basic_price || 0;
      let taxvalue = ele.taxvalue || ele.tax_value || 0;
  
      let value = basicprice + taxvalue;
  
      if (this.SUM > 0) {
        value += (basicprice / this.SUM) * (this.model1.totalfreight || 0)
               + (basicprice / this.SUM) * (this.model1.otherchargesval || 0)
               + (basicprice / this.SUM) * (this.model1.othertaxvalue || 0);
      }
  
      console.log('Calculated Value:', value);
      this.dmrvalue += value;
    });
  }
  
  // saveaddededitdata(fr: any) {
  //   this.editpanelOpenState = false;
  //   this.intialData = false;
  //   this.editData = false;
  //   this.panelOpenState = false;
  //   this.masterData.forEach((el: any) => {
  //     if (el.system_reference_1 == this.model2.matcode) {
  //       this.model2matcode = el.code
  //       this.materialCODE = el.code
  //       this.materialNAME = el.name
  //     }
  //   });
  //   this.model2['material_code'] = this.materialCODE
  //   this.model2['material_description'] = this.materialNAME
  //   this.model2.totalprice = this.model2.basicprice + this.model2.taxvalue
  //   this.saveddataarray.splice(this.selectedIndex, 1, this.model2);
  //   this.dataSource.data = this.saveddataarray
  //   console.log(this.dataSource.data);

  //   this.saveddataarray[this.selectedIndex].matcode = this.model2matcode
  //   this.SUM = 0
  //   // console.log(this.model2);
  //   this.saveddataarray.forEach((el: any) => {
  //     this.SUM += (el.basicprice || el.basic_price)
  //   })
  //   this.model2 = {}
  //   this.dialog.closeAll()
  //   this.countdmr=0
  //   this.dmrvalue=0
  //   this.matcodedata=""
  //   this.saveddataarray.forEach((ele: any,index) => {
  //     // console.log("entered", ele);
  //     this.countdmr =  index+1
  //     console.log('@@@@@@@@@@@@', this.countdmr);
  //     let value=0
  //     value=((+(ele.basicprice || ele.basic_price) + (ele.taxvalue || ele.tax_value) + (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.totalfreight || 0)) +
  //     (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.otherchargesval || 0)) +
  //     (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.othertaxvalue || 0))) || 0)
  //     console.log('hiii',value);
  //     if(ele.taxvalue == 0 || ele.taxpercentage == 0){
  //       this.dmrvalue+=ele.basicprice
  //       console.log('value :' + value + 'dmrvalue :' + this.dmrvalue , 'ele.basicprice:'+ele.basicprice); 
  //     }else{
  //       this.dmrvalue+=value;
  //       console.log('value :' + value + 'dmrvalue :' + this.dmrvalue); 
  //     }
  //   })
  // }
  selectedmastereditgroup() {
    this.masterData.forEach((el: any) => {
      if (el.system_reference_1 == this.model2.matcode) {
        this.model2.uom = el.uom_1
        this.materialCODE = el.code
        this.materialNAME = el.name
      }


    });
    console.log(this.model2matcode, this.model2matdes);
  }
  Invoicevalue(ev: any) {
    this.valuefrminv = ev.target.value
    this.model.shrtqty = this.valuefrminv - this.valuefrmrec
    this.model.excsqty = this.valuefrmrec - this.valuefrminv
    if (this.unitValue == 0) {
      this.model.basicprice = 0
    } else {
      this.model.basicprice = (this.valuefrmrec * this.unitValue) - this.model.disvalue
    }
    if (this.model.shrtqty < 0) {
      this.model.shrtqty = 0
    }
    if (this.model.excsqty < 0) {
      this.model.excsqty = 0
    }
    if (this.model.rejqty < 0) {
      this.model.rejqty = 0
    }
  }
  Invoiceeditvalue(ev: any) {
    if (this.model2.invqty == '' && this.model2.recqty == '') {
      this.valuefrminvedit = ev.target.value
      this.model2.shrtqty = this.valuefrminvedit - this.valuefrmrecedit
      this.model2.excsqty = this.valuefrmrecedit - this.valuefrminvedit
    } else {
      this.model2.shrtqty = this.model2.invqty - this.model2.recqty
      this.model2.excsqty = this.model2.recqty - this.model2.invqty
    }


    if (this.model2.unitValue == '') {
      this.model2.basicprice = 0
    } else if (this.unitValueedit) {
      this.model2.basicprice = (this.model2.recqty * this.unitValueedit) - this.model2.disvalue
    }
    else {
      this.model2.basicprice = (this.model2.recqty * this.model2.unitprice) - this.model2.disvalue
    }
    if (this.model2.shrtqty < 0) {
      this.model2.shrtqty = 0
    }
    if (this.model2.excsqty < 0) {
      this.model2.excsqty = 0
    }
  }
  Receivevalueedit(ev1: any) {
    if (this.model2.invqty == '' && this.model2.recqty == '') {
      this.valuefrmrecedit = ev1.target.value;
      this.model2.shrtqty = this.valuefrminvedit - this.valuefrmrecedit
      this.model2.excsqty = this.valuefrmrecedit - this.valuefrminvedit
    } else {
      this.model2.shrtqty = this.model2.invqty - this.model2.recqty
      this.model2.excsqty = this.model2.recqty - this.model2.invqty
    }
    if (this.model2.unitValue == '') {
      this.model2.basicprice = 0
    } else if (this.unitValueedit) {
      this.model2.basicprice = (this.model2.recqty * this.unitValueedit) - this.model2.disvalue
    }
    else {
      this.model2.basicprice = (this.model2.recqty * this.model2.unitprice) - this.model2.disvalue
    }
    if (this.model2.shrtqty < 0) {
      this.model2.shrtqty = 0
    }
    if (this.model2.excsqty < 0) {
      this.model2.excsqty = 0
    }
  }
  Rejectqty(){
    const rejqtyNumber = Number(this.model.rejqty);
    const recqtyNumber = Number(this.model.recqty);
    if (rejqtyNumber > recqtyNumber) {
      this.alertcall.showWarning("Warning", "Rejected Quantity cannot be more than Received Quantity");
      this.model.rejqty = ""; 
    } else if (this.model.recqty == 0) {
      this.model.rejqty = ''; 
    } 
  }
  Rejectqtyedit(){
    const rejqtyNumber = Number(this.model2.rejqty);
    const recqtyNumber = Number(this.model2.recqty);

    if (rejqtyNumber > recqtyNumber) {
      this.alertcall.showWarning("Warning", "Rejected Quantity cannot be more than Received Quantity");
      this.model2.rejqty = ''; 
    } else if (recqtyNumber === 0) {
      this.model2.rejqty = ''; 
    } 
  }
  Receivevalue(ev1: any) {
    this.valuefrmrec = ev1.target.value;
    this.model.shrtqty = this.valuefrminv - this.valuefrmrec
    this.model.excsqty = this.valuefrmrec - this.valuefrminv
    if (this.unitValue == 0) {
      this.model.basicprice = 0
    } else {
      this.model.basicprice = (this.valuefrmrec * this.unitValue) - this.model.disvalue
    }
    if (this.model.shrtqty < 0) {
      this.model.shrtqty = 0
    }
    if (this.model.excsqty < 0) {
      this.model.excsqty = 0
    }
    if (this.model.rejqty < 0) {
      this.model.rejqty = 0
    }
  }
  selectedunitprice(ev: any) {
    this.unitValue = ev.target.value
    this.model.disvalue = this.valuefrmrec * this.unitValue * this.discValue * 0.01
    this.model.basicprice = (this.valuefrmrec * this.unitValue) - this.model.disvalue
    if (this.model.taxdescription) {
      this.selectedtax()
    }
  }
  selectedunitpriceedit(ev: any) {
    if (this.model2.unitValue == '' && this.unitValueedit == 0) {
      this.unitValueedit = ev.target.value
      this.model2.disvalue = this.valuefrmrecedit * this.unitValueedit * this.discValueedit * 0.01
      this.model2.basicprice = (this.valuefrmrecedit * this.unitValueedit) - this.model2.disvalue
    } else if (this.model2.unitValue) {
      this.model2.disvalue = this.model2.recqty * this.model2.unitValue * this.model2.disvalue * 0.01
      this.model2.basicprice = (this.model2.recqty * this.model2.unitValue) - this.model2.disvalue
    }
    if (this.unitValueedit) {
      this.model2.basicprice = (this.model2.recqty * this.unitValueedit) - this.model2.disvalue
    }
    else {
      this.model2.basicprice = (this.model2.recqty * this.model2.unitprice) - this.model2.disvalue
    }
    if (this.model2.taxdescription) {
      this.selectedtaxedit()
    }
  }
  selecteddiscountprice(ev: any) {
    this.discValue = ev.target.value
    this.model.disvalue = this.valuefrmrec * this.unitValue * this.discValue * 0.01
    this.model.basicprice = (this.valuefrmrec * this.unitValue) - this.model.disvalue
    if (this.model.taxdescription) {
      this.selectedtax()
    }
  }
  selecteddiscountpriceedit(ev: any) {

    if (this.model2.dispercentage == '' && this.discValueedit == 0) {
      this.discValueedit = ev.target.value
      this.model2.disvalue = this.valuefrmrecedit * this.unitValueedit * this.discValueedit * 0.01
      this.model2.basicprice = (this.valuefrmrecedit * this.unitValueedit) - this.model2.disvalue
    } else if (this.model2.dispercentage) {
      this.model2.disvalue = this.model2.recqty * this.model2.unitprice * this.model2.dispercentage * 0.01
      this.model2.basicprice = (this.model2.recqty * this.model2.unitprice) - this.model2.disvalue
    }
    if (this.discValueedit == 0) {
      this.model2.basicprice = (this.model2.recqty * this.model2.unitprice) - this.model2.disvalue
    }
    // if(this.discValueedit){
    //   this.model2.disvalue=this.model2.recqty*this.model2.unitprice*this.model2.dispercentage*0.01
    //   this.model2.basicprice=(this.model2.recqty*this.model2.unitprice)- this.model2.disvalue
    // }
    // else {
    //   this.model2.basicprice=(this.model2.recqty*this.model2.unitprice)- this.model2.disvalue
    // }
    if (this.model2.taxdescription) {
      this.selectedtaxedit()
    }
  }
  selectedtax() {

    // this.model.taxpercentage=this.model.taxdescription.percentage
    this.taxData.forEach((el: any) => {
      if (el.code == this.model.taxdescription) {
        this.model.taxpercentage = el.percentage
      }
    })
    if (this.valuefrminv == 0) {
      this.model.taxvalue = 0
      this.model.totalprice = 0
    } else {
      this.model.taxvalue = this.model.basicprice * this.model.taxpercentage * 0.01
      this.model.totalprice = this.model.basicprice + this.model.taxvalue
    }

  }
  selectedtaxedit() {
    this.taxData.forEach((el: any) => {
      if (el.code == this.model2.taxdescription) {
        this.model2.taxpercentage  = el.percentage
      }
    })
    // this.model2.taxpercentage = this.editedtaxdescription
    //  this.taxPERCENTAGE=
    console.log(this.model2.taxpercentage);
    this.model2.taxvalue = this.model2.basicprice * this.model2.taxpercentage * 0.01
    this.model2.totalprice = this.model2.basicprice + this.model2.taxvalue
    // if (this.model2.taxpercentage) {
    //   this.model2.taxvalue = this.model2.basicprice * this.model2.taxpercentage * 0.01
    //   this.model2.totalprice = this.model2.basicprice + this.model2.taxvalue
    // }

  }
  selectedbasicfreight(ev: any) {
    this.basicFreight = ev.target.value
    this.model1.frtaxvalue = this.basicFreight * this.freightPercentage * 0.01
    this.selectedfreighttax()
  }
  selectedbasicfreight1(ev: any) {
    this.basicFreight1 = ev.target.value
    this.editmodel.frtaxvalue = this.basicFreight1 * this.freightPercentage1 * 0.01
    this.selectedfreighttax1()
  }
  selectedfreighttax() {
    this.taxData.forEach((element: any) => {
      if (element.code == this.model1.frtaxdescription) {
        this.model1.frtaxpercentage = element.percentage
        this.model1.frtaxvalue = this.basicFreight * this.model1.frtaxpercentage * 0.01
        this.model1.totalfreight = +this.basicFreight + this.model1.frtaxvalue
      }
    });



  }
  selectedfreighttax1() {
    this.taxData.forEach((element: any) => {
      if (element.code == this.editmodel.frtaxdescription) {
        this.editmodel.frtaxpercentage = element.percentage
        this.editmodel.frtaxvalue = this.basicFreight1 * this.editmodel.frtaxpercentage * 0.01
        this.editmodel.totalfreight = +this.basicFreight1 + this.editmodel.frtaxvalue
      }
    });



  }
  getTaxValue() {
    let value = this.model1.othertaxper * 0.01
    this.model1.othertaxvalue = value * this.model1.otherchargesval
  }
  selectedTAXDESC() {
    this.taxData.forEach((element: any) => {
      if (element.code == this.model1.othertaxdescription) {
        this.model1.othertaxper = element.percentage
        // this.model1.othertaxvalue=this.basicFreight1*this.editmodel.frtaxpercentage*0.01
        // this.editmodel.totalfreight= +this.basicFreight1+this.editmodel.frtaxvalue
      }
    });
  }
  selectedTAXDESC1() {
    this.taxData.forEach((element: any) => {
      if (element.code == this.editmodel.othertaxdescription) {
        this.editmodel.othertaxper = element.percentage
        // this.model1.othertaxvalue=this.basicFreight1*this.editmodel.frtaxpercentage*0.01
        // this.editmodel.totalfreight= +this.basicFreight1+this.editmodel.frtaxvalue
      }
    });
  }

  keyPressNumbers(evt: any) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  getpurchaseorderDATA(ev: any) {
    console.log(ev.target.value);
    this.selectedponumber = ev.target.value
    if (this.selectedponumber.length > 2) {
      this.getpoData()
    }
    if (!this.selectedponumber) {
      this.getpoData()
    }
  }
  getpoData() {
    let obj = {
      "command": "por",
      // "command": "set",
      // "lmt" : 100000,
      // "pid" : 1 ,
      "key": this.ponumber
    }
    this.custservice.getmattransnamedata(obj).subscribe((res: any) => {
      this.PODATA = res.data

    })
  }
  openpurchaseordermodel(data: any) {
    this.POID = []
    this.dialog.open(data, {
      width: '600px'
    })
    this.getpoData()
  }
  submitpono(fr: any) {
    this.editDATAA = false;
    this.POID = []
    let obj = {
      "command": "set",
      "key": this.ponumber,
      // "field": "number",
      "document_type": "PurchaseOrder"
      // "document_type":"OutGatePassRGP"
    }
    this.custservice.getrgpdata(obj).subscribe((res: any) => {
      this.poiddata = res.data
      res.data.forEach((ele: any) => {
        this.POID.push(ele.id)
      })
      console.log(this.POID);
      this.saveddataarray = res.data
      const newArrayOfObj = this.saveddataarray.map(({
        // quantity: invoice_quantity,
        // quantity: received_quantity,
        pending_quantity: invoice_quantity,
         pending_quantity: received_quantity,
        ...rest
      }) => ({
        invoice_quantity,
        received_quantity,
        ...rest
      }));
      // })
      console.log(newArrayOfObj);
      this.saveddataarray = newArrayOfObj
      this.dataSource.data = this.saveddataarray
      this.tabledata = res.data[0]
      this.model1.companyname = this.tabledata.company_name
      this.model1.dateee = moment(new Date()).format("YYYY-MM-DD")
      this.model1.ponumber = this.tabledata.number
      this.model1.podate = moment(this.tabledata.date).format("YYYY-MM-DD")
      this.model1.genumber = this.tabledata.gate_entry_number
      this.model1.invoicenumber = this.tabledata.invoice_number
      this.model1.dcnumber = this.tabledata.dc_number
      this.model1.vendorname = this.tabledata.vendor_name
      this.model1.packdetails = this.tabledata.packing_details
      this.model1.trsname = this.tabledata.transporter_name
      this.model1.vnumber = this.tabledata.vehicle_number
      this.model1.irnumber = this.tabledata.lr_number
      this.model1.basicfreight = this.tabledata.basic_freight
      this.model1.frtaxdescription = this.tabledata.freight_tax_description
      this.model1.frtaxpercentage = this.tabledata.freight_tax_percent
      this.model1.frtaxvalue = this.tabledata.freight_tax_value
      this.model1.totalfreight = this.tabledata.total_freight
      this.model1.othercharges = this.tabledata.other_charges_description
      this.model1.otherchargesval = this.tabledata.other_charges_value
      this.model1.othertaxdescription = this.tabledata.other_tax_description
      this.model1.othertaxper = this.tabledata.other_tax_percent
      this.model1.othertaxvalue = this.tabledata.other_tax_value
      // this.model1.qualitycheck = this.tabledata.quality_check
      this.model1.comments = this.tabledata.comments
      this.model1.waybillno = this.tabledata.waybillno
      console.log(this.model1.vendorname);
      this.SUM = 0
      this.saveddataarray.forEach((el: any) => {
        this.SUM += (el.basicprice || el.basic_price)
      })
      this.dmrvalue = 0;
      this.countdmr = 0;
      this.saveddataarray.forEach((ele: any,index:any) => {
        this.countdmr =  index+1
        let value=0
        value=((+(ele.basicprice || ele.basic_price) + (ele.taxvalue || ele.tax_value) + (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.totalfreight || 0)) +
        (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.otherchargesval || 0)) +
        (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.othertaxvalue || 0))) || 0)
        this.dmrvalue+=value;
        console.log('value :' + value + 'dmrvalue :' + this.dmrvalue); 
    })
      this.dialog.closeAll()
      this.ponumber = ''
      // this.copieddmr=''
      // this.model1.add({vendorname:this.editabledata.vendor_name})
    })

  }
  showContent(index: any) {
    this.selectedIndex = index;
    console.log(this.selectedIndex);

  }
  getGEN(ev: any) {
    console.log(ev.target.value);
    this.selectedgen = ev.target.value
    if (this.selectedgen.length > 2) {
      this.getgateentrynumberdata()
    }
    if (!this.selectedgen) {
      this.getgateentrynumberdata()
    }
  }
  getgateentrynumberdata() {
    let obj = {
      command: "gen",
      key: this.selectedgen || ""
    }
    this.custservice.addmaterialreceiptdata(obj).subscribe((res: any) => {
      this.GENDATA = res.data

    })
  }
  getselectedvalue() {
    let obj = {
      key: this.model1.genumber,
      field: "number",
      command: "mat",
    }
    this.custservice.getGateInwardData(obj).subscribe((res: any) => {
      console.log(res);
      this.model1.vnumber = res.data[0].vehicle_number
      this.model1.gedate = moment(res.data[0].date).format("YYYY-MM-DD")
    })
  }

  //search functionality
  onChange() {
    console.log('Selected:', this.columnname);
    this.searchData = ""
    // this.searchData=this.columnname
    // let selectedColumn=this.searchData
  }
  search() {
    console.log(this.searchData);
    // let key = this.columnname
    // let obj={"command":"lst",[key]:this.searchData}
    let obj = { "command": "lst", "field": this.columnname, "key": this.searchData, "lmt": this.pageSize, "pid": this.pageIndex,showLoader: 'hide'}
    // this.searchData=event.target.value;
    if (this.searchData.length > 2) {
      this.custservice.getSearchDailyMaterialReceipt(obj).subscribe((res: any) => {
        console.log(res);
        this.custservice.getmaterialreceiptdata(obj).subscribe((res: any) => {
          this.reaData = false;
          this.totalRecords = res?.count;
          this.dataSource.data = res.data
          this.dataSourcemain = new MatTableDataSource(res.data);
          // setTimeout(
          // () => {
          //     // this.dataSourcemain.paginator = this.paginator;
          //     this.dataSource.sort = this.sort;
          //  });
          if (res.data.length == 0) {
            this.reaData = true
          }

        })
      })
    } else if (!this.searchData) {
      this.getData()
      this.columnname = ""
    }
    // else if(this.searchData.length===0 || this.searchData.length == ""){
    //   this.getData()
    //   this.columnname=""
    // }
  }
  getrgpDATA(ev: any) {
    console.log(ev.target.value);
    this.selectedrgpnumber = ev.target.value
    if (this.selectedrgpnumber.length > 2) {
      this.getrgpdataData()
    }
    if (!this.selectedrgpnumber) {
      this.getrgpdataData()
    }
  }
  getrgpdataData() {
    let obj = {
      "command": "rgp",
      "key": this.rgpnumber
    }
    this.custservice.getrgpdata(obj).subscribe((res: any) => {
      this.RGPDATA = res.data

    })
  }

  openrgpmodel(data: any) {
    this.POID = []
    this.dialog.open(data, {
      width: '600px'
    })
    this.getrgpdataData();
  }
  submitrgpno(fr: any) {
    this.editDATAA = false;
    this.POID = []
    let obj = {
      "command": "set",
      "key": this.rgpnumber,
      // "field": "number",
      // "document_type":"PurchaseOrder"
      "document_type": "OutGatePassRGP"
    }
    this.custservice.getrgpdata(obj).subscribe((res: any) => {
      this.poiddata1 = res.data[0]
      res.data.forEach((ele: any) => {
        this.POID.push(ele.id)
      })
      console.log(this.POID);
      this.saveddataarray = res.data
      const newArrayOfObj = this.saveddataarray.map(({
        quantity: invoice_quantity,
        quantity: received_quantity,
        ...rest
      }) => ({
        invoice_quantity,
        received_quantity,
        ...rest
      }));
      // })
      console.log(newArrayOfObj);
      this.saveddataarray = newArrayOfObj
      this.dataSource.data = this.saveddataarray
      this.tabledata = res.data[0]
      // this.POID= this.tabledata.id
      this.model1.companyname = this.tabledata.company_name
      this.model1.dateee = moment(new Date()).format("YYYY-MM-DD")
      this.model1.ponumber = this.tabledata.number
      this.model1.podate = moment(this.tabledata.date).format("YYYY-MM-DD")
      // this.model1.genumber = this.tabledata.gate_entry_number
      // this.model1.invoicenumber = this.tabledata.invoice_number
      // this.model1.dcnumber = this.tabledata.dc_number
      this.model1.vendorname = this.tabledata.consignee_name
      // this.model1.packdetails = this.tabledata.packing_details
      // this.model1.trsname = this.tabledata.transporter_name
      // this.model1.vnumber = this.tabledata.vehicle_number
      // this.model1.irnumber = this.tabledata.lr_number
      // this.model1.basicfreight = this.tabledata.basic_freight
      // this.model1.frtaxdescription = this.tabledata.freight_tax_description
      // this.model1.frtaxpercentage = this.tabledata.freight_tax_percent
      // this.model1.frtaxvalue = this.tabledata.freight_tax_value
      // this.model1.totalfreight = this.tabledata.total_freight
      // this.model1.othercharges = this.tabledata.other_charges_description
      // this.model1.otherchargesval = this.tabledata.other_charges_value
      // this.model1.othertaxdescription = this.tabledata.other_tax_description
      // this.model1.othertaxper = this.tabledata.other_tax_percent
      // this.model1.othertaxvalue = this.tabledata.other_tax_value
      // this.model1.qualitycheck = this.tabledata.quality_check
      // this.model1.comments = this.tabledata.comments
      // this.model1.waybillno = this.tabledata.waybillno
      // console.log(this.model1.vendorname);
      this.SUM = 0
      this.saveddataarray.forEach((el: any, index: any) => {
        this.countdmr = index + 1
        this.SUM += el.basic_price
      })
      console.log(this.SUM);
      this.dialog.closeAll()
      this.rgpnumber = ''
      // this.copieddmr=''
      // this.model1.add({vendorname:this.editabledata.vendor_name})
    })

  }


  //view
  // viewsaveddata(data: any, dialog: any) {
  //   this.dialog.open(dialog, {
  //     width: "400px"
  //   })
  //   this.viewnumber = data.number

  // }
  viewreason(data: any) {
    console.log(data, 'llllllllllllllllllllllllll')
    this.viewnumber = data.number;
    let obj = {
      "command": "mat",
      "key": data.number || this.viewnumber,
      "field": "number"
    }
    this.custservice.editmaterialreceiptdata(obj).subscribe((res: any) => {
      console.log(res.data);
      if (res.data.length > 0) {
        // this.dialog.closeAll()
        this.dmrno=data.number
        this.storageloc=res.data;
        this.viewData = true
        this.saveddataarray = res.data
        this.dataSource.data = this.saveddataarray
        this.editabledata = res.data[0]
        this.model1.companyname = this.editabledata.company_name !== null ? this.editabledata.company_name : "NA"
        this.model1.dateee = this.editabledata.date !== null ? moment(this.editabledata.date).format("DD-MM-YYYY") : "NA"
        this.model1.ponumber = this.editabledata.purchase_order_number !== null ? this.editabledata.purchase_order_number : "NA"
        this.model1.podate = this.editabledata.purchase_order_date !== null ? moment(this.editabledata.purchase_order_date).format("DD-MM-YYYY") : "NA"
        this.model1.genumber = this.editabledata.gate_entry_number !== null ? this.editabledata.gate_entry_number : "NA"
        this.model1.gedate = this.editabledata.gate_entry_date !== null ? moment(this.editabledata.gate_entry_date).format("DD-MM-YYYY") : "NA"
        this.model1.invoicenumber = this.editabledata.invoice_number !== null ? this.editabledata.invoice_number : "NA"
        this.model1.invdate = this.editabledata.invoice_date !== null ? moment(this.editabledata.invoice_date).format("DD-MM-YYYY") : "NA"
        this.model1.dcnumber = this.editabledata.dc_number !== null ? this.editabledata.dc_number : "NA"
        this.model1.dcdate = this.editabledata.dc_date !== null ? moment(this.editabledata.dc_date).format("DD-MM-YYYY") : "NA"
        this.model1.vendorname = this.editabledata.vendor_name !== null ? this.editabledata.vendor_name : "NA"
        this.model1.packdetails = this.editabledata.packing_details !== null ? this.editabledata.packing_details : "NA"
        this.model1.trsname = this.editabledata.transporter_name !== null ? this.editabledata.transporter_name : "NA"
        this.model1.vnumber = this.editabledata.vehicle_number !== null ? this.editabledata.vehicle_number : "NA"
        this.model1.irnumber = this.editabledata.lr_number !== null ? this.editabledata.lr_number : "NA"
        this.model1.irdate = this.editabledata.lr_date !== null ? moment(this.editabledata.lr_date).format("DD-MM-YYYY") : "NA"
        this.model1.basicfreight = this.editabledata.basic_freight !== null ? this.editabledata.basic_freight : "NA"
        this.model1.frtaxdescription = this.editabledata.freight_tax_description !== "" ? this.editabledata.freight_tax_description : "NA"
        this.model1.frtaxpercentage = this.editabledata.freight_tax_percent !== null ? this.editabledata.freight_tax_percent : "NA"
        this.model1.frtaxvalue = this.editabledata.freight_tax_value !== null ? this.editabledata.freight_tax_value : "NA"
        this.model1.totalfreight = this.editabledata.total_freight !== null ? this.editabledata.total_freight : "NA"
        this.model1.othercharges = this.editabledata.other_charges_description !== "" ? this.editabledata.other_charges_description : "NA"
        this.model1.otherchargesval = this.editabledata.other_charges_value !== null ? this.editabledata.other_charges_value : "NA"
        this.model1.othertaxdescription = this.editabledata.other_tax_description !== "" ? this.editabledata.other_tax_description : "NA"
        this.model1.othertaxper = this.editabledata.other_tax_percent !== null ? this.editabledata.other_tax_percent : "NA"
        this.model1.othertaxvalue = this.editabledata.other_tax_value !== null ? this.editabledata.other_tax_value : "NA"
        this.model1.comments = this.editabledata.comments !== null ? this.editabledata.comments : "NA"
        this.model1.waybillno = this.editabledata.way_bill_number !== null ? this.editabledata.way_bill_number : "NA"
        this.model1.grn_number = this.editabledata.grn_number !== null ? this.editabledata.grn_number : "NA",
          this.model1.grdate = this.editabledata.grn_date !== null ? moment(this.editabledata.grn_date).format("DD-MM-YYYY") : "NA",
          this.model1.doc_reference = this.editabledata.ho_dispatch_ref !== null ? this.editabledata.ho_dispatch_ref : "NA",
          this.model1.doc_date = this.editabledata.ho_dispatch_date !== null ? moment(this.editabledata.ho_dispatch_date).format("DD-MM-YYYY") : "NA",
          this.model1.boe_number = this.editabledata.boe_number !== null ? this.editabledata.boe_number : "NA",
          this.model1.boe_date = this.editabledata.boe_date !== null ? moment(this.editabledata.boe_date).format("DD-MM-YYYY") : "NA",
          this.model1.directgrn = this.editabledata.direct_grn
        this.model1.qualitycheck = this.editabledata.quality_check
        this.model1.netweight=this.editabledata.net_weight !== null ? this.editabledata.net_weight :'NA',
        this.model1.ho_received_date = this.editabledata.ho_received_date !== null?moment(this.editabledata.ho_received_date).format("DD-MM-YYYY"):'NA',
        this.model1.fa_submitted_date= this.editabledata.fa_submitted_date !== null?moment(this.editabledata.fa_submitted_date).format("DD-MM-YYYY"):'NA',
        this.model1.fa_submitted_to=this.editabledata.fa_submitted_to !== null ? this.editabledata.fa_submitted_to : 'NA',
        this.SUM = 0
        this.saveddataarray.forEach((el: any) => {
          this.SUM += (el.basicprice || el.basic_price)
        })
        this.dmrvalue=0
        this.countdmr=0
        this.saveddataarray.forEach((ele: any,index) => {
          this.countdmr =  index+1
          let value=0
          value=((+(ele.basicprice || ele.basic_price) + (ele.taxvalue || ele.tax_value) + (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.totalfreight || 0)) +
          (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.otherchargesval || 0)) +
          (((ele.basicprice || ele.basic_price) / this.SUM) * (this.model1.othertaxvalue || 0))) || 0)
          this.dmrvalue+=value;  
          console.log('value :' + value + 'dmrvalue :' + this.dmrvalue); 
        })
        this.demo1TabIndex = 0;
        this.editeddata = true;
      } else {
        this.alertcall.showWarning("Accepted", "No Data Found");
      }
      // this.btn = "Update"
      // this.copieddmr=''
      // this.model1.add({vendorname:this.editabledata.vendor_name})
    })

  }
  BacktoList() {
    this.demo1TabIndex = 1;
    this.viewData = false
  }

  //on change tab
  onTabChanged(ev: any) {
    if (this.demo1TabIndex === 1) {
      // alert(1);
      this.btn="Save"
      this.columnname=""
      this.searchData=""
      this.columnnamepnd=""
      this.searchDatapnd=""
      this.columnnamegrnpnd=""
      this.searchDatagrnpnd=""
      this.logsearchData=""
      this.viewData = false
      this.saveddataarray = []
      this.dataSource.data = []
      this.SNO = []
      this.matCode = []
      this.matName = []
      this.UOM = []
      this.storageLocation = []
      this.InvoiceQuantity = []
      this.RecQTY = []
      this.shrQTY = []
      this.excsQTY = []
      this.RejQTY = []
      this.unitPrice = []
      this.discPERCENT = []
      this.discVALUE = []
      this.basicPRICE = []
      this.taxDESC = []
      this.taxPERCEN = []
      this.taxVALUE = []
      this.otherTAXSPLIT = []
      this.freightSPLIT = []
      this.otherchargesSPLIT = []
      this.othertaxSPLIT = []
      this.totalPRICE = []
      this.valuationType = []
      this.POID = []
      this.model1 = {
        dateee : moment(new Date()).format("YYYY-MM-DD")
      }
      this.countdmr = 0
      this.dmrvalue = 0
      this.router.navigate(['/inventory/dmr'])
      this.pageIndex = 1;
      this.pageIndex1 = 1;
      this.editeddata = false;
    this.dataSourcemain.sort = this.sort;
      this.paginator.pageIndex = this.pageIndex - 1
      this.getData();
    } else if (this.demo1TabIndex === 2) {
      // alert(12);
      this.btn="Save"
      this.viewData = false
      this.columnname=""
      this.searchData=""
      this.columnnamepnd=""
      this.searchDatapnd=""
      this.columnnamegrnpnd=""
      this.searchDatagrnpnd=""
      this.logsearchData=""
      this.model1 = {
        dateee : moment(new Date()).format("YYYY-MM-DD")
      }
      this.saveddataarray = []
      this.dataSource.data = []
      this.SNO = []
      this.matCode = []
      this.matName = []
      this.UOM = []
      this.storageLocation = []
      this.InvoiceQuantity = []
      this.RecQTY = []
      this.shrQTY = []
      this.excsQTY = []
      this.RejQTY = []
      this.unitPrice = []
      this.discPERCENT = []
      this.discVALUE = []
      this.basicPRICE = []
      this.taxDESC = []
      this.taxPERCEN = []
      this.taxVALUE = []
      this.otherTAXSPLIT = []
      this.freightSPLIT = []
      this.otherchargesSPLIT = []
      this.othertaxSPLIT = []
      this.totalPRICE = []
      this.valuationType = []
      this.POID = []
      this.countdmr = 0
      this.dmrvalue = 0
      this.router.navigate(['/inventory/dmr'])
      this.pageIndex = 1;
      this.pageIndex1 = 1;
      this.editeddata = false;
    this.dataSourcemainpnd.sort = this.sort;
    this.getdatapnd();
    } else if (this.demo1TabIndex === 4) {
      // alert(12);
      this.btn="Save"
      this.viewData = false
      this.columnname=""
      this.searchData=""
      this.columnnamepnd=""
      this.searchDatapnd=""
      this.columnnamegrnpnd=""
      this.searchDatagrnpnd=""
      this.logsearchData=""
      this.saveddataarray = []
      this.dataSource.data = []
      this.SNO = []
      this.matCode = []
      this.matName = []
      this.UOM = []
      this.storageLocation = []
      this.InvoiceQuantity = []
      this.RecQTY = []
      this.shrQTY = []
      this.excsQTY = []
      this.RejQTY = []
      this.unitPrice = []
      this.discPERCENT = []
      this.discVALUE = []
      this.basicPRICE = []
      this.taxDESC = []
      this.taxPERCEN = []
      this.taxVALUE = []
      this.otherTAXSPLIT = []
      this.freightSPLIT = []
      this.otherchargesSPLIT = []
      this.othertaxSPLIT = []
      this.totalPRICE = []
      this.valuationType = []
      this.POID = []
      this.model1 = {
        dateee : moment(new Date()).format("YYYY-MM-DD")
      }
      this.countdmr = 0
      this.dmrvalue = 0
      this.router.navigate(['/inventory/dmr'])
      this.logpagesize = 10; 
      this.pageIndex = 1;   
      this.pageIndex1 = 1;
      this.editeddata = false;
    } else if (this.demo1TabIndex === 3) {
      this.btn="Save"
      this.viewData = false
      this.saveddataarray = []
      this.dataSource.data = []
      this.SNO = []
      this.columnname=""
      this.searchData=""
      this.columnnamepnd=""
      this.searchDatapnd=""
      this.columnnamegrnpnd=""
      this.searchDatagrnpnd=""
      this.logsearchData=""
      this.matCode = []
      this.matName = []
      this.UOM = []
      this.storageLocation = []
      this.InvoiceQuantity = []
      this.RecQTY = []
      this.shrQTY = []
      this.excsQTY = []
      this.RejQTY = []
      this.unitPrice = []
      this.discPERCENT = []
      this.discVALUE = []
      this.basicPRICE = []
      this.taxDESC = []
      this.taxPERCEN = []
      this.taxVALUE = []
      this.otherTAXSPLIT = []
      this.freightSPLIT = []
      this.otherchargesSPLIT = []
      this.othertaxSPLIT = []
      this.totalPRICE = []
      this.valuationType = []
      this.POID = []
      this.model1 = {
        dateee : moment(new Date()).format("YYYY-MM-DD")
      }
      this.countdmr = 0
      this.dmrvalue = 0
      this.router.navigate(['/inventory/dmr'])
      this.pageIndex = 1;
      this.pageIndex1 = 1;
      this.editeddata = false;
    this.dataSourcemaingrnpnd.sort = this.sort;
    this.getgoodslistpnd()
    }

  }
 
  sendtoQC() {
    let obj = {
      "command": "sqc",
      "number": this.viewnumber,
    }
    this.custservice.editmaterialreceiptdata(obj).subscribe((res: any) => {
      if (res) {
        this.alertcall.showSuccess('Accepted', res.message);
        this.model1.qualitycheck = true
        this.getData()
        this.getdatapnd();
        this.getgoodslistpnd()
      } else {
        this.alertcall.showWarning("Warning", res.message);
      }
    });
  }
  sendtoGRN() {
    let obj = {
      "command": "drg",
      "number": this.viewnumber,
    }
    this.custservice.editmaterialreceiptdata(obj).subscribe((res: any) => {
      if (res) {
        this.alertcall.showSuccess('Accepted', res.message);
        this.model1.directgrn = true
        this.getData()
        this.getdatapnd();
        this.getgoodslistpnd()
      } else {
        this.alertcall.showWarning("Warning", res.message);
      }
    })
  }
  revokeQC() {
    let obj = {
      "command": "cqc",
      "number": this.viewnumber,
    }
    this.custservice.editmaterialreceiptdata(obj).subscribe((res: any) => {
      if (res) {
        this.alertcall.showSuccess('Accepted', res.message);
        this.model1.qualitycheck = false
        this.getData()
        this.getdatapnd();
        this.getgoodslistpnd()
      } else {
        this.alertcall.showWarning("Warning", res.message);
      }
    });
  }
  revokeGRN() {
    let obj = {
      "command": "cgr",
      "number": this.viewnumber,
    }
    this.custservice.editmaterialreceiptdata(obj).subscribe((res: any) => {
      if (res) {
        this.alertcall.showSuccess('Accepted', res.message);
        this.model1.directgrn = false
        this.getData()
        this.getdatapnd();
        this.getgoodslistpnd()
      } else {
        this.alertcall.showWarning("Warning", res.message);
      }
    })
  }

  //qc pending search
  onChangepnd() {
    this.searchDatapnd = ""
  }
  searchpnd() {
    let obj = { "command": "pnd", "field": this.columnnamepnd, "key": this.searchDatapnd, "lmt": this.pageSize, "pid": this.pageIndex }
    if (this.searchDatapnd.length > 2) {
      this.custservice.getSearchQualityCheck(obj).subscribe((res: any) => {
        this.reaDatapnd = false;
        // this.totalRecords = res?.count;
        this.totalRecordspnd = res?.count;
        this.dataSourcemainpnd.data = res.data
        this.dataSourcemainpnd = new MatTableDataSource(res.data);
        if (res.data.length == 0) {
          this.reaDatapnd = true
        }
      })
    } else if (!this.searchDatapnd) {
      this.getdatapnd();
      this.columnname = ""
    }
  }
  //grn pending
  onpageeventgrnpnd(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    if(!this.searchDatagrnpnd){
      this.getgoodslistpnd()
    }else{
      this.searchgrnpnd()
    }
  }
  getgoodslistpnd() {
    let obj = {
      "command": "pnd",
      "pid": this.pageIndex,
      "lmt": this.pageSize,
    }
    this.custservice.getgoodsreceiptlist(obj).subscribe((res: any) => {
      this.reaDatagrnpnd = false;
      this.goodsreceptdata = res.data
      this.totalRecordsgrnpnd = res?.count;
      this.dataSourcemaingrnpnd.data = res.data
      this.dataSourcemaingrnpnd = new MatTableDataSource(res.data);
      this.columnnamegrnpnd = "";
      this.searchDatagrnpnd = "";
      this.dataSourcemaingrnpnd.sort = this.sort;
      if (res.data.length == 0) {
        this.reaDatagrnpnd = true
      }
    })
  }
  //search grn pending
  onChangegrnpnd() {
    this.searchDatagrnpnd = ""
  }
  searchgrnpnd() {
    let obj = { "command": "pnd", "field": this.columnnamegrnpnd, "key": this.searchDatagrnpnd, "lmt": this.pageSize, "pid": this.pageIndex }
    if (this.searchDatagrnpnd.length > 2) {
      this.custservice.getgoodsreceiptlist(obj).subscribe((res: any) => {
        this.reaDatagrnpnd = false;
        this.goodsreceptdata = res.data
        this.totalRecordsgrnpnd = res?.count;
        this.dataSourcemaingrnpnd.data = res.data
        this.dataSourcemaingrnpnd = new MatTableDataSource(res.data);
        if (res.data.length == 0) {
          this.reaDatagrnpnd = true
        }
      })
    } else if (!this.searchDatagrnpnd) {
      this.getgoodslistpnd()
      this.columnname = ""
    }
  }
 
  viewAttachment(data: any, number: any) {
    console.log(number);

    this.dialog.open(data, {
      width: '800px'
    })
    this.selectedPO = number
    this.getexistingpouploadedfiles(number)

  }
  viewDocPO(file: any) {
    // // const url = this.imageUrl + '/' + file.file_path;
    const url = file.file_path;
    window.open(url, '_blank');
  }

  getexistingpouploadedfiles(number: any) {
    let params = new HttpParams();
    params = new HttpParams()
      .set("document_number", this.selectedPO || number)
      .set("document_type", "Purchase Order")
    this.custservice.getexistingfies(params).subscribe((res: any) => {
      if (res && res['status_code'] == '200') {
        this.filedataPO = res.data
        this.createNewFile.fileName = ''
        this.fileUploadUrls = []
      } else {
        this.filedataPO = []
        console.log(this.filedataPO);
      }
    })
  }
  handleBackspace(event: KeyboardEvent): void {
    event.preventDefault();
  }
  




  

  onPrintOptionChange() {
    if (this.selectedPrintOptions === 'sticker') {
      this.selectedPrintOption = 1;
}
}
getRepeatedItems(): any[] {
  const itemCount = this.selectedPrintOption || 0;
  const result = [];

  for (let i = 0; i < itemCount; i++) {
    result.push(i);
  }
  return result;
}
 async printbtn( data:any, dialog: any , row:any){
    dialog = dialog
    this.storageloc=row;
    this.matrow= row.material_code 
     await this.getMasterData(row);
    this.dialog.open(dialog, {
      width:"500px"
    });
    this.editednumber = data
  }
  getMasterData(code:any) {
    let obj = {
      "command":"qrt",
      "number":this.dmrno,
      "material_code":this.matrow
    }
    console.log(this.selectedmaterial,"searchmasterdataaaa")
    this.custservice.getDailymaterialreceipt(obj).subscribe((res: any) => {
      this.totalmasterRecords = res;
       this.resImg = res.img
    })
  }
  getSafeImageURL(base64String: string): SafeResourceUrl {
    // Create a data URL using the Base64 string
    const imageUrl = 'data:image/jpg;base64,' + base64String;
    // Sanitize the URL for safe use
    return this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
  }
  openNewFileUploadModal(data: any) {
    this.dialog.open(data, {
      width: '800px'
    })
  }

  deleteFileItem(index:any){
    this.selectedfiles.splice(index,1)
   }
   trimInput(){
    this.ponumber = this.ponumber.trim();
    this.rgpnumber = this.rgpnumber.trim();
    this.copieddmr = this.copieddmr.trim();
    this.model1.companyname = this.model1.companyname.trim();
    this.model1.dateee = this.model1.dateee.trim();
    this.model1.ponumber = this.model1.ponumber.trim();
    this.model1.podate = this.model1.podate.trim();
    this.model1.vendorname = this.model1.vendorname.trim();
    this.model1['invoicenumber'] = this.model1['invoicenumber'].trim();
    this.model1.invdate = this.model1.invdate.trim();
    this.model1.packdetails = this.model1.packdetails.trim();
    this.model1.dcnumber = this.model1.dcnumber.trim();
    this.model1.dcdate = this.model1.dcdate.trim();
    this.model1.genumber = this.model1.genumber.trim();
    this.model1.gedate = this.model1.gedate.trim();
    this.model1.trsname = this.model1.trsname.trim();
    this.model1.irnumber = this.model1.irnumber.trim();
    this.model1.irdate = this.model1.irdate.trim();
    this.model1.vnumber = this.model1.vnumber.trim();
    this.model1.basicfreight = this.model1.basicfreight.trim();
    this.model1.frtaxdescription = this.model1.frtaxdescription.trim();
    this.model1.frtaxpercentage = this.model1.frtaxpercentage.trim();
    this.model1.frtaxvalue = this.model1.frtaxvalue.trim();
    this.model1.totalfreight = this.model1.totalfreight.trim();
    this.model1.othercharges = this.model1.othercharges.trim();
    this.model1.otherchargesval = this.model1.otherchargesval.trim();
    this.model1.waybillno = this.model1.waybillno.trim();
    this.model1.othertaxdescription = this.model1.othertaxdescription.trim();
    this.model1.othertaxper = this.model1.othertaxper.trim();
    this.model1.othertaxvalue = this.model1.othertaxvalue.trim();
    this.model1.netweight = this.model1.netweight.trim();
    this.model1.grn_number = this.model1.grn_number.trim();
    this.model1.grdate = this.model1.grdate.trim();
    this.model1.doc_reference = this.model1.doc_reference.trim();
    this.model1.boe_number =  this.model1.boe_number.trim();
    this.model1.boe_date = this.model1.boe_date.trim();
    this.model1.doc_date = this.model1.doc_date.trim();
    this.model1.boe_number =  this.model1.boe_number.trim();
    this.model1.boe_date = this.model1.boe_date.trim();
    this.model1.comments = this.model1.comments.trim();
   }
   savedisable(){
    if(!this.model1.companyname || !this.model1.dateee || !this.model1.ponumber || !this.model1.podate || !this.model1.vendorname || !this.model1.invoicenumber || !this.model1.invdate || !this.model1.packdetails || !this.model1.genumber || !this.model1.gedate){
      return true
    }
    else{
      return false
    }
   }
}
