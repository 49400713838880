import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerService } from 'src/app/services/customer.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from 'src/app/shared/loader/loader.service';
import { AlertCallsService } from 'src/app/auth/alert-calls.service';
@Component({
  selector: 'app-qrprint-dmr',
  templateUrl: './qrprint-dmr.component.html',
  styleUrls: ['./qrprint-dmr.component.scss']
})
export class QrprintDmrComponent implements OnInit {

  data: any;

  dmrnum: any;
  number: '';
  matrow: any;
  isPrintEnable: boolean = false;
  totalmasterRecords: any[]=[];
  storageloc:any[]=[];
  dataSource = new MatTableDataSource();
  saveddataarray: any[]=[];
  resImgLoaded: boolean = false;
  editednumber: any;
  imageUrls: string[] = [];

  selectedPrintOption: number=1;
  matcode: any;
  // imageUrls: never[];
  
  printQty: number;
  resImg: any;
  printqutno: any;
  mattcode: any[]=[];
  dmrno: any;
  matcodee: any;
  dmrnoo: any;
  changedPrintValue: any;
  printValueArray: any[] =[]
  subsubmit: boolean;
  constructor( private custservice: CustomerService,private dialog: MatDialog,public loader: LoaderService,private alertcall: AlertCallsService,) { }
  public config = {
    printMode: 'template',
    popupProperties: "window.open",
    stylesheets: [{ rel: 'stylesheet', href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' },

   ],
  };
  @ViewChild('printContent') printContent: ElementRef;
  ngOnInit(): void {
    this.getData()
    this.onSearch(event);
  }
  displayedColumns: any[] = [
    'sno',
    'dmr_no',
    'material_code',
    'material_description',
    'unit_of_measurement',
    'received_quantity',
    'storage_location',
    'print',
    'Action'
    ]
 
  getData() {
    let obj = {
      "command": "lst",
      "lmt": 100,
      "pid": 1,
      key: ""
    }
    this.custservice.getmattransnamedata(obj).subscribe((res: any) => {
      this.data = res.data
      this.filteredData = this.data;
      this.dmrnum=this.data.number
      console.log(this.number,"dattttttttttttt")
    })
  }
  filteredData: any[] = [];

  onSearch(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    this.filteredData = this.data.filter((item: any) => item.number.toLowerCase().includes(searchTerm));
  }
//   tabledata() {
//     let obj = {
//         "command": "mat",
//         "key": this.number,
//         "field": "number"
//     };

//     this.custservice.editmaterialreceiptdata(obj).subscribe((res: any) => {
//         this.data = res.data
//         let newData = res.data; 
//         this.matcode = newData[0].material_code
//         console.log(this.matcode,"bbbbbbbbb")
//         for (let item of newData) {
//             this.saveddataarray.push({'printQty':3,...item}); 
//             this.mattcode.push(this.saveddataarray[0].material_code)
//         }
//         this.dataSource.data = this.saveddataarray;
//         this.printqutno=this.saveddataarray[0].printQty
//         console.log( this.mattcode, "dayyiii");
//         console.log(this.saveddataarray[0].material_code,"hhsshhhhhhhhhh")
//     });
// }

tabledata() {
  let obj = {
      "command": "mat",
      "key": this.number,
      "field": "number"
  };

  this.custservice.editmaterialreceiptdata(obj).subscribe((res: any) => {
      this.data = res.data;
      let newData = res.data; 
      this.matcode = newData[0].material_code;
      console.log(this.matcode, "bbbbbbbbb");
      for (let item of newData) {
        let existingItem = this.saveddataarray.find(savedItem => savedItem.id === item.id);
        if (!existingItem) {
          this.saveddataarray.push({'printQty': 1, ...item}); 
          // this.mattcode.push(item.material_code); 
          // this.printValueArray = this.saveddataarray.map((ele:any) => ele.printQty)
        }
        else{
          this.alertcall.showWarning(existingItem.number,"DMR Number is exist");
        }
      }
      this.dataSource.data = this.saveddataarray;
      this.printqutno = this.saveddataarray[0].printQty;
      this.isPrintEnable = true;
      this.number=''
      this.getData()
      // setTimeout(() => {
      //   this.loader.show()
      //   this.getMasterData('');
      //   this.loader.hide()
      // }, 100);
      this.getMasterData('');
  });
}

changePrintQty(eachRow:any){
  this.getMasterData('');
}

getMasterData(data:any) {
  // this.loader.show()
  this.subsubmit=true

let listArray: any[]= [];
for (let item of this.saveddataarray) {
  listArray.push({"material_code": item.material_code,  "number": item.number, "id":item.id });
}
  let obj:any={
    "command": "qrm",
    //  showLoader: 'hide',
    "list": listArray
  }
  if(data === ''){
    obj.showLoader ='hide'
  }
  this.custservice.getDailymaterialreceipt(obj).subscribe((res: any) => {
    // this.loader.show();
    this.imageUrls = [];
   
    if(data !== ''){
      this.submitpopup(data)
    
    }
    console.log('Response123:');
    res.image_list.map((rec: any)=> {
      var matIndex = this.saveddataarray.findIndex((item) => item.id === rec.id);
      console.log(this.saveddataarray[matIndex], "********************");
      if(matIndex >= 0) {
        this.resImg = rec.img;
        let j = 0;
        for (let i = 0; i < Number(this.saveddataarray[matIndex]['printQty']) ; i++) {
          console.log(typeof(this.saveddataarray[matIndex]['printQty']));
          this.imageUrls.push(this.resImg);
          j = i+1
          if(j == this.saveddataarray[matIndex]['printQty']){
          }
        } 
      }
    })
    this.subsubmit=false
  });
}
getMasterDataa(){
  this.saveddataarray = [];
  this.dataSource.data = [];
  this.isPrintEnable = false;
}

submitpopup(data: any) {
  this.dialog.open(data, {
    width: "400px",
   
  });
  // setTimeout(() => {
  //   this.getMasterData()
  // }, 100);
}
closemodel() {
  this.dialog.closeAll()
}


gettotalCount(){
  let count = 0
  for(let each of this.saveddataarray){

    count = count+ each.printQty
  }
  return count

}
deleteItem(row: any) {
  const index = this.saveddataarray.findIndex(item => item.id === row.id);
  if (index !== -1) {
    this.saveddataarray.splice(index, 1); 
    this.dataSource.data = this.saveddataarray; 
  };
  this.alertcall.showSuccess('Accepted','Record Deleted Successfully')
  this.getMasterData('');
}



}
