// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    //  url: 'https://testgeps.greenko.net/app/api', //test
  
    //  url: 'https://gepsv2.greenkogroup.com/app/api',  //v2
    url: "https://mms.intellaire.com/api", //live
    // url: "http://10.80.17.84:8000/api",
    // url: "http://10.80.14.84:8001/api",
    // sso_url: 'https://testgeps.greenko.net', //test
    // sso_url:'https://gepsv2.greenkogroup.com',  //v2
    sso_url: "https://mms.intellaire.com", //live
    // sso_url: "http://10.80.14.84:8000/api",
    // sso_url: "http://10.80.14.84:8001/api",
    // sso_api : 'http://logintest.gits.lan/',
    // sso_api: "https://qa-ssov2.intellaire.com/ssov2", //common for both
    sso_api:"https://gee.greenko.net/ssov2",
    // sso_api:"https://testgeps.greenko.net",
    // sso_api: "http://10.80.14.22:8000/api",
    // sso_api: "http://10.80.14.84:8000/api",
    // sso_api: "http://10.80.14.84:8001/api",
    //  base_url: 'https://testgeps.greenko.net', //test
    //  base_url:'https://gepsv2.greenkogroup.com',  //v2
    base_url: "https://mms.intellaire.com/", //live
    // base_url: "http://10.80.14.84:8000/api",
    // base_url: "http://10.80.14.84:8001/api",
    dms_base_url: "https://mms.intellaire.com/app/dms",
    // dms_base_url: "http://10.80.16.121:5000/",
    subfile_url: "https://digitele-geps-dms.s3.amazonaws.com/public",
    rmc_base_url:'https://mms.intellaire.com/geps/rmc',
    // rmc_base_url:'http://10.91.97.24:4116/api',
    // rmc_base_url:'https://qa-mms.intellaire.com/apiv2', //test
    // Project Dashboard
    pdashboard_url: "https://testgeps.greenko.net",
    logistics_url:"https://mms.intellaire.com/app/logistics",
    socket_url:"wss://mms.intellaire.com",
    clients:{
        'mms-admin':{
            url: "https://login.intellaire.com/",
            realm:"mms-admin",
            clientId: "geps",
        }
    },
    logoimageUrl:"",
    isnonGroup:"yes",
    amGreen: false,
}
  