<!-- <div class="page-breadcrumb page-header">
    <div class="row">
        <div class="col-12 align-self-center">
            <h4 class="page-title">Daily Material Receipt</h4> -->
<!-- <div class="d-flex align-items-center m-t-10">
                <span class="m-r-20">Start Date : {{startDate | date}} </span>
                <span>End Date : {{endDate | date}} </span>
            </div> -->
<!-- </div>
    </div>
</div> -->
<div class="material-tab">
    <mat-tab-group [backgroundColor]="'primary'" animationDuration="0ms" #tabGroup [(selectedIndex)]="demo1TabIndex"
        (selectedTabChange)="onTabChanged($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                Daily Material Receipt
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <div class="row ">
                            <span *ngIf="viewData" class="col-md-0 ml-2 mb-2 ">
                                <button mat-raised-button type="button" (click)="BacktoList()"
                                    class="savebutton">Back</button>
                            </span>
                            <span *ngIf="viewData && !model1.qualitycheck" class="col-md-0 ml-2 mb-2 ">
                                <button mat-raised-button type="submit" (click)="sendtoQC()"
                                    class="savebutton" [disabled]="model1.qualitycheck">Send To QC</button>
                            </span>
                            <span *ngIf="viewData && model1.qualitycheck" class="col-md-0 ml-2 mb-2 ">
                                <button mat-raised-button type="submit" (click)="revokeQC()"
                                    class="savebutton" [disabled]="!model1.qualitycheck">Revoke QC</button>
                            </span>
                            <span *ngIf="viewData && !model1.directgrn" class="col-md-0 ml-2 mb-2 ">
                                <button mat-raised-button type="submit" (click)="sendtoGRN()"
                                    class="savebutton" [disabled]="model1.directgrn">Send To Direct GRN</button>
                            </span>
                            <span *ngIf="viewData && model1.directgrn" class="col-md-0 ml-2 mb-2 ">
                                <button mat-raised-button type="submit" (click)="revokeGRN()"
                                    class="savebutton" [disabled]="!model1.directgrn">Revoke Direct GRN</button>
                            </span>
                            <div *ngIf="viewData" class="col mt-1 text-right">
                                <p *ngIf="viewData" style="font-size: 16px;font-weight: 500;">View of Document Number:
                                    {{viewnumber}}</p>
                            </div>
                            <button *ngIf="!viewData" mat-raised-button class="savebutton" color="secondary"
                                style="margin-bottom: 2%;margin-left: 10px;" [disabled]="saveddataarray.length==0 || savedisable()"
                                (click)="savefinaldata()">{{btn}}</button>
                        
                            <span *ngIf="!viewData" class="col-md-0 ml-2 mb-2 ">
                                <button mat-raised-button type="submit" (click)="clearpage1()"
                                    class="savebutton">Add New</button>
                            </span>
                            <!-- <span style="margin-left: 1%;"> -->
                            <button *ngIf="!viewData" mat-raised-button type="submit" class="savebutton"
                                (click)="copydmr(copydmrmodel)" style="margin-top:0%;left:1%;margin-bottom:2%;">Copy DMR
                                <!-- <span class="material-icons">content_copy</span> -->
                            </button>
                            <!-- </span> -->

                            <!-- <span style="margin-left: 1%;">  -->
                            
                            <!-- </span> -->
                            <!-- <span class="col-md-6"></span> -->
                            <!-- <span class="col-md-6">  -->
                            <button *ngIf="!viewData" mat-raised-button class="savebutton"
                                style="left:2%;margin-bottom: 2%;" color="secondary"
                                (click)="openpurchaseordermodel(pomodel)">Select Purchase
                                Order</button>
                            <button *ngIf="!viewData" mat-raised-button class="savebutton"
                                style="left:3%;margin-bottom: 2%;" color="secondary"
                                (click)="openrgpmodel(rgpmodel)">RGP Return</button>
                            <!-- </span> -->

                            <div *ngIf="!viewData" class="col-md-0 attachFilediv">
                                <div *ngIf="!viewData" class="col-md-0 ml-0 mb-2">
                                  <button mat-raised-button color="secondary" type="button"
                                      class="attachfile savebutton button1"
                                      (click)="openNewFileUploadModal(newFileUploadModal)">Attach File&nbsp;
                                  </button>
              
                              </div>
                              </div>

                            <!-- <div class="attachFilediv"> -->
                            <!-- <button *ngIf="!viewData" type="button" mat-raised-button class="attachfile mt-0"
                                color="secondary" style="margin-bottom: 2%;" (click)="autoFileUpload2.click()">
                                Attach File&nbsp; <mat-icon
                                    class="attachFileIcon">file_upload</mat-icon></button> -->
                            <!-- <div class="file">
                                <input hidden class="form-control-file" name="file" type="file" multiple
                                    (change)="uploaddmrfiles($event)" #autoFileUpload2 accept=".doc, .pdf, .jpeg, .jpg, .png, .xlsx">
                                <ng-container *ngIf=" fileUploadUrlsdmr.length > 0"> -->
                                    <!-- <span style="margin-left:3px">Selected : {{fileUploadUrlsdmr.length}} files</span>  -->
                                    <!-- <table>
                                    <tr class="text-center">
                                        <th>SNO</th>
                                        <th>FileName</th>
                                    </tr>
                                    <tr *ngFor="let item of filenamearray;let i=index;">
                                    <td>{{i+1}}</td>
                                    <td>{{item}}</td>
                                    </tr>
                                  </table> -->
                                    <!-- <div *ngFor="let item of filenamearray;let i=index;">{{i+1}}.&nbsp;{{item}}</div>
                                </ng-container>
                            </div>
                        </div> -->
                            <div *ngIf="!viewData && txnId" class="col mt-1 text-right">
                                <p *ngIf="!viewData && txnId">Transaction Id: {{txnId}}</p>
                            </div>
                        </div>

                        <form #myform="ngForm">
                            <div class="row mt-2 mb-2" *ngIf="!viewData && editednumber">
                                <div class="col-md-12">
                                    <p  style="font-size: 16px;font-weight: 500;">Change Mode:
                                        {{editednumber}}</p>
                                </div>
                            </div>
                            <div class="row mb-3 mt-2">
                                <div class="col-md-6">
                                    <label>Company Name<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.companyname}}</p>
                                    <select *ngIf="!viewData" class="dropdownstyles" name="companyname" (input)="trimInput()"
                                        [(ngModel)]="model1.companyname">
                                        <option [value]="undefined" selected disabled>Select One</option>
                                        <option *ngFor="let item of companyData" [ngValue]="item.company_name">
                                            {{item.company_name}}
                                        <option *ngFor="let item of projects" [ngValue]="item">{{item}}
                                        </option>
                                    </select>

                                    <!-- <mat-form-field class="example-full-width" style=" width:100%;
                                    border: 1px solid rgba(0, 0, 0, 0.12);
                                    border-radius: 5px;
                                    height: 35px;" appearance="none">
                                    <input type="text" placeholder="Search Company Name " aria-label="Number" matInput
                                      style="border:none" class="inputdropdown1" name="companyname"
                                      [(ngModel)]="model1.companyname" (keyup)="selectedCompanyData($event)"
                                      [matAutocomplete]="auto222" />
                
                                    <mat-autocomplete autoActiveFirstOption #auto222="matAutocomplete"
                                    >
                
                                      <mat-option *ngFor="let item of companyData" [ngValue]="item.company_name">
                                        {{item.company_name}}</mat-option>
                                    </mat-autocomplete>
                                  </mat-form-field> -->
                                </div>
                                
                                <div class="col-md-3">
                                    <label>Purchase Order Number<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.ponumber}}</p>
                                    <div *ngIf="!viewData" class="d-flex">
                                        <input  type="text" placeholder="Enter Purchase Order No" (input)="trimInput()"
                                            class="inputstyles" name="ponumber" [(ngModel)]="model1.ponumber">
                                     <i ngbTooltip="View Files" placement="bottom" *ngIf="model1.ponumber" class="fas fa-eye viewAttachment" aria-hidden="true" (click)="viewAttachment(viewFilemodel,model1.ponumber)"></i>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label>Purchase Order Date<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.podate}}</p>
                                    <input *ngIf="!viewData" type="date" style="text-transform: uppercase;" (input)="trimInput()"
                                        class="inputstyles" name="podate" [(ngModel)]="model1.podate" [max]="today">
                                </div>
                               
                            
                           
                                <div class="col-md-6">
                                    <label>Vendor Name<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.vendorname}}</p>
                                    <!-- <select class="dropdownstyles" name="vendorname" [(ngModel)]="model1.vendorname">
                                        <option [value]="undefined" selected disabled>Select One</option>
                                        <option *ngFor="let item of vendorData" [ngValue]="item.name">{{item.name}}
                                        </option>
                                    </select> -->
                                    <mat-form-field *ngIf="!viewData" class="example-full-width" style=" width:100%;
                                    border: 1px solid rgba(0, 0, 0, 0.12);
                                    border-radius: 5px;
                                    height: 35px;" appearance="none">
                                        <input type="text" placeholder="Search Vendor Name " aria-label="Number" (input)="trimInput()"
                                            matInput style="border:none" class="inputdropdown1" name="vendorname"
                                            [(ngModel)]="model1.vendorname" (keyup)="getvendordropdowndata($event)"
                                            [matAutocomplete]="auto221" />

                                        <mat-autocomplete autoActiveFirstOption #auto221="matAutocomplete">

                                            <mat-option *ngFor="let item of vendorData" [value]="item.name">
                                                {{item.name}}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <label>Date<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.dateee}}</p>
                                    <input *ngIf="!viewData" type="date" style="text-transform: uppercase;" (input)="trimInput()"
                                        class="inputstyles" name="dateee" [(ngModel)]="model1.dateee" [max]="today"
                                        dateFormat="yyyy/mm/dd">
                                </div>
                                <div class="col-md-3">
                                    <label>Invoice Number<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.invoicenumber}}</p>
                                    <input *ngIf="!viewData" type="text" placeholder="Enter Invoice No" 
                                        class="inputstyles" name="invoicenumber" [(ngModel)]="model1.invoicenumber" (input)="trimInput()">
                                </div>
                                <div class="col-md-3">
                                    <label>Invoice Date<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.invdate}}</p>
                                    <input *ngIf="!viewData" type="date" style="text-transform: uppercase;" (input)="trimInput()"
                                        class="inputstyles" name="invdate" [(ngModel)]="model1.invdate" [max]="today">
                                </div>
                                <div class="col-md-3">
                                    <label>Packing Details<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.packdetails}}</p>
                                    <input *ngIf="!viewData" type="text" placeholder="Enter Packing Details" (input)="trimInput()"
                                        class="inputstyles" name="packdetails" [(ngModel)]="model1.packdetails">
                                </div>
                           
                                <div class="col-md-3">
                                    <label>DC Number</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.dcnumber}}</p>
                                    <input *ngIf="!viewData" type="text" placeholder="Enter DC No" class="inputstyles" (input)="trimInput()"
                                        name="dcnumber" [(ngModel)]="model1.dcnumber">
                                </div>
                                <div class="col-md-3">
                                    <label>DC Date</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.dcdate}}</p>
                                    <input *ngIf="!viewData" type="date" style="text-transform: uppercase;" (input)="trimInput()"
                                        class="inputstyles" name="dcdate" [(ngModel)]="model1.dcdate" [max]="today">
                                </div>
                                <div class="col-md-3">
                                    <label>Gate Entry Number<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.genumber}}</p>
                                    <mat-form-field *ngIf="!viewData" class="example-full-width " appearance="none"
                                        class="matformfieldstyles">
                                        <input type="text" placeholder="Search Gate Entry Number " aria-label="Number" (input)="trimInput()"
                                            style="height:35px !important" matInput style="border:none"
                                            class="inputdropdown" name="genumber" [(ngModel)]="model1.genumber"
                                            (keyup)="getGEN($event)" [matAutocomplete]="auto1" />
                                        <!-- (keyup)="checkPin($event)"  -->
                                        <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"
                                            (optionSelected)='getselectedvalue()'>

                                            <mat-option *ngFor="let item of GENDATA" [value]="item.number"
                                                multiple>{{item.number}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <label>Gate Entry Date<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.gedate}}</p>
                                    <input *ngIf="!viewData" type="date" style="text-transform: uppercase;" (input)="trimInput()"
                                        class="inputstyles" name="gedate" [(ngModel)]="model1.gedate" [max]="today">
                                </div>
                          
                                <div class="col-md-3">
                                    <label>Transporter Name</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.trsname}}</p>
                                    <mat-form-field *ngIf="!viewData" class="example-full-width " appearance="none"
                                        class="matformfieldstyles">
                                        <input type="text" placeholder="Search Transporter Name " aria-label="Number" (input)="trimInput()"
                                            matInput style="border:none" class="inputdropdown" name="trsname"
                                            [(ngModel)]="model1.trsname" (keypress)="gettransportDATA1($event)"
                                            [matAutocomplete]="auto" />
                                        <!-- (keyup)="checkPin($event)"  -->
                                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">

                                            <mat-option *ngFor="let item of transporterName"
                                                [value]="item.transporter_name" multiple>{{item.transporter_name}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <label>LR Number</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.irnumber}}</p>
                                    <input *ngIf="!viewData" type="text" class="inputstyles" name="irnumber" (input)="trimInput()"
                                        placeholder="Enter your LR Number" [(ngModel)]="model1.irnumber">
                                </div>
                                <div class="col-md-3">
                                    <label>LR Date</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.irdate}}</p>
                                    <input *ngIf="!viewData" type="date" style="text-transform: uppercase;" (input)="trimInput()"
                                        class="inputstyles" name="irdate" [(ngModel)]="model1.irdate" [max]="today">
                                </div>
                                <div class="col-md-3">
                                    <label>Vehicle Number</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.vnumber}}</p>
                                    <mat-form-field *ngIf="!viewData" class="example-full-width matformfield"
                                        appearance="none" class="matformfieldstyles">
                                        <input type="text" placeholder="Search Vehicle Number " aria-label="Number" (input)="trimInput()"
                                            matInput style="border:none" class="inputdropdown" name="vnumber"
                                            [(ngModel)]="model1.vnumber" (keyup)="getvehiclenumDATA1($event)"
                                            [matAutocomplete]="auto1" />

                                        <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">

                                            <mat-option *ngFor="let item of vehicleNum" [value]="item.vehicle_number">
                                                {{item.vehicle_number}}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                         
                                <div class="col-md-3">
                                    <label>Basic Freight</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.basicfreight}}</p>
                                    <input *ngIf="!viewData" type="text" class="inputstyles" name="basicfreight" (input)="trimInput()"
                                        placeholder="Enter your Basic Freight" [(ngModel)]="model1.basicfreight"
                                        (keypress)="keyPressNumbers($event)" (keyup)="selectedbasicfreight($event)">
                                </div>
                                <div class="col-md-3">
                                    <label>Freight Tax Description</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.frtaxdescription}}</p>
                                    <select *ngIf="!viewData" class="dropdownstyles" name="frtaxdescription" (input)="trimInput()"
                                        [(ngModel)]="model1.frtaxdescription" (change)="selectedfreighttax()">
                                        <option [value]="undefined" selected disabled>Select One</option>

                                        <option *ngFor="let item of taxData" [ngValue]="item.code">{{item.code}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label>Freight Tax Percentage</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.frtaxpercentage}}</p>
                                    <input *ngIf="!viewData" type="text" class="inputstyles" name="frtaxpercentage" (input)="trimInput()"
                                        placeholder="Enter your Tax percentage " [(ngModel)]="model1.frtaxpercentage"
                                        (keypress)="keyPressNumbers($event)">

                                </div>
                                <div class="col-md-3">
                                    <label>Freight Tax Value</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.frtaxvalue}}</p>
                                    <input *ngIf="!viewData" type="text" class="inputstyles" name="frtaxvalue" (input)="trimInput()"
                                        placeholder="Enter your Tax Value" [(ngModel)]="model1.frtaxvalue">
                                </div>
                           

                                <div class="col-md-3">
                                    <label>Total Freight</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.totalfreight}}</p>
                                    <input *ngIf="!viewData" type="text" class="inputstyles" name="totalfreight" (input)="trimInput()"
                                        placeholder="Enter your Total Freight" [(ngModel)]="model1.totalfreight"
                                        readonly>
                                </div>

                                <div class="col-md-3">
                                    <label>Other&nbsp;Charges&nbsp;Description</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.othercharges}}</p>
                                    <input *ngIf="!viewData" type="text" class="inputstyles" name="othercharges" (input)="trimInput()"
                                        placeholder="Enter your Charges " [(ngModel)]="model1.othercharges">
                                </div>
                                <div class="col-md-3">
                                    <label>Other Charges Value</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.otherchargesval}}</p>
                                    <input *ngIf="!viewData" type="text" class="inputstyles" name="otherchargesval" (input)="trimInput()"
                                        placeholder="Enter your Charges  Value" [(ngModel)]="model1.otherchargesval"
                                        (keypress)="keyPressNumbers($event)" (keyup)="getTaxValue()">

                                </div>
                                <div class="col-md-3">
                                    <label>Way Bill No</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.waybillno}}</p>
                                    <!-- <mat-form-field class="example-full-width"> -->
                                    <input *ngIf="!viewData" class="inputstyles" type="text" (input)="trimInput()"
                                        placeholder="Enter Way Bill No" name="waybillno" [(ngModel)]="model1.waybillno">
                                    <!-- </mat-form-field> -->
                                </div>
                          
                                <div class="col-md-3">
                                    <label>Other Tax Description</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.othertaxdescription}}</p>
                                    <select *ngIf="!viewData" class="dropdownstyles" name="othertaxdescription" (input)="trimInput()"
                                        [(ngModel)]="model1.othertaxdescription" (change)="selectedTAXDESC()">
                                        <option [value]="undefined" selected disabled>Select One</option>

                                        <option *ngFor="let item of taxData" [ngValue]="item.code">{{item.code}}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-md-3">
                                    <label>Other Tax Percentage</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.othertaxper}}</p>
                                    <input *ngIf="!viewData" type="texst" class="inputstyles" name="othertaxper" (input)="trimInput()"
                                        placeholder="Enter your Tax Percentage" [(ngModel)]="model1.othertaxper"
                                        (keypress)="keyPressNumbers($event)">

                                </div>

                                <div class="col-md-3">
                                    <label>Other Tax Value</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.othertaxvalue}}</p>
                                    <input *ngIf="!viewData" type="text" class="inputstyles" name="othertaxvalue" (input)="trimInput()"
                                        placeholder="Enter your Tax Value " [(ngModel)]="model1.othertaxvalue"
                                        (keypress)="keyPressNumbers($event)">
                                </div>
                                <div class="col-md-3">
                                    <label>Net Weight In Kgs</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.netweight}}</p>
                                    <input *ngIf="!viewData" type="text" class="inputstyles" name="netweight" [(ngModel)]="model1.netweight" placeholder="Enter Net Weight"
                                        (keypress)="keyPressNumbers($event)" (input)="trimInput()">
                                </div>
                                
                        
                                <!-- <div class="col-md-3">
                                    <label>Quality Check<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.qualitycheck}}</p>
                                    <select *ngIf="!viewData" class="dropdownstyles" name="qualitycheck"
                                        [(ngModel)]="model1.qualitycheck" [value]='selected'>
                                        <option [value]="undefined" selected disabled>Select One</option>
                                        <option [ngValue]="false">No</option>
                                        <option [ngValue]="true">Yes</option> -->
                                        <!-- <option *ngFor="let item of classDATA" [ngValue]="item">{{item.code}}</option> -->
                                    <!-- </select> -->
                                    <!-- <select class="dropdownstyles" name="qualitycheck"
                                [(ngModel)]="model1.qualitycheck"  formControlName="qc">
                                    <option *ngFor="let item of qc" [value]="item.value" >{{item.key}}</option>
                                    </select> -->
                                <!-- </div> -->
                                <div class="col-3">
                                    <label>Goods Receipt Number</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.grn_number}}</p>
                                    <input *ngIf="!viewData" type="text" placeholder="Enter Goods Receipt Number" (input)="trimInput()"
                                        class="inputstyles" name="gr_number" [(ngModel)]="model1.grn_number">
                                </div>
                                <div class="col-md-3">
                                    <label>Goods Receipt Date</label>
                                    <p *ngIf="viewData" class="viewdata">{{model1.grdate}}
                                    </p>
                                    <input *ngIf="!viewData" type="date" style="text-transform: uppercase;" (input)="trimInput()"
                                        class="inputstyles" name="grdate" [(ngModel)]="model1.grdate"                
                                        [min]="formatDate1(model1.dateee)" 
                                        >
                                </div>
                                <div class="col-md-3">
                                    <label>Document Dispatch Reference</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.doc_reference}}</p>
                                    <input *ngIf="!viewData" type="text" placeholder="Enter Document Dispatch Reference" (input)="trimInput()"
                                        class="inputstyles" name="doc_reference" [(ngModel)]="model1.doc_reference">
                                </div>
                                <div class="col-md-3">
                                    <label>Document Dispatch Date</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.doc_date}}</p>
                                    <input *ngIf="!viewData" type="date" style="text-transform: uppercase;" (input)="trimInput()"
                                        class="inputstyles" name="doc_date" [(ngModel)]="model1.doc_date"                            
                                                     [min]="formatDate1(model1.grdate)" 
                                        >
                                </div>
                                
                          
                                <div class="col-md-3" *ngIf="!hidebenumber">
                                    <label>BOE Number</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.boe_number}}</p>
                                    <input *ngIf="!viewData" type="text" placeholder="Enter BOE Number" (input)="trimInput()"
                                        class="inputstyles" name="boe_number" [(ngModel)]="model1.boe_number">
                                </div>
                                <div class="col-md-3" *ngIf="!hidebenumber">
                                    <label>BOE Date</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.boe_date}}</p>
                                    <input *ngIf="!viewData" type="date" style="text-transform: uppercase;" (input)="trimInput()"
                                        class="inputstyles" name="boe_date" [(ngModel)]="model1.boe_date" [max]="today">
                                </div>
                                <div class="col-md-6" style="display:grid">
                                    <label>Comments</label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.comments}}</p>
                                    <textarea rows="2" cols="50" style="resize:none" *ngIf="!viewData" type="text" placeholder="Enter Comments" (input)="trimInput()"
                                        class="inputstyles" name="comments" [(ngModel)]="model1.comments"></textarea>
                                </div>
                                <!-- <div class="col-md-3">
                                    <label>Direct GRN<span class="mandetory">*</span></label>
                                    <p *ngIf="viewData" class="viewdata"> {{model1.directgrn}}</p>
                                    <select *ngIf="!viewData" class="dropdownstyles" name="directgrn"
                                        [(ngModel)]="model1.directgrn" [value]='selected' (change)="directgrn()">
                                        <option [value]="undefined" selected disabled>Select One</option>
                                        <option [ngValue]="false">No</option>
                                        <option [ngValue]="true" >Yes</option>
                                    </select>
                                </div> -->
                            </div>
                            <div class="row " >
                                <div class="col-12">
                                    <div class="row p-2 m-1" style="border:1px solid red">
                                       <div class="col-12 mb-2 text-center">
                                        <h6 class="textHO">These data will be updated in HO</h6>
                                       </div>
                                            <div class="col-4">
                                                <label>HO Receipt Date</label>
                                                <p *ngIf="viewData" class="viewdata"> {{model1.ho_received_date}}</p>
                                                <input *ngIf="!viewData" type="date" style="text-transform: uppercase;"
                                                    class="inputstyles" name="ho_received_date" [(ngModel)]="model1.ho_received_date" 
                                                    [min]="formatDate1(model1.doc_date)" 
                                                    (keydown)="handleBackspace($event)">
                                            </div>
                                            <div class="col-4">
                                                <label>F&A submission Date</label>
                                                <p *ngIf="viewData" class="viewdata"> {{model1.fa_submitted_date}}</p>
                                                <input *ngIf="!viewData" type="date" style="text-transform: uppercase;"
                                                    class="inputstyles" name="fa_submitted_date" [(ngModel)]="model1.fa_submitted_date" (keydown)="handleBackspace($event)"
                                                    [min]="formatDate1(model1.ho_received_date)" 
                                                    >
                                            </div>
                                            <div class="col-4">
                                                <label>Submitted to</label>
                                                <p *ngIf="viewData" class="viewdata"> {{model1.fa_submitted_to}}</p>
                                                <input *ngIf="!viewData" type="text" placeholder="Enter Submitted To"
                                                class="inputstyles" name="fa_submitted_to" [(ngModel)]="model1.fa_submitted_to">
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <br>

                        <!-- <div class="row">
                            <div class="col-md-2">
                                <label>Total Value:</label>
                            </div>
                            <div class="col-md-4">
                                {{gettotalvalue()}}
                            </div>
                            <div class="col-md-2">
                                <label>No Of Items:</label>
                            </div>
                            <div class="col-md-4">
                                {{saveddataarray.length}}
                            </div>
                        </div> -->
                        <!-- <button mat-raised-button class="m-t-10" class="savebutton" style="width:6%;background-color: rgba(0, 0, 0, 0.12)" color="secondary" (click)="saveupdateddata()">Update</button><br> -->
                        <div class="row p-10">
                            <!-- <div class="col-6">
        <input type="search" class="searchfield"  placeholder="Search By Name/Code" [(ngModel)]="searchData" (input)="searchdata()">
    </div> -->
                            <div class="col-3">
                                <p>No of Items:{{countdmr}}</p>
                            </div>
                            <div class="col-3">
                                <p>DMR Value:{{dmrvalue | number:'1.2-2'}}</p>
                            </div>
                            <div *ngIf="!viewData" class="col-6  float-right text-right">

                                <button style="margin-right:1%" mat-icon-button value="Add" matTooltip="Add"
                                    class="custom-icon-button " (click)="addmaterialreceipt()&&'panelOpenState === true'">
                                    <!-- matreceiptmodel -->
                                    <span class="material-icons">
                                        add
                                    </span>
                                </button>


                            </div>

                        </div>
                        <div class="row" *ngIf="!viewData">
                            <div class="col-12">
                                <mat-accordion *ngIf="intialData">
                                    <mat-expansion-panel [expanded]="panelOpenState" (opened)="panelOpenState = true"
                                        (closed)="panelOpenState = false" #matExpansionPanel1>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title style="font-weight:bold"  (click)="addmaterialreceipt()">
                                                Add Items
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <!-- <p>I'm visible because I am open</p> -->
                                        <div class="mt-3" *ngIf="intialData">
                                            <form #myaddform="ngForm" (ngSubmit)="saveaddeddata(myaddform)">
                                                <div class="row mb-2">
                                                    <div class="col-md-2">
                                                        <label>Material Code/Name<span class="mandetory">*</span></label>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <mat-form-field class="example-full-width" style="width: 100%; border: 1px solid rgba(0, 0, 0, 0.12); border-radius: 5px; background-color: #fff; height: 35px;" appearance="none">
                                                          <input type="text" placeholder="Search Material Code/Name" aria-label="Number" matInput #materialInput
                                                                 style="border: none" class="inputdropdown1" name="matcode" [(ngModel)]="model.matcode"
                                                                 (keyup)="getmaterialDATA($event)" [matAutocomplete]="auto4" />
                                                          
                                                          <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete" (optionSelected)="selectedmastergroup()">
                                                            <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1">
                                                              {{ item.system_reference_1 }}
                                                            </mat-option>
                                                          </mat-autocomplete>
                                                          
                                                          <button mat-icon-button *ngIf="model.matcode" matSuffix (click)="clearInput($event, materialInput)">
                                                            <mat-icon>clear</mat-icon>
                                                          </button>
                                                        </mat-form-field>
                                                      </div>
                                                      


                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <label>Storage Location<span class="mandetory">*</span></label>
                                                        <mat-form-field class="example-full-width" appearance="none" class="matformfieldstyles1">
                                                            <input type="text" placeholder="Search Storage Location " aria-label="Number" matInput
                                                                style="border:none" class="inputdropdown1" name="storagelocation"
                                                                [(ngModel)]="model.storagelocation" (keyup)="getstorageDATA($event)"
                                                                [matAutocomplete]="auto5" />

                                                            <mat-autocomplete autoActiveFirstOption #auto5="matAutocomplete">

                                                                <mat-option *ngFor="let item of storageData" [value]="item.storage_location">
                                                                    {{item.storage_location}}</mat-option>
                                                            </mat-autocomplete>
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <label>Unit Of Measurement<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Unit Of Measurement" class="inputstyles" name="uom" [(ngModel)]="model.uom" readonly>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Valuation Type<span class="mandetory">*</span></label>
                                                        <select class="dropdownstyles" name="valution_type" [(ngModel)]="model.valuationType">
                                                            <option selected disabled value="undefined">Select One</option>
                                                            <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">{{valution.code}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div><br>

                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <label>Invoice Quantity<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Invoice Quantity" class="inputstyles" name="invqty" [(ngModel)]="model.invqty"
                                                            (keypress)="keyPressNumbers($event)" (keyup)="Invoicevalue($event)">
                                                    </div>

                                                    <div class="col-md-4">
                                                        <label>Received Quantity<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Received Quantity" class="inputstyles" name="recqty" [(ngModel)]="model.recqty"
                                                            (keypress)="keyPressNumbers($event)" (keyup)="Receivevalue($event)">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Shortage Quantity<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Shortage Quantity" class="inputstyles" name="shrtqty" [(ngModel)]="model.shrtqty" readonly>
                                                    </div>
                                                </div><br>
                                                <div class="row">
                                           

                                                    <div class="col-md-4">
                                                        <label>Excess Quantity<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Excess Quantity" class="inputstyles" name="excsqty" [(ngModel)]="model.excsqty" readonly>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Rejected Quantity<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Rejected Quantity" class="inputstyles" name="rejqty" [(ngModel)]="model.rejqty" (keyup)="Rejectqty()"  (keypress)="keyPressNumbers($event)">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Discount Percentage<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Discount Percentage" class="inputstyles" name="dispercentage" [(ngModel)]="model.dispercentage"
                                                            (keypress)="keyPressNumbers($event)" (keyup)="selecteddiscountprice($event)">
                                                    </div>
                                                </div><br>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <label>Discount Value<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Discount Value" class="inputstyles" name="disvalue" [(ngModel)]="model.disvalue" readonly>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <label>Unit Price<span class="mandetory">*</span></label>
        
                                                        <input type="text" placeholder="Unit Price" class="inputstyles" name="unitprice" [(ngModel)]="model.unitprice"
                                                            (keypress)="keyPressNumbers($event)" (keyup)="selectedunitprice($event)">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Basic Price<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Basic Price" class="inputstyles" name="basicprice" [(ngModel)]="model.basicprice" readonly>
                                                    </div>
                                                </div><br>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <label>Tax Description<span class="mandetory">*</span></label>
                                                        <select class="dropdownstyles" name="taxdescription" [(ngModel)]="model.taxdescription"
                                                            (change)="selectedtax()">
                                                            <option selected disabled value="undefined">Select One</option>
                                                            <option *ngFor="let item of taxData" [ngValue]="item.code">{{item.code}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Tax Percentage<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Tax Percentage" class="inputstyles" name="taxpercentage" [(ngModel)]="model.taxpercentage"
                                                            (keypress)="keyPressNumbers($event)" (keyup)="selectedtaxpercent($event)">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Tax Value<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Tax Value" class="inputstyles" name="taxvalue" [(ngModel)]="model.taxvalue" readonly>
                                                    </div>
                                                </div><br>
    
                                                <div class="col-md-12 text-center">
                                                    <button mat-raised-button class="savebutton1"  [disabled]="!model.matcode  || !model.recqty || !model.invqty || !model.uom
                                                    || !model.storagelocation || !model.valuationType">Add</button>
                                                </div>
                                            </form>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                                <mat-accordion *ngIf="editData">
                                    <mat-expansion-panel [expanded]="editpanelOpenState" (opened)="editpanelOpenState = true"
                                        (closed)="editpanelOpenState = false">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title style="font-weight:bold" >
                                                Edit Items
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <!-- <p>I'm visible because I am open</p> -->
                                        <div class="mt-3" *ngIf="editData">
                                            <form #myaddeditform="ngForm" (ngSubmit)="saveaddededitdata(myaddeditform)">
                                                <div class="row">
                                                    <div class="col-md-2">
                                                        <label>Material Code/Name<span class="mandetory">*</span></label>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <mat-form-field class="example-full-width" style="width: 100%; border: 1px solid rgba(0, 0, 0, 0.12); border-radius: 5px; background: #fff; height: 35px;" appearance="none">
                                                          <input type="text" placeholder="Search Material Code/Name" aria-label="Number" matInput #materialInput
                                                                 style="border: none" class="inputdropdown" name="matcode" [(ngModel)]="model2.matcode"
                                                                 (keyup)="getmaterialDATA1($event)" [matAutocomplete]="auto6" />
                                                      
                                                          <mat-autocomplete autoActiveFirstOption #auto6="matAutocomplete" (optionSelected)="selectedmastereditgroup()">
                                                            <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1">
                                                              {{ item.system_reference_1 }}
                                                            </mat-option>
                                                          </mat-autocomplete>
                                                      
                                                          <button mat-icon-button *ngIf="model2.matcode" matSuffix (click)="clearInput1($event, materialInput)">
                                                            <mat-icon>clear</mat-icon>
                                                          </button>
                                                        </mat-form-field>
                                                      </div>
                                                      
                                    
                                    
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <label>Storage Location<span class="mandetory">*</span></label>
                                                        <mat-form-field class="example-full-width" appearance="none" class="matformfieldstyles1">
                                                            <input type="text" placeholder="Search Storage Location " aria-label="Number" matInput
                                                                style="border:none" class="inputdropdown" name="storagelocation"
                                                                [(ngModel)]="model2.storagelocation" (keyup)="getstorageDATA1($event)"
                                                                [matAutocomplete]="auto7" />
                                    
                                                            <mat-autocomplete autoActiveFirstOption #auto7="matAutocomplete">
                                    
                                                                <mat-option *ngFor="let item of storageData" [value]="item.storage_location">
                                                                    {{item.storage_location}}</mat-option>
                                                            </mat-autocomplete>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Unit Of Measurement<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Unit Of Measurement" class="inputstyles" name="uom" [(ngModel)]="model2.uom" readonly>
                                                        <!-- <select class="dropdownstyles" name="uom" [(ngModel)]="model.uom">
                                                            <option selected disabled>Select One</option>
                                                            <option>hello</option>
                                                           
                                                        </select> -->
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Valuation Type<span class="mandetory">*</span></label>
                                                        <select class="dropdownstyles" name="valution_type" [(ngModel)]="model2.valuationType">
                                                            <option selected disabled value="undefined">Select One</option>
                                                            <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">{{valution.code}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div><br>
                                    
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <label>Invoice Quantity<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Invoice Quantity" class="inputstyles" name="invqty" [(ngModel)]="model2.invqty"
                                                            (keypress)="keyPressNumbers($event)" (keyup)="Invoiceeditvalue($event)">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Received Quantity<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Received Quantity" class="inputstyles" name="recqty" [(ngModel)]="model2.recqty"
                                                            (keypress)="keyPressNumbers($event)" (keyup)="Receivevalueedit($event)">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Shortage Quantity<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Shortage Quantity" class="inputstyles" name="shrtqty" [(ngModel)]="model2.shrtqty" readonly>
                                                    </div>
                                                </div><br>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <label>Excess Quantity<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Excess Quantity" class="inputstyles" name="excsqty" [(ngModel)]="model2.excsqty" readonly>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Rejected Quantity<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Rejected Quantity" class="inputstyles" name="rejqty" [(ngModel)]="model2.rejqty" (keyup)="Rejectqtyedit()"
                                                            (keypress)="keyPressNumbers($event)" >
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Discount Percentage<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Discount Percentage" class="inputstyles" name="dispercentage" [(ngModel)]="model2.dispercentage"
                                                            (keypress)="keyPressNumbers($event)" (keyup)="selecteddiscountpriceedit($event)">
                                                    </div>
                                                </div><br>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <label>Discount Value<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Discount Value" class="inputstyles" name="disvalue" [(ngModel)]="model2.disvalue" readonly>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Unit Price<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Unit Price" class="inputstyles" name="unitprice" [(ngModel)]="model2.unitprice"
                                                            (keypress)="keyPressNumbers($event)" (keyup)="selectedunitpriceedit($event)">
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Basic Price<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Basic Price" class="inputstyles" name="basicprice" [(ngModel)]="model2.basicprice" readonly>
                                                    </div>
                                                </div><br>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <label>Tax Description<span class="mandetory">*</span></label>
                                                        <select class="dropdownstyles" name="taxdescription" [(ngModel)]="model2.taxdescription"
                                                            (change)="selectedtaxedit()">
                                                            <option selected disabled value="undefined">Select One</option>
                                                            <option *ngFor="let item of taxData" [ngValue]="item.code">{{item.code}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Tax Percentage<span class="mandetory">*</span></label>
                                                        <input type="text" placeholder="Tax Percentage" class="inputstyles" name="taxpercentage" [(ngModel)]="model2.taxpercentage"
                                                            (keypress)="keyPressNumbers($event)" (keyup)="selectedtaxeditpercent($event)" readonly>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Tax Value<span class="mandetory">*</span></label>
                                                    
                                                        <input type="text" placeholder="Tax Value" class="inputstyles" name="taxvalue" [(ngModel)]="model2.taxvalue" readonly>
                                                    </div>
                                                </div><br>
                                               
                                                <div class="col-md-12 text-center">
                                                <button mat-raised-button class="savebutton1" [disabled]="!model2.matcode  || !model2.recqty || !model2.invqty || !model2.uom
                                                || !model2.storagelocation || !model2.valuationType">Update</button>
                                                </div>
                                            </form>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div>
                    
                        <ng-container>

                            <div class="row p-t-10">
                                <div class="col-12 col-md-12">
                                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                    <div class="table-responsive mat-table-wrapper">
                                        <div class="example-table-container materialTable">
                                            <table mat-table [dataSource]="dataSource">
                                                <ng-container matColumnDef="action">
                                                    <th mat-header-cell *matHeaderCellDef >
                                                        Action
                                                    </th>
                                                    <td mat-cell *matCellDef="let row;let i=index;">
                                                       <span class="d-flex">
                                                        <button *ngIf="!viewData" mat-icon-button
                                                        aria-label="Example icon button with a vertical three dot icon"
                                                        (click)="editdata(row,i)&&'editpanelOpenState === true'"
                                                        [disabled]="viewData===true">
                                                        <mat-icon class="editic">edit</mat-icon>
                                                    </button>
                                                    <button *ngIf="viewData" mat-icon-button>
                                                        <mat-icon matTooltip="Print" 
                                                            (click)="printbtn(i ,editmodell, row)"
                                                            style="cursor:pointer;"> local_printshop</mat-icon>
                                                    </button>
                                                    <!-- (click)="editdata(row,i,matreceipteditmodel) -->
                                                    <button *ngIf="!viewData" mat-icon-button
                                                        style="color: red;border:none"
                                                        aria-label="Example icon button with a vertical three dot icon"
                                                        (click)="deleterow(i)" [disabled]="viewData===true">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                       </span>
                                                    </td>
                                                </ng-container>


                                                <ng-container matColumnDef="sno">
                                                    <th mat-header-cell *matHeaderCellDef style="width:5%">S.NO</th>
                                                    <td mat-cell *matCellDef="let row;let i=index;">
                                                        {{i+1}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="material_code" class="text-center">
                                                    <th mat-header-cell *matHeaderCellDef style="width:5%">Material Code
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.material_code}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="material_description">
                                                    <th mat-header-cell *matHeaderCellDef style="width:5%">Material
                                                        Description
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{ row.material_description}}
                                                    </td>
                                                </ng-container>


                                                <ng-container matColumnDef="unit_of_measurement">
                                                    <th mat-header-cell *matHeaderCellDef style="width:3%">UOM
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.uom ||row.unit_of_measurment}}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="storage_location">
                                                    <th mat-header-cell *matHeaderCellDef style="width:4%">Storage
                                                        Location</th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.storagelocation || row.storage_location }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="valution_type">
                                                    <th mat-header-cell *matHeaderCellDef style="width:5%">Valuation
                                                        Type</th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.valuationType || row.valuation_type }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="invoice_quantity">
                                                    <th mat-header-cell *matHeaderCellDef style="width:4%">Invoice
                                                        Quantity
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.invqty|| row.invoice_quantity }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="received_quantity">
                                                    <th mat-header-cell *matHeaderCellDef style="width:5%">Received
                                                        Quantity</th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.recqty || row.received_quantity }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="shortage_quantity">
                                                    <th mat-header-cell *matHeaderCellDef style="width:5%"> Shortage
                                                        Quantity</th>
                                                    <td mat-cell *matCellDef="let row">
                                                        <p> {{row.shrtqty || row.shortage_quantity||0}}</p>
                                                        <!-- <p *ngIf=" row.shrtqty==0">0</p> -->

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="excess_quantity">
                                                    <th mat-header-cell *matHeaderCellDef style="width:4%"> Excess
                                                        Quantity
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        <p> {{row.excsqty || row.excess_quantity ||0}}</p>
                                                        <!-- <p *ngIf=" row.excsqty==0 ||!row.excsqty">0</p> -->

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="rejected_quantity">
                                                    <th mat-header-cell *matHeaderCellDef style="width:4%"> Rejected
                                                        Quantity
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        <p> {{row.rejqty || row.rejected_quantity ||0}}</p>
                                                        <!-- <p *ngIf=" row.excsqty==0 ||!row.excsqty">0</p> -->

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="unit_price">
                                                    <th mat-header-cell *matHeaderCellDef style="width:4%">
                                                        Unit Price
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.unitprice || row.unit_price }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="discount_percent">
                                                    <th mat-header-cell *matHeaderCellDef style="width:5%">
                                                        Discount %
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.dispercentage || row.discount_percent ||0}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="discount_value">
                                                    <th mat-header-cell *matHeaderCellDef style="width:5%">
                                                        Discount Value
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{(row.disvalue || row.discount_value||0) | number:'1.2-2' }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="basic_price">
                                                    <th mat-header-cell *matHeaderCellDef style="width:3%">
                                                        Basic Price
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{(row.basicprice || row.basic_price) | number:'1.2-2' }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="tax_description">
                                                    <th mat-header-cell *matHeaderCellDef style="width:6%">
                                                        Tax Description
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{ row.taxdescription|| row.tax_description }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="tax_percent">
                                                    <th mat-header-cell *matHeaderCellDef style="width:4%">
                                                        Tax Percentage
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{ row.taxpercentage == 0 ? 0 : row.taxpercentage || row.tax_percent}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="tax_value">
                                                    <th mat-header-cell *matHeaderCellDef style="width:4%">
                                                        Tax Value
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{(row.taxvalue == 0 ? 0 : row.taxvalue || row.tax_value) | number:'1.2-2' }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="freight_split">
                                                    <th mat-header-cell *matHeaderCellDef style="width:4%">
                                                        Freight Split
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{(((row.basicprice ||row.basic_price)/SUM)*model1.totalfreight
                                                        || row.freight_split) | number:'1.2-2' }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="other_charges_split">
                                                    <th mat-header-cell *matHeaderCellDef style="width:4%">
                                                        Other Charges Split
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{(((row.basicprice||row.basic_price)/SUM)*model1.otherchargesval
                                                        ||row.other_charges_split) | number:'1.2-2'
                                                        }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="other_tax_split">
                                                    <th mat-header-cell *matHeaderCellDef style="width:4%">
                                                        Other Tax Split
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{(((row.basicprice||row.basic_price)/SUM)*model1.othertaxvalue
                                                        || row.other_tax_split) | number:'1.2-2' }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="total_price">
                                                    <th mat-header-cell *matHeaderCellDef style="width:4%">
                                                        Total Price
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        <p class="totalPrice" *ngIf="row.taxpercentage == 0 && row.taxvalue == 0">
                                                          {{row.totalprice}}
                                                          </p>
                                                        <p class="totalPrice" *ngIf="model1.totalfreight && model1.otherchargesval&&
                            model1.othertaxvalue">
                                                            {{(+(row.basicprice||row.basic_price)+(row.taxvalue||row.tax_value)+(((row.basicprice||row.basic_price)/SUM)*model1.totalfreight
                                                            )+
                                                            (((row.basicprice||row.basic_price)/SUM)*model1.otherchargesval)+
                                                            (((row.basicprice||row.basic_price)/SUM)*model1.othertaxvalue))
                                                            |
                                                            number:'1.2-2'}}</p>
                                                        <p class="totalPrice" *ngIf="model1.totalfreight&& !model1.otherchargesval&&
                                !model1.othertaxvalue"> {{(+(row.basicprice||row.basic_price)+(row.taxvalue||row.tax_value)+(((row.basicprice||row.basic_price)/SUM)*model1.totalfreight
                                                            )) | number:'1.2-2'}}</p>
                                                        <p class="totalPrice" *ngIf="!model1.totalfreight&& model1.otherchargesval&&
                                !model1.othertaxvalue"> {{(+(row.basicprice||row.basic_price)+(row.taxvalue||row.tax_value)+(((row.basicprice||row.basic_price)/SUM)*model1.otherchargesval
                                                            )) | number:'1.2-2'}}</p>
                                                        <p class="totalPrice" *ngIf="!model1.totalfreight&& !model1.otherchargesval &&
                                model1.othertaxvalue"> {{(+(row.basicprice||row.basic_price)+(row.taxvalue||row.tax_value)+(((row.basicprice||row.basic_price)/SUM)*model1.othertaxvalue
                                                            )) | number:'1.2-2'}}</p>
                                                        <p class="totalPrice" *ngIf="model1.totalfreight&& model1.otherchargesval &&
                                !model1.othertaxvalue"> {{(+(row.basicprice||row.basic_price)+(row.taxvalue||row.tax_value)+(((row.basicprice||row.basic_price)/SUM)*model1.totalfreight
                                                            )+
                                                            (((row.basicprice||row.basic_price)/SUM)*model1.otherchargesval))
                                                            |
                                                            number:'1.2-2'}}</p>
                                                        <p class="totalPrice" *ngIf="!model1.totalfreight&& model1.otherchargesval &&
                                model1.othertaxvalue"> {{(+(row.basicprice||row.basic_price)+(row.taxvalue||row.tax_value)+(((row.basicprice||row.basic_price)/SUM)*model1.otherchargesval
                                                            )+
                                                            (((row.basicprice||row.basic_price)/SUM)*model1.othertaxvalue))
                                                            |
                                                            number:'1.2-2'}}</p>
                                                        <p class="totalPrice" *ngIf="model1.totalfreight&& !model1.otherchargesval &&
                                model1.othertaxvalue"> {{(+(row.basicprice||row.basic_price)+(row.taxvalue||row.tax_value)+(((row.basicprice||row.basic_price)/SUM)*model1.totalfreight
                                                            )+
                                                            (((row.basicprice||row.basic_price)/SUM)*model1.othertaxvalue))
                                                            |
                                                            number:'1.2-2'}}</p>
                                                        <p class="totalPrice" *ngIf="!model1.totalfreight&& !model1.otherchargesval&&
                                !model1.othertaxvalue"> {{(+(row.basicprice||row.basic_price)+(row.taxvalue||row.tax_value))
                                                            | number:'1.2-2' }}</p>
                                                    </td>

                                                </ng-container>
                                               

                                                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                                </tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                            </table>
                                        </div>
                                        <!-- <div *ngIf="taxdata==true" class="text-center">
                    <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                        Loading...
                    </h4>
                    <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                        No Records Found
                    </h4>
                </div> -->
                                        <!-- <mat-paginator [length]="totalRecords" [pageSize]="10"
                        [pageSizeOptions]="[5,10, 25, 50, 75, 100]" #masterpaginator
                        (page)="onpageevent($event)">
                    </mat-paginator> -->

                                    </div>
                                </div>
                            </div><br>

                        </ng-container>


                    </mat-card-content>
                </mat-card>

            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                List
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <ng-container>

                            <div class="row p-t-10">
                                <div class="col-md-4 mt-2 mb-3 d-flex">
                                    <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                                    <select class="dropdownstyles" name="columnname" [(ngModel)]="columnname"
                                        (change)="onChange()">
                                        <option value="" selected disabled>Select Column</option>
                                        <option value="number">DMR Number</option>
                                        <!-- <option value="date">Date</option> -->
                                        <!-- <option value="company_name">Project Name</option> -->
                                        <option value="vendor_name">Vendor Name</option>
                                        <option value="invoice_number">Invoice Number</option>
                                    </select>
                                </div>
                                <div class="col-md-3 mt-2 mb-3 ">
                                    <input type="search" class="searchfield" placeholder="Search"
                                        [(ngModel)]="searchData" (input)="search()">
                                </div>
                                <div class="col-5 col-md-5 mt-2 mb-3 text-right">
                                    <button mat-icon-button value="Refresh" matTooltip="Refresh"  (click)="getData()"
                                    class="custom-icon-button ml-3">
                                    <span class="material-icons">
                                        refresh
                                    </span>
                                </button>
                                </div>
                                <div class="col-12 col-md-12">
                                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                    <div class="table-responsive mat-table-wrapper">
                                        <div class="example-table-container">
                                            <table mat-table [dataSource]="dataSourcemain" matSort >


                                                <ng-container matColumnDef="sno">
                                                    <th mat-header-cell *matHeaderCellDef style="width:6%">S.NO</th>
                                                    <td mat-cell *matCellDef="let row;let i=index;">
                                                        {{(i+1) + (paginator?.pageIndex *
                                                        paginator?.pageSize)}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="DMRNumber">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="number" >DMR Number
                                                        
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.number}}

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="Date">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="date" >Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.date | date: 'dd/MM/yyyy'}}
                                                    </td>
                                                </ng-container>


                                                <ng-container matColumnDef="ProjectName">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="company_name" >Company Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.company_name }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="porNumber">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="number" >PO Number
                                                        
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.purchase_order_number}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="VendorName">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="vendor_name">Vendor Name</th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.vendor_name}}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="InvoiceNumber">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="invoice_number">Invoice Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.invoice_number}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="count">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="count">No.of Items
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.count}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="dmrvalue">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="value">DMR Value
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.value}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="senttoqc">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="quality_check">Sent To QC
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row?.quality_check==='true' ? 'Yes' : 'No'}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="directgrn">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="direct_grn">Direct GRN
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row?.direct_grn==='true' ? 'Yes' : 'No'}}
                                                    </td>
                                                </ng-container>
                                                <!-- <ng-container matColumnDef="ActiveStatus">
                                                <th mat-header-cell *matHeaderCellDef>Active Status
                                                </th>
                                                <td mat-cell *matCellDef="let row">
                                                    {{row.is_active}}
                                                </td>
                                            </ng-container> -->
                                                <ng-container matColumnDef="action">
                                                    <th mat-header-cell *matHeaderCellDef style="width:10%">
                                                        Action
                                                    </th>
                                                    <td mat-cell *matCellDef="let row;let i=index;" class="text-center">
                                                        <!-- <button mat-icon-button style="margin-left:-11%"
                                                            aria-label="Example icon button with a vertical three dot icon"
                                                            (click)="editsaveddata(row);tabGroup.selectedIndex = 0">
                                                            <mat-icon>edit</mat-icon>
                                                        </button> -->
                                                        <div class="mb-0">
                                                            <mat-icon matTooltip="View"
                                                                style="border:none;background: none;cursor: pointer;"
                                                                (click)="viewreason(row)">remove_red_eye</mat-icon>&nbsp;
                                                            <mat-icon matTooltip="Edit"
                                                                style="border:none;background: none;cursor: pointer;color: blue;"
                                                                (click)="editsaveddata(row,Editmodel)"
                                                                [ngClass]="row.quality_check_status === 'COMPLETED' ? 'disabled' : 'enabled'">edit</mat-icon>
                                                            <mat-icon matTooltip="Delete"
                                                                style="color: red;border:none;background: none;cursor: pointer;"
                                                                (click)="deleteItem(row,deleteItemModel)">delete</mat-icon>
                                                            <!-- </button> -->
                                                            <mat-icon matTooltip="File Upload" (click)="openfileuploadmodel(fileuploadmodel,row)"
                                                                style="cursor:pointer;font-size:24px;color: #28b351;">file_upload</mat-icon>
                                                            <!-- <i class="fa fa-paperclip" aria-hidden="true"
                                                                (click)="openfileuploadmodel(fileuploadmodel,row)"
                                                                style="cursor:pointer;font-size:16px"></i> -->
                                                        </div>
                                                    </td>
                                                </ng-container>


                                                <tr mat-header-row *matHeaderRowDef="displayedColumns1;sticky: true">
                                                </tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
                                            </table>
                                        </div>
                                        <div *ngIf="reaData==true" class="text-center">
                                            <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                                Loading...
                                            </h4>
                                            <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                                                No Records Found
                                            </h4>
                                        </div>
                                        <!-- <mat-paginator [length]="totalRecords" [pageSize]="10"
                                            [pageSizeOptions]="[5,10, 25, 50, 75, 100]" #masterpaginator
                                            (page)="onpageevent($event)">
                                        </mat-paginator> -->
                                        <div class="d-flex justify-content-end">
                                           <ng-container *ngIf="totalRecords>0">
                                            <mat-paginator [length]="totalRecords" [pageSize]="10"
                                            [pageSizeOptions]="[5,10, 25, 50, 75, 100]" #paginator
                                            (page)="onpageevent($event)">
                                        </mat-paginator>
                                        <!-- <div class="go-to-container">
                                            <div class="go-to-label">Page Nos: </div>
                                            <mat-form-field>
                                                <mat-select [(ngModel)]="goTo" (selectionChange)="goToChange()">
                                                    <mat-option *ngFor="let pageNumber of pageNumbers"
                                                        [value]="pageNumber">{{pageNumber}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div> -->
                                           </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
            QC Pending 
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <ng-container>

                            <div class="row p-t-10">
                                <div class="col-md-4 mt-2 mb-3 d-flex">
                                    <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                                    <select class="dropdownstyles" name="columnname" [(ngModel)]="columnnamepnd"
                                        (change)="onChangepnd()">
                                        <option value="" selected disabled>Select Column</option>
                                        <!-- <option value="number">QC Number</option> -->
                                        <option value="number">DMR Number</option>
                                        <option value="vendor_name">Vendor Name</option>
                                        <option value="invoice_number">Invoice Number</option>
                                        <!-- <option value="company_name">Company Name</option> -->
                                        <!-- <option value="purchase_order_number">PO Number</option> -->
                                    </select>
                                </div>
                                <div class="col-md-3 mt-2 mb-3 ">
                                    <input type="search" class="searchfield" placeholder="Search"
                                        [(ngModel)]="searchDatapnd" (input)="searchpnd()">
                                </div>
                                <div class="col-5 col-md-5 mt-2 mb-3 text-right">
                                    <button mat-icon-button value="Refresh" matTooltip="Refresh"  (click)="getdatapnd()"
                                    class="custom-icon-button ml-3">
                                    <span class="material-icons">
                                        refresh
                                    </span>
                                </button>
                                </div>
                                <div class="col-12 col-md-12">
                                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                    <div class="table-responsive mat-table-wrapper">
                                        <div class="example-table-container">
                                            <table mat-table [dataSource]="dataSourcemainpnd" matSort>


                                                <ng-container matColumnDef="sno">
                                                    <th mat-header-cell *matHeaderCellDef style="width:6%">S.NO</th>
                                                    <td mat-cell *matCellDef="let row;let i=index;">
                                                        {{(i+1) + (masterpaginator?.pageIndex *
                                                        masterpaginator?.pageSize)}}

                                                    </td>
                                                </ng-container>
                                    
                                                <ng-container matColumnDef="qc_date">
                                                    <th mat-header-cell *matHeaderCellDef style="width:8%" mat-sort-header="qc_sent_date">Sent From Stores
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.qc_sent_date | date: 'dd/MM/yyyy hh:mm a'}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="DMRNumber">
                                                    <th mat-header-cell *matHeaderCellDef style="width:10%" mat-sort-header="dmr_number">DMR Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.dmr_number}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="dmr_date">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="dmr_date">DMR Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.dmr_date| date: 'dd/MM/yyyy'}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="company_name">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="company_name">Company Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.company_name}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="VendorName">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="vendor_name">Vendor Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.vendor_name }}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="InvoiceNumber">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header="invoice_number">Invoice Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.invoice_number}}
                                                    </td>
                                                </ng-container>
                                             
                                                <ng-container matColumnDef="po_number">
                                                    <th mat-header-cell *matHeaderCellDef style="width:8%" mat-sort-header="purchase_order_number">PO Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.purchase_order_number}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="count">
                                                    <th mat-header-cell *matHeaderCellDef style="width:8%" mat-sort-header="count">Count
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.count}}

                                                    </td>
                                                </ng-container>
                                                <!-- <ng-container matColumnDef="date">
                                                    <th mat-header-cell *matHeaderCellDef style="width:8%">Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.created_date | date: 'dd/MM/yyyy '}}

                                                    </td>
                                                </ng-container> -->
                                                <!-- <ng-container matColumnDef="action">
                                                    <th mat-header-cell *matHeaderCellDef style="width:13%"
                                                        class="text-center">
                                                        Action
                                                    </th>
                                                    <td mat-cell *matCellDef="let row" class="text-center">
                                                        <mat-icon matTooltip="Proceed " style="border:none;background: none;cursor: pointer;"
                                                            (click)="viewdata(row)">how_to_reg</mat-icon>
                                                            <mat-icon class="ml-2" matTooltip="View Documents" style="border:none;background: none;cursor: pointer;"
                                                            (click)="viewAttachment1(viewFilemodel,row)">remove_red_eye</mat-icon>
                                                       
                                                    </td>
                                                </ng-container> -->


                                                <tr mat-header-row *matHeaderRowDef="displayedColumns1pnd;sticky: true">
                                                </tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns1pnd"></tr>
                                            </table>
                                        </div>
                                        <div *ngIf="reaDatapnd==true" class="text-center">
                                            <h4 class="text-secondary m-3" *ngIf="loadingRecordspnd">
                                                Loading...
                                            </h4>
                                            <h4 class="text-secondary m-3" *ngIf="!loadingRecordspnd">
                                                No Records Found
                                            </h4>
                                        </div>
                                       <ng-container *ngIf="totalRecordspnd>0">
                                        <mat-paginator [length]="totalRecordspnd" [pageSize]="10"
                                        [pageSizeOptions]="[5,10, 25, 50, 75, 100]" #masterpaginator
                                        (page)="onpageeventpnd($event)">
                                    </mat-paginator>
                                       </ng-container>

                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
            GRN Pending
            </ng-template>
            <ng-template matTabContent>
                <mat-card>
                    <mat-card-content>
                        <ng-container>

                            <div class="row p-t-10">
                                <div class="col-md-4 mt-2 mb-3 d-flex">
                                    <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
                                    <select class="dropdownstyles" name="columnname" [(ngModel)]="columnnamegrnpnd"
                                        (change)="onChangegrnpnd()">
                                        <option value="" selected disabled>Select Column</option>
                                        <option value="number">DMR Number</option>
                                        <option value="vendor_name">Vendor Name</option>
                                        <!-- <option value="invoice_number">Invoice Number</option> -->
                                        <option value="company_name">Company Name</option>
                                    </select>
                                </div>
                                <div class="col-md-3 mt-2 mb-3 ">
                                    <input type="search" class="searchfield" placeholder="Search"
                                        [(ngModel)]="searchDatagrnpnd" (input)="searchgrnpnd()">
                                </div>
                                <div class="col-5 col-md-5 mt-2 mb-3 text-right">
                                    <button mat-icon-button value="Refresh" matTooltip="Refresh"  (click)="getgoodslistpnd()"
                                    class="custom-icon-button ml-3">
                                    <span class="material-icons">
                                        refresh
                                    </span>
                                </button>
                                </div>
                                <div class="col-12 col-md-12">
                                    <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                                    <div class="table-responsive mat-table-wrapper">
                                        <div class="example-table-container">
                                            <table mat-table [dataSource]="dataSourcemaingrnpnd" style="border:none;" matSort>


                                                <ng-container matColumnDef="sno">
                                                    <th mat-header-cell *matHeaderCellDef
                                                        style="width:6%;font-weight: 600;">S.NO</th>
                                                    <td mat-cell *matCellDef="let row;let i=index;" class="text-center">
                                                        {{(i+1) + (masterpaginator?.pageIndex *
                                                        masterpaginator?.pageSize)}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="qc_number">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="qc_number">QC
                                                        Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.qc_number}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="qc_date">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="qc_date">Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.qc_date | date: 'dd/MM/yyyy '}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="dmr_number">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="dmr_number">DMR
                                                        Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.dmr_number }}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="dmr_date">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="dmr_date">DMR Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.dmr_date | date: 'dd/MM/yyyy '}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="qc_sent_date">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="qc_sent_date">Sent From Stores
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.qc_sent_date | date: 'dd/MM/yyyy hh:mm a'}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="qc_release_date">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="qc_release_date">QC Release Date
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.qc_release_date | date: 'dd/MM/yyyy hh:mm a'}}

                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="purchase_order_number">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="purchase_order_number">PO
                                                        Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.purchase_order_number }}
                                                    </td>
                                                </ng-container>
                                               
                                                <ng-container matColumnDef="company_name">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="company_name">
                                                        Company Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.company_name}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="vendor_name">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="vendor_name">
                                                        Vendor Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.vendor_name}}
                                                    </td>
                                                </ng-container>
                                               
                                                <ng-container matColumnDef="count">
                                                    <th mat-header-cell *matHeaderCellDef style="font-weight: 600" mat-sort-header="count">Count
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.count}}
                                                    </td>
                                                </ng-container>
                                                <tr mat-header-row *matHeaderRowDef="displayedColumnsgrnpnd;sticky: true">
                                                </tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumnsgrnpnd"></tr>
                                            </table>
                                        </div>
                                        <div *ngIf="reaDatagrnpnd==true" class="text-center">
                                            <h4 class="text-secondary m-3" *ngIf="loadingRecordsgrnpnd">
                                                Loading...
                                            </h4>
                                            <h4 class="text-secondary m-3" *ngIf="!loadingRecordsgrnpnd">
                                                No Records Found
                                            </h4>
                                        </div>
                                        <ng-container *ngIf="totalRecordsgrnpnd>0">
                                            <mat-paginator [length]="totalRecordsgrnpnd" [pageSize]="10"
                                            [pageSizeOptions]="[5,10, 25, 50, 75, 100]" #masterpaginator
                                            (page)="onpageeventgrnpnd($event)">
                                        </mat-paginator>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Activity Log
            </ng-template>
            <ng-template matTabContent>
                <app-activitylog [activitylogfor]="'DailyMaterialReceipt'"></app-activitylog>
                 </ng-template>
        </mat-tab>
        
    </mat-tab-group>
</div>



<ng-template #matreceiptmodel>
    <div mat-dialog-title class="dialog-title">
        <h2>Add Items</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            style="border:none;background:none">
            <mat-icon class="icon1">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content style="overflow: initial;">
        <form #myaddform="ngForm" (ngSubmit)="saveaddeddata(myaddform)">
            <div class="row mb-2">
                <div class="col-md-2">
                    <label>Material Code/Name<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-10">
                    <!-- <select class="dropdownstyles" name="matcode" [(ngModel)]="model.matcode"  (change)="selectedmastergroup()">
                    <option selected disabled>Select One</option>
                    <option *ngFor="let item of masterData" [ngValue]="item.system_reference_1">{{item.system_reference_1}}</option>
                </select> -->
                    <!-- <mat-form-field class="example-full-width" appearance="none" class="matformfieldstyles">
                        <input type="text" placeholder="Search Material Code/Name " aria-label="Number" matInput
                            style="border:none" class="inputdropdown" name="matcode" [(ngModel)]="model.matcode"
                            (keyup)="getmaterialDATA($event)" [matAutocomplete]="auto4" />

                        <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete"
                            (optionSelected)="selectedmastergroup()">

                            <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1" >
                                {{item.system_reference_1}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field> -->
                    <mat-form-field class="example-full-width" style=" width:100%;
                    border: 1px solid rgba(0, 0, 0, 0.12);
                    border-radius: 5px;
                    height: 35px;" appearance="none">
                        <input type="text" placeholder="Search Material Code/Name " aria-label="Number" matInput
                            style="border:none" class="inputdropdown" name="matcode" [(ngModel)]="model.matcode"
                            (keyup)="getmaterialDATA($event)" [matAutocomplete]="auto4" />

                        <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete"
                            (optionSelected)="selectedmastergroup()">

                            <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1">
                                {{item.system_reference_1}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

            </div>
            <div class="row">
                <div class="col-md-2">
                    <label>Storage Location<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <!-- <select class="dropdownstyles" name="storagelocation" [(ngModel)]="model.storagelocation">
                    <option selected disabled>Select One</option>
                   
                    <option *ngFor="let item of storageData" [ngValue]="item.storage_location">{{item.storage_location}}</option>
                </select> -->
                    <mat-form-field class="example-full-width" appearance="none" class="matformfieldstyles">
                        <input type="text" placeholder="Search Storage Location " aria-label="Number" matInput
                            style="border:none" class="inputdropdown" name="storagelocation"
                            [(ngModel)]="model.storagelocation" (keyup)="getstorageDATA($event)"
                            [matAutocomplete]="auto5" />

                        <mat-autocomplete autoActiveFirstOption #auto5="matAutocomplete">

                            <mat-option *ngFor="let item of storageData" [value]="item.storage_location">
                                {{item.storage_location}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="col-md-2">
                    <label>Unit Of Measurement<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="uom" [(ngModel)]="model.uom" readonly>
                    <!-- <select class="dropdownstyles" name="uom" [(ngModel)]="model.uom">
                        <option selected disabled>Select One</option>
                        <option>hello</option>
                       
                    </select> -->
                </div>

            </div><br>

            <div class="row">
                <div class="col-md-2">
                    <label>Invoice Quantity<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="invqty" [(ngModel)]="model.invqty"
                        (keypress)="keyPressNumbers($event)" (keyup)="Invoicevalue($event)">
                </div>
                <div class="col-md-2">
                    <label>Received Quantity<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="recqty" [(ngModel)]="model.recqty"
                        (keypress)="keyPressNumbers($event)" (keyup)="Receivevalue($event)">
                </div>

            </div><br>
            <div class="row">
                <div class="col-md-2">
                    <label>Shortage Quantity<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="shrtqty" [(ngModel)]="model.shrtqty" readonly>
                </div>
                <div class="col-md-2">
                    <label>Excess Quantity<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="excsqty" [(ngModel)]="model.excsqty" readonly>
                </div>

            </div><br>
            <div class="row">
                <div class="col-md-2">
                    <label>Discount Percentage<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="dispercentage" [(ngModel)]="model.dispercentage"
                        (keypress)="keyPressNumbers($event)" (keyup)="selecteddiscountprice($event)">
                </div>
                <div class="col-md-2">
                    <label>Discount Value<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="disvalue" [(ngModel)]="model.disvalue" readonly>
                </div>
            </div><br>
            <div class="row">
                <div class="col-md-2">
                    <label>Unit Price<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="unitprice" [(ngModel)]="model.unitprice"
                        (keypress)="keyPressNumbers($event)" (keyup)="selectedunitprice($event)">
                </div>
                <div class="col-md-2">
                    <label>Basic Price<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="basicprice" [(ngModel)]="model.basicprice" readonly>
                </div>

            </div><br>
            <div class="row">
                <div class="col-md-2">
                    <label>Tax Description<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <select class="dropdownstyles" name="taxdescription" [(ngModel)]="model.taxdescription"
                        (change)="selectedtax()">
                        <option selected disabled>Select One</option>
                        <option *ngFor="let item of taxData" [ngValue]="item.code">{{item.code}}</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <label>Tax Percentage<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="taxpercentage" [(ngModel)]="model.taxpercentage"
                        (keypress)="keyPressNumbers($event)" (keyup)="selectedtaxpercent($event)">
                </div>

            </div><br>
            <div class="row">
                <div class="col-md-2">
                    <label>Tax Value<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="taxvalue" [(ngModel)]="model.taxvalue" readonly>
                </div>
                <div class="col-md-2">
                    <label>Valuation Type<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <select class="dropdownstyles" name="valution_type" [(ngModel)]="model.valuationType">
                        <option selected disabled>Select One</option>
                        <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">{{valution.code}}
                        </option>
                    </select>
                </div>
            </div><br>
            <!-- <div class="row">
                <div class="col-md-2">
                    <label>Freight Split</label>
                </div>
                <div class="col-md-4">
                    <input type="text"  class="inputstyles" name="freightsplit" [(ngModel)]="model.freightsplit">
                </div>
                <div class="col-md-2">
                    <label>Other Charges Split</label>
                </div>
                <div class="col-md-4">
                    <input type="text"  class="inputstyles" name="otherchargessplit" [(ngModel)]="model.otherchargessplit">
                </div>
            </div><br> -->
            <!-- <div class="row">
                <div class="col-md-2">
                    <label>Other Tax Split</label>
                </div>
                <div class="col-md-4">
                    <input type="text"  class="inputstyles" name="othertaxsplit" [(ngModel)]="model.othertaxsplit">
                </div>
                <div class="col-md-2">
                    <label>Total Price</label>
                </div>
                <div class="col-md-4">
                    <input type="text"   class="inputstyles" name="totalprice" [(ngModel)]="model.totalprice"  >
                </div>
            </div><br> -->
            <button mat-raised-button class="savebutton" style="width:6%;font-weight: 500;margin-left: 43%;
            margin-top: 3%;" [disabled]="!model.matcode  || !model.recqty || !model.invqty || !model.uom
            || !model.storagelocation || !model.valuationType">Add</button>
        </form>
    </mat-dialog-content>
</ng-template>
<ng-template #matreceipteditmodel>
    <div mat-dialog-title class="dialog-title">
        <h2>Edit Items</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            style="border:none;background:none">
            <mat-icon class="icon1">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content style="overflow: initial;">
        <form #myaddeditform="ngForm" (ngSubmit)="saveaddededitdata(myaddeditform)">
            <div class="row">
                <div class="col-md-2">
                    <label>Material Code/Name<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-10">
                    <!-- <select class="dropdownstyles" name="matcode" [(ngModel)]="model2.matcode"  (change)="selectedmastereditgroup()">
                    <option selected disabled>Select One</option>
                    <option *ngFor="let item of masterData" [ngValue]="item.system_reference_1">{{item.system_reference_1}}</option>
                </select> -->
                    <mat-form-field class="example-full-width" style=" width:100%;
                border: 1px solid rgba(0, 0, 0, 0.12);
                border-radius: 5px;
                height: 35px;" appearance="none">
                        <input type="text" placeholder="Search Material Code/Name " aria-label="Number" matInput
                            style="border:none" class="inputdropdown" name="matcode" [(ngModel)]="model2.matcode"
                            (keyup)="getmaterialDATA1($event)" [matAutocomplete]="auto6" />

                        <mat-autocomplete autoActiveFirstOption #auto6="matAutocomplete"
                            (optionSelected)="selectedmastereditgroup()">

                            <mat-option *ngFor="let item of masterData" [value]="item.system_reference_1">
                                {{item.system_reference_1}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>


            </div>
            <div class="row">
                <div class="col-md-2">
                    <label>Storage Location<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <!-- <select class="dropdownstyles" name="storagelocation" [(ngModel)]="model2.storagelocation">
                    <option selected disabled>Select One</option>
                   
                    <option *ngFor="let item of storageData" [ngValue]="item.storage_location">{{item.storage_location}}</option>
                </select> -->
                    <mat-form-field class="example-full-width" appearance="none" class="matformfieldstyles">
                        <input type="text" placeholder="Search Storage Location " aria-label="Number" matInput
                            style="border:none" class="inputdropdown" name="storagelocation"
                            [(ngModel)]="model2.storagelocation" (keyup)="getstorageDATA1($event)"
                            [matAutocomplete]="auto7" />

                        <mat-autocomplete autoActiveFirstOption #auto7="matAutocomplete">

                            <mat-option *ngFor="let item of storageData" [value]="item.storage_location">
                                {{item.storage_location}}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <label>Unit Of Measurement<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="uom" [(ngModel)]="model2.uom" readonly>
                    <!-- <select class="dropdownstyles" name="uom" [(ngModel)]="model.uom">
                        <option selected disabled>Select One</option>
                        <option>hello</option>
                       
                    </select> -->
                </div>

            </div><br>

            <div class="row">
                <div class="col-md-2">
                    <label>Invoice Quantity<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="invqty" [(ngModel)]="model2.invqty"
                        (keypress)="keyPressNumbers($event)" (keyup)="Invoiceeditvalue($event)">
                </div>
                <div class="col-md-2">
                    <label>Received Quantity<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="recqty" [(ngModel)]="model2.recqty"
                        (keypress)="keyPressNumbers($event)" (keyup)="Receivevalueedit($event)">
                </div>

            </div><br>
            <div class="row">
                <div class="col-md-2">
                    <label>Shortage Quantity<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="shrtqty" [(ngModel)]="model2.shrtqty" readonly>
                </div>
                <div class="col-md-2">
                    <label>Excess Quantity<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="excsqty" [(ngModel)]="model2.excsqty" readonly>
                </div>

            </div><br>
            <div class="row">

                <div class="col-md-2">
                    <label>Discount Percentage<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="dispercentage" [(ngModel)]="model2.dispercentage"
                        (keypress)="keyPressNumbers($event)" (keyup)="selecteddiscountpriceedit($event)">
                </div>
                <div class="col-md-2">
                    <label>Discount Value<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="disvalue" [(ngModel)]="model2.disvalue" readonly>
                </div>
            </div><br>
            <div class="row">
                <div class="col-md-2">
                    <label>Unit Price<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="unitprice" [(ngModel)]="model2.unitprice"
                        (keypress)="keyPressNumbers($event)" (keyup)="selectedunitpriceedit($event)">
                </div>
                <div class="col-md-2">
                    <label>Basic Price<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="basicprice" [(ngModel)]="model2.basicprice" readonly>
                </div>

            </div><br>
            <div class="row">
                <div class="col-md-2">
                    <label>Tax Description<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <select class="dropdownstyles" name="taxdescription" [(ngModel)]="model2.taxdescription"
                        (change)="selectedtaxedit()">
                        <option selected disabled>Select One</option>
                        <option *ngFor="let item of taxData" [ngValue]="item.code">{{item.code}}</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <label>Tax Percentage<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="taxpercentage" [(ngModel)]="model2.taxpercentage"
                        (keypress)="keyPressNumbers($event)" (keyup)="selectedtaxeditpercent($event)" readonly>
                </div>

            </div><br>
            <div class="row">
                <div class="col-md-2">
                    <label>Tax Value<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <input type="text" class="inputstyles" name="taxvalue" [(ngModel)]="model2.taxvalue" readonly>
                </div>
                <div class="col-md-2">
                    <label>Valuation Type<span class="mandetory">*</span></label>
                </div>
                <div class="col-md-4">
                    <select class="dropdownstyles" name="valution_type" [(ngModel)]="model2.valuationType">
                        <option selected disabled>Select One</option>
                        <option *ngFor="let valution of valutiondata" [ngValue]="valution.code">{{valution.code}}
                        </option>
                    </select>
                </div>
            </div><br>
            <!-- <div class="row">
                <div class="col-md-2">
                    <label>Freight Split</label>
                </div>
                <div class="col-md-4">
                    <input type="text"  class="inputstyles" name="freightsplit" [(ngModel)]="model.freightsplit">
                </div>
                <div class="col-md-2">
                    <label>Other Charges Split</label>
                </div>
                <div class="col-md-4">
                    <input type="text"  class="inputstyles" name="otherchargessplit" [(ngModel)]="model.otherchargessplit">
                </div>
            </div><br> -->
            <!-- <div class="row">
                <div class="col-md-2">
                    <label>Other Tax Split</label>
                </div>
                <div class="col-md-4">
                    <input type="text"  class="inputstyles" name="othertaxsplit" [(ngModel)]="model.othertaxsplit">
                </div>
                <div class="col-md-2">
                    <label>Total Price</label>
                </div>
                <div class="col-md-4">
                    <input type="text"   class="inputstyles" name="totalprice" [(ngModel)]="model.totalprice"  >
                </div>
            </div><br> -->
            <button mat-raised-button class="savebutton" style="width:8%;font-weight: 500;margin-left: 43%;
            margin-top: 3%;">Update</button>
        </form>
    </mat-dialog-content>
</ng-template>

<ng-template #deleteItemModel>
    <div mat-dialog-title>
    <h1 >Delete Activity</h1>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            style="border:none;background:none;margin-top: -13% !important;">
            <mat-icon class="icon1">close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <p>Are you sure, you want to delete record?</p>
        <div class="row">
            <div class="col-md-2">
                <label style="padding-top:5px;">Reason</label>
            </div>
            <div class="col-md-4">
                <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                    [ngModel]="deletemodel.reason" (ngModelChange)="deletemodel.reason=$event.trim()" 
                    (input)="deletemodel.reason=$event.target.value.trim()" style="width: 181%;">
            </div>
        </div>

    </div>
    <div mat-dialog-actions class="justify-content-center">
        <!-- <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">Close</button>&nbsp; -->
        <button mat-button cdkFocusInitial (click)="deleteFile()" class="matButton"
            [disabled]="!deletemodel.reason">Delete</button>
    </div>
</ng-template>
<ng-template #copydmrmodel>
    <div mat-dialog-title class="dialog-title" >
        <h2>Copy DMR</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            style="border:none;background:none;margin-top: -9% !important;">
            <mat-icon class="icon1">close</mat-icon>
        </button>
    </div>
    <form #mydmrform="ngForm" (ngSubmit)="submitdmrno(mydmrform)">
        <div class="row">
            <div class="col-md-10 mt-2">
                <!-- <label>Search DMR Number<span class="mandetory">*</span></label> -->
                <!-- <input type="text" style="width:165%" class="inputstyles" name="copieddmr"
            [(ngModel)]="copieddmr"> -->
                <mat-form-field class="example-full-width" appearance="outline" style="width:100%;height: 35px;">
                    <input type="text" placeholder="Search DMR Number" aria-label="Number" matInput (input)="trimInput()"
                        style="border:none;margin-bottom: 1%;" class="inputdropdown" name="copieddmr"
                        [(ngModel)]="copieddmr" (keyup)="getdmrDATA($event)" [matAutocomplete]="auto4" />

                    <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete">

                        <mat-option *ngFor="let item of DMRDATA" [value]="item.number">
                            {{item.number}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col-md-2 mt-2 pt-1">
                <button mat-raised-button class="savebutton submitBtn" style="font-weight: 500;" [disabled]="!copieddmr">Submit</button>
            </div>
        </div>
    </form>
</ng-template>
<ng-template #pomodel>
    <div mat-dialog-title class="dialog-title">
        <h2>Select Purchase Order</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            style="border:none;background:none;margin-top: -9% !important;">
            <mat-icon class="icon1">close</mat-icon>
        </button>
    </div>
    <form #mypoform="ngForm" (ngSubmit)="submitpono(mypoform)">
        <div class="row">
            <div class="col-md-10 mt-2">
                <!-- <label>Select PO Number<span class="mandetory">*</span></label> -->
            
                <!-- <input type="text" style="width:165%" class="inputstyles" name="copieddmr"
            [(ngModel)]="copieddmr"> -->
                <mat-form-field class="example-full-width" appearance="outline" style="font-size:12px;width:100%;height: 35px;">
                    <input type="text" placeholder="Search PO Number " aria-label="Number" matInput (input)="trimInput()"
                        style="border:none;margin-bottom: 1%;" class="inputdropdown" name="ponumber"
                        [(ngModel)]="ponumber" (keyup)="getpurchaseorderDATA($event)" [matAutocomplete]="auto4" />

                    <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete">

                        <mat-option *ngFor="let item of PODATA" [value]="item.number">
                            {{item.number}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <!-- <select class="dropdownstyles" name="ponumber" [(ngModel)]="ponumber" >
                            <option selected disabled>Select One</option>
                            <option *ngFor="let item of PODATA" [ngValue]="item.number">{{item.number}}
                            </option>
                        </select> -->

            </div>
       <div class="col-md-2 mt-2 pt-1">
       <button mat-raised-button class="savebutton submitBtn" style="font-weight: 500;" [disabled]="!ponumber">Submit</button>
       </div>
        </div>

    </form>
</ng-template>
<ng-template #fileuploadmodel>
    <div mat-dialog-title class="dialog-title">
        <h2>Daily Material Receipt: {{dmrnumber}}</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            style="border:none;background:none;margin-top: -9%;">
            <mat-icon class="icon1">close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content class="row">
        <div *ngIf="filedata">
            <!-- <span *ngFor="let item of filedata"> -->
            <!-- <span *ngFor="let item of filedata"> <a href="{{item.file_path}}">{{item.file_path}}</a></span> -->
            <!-- <h4>View File:&nbsp;&nbsp; <i class="fas fa-eye text-cyan m-r-20 " 
            style="cursor: pointer;" (click)="viewDoc(item)"></i></h4> -->
            <table class="text-center">
                <thead style="background: #e9ecef;">
                    <!-- <tr> -->
                    <th>Created By</th>
                    <th>Created Date</th>
                    <th>File Name</th>
                    <th>Action</th>
                    <!-- </tr> -->
                </thead>
                <tbody>
                    <tr *ngFor="let item of filedata">
                        <td>{{item.created_by}}</td>
                        <td>{{item.created_date}}</td>
                        <td class="tablebody">{{item.file_name}} &nbsp;&nbsp;&nbsp;<i class="fas fa-eye text-cyan m-r-20 "
                                style="cursor: pointer;" title="View File" (click)="viewDoc(item)"></i></td>
                        <td>
                            <button mat-icon-button style="color: red;border:none;cursor: pointer;"
                                aria-label="Example icon button with a vertical three dot icon" matTooltip="Delete"
                                (click)="deleterowfile(deletefileModel,item)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center" *ngIf="filedata.length===0">
                No Data Found
            </div>
            <!-- <a style="cursor: pointer;" (click)="viewDoc(item)">{{item.file_name}}</a> -->
            <!-- </span> -->
        </div>
    </div><br>
    <button mat-raised-button type="button" (click)="autoFileUpload1.click()">Attach File&nbsp; <mat-icon class="file2">file_upload</mat-icon></button>
    <div class="file">
        <input hidden class="form-control-file" name="file" type="file" (change)="uploadWbsFile($event);showContent(i);"
            #autoFileUpload1 multiple accept=".doc, .pdf, .jpeg, .jpg, .png, .xlsx"/>
        <!-- <ng-container *ngIf="fileUploadUrls.length>0">
            <span>Selected: {{fileUploadUrls.length}} files</span>
        </ng-container> -->
    </div>
</ng-template>
<ng-template #deletefileModel>
    <h1 mat-dialog-title>Delete Activity</h1>
    <div mat-dialog-content>Are you sure, you want to delete file?</div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">Close</button>&nbsp;
        <button mat-button cdkFocusInitial (click)="deleteexistingfile()" class="matButton">Delete</button>
    </div>
</ng-template>
<ng-template #Editmodel>
    <!-- <h1 mat-dialog-title></h1> -->
    <div mat-dialog-title>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
        style="border:none;background:none;margin-top: -3% !important;">
        <mat-icon class="icon1">close</mat-icon>
    </button>
</div>
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-2">
                <label style="padding-top:5px;">Reason</label>
            </div>
            <div class="col-md-4">
                <input type="text" placeholder="Please Enter Reason" class="inputstyles" name="reason"
                    [ngModel]="editModel.reason" (ngModelChange)="editModel.reason=$event.trim()" 
                    (input)="editModel.reason=$event.target.value.trim()" style="width: 181%;">
            </div>
        </div>
    </div><br>
    <div mat-dialog-actions class="justify-content-center">
        <!-- <button mat-button mat-dialog-close class="matButton" style="background-color:rgb(239,164,164)">Close</button>&nbsp; -->
        <button mat-button cdkFocusInitial (click)="saveeditreason()" class="matButton submitBtn"
            [disabled]="!editModel.reason">Submit</button>
    </div>
</ng-template>


<ng-template #rgpmodel>
    <div mat-dialog-title class="dialog-title">
        <h2>Select RGP</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            style="border:none;background:none;margin-top: -10% !important;">
            <mat-icon class="icon1">close</mat-icon>
        </button>
    </div>
    <form #mypoform="ngForm" (ngSubmit)="submitrgpno(myrgpform)">
        <div class="row">
            <div class="col-md-10 mt-2">
                <!-- <label>Select RGP Number<span class="mandetory">*</span></label> -->
                <mat-form-field class="example-full-width" appearance="outline" style="font-size:12px;width:100%;height: 35px;">
                    <input type="text" placeholder="Search RGP Number " aria-label="Number" matInput (input)="trimInput()"
                        style="border:none;margin-bottom: 1%;" class="inputdropdown" name="rgpnumber"
                        [(ngModel)]="rgpnumber" (keyup)="getrgpdataData($event)" [matAutocomplete]="auto4" />

                    <mat-autocomplete autoActiveFirstOption #auto4="matAutocomplete">

                        <mat-option *ngFor="let item of RGPDATA" [value]="item.number">
                            {{item.number}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="col-md-2 mt-2 pt-1">
        <button mat-raised-button type="submit" class="savebutton submitBtn" style="font-weight: 500;
            margin-top: 3%;" [disabled]="!rgpnumber">Submit</button>
            </div>
        </div>

    </form>
</ng-template>

 
<ng-template #viewFilemodel>
    <div mat-dialog-title class="dialog-title">
        <h2>Purchase Order Number: {{selectedPO}}</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon" (click)="closemodel()"
            style="border:none;background:none;margin-top: -9% !important;">
            <mat-icon class="icon1">close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content class="row">
        <div *ngIf="filedataPO">
            <table>
                <thead style="background: #e9ecef;">
                    <th>Created By</th>
                    <th>Created Date</th>
                    <th>File Name</th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of filedataPO">
                        <td>{{item.created_by}}</td>
                        <td>{{item.created_date}}</td>
                        <td>{{item.file_name}} &nbsp;&nbsp;&nbsp;<i class="fas fa-eye text-cyan m-r-20 "
                                style="cursor: pointer;" title="View File" (click)="viewDocPO(item)"></i></td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center" *ngIf="filedataPO.length===0">
                No Data Found
            </div>
        </div>
    </div><br>
</ng-template>
<ng-template #editmodell>
    <div class="text-right" mat-dialog-title>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close style="margin-top: -3 !important;" class="close1" (click)="closemodel()">
            <mat-icon class="closeic">close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content class="matdiolog">
        <div class="row justify-content-center">
            <div class="col-6">
                <label>Print Type</label>
                <select class="dropdownstyles" [(ngModel)]="selectedPrintOptions" (change)="onPrintOptionChange()">
                    <option [value]="undefined" selected disabled>Select One</option>
                    <option value="a4">A4 Size</option>
                    <option value="sticker">Sticker Size</option>
                </select>
            </div>

            <div class="col-6 justify-content-end" *ngIf="selectedPrintOptions !== 'sticker'">
                <label>Print Quantity</label>
                <input class="dropdownstyles" type="number" [(ngModel)]="selectedPrintOption"
                    (input)="onPrintOptionChange()" placeholder="Enter the Quantity" />
            </div>
        </div>
        <div mat-dialog-actions class="printdata justify-content-center mt-2">
            <div class="print">
                <button [print]="['receipt', config]" class="btn btn-info">
                    Print
                </button>
            </div>
        </div>
    </div>
</ng-template>

<div *ngIf="selectedPrintOptions === 'sticker'">
    <div id="receipt">
        <img class="qrsticker" [src]="resImg">
    </div>
</div>

<div *ngIf="selectedPrintOptions === 'a4'">
    <div class="row" id="receipt" *ngIf="selectedPrintOption > 0">
        <div class="col-6 imgsizee " *ngFor="let row of getRepeatedItems()">
            <img class="a4rotate" [src]="resImg">
        </div>
    </div>
</div>
<ng-template #newFileUploadModal>
    <div mat-dialog-title class="dialog-title">
        <h2 class="file1">File Upload</h2>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon"
                    (click)="closemodel()" >
                    <mat-icon class="icon1">close</mat-icon>
                </button>
    </div>
    <div  *ngIf="!editeddata">
    <div mat-dialog-content class="row">
        <div *ngIf="selectedfiles.length > 0">
            <table>
                <thead class="table2">
                    <th>S.NO</th>
                    <th>File Name</th>
                    <th>Action</th>
                </thead>
                <tbody class="text-center">
                    <tr *ngFor="let item of selectedfiles;let i=index;">
                        <td>{{i+1}}</td>
                        <td class="tablebody1">{{item.name}}</td>
                        <td>
                            <button mat-icon-button class="delete1"
                            (click)="deleteFileItem(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div><br>
    <button type="button" mat-raised-button class="attachfile ml-1" color="secondary" (click)="autoFileUpload2.click()" >
        Attach File&nbsp; <mat-icon class="file2">file_upload</mat-icon></button>
    <div class="file">
        <input hidden class="form-control-file" name="file" type="file" multiple (change)="uploaddmrfiles($event)"
            #autoFileUpload2>
    </div>
  </div>
  
  <div  *ngIf="editeddata">
    <div mat-dialog-content class="row">
      <div *ngIf="filedata">
        <table class="text-center">
          <thead style="background: #e9ecef;">
            <!-- <tr> -->
            <th>Created By</th>
            <th>Created Date</th>
            <th>File Name</th>
            <th>Action</th>
            <!-- </tr> -->
          </thead>
          <tbody>
            <tr *ngFor="let item of filedata">
              <td>{{item.created_by}}</td>
              <td>{{item.created_date}}</td>
              <td class="tablebody">{{item.file_name}} &nbsp;&nbsp;&nbsp;<i class="fas fa-eye text-cyan m-r-20 " style="cursor: pointer;"
                  title="View File" (click)="viewDoc(item)"></i></td>
              <td>
                <button mat-icon-button style="color: red;border:none;cursor: pointer;"
                  aria-label="Example icon button with a vertical three dot icon" matTooltip="Delete"
                  (click)="deleterowfile(deletefileModel,item)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-center" *ngIf="filedata.length===0">
          No Data Found
      </div>
      </div>
    </div><br>
    <button mat-raised-button type="button" (click)="autoFileUpload1.click()">Attach File</button>
    <div class="file">
      <input hidden class="form-control-file" name="file" type="file" multiple
        (change)="uploadWbsFile($event);showContent(i);" #autoFileUpload1 />
      <!-- <ng-container *ngIf="fileUploadUrls.length>0">
        <div *ngFor="let item of filenamearray1;let i=index">{{i+1}}.&nbsp;{{item}}</div>
        
      </ng-container> -->
    </div>
  </div>
  </ng-template>