<div class="page-breadcrumb page-header">
    <div class="row">
        <div class="col-12 align-self-center">
            <h4 class="page-title">Financial Year</h4>
            <!-- <div class="d-flex align-items-center m-t-10">
                <span class="m-r-20">Start Date : {{startDate | date}} </span>
                <span>End Date : {{endDate | date}} </span>
            </div> -->
        </div>
    </div>
</div>

        <mat-card>
            <mat-card-content>
                <div class="row p-10">
<div class="col-6">
    <label class="mt-2">Search&nbsp;Column&nbsp;By:&nbsp;</label>
    <input type="search" class="searchfield"  placeholder="Search By Financial Year" [(ngModel)]="searchData" (input)="searchdata()">
</div>
                    <div class="col-6  float-right text-right">

                        <button mat-icon-button value="Add" matTooltip="Add" class="custom-icon-button "
                            (click)="addfinanceyr(financeyrmodel)">
                            <span class="material-icons">
                                add
                            </span>
                        </button>


                    </div>

                </div>

                <ng-container>

                    <div class="row p-t-10">
                        <div class="col-12 col-md-12">
                            <!-- <mat-progress-bar mode="indeterminate" *ngIf="loadingRecords"></mat-progress-bar> -->
                            <div class="table-responsive mat-table-wrapper">
                                <div class="example-table-container">
                                    <table mat-table [dataSource]="dataSource">


                                        <ng-container matColumnDef="sno">
                                            <th mat-header-cell *matHeaderCellDef style="width:6%">S.NO</th>
                                            <td mat-cell *matCellDef="let row;let i=index;">
                                                {{(i+1) + (masterpaginator?.pageIndex * masterpaginator?.pageSize)}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="description">
                                            <th mat-header-cell *matHeaderCellDef style="width:13%">
                                                Financial Year</th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.description}}

                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="fromdate">
                                            <th mat-header-cell *matHeaderCellDef style="width:24%">
                                                From Date</th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.from_date | date: 'dd/MM/yyyy'}}
                                            </td>
                                        </ng-container>


                                        <ng-container matColumnDef="todate">
                                            <th mat-header-cell *matHeaderCellDef style="width:23%">To Date
                </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.to_date | date: 'dd/MM/yyyy' }}
                                            </td>
                                        </ng-container>

                                        <!-- <ng-container matColumnDef="ActiveStatus">
                                            <th mat-header-cell *matHeaderCellDef style="width:11%">
                                                Active Status
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                {{row.is_active}}
                                            </td>
                                        </ng-container> -->
                                        <ng-container matColumnDef="action">
                                            <th mat-header-cell *matHeaderCellDef style="width:10%">
                                                Actions
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                <button mat-icon-button matTooltip="Edit"
                                                    aria-label="Example icon button with a vertical three dot icon"
                                                   (click)="editdata(row,financeeditmodel)" >
                                                    <mat-icon style="color: blue;">edit</mat-icon>
                                                </button>

                                                <button mat-icon-button style="color: red;" matTooltip="Delete"
                                                    aria-label="Example icon button with a vertical three dot icon" (click)="deletedata(row,deleteFileModal)"
                                                    >
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>


                                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                    </table>
                                </div>
                                <div *ngIf="financedata==true" class="text-center">
                                    <h4 class="text-secondary m-3" *ngIf="loadingRecords">
                                        Loading...
                                    </h4>
                                    <h4 class="text-secondary m-3" *ngIf="!loadingRecords">
                                        No Records Found
                                    </h4>
                                </div>
                                <ng-container *ngIf="totalRecords > 0">
                                    <mat-paginator [length]="totalRecords" [pageSize]="10"
                                        [pageSizeOptions]="[5,10,50,100,500,1000]" #masterpaginator
                                        (page)="onpageevent($event)">
                                    </mat-paginator>
                                 </ng-container>   
                            </div>
                        </div>
                    </div>
                </ng-container>

            </mat-card-content>
        </mat-card>
        <ng-template #financeyrmodel>
            <div mat-dialog-title class="dialog-title">
                <div class="row">
                    <div class="col-11">
                        <h2>Add Financial Year</h2>
                    </div>
                    <div class="col-1 text-right">
                        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon">
                            <mat-icon class="icon1">close</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <mat-dialog-content>
                <form #myform="ngForm" (ngSubmit)="savefinancedata(myform)">
                    <div class="row">
                        <div class="col-md-2">
                            <label>Financial Year<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter Financial Year" class="inputstyles" name="description"
                                [(ngModel)]="description" >
                        </div>
        
                        <!-- </div> -->
                        <div class="col-md-2">
                            <label>From Date<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="date" class="inputstyles" name="fromdate"
                            [(ngModel)]="fromdate" style="text-transform: uppercase;">
                            
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>To Date<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="date" class="inputstyles" name="todate" [(ngModel)]="todate"
                            style="text-transform: uppercase;">
                           <div *ngIf="validateRange()">
                            <p class="error-message">To Date must be greater than the From Date.</p>
                           </div>
                        </div>
                        
                     </div><br>
                  
                    
                    <button mat-raised-button type="submit" class="savebutton" [disabled]="!description || !fromdate || !todate || validateRange()">Save</button>
                </form>
            </mat-dialog-content>
        </ng-template>
        <ng-template #financeeditmodel>
            <div mat-dialog-title class="dialog-title">
                <div class="row">
                    <div class="col-11">
                        <h2>Edit Financial Year</h2>
                    </div>
                    <div class="col-1 text-right">
                        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon">
                            <mat-icon class="icon1">close</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <mat-dialog-content>
                <form #myeditform="ngForm" (ngSubmit)="savefinanceeditdata(myeditform)">
                    <div class="row">
                        <div class="col-md-2">
                            <label>Financial Year<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" placeholder="Enter Financial Year" class="inputstyles" name="descriptionedit"
                                [(ngModel)]="descriptionedit" >
                        </div>
        
                        <!-- </div> -->
                        <div class="col-md-2">
                            <label>From Date<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="date" class="inputstyles" name="fromdateedit"
                            [(ngModel)]="fromdateedit" style="text-transform: uppercase;">
                            
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-md-2">
                            <label>To Date<span class="mandetory">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input type="date" class="inputstyles" name="todateedit" [(ngModel)]="todateedit"
                            style="text-transform: uppercase;">
                            <div *ngIf="validateRangeedit()">
                                <p class="error-message">To Date must be greater than the From Date.</p>
                               </div>
                        </div>
                        
                     </div><br>
                  
                    <button mat-raised-button type="submit" class="savebutton" [disabled]="!descriptionedit || !fromdateedit || !todateedit || validateRangeedit()">Update</button>
                </form>
            </mat-dialog-content>
        </ng-template>
        <ng-template #deleteFileModal>
            <!-- <h1 mat-dialog-title>Delete Financial Year</h1> -->
            <div class="mb-0" mat-dialog-title>
                <div class="row">
                    <div class="col-11">
                        <h1>Delete Financial Year</h1>
                    </div>
                    <div class="col-1 text-right">
                        <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeicon">
                            <mat-icon class="icon1">close</mat-icon>
                        </button>
                    </div>
                </div>
             </div>
            <div mat-dialog-content>Are you sure, you want to delete record?</div>
            <div mat-dialog-actions class="justify-content-center">
                <!-- <button mat-button mat-dialog-close style="background-color: rgb(239, 164, 164);">Close</button> -->
                <button mat-button cdkFocusInitial (click)="deleteFile()">Delete</button>
            </div>
        </ng-template>
