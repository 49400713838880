import { environment } from 'src/environments/environment';
import { RouteInfo } from './vertical-sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  // {
  //   path: '',
  //   title: 'Modules',
  //   icon: '',
  //   class: 'nav-small-cap',
  //   extralink: true,
  //   label: '',
  //   labelClass: '',
  //   submenu: [],
  // },
  // {
  //   path: '',
  //   title: 'Field Executive',
  //   icon: 'fas fa-user-times',
  //   class: 'nav-small-cap',
  //   extralink: true,
  //   label: '',
  //   labelClass: '',
  //   submenu: [],
  // },
  // {
  //   path: '',
  //   title: 'Quality',
  //   icon: 'fas fa-user-plus',
  //   class: 'nav-small-cap',
  //   extralink: true,
  //   label: '',
  //   labelClass: '',
  //   submenu: [],
  // },
  {
    path: "",
    title: "Dashboard",
    icon: "fa fa-folder-open",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: environment.amGreen,
    submenu: [
      {
        path: "/maindashboard",
        title: "Projects",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN","VIEW_PROJECTS"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/GamsDashboard",
        title: "GAMS Dashboard",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN","GAMS_DASHBOARD"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/projectdashboard",
        title: "Project Dashboard",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN","PROJECT_DASHBOARD"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
    ],
    roles: ["SUPER_ADMIN", "VIEW_PROJECTS","GAMS_DASHBOARD",'PROJECT_DASHBOARD'],
    company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  },
  // {
  //   path: "/version1",
  //   title: "Version1",
  //   icon: "fas fa-list",
  //   class: "has-arrow",
  //   extralink: false,
  //   label: "",
  //   labelClass: "",
  //   submenu: [
  
      
  // {
  //   path: '/qsd',
  //   title: 'QSD',
  //   icon: 'fas fa-list',
  //   class: 'has-arrow',
  //   extralink: false,
  //   label: '',
  //   labelClass: '',
  //   submenu: [
  //     {
  //       path: '/qsd',
  //       title: 'QSD Supply',
  //       icon: 'mdi mdi-book-multiple',
  //       class: '',
  //       extralink: false,
  //       label: '',
  //       labelClass: '',
  //       submenu: [],
  //       roles: ['SUPER_ADMIN', 'QSD_SUPPLY'],
  //       company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  //     },
  //     {
  //       path: '/qsd/qsdservices',
  //       title: 'QSD Services',
  //       icon: 'mdi mdi-book-multiple',
  //       class: '',
  //       extralink: false,
  //       label: '',
  //       labelClass: '',
  //       submenu: [],
  //       roles: ['SUPER_ADMIN', 'QSD_SERVICES'],
  //       company: ["GREENKO", "Digitele", "Digitele Networks"]
  //     },
  //     {
  //       path: '/qsd/siterecomsheet',
  //       title: 'Site Recommendation Sheet',
  //       icon: 'mdi mdi-book-multiple',
  //       class: '',
  //       extralink: false,
  //       label: '',
  //       labelClass: '',
  //       submenu: [],
  //       roles: ['SUPER_ADMIN', 'SITE_RECOMMENDATION'],
  //       company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  //     },
  //     {
  //       path: '/qsd/invoice',
  //       title: 'Model Invoice',
  //       icon: 'mdi mdi-book-multiple',
  //       class: '',
  //       extralink: false,
  //       label: '',
  //       labelClass: '',
  //       submenu: [],
  //       roles: ['SUPER_ADMIN'],
  //       company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  //     },


  //   ],
  //   roles: ['SUPER_ADMIN', 'QSD_SUPPLY', 'QSD_SERVICES', 'SITE_RECOMMENDATION'],
  //   company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  // },

  // {
  //   path: "/fuel-inventory",
  //   title: "Fuel Inventory",
  //   icon: "fa fa-suitcase",
  //   class: "has-arrow",
  //   extralink: false,
  //   label: "",
  //   labelClass: "",
  //   submenu: [
  //     // {
  //     //   path: "/fuel-inventory/fuel-issue",
  //     //   title: "Fuel Issue",
  //     //   icon: "mdi mdi-book-multiple",
  //     //   class: "",
  //     //   extralink: false,
  //     //   label: "",
  //     //   labelClass: "",
  //     //   submenu: [],
  //     //   roles: ["SUPER_ADMIN"],
  //     //   company: ["GREENKO", "MEIL-HES", "Digitele", "Digitele Networks"]
  //     // },
     
  //     {
  //       path: "/fuel-inventory/vendor-bunk-request",
  //       title: "Vendor Bunk Request",
  //       icon: "mdi mdi-book-multiple",
  //       class: "",
  //       extralink: false,
  //       label: "",
  //       labelClass: "",
  //       submenu: [],
  //       roles: ["SUPER_ADMIN"],
  //       company: ["GREENKO", "MEIL-HES", "Digitele", "Digitele Networks","Greenko Group"]
  //     },
  //     {
  //       path: "/fuel-inventory/bunk-confirmation",
  //       title: "Bunk Confirmation",
  //       icon: "mdi mdi-book-multiple",
  //       class: "",
  //       extralink: false,
  //       label: "",
  //       labelClass: "",
  //       submenu: [],
  //       roles: ["SUPER_ADMIN"],
  //       company: ["GREENKO", "MEIL-HES", "Digitele", "Digitele Networks","Greenko Group"]
  //     },
  //     {
  //       path: "/fuel-inventory/fuelreceiptnoe",
  //       title: "Fuel Receipt Note",
  //       icon: "mdi mdi-book-multiple",
  //       class: "",
  //       extralink: false,
  //       label: "",
  //       labelClass: "",
  //       submenu: [],
  //       roles: ["SUPER_ADMIN"],
  //       company: ["GREENKO", "MEIL-HES", "Digitele", "Digitele Networks","Greenko Group"]
  //     },
  //     {
  //       path: "/fuel-inventory/fuel-issue-request",
  //       title: "Fuel Issue Request",
  //       icon: "mdi mdi-book-multiple",
  //       class: "",
  //       extralink: false,
  //       label: "",
  //       labelClass: "",
  //       submenu: [],
  //       roles: ["SUPER_ADMIN"],
  //       company: ["GREENKO", "MEIL-HES", "Digitele", "Digitele Networks","Greenko Group"]
  //     },
    
  //     {
  //       path: "/fuel-inventory/fuel-issue",
  //       title: "Fuel Issue",
  //       icon: "mdi mdi-book-multiple",
  //       class: "",
  //       extralink: false,
  //       label: "",
  //       labelClass: "",
  //       submenu: [],
  //       roles: ["SUPER_ADMIN"],
  //       company: ["GREENKO", "MEIL-HES", "Digitele", "Digitele Networks","Greenko Group"]
  //     },
      
  //   ],
  //   roles: ["SUPER_ADMIN"],
  //   company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  // },


  //   ],
  //   // 'GOODS_ISSUE_REQUEST','PURCHASE_REQUEST', 'WORK_REQUEST', 'WORK_COMPLETION', 'JMS','QSD_SUPPLY', 'QSD_SERVICES', 'SITE_RECOMMENDATION','GATE_OUTWARD' 'OUT_GATE_PASS','PR_REPORTS','PO_REPORTS',
  //   roles: ["SUPER_ADMIN",   'GOODS_RECEIPT','GATE_OUTWARD_GI',"GATE_OUTWARD_RGP","GATE_OUTWARD_NRGP",'OUT_GATE_PASS_RGP','OUT_GATE_PASS_NRGP', 'PURCHASE_REQUEST', 'WORK_REQUEST',
  //   'GOODS_ISSUE', 'GOODS_RETURN','DELIVERY_CHALLAN',"INVENTORY_REPORTS", 'GSS_REPORTS' ,"DAILY_MATERIAL_RECEIPT", 'QUALITY_CHECK','GATE_INWARD',"WORK_ORDER" ],
  //   company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  // },
  {
    path: "/inventory",
    title: "Stores",
    icon: "fa fa-suitcase",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: environment.amGreen,
    submenu: [
      {
      path: "/inventory/storesdashboard",
      title: "Stores Dashboard",
      icon: "mdi mdi-book-multiple",
      class: "",
      extralink: false,
      label: "",
      labelClass: "",
      isamGreenEnv: environment.amGreen,
      submenu: [],
      roles: ["SUPER_ADMIN", "STORES_DASHBOARD"],
      company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
    },
    {
      path: "/inventory/gamdashboard",
      title: "Gams Dashboard",
      icon: "mdi mdi-book-multiple",
      class: "",
      extralink: false,
      label: "",
      labelClass: "",
      isamGreenEnv: environment.amGreen,
      submenu: [],
      roles: ["SUPER_ADMIN", "GAM_DASHBOARD"],
      company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
    },
      {
        path: "/inventory/dmr",
        title: "Daily Material Receipt",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "DAILY_MATERIAL_RECEIPT"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/inventory/qualitycheck",
        title: "Quality Check",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "QUALITY_CHECK"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/inventory/goodsreceipt",
        title: "Goods Receipt",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "GOODS_RECEIPT"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/inventory/goodsissue",
        title: "Goods Issue",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "GOODS_ISSUE"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/inventory/goodsreturn",
        title: "Goods Return",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "GOODS_RETURN"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      // {
      //   path: "/inventory/goodstransfer",
      //   title: "Out Gate Pass",
      //   icon: "mdi mdi-book-multiple",
      //   class: "",
      //   extralink: false,
      //   label: "",
      //   labelClass: "",
      //   submenu: [],
      //   roles: ["SUPER_ADMIN", "OUT_GATE_PASS"],
      //   company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      // },
      {
        path: "/inventory/deliverychallan",
        title: "Delivery Challan",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN",'DELIVERY_CHALLAN'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      // {
      //   path: "/inventory/subContractGoodsIssue",
      //   title: "Sub-Contract Goods Issue",
      //   icon: "mdi mdi-book-multiple",
      //   class: "",
      //   extralink: false,
      //   label: "",
      //   labelClass: "",
      //   submenu: [],
      //   roles: ["SUPER_ADMIN"],
      //   company: ["GREENKO", "MEIL-HES", "Digitele", "Digitele Networks","Greenko Group"]
      // },
      {
        path: "/inventory/outgatepassrgp1",
        title: "OutGatePass RGP",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN",'OUT_GATE_PASS_RGP'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/inventory/nrgp",
        title: "OutGatePass NRGP",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN",'OUT_GATE_PASS_NRGP'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/inventory/esdn",
        title: "ESDN",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN","ESDN"],
        company: ["GREENKO", "MEIL-HES", "Digitele", "Digitele Networks"]
      },
      {
        path: "/inventory/audit",
        title: "Audit",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN","AUDIT"],
        company: ["GREENKO", "MEIL-HES", "Digitele", "Digitele Networks"]
      },
      {
        path: "/inventory/storagelocation",
        title: "Storage Location",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN",'STORAGE_LOCATION'],
        company: ["GREENKO", "MEIL-HES", "Digitele", "Digitele Networks"]
      },
      {
        path: "/inventory/dmrbulkupdate",
        title: "DMRBulkUpdate",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN",'DMR_BULK_UPDATE'],
        company: ["GREENKO", "MEIL-HES", "Digitele", "Digitele Networks"]
      },


      {
        path: "/inventory/qrprint-dmr",
        title: "OR Print DMR",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [], 
        roles: ["SUPER_ADMIN",'QR_PRINT_DMR'],
        company: ["GREENKO", "MEIL-HES", "Digitele", "Digitele Networks"]
      },
    ],
    roles: ["SUPER_ADMIN", "DAILY_MATERIAL_RECEIPT", 'QUALITY_CHECK', 'GOODS_RECEIPT','STORAGE_LOCATION','AUDIT',
      'GOODS_ISSUE', 'GOODS_RETURN', 'OUT_GATE_PASS','DELIVERY_CHALLAN','OUT_GATE_PASS_RGP','OUT_GATE_PASS_NRGP','ESDN','DMR_BULK_UPDATE','QR_PRINT_DMR'],
    company: ["GREENKO", "MEIL-HES", "Digitele", "Digitele Networks","Greenko Group"]
  },
  {
    path: "/planning",
    title: "Planning/ QSD",
    icon: "fa fa-file",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: false,
    submenu: [
      // {
      //   path: '/planning/goodsIssueRequest',
      //   title: 'Goods Issue Request',
      //   icon: 'mdi mdi-book-multiple',
      //   class: '',
      //   extralink: false,
      //   label: "",
      //   labelClass: "",
      //   submenu: [],
      //   roles: ["SUPER_ADMIN", "GOODS_ISSUE_REQUEST"],
      //   company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      // },
   
      {
        path: "/planning/purchaserequest",
        title: "Purchase Request",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "PURCHASE_REQUEST"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/planning/workrequest",
        title: "Work Request",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "WORK_REQUEST"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/planning/nfa",
        title: "NFA/Otherdocuments",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: false,
        submenu: [],
        roles: ["SUPER_ADMIN", "NFA_REQUEST"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/planning/materialreqRequest",
        title: "Material Request",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: false,
        submenu: [],
        roles: ["SUPER_ADMIN", "MATERIAL_REQUEST"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
     
      // {
      //   path: "/planning/workcompletion",
      //   title: "Work Completion",
      //   icon: "mdi mdi-book-multiple",
      //   class: "",
      //   extralink: false,
      //   label: "",
      //   labelClass: "",
      //   submenu: [],
      //   roles: ["SUPER_ADMIN", "WORK_COMPLETION"],
      //   company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      // },
      // {
      //   path: "/planning/jms",
      //   title: "JMS",
      //   icon: "mdi mdi-book-multiple",
      //   class: "",
      //   extralink: false,
      //   label: "",
      //   labelClass: "",
      //   submenu: [],
      //   roles: ["SUPER_ADMIN", "JMS"],
      //   company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      // },
     

    ],
    // 'GOODS_ISSUE_REQUEST','WORK_COMPLETION', 'JMS'
    roles: ['SUPER_ADMIN',  'PURCHASE_REQUEST', 'WORK_REQUEST', 'NFA_REQUEST','MATERIAL_REQUEST' ],
    company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  },

  {
    path: "/reports",
    title: "Reports",
    icon: "fa fa-file",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: environment.amGreen,
    submenu: [
      // {
      //   path: "/reports",
      //   title: "Available Stock",
      //   icon: "mdi mdi-book-multiple",
      //   class: "",
      //   extralink: false,
      //   label: "",
      //   labelClass: "",
      //   submenu: [],
      //   roles: ["SUPER_ADMIN", "VIEW_MATERIAL"],
      // },
      // {
      //   path: "/reports/smstock",
      //   title: "Single Material Stock",
      //   icon: "mdi mdi-book-multiple",
      //   class: "",
      //   extralink: false,
      //   label: "",
      //   labelClass: "",
      //   submenu: [],
      //   roles: ["SUPER_ADMIN", "VIEW_MATERIAL"],
      // },
      {
        path: "/reports/ir",
        title: "Stores Reports",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "INVENTORY_REPORTS"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/reports/gss_reports",
        title: "Gss Reports",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", 'GSS_REPORTS'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/reports/transitreports",
        title: "Transit Reports",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN","TRANSIT_REPORTS"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      
      {
        path: "/reports/prreports",
        title: "PR Reports",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", 'PR_REPORTS'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]

      },
      {
        path: "/reports/poreports",
        title: "PO Reports",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", 'PO_REPORTS'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/reports/rmcreports",
        title: "RMC Reports",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", 'RMC_REPORTS'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/reports/nfareports",
        title: "NFA Reports",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", 'NFA_REPORTS'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/reports/machineryreports",
        title: "MACHINERY Reports",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", 'MACHINERY_REPORTS'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/reports/esdnreports",
        title: "ESDN Reports",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", 'ESDN_REPORTS'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/reports/reusableitemsreports",
        title: "Reusable Item Reports",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", 'REUSABLE_ITEMS_REPORTS'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
    ],
    // 'PR_REPORTS', 'PO_REPORTS'
    roles: ["SUPER_ADMIN", "INVENTORY_REPORTS", 'GSS_REPORTS','TRANSIT_REPORTS', 'PO_REPORTS','PR_REPORTS','ESDN_REPORTS','REUSABLE_ITEMS_REPORTS','RMC_REPORTS','MACHINERY_REPORTS','NFA_REPORTS'],
    company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  },
  {
    path: "/gss",
    title: "GSS",
    icon: "fa fa-file",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: environment.amGreen,
    submenu: [

      {
        path: "/gss/gate_inward",
        title: "Gate Inward",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", 'GATE_INWARD'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      // {
      //   path: "/gss/gate_outward",
      //   title: "Gate Outward",
      //   icon: "mdi mdi-book-multiple",
      //   class: "",
      //   extralink: false,
      //   label: "",
      //   labelClass: "",
      //   submenu: [],
      //   roles: ["SUPER_ADMIN", 'GATE_OUTWARD'],
      //   company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      // },
      {
        path: "/gss/gate-outward-rgp",
        title: "Gate Outward RGP",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN","GATE_OUTWARD_RGP"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/gss/gate-outward-nrgp1",
        title: "Gate Outward NRGP",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN","GATE_OUTWARD_NRGP"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/gss/gate-outward-gi",
        title: "Gate Outward GI",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN",'GATE_OUTWARD_GI'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
    ],
    roles: ["SUPER_ADMIN", 'GATE_INWARD', 'GATE_OUTWARD','GATE_OUTWARD_GI',"GATE_OUTWARD_RGP","GATE_OUTWARD_NRGP"],
    company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  },
  {
    path: "/rmc",
    title: "RMC",
    icon: "fa fa-file",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: environment.amGreen,
    submenu: [

      {
        path: "/rmc/rmcgoodsreceipt",
        title: "Raw Material Goods Receipt",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", 'RMC_RECEIPT'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/rmc/rmcgoodsissue",
        title: "Raw Material Goods Consumption",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", 'RMC_CONSUMPTION'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/rmc/rmcgoodsreturn",
        title: "Raw Material Goods Return",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", 'RMC_RETURN'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/rmc/rmcissue",
        title: "RMC Supply",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", 'RMC_SUPPLY'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
    ],
    roles: ["SUPER_ADMIN", 'RMC_RECEIPT','RMC_CONSUMPTION','RMC_RETURN','RMC_SUPPLY'],
    company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  },

  {
    path: "/reusableitems",
    title: "Reusable Items",
    icon: "fa fa-file",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: environment.amGreen,
    submenu: [

      {
        path: "/reusableitems/reusableitemsinward",
        title: "Reusable Items Inward",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", 'REUSABLE_ITEMS_INWARD'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/reusableitems/reusableitemsoutward",
        title: "Reusable Items Outward",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", 'REUSABLE_ITEMS_OUTWARD'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/reusableitems/reusableitemsreturn",
        title: "Reusable Items Return",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", 'REUSABLE_ITEMS_RETURN'],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
     
    ],
    roles: ["SUPER_ADMIN", "REUSABLE_ITEMS_INWARD","REUSABLE_ITEMS_OUTWARD","REUSABLE_ITEMS_RETURN"],
    company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  },

  {
    path: "ticket",
    title: "C&P",
    icon: "fas fa-file-excel",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: environment.amGreen,
    submenu: [
      // {
      //   path: "/cp/po",
      //   title: "PO Uploads",
      //   icon: "mdi mdi-book-multiple",
      //   class: "",
      //   extralink: false,
      //   label: "",
      //   labelClass: "",
      //   submenu: [],
      //   roles: ["SUPER_ADMIN", "VIEW_PO_UPLOADS"],
      //   company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      // },
      // {
      //   path: "/cp/wo",
      //   title: "WO Uploads",
      //   icon: "mdi mdi-book-plus",
      //   class: "",
      //   extralink: false,
      //   label: "",
      //   labelClass: "",
      //   submenu: [],
      //   roles: ["SUPER_ADMIN", "VIEW_WO_UPLOADS"],
      //   company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      // },
      {
        path: "/cp/purchase_order",
        title: "Purchase Order",
        icon: "mdi mdi-book-plus",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "PURCHASE_ORDER"],
        company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/cp/workorder",
        title: "Work Order",
        icon: "mdi mdi-book-plus",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "WORK_ORDER"],
        company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      // {
      //   path: "/cp/joborder",
      //   title: "Job Order",
      //   icon: "mdi mdi-book-plus",
      //   class: "",
      //   extralink: false,
      //   label: "",
      //   labelClass: "",
      //   submenu: [],
      //   roles: ["SUPER_ADMIN"],
      //   company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      // },
      {
        path: "/cp/po-excel-upload",
        title: "Excel Upload",
        icon: "mdi mdi-book-plus",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN"],
        company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/cp/po-boq",
        title: "Purchase Order BOQ",
        icon: "mdi mdi-book-plus",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN","PO_BOQ"],
        company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/cp/workordersupply",
        title: "Sale Order Supply Materials",
        icon: "mdi mdi-book-plus",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN","SALE_ORDER_SUPPLY_MATERIALS"],
        company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
    ],
    roles: ["SUPER_ADMIN", "VIEW_PO_UPLOADS","VIEW_WO_UPLOADS","PURCHASE_ORDER","WORK_ORDER","SALE_ORDER_SUPPLY_MATERIALS","PO_BOQ"],
    company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  },

  {
    path: "/machinery/vehiclelog",
    title: "Machinery",
    icon: "fa fa-file",
    class: "",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: false,
    submenu: [],
   
    roles: ['SUPER_ADMIN',  'MACHINERY' ],
    company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  },
  
  



//   {
//     path: "/version2",
//     title: "Version2",
//     icon: "fas fa-list",
//     class: "has-arrow",
//     extralink: false,
//     label: "",
//     labelClass: "",
//     submenu: [
//     {
//       path: "/planning2",
//       title: "Planning",
//       icon: "mdi mdi-book-multiple",
//       class: "has-arrow",
//       extralink: false,
//       label: "",
//       labelClass: "",
//       submenu: [
//         {
//           path: "/planning2/goodsissuerequest2",
//           title: "Goods Issue Request",
//           icon: "mdi mdi-book-multiple",
//           class: "",
//           extralink: false,
//           label: "",
//           labelClass: "",
//           submenu: [],
//           roles: ["SUPER_ADMIN","GOODS_ISSUE_REQUEST_V2"],
//           company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
//       },
//         //   {
//         //     path: "/planning2/purchaserequest2",
//         //     title: "Purchase Request",
//         //     icon: "mdi mdi-book-multiple",
//         //     class: "",
//         //     extralink: false,
//         //     label: "",
//         //     labelClass: "",
//         //     submenu: [],
//         //     roles: ["SUPER_ADMIN"],
//         //     company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
//         // },
     
//       ],
//       roles: ["SUPER_ADMIN","GOODS_ISSUE_REQUEST_V2"],
//       company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
//     },
// //     {
// //       path: "/inventory2",
// //       title: "Inventory",
// //       icon: "mdi mdi-book-multiple",
// //       class: "has-arrow",
// //       extralink: false,
// //       label: "",
// //       labelClass: "",
// //       submenu: [
// //         {
// //           path: "/inventory2/dmr2",
// //           title: "Daily Material Receipt",
// //           icon: "mdi mdi-book-multiple",
// //           class: "",
// //           extralink: false,
// //           label: "",
// //           labelClass: "",
// //           submenu: [],
// //           roles: ["SUPER_ADMIN","DAILY_MATERIAL_RECEIPT_V2"],
// //           company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
// //       },
// //         {
// //           path: "/inventory2/qualitycheck2",
// //           title: "Quality Check",
// //           icon: "mdi mdi-book-multiple",
// //           class: "",
// //           extralink: false,
// //           label: "",
// //           labelClass: "",
// //           submenu: [],
// //           roles: ["SUPER_ADMIN","QUALITY_CHECK_V2"],
// //           company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
// //       },
// //         {
// //           path: "/inventory2/goodsissue2",
// //           title: "Goods Issue",
// //           icon: "mdi mdi-book-multiple",
// //           class: "",
// //           extralink: false,
// //           label: "",
// //           labelClass: "",
// //           submenu: [],
// //           roles: ["SUPER_ADMIN","GOODS_ISSUE_V2"],
// //           company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
// //       },
// //       {
// //         path: "/inventory2/goodreturn2",
// //         title: "Goods Return",
// //         icon: "mdi mdi-book-multiple",
// //         class: "",
// //         extralink: false,
// //         label: "",
// //         labelClass: "",
// //         submenu: [],
// //         roles: ["SUPER_ADMIN","GOODS_RETURN_V2"],
// //         company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
// //     },
// //     {
// //       path: "/inventory2/goodsreceipt2",
// //       title: "Goods Receipt",
// //       icon: "mdi mdi-book-multiple",
// //       class: "",
// //       extralink: false,
// //       label: "",
// //       labelClass: "",
// //       submenu: [],
// //       roles: ["SUPER_ADMIN","GOODS_RECEIPT_V2"],
// //       company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
// //   },
// //   {
// //     path: "/inventory2/outgatepassrgp2",
// //     title: "OutGatePass RGP",
// //     icon: "mdi mdi-book-multiple",
// //     class: "",
// //     extralink: false,
// //     label: "",
// //     labelClass: "",
// //     submenu: [],
// //     roles: ["SUPER_ADMIN","OUT_GATE_PASS_RGP_V2"],
// //     company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
// // },
// // {
// //   path: "/inventory2/nrgp2",
// //   title: "OutGatePass NRGP",
// //   icon: "mdi mdi-book-multiple",
// //   class: "",
// //   extralink: false,
// //   label: "",
// //   labelClass: "",
// //   submenu: [],
// //   roles: ["SUPER_ADMIN","OUT_GATE_PASS_NRGP_V2"],
// //   company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
// // },
// //     ],
// //     roles: ["SUPER_ADMIN","DAILY_MATERIAL_RECEIPT_V2","QUALITY_CHECK_V2","GOODS_ISSUE_V2","GOODS_RETURN_V2","GOODS_RECEIPT_V2","OUT_GATE_PASS_RGP_V2","OUT_GATE_PASS_NRGP_V2"],
// //     company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
// //     },
//     {
//       path: "/gss",
//       title: "GSS",
//       icon: "fa fa-file",
//       class: "has-arrow",
//       extralink: false,
//       label: "",
//       labelClass: "",
//       submenu: [
  
//         {
//           path: "/gss2/gate-inward2",
//           title: "Gate Inward",
//           icon: "mdi mdi-book-multiple",
//           class: "",
//           extralink: false,
//           label: "",
//           labelClass: "",
//           submenu: [],
//           roles: ["SUPER_ADMIN","GATE_INWARD_V2"],
//           company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
//         },
//         {
//           path: "/gss2/gate-outward-rgp2",
//           title: "Gate Outward RGP",
//           icon: "mdi mdi-book-multiple",
//           class: "",
//           extralink: false,
//           label: "",
//           labelClass: "",
//           submenu: [],
//           roles: ["SUPER_ADMIN","GATE_OUTWARD_RGP_V2"],
//           company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
//         },
//         {
//           path: "/gss2/gate-outward-nrgp2",
//           title: "Gate Outward NRGP",
//           icon: "mdi mdi-book-multiple",
//           class: "",
//           extralink: false,
//           label: "",
//           labelClass: "",
//           submenu: [],
//           roles: ["SUPER_ADMIN","GATE_OUTWARD_NRGP_V2"],
//           company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
//         },
//         {
//           path: "/gss2/gate-outward-gi2",
//           title: "Gate Outward GI",
//           icon: "mdi mdi-book-multiple",
//           class: "",
//           extralink: false,
//           label: "",
//           labelClass: "",
//           submenu: [],
//           roles: ["SUPER_ADMIN","GATE_OUTWARD_GI_V2"],
//           company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
//         },
//       ],
//       roles: ["SUPER_ADMIN","GATE_INWARD_V2","GATE_OUTWARD_RGP_V2","GATE_OUTWARD_NRGP_V2","GATE_OUTWARD_GI_V2"],
//       company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
//     },
//     {
//       path: "/reports2",
//       title: "Reports",
//       icon: "mdi mdi-book-multiple",
//       class: "has-arrow",
//       extralink: false,
//       label: "",
//       labelClass: "",
//       submenu: [
//         {
//           path: "/reports2/inventoryreports2",
//           title: "Inventory Reports",
//           icon: "mdi mdi-book-multiple",
//           class: "",
//           extralink: false,
//           label: "",
//           labelClass: "",
//           submenu: [],
//           roles: ["SUPER_ADMIN","INVENTORY_REPORTS_V2"],
//           company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
//       },
//     //   {
//     //     path: "/reports2/fuelreports2",
//     //     title: "Fuel Reports",
//     //     icon: "mdi mdi-book-multiple",
//     //     class: "",
//     //     extralink: false,
//     //     label: "",
//     //     labelClass: "",
//     //     submenu: [],
//     //     roles: ["SUPER_ADMIN"],
//     //     company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
//     // },
//     {
//       path: "/reports2/gssreports2",
//       title: "GSS Reports",
//       icon: "mdi mdi-book-multiple",
//       class: "",
//       extralink: false,
//       label: "",
//       labelClass: "",
//       submenu: [],
//       roles: ["SUPER_ADMIN","GSS_REPORTS_V2"],
//       company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
//   },
//       ],
//       roles: ["SUPER_ADMIN","INVENTORY_REPORTS_V2","GSS_REPORTS_V2"],
//       company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
//     },
// //     {
// //       path: "/fuelinventory2",
// //       title: "Fuel Inventory",
// //       icon: "mdi mdi-book-multiple",
// //       class: "has-arrow",
// //       extralink: false,
// //       label: "",
// //       labelClass: "",
// //       submenu: [
// //         {
// //           path: "/fuelinventory2/vendorbank2",
// //           title: "Vendor Bunk Request",
// //           icon: "mdi mdi-book-multiple",
// //           class: "",
// //           extralink: false,
// //           label: "",
// //           labelClass: "",
// //           submenu: [],
// //           roles: ["SUPER_ADMIN"],
// //           company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
// //       },
    
// //       {
// //         path: "/fuelinventory2/bunkconfirmations2",
// //         title: "Bunk Confirmation",
// //         icon: "mdi mdi-book-multiple",
// //         class: "",
// //         extralink: false,
// //         label: "",
// //         labelClass: "",
// //         submenu: [],
// //         roles: ["SUPER_ADMIN"],
// //         company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
// //     },
// //     {
// //       path: "/fuelinventory2/fuelreceiptnote2",
// //       title: "Fuel Receipt Note",
// //       icon: "mdi mdi-book-multiple",
// //       class: "",
// //       extralink: false,
// //       label: "",
// //       labelClass: "",
// //       submenu: [],
// //       roles: ["SUPER_ADMIN"],
// //       company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
// //   },
// //     {
// //       path: "/fuelinventory2/fuelissuerequest2",
// //       title: "Fuel Issue Request",
// //       icon: "mdi mdi-book-multiple",
// //       class: "",
// //       extralink: false,
// //       label: "",
// //       labelClass: "",
// //       submenu: [],
// //       roles: ["SUPER_ADMIN"],
// //       company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
// //   },
// //   {
// //     path: "/fuelinventory2/fuelissue2",
// //     title: "Fuel Issue",
// //     icon: "mdi mdi-book-multiple",
// //     class: "",
// //     extralink: false,
// //     label: "",
// //     labelClass: "",
// //     submenu: [],
// //     roles: ["SUPER_ADMIN"],
// //     company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
// // },
// //       ],
// //       roles: ["SUPER_ADMIN"],
// //       company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
// //     },
//     // {
//     //   path: "cp2",
//     //   title: "C&P",
//     //   icon: "fas fa-file-excel",
//     //   class: "has-arrow",
//     //   extralink: false,
//     //   label: "",
//     //   labelClass: "",
//     //   submenu: [
//     //     {
//     //       path: "/cp2/excelupload",
//     //       title: "Excel Upload",
//     //       icon: "mdi mdi-book-plus",
//     //       class: "",
//     //       extralink: false,
//     //       label: "",
//     //       labelClass: "",
//     //       submenu: [],
//     //       roles: ["SUPER_ADMIN","PO_EXCEL_UPLOAD_V2"],
//     //       company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
//     //     },
//     //   ],
//     //   roles: ["SUPER_ADMIN","PO_EXCEL_UPLOAD_V2"],
//     //   company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
//     // },
//   ],
//   roles: ["SUPER_ADMIN","GOODS_ISSUE_REQUEST_V2",
//   "DAILY_MATERIAL_RECEIPT_V2","QUALITY_CHECK_V2","GOODS_ISSUE_V2","GOODS_RETURN_V2","GOODS_RECEIPT_V2","OUT_GATE_PASS_RGP_V2","OUT_GATE_PASS_NRGP_V2",
// "GATE_INWARD_V2","GATE_OUTWARD_RGP_V2","GATE_OUTWARD_NRGP_V2","GATE_OUTWARD_GI_V2",
// "INVENTORY_REPORTS_V2","GSS_REPORTS_V2",
// "PO_EXCEL_UPLOAD_V2"
// ],
//   company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
// },
{
  path: "/documentapprovals/mypendingapprovals",
  title: "Document Approvals",
  icon: "fas fa-file-excel",
  class: "",
  extralink: false,
  label: "",
  labelClass: "",
  isamGreenEnv: false,
  submenu: [],
  roles: ["SUPER_ADMIN","DOCUMENT_APPROVALS"],
company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
},
  {
    path: "/material",
    title: "Master",
    icon: "fas fa-list",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: false,
    submenu: [
      {
        path: "/material",
        title: "Material",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "VIEW_MATERIAL"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/material/vendor",
        title: "Vendors",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "VIEW_VENDORS"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/material/contractors",
        title: "Contractors",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "VIEW_CONTRACTORS"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/material/companys",
        title: "Companies",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "VIEW_COMPANIES"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/material/tax",
        title: "Tax List",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "VIEW_TAX_LIST"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/material/financeyear",
        title: "Financial Year",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "VIEW_FINANCIAL_YEAR"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/material/servicemaster",
        title: "Service Master",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "SERVICE_MASTER"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/material/vehiclemaster",
        title: "Vehicle Master",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "VEHICLE_MASTER"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },

      {
        path: "/material/projectmaster",
        title: "Project Master",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "PROJECT_MASTER"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/material/costcenter",
        title: "Cost Center",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "COSTCENTER_MASTER"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/material/plantmaster",
        title: "Plant Master",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "PLANT_MASTER"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/material/equipmentmaster",
        title: "Equipment Master",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "EQUIPMENT_MASTER"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/material/designmixmaster",
        title: "Design Mix Master",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "DESIGNMIX_MASTER"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/material/verticalmaster",
        title: "Vertical Master",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "VERTICAL_MASTER"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/material/businessunit",
        title: "Business Unit",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "BUSSINESS_MASTER"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/material/clustermaster",
        title: "Cluster Master",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "CLUSTER_MASTER"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/material/authorizationchainmas",
        title: "Authorization Chain",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: false,
        submenu: [],
        roles: ["SUPER_ADMIN", "AUTHORIZATION_CHAIN_MAS"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/material/storagelocations",
        title: "Storage Locations",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "STORAGE_LOCATION_MASTER"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
    ],
    roles: ["SUPER_ADMIN", "VIEW_MATERIAL","VIEW_VENDORS","VIEW_CONTRACTORS","VIEW_COMPANIES","VIEW_TAX_LIST","VIEW_FINANCIAL_YEAR","SERVICE_MASTER","VEHICLE_MASTER","AUTHORIZATION_CHAIN_MAS"
    ,"COSTCENTER_MASTER","PROJECT_MASTER","PLANT_MASTER","EQUIPMENT_MASTER","DESIGNMIX_MASTER","VERTICAL_MASTER","BUSSINESS_MASTER","CLUSTER_MASTER","STORAGE_LOCATION_MASTER" ],
    company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  },
  {
    path: "/tickets",
    title: "Tickets",
    icon: "fas fa-ticket-alt",
    class: "",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: environment.amGreen,
    submenu: [],
    roles: ["SUPER_ADMIN", "VIEW_TICKETS"],
    company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  },

  // {
  //   path: "ticket",
  //   title: "C&P",
  //   icon: "fas fa-file-excel",
  //   class: "has-arrow",
  //   extralink: false,
  //   label: "",
  //   labelClass: "",
  //   submenu: [
  //     // {
  //     //   path: "/cp/po",
  //     //   title: "PO Uploads",
  //     //   icon: "mdi mdi-book-multiple",
  //     //   class: "",
  //     //   extralink: false,
  //     //   label: "",
  //     //   labelClass: "",
  //     //   submenu: [],
  //     //   roles: ["SUPER_ADMIN", "VIEW_PO_UPLOADS"],
  //     //   company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  //     // },
  //     // {
  //     //   path: "/cp/wo",
  //     //   title: "WO Uploads",
  //     //   icon: "mdi mdi-book-plus",
  //     //   class: "",
  //     //   extralink: false,
  //     //   label: "",
  //     //   labelClass: "",
  //     //   submenu: [],
  //     //   roles: ["SUPER_ADMIN", "VIEW_WO_UPLOADS"],
  //     //   company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  //     // },
  //     {
  //       path: "/cp/purchase_order",
  //       title: "Purchase Order",
  //       icon: "mdi mdi-book-plus",
  //       class: "",
  //       extralink: false,
  //       label: "",
  //       labelClass: "",
  //       submenu: [],
  //       roles: ["SUPER_ADMIN", "PURCHASE_ORDER"],
  //       company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  //     },
  //     {
  //       path: "/cp/workorder",
  //       title: "Work Order",
  //       icon: "mdi mdi-book-plus",
  //       class: "",
  //       extralink: false,
  //       label: "",
  //       labelClass: "",
  //       submenu: [],
  //       roles: ["SUPER_ADMIN", "WORK_ORDER"],
  //       company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  //     },
  //     {
  //       path: "/cp/joborder",
  //       title: "Job Order",
  //       icon: "mdi mdi-book-plus",
  //       class: "",
  //       extralink: false,
  //       label: "",
  //       labelClass: "",
  //       submenu: [],
  //       roles: ["SUPER_ADMIN"],
  //       company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  //     },
  //     {
  //       path: "/cp/po-excel-upload",
  //       title: "Excel Upload",
  //       icon: "mdi mdi-book-plus",
  //       class: "",
  //       extralink: false,
  //       label: "",
  //       labelClass: "",
  //       submenu: [],
  //       roles: ["SUPER_ADMIN"],
  //       company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  //     },
  //   ],
  //   roles: ["SUPER_ADMIN", "VIEW_PO_UPLOADS","VIEW_WO_UPLOADS","PURCHASE_ORDER","WORK_ORDER",],
  //   company: ["MEIL-HES", "GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  // },
  {
    path: "/gallery",
    title: "Gallery",
    icon: "fas fa-image",
    class: "",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: environment.amGreen,
    submenu: [],
    roles: ["SUPER_ADMIN", "VIEW_GALLERY"],
    company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  },
  {
    path: "/userprofiles",
    title: "User Details",
    icon: "fas fa-user",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: environment.amGreen,
    submenu: [
      {
        path: "/userprofiles",
        title: "User Profiles",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "USER_PROFILE"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/pending-signatures",
        title: "Pending Signatures",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "PENDING_SIGNATURES"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
      {
        path: "/autotriggeremail/autotriggeremails",
        title: "Auto Trigger Emails",
        icon: "mdi mdi-book-multiple",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        isamGreenEnv: environment.amGreen,
        submenu: [],
        roles: ["SUPER_ADMIN", "AUTO_TRIGGER_EMAIL"],
        company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
      },
    ],
    roles: ["SUPER_ADMIN","USER_PROFILE","PENDING_SIGNATURES","AUTO_TRIGGER_EMAIL"],
    company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  },
  {
    path: "/users",
    title: "User Management",
    icon: "fas fa-user",
    class: "",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: environment.amGreen,
    submenu: [],
    roles: ["SUPER_ADMIN", "VIEW_USER_MANAGEMENT"],
    company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  },
  {
    path: "/projectwbs",
    title: "User Projects WBS",
    icon: "fas fa-users",
    class: "",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: environment.amGreen,
    submenu: [],
    roles: ["SUPER_ADMIN", "VIEW_USER_PROJECT_WBS"],
    company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  },
  {
    path: "/menus",
    title: "Menu Roles",
    icon: "fas fa-bars",
    class: "",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: environment.amGreen,
    submenu: [],
    roles: ["SUPER_ADMIN", "VIEW_MENU_ROLES"],
    company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  },
  {
    path: "/settings",
    title: "Settings",
    icon: "fas fa-cog",
    class: "",
    extralink: false,
    label: "",
    labelClass: "",
    isamGreenEnv: environment.amGreen,
    submenu: [],
    roles: ["SUPER_ADMIN", "VIEW_SETTINGS"],
    company: ["GREENKO", "Digitele", "Digitele Networks","Greenko Group"]
  },
  // {
  //   path: "/master-add",
  //   title: "Master Add",
  //   icon: "fas fa-list",
  //   class: "has-arrow",
  //   extralink: false,
  //   label: "",
  //   labelClass: "",
  //   submenu: [
  //     {
  //       path: "/master-add/vehiclemaster",
  //       title: "Vehicle",
  //       icon: "mdi mdi-book-multiple",
  //       class: "",
  //       extralink: false,
  //       label: "",
  //       labelClass: "",
  //       submenu: [],
  //       roles: ["SUPER_ADMIN", "VIEW_MATERIAL"],
  //       company: ["GREENKO", "Digitele", "Digitele Networks"]
  //     },
  //   ],
  //   roles: ["SUPER_ADMIN", "VIEW_MATERIAL"],
  //   company: ["GREENKO", "Digitele", "Digitele Networks"]
  // },
];
